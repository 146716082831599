import React, {useEffect} from "react";
import { useAtom } from 'jotai';
import useState from 'react-usestateref';
import { styled } from '@mui/material/styles';
import TaskManager from '../FileManager/TaskManager';

import { Grid, Paper } from '@mui/material';

const Item = styled(Paper)(({ theme })=>({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: 0,
    textAlign: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%',
    color: theme.palette.text.secondary,
  }));

const AddVendor=({dynamicComponent,globalState,dbUpdateValidate,viewData,globalWrn})=> {
  const {compID} = viewData
  const outputState = useState(Object)
  const mainState = useState(Object)
  const [,, outputRef] = outputState
  const [, setState, stateRef] = mainState
  const [teamMember] = useAtom(globalState.teamMemberState)
  const [vendorMap] = useAtom(globalState.vendorMapState)
  
  var [, setPopup2] = useAtom(globalState.popupState2)
  var [, setPopup3] = useAtom(globalState.popupState3)
  var [, setPopup] = useAtom(globalState.popupState)

  if(compID) var vendorData = vendorMap.get(compID);
  const simp = (t, path) => path.split(".").reduce((r, k) => r?.[k], t)
  function latestState(x){
    return outputRef.current[x] || simp(vendorData,x)
  }


  useEffect(()=>{
    if(latestState('AccountNumber') && latestState('Name')) {
        setPopup2(p=>({...p,...{
            handleSubmit: ()=> {
                dbUpdateValidate({model:'Vendors',queryID: compID ? true: false, query:  compID ?  compID : {...outputRef.current},update:{...outputRef.current}},({success,info})=>{
                if(success) {
                    globalWrn('success',`Vendor was created.`)
                    if(!compID&&info._id) stateRef.current.createNewFolder(`/EKC/Vendors`, info._id).then(({data})=> 
                    dbUpdateValidate({model:'Vendors',queryID: true, query: info._id, update:{[`Repository.${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}folderID`]: data.folderInfo.id }},({success})=>{
                    if(success) globalWrn('success',`You have successfully created a repository for ${latestState('Name')}`)
                  }) )
                }
                else globalWrn('error',`We had an issue creating the Vendor.`)
            })
            setPopup2({open:false})
            }
    }   }))
    }
  },[outputRef.current])

  const newSite=()=>{
    setState(p=>({...p,...{
      newSt: dynamicComponent('AddSites','elements',{state:'newSite', popupNum: 3},mainState),
    }}))
  }
  
  const addSites=()=>{
    newSite()
    setPopup3(p=>({...p,...{
      open:true,
      title:`Add District/Company Site`,
      description: `Do not add entire company, government bureau/agency, or school district.`,
      inputField: stateRef.current.newSt,
      handleClose: ()=>setPopup3({open:false}),
  }}))  
  }


  useEffect(()=>{
    setState(p=>({...p,...{
        Vendor: <Grid>
                    <Item>
                        <Grid item xs={2} sx={{padding:'0.5%', width: '49%', margin: 'auto'}}>
                            {dynamicComponent('InputField','elements',{state:'AccountNumber',defaultValue:latestState('AccountNumber'),label:'Add an Account Number'},outputState)}
                        </Grid>
                        <Grid item xs={2} sx={{padding:'0.5%', width: '49%', margin: 'auto'}}>
                            {dynamicComponent('InputField','elements',{state:'Name',defaultValue:latestState('Name'),label:'Add a Vendor Name'},outputState)}
                        </Grid>
                    </Item>
                    <Item>
                        <Grid item xs={2} sx={{padding:'0.5%', width: '49%', margin: 'auto'}}> 
                            {dynamicComponent('AutoComplete','elements',{state:'Address',label:'Company(s) / Site(s)',defaultValue:latestState('Address'),model:'Sites',idConvert:'$nin',query:{'_id':Array.from(teamMember).filter(([,{userType}])=>userType==="Sites").flatMap(([k])=>k)}, addEntry:addSites},outputState)}
                        </Grid>
                        <Grid item xs={2} sx={{padding:'0.5%', width: '49%', margin: 'auto'}}> 
                            {dynamicComponent('InputField','elements',{state:'Phone',type:'tel',defaultValue:latestState('Phone'),label:'Add Phone Number'},outputState)}
                        </Grid>
                    </Item>
                    <Item>
                        <Grid item xs={2} sx={{padding:'0.5%', width: '49%', margin: 'auto'}}> 
                            {dynamicComponent('InputField','elements',{state:'Fax',type:'tel',defaultValue:latestState('Fax'),label:'Add Fax Number (Optional)'},outputState)}
                        </Grid>
                        <Grid item xs={2} sx={{padding:'0.5%', width: '49%', margin: 'auto'}}> 
                            {dynamicComponent('InputField','elements',{state:'Check Limit',defaultValue:latestState('Check Limit'),label:'Add Check Limit'},outputState)}
                        </Grid>
                    </Item>
                    <Item>
                        <Grid item xs={2} sx={{padding:'0.5%', width: '49%', margin: 'auto'}}> 
                            {dynamicComponent('InputField','elements',{state:'Terms',defaultValue:latestState('Terms'),label:'Add Terms'},outputState)}
                        </Grid>
                        <Grid item xs={2} sx={{padding:'0.5%', width: '49%', margin: 'auto'}}> 
                            {dynamicComponent('InputField','elements',{state:'Pct',defaultValue:latestState('Pct'),label:'Add Pct'},outputState)}
                        </Grid>
                    </Item>
                    <Item>
                        <Grid item xs={2} sx={{padding:'0.5%', width: '49%', margin: 'auto'}}> 
                            {dynamicComponent('InputField','elements',{state:'Status',defaultValue:latestState('Status'),label:'Add Status'},outputState)}
                        </Grid>
                        <Grid item xs={2} sx={{padding:'0.5%', width: '49%', margin: 'auto'}}> 
                            {dynamicComponent('InputField','elements',{state:'GL Acct',defaultValue:latestState('GL Acct'),label:'Add a GL Account'},outputState)}
                        </Grid>
                    </Item>
                    <Item>
                        <Grid item xs={2} sx={{padding:'0.5%', width: '49%', margin: 'auto'}}> 
                            {dynamicComponent('InputField','elements',{state:'ID',defaultValue:latestState('ID'),label:'Add an ID'},outputState)}
                        </Grid>
                        <Grid item xs={2} sx={{padding:'0.5%', width: '49%', margin: 'auto'}}> 
                            {dynamicComponent('InputField','elements',{state:'Misc',defaultValue:latestState('Misc'),label:'Misc.'},outputState)}
                        </Grid>
                    </Item>
                    <Item>
                        <Grid item xs={2} sx={{padding:'0.5%', width: '49%', margin: 'auto'}}> 
                            {dynamicComponent('InputField','elements',{state:'ordersEmail',defaultValue:latestState('ordersEmail'),type:'email',label:'Orders Email'},outputState)}
                        </Grid>
                        <Grid item xs={2} sx={{padding:'0.5%', width: '49%', margin: 'auto'}}> 
                            {dynamicComponent('InputField','elements',{state:'repEmail',defaultValue:latestState('repEmail'),type:'email',label:'Rep Email.'},outputState)}
                        </Grid>
                    </Item>
                    <Item>
                        <Grid item xs={2} sx={{padding:'0.5%', width: '99%', margin: 'auto'}}> 
                            {dynamicComponent('InputField','elements',{state:'Notes',defaultValue:latestState('Notes'),label:'Add any Vendor Notes'},outputState)}
                        </Grid>
                    </Item>
                </Grid>
    }}))
  },[vendorMap.get(compID)])

  return [stateRef.current.Vendor, <TaskManager That={mainState}/>]
}
export default AddVendor