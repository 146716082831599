import React, { useEffect, useRef } from "react";
import { useAtom } from 'jotai';
import useState from 'react-usestateref';

import { Box, TextField, Paper, List, ListItem, ListItemText, Divider, Accordion, AccordionDetails ,AccordionSummary, Typography, Drawer, IconButton, InputBase, ListItemIcon, ListSubheader, ListItemButton, Collapse } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import {ExpandMore,ExpandLess,Cancel} from '@mui/icons-material';
import SegmentIcon from '@mui/icons-material/Segment';
import RefreshIcon from '@mui/icons-material/Refresh';

  export default function UserButton(props){
  const {That,viewData,action,globalState,dynamicComponent,doesIDExist} = props
  const {label, defaultValue, state, pramPass, buttonBypass, disabled, style} = viewData
  const [teamMember] = useAtom(globalState.teamMemberState)
  const [, setParentState] = That
  const mainState = useState({})
  const [, setState, {current:stateRef}] = mainState

  const stateChgMap=(val)=>{ 
    setState(p=>({...p,drawerState: false }))
  setParentState(p=>({...p,[state]:val}))
  if(action) action.forEach((x)=>x({[state]:val,pramPass:pramPass})) 
  }

  useEffect(()=>{
    if(defaultValue){ 
    var defultUser = teamMember.get(defaultValue)
      setState(p=>({...p,
        avatar: defaultValue,
        fullName: `${defultUser?.firstName} ${defultUser?.lastName}`,
        avatBubble: dynamicComponent('AvatarBubble','elements',{state:'projLead',Catagory:'Team',label:'missing Lead',userID:defaultValue},mainState)
         })) 
      }
  },[defaultValue])

  useEffect(()=>{
    if(buttonBypass) setState(p=>({...p,drawerState: true }))
  },[])

return [ !buttonBypass && <Box onMouseDown={()=>{}} onClick={()=>{ setState(p=>({...p,drawerState: true })) }} sx={{ display: 'flex', alignItems: 'flex-end', m:'2%',...style }}>
       { stateRef.avatar ? <Box style={{marginRight:'4%'}}>{stateRef.avatBubble}</Box> : <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} /> }
       <TextField disabled={true} sx={{width:'100%',pointerEvents:'none'}}  id={state} label={label} InputLabelProps={{ shrink: stateRef.fullName ? true: false }} value={stateRef.fullName} variant="standard" />
      </Box>,
      (!disabled || disabled !== true) && <Drawed {...props} That={mainState} action={(x)=>{ setState(p=>({...p,drawerState: !stateRef.drawerState, fullName: `${teamMember.get(x.data).firstName} ${teamMember.get(x.data).lastName}`, avatar: x.data })); stateChgMap(x.data); }} /> 
      ]
  }
 
function Drawed(props){
  const {That,globalState} = props
  const [, setParentState, {current: parentStateRef}] = That
  const [, setState, {current:stateRef}] = useState({})
  const [divisionMap] = useAtom(globalState.divisionMapState)
  const [teamMember] = useAtom(globalState.teamMemberState)

  useEffect(()=>{
    var chapters = Array.from([...divisionMap],([k,v])=>v.teamName)
    if(chapters) var list = Object.assign(...chapters.flatMap(x=>({[x]:[].concat(...Array.from([...teamMember],([k,v])=>[{[k]:v}].filter(()=>divisionMap.get(v.division)?.teamName === x).map(()=>k)))})))
    setState(p=>({...p,chapters,list}))
  },[teamMember])
 

return <Drawer anchor={'right'} open={parentStateRef.drawerState || false} onClose={()=> setParentState(p=>({...p,drawerState: false })) } >
         <Accod {...props} list={stateRef.list} chapters={stateRef.chapters} />
         <SearchBar {...props} list={stateRef.list} chapters={stateRef.chapters} />
        </Drawer>
}


function Accod(props){
  const {That,viewData:{divisionName},chapters,list} = props
  const [, setParentState] = That
  const [expanded, setExpanded] = React.useState(false)
  const accordion = useRef(null)
  const handleChange = (panel) => (event, isExpanded) => setExpanded(isExpanded ? panel : false)

 return <Box
      sx={{width:'100%'}}
      role="presentation"
    >  <Box ref={accordion} style={{padding:'2%'}}> {
    chapters.flatMap((x,i)=>
    <Accordion expanded={expanded === `panel${i}` || (expanded===false && divisionName === x)} onChange={handleChange(`panel${i}`)} >
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1bh-content" id={`panel${i}bh-header`} >
        <Typography sx={{ width: '33%', flexShrink: 0 }}> {x?.toUpperCase()||'Uncategorized'} </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{padding:0}}>
        { list[x]?.flatMap(y=> <Listed {...props} userID={y} />) }
        </AccordionDetails>
      </Accordion>
) }</Box> </Box> 
}

function Listed({That,action,globalState,dynamicComponent,userID}){
  const [, , parentStateRef] = That
  const [, setState, stateRef] = useState(Object)
  const [teamMember] = useAtom(globalState.teamMemberState)

  if(teamMember.has(userID)) var fullName = `${teamMember.get(userID).firstName} ${teamMember.get(userID).lastName}`

useEffect(()=>{
setState(p=>({...p,
user: <List sx={{padding:0}} key={'listed'+userID}>
          <ListItem button key={'itemName'+userID} sx={{flexWrap: 'wrap',maxWidth: '65vw'}} onClick={()=>action({type:'Select',data:userID}) } >
              { userID && <ListItemIcon>{ dynamicComponent('AvatarBubble','elements',{state:'projLead',Catagory:'Team',label:'missing Lead',userID:userID},That) }</ListItemIcon> }
             <ListItemText primary={<Typography>{fullName}</Typography>}/>
          </ListItem>
          </List>
    }))
  },[parentStateRef.current])

return stateRef.current.user
}


export function SearchBar({That,list,action,globalState,dynamicComponent,waveLoad,dynamicMapData,queryDB}) {
  const [teamMember,setTeamMember] = useAtom(globalState.teamMemberState)
  const [, setState, stateRef] = That
  const [open, setOpen] = useState(true);
  const module = useRef(null)
  const handleClick = () => {
    setOpen(!open);
  };


   const getAllMembers=async()=>{
    setState(p=>({...p,refreshActive: true}))
    queryDB({ model: 'User', query: {}}, (items, err) => {
      const newArr = Object.assign({}, ...items.flatMap(({_id,local})=>({[_id]:local})))
      dynamicMapData(newArr, teamMember, setTeamMember, "teams")
      setState(p=>({...p,refreshActive: false}))
    })
  }

  return <Paper component="form" variant="outlined" sx={{ p: '2px 4px',m:'1% 1% 0', display: 'flex', position:'sticky', bottom: '.5%', flexDirection:'column', alignItems: 'center', width: '97%', zIndex:9999 }} >
      <Box sx={{display: 'flex',w:'100%'}}>
      <Box>
       <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <InputBase
        ref={module}
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search"
        inputProps={{ 'aria-label': 'search' }}
        onChange={({target})=> setState(p=>({...p, search: target.value?.length>0 ? target.value : undefined })) }
      />{stateRef?.current?.refreshActive === true ? waveLoad() : <IconButton onClick={getAllMembers}><RefreshIcon/></IconButton>}</Box>
     { stateRef.current.search &&
      [<Divider variant="middle" flexItem orientation="vertical" />,
      <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
        <Cancel onClick={()=>{ module.current.querySelector('input').value = ''; setState(p=>({...p, search: undefined })) }} />
      </IconButton>] }
      </Box>
      { stateRef.current.search && [<Divider />,
      <List
      sx={{ width: '100%', bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader id="nested-list-subheader">
         { stateRef.current.search }
        </ListSubheader>
      }
    >
     { list && Object.entries(list)
     .filter(([k,v],i)=> v.some((userID)=> teamMember.has(userID) && Object.values(teamMember.get(userID))?.filter(v=>v?.constructor === String)?.map(v=>v?.toLowerCase())?.some(x=>x?.startsWith(stateRef.current.search?.toLowerCase()))))
     .flatMap(([k,v],i)=>{

    return [<ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <SegmentIcon />
        </ListItemIcon>
        <ListItemText primary={k} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>,
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List disablePadding sx={{pl:'15%'}}>
        {
        v.filter((userID)=> teamMember.has(userID) && Object.values(teamMember.get(userID))?.filter(v=>v?.constructor === String)?.map(v=>v?.toLowerCase())?.some(x=> x?.startsWith(stateRef.current.search?.toLowerCase())) )
        .flatMap((userID,i)=>{
        var {firstName, lastName} = teamMember.get(userID)
        var fullName = firstName+' '+lastName
        return <ListItem button key={'itemName'+userID} sx={{flexWrap: 'wrap',maxWidth: '65vw'}} onClick={()=>action({type:'Select',data:userID})} >
              { userID && <ListItemIcon>{ dynamicComponent('AvatarBubble','elements',{state:'projLead',Catagory:'Team',label:'missing Lead',userID:userID},That) }</ListItemIcon> }
            <ListItemText primary={<Typography>{fullName}</Typography>} />
          </ListItem>
        }) }
        </List>
      </Collapse>]
     }) }
    </List>] }
    </Paper>
}