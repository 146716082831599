import React, { useEffect,Suspense } from "react";
import useState from 'react-usestateref';
import { useAtom } from 'jotai';
import dayjs from 'dayjs';
import { extendTheme } from '@mui/joy/styles';
import {Divider} from '@mui/material';
import { StyledEngineProvider, CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import Chip from '@mui/joy/Chip';
import Avatar from '@mui/joy/Avatar';
import { TextareaAutosize } from '@mui/base';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemButton from '@mui/joy/ListItemButton';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/joy/Typography';
import Sheet, { sheetClasses } from '@mui/joy/Sheet';
import Switch from '@mui/joy/Switch';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import Slider from '@mui/joy/Slider';
import {Box} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import Button from '@mui/joy/Button';
import AvatarGroup from '@mui/joy/AvatarGroup';
import Input from "@mui/joy/Input";
import BackupIcon from '@mui/icons-material/Backup';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import TaskManager from '../FileManager/TaskManager';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import HardwareIcon from '@mui/icons-material/Hardware';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';

import Card from '@mui/joy/Card';
import AspectRatio from '@mui/joy/AspectRatio';
import CardContent from '@mui/joy/CardContent';

import { CircularProgress } from '@mui/material';

import mainconfig from '../Data/Config';

var isToday = require('dayjs/plugin/isToday')
dayjs.extend(isToday)

 const EODform=({That,viewData,globalState,dynamicComponent,globalWrn,waveLoad,handlePopupClose,dbUpdateValidate,newNotification,imageCompress,projectEOD,queryDB})=>{
  const [projectMap] = useAtom(globalState.projectMapState)
  const [divisionMap] = useAtom(globalState.divisionMapState)
  const [teamMember] = useAtom(globalState.teamMemberState)
  const [popupData, setPopup] = useAtom(globalState.popupState)
  const [UserMap] = useAtom(globalState.userMapState)
  const [appState] = useAtom(globalState.appState)
  const [inventoryMap] = useAtom(globalState.inventoryMapState)
  const {state,projID,ProjData,projLabel,handleRemove,reSubmission, eodID} = viewData
  const { ProjectID, DivisionID, modelType } = projID
  const [, setParentState] = That
  const mainState = useState(Object)
  const [, setState, stateRef] = mainState
  const outputState = useState(Object)
  const [, setOutput, outputRef] = outputState
  const uploadFilesState = useState(Object)
  const [, setUploadFiles, uploadFilesRef] = uploadFilesState
  const [, setUploadTempFiles, uploadTempFilesRef] = useState(Object)
  const [, setProjectItems, projectItemsRef] =  useState(Object)
  const ProjectData = projectMap.get(ProjectID)
  const { mode, setMode } = useColorScheme();
  const [radius] = useState(16);
  const [childHeight] = useState(32);
  const PhaseOption = projectMap.get( ProjectID )?.SalesState?.PhaseOption ? projectMap.get( ProjectID )?.SalesState?.PhaseOption : false
  const excluded = ['Photos/Videos']
  const clockin = ProjectData?.ClockInOut?.some(({Clockin})=>Clockin && dayjs(Clockin).isToday())
  const clockout = ProjectData?.ClockInOut?.some(({Clockout})=> Clockout && dayjs(Clockout).isToday())

  var dataSats = {
    projID:{ 
      ProjectID: ProjectData?._id, 
      DivisionID: ProjectData?.DivisionID,
      status: ProjectData?.Status, 
      paused: ProjectData?.ProjectState?.Paused?.some(x=> !x.endDate && x.startDate)
    },
    ProjectTitle: ProjectData?.ProjectTitle,
    PhaseOption: ProjectData?.SalesState?.PhaseOption || false,
    EOD: ProjectData?.ProjectState?.EODLogg?.some(x=>x.date && dayjs(x.date).isToday()),
    members: ProjectData?.ProjectState?.Members?.length>0 ? [...ProjectData.ProjectState?.Members.flatMap(x=>({[x.id]:x.userFilter}))] :[],
    managers: { 
      created: ProjectData?.Created, 
      estimator: ProjectData?.SalesState?.estimator, 
      projManager: ProjectData?.ProjectState?.projManager, 
      teamLead: ProjectData?.ProjectState?.teamLead 
    },
    projectTasks: ProjectData?.SalesState?.PhaseOption ? ProjectData?.SalesState?.PhaseFilter : ProjectData?.SalesState?.OptionsFilter,
    UnitCount: ProjectData?.SalesState?.UnitCount,
    Inventory: 'Not Set Yet',
    Deliveries: 'Not Set Yet',
    Production: {
      PhaseOption: ProjectData?.SalesState?.PhaseOption || false,
    }
  }

  function uploadChecker(){
    stateRef.current.getFilesList(`/EKC/Projects/${ProjectID}/EOD/${dayjs().format('ddd, MMM D, YYYY')}`).then(({data})=>{ 
      if(data?.children) setState(p=>({...p,uploaded : data?.children }))
      })
  }

  useEffect(()=>{ 
    uploadChecker()
    if(mode!=='dark')setMode('dark')
   globalState.userStore.getItem(`eodForm_${ProjectID+dayjs().format('MMM Do YY')}`).then(x=>{
    if(x) setOutput(x)
   })
  },[])

  useEffect(()=>{
  Array.from(inventoryMap.values(),(x)=>{
   if(x?.ProjectID?.includes(ProjectID)){ 
    setProjectItems(p=>({...p,...{[x._id]:x}}))
  }
  })
  },[inventoryMap,appState.get('inventorySet')])

  useEffect(()=>{
    if( outputRef.current ) globalState.userStore.setItem(`eodForm_${ProjectID+dayjs().format('MMM Do YY')}`,outputRef.current)
  },[outputRef.current])

const fileUploader = async()=>{
   await stateRef.current.createNewFolder(`/EKC/Projects/${ProjectID}`,'EOD')
   await stateRef.current.createNewFolder(`/EKC/Projects/${ProjectID}/EOD`, dayjs().format('ddd, MMM D, YYYY') )
  Object.entries(uploadFilesRef.current).forEach(([k,v])=>{ 
  var formData = new FormData();
  formData.append('path', `/EKC/Projects/${ProjectID}/EOD/${dayjs().format('ddd, MMM D, YYYY')}`);
  formData.append('files', v.data, v.name.replace(".",`( ${v.type+'_'+v.index} ).`));
  stateRef.current.uploadFile(formData)
  })
}

const tempfileUploader =()=>{
  stateRef.current.createNewFolder(`/EKC/Projects/${ProjectID}`,'EOD')
  stateRef.current.createNewFolder(`/EKC/Projects/${ProjectID}/EOD`, dayjs().format('ddd, MMM D, YYYY') )

  var path = `./EKC/Projects/${ProjectID}/EOD/${dayjs().format('ddd, MMM D, YYYY')}`
  Promise.all(Object.entries(uploadTempFilesRef.current?.[ProjectID]).flatMap(([k,{data,name,index}])=>{
    imageCompress({image:data,writePath:path,fileName:name.replace(".",`( ${k+'_'+index} ).`)},({sucess,err})=>{
      if(sucess===true){
        globalWrn('success',`Your File was Uploaded.`)
        uploadChecker()
      }
      else globalWrn('error',`There was an error uploading your file(s).`)
    })
  })).then(()=>{
    setUploadTempFiles({})
    handlePopupClose()
  })
}


const emailManagers=(ProjectID)=>{            
  var CBManagers= ['60f5bfd9d693ffca1fe98624', '621fc091a33d2e33e4eec4ac']
  var SuppManagers = ['620a92efa33d2e33e4315cec','63f4faca777f6ad0d02f8f21']
  var emailTo
  if(divisionMap.get(projectMap.get(ProjectID).DivisionID).category === "ChromeBook") emailTo = CBManagers
  else if(divisionMap.get(projectMap.get(ProjectID).DivisionID).category === "Construction") emailTo = SuppManagers
  else return null

  var email = []
  for(var i = 0; i < emailTo.length; i++)
  {
    email.push(teamMember.get(emailTo[i]).email)
  }
  var name_u = `${UserMap.get('firstName')} ${UserMap.get('lastName')}`
  var body = `For Project: ${projectMap.get(ProjectID).ProjectTitle}, ${UserMap.get('firstName')} ${UserMap.get('lastName')} urgently needs: ${outputRef.current?.[ProjectID]?.materialNeeded}`
  newNotification({Title:`${UserMap.get('firstName')} ${UserMap.get('lastName')} urgently requests materials`,Body:body,Data:null,Avatar:null,Icon:null,Choice:{AppNotification:true,Text:false,Email:true},Email:email,name:name_u,sender:UserMap.get('avatar'),receiver:emailTo})
}

  const EODupload = ()=>{
    setState(p=>({...p,...{loading : true }}))
    if(uploadFilesRef.current?.[ProjectID]) var files = Object.keys(uploadFilesRef.current?.[ProjectID]).length>0
    if(reSubmission)
    {
      var submitEOD = {'ProjectState.EODLogg[missingEOD]': {...outputRef.current?.[ProjectID],date:dayjs(),user:UserMap.get('avatar')} }

      var arrayFilters =  [{"el.date": {$in: [reSubmission]}}]
      var output = {'ProjectState.EODLogg.$[el]': {...outputRef.current?.[ProjectID],date:dayjs(),user:UserMap.get('avatar'),Warned:false} }
      dbUpdateValidate({model:'Projects',modelType,queryID:true,query:ProjectID,update:output,arrayFilters:arrayFilters},({success,info,err})=>{ 
        setState(p=>({...p,...{loading : false }}))
        if(success){
           handleRemove(projLabel)
           globalWrn('success',`You have successfully updated ${ProjectData.ProjectTitle}.`)
        }
        if(err) globalWrn('error',`there was an error updating ${ProjectData.ProjectTitle}.`)
        })

    }
    else
    {
      var submit = {'ProjectState.EODLogg': {...outputRef.current?.[ProjectID],date:dayjs(),user:UserMap.get('avatar')} }

      projectEOD({projID,submitObj:submit},({success})=>{
        setState(p=>({...p,...{loading : false }}))
        if(success===true){                              
        handleRemove(ProjectID)
        globalWrn('success',`Project ${ProjectData?.ProjectTitle}: EOD Report Submitted.`)
          var mobile = teamMember.get(ProjectData?.ProjectState?.projManager)?.mobilePhone?.replace(/[^0-9]/g,'')?.slice(-10)
          var name_u = `${teamMember.get(ProjectData?.ProjectState?.projManager)?.firstName} ${teamMember.get(ProjectData?.ProjectState?.projManager)?.lastName}`
          var body = `${UserMap.get('firstName')} ${UserMap.get('lastName')} has submitted an EOD for project: ${ProjectData?.ProjectTitle}`
          var SuppManagers = ['620a92efa33d2e33e4315cec','63f4faca777f6ad0d02f8f21']
          
          var cats = divisionMap.get(ProjectData.DivisionID).teamName.split(' ')
          if(cats.includes('Support')) SuppManagers.forEach(x=>{
            newNotification({Title:`${UserMap.get('firstName')} ${UserMap.get('lastName')} has submitted an EOD.`,Body:body,Data:null,Avatar:null,Icon:null,Choice:{AppNotification:true,Text:false,Email:true},mobile:mobile,Email:teamMember.get(x)?.email,name:name_u,sender:UserMap.get('avatar'),receiver:x}) 
          })
        fileUploader()
        }else globalWrn('error',`There was an error updating project ${ProjectData?.ProjectTitle}.`)
        setState(p=>({...p,...{loading : false }}))
      })
    }
    
  }

  useEffect(()=>{if(mode!=='dark')setMode('dark')},[])

  useEffect(()=>{ 
    if(outputRef.current) setParentState(p=>({...p,...{[state]: p[state] ? {...p[state], ...outputRef.current} : outputRef.current }}))
  },[outputRef.current])

  useEffect(()=>{
    var notesSubmit = outputRef.current?.[ProjectID]?.notes && outputRef.current[ProjectID].notes.length>0

  if( PhaseOption ){
    var phaseSub = projectMap.get( ProjectID )?.SalesState?.PhaseFilter?.filter(({Options})=>Options)?.some(({label:header,Options},i)=> Object.entries(Options).some(([k,v2])=> Object.entries(v2).some(([k,{label}],i)=>outputRef.current?.workBreakdown?.[header]?.some(({type})=>type===label) && uploadFilesRef.current?.[header]?.[label+'1'])))
    if( (phaseSub && notesSubmit) || notesSubmit ) setState(p=>({...p,...{
      submitBttn: <Button size="lg" disabled={stateRef.current.loading===true&&true} startDecorator={<BackupIcon/>} sx={{position:'sticky',bottom:'5px',left: 0}} onClick={EODupload}>Submit Report{stateRef.current.loading===true&&<CircularProgress sx={{padding:'1%'}}/>}</Button>
    }}))
    else if(stateRef.current.submitBttn) setState(p=>({...p,...{ submitBttn: undefined }}))
  }else{
    var chmSubmit = dataSats?.projectTasks?.constructor === Object && ( Object.keys(dataSats?.projectTasks)?.every(x=> outputRef.current?.workBreakdown?.map(y=>y.type).includes(x)))
    var conSubmit = dataSats?.projectTasks?.constructor === Object && divisionMap.get(DivisionID)?.category !== "ChromeBook" && Object.entries(dataSats?.projectTasks)?.every(([k,v])=> v ? v?.every(x=> outputRef.current?.workBreakdown?.map(y=>y.type).includes(x.label)) : false )
    if( chmSubmit || conSubmit || notesSubmit ) setState(p=>({...p,...{
      submitBttn: <Button size="lg" disabled={stateRef.current.loading===true&&true} startDecorator={<BackupIcon/>} sx={{position:'sticky',bottom:'5px',left: 0}} onClick={EODupload}>Submit Report{stateRef.current.loading===true&&<CircularProgress sx={{padding:'1%'}}/>}</Button>
    }}))
    else if(stateRef.current.submitBttn) setState(p=>({...p,...{ submitBttn: undefined }})) 
  }
  
  },[outputRef.current,uploadFilesRef.current,stateRef.current.loading])

  const projectList = (items) => {
    if(ProjectData?.SalesState?.OptionsFilter && ProjectData?.SalesState?.OptionsFilter?.constructor === Object) var listFilter = ProjectData.SalesState.OptionsFilter
     var data = Object.keys(Object.assign({},...Object.entries(items)
    .filter(([k, v],i) => listFilter ? Object.entries(listFilter).some(([k3,v3])=> k3==k) : false )
    .map(([k, v],i) =>
    listFilter ? Object.entries(listFilter).some(([k3,v3])=>k3==k) ? 
    {[k]: Object.entries(v)
    .filter(([k2, v2],i2) => Object.entries(listFilter).some(([k3,v3])=> k==k3 ? Object.entries(v3).some(([k4,v4])=> Number(k4)==i2): false) )
    .map(([k2, v2],i2) =>({label: v2.label}) )
    } 
    : {[k]:false}
    : {[k]:false}
    )) )
    try {
    return [...data,...Object.keys(items[1])]
    }
    catch(err) {
    return data
    }
    }

  function addUser(){
    var divisionCat = divisionMap.get(DivisionID)?.category
    var divisionName = divisionMap.get(DivisionID)?.teamName
    
    queryDB({model:'AdminOrg',query:{'fieldSet':`${divisionCat}Services`},filter:{'_id':0,'fieldSet':0,'Number':0}},(items,err)=>{ 
      if(items?.length>0){ 
      var chapters = Array.from([...divisionMap],([k,v])=>v?.teamName)
      var list = Object.assign(...chapters.flatMap(x=>({[x]:[].concat(...Array.from([...teamMember],([k,v])=>[{[k]:v}].filter(()=>divisionMap.has(v?.division) && divisionMap.get(v.division)?.teamName === x).map(()=>v.avatar)))})))
      setParentState(p=>({...p,...{
        Drawer: dynamicComponent('UserSelect','elements',{state:`Members`,label:'Associates',defaultValue: ProjectData.ProjectState.Members, manualOpen:true,projID,divisionName:divisionName,projTitle:dataSats?.ProjectTitle, projList:projectList(items[0]) ,chapters:Array.from([...divisionMap],([k,v])=>v?.teamName),list:list},outputState,[({Members})=>{
            setParentState(p=>({...p,...{Drawer:undefined}}))
          }]),
        }}))
      }
    })
}

function usedRes(){
  var items = [
    {id:'InputField',loc:'elements',viewData:{state:'label',label:'Material Used',type:'text'}},
    {id:'InputField',loc:'elements',viewData:{state:'addAmmount',label:'Add Ammount',type:'number'}},
  ]
  var lists = dynamicComponent('Listable','elements',{state:'addMaterial',label:'+ Add',items:items,defaultValue:outputRef.current?.[ProjectID]?.addMaterial,required:false},mainState,[(x)=>{
    if(x.addMaterial) setOutput(p=>({...p,...{[ProjectID]:{...p[ProjectID],'addMaterial': x.addMaterial.filter(x=> ['label','addAmmount'].every(y=> Object.keys(x).includes(y))) }}}))
  }])

  setPopup(p=>({...p,...{
    open:true,
    title:`Add Materials Used`,
    description: `You can add only add products outside of scope`,
    inputField: lists,
    handleClose: handlePopupClose,
    cancelLabel: 'Close',
    nameInputSets: {},
}}))
}

  function addFiles(){
    var items = [ {id:'InputField',loc:'elements',viewData:{state:'label',label:'Photo/Video Of (REQUIRED)',type:'text'}}, {id:'Dropzone',loc:'elements',viewData:{state:'addFile',label:'Photo/Video'}} ]
    var lists = dynamicComponent('Listable','elements',{state:'addFiles',label:'+ Add',items:items,defaultValue:null,required:false},mainState,[(x)=>{
      if(x.addFiles) x.addFiles.forEach(({label,addFile})=>{ if(label && addFile){
        addFile.forEach((x,i)=> setUploadTempFiles(p=>({...p,...{[ProjectID]:{...p[ProjectID],[label+'_'+i]:{type:'AddFile', index:i, name:x.name, fileType:x.type, data:x}}}})))
        if(!popupData.handleSubmit)setPopup(p=>({...p,...{
          submitLabel: 'Upload',
          handleSubmit: tempfileUploader,
        }}))
      }
       })
      
    }])
    setPopup(p=>({...p,...{
      open:true,
      title:`Add Photos/Videos `,
      description: `A label is required, Max file size is 10MB`,
      inputField: lists,
      handleClose: handlePopupClose,
      cancelLabel: 'Cancel',
      nameInputSets: {},
  }}))
}

const simp = (t, path) => path.split(".").reduce((r, k) => r?.[k], t)
function latestState(x){
  return outputRef.current[x] || simp(ProjectData,x)
}

useEffect(()=>{ setState(p=>({...p,...{ 
  returnDate: dynamicComponent('DatePicker','elements',{state:'return',label:'Return Date & Time', defaultValue: 'Return Date Needed', min: dayjs().startOf('day'), max: latestState('SalesState.rfpDue.calendar') ? latestState('SalesState.rfpDue.calendar') : null },outputState)
}})) },[])

function perc2color(perc) {
	var r, g, b = 0;
	if(perc < 50) {
		r = 255;
		g = Math.round(5.1 * perc);
	}
	else {
		g = 255;
		r = Math.round(510 - 5.10 * perc);
	}
	var h = r * 0x10000 + g * 0x100 + b * 0x1;
	return '#' + ('000000' + h.toString(16)).slice(-6);
}

const clockInOut = () =>{
 if(!clockin) dbUpdateValidate({model:'Projects',modelType,queryID:true,query:ProjectID,update:{ $push: { 'ClockInOut':{Clockin:dayjs()}}}},({success,info,err})=>{ 
    if(success) globalWrn('success',`You have successfully updated ${ProjectData.ProjectTitle}.`)
    if(err) globalWrn('error',`there was an error updating ${ProjectData.ProjectTitle}.`)
    })
 else dbUpdateValidate({model:'Projects',modelType,queryID:true,query:ProjectID,update:{ 'ClockInOut.$[el].Clockout': dayjs() },arrayFilters:[{"el._id": {$in: [ProjectData?.ClockInOut?.find(({Clockin})=>Clockin&&dayjs(Clockin).isToday())._id]}}]},({success,info,err})=>{ 
  if(success) globalWrn('success',`You have successfully updated ${ProjectData.ProjectTitle}.`)
  if(err) globalWrn('error',`there was an error updating ${ProjectData.ProjectTitle}.`)
  })
}

const projTrackerStats = ({group,label})=>({
  taskToday: dataSats?.Production?.[`proj${PhaseOption?'Phase':''}CompleteByTaskToday`]?.[group] | 0, 
  taskDone: dataSats?.Production?.[`proj${PhaseOption?'Phase':''}CompleteByTask`]?.[group] | 0,
  taskTotal: dataSats?.projectTasks?.[group]?.find(x=>x.label===label)?.units ? parseInt(dataSats?.projectTasks?.[group]?.find(x=>x.label===label)?.units,10) : dataSats?.UnitCount
})

const BuildOut=({label,units,phase,group})=>{
if(phase){
  var item = outputRef.current?.workBreakdown?.[phase]?.some(({type})=>type===label)
  var valAm = outputRef.current?.workBreakdown?.[phase]?.find(({type})=>type===label)?.ammount
  var merVal = (val)=>setOutput(p=>({...p,...{ workBreakdown: {...p.workBreakdown,[phase]: p.workBreakdown?.[phase] ? [].concat(p.workBreakdown[phase],val): val} }}))
  var updVal = (val)=> setOutput(p=>({...p,...{ workBreakdown: {...p.workBreakdown,[phase]: p.workBreakdown?.[phase]?.map(x => {
    if(x.type===label) return {...x, ammount: parseInt(val,10)}
    return x
  }) }
}}))
} else {
  var item = outputRef.current?.workBreakdown?.some(x=>x.type===label)
  var valAm = outputRef.current?.workBreakdown?.find(x=>x.type===label)?.ammount
  var merVal = (val)=>setOutput(p=>({...p,...{workBreakdown: p.workBreakdown ? [].concat(p.workBreakdown,val): val }}))
  var updVal = (val)=> setOutput(p=>({...p,...{workBreakdown: p.workBreakdown.map(x => {
    if(x.type===label) return {...x, ammount: parseInt(val,10)}
    return x
  })
}}))
}

return [ (units ? parseInt(units, 10) < 50 : dataSats?.Production?.total < 50) ? [
       <Box sx={{display:'flex',justifyContent:'space-between'}}>
         <Sheet variant="solid" color={item?"success":"warning"} sx={{m:'auto 0'}} >
           {item?<TaskAltOutlinedIcon/>:<RadioButtonUncheckedOutlinedIcon />}
         </Sheet>
         <Typography variant="body1">{label}</Typography>
         {item && <Chip disabled={false} onClick={function(){}} size="md" variant="outlined">
             <Typography variant="caption" sx={{whiteSpace:'pre-wrap'}}>{ valAm||0 }</Typography>
             </Chip>}
         </Box>,
         <Box sx={{display:'flex',justifyContent:'space-between'}}>
       { <Slider
         aria-label={label}
         value={item ? valAm : projTrackerStats({group,label}).taskToday}
         onChange={(e,v)=>{ if(v>=0){ 
           var val = [{type:label,ammount:parseInt(v,10),category:group}]
           if(item) updVal(v)
           else merVal(val)
           } }}
         step={1}
         marks
         min={0}
         max={ projTrackerStats({group,label})?.taskTotal && projTrackerStats({group,label})?.taskDone ? projTrackerStats({group,label})?.taskTotal - projTrackerStats({group,label})?.taskDone : 100}
         valueLabelDisplay="auto"
         /> }
         <Box sx={{display:'flex',justifyContent:'space-evenly',m:'auto',gap:'6%'}}>
  <Sheet sx={{maxHeight:'46px',minWidth:'46px',maxWidth:'46px',m:'auto 0'}} color="success" aria-label="Plus" onClick={()=>{ 
     var newNumb = item ? valAm +1 : 1
     var val = [{type:label,ammount: newNumb,category:group }]
     if(item) updVal(newNumb)
     else merVal(val)
     }}>
       <AddCircleIcon sx={{ fontSize: 46 }} />
       </Sheet>
       <Sheet sx={{maxHeight:'46px',minWidth:'46px',maxWidth:'46px',m:'auto 0'}} color="danger" aria-label="Minus" onClick={()=>{
         var newNumb = item && valAm>=1 ? valAm -1 : 0
         var val = [{type:label,ammount: newNumb,category:group}]
         if(item) updVal(newNumb)
         else merVal(val)
       }}>
       <RemoveCircleIcon sx={{ fontSize: 46 }} />
       </Sheet>
     </Box>
    </Box>   
     ]
 
 : <Box sx={{display:'flex',justifyContent:'space-between',flexWrap:'wrap',columnGap:'8px'}}>
       <Sheet variant="solid" color={item?"success":"warning"} sx={{m:'auto 0'}} >
           {item?<TaskAltOutlinedIcon/>:<RadioButtonUncheckedOutlinedIcon />}
       </Sheet>
    <Input
    onChange={({target})=>{ 
      if(target.value.length===0) target.value = 0
      if( target.value > (projTrackerStats({group,label})?.taskTotal - projTrackerStats({group,label})?.taskDone)) target.value = projTrackerStats({group,label})?.taskTotal - projTrackerStats({group,label})?.taskDone
      if(target.value>=0){ 
     var val = [{type:label,ammount:parseInt(target.value,10),category:group}]
     if(item) updVal(target.value)
     else merVal(val)
     } }}
    sx={{margin:'auto',flex:1,maxWidth:'280px',"& .JoyInput-endDecorator": {maxWidth:'60%',p:'1%',display:'flex',justifyContent:'flex-end'}}}
    type={"number"}
    InputProps={{
      inputProps: { 
          max: projTrackerStats({group,label})?.taskTotal - projTrackerStats({group,label})?.taskDone, 
          min: 0 
      }
    }}
    value={ item ? valAm : projTrackerStats({group,label}).taskToday }
    placeholder={dataSats?.Production?.projCompleteByTaskToday?.[label]||0}
    startDecorator={<TaskAltOutlinedIcon/>}
    endDecorator={
      <Chip size="sm" variant="soft" >
        <Typography variant="caption" sx={{whiteSpace:'pre-wrap',fontSize:'12px',lineHeight:1.2,textAlign:'center'}}>{label}</Typography>
        <Typography variant="caption" sx={{whiteSpace:'pre-wrap',fontSize:'10px',lineHeight:1,textAlign:'center'}}>{projTrackerStats({group,label})?.taskDone} of {projTrackerStats({group,label})?.taskTotal}</Typography>
      </Chip>
    }
  />
  <Box sx={{display:'flex',justifyContent:'space-between',m:'auto',mr:0}}>
  <Sheet sx={{maxHeight:'46px',minWidth:'46px',maxWidth:'46px',margin:1}} color="success" aria-label="Plus" onClick={()=>{ 
     var newNumb = item ? valAm +1 : 1
     if( newNumb > (projTrackerStats({group,label})?.taskTotal - projTrackerStats({group,label})?.taskDone)) newNumb = projTrackerStats({group,label})?.taskTotal - projTrackerStats({group,label})?.taskDone
     var val = [{type:label,ammount: newNumb,category:group }]
     if(item) updVal(newNumb)
     else merVal(val)
     }}>
       <AddCircleIcon sx={{ fontSize: 46 }} />
       </Sheet>
       <Sheet sx={{maxHeight:'46px',minWidth:'46px',maxWidth:'46px',margin:1}} color="danger" aria-label="Minus" onClick={()=>{
         var newNumb = item && valAm>=1 ? valAm -1 : 0
         var val = [{type:label,ammount: newNumb,category:group }]
         if(item) updVal(newNumb)
         else merVal(val)
       }}>
       <RemoveCircleIcon sx={{ fontSize: 46 }} />
       </Sheet>
     </Box>
   </Box>,
   (divisionMap.get(DivisionID)?.category !== "ChromeBook" && valAm>0 && item) && <Box sx={{display:'flex',justifyContent:'space-evenly', flexWrap:'wrap', gap: 1}}>{ [...Array(valAm)]?.map((_,i) =>  
    { 
     var indexer = i+1
     if(phase) var validImage = uploadFilesRef.current?.[phase]?.[label+indexer]
     else validImage = uploadFilesRef.current?.[ProjectID]?.[label+indexer]

     return [<input
       key={'input'+indexer}
       style={{ display: "none" }}
       id={"contained-button-file"+label+indexer}
       type="file"
       onChange={({target}) => {
        if(phase) setUploadFiles(p=>({...p,...{[phase]:{...p[phase],[label+indexer]:{type: label, index:indexer, name:target.files[0].name, fileType:target.files[0].type, data:target.files[0]}}}}))
        else setUploadFiles(p=>({...p,...{[ProjectID]:{...p[ProjectID],[label+indexer]:{type: label, index:indexer, name:target.files[0].name, fileType:target.files[0].type, data:target.files[0]}}}}))
        }}
     />,
     <label htmlFor={"contained-button-file"+label+indexer} key={'bttn'+indexer}>
     <Button startDecorator={uploadFilesRef.current?.[validImage]?.[label+indexer]?<CloudDoneIcon/>:<CloudUploadOutlinedIcon />} variant="soft" color={validImage?"success":"warning"} component="span">
       Pic {indexer}
     </Button>
     </label>]
     })} </Box> ];
 } 
 const projMarks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 25,
    label: '25%',
  },
  {
    value: 50,
    label: '50%',
  },
  {
    value: 75,
    label: '75%',
  },
  {
    value: 100,
    label: '100%',
  },
];


return <Suspense fallback={waveLoad()}>
  <StyledEngineProvider injectFirst>
    <TaskManager That={mainState}/>
   <Sheet variant="soft" sx={{ p:'1%', borderRadius: 'sm' }}>
    { projLabel ? <List
      aria-labelledby="ios-example-demo"
      sx={(theme) => ({
        '& ul': {
          '--List-gap': '0px',
          bgcolor: 'background.surface',
          '& > li:first-of-type > [role="button"]': {
            borderTopRightRadius: 'var(--List-radius)',
            borderTopLeftRadius: 'var(--List-radius)',
          },
          '& > li:last-child > [role="button"]': {
            borderBottomRightRadius: 'var(--List-radius)',
            borderBottomLeftRadius: 'var(--List-radius)',
          },
        },
        '--List-radius': '8px',
        '--List-gap': '1rem',
        '--List-divider-gap': '0px',
        '--List-item-paddingY': '0.5rem',
        // override global variant tokens
        '--joy-palette-neutral-plainHoverBg': 'rgba(0 0 0 / 0.08)',
        '--joy-palette-neutral-plainActiveBg': 'rgba(0 0 0 / 0.12)',
        [theme.getColorSchemeSelector('light')]: {
          '--joy-palette-divider': 'rgba(0 0 0 / 0.08)',
        },
        [theme.getColorSchemeSelector('dark')]: {
          '--joy-palette-neutral-plainHoverBg': 'rgba(255 255 255 / 0.1)',
          '--joy-palette-neutral-plainActiveBg': 'rgba(255 255 255 / 0.16)',
          '--joy-palette-background-surface': 'rgba(0,0,0,.45)'
        },
      })}
    >
      <ListItem nested sx={{position:'sticky',top: 0,left: 0,zIndex: 9,pt:.5,backdropFilter:'blur(20px)'}}>
        <List aria-label="Personal info" sx={{ '--List-decorator-size': '72px' }}>
          <ListItem >
            <ListItemDecorator sx={{marginRight:'5%',flexDirection:'column'}}>
              <Avatar sx={{ '--Avatar-size': '60px' }} src={`https://dev-services.ekc.app/profiles/avatar/${projectMap.get(ProjectID)?.ProjectState?.projManager}_avatar.jpg`} />
              <Typography fontSize="xs">
             {teamMember.get(projectMap.get(ProjectID)?.ProjectState?.projManager)?.firstName} {teamMember.get(projectMap.get(ProjectID)?.ProjectState?.projManager)?.lastName}
              </Typography>
            </ListItemDecorator>
            <Box sx={{width:'75%'}}>
              <Typography level="body1" fontSize="xl2" fontWeight="xl" id="ios-example-demo" mb={1} sx={{lineHeight:1,fontSize:'22px'}} >
              Report: { projLabel }
              </Typography>
              <ListItemContent>Started: {dayjs(projectMap.get(ProjectID)?.ProjectState?.Calendar?.startDate).format("MMM D 'YY")} - Ends On: {dayjs(projectMap.get(ProjectID)?.ProjectState?.Calendar?.endDate).format("MMM D 'YY")} 
              <Typography id="description" level="body3" aria-hidden>Day Starts: {dayjs(projectMap.get(ProjectID)?.ProjectState?.Calendar?.startDate).format('h:mm a')} - Day Ends: {dayjs(projectMap.get(ProjectID)?.ProjectState?.Calendar?.endDate).format('h:mm a')}</Typography></ListItemContent>
            </Box>
            </ListItem>
            { (!clockout && ProjectData?.userOpt?.eodOptions?.includes('CLOCK IN/OUT')) &&
             <ListItem >
              <Button size="lg" color={clockin?'warning':'success'} startDecorator={<WatchLaterIcon/>} sx={{position:'sticky',top:'5px',width:'100%'}} onClick={clockInOut}>CLOCK {clockin?'OUT':'IN'}</Button>
              </ListItem> }
          <ListItem>
            <ListItemButton sx={{gap:'2%',flexWrap:'wrap'}}>
            <Typography fontSize="xs">Your team for today:</Typography>
              <Box sx={{display:'flex',justifyContent:'space-between',flex:1}}>
              <AvatarGroup>
              { outputRef.current?.Members && [...(outputRef.current?.Members ? outputRef.current.Members : projectMap.get(ProjectID)?.ProjectState?.Members)]?.flatMap((x,i) => (
                  <Avatar key={x.id+i} src={`https://dev-services.ekc.app/profiles/avatar/${x.id}_avatar.jpg`} title={
                    `${teamMember.get(x.id)?.firstName} ${teamMember.get(x.id)?.lastName}`
                    
                  }/>
              ))}
            </AvatarGroup>
            <Button variant="soft" onClick={addUser}>Change Team</Button>
              </Box>
            </ListItemButton>
          </ListItem>
        </List>
      </ListItem> 
      { projectMap.get(ProjectID)?.ProjectDesc &&
      <ListItem
          sx={{
            bgcolor: 'background.surface',
            mb: 1,
            borderRadius: 'var(--List-radius)',
          }}
        >
          <ListItemButton aria-describedby="description" sx={{ borderRadius: 'var(--List-radius)',flexDirection:'column' }}>
           <Box sx={{ display:'flex',width:'100%',flex:1,justifyContent:'space-between' }}>
           <Typography textColor="text.tertiary">Job Details</Typography>
           </Box>
           <ListItemContent sx={{width:'100%'}}><Typography id="description" level="body1" aria-hidden component={Box}>
              <pre style={{ fontFamily: 'inherit' }}>
                { projectMap.get(ProjectID)?.ProjectDesc}
              </pre>
            </Typography>
            { projectItemsRef.current && Object.keys(projectItemsRef.current).length > 0 &&
            [<Divider/>,
            <Box sx={{ display:'flex',width:'100%',flex:1,justifyContent:'space-between' }}>
           <Typography textColor="text.tertiary">Project Inventory</Typography>
           </Box>,
           <Box sx={{maxHeight:'30vh',overflow:'auto'}}>{
            Object.entries(projectItemsRef.current).flatMap(([k,data])=>{
              if(data.ProductItems?.length > 0)
              {
              var {QTYOnHand,QTYReceived,ProductItems:[{Product}]} = data
              }
              else
              {
                var {QTYOnHand,QTYReceived} = data
              }
            return <ListItem sx={{m:0}} onClick={()=>
            setState(p=>({...p,...{
              productCard : dynamicComponent('ProductCard', 'elements', {ProductID:data.ProductItems[0]?.id, InventoryID:k}, mainState)
            }})) 
          }>
            <ListItemDecorator sx={{marginRight:'5%',flexDirection:'column'}}>
              <Avatar sx={{ '--Avatar-size': '40px' }} src={Product && Product?.IMAGE_SPOTLIGHT?.startsWith("https://www.dev-services.ekc.app") ? Product?.IMAGE_SPOTLIGHT.replace(/www.dev-/g, 'www.') : Product?.IMAGE_SPOTLIGHT} />
            </ListItemDecorator>
            <Box sx={{width:'75%'}}>
              <Typography level="body1" mb={1} sx={{lineHeight:1,fontSize:'16px'}} >
              { Product && Product.DISPLAY_NAME } ( QTY: { QTYReceived-QTYOnHand } )
              </Typography>
            </Box>
            </ListItem>})}</Box>] }
            </ListItemContent> 
          </ListItemButton>
        </ListItem> }
      
        { ProjectData?.userOpt?.eodOptions?.includes('OVERALL PERCENTAGE') &&
        <ListItem sx={{bgcolor: 'background.surface', mb: 1, borderRadius: 'var(--List-radius)'}}>
          <ListItemButton  aria-describedby="ProgressPerc" sx={{ borderRadius: 'var(--List-radius)',flexDirection:'column' }} >
          <Box sx={{ display:'flex',width:'100%',flex:1,justifyContent:'space-between',gap:2}}>
          <Chip size="sm" variant="soft" >
          <Typography sx={{lineHeight:1,whiteSpace:'break-spaces',textAlign:'center'}}>Project Progress {outputRef.current?.[ProjectID]?.Progress && `${outputRef.current?.[ProjectID]?.Progress}%`}</Typography>
            </Chip>
           <Slider aria-label={'Progress'}
           value={outputRef.current?.[ProjectID]?.Progress ? outputRef.current[ProjectID].Progress:0}
           onChange={(e,v)=> setOutput(p=>({...p,...{[ProjectID]:{...p[ProjectID],Progress:v}}})) }
           marks={projMarks}
           min={0}
           max={100}
           getAriaValueText={(V)=>`${V}%`}
           valueLabelDisplay="auto"
           sx={{margin:'0 3% 3%',
            '& .MuiSlider-thumb': { backgroundColor: perc2color(outputRef.current?.[ProjectID]?.Progress|0) },
            '& .MuiSlider-track': { backgroundColor: perc2color(outputRef.current?.[ProjectID]?.Progress|0) },
            '& .MuiSlider-active': { backgroundColor: perc2color(outputRef.current?.[ProjectID]?.Progress|0) }
          }}
         />
         </Box>
          </ListItemButton>
        </ListItem> }

      <ListItem sx={{bgcolor: 'background.surface', mb: 1, borderRadius: 'var(--List-radius)'}} >
          <ListItemButton  aria-describedby="Overtime" sx={{ borderRadius: 'var(--List-radius)',flexDirection:'column' }} >
           <Box sx={{ display:'flex',width:'100%',flex:1,justifyContent:'space-between' }}>
           <Typography textColor="text.tertiary">Overtime? </Typography>

        {outputRef.current.Overtime && <Chip
         disabled={false}
         onClick={function(){}}
         size="md"
         variant="outlined"
          >
          {outputRef.current.Overtime?outputRef.current.Overtime:0} Hours
          </Chip>}

          <Switch
            size="lg"
            color={outputRef.current?.[ProjectID]?.SWovertime ? 'success' : 'neutral' }
            componentsProps={{ input: { 'aria-label': 'dark mode' } }}
            checked={outputRef.current?.[ProjectID]?.SWovertime}
            onChange={()=>setOutput(p=>({...p,...{[ProjectID]:{...p[ProjectID],SWovertime:!outputRef.current?.[ProjectID]?.SWovertime }}})) }
          />

            </Box>
          { outputRef.current?.[ProjectID]?.SWovertime &&
           <Slider
           aria-label={'Overtime'}
           value={outputRef.current?.[ProjectID]?.Overtime ? outputRef.current[ProjectID].Overtime:0}
           onChange={(e,v)=>{
             setOutput(p=>({...p,...{[ProjectID]:{...p[ProjectID],Overtime:v}}})) 
           }}
           step={.25}
           marks
           min={0}
           max={16}
           valueLabelDisplay="auto"
         /> }
          </ListItemButton>
        </ListItem>
  
      { PhaseOption ? <TabsPricingExample globalState={globalState} ProjectID={ProjectID} BuildOut={BuildOut}/> : ProjData && Object.entries(ProjData)?.filter(([k])=>!excluded.includes(k))?.flatMap(([k,v],i)=>[
      <Divider textAlign="left" sx={{marginTop:2,"&::before, &::after": { borderColor:'#09090D' }}}>
        <Chip startDecorator={<FactCheckIcon/>}>{k}</Chip>
      </Divider>,
      
      <ListItem nested>
        <List
          aria-label="Network"
          sx={{
            [`& .${sheetClasses.root}`]: {
              p: 0.5,
              lineHeight: 0,
              borderRadius: 'sm',
            },
          }}
        >
  
         <ListItem key={k+i}>
            <ListItemButton>
              <ListItemContent>
                      { divisionMap.get(DivisionID)?.category !== "ChromeBook" ? Object.entries(v).flatMap(([oldK,{label,units}],i)=>BuildOut({label,units,group:k})) : 
                     BuildOut({label:k,group:k})
                       }
                </ListItemContent>
            </ListItemButton>
          </ListItem>
        </List>
      </ListItem>
    ] ) }
   <ListItem nested>
        <ListItem
          sx={{
            bgcolor: 'background.surface',
            mb: 1,
            borderRadius: 'var(--List-radius)',
          }}
        >
          <ListItemButton
            aria-describedby="apple-tv-description"
            sx={{ borderRadius: 'var(--List-radius)',flexDirection:'column' }}
          >
           <Box sx={{ display:'flex',width:'100%',flex:1,justifyContent:'space-between' }}>
           <Typography textColor="text.tertiary">Missing or needed material? </Typography>

           <Switch
            size="lg"
            color={outputRef.current?.[ProjectID]?.SWmaterial ? 'success' : 'neutral' }
            componentsProps={{ input: { 'aria-label': 'dark mode' } }}
            checked={outputRef.current?.[ProjectID]?.SWmaterial}
            onChange={()=>setOutput(p=>({...p,...{[ProjectID]:{...p[ProjectID],SWmaterial:!outputRef.current?.[ProjectID]?.SWmaterial }}})) }
          />

            </Box>
          { outputRef.current?.[ProjectID]?.SWmaterial &&
            <TextareaAutosize
            size="lg"
            multiline
            placeholder="What material or items do you need urgently?"
            style={{ width: '100%',backgroundColor:'transparent',color:'#fff',fontSize:'16px' }}
            value={outputRef.current?.[ProjectID]?.materialNeeded}
            sx={{
              '--Input-radius': `${radius}px`,
              '--Input-decorator-childHeight': `${childHeight}px`,
            }}
            onChange={({target})=>{ setOutput(p=>({...p,...{[ProjectID]:{...p[ProjectID],materialNeeded:target.value}}})) }}
          /> }
          </ListItemButton>
        </ListItem>
  
        <ListItem
          sx={{
            bgcolor: 'background.surface',
            mb: 1,
            borderRadius: 'var(--List-radius)',
          }}
        >
          <ListItemButton
            aria-describedby="apple-tv-description"
            sx={{ borderRadius: 'var(--List-radius)',flexDirection:'column' }}
          >
           <Typography textColor="text.tertiary" sx={{width:'100%'}}>Tech Notes: </Typography>
           <TextareaAutosize
          size="md"
          multiline
          value={outputRef.current?.[ProjectID]?.techNotes}
          placeholder="Notes outside of the report."
          style={{ width: '100%',backgroundColor:'transparent',color:'#fff',fontSize:'16px' }}
          onChange={({target})=>{ setOutput(p=>({...p,...{[ProjectID]:{...p[ProjectID],techNotes:target.value}}})) }}
          sx={{
            '--Input-radius': `${radius}px`,
            '--Input-decorator-childHeight': `${childHeight}px`,
          }}
        />
          </ListItemButton>
        </ListItem>

        <ListItem
          sx={{
            bgcolor: 'background.surface',
            mb: 1,
            borderRadius: 'var(--List-radius)',
          }}
        >
          <ListItemButton
            aria-describedby="apple-tv-description"
            sx={{ borderRadius: 'var(--List-radius)',flexDirection:'column' }}
          >
           <Typography textColor="text.tertiary" sx={{width:'100%'}}>Report: </Typography>
           <TextareaAutosize
          size="md"
          multiline
          value={outputRef.current?.[ProjectID]?.notes}
          placeholder="Anything to report for today?"
          style={{ width: '100%',backgroundColor:'transparent',color:'#fff',fontSize:'16px' }}
          onChange={({target})=>{ setOutput(p=>({...p,...{[ProjectID]:{...p[ProjectID],notes:target.value}}})) }}
          sx={{
            '--Input-radius': `${radius}px`,
            '--Input-decorator-childHeight': `${childHeight}px`,
          }}
        />
          </ListItemButton>
        </ListItem>

        <ListItem
          sx={{
            bgcolor: 'background.surface',
            mb: 1,
            borderRadius: 'var(--List-radius)',
          }}
        >
          <ListItemButton
            aria-describedby="apple-tv-description"
            sx={{ borderRadius: 'var(--List-radius)',flexDirection:'column' }}
          >
            <Box sx={{display:'flex',width:'100%',justifyContent: 'space-evenly',gap:'3%'}}>
            <Button size="sm" startDecorator={<HardwareIcon/>} onClick={usedRes}>Material Used</Button>
            <Button size="sm" startDecorator={<SubscriptionsIcon/>} onClick={addFiles}>Additional Photos/Videos</Button>
            </Box>
          </ListItemButton>
        </ListItem>
        <Box sx={{display:'flex',width:'100%',justifyContent: 'space-evenly',gap:'1%',flexWrap:'wrap'}}>
          {stateRef.current.uploaded && stateRef.current.uploaded?.flatMap(({name,path,created})=>
          <Card
          color="primary"
          invertedColors={false}
          orientation="vertical"
          size="sm"
          sx={{minWidth:150}}
        >
          <AspectRatio minHeight="120px" maxHeight="200px">
        <CardMedia
          component="img"
          image={`${mainconfig.serverPath+path}`}
          loading="lazy"
          alt=""
        />
         <CardContent sx={{position: 'absolute', bottom: 0,width: '100%',height: '50%', justifyContent: 'end', background: 'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 65px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 65px)', }}>
          <Typography level="body3" fontWeight="sm" textColor="#fff"  >
            {dayjs(created).format('ddd, MMM D, YY h:mma')}
          </Typography>
        </CardContent>
      </AspectRatio>
          </Card>

          ) }
        </Box>

        <Typography id="apple-tv-description" level="body3" aria-hidden>
          You are required to fill out these reports every workday. ** The "Report" section is a required field.
        </Typography>
      </ListItem>
      { stateRef.current.submitBttn }
    </List> : null}
  </Sheet>
 </StyledEngineProvider>
 { stateRef.current.productCard }
</Suspense>
}

const filesTheme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        background: {
          appBody: 'var(--joy-palette-neutral-50)',
          componentBg: 'var(--joy-palette-common-white)',
        },
      },
    },
    dark: {
      palette: {
        background: {
          appBody: 'var(--joy-palette-common-black)',
          componentBg: 'var(--joy-palette-neutral-900)',
        },
      },
    },
  }
});

const EODformPre = (props)=>{
  return <CssVarsProvider disableTransitionOnChange theme={filesTheme}><EODform {...props}/></CssVarsProvider>
}

export default EODformPre

function TabsPricingExample({globalState,ProjectID,BuildOut}) {
  const [projectMap,] = useAtom(globalState.projectMapState)

return [
  <Divider sx={{m:2,"&::before, &::after": {borderColor:'#09090D'}}}>
    <Chip startDecorator={<FactCheckIcon/>}>Project Phases</Chip>
  </Divider>,
    <Tabs
      size="sm"
      aria-label="Pricing plan"
      defaultValue={0}
      sx={() => ({
        '--Tabs-gap': '0px',
        borderRadius: 'sm',
        overflow: 'auto',
      })}
    >
      <TabList
        sx={{
          '--List-item-radius': '0px',
          borderRadius: 0,
          flexWrap:'wrap',
          bgcolor: 'rgba(0,0,0,.45)',
          [`& .${tabClasses.root}`]: {
            fontWeight: 'lg',
            borderRadius: 3,
            position: 'relative',
            [`&.${tabClasses.selected}`]: {
              color: 'primary.500',
            },
            [`&.${tabClasses.selected}:before`]: {
              content: '""',
              display: 'block',
              position: 'absolute',
              bottom: -1,
              width: '100%',
              height: 2,
              bgcolor: 'primary.400',
            },
            [`&.${tabClasses.focusVisible}`]: {
              outlineOffset: '-3px',
            },
          },
        }}
      >
      { projectMap.get( ProjectID )?.SalesState?.PhaseFilter?.map(({label})=><Tab><Typography my={1}>{label}</Typography></Tab> )}
      </TabList>
      { projectMap.get( ProjectID )?.SalesState?.PhaseFilter?.map(({label:header,Options,_id},i)=>
        Options !== undefined ? Object.entries(Options).map(([k,v2])=>
            <TabPanel value={i} sx={{p:'0 3%'}}>
              <Divider textAlign="left" sx={{marginTop:2,"&::before, &::after": { borderColor:'#09090D' }}}>
              <Chip startDecorator={<FactCheckIcon/>}>{k}</Chip>
              </Divider>
          { Object.entries(v2).map(([oldK,{label,units}],i)=> BuildOut({label,units,phase:header,group:k,PhaseID:_id}) ) }
      </TabPanel>
     ) : <TabPanel value={i} sx={{p:'0 3%'}}><Typography my={1} sx={{textAlign:'center'}}>THIS PHASE IS EMPTY.</Typography></TabPanel> 
      ) }
    </Tabs>]
}