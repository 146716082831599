import React, { useEffect } from "react";
import useState from 'react-usestateref';
import { useAtom } from 'jotai';
import dayjs from 'dayjs';
import {Box, ButtonGroup, Stepper, Step, StepLabel, StepContent, Button, Paper, Typography} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import TaskManager from '../FileManager/TaskManager';
import LinkedCameraIcon from '@mui/icons-material/LinkedCamera';

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
          main: '#0061AD',
        },
      secondary: {
          main: '#fff',
        },
    }
    });

export default function ProjStepper(props) {
const {That, globalState, viewData, dynamicComponent,waveLoad,handlePopupClose,newNotification} = props
const {projID, projStepps } = viewData
const [popupData, setPopup] = useAtom(globalState.popupState)
const [, setUploadTempFiles, uploadTempFilesRef] = useState(Object)
const [projectStats, ] = useAtom(globalState.projectsStatsState)
const mainState = useState(Object) 
const [UserMap, ] = useAtom(globalState.userMapState)
const [, setState, stateRef] = mainState
const [, setParentState, parentStateRef] = That
const [, setActiveStep] = useState(0);
const { ProjectID } = projID
const [teamMember, ] = useAtom(globalState.teamMemberState)
const [projectMap, ] = useAtom(globalState.projectMapState)
const ProjectData = projectMap.has(ProjectID) ? projectMap.get(ProjectID) : undefined

if(projectStats.has('quickStatsToday')) var dataSats = projectStats.get('quickStatsToday')?.find(({projID})=>projID.ProjectID===ProjectID)

  const handleReset = () => {
    setParentState(p=>({...p,...{cardPlace: 0 }}))
  };

  const handleBack = () => {
    setParentState(p=>({...p,...{cardPlace: p.cardPlace - 1}}))
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  useEffect(() => { 
    if(stateRef.current.manualAdd&& stateRef.current.manualAdd.length>1 ) setPopup(p=>({...p,...{
      handleSubmit:()=>{ 
        setPopup(p=>({...p,...{ handleScanMulti: {[popupData.data]:stateRef.current.manualAdd} }}))
      }, 
    }}))
    },[stateRef.current.manualAdd])

  const manualAdd=(x)=>{
    setPopup(p=>({...p,...{
                    open:true,
                    data: x,
                    title:`Manually Add Barcode`,
                    inputField: [
                      props.dynamicComponent('InputField','elements',{state:'manualAdd',label:`Add ${x.replace(/([A-Z])/g,(y)=> ` ${y}`)}`,type:'text'},mainState)
                    ],
                    handleClose: ()=>{props.handlePopupClose()},
                    nameInputSets: {},
    }}))
    }
    
    const dupRemove = ()=>{
      var newItem = parentStateRef.current.preList.flatMap(y=> Object.entries(y).filter(([k,v])=>!Object.entries(v)?.some(([k,v])=>v.duplicate===parentStateRef.current.duplicate && !v.repeatable)).map(([k,v])=>({[k]:v})))
      setParentState(p=>({...p,...{ 
        projSteps: waveLoad(),
        preList : newItem,
        userFilter: Object.keys(Object.assign({},...newItem))
      }})) 
      setParentState(p=>({...p,...{ duplicate: p.duplicate ? p.duplicate===0 ? 0 : p.duplicate-1 : 0 }})) 
      }

    const dupRepeatable = ()=>{
    setParentState(p=>({...p,...{ duplicate: p.duplicate ? p.duplicate+1 : 1 }}))   
    var indexOF = parentStateRef.current.preList.findLastIndex((x,i,a)=>{
      if(a.every(x=> Object.entries(x).some(([k,v])=> !v?.some(z=>z.duplicate)) )) return Object.entries(x).some(([k,v])=> v?.some(z=>z.repeatable))&&!Object.entries(a[i+1]).some(([k,v])=> v?.some(z=>z.repeatable))
      else return Object.entries(x).some(([k,v])=> v?.some(z=>z.duplicate))&&!Object.entries(a[i+1]).some(([k,v])=> v?.some(z=>z.duplicate))
   }) 
    var newItem = parentStateRef.current.preList.flatMap(y=> Object.entries(y).filter(([k,v])=> v && v?.some(z=>z.repeatable)).flatMap(([k,v])=>{ v.duplicate = parentStateRef.current.duplicate; return {[k+` (Item ${parentStateRef.current.duplicate})`]:v.flatMap(x=>{ x.duplicate = parentStateRef.current.duplicate; return Object.entries(x).filter((([k,v])=>k!=='repeatable')).map(([k,v])=>({[k]:v}))})}}))
    var first = parentStateRef.current.preList.slice(0,indexOF+1);
    var second = parentStateRef.current.preList.slice(indexOF+1);

    setParentState(p=>({...p,...{ 
      projSteps: waveLoad(),
      ['color'+parentStateRef.current.duplicate]: 'hsla('+ ~~(360 * Math.random())+',60%,55%,1)',
      preList : [...first,...newItem,...second],
      userFilter: Object.keys(Object.assign({},...[...first,...newItem,...second]))
    }}))
    }

    const tempfileUploader =()=>{
      stateRef.current.createNewFolder(`/EKC/Projects/${dataSats.projID.ProjectID}`,'EOD')
      stateRef.current.createNewFolder(`/EKC/Projects/${dataSats.projID.ProjectID}/EOD`, dayjs().format('ddd, MMM D, YYYY') )
      Promise.all(Object.entries(uploadTempFilesRef.current).flatMap(([k,v])=>{ 
      Object.entries(v).forEach(([k2,v2])=>{
        var formData = new FormData();
        formData.append('path', `/EKC/Projects/${dataSats.projID.ProjectID}/EOD/${dayjs().format('ddd, MMM D, YYYY')}`);
        formData.append('files', v2.data, v2.name.replace(".",`( ${k2+'_'+v2.index} ).`));
        stateRef.current.uploadFile(formData)
      })
      return
      })).then(()=>{
        setUploadTempFiles({})
        handlePopupClose()
      })
    }

    function addFiles(){
      var items = [ {id:'InputField',loc:'elements',viewData:{state:'label',label:'Photo/Video Of (REQUIRED)',type:'text'}}, {id:'Dropzone',loc:'elements',viewData:{state:'addFile',label:'Photo/Video'}} ]
      var lists = dynamicComponent('Listable','elements',{state:'addFiles',label:'+ Add',items:items,defaultValue:null,required:false},mainState,[(x)=>{
        if(x.addFiles) x.addFiles.forEach(({label,addFile})=>{ if(label && addFile){
          addFile.forEach((x,i)=> setUploadTempFiles(p=>({...p,...{[ProjectID]:{...p[ProjectID],[label+'_'+i]:{type:'AddFile', index:i, name:x.name, fileType:x.type, data:x}}}})))
          if(!popupData.handleSubmit)setPopup(p=>({...p,...{
            submitLabel: 'Upload',
            handleSubmit: tempfileUploader,
          }}))
        }
         })
        
      }])
      setPopup(p=>({...p,...{
        open:true,
        title:`Add Photos/Videos `,
        description: `A label is required, Max file size is 10MB`,
        inputField: lists,
        handleClose: handlePopupClose,
        cancelLabel: 'Cancel',
        nameInputSets: {},
    }}))
  }

  useEffect(()=>{
    setState(p=>({...p,...{
      num: dynamicComponent('InputField','elements',{state:'barcode',label:`Enter Serial Number Here`,type:'text'},mainState),
      reason: dynamicComponent('InputField','elements',{state:'reasonWhy',label:`Reason for RMA`,type:'text'},mainState),
    }}))
  },[])

  useEffect(()=>{
    setState(p=>({...p,...{
        rmaSubmit: <Box sx={{display:'flex',flexDirection:'column',gap:'5px',p:'1%'}}>{[stateRef.current.num,stateRef.current.reason]}</Box>,
    }}))
  },[])

    const rmaAdd=()=>{
    setPopup(p=>({...p,...{
                    open:true,
                    title:`Submit for RMA`,
                    description:`Scan barcode, or enter manually`,
                    inputField: stateRef.current.rmaSubmit,
                    handleClose: ()=>{props.handlePopupClose()
                      setState(p=>({...p,...{
                        scanDisabled: false,
                      }}))
                    },
                    nameInputSets: {},
    }}))
    }

    const submitRMA=()=>{
      var sendTo = []
      sendTo.push(ProjectData.ProjectState?.projManager)
      sendTo.push(ProjectData.ProjectState?.teamLead)
      sendTo.push("621fc091a33d2e33e4eec4ac") //Alejandra
      sendTo.forEach(x=>{
        var name_u = `${teamMember.get(x).firstName} ${teamMember.get(x).lastName}`
        var body = `${UserMap.get('firstName')} ${UserMap.get('lastName')} submitted an RMA. Serial number: ${stateRef.current.barcode}. Reason: ${stateRef.current.reasonWhy}`
        var email = teamMember.get(x).email
        var phone = teamMember.get(x).mobilePhone

        newNotification({Title:`An RMA has been submitted`,Body:body,Data:null,Avatar:null,Icon:null,Choice:{AppNotification:true,Text:false,Email:true},mobile:phone,Email:email,name:name_u,sender:UserMap.get('avatar'),receiver:x})
      })
      handleReset()
      handlePopupClose()
    }

    useEffect(()=>{
      if(stateRef.current.barcode && stateRef.current.reasonWhy)
      {
        setPopup(p=>({...p,...{
          open:true,
          handleSubmit: ()=> submitRMA() }}))
        }
    },[stateRef.current.barcode,stateRef.current.reasonWhy])

   useEffect(() =>{
       projStepps((x)=>setActiveStep(x))
       },[])

  useEffect(() =>{
    const stepElement = document.getElementById(parentStateRef.current.cardPlace);
    if (stepElement) stepElement.scrollIntoViewIfNeeded({ block: 'start', inline: 'nearest', behavior: 'smooth' });
    },[parentStateRef.current.cardPlace])

  return <ThemeProvider theme={darkTheme}>
      <TaskManager That={mainState} />
      <Box sx={{ padding:'1%', display: 'flex', flexDirection: 'column', maxHeight:'90%', width: '100%', m: 'auto 1%', maxWidth: 325, bgcolor: 'rgba(18,18,18,.65)', borderRadius:1 }}>
      <Stepper activeStep={parentStateRef.current.cardPlace} sx={{overflow:'auto'}} orientation="vertical">
        {parentStateRef.current.preList ? Object.entries(Object.assign({SerialNumber: [{label: 'Scan Serial Number'}]},...parentStateRef.current.preList)).map(([k,v], i) => (
          <Step key={k+i} id={i}>
            <StepLabel optional={parentStateRef.current.cardPlace!==0&&parentStateRef.current.cardPlace===i?<Button variant="outlined" color={'warning'} size="small" onClick={handleBack} sx={{fontSize:'12px',lineHeight:1,padding:'1%'}}>Back</Button>:null}>
              <Typography sx={v?.some((x,i)=>x.repeatable||x.duplicate)?{borderRadius:'8px',padding: '0 2%', border: v?.some((x,i)=>x.repeatable)? '1px solid #90caf9' :v?.some((x,i)=>x.duplicate) ? `1px solid ${parentStateRef.current['color'+v?.find((x,i)=>x.duplicate)?.duplicate]}` : 'unset'}:null}>
                {k?.split(/(?=[A-Z])/).join(" ")}
              </Typography>
            </StepLabel>
            <StepContent>
             { v?.map((x,i)=>
                <Typography key={'stpes'+i}>
                  {i+1} ∙ {x.label} {['Photos/Videos'].includes(k)&&x.units?`(${x.units}) required`:null}
                  </Typography> ) }
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={()=>manualAdd(k)}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Add Manually
                  </Button>
                  <Button
                    color={"error"}
                    onClick={rmaAdd}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Defective
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        )):null}
      </Stepper>
      { (parentStateRef.current.preList.length === parentStateRef.current.userFilter.length) && setTimeout(()=>
       <Paper square elevation={0} sx={{ p: 3 }}>
        <Typography>You are Most likely going too fast, take a breather and reset.</Typography>
        <Button onClick={handleReset}>Reset</Button>
      </Paper> ,3000)}
       { parentStateRef.current.preList && parentStateRef.current.preList?.some(y=>Object.entries(y).some(([k,v])=> v.some(z=>z.repeatable))) ?
    <Box sx={{position:'sticky',bottom:0}}>
      <ButtonGroup size="large" sx={{gap:'1%',width:'100%'}} aria-label="large button group">
        { dynamicComponent('DefaultButton','elements',{state:'dupAdd',label: `Create Dup. (Items ${parentStateRef.current.duplicate+1 ? parentStateRef.current.duplicate+1 :1})`,type:'button',startIcon:<ControlPointDuplicateIcon/>,full:true},mainState,[dupRepeatable]) }
        { parentStateRef.current.preList?.some(y=>Object.entries(y).some(([k,v])=> v.some(z=>z.duplicate && !z.repeatable ))) ? dynamicComponent('DefaultButton','elements',{state:'dupRemve',label: `Remove Dup. (Items ${parentStateRef.current.duplicate | 0})`,type:'button',startIcon:<PlaylistRemoveIcon/>,full:true},mainState,[dupRemove]) : null }
        { parentStateRef.current.preList?.some(y=>Object.entries(y).some(([k,v])=> k==='Photos/Videos')) ? dynamicComponent('DefaultButton','elements',{state:'dupAdd',label: `Upload pics & vids`,type:'button',color:'#AF8FE9',startIcon:<LinkedCameraIcon/>,full:true},mainState,[addFiles]) : null }
    </ButtonGroup>
    </Box>
    :null }
    </Box>
    </ThemeProvider>
}