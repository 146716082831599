import React, { useEffect, useRef } from "react";
import { useAtom } from 'jotai';
import useState from 'react-usestateref';
import {ViewportList} from "react-viewport-list";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { styled } from '@mui/material/styles';
import { Avatar,Paper,Divider,Box,Drawer,Button,Typography,List,ListItemText,ListItemIcon,ListItem,IconButton,InputBase,ListSubheader,ListItemButton,Card,CardContent,ListItemAvatar} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import _without from "lodash/without";
import BlurOnIcon from '@mui/icons-material/BlurOn';

import { useDebounce } from "use-debounce";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import TuneIcon from '@mui/icons-material/Tune';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';

const catagory = ["Audio", "Cameras", "Cases & Bags", "Computers & Software", "Conferencing", "Content Management", "Control", "Displays", "Furniture", "Lighting & Studio", "Mounts & Rigging", "Networking & Cabling", "Power", "Presentation", "Production", "Security & Safety", "Signal Management"]

  export default function ProductSelect(props){
  const {That,viewData,action,globalState} = props
  const {label,state,manualOpen,defaultValue,multi,checkOut} = viewData
  const [, setParentState,] = That
  const mainState = useState(Object)
  const [, setState, stateRef] = mainState
  const [productMap] = useAtom(globalState.productMapState)
  const outputState = useState([])
  const [, setOutput, outputRef] = outputState

  
  useEffect(()=>{
   if(defaultValue && defaultValue.length > 0){ 
    setOutput(Object.assign({},...defaultValue?.flatMap(({id,Product})=>({[id]:Product}))))
    setState(p=>({...p,
      defaultValue: Object.assign({},...defaultValue)
    }))
  }
  },[defaultValue])

  useEffect(()=>{
    if(!multi && Object.keys(outputRef.current)?.length > 1) delete outputRef.current[Object.keys(outputRef.current)[0]];
    if(!multi && Object.keys(outputRef.current)?.length > 0) setState(p=>({...p,drawerState: false}))
    if(Object.keys(outputRef.current)?.length > 0){

      if(productMap.has(Object.keys(outputRef.current)[0])) var {IMAGE_SPOTLIGHT,DISPLAY_NAME,MODEL_NUMBER_CLEAN,MODEL_NUMBER} = productMap.get(Object.keys(outputRef.current)[0])
      
      setState(p=>({...p,
        menuBttn: <ListItem sx={{gap:'2%'}} onClick={()=> setState(p=>({...p,drawerState: !stateRef.current.drawerState}))}>
          {
          Object.entries(outputRef.current).flatMap(([i,x])=>{
        
        return <Box key={"productItems"+i} sx={{display:'flex',gap:'10px'}}><ListItemAvatar>
          <Avatar sx={{ width: 56, height: 56 }}>
                <LazyLoadImage
            effect="blur"
            threshold={10000}
            style={{maxWidth:'clamp(40px,100%,10vw)',objectFit:'contain'}}
            key={'MainLogo'}
            onError={(e)=>e.target.src = 'https://www.services.ekc.app/scripts/images/AV.jpeg'}
            src={x?.IMAGE_SPOTLIGHT?x?.IMAGE_SPOTLIGHT:'https://www.services.ekc.app/scripts/images/AV.jpeg'}
          />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={x?.DISPLAY_NAME} secondary={'Model #: '+(x?.MODEL_NUMBER_CLEAN ? x?.MODEL_NUMBER_CLEAN : x?.MODEL_NUMBER)} /></Box>
      })}
      </ListItem>
       }))
    }
  },[multi,outputRef.current])
  
  useEffect(()=>{
    if(outputRef.current && Object.keys(outputRef.current)?.length > 0 || defaultValue){
    if(stateRef.current.drawerState===false && (Object.keys(outputRef.current)?.length>0 || stateRef.current?.defaultValue && Object.keys(stateRef.current?.defaultValue)?.length>0 ) ){
      
      var val = Object.entries(outputRef.current).flatMap(([k,v])=>({id:k,Product:v}))
      if(stateRef.current.defaultValue && Object.keys(stateRef.current.defaultValue).length > 0) var compare = stateRef.current?.defaultValue ? Object.entries(stateRef.current?.defaultValue).flatMap(([k,v])=>({id:k,userFilter:v})) : null
      if(compare !== null && JSON.stringify(val) !== JSON.stringify(compare)){
        setParentState(p=>({...p,[state]: val }))
        action?.forEach((x)=>x({[state]:val})) 
        }
      }
    }
  },[stateRef.current.drawerState, outputRef.current])

  useEffect(()=>{
    if(manualOpen) setState(p=>({...p,drawerState: true,menuBttn:null }))
    else setState(p=>({...p,
      menuBttn: props.dynamicComponent('DefaultButton','elements',{state:'ProductSelectBttn',label:label,type:'button',startIcon:<BlurOnIcon/>,color:'#c0392b',full:true},mainState,[()=>{

        setState(p=>({...p,drawerState: !stateRef.current.drawerState }))
      } ])
     }))
  },[manualOpen])
  
return [ stateRef.current.menuBttn, <Drawed {...props} That={mainState} Output={outputState} checkOut={checkOut} /> ]
  }

function Drawed(props){
  const {That, globalState, dynamicComponent, Output, globalWrn, waveLoad, dynamicMapData, productDelete, queryDB} = props
  const [, setPopup2] = useAtom(globalState.popupState2)
  const [, setOutput, outputRef] = Output
  const [UserMap] = useAtom(globalState.userMapState)
  const [productMap,setProductMap] = useAtom(globalState.productMapState)
  const [, setParentState, parentStateRef] = That
  const mainState = useState(Object)
  const [, setState, stateRef] = mainState

const closeDrawer = (e)=>{
  setParentState(p=>({...p,drawerState: false }))
}

const createInventory=()=>{
  setPopup2(p=>({...p,
    open:true,
    zIndex: 1200,
    width: '100%',
    title:`Create Product`,
    description: 'Your new Products will be amazing.',
    inputField: dynamicComponent('CreateProduct','elements',{state:'newInventoryItem',catagory:catagory },Output,[buildReq]),
    handleClose: ()=>setPopup2({open:false}),
    nameInputSets: {},
}))
}

useEffect(()=>{
  reBuild()
},[productMap])

function reBuild(){
  var newITems = Array.from(productMap).flatMap(([k,v])=>({...v,_id:k}))
  var catagories = newITems.reduce((a,c)=> {
    var v = c['CATEGORY_DISPLAY_NAME'] || 'Uncategorized'
    a[v] = a[v] || {'CATEGORY_DISPLAY_NAME':v, data: []}
    a[v].data.push(c)
    return a },{})

    var dropList = [{primary:'All',icon: <AllInclusiveIcon/>,divider:true,dividerText:'Filters'}];
    
 if(newITems) setState(p=>({...p,
    EKCEntries: newITems,
    EKCCatagories: [catagories],
    filterDropdown: dynamicComponent('DropDown','elements',{state:'statusFilter',icon:<TuneIcon/>, list:dropList, replace:true },mainState),
   }))
}

function buildReq(){
  if(productMap.size>0) reBuild()
    queryDB({model:'Products',idConvert:'$nin',query:{'_id':productMap.keys()}},(items)=>{
  Promise.all(
    items.flatMap(x=> dynamicMapData({ [x._id]: x }, productMap, setProductMap, () => setProductMap(p => new Map(p))) )
    ).then(reBuild)
})
}

// delete a product
const submitDelete=(_id)=>{
  productDelete({productID:_id},({success,err})=>{ 
    if(err) globalWrn('error',`We had an issue deleting the item.`)
    else if(success){ 
      setPopup2({open:false})
      globalWrn('success',`Delete Successful.`)
    }})
  
}

const deleteItem=(_id)=>{
  setPopup2(p=>({...p,
    open:true,
    zIndex: 1200,
    width: '100%',
    title:`Delete Product`,
    description: 'Are you sure you want to delete this product? This cannot be undone.',
    handleClose: ()=>setPopup2({open:false}),
    handleSubmit: ()=>submitDelete(_id),
    nameInputSets: {},
}))
}

const UpdateItem=(x)=>{

setPopup2(p=>({...p,
    open:true,
    zIndex: 1200,
    width: '100%',
    title:`Update Product`,
    description: 'Your Products will be amazing.',
    inputField: dynamicComponent('CreateProduct','elements',{state:'newInventoryItem',catagory:catagory, defaultValue:x },Output,[buildReq]),
    handleClose: ()=>setPopup2({open:false}),
    nameInputSets: {},
}))
}

function cardBuilder(x,i){
  var {_id,PART_NUMBER,DISPLAY_NAME,SHORT_DESCRIPTION,IMAGE_SPOTLIGHT,MODEL_NUMBER_CLEAN,MSRP} = x
  if(!_id) return console.log('About to ruin your day',_id)

return <Box sx={{height:'150px',minHeight:'150px',maxHeight:'150px',overflow:'auto',display:'flex'}}><Card sx={{ display: 'inline-block',margin:'auto', flexDirection: 'column',height:'clamp(139px,82%,96%)', width:'96%',padding:'4px 6px',overflow:'hidden'}}>
  <Box sx={{ display: 'flex',justifyContent:'space-between',gap:'5px'}}>
 <Box sx={{ display: 'flex', flexDirection: 'column' }}>
   <CardContent sx={{ flex: '1 0 auto',padding:'1%!important', display:'flex',flexDirection:'column',gap:'5px'}}>
     <Typography variant="body2" sx={{overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical',lineHeight:1.1,fontSize:'14px'}}>{DISPLAY_NAME}</Typography>
     
     <Box sx={{ overflow:'auto',gap:'4px',lineHeight:1,display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical'}}>
     {SHORT_DESCRIPTION && <Typography variant="caption" color="text.secondary" sx={{lineHeight:1}}>{SHORT_DESCRIPTION}</Typography>}
     {PART_NUMBER && <Typography variant="caption"> | Part #: {PART_NUMBER}</Typography>}
     {MODEL_NUMBER_CLEAN && <Typography variant="caption"> | Model #: {MODEL_NUMBER_CLEAN}</Typography>}
     {MSRP && <Typography variant="caption"> | MSRP: ${MSRP}</Typography>}
     </Box>
     <Box sx={{display:'flex',gap:'3%'}}>
     <Button variant="outlined" startIcon={<AddCircleOutlineIcon />} onClick={()=>{setOutput(p=>({...p,[_id]:x}))}}>Select</Button>
    { UserMap.get('level')<=3 && <Box sx={{display:'flex'}}>
      <IconButton onClick={()=>UpdateItem(x)}>
        <EditIcon/>
      </IconButton>
      <IconButton onClick={()=>deleteItem(_id)}>
        <DeleteIcon/>
      </IconButton>
    </Box>
    }
    </Box>
   
   </CardContent>
   
 </Box>
 <Box sx={{ display: 'flex', flexDirection: 'column',justifyContent:'space-evenly',maxWidth:'25vw',gap:'4%' }}>
 {IMAGE_SPOTLIGHT && <Avatar variant="rounded" sx={{ maxWidth: 'clamp(25px,100%,15vh)',maxHeight:'clamp(25px,100%,15vh)', width:'140px', height:'auto',margin:'auto'}} >
   <LazyLoadImage
    alt={'IMAGE'}
    effect="blur"
    threshold={10000}
    height={'100%'}
    width={'100%'}
    style={{objectFit:'contain'}}
    key={'MainLogo'}
    onError={(e)=>e.target.src = 'https://www.services.ekc.app/scripts/images/AV.jpeg'}
    src={IMAGE_SPOTLIGHT}
  /> 
  </Avatar> }
 </Box>
  </Box>
</Card>
</Box>
}

return <Drawer
          anchor={'right'}
          PaperProps={{ sx: {maxWidth:'80vw', backgroundColor:'rgba(0,0,0,.35)',backdropFilter:'blur(15px)',padding:'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)'}}}
          open={parentStateRef.current.drawerState ? parentStateRef.current.drawerState : false}
          onClose={closeDrawer}
          >
          <Box sx={{display:'flex',flexDirection:'space-evenly',position:'sticky',top:0,zIndex:9,backgroundColor:'#121212',padding:'1%',margin:'1%',borderRadius:'4px'}}>
          <Button sx={{backgroundColor:'#90caf9', color:'black',flex:1}} onClick={createInventory}>Create Product</Button>
          {stateRef.current.filterDropdown}
          </Box>
          <Box ref={module} sx={{flex: 1, display: 'flex', flexDirection: 'column', overflow: 'auto'}}>
            { stateRef.current?.EKCEntries ? <ViewportList
                viewportRef={module}
                items={stateRef.current?.EKCEntries}
                overscan={55} 
                itemMinSize={300}
                >
                {cardBuilder}
                </ViewportList> 
            : waveLoad() }
             </Box>
          <SearchBar {...props} mainState={mainState} UpdateItem={UpdateItem} deleteItem={deleteItem} />
        </Drawer>
}

const AccordionSub = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummarySub = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    marginLeft: theme.spacing(1),
  },
}));

export function SearchBar(props) {
  var {globalState,Output,mainState,deleteItem,UpdateItem} = props
  const [UserMap] = useAtom(globalState.userMapState)
  const [, setState, stateRef] = mainState
  const [, setOutput, ] = Output
  const [filter, setFilter] = useState(stateRef.current.search);
  const [debouncedFilter] = useDebounce(filter, 500);
  const module = useRef(null)

  function axoisBuild(){
    setState(p=>({...p, 
      searchData : stateRef.current?.EKCEntries?.filter(x=> Object.values(x).some(y=>y?.toString().toLowerCase()?.includes(stateRef.current.search?.toString().toLowerCase())) )
    }))
  }

  useEffect(()=>{
    setFilter(stateRef.current.search)
  },[stateRef.current.search])

  useEffect(()=>{
    if(stateRef.current.search) axoisBuild()
  },[debouncedFilter])

    function addMore(){
      var first = parseInt(stateRef.current.searchData?.pop()?.ROW?.split('/')[0],10)
      var last = parseInt(stateRef.current.searchData?.pop()?.ROW?.split('/')?.pop(),10)
      var adding = <Button variant="contained" startIcon={<AddCircleOutlineIcon />} onClick={()=>axoisBuild(stateRef.current.pgnum+1)} sx={{m:'2% 5% 0',width:stateRef.current.pgnum>1?'40%':'89%'}}>Next Page (Page {stateRef.current.pgnum})</Button>
      var subt = <Button variant="contained" startIcon={<AddCircleOutlineIcon />} color="warning" onClick={()=>axoisBuild(stateRef.current.pgnum-1)} sx={{m:'2% 5% 0',width:first!==last?'40%':'89%'}}>Prev Page (Page {stateRef.current.pgnum-1})</Button>
      if(first===last) return subt
      if(first<last && stateRef.current.pgnum>1) return [adding,subt]
      if(first<last) return adding
    }

return <Paper component="form" variant="outlined" sx={{ p: '2px 4px',m:'1% 1% 0', display: 'flex', position:'sticky', bottom: '.5%', flexDirection:'column', alignItems: 'center', width: '97%', zIndex:998 }} >
      <Box sx={{display: 'flex',w:'100%'}}>
       <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <InputBase
        ref={module}
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search"
        inputProps={{ 'aria-label': 'search' }}
        onKeyPress={(e) => e.key === 'Enter' && e.preventDefault() }
        onChange={({target})=> setState(p=>({...p, search: target.value?.length>0 ? target.value : undefined })) }
      />
     { stateRef.current.search &&
      [<Divider variant="middle" flexItem orientation="vertical" />,
      <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={()=>{ module.current.querySelector('input').value = ''; setState(p=>({...p, search: undefined })) }}>
        <CancelIcon />
      </IconButton>] }
      </Box>
      { stateRef.current.search  && [<Divider />,
      <List
      sx={{ width: '100%', bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader id="nested-list-subheader">
          <Typography variant="caption" >searching:  { stateRef.current.search }</Typography>
        </ListSubheader>
      }
    >
     { stateRef.current.searchData?.constructor===Array && stateRef.current.searchData
     .flatMap((x,i)=>{
      var {PART_NUMBER,DISPLAY_NAME,IMAGE_SPOTLIGHT,MODEL_NUMBER_CLEAN,MSRP,_id} = x
    return [<ListItemButton key={"listItemProd_"+i} sx={{padding:'4px 6px'}} >
        <ListItemText primary={<Typography sx={{lineHeight:1}}>{DISPLAY_NAME}</Typography>} secondary={
        [MODEL_NUMBER_CLEAN && <Typography variant="caption"> Model #: {MODEL_NUMBER_CLEAN}</Typography>,
        PART_NUMBER && <Typography variant="caption"> | Part #: {PART_NUMBER}</Typography>,
        MSRP && <Typography variant="caption"> | MSRP: ${MSRP}</Typography>]
        } />
        <Box sx={{display:'flex',flexDirection:'column'}}>
          <Box sx={{display:'flex',justifyContent:'space-between'}}>
        {IMAGE_SPOTLIGHT && <ListItemIcon>
          <Avatar variant="rounded" sx={{ maxWidth:'100%',maxHeight:'clamp(35px,100%,15vh)',width:'65px', height:'auto',margin:'auto'}} >
        <LazyLoadImage
          alt={'IMAGE'}
          effect="blur"
          threshold={10000}
          height={'100%'}
          width={'100%'}
          style={{objectFit:'contain'}}
          key={'MainLogo'}
          onError={(e)=>e.target.src = 'https://www.services.ekc.app/scripts/images/AV.jpeg'}
          src={IMAGE_SPOTLIGHT}
        /> 
        </Avatar>
        </ListItemIcon>}
        { UserMap.get('level')<=3 && <Box sx={{display:'flex',justifyContent:'space-evenly',flexDirection:'column'}}>
      <IconButton onClick={()=>UpdateItem(x)}>
        <EditIcon/>
      </IconButton>
      <IconButton onClick={()=>deleteItem(_id)}>
        <DeleteIcon/>
      </IconButton>
    </Box> }
        </Box>
        <Button variant="outlined" startIcon={<AddCircleOutlineIcon />} onClick={()=>setOutput(p=>({...p,[_id]:x})) } >Select</Button>
        </Box>
      </ListItemButton>]
     }) }
     { stateRef.current?.searchData?.constructor===Array && addMore() }
    </List>] }
    </Paper>
}