
/* eslint-disable no-unused-expressions */
import React, { useEffect } from "react";
import { useAtom } from 'jotai';
import useState from 'react-usestateref';
import dayjs from "dayjs";
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import * as isToday from 'dayjs/plugin/isToday';

import {Box,Typography,Card,Divider} from "@mui/material";
dayjs.extend(localizedFormat);
dayjs.extend(isToday);
dayjs.extend(isSameOrBefore)

const DeliveriesFormReschedule=({dynamicComponent,CheckID,dbUpdateValidate,globalState,globalWrn,viewData,newNotification})=> {
   const mainState = useState(Object)
   const outputState = useState(Object)
   const {data,TransferAmountRef,AdminView,setAdminPanelRefresh,AdminPanelRefresh} = viewData
   const [, setState, stateRef] = mainState
   const [, setOutput, outputRef] = outputState
   const [UserMap] = useAtom(globalState.userMapState)
   const [projectMap,] = useAtom(globalState.projectMapState)
   const [inventoryMap, ] = useAtom(globalState.inventoryMapState)
   const [divisionMap, ] = useAtom(globalState.divisionMapState)
   const [teamMember,setTeamMember] = useAtom(globalState.teamMemberState)
   const [productMap,] = useAtom(globalState.productMapState)
   var [, setPopup] = useAtom(globalState.popupState)
   var [, setPopup2] = useAtom(globalState.popupState2)

   const timestamp = new Date()

   useEffect(()=>{
    var transferTableObj = {}
    const deliveryDate = data?.[0]?.TransferRequests?.filter(x=>x?.deliveryDate)
    if(data) transferTableObj = {current: data?.flatMap(x=>x.InventoryID.flatMap(id=>{return {...inventoryMap.get(id), TransferAmount: outputRef.current?.['amount_'+id]||0}}))}
    var divisionName = UserMap.has('division') && divisionMap.has(UserMap.get('division')) ? divisionMap.get(UserMap.get('division')).teamName : "EKC Corporate"

    setState(p=>({...p,...{
      timestamp: timestamp,
      notes: <Box sx={{marginTop: '1%'}}>{dynamicComponent('InputField','elements',{state:'notesField',label:'Notes',type:'text',style:{margin:0}},outputState)}</Box>,
      transferListItems: <Box sx={{marginTop: '1%'}}>{dynamicComponent('TransferList','elements',{state:'transferList', list:transferTableObj, TransferAmountRef},mainState)}</Box>,
      dateSelector: <Box sx={{marginTop: '1%'}}>{dynamicComponent('DatePicker','elements',{state:'deliveryDate',label:'Reschedule Date', defaultValue: outputRef.current?.deliveryDate ? outputRef.current?.deliveryDate : deliveryDate?.length > 0 ? deliveryDate?.[0]?.deliveryDate : null, min: dayjs().startOf('day') },outputState)}</Box>,
      //QTYOrdered: <Box sx={{marginTop: '1%'}}>{dynamicComponent('LabelValue','elements',{state:'amount',Min:0,Max: 5,label:'Amount Not Delivered',type:'number',labelEditable:false, defaultValue:outputRef?.current?.amount},outputState,[({amount})=>setOutput(p=>({...p,...{amount:amount.value}}))])}</Box>,
      delDriver: <Box sx={{marginTop: '1%'}}>{dynamicComponent('UserButton','elements',{state:'rescheduleDeliveryDriver', label:'Rescheduled Delivery Driver',defaultValue:data?.[0]?.TransferRequests?.filter(x=>x.Completed !== true)?.[0]?.deliverDriver, divisionName:divisionName},outputState)}</Box>,
      }}))
    },[inventoryMap, outputRef.current])

    useEffect(()=>{
      stateRef.current?.rightList?.forEach(x=>{
        var max
        if(AdminView) max = TransferAmountRef?.filter(y=>Object.keys(y)?.[0] === x?.ProductItems?.[0]?.id)?.[0]?.[x?.ProductItems?.[0]?.id]
        else data?.forEach(transfers=>{if(transfers?.InventoryID.filter(y=>y === x?.InventoryID).length > 0) transfers?.TransferRequests.forEach(vals=>{if(vals?.InventoryID === x?.InventoryID) max = Math.abs(vals?.TransferAmount)})})
          setState(p=>({...p,
             ["QTYOrdered_"+[x._id]]: <Box key={"Amnt_"+x._id} sx={{marginTop: '1%'}}>{dynamicComponent('LabelValue','elements',{state:['amount_'+x?._id],Min:0,Max: max,label:`Amount for: ${x?.ProductItems?.[0]?.Product?.DISPLAY_NAME}`,type:'number',labelEditable:false, defaultValue:outputRef?.current?.amount},outputState,[({amount})=>{if(amount?.value) setOutput(p=>({...p,['amount_'+x._id]:amount?.value}))}])}</Box>,
           }))
      })
    },[stateRef.current?.rightList])

    const submit=()=>{
      data?.flatMap(x=>x?.TransferRequests.forEach(req => {
        if (req?.Completed === true) {/*Do Nothing*/}
        else if (!req?.deliveryDate && AdminView) {/*Do Nothing*/}
        else if(stateRef?.current?.rightList?.filter(x=>x?.InventoryID === req?.InventoryID)?.length === 0) {/*Do Nothing*/}
        else {
          if(AdminView) var output = {
          notes: outputRef.current?.notes ? outputRef.current?.notesField : "No Notes",
          UserID: outputRef.current?.rescheduleDeliveryDriver,
          timestamp: timestamp,
          date: outputRef.current?.deliveryDate
        }
          else var output = {
            notes: outputRef.current?.notes ? outputRef.current?.notesField : "No Notes",
            UserID: outputRef.current?.rescheduleDeliveryDriver,
            timestamp: timestamp,
          }

        if(outputRef.current?.['amount_'+req?.InventoryID] === Math.abs(req?.TransferAmount))
          {
            var arrayFilters = [{"el._id": req._id}]
            output = {"$set": {"TransferRequests.$[el].Completed": true}}
            dbUpdateValidate({model:'Inventory',queryID:true,query:req.InventoryID,update:output,arrayFilters:arrayFilters, idCreate:true},({success,info,err})=>{ 
                  if(success){    
                    globalWrn('success', 'Successfully Rescheduled Delivery')   
                    if(AdminView === true) {setAdminPanelRefresh(!AdminPanelRefresh); setPopup2({open:false})} else setPopup({open: false})
                  }else {console.log("DELIVERY NOT SAVED!!!", err); globalWrn('error', 'There was a problem rescheduling your delivery')}
                })

                 // Create new transfer request
                 if(AdminView) var transferPush = {deliveryDate: outputRef.current?.deliveryDate, Status:req?.Status,Notes:outputRef?.current?.notesField,InventoryID:req?.InventoryID,ProductID:req?.ProductID,requestor:req?.requestor,signedName:req?.signedName,deliverDriver:req?.deliverDriver,TransferAmount:Math.abs(outputRef.current?.['amount_'+req?.InventoryID]),TransferType:req?.TransferType, authorizor:req?.authorizor,timestamp:new Date()};
                 else var transferPush = {Status:req?.Status,Notes:outputRef?.current?.notesField,InventoryID:req?.InventoryID,ProductID:req?.ProductID,requestor:req?.requestor,signedName:req?.signedName,deliverDriver:req?.deliverDriver,TransferAmount:Math.abs(outputRef.current?.['amount_'+req?.InventoryID]),TransferType:req?.TransferType, authorizor:req?.authorizor,timestamp:new Date()};
            // Final update
            output = {"$push": {"TransferRequests": transferPush}}

          dbUpdateValidate({model:'Inventory',queryID:true,query:req.InventoryID,update:output, idCreate:true},({success,info,err})=>{ 
                if(success){    
                  globalWrn('success', 'Successfully Rescheduled Delivery')   
                  if(AdminView === true) {setAdminPanelRefresh(!AdminPanelRefresh); setPopup2({open:false})} else setPopup({open: false}) 
                  }
                else {console.log("DELIVERY NOT SAVED!!!", err); globalWrn('error', 'There was a problem rescheduling your delivery')}
              })
          }
          else
          {
        // Create new transfer request
        if(AdminView) var transferPush = {deliveryDate: outputRef.current?.deliveryDate, Status:req?.Status,Notes:outputRef?.current?.notesField,InventoryID:req?.InventoryID,ProductID:req?.ProductID,requestor:req?.requestor,signedName:req?.signedName,deliverDriver:req?.deliverDriver,TransferAmount:Math.abs(outputRef.current?.['amount_'+req?.InventoryID]),TransferType:req?.TransferType, authorizor:req?.authorizor,timestamp:new Date()};
        else var transferPush = {Status:req?.Status,Notes:outputRef?.current?.notesField,InventoryID:req?.InventoryID,ProductID:req?.ProductID,requestor:req?.requestor,signedName:req?.signedName,deliverDriver:req?.deliverDriver,TransferAmount:Math.abs(outputRef.current?.['amount_'+req?.InventoryID]),TransferType:req?.TransferType, authorizor:req?.authorizor,timestamp:new Date()};
        // Final update
        output = {"$push": {"TransferRequests": transferPush}}

      dbUpdateValidate({model:'Inventory',queryID:true,query:req.InventoryID,update:output, idCreate:true},({success,info,err})=>{ 
            if(success){    
              globalWrn('success', 'Successfully Rescheduled Delivery')   
              if(AdminView === true) {setAdminPanelRefresh(!AdminPanelRefresh); setPopup2({open:false})} else setPopup({open: false})
            }else {console.log("DELIVERY NOT SAVED!!!", err); globalWrn('error', 'There was a problem rescheduling your delivery')}
          })
        }

        // Edit previous quantity for old transfer request
        var arrayFilters = [{"el.timestamp": req.timestamp}]
        var output2 = {"$set": {"TransferRequests.$[el].TransferAmount": (Math.abs(req?.TransferAmount) - Math.abs(outputRef.current?.['amount_'+req?.InventoryID]))}}
        dbUpdateValidate({model:'Inventory',queryID:true,query:req.InventoryID,update:output2,arrayFilters:arrayFilters, idCreate:true},({success,info,err})=>{ 
              if(success){    
                globalWrn('success', 'Successfully Rescheduled Delivery')   
                if(AdminView === true) {setAdminPanelRefresh(!AdminPanelRefresh); setPopup2({open:false})} else setPopup({open: false})
              }else {console.log("DELIVERY NOT SAVED!!!", err); globalWrn('error', 'There was a problem rescheduling your delivery')}
            })

    }}))
        const prjManID = projectMap.get(data?.[0]?.ProjectID)?.ProjectState?.projManager
        var prjManEmail
        var prjManName

        if(teamMember.has(prjManID)) {
          prjManEmail = teamMember.get(prjManID)?.email
          prjManName = `${teamMember.get(prjManID)?.firstName} ${teamMember.get(prjManID)?.lastName}`
        }
        else CheckID({_id: prjManID, key: "User", map: teamMember, hydrate: 'teamMemberState', setMap: setTeamMember},(res)=>{
          prjManEmail = res?.email
          prjManName = `${res?.firstName} ${res?.lastName}`
        })

        var bodyField = data?.flatMap(x=>x?.TransferRequests?.map(y=>{if(y?.Completed !== true && y?.deliveryDate && stateRef?.current?.rightList?.filter(tmp=>tmp?.InventoryID === y?.InventoryID)?.length > 0) return `<li>${productMap.get(y?.ProductID)?.DISPLAY_NAME}: <b>${Math.abs(y?.TransferAmount)}</b></li>`})).join('')
        const body = `${UserMap.get('firstName')} ${UserMap.get('lastName')} made a reschedule delivery request. Go to your project to review it now.\n\nNotes Provided: ${outputRef?.current?.notesField || 'No notes added.'}\n\nItems Rescheduled:\n<ul>${bodyField}</ul>`

        var name_u = prjManName
        newNotification({Title:`A delivery to ${projectMap.get(data?.[0]?.ProjectID)?.ProjectTitle} needs review`,Body:body,Data:null,avatar:prjManID,Icon:null,cc:teamMember.get((divisionMap.get(projectMap.get(data?.[0]?.ProjectID)?.DivisionID)?.logManager))?.email,Choice:{AppNotification:true,Text:false,Email:true},Email:prjManEmail,EmailForm:"Warning",name:name_u,sender:UserMap.get('avatar'),receiver:prjManID})
    }

    useEffect(()=>{
      if(outputRef.current.notesField && stateRef.current.rightList && stateRef.current.rightList?.length > 0 && AdminView === false)
      {
        setPopup(p=>({...p,...{
          handleSubmit: submit
        }}))
      }
      else if(outputRef.current.deliveryDate && stateRef.current.rightList && stateRef.current.rightList?.length > 0 && (outputRef.current.rescheduleDeliveryDriver || data?.[0]?.TransferRequests?.filter(x=>x.Completed !== true)?.[0]?.deliverDriver) && AdminView === true)
      {
        setPopup2(p=>({...p,...{
          handleSubmit: submit
        }}))
      }
    },[stateRef.current.rightList, outputRef.current])



   return <Box sx={{padding: '1%', overflow: 'scroll'}}>
    <Card sx={{padding: '2%'}}>
      <Typography>Select the items to reschedule</Typography>
      {stateRef.current.transferListItems}
      <Divider sx={{padding: '1%', borderBottomWidth: 2}}/>
      <Typography sx={{marginTop: '1%'}}>Why are you rescheduling the items?</Typography>
      {stateRef.current.notes}
      <Divider sx={{padding: '1%', borderBottomWidth: 2}}/>
      <Typography sx={{marginTop: '1%'}}>Select The Quantity of Items to return</Typography>
      {data?.flatMap(x=>x.InventoryID.flatMap(y=>stateRef.current?.["QTYOrdered_"+[y]]))}
      <Divider sx={{padding: '1%', borderBottomWidth: 2}}/>
      {AdminView === true && <><Typography sx={{marginTop: '1%'}}>Select The Reschedule Date</Typography>
      {stateRef.current.dateSelector}
      <Divider sx={{padding: '1%', borderBottomWidth: 2}}/></>}
      {AdminView === true && <><Typography sx={{marginTop: '1%'}}>Select The Rescheduled Delivery Driver</Typography>
      {stateRef.current.delDriver}</>}
    </Card>
   </Box>

    }
    export default DeliveriesFormReschedule;