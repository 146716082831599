import React, { useEffect } from "react";
import useState from 'react-usestateref';
import { Typography, Paper, ToggleButton, ToggleButtonGroup, Divider, Box } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const ToggleBttnGroups = ({viewData:{state,Items,pramPass,defaultValue,style},That,action}) => {
    const [, setOutput, outputRef] = useState(Object);
    const [, setParentState, parentStateRef] = That
    if(defaultValue && defaultValue.constructor === Array) var defaultValue = defaultValue[0]

  function outpTest(a,b){
    return Object.entries(a).every(([k,v])=> Object.entries(b).some(([k2,v2])=>k2===k&&v2===v)) &&
    Object.entries(b).every(([k,v])=> Object.entries(a).some(([k2,v2])=>k2===k&&v2===v))
  }

    useEffect(()=>{
     if( (outputRef.current && Object.keys(outputRef.current).length>0 && (defaultValue&&outputRef.current) && !outpTest(outputRef.current,defaultValue) ) || (outputRef.current && Object.keys(outputRef.current).length>0 && !defaultValue) ){
       setParentState(p=>({...p,...{[state]:outputRef.current}}))
       if(action) action.forEach((x)=>x({[state]:outputRef.current,pramPass})) 
     }
    },[outputRef.current])

    useEffect(()=>{
      if(defaultValue) setOutput(defaultValue)
    },[defaultValue]) 
  
    const handleChange = ({i,type,val,subState})=>{
      var unItem = subState || type+i
      setOutput(p=>({...p,...{[unItem]:val}}))
    }

const paperStyle = { display: 'flex',flexDirection:'column', border: (theme) => `1px solid ${theme.palette.divider}`, flexWrap: 'wrap',width:'100%'}

return <Paper elevation={8} variant="outlined" sx={style ? {...paperStyle,...style} : paperStyle} >
          { Items?.flatMap(({type,label:header,items,subState},i)=>{
         var listed = [<StyledToggleButtonGroup size="small" exclusive={type==='single'} onChange={(e,v)=> handleChange({i:i,type,val:v,subState}) } aria-label={header} value={outputRef.current?.[subState || type+i]} sx={{flex:1,justifyContent:'space-evenly'}}>
                      { items?.flatMap(({label:value})=>
                      <ToggleButton aria-label={value} value={value}><Typography sx={{lineHeight:1.1}}>{value}</Typography></ToggleButton>
                     )}
                      </StyledToggleButtonGroup>,
                      i+1 !== Items?.length && <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} /> ]
 if(header) return <Box sx={{display:'flex',flexDirection:'column',flex:1,position:'sticky',top:'1%',left:'1%',width:'100%',overflow:'auto'}}>{[ header && <Typography varient={'caption'} sx={{pl:'2%',lineHeight:1.5,fontSize:'12px',position:'sticky',top:0,left:0}}>{header}</Typography>,<Box sx={{display:'flex',width:'100%'}}>{listed}</Box>]}</Box>
 else return listed
          })}
        </Paper>
  }

  export default ToggleBttnGroups