import React, { useEffect, useRef, useCallback } from "react";
 import { useAtom } from 'jotai';
 import useState from 'react-usestateref';
 import dayjs from "dayjs";
 import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
 import * as localizedFormat from 'dayjs/plugin/localizedFormat';
 import * as isToday from 'dayjs/plugin/isToday';

 import Calendar from 'react-calendar';

 import 'react-calendar/dist/Calendar.css';

 import {Box,Button,Typography,Paper,Divider} from "@mui/material";
 dayjs.extend(localizedFormat);
 dayjs.extend(isToday);
 dayjs.extend(isSameOrBefore)

const DeliveriesControl=({dynamicMapData,dynamicComponent,globalState,viewData,waveLoad,CheckID,queryDB})=> {
  const mainState = useState(Object)
  const [, setState, stateRef] = mainState
  const [, setListProjName, projName] = useState(Array)
  const [, setDeliveries, DeliveriesRef] = useState(Array)
  const { ProjectID } = viewData
  const [projectMap,] = useAtom(globalState.projectMapState)
  const [inventoryMap,setInventoryMap] = useAtom(globalState.inventoryMapState)
  const [teamMember,setTeamMember] = useAtom(globalState.teamMemberState)
  const mapRef = useRef(null)
  const [value, onChange] = useState(new Date());

  var [, setPopup2] = useAtom(globalState.popupState2);

  const [AdminPanelRefresh,setAdminPanelRefresh] = useState(false)


  useEffect(()=>{
    // Get The Delivery
    queryDB({ model: 'Inventory', query: {'ProjectID': ProjectID, 'TransferRequests.TransferType': "Delivery"}, filter:{} }, (x) => {
          if(x?.length === 0) setState(p=>({...p,data: 0}))
          else x?.forEach(y=>{
          dynamicMapData({[y._id]: y}, inventoryMap, setInventoryMap);
          var {TransferRequests,DivisionID,ProjectID,ProductItems:[{id:ProductID}]} = y;
          TransferRequests = TransferRequests.filter(x=>x?.TransferType === "Delivery" && x?.Completed !== true && x?.TransferAmount > 0)
          var currRequests = TransferRequests.flatMap(x=>{return {...x, ProductID, InventoryID: y._id}})
                 if(currRequests?.length > 0) {
                setListProjName(p => {
                  // Ensure no duplicates
                  if(p.filter(x=>Object.values(x).includes(ProjectID?.[0]))?.length > 0) return [...p];
                  else if (p) return [...p, {[projectMap.get(ProjectID?.[0])?.ProjectTitle]: ProjectID?.[0]}] 
                  else return [{[projectMap.get(ProjectID?.[0])?.ProjectTitle]: ProjectID?.[0]}]
              })
                 let finalObj = {
                  ProjectID: ProjectID?.[0],
                  DivisionID,
                  ProductID: [ProductID],
                  InventoryID: [y?._id],
                  TransferRequests: currRequests
                 }
                 setDeliveries(p=> {
                  if(p?.filter(x=>x.ProjectID === y.ProjectID?.[0])?.length === 0)
                    return [...p,finalObj]
                  else if (p?.filter(x=>x.ProjectID === y.ProjectID?.[0])?.length > 0) return p.flatMap(tmp=>{ 
                    if(tmp.ProjectID === y.ProjectID?.[0]) 
                      return {
                          ProjectID:tmp.ProjectID,
                          DivisionID:tmp.DivisionID,
                          ProductID:tmp.ProductID.concat(ProductID),
                          InventoryID:tmp.InventoryID.concat(y._id),
                          TransferRequests:currRequests
                        };
                    else return tmp;
                      })
                  else if (!p) return [finalObj]
                 })  
                }
                 })
                })
  },[AdminPanelRefresh])

  useEffect(()=>{
    if(DeliveriesRef.current && DeliveriesRef.current?.length > 0)
    {
      setState(p=>({...p,data: DeliveriesRef.current}))
    }
  },[DeliveriesRef.current])

  useEffect(()=>{
    // Initialize the top list array
    if(stateRef.current?.finalData?.length > 0 && (!stateRef.current?.chosenOne || !(stateRef.current?.chosenOne?.length > 0)) ) {
        var list = stateRef.current?.finalData?.map((x,i)=>{if(i === 0) return true; else return false})
        
        setState(p=>({...p,
            chosenOne: list,
            chosenOneData: stateRef.current.data?.[0]
        }))
    }
  },[stateRef.current.finalData])

  const chosenOne = (i) => {
    var temp = stateRef?.current?.chosenOne.map((x,i2)=>{if(i2 !== i) return false; else return true;})
    setState(p=>({...p, chosenOne: temp}))
  }

  function filterArrayBySubstring(array, substring) {
    return array.filter(obj => {
        // Get the key of the current object
        let key = Object.keys(obj)[0];
        // Convert both the key and the substring to lowercase for case-insensitive comparison
        return key.toLowerCase().includes(substring.toLowerCase());
    });
}

  useEffect(()=>{
    if(stateRef.current?.SearchBar && stateRef.current?.SearchBar !== "") // If Search Query
    {
        // Reset the list
        setState(p=>({...p, data: DeliveriesRef.current}))

        const result = filterArrayBySubstring(projName.current, stateRef.current?.SearchBar);

        const valuesArr = result.flatMap(x=>Object.values(x))

        const data = stateRef?.current?.data?.filter(x=>valuesArr?.includes(x.ProjectID))

        setState(p=>({...p, data: data}))
    }
    else if(stateRef.current?.SearchBar == "") // Reset Search Query
    {
      setState(p=>({...p, data: DeliveriesRef.current}))
    }
  },[stateRef.current?.SearchBar])

  function getRandomDarkColor() {
    function getRandomHexComponent() {
        let component = Math.floor(Math.random() * 128); // Range 0-127 for darker colors
        return component.toString(16).padStart(2, '0');
    }

    const r = getRandomHexComponent();
    const g = getRandomHexComponent();
    const b = getRandomHexComponent();

    return `#${r}${g}${b}`;
}

  const tileContent = useCallback(({ date, view }) => {
    // Add class to tiles in month view only
    if (view === 'month') {
      // Check if a date React-Calendar wants to check is on the list of dates to add class to
      return stateRef.current.finalData?.map(val=>{
      if ([val?.[0]?.deliveryDate].find(dDate => dayjs(dDate).format('DD/MM/YYYY') === dayjs(date.toISOString()).format('DD/MM/YYYY'))) {
        return <Button sx={{backgroundColor:getRandomDarkColor}} onClick={()=>openItems(date)}>Delv.</Button>;
      }
    })
    }
  },[stateRef.current.data])

  const openItems=(date)=>{
    const timeDate = stateRef.current.chosenOneData?.TransferRequests.filter(x=>x?.Completed !== true)?.flatMap(x=>x?.deliveryDate).find(dDate => dayjs(dDate).format('DD/MM/YYYY') === dayjs(date.toISOString()).format('DD/MM/YYYY'))
    const label = teamMember.get(projectMap.get(stateRef.current.chosenOneData?.ProjectID)?.SalesState?.SiteID?.[0])?.name

    setPopup2(p=>({...p,
      open: true,
      inputField:<Box sx={{padding: '1%'}}>Delivery to: {label} at {dayjs(timeDate).format('lll')}</Box>,
      handleClose: ()=>{setPopup2({open: false})}
    }))
  }

  function groupTransferRequestsByDate(data) {
    const transferRequests = data.TransferRequests;
    const groupedRequests = [];

    const groupedByDate = transferRequests.reduce((acc, request) => {
        const deliveryDate = new Date(request.deliveryDate).toDateString(); // Normalize to date only
        if (!acc[deliveryDate]) {
            acc[deliveryDate] = [];
        }
        acc[deliveryDate].push(request);
        return acc;
    }, {});

    for (const date in groupedByDate) {
        groupedRequests.push(groupedByDate[date]);
    }

    return groupedRequests;
}

  useEffect(()=>{
    if(stateRef.current.data) {
      const arrTest = groupTransferRequestsByDate(stateRef.current?.data?.[0])
    setState(p=>({...p, 
      finalData: arrTest,
      scrollView:
      <Box style={{width: '100%', height: '100%', flex:1, overflowX: 'scroll', marginBottom: '1%', padding: '1%', borderRadius: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center'}}> 
      {arrTest?.flatMap((x,i)=>
      <>
          <Box sx={{display: 'flex', width: '100%', height: '70%', justifyContent: 'center', padding: '1%', marginRight: '1%', marginLeft: '1%', borderRadius: '5px', backgroundColor: stateRef?.current?.chosenOne?.[i] === true ? '#393939' : '#191919'}} onClick={()=>{chosenOne(i); console.log("tset", stateRef.current.chosenOne)}}>
              <Typography sx={{alignContent: 'center'}}>Delivery on: {dayjs(x?.[0]?.deliveryDate).format('LLL')}</Typography>
          </Box>
          { (i + 1) < stateRef?.current?.data?.length && <Divider sx={{height: '5em'}} orientation="vertical"/>}
          </>
      )}
      </Box>
    }))
  }
  },[stateRef.current.data, stateRef.current.chosenOne,DeliveriesRef.current])

  // Determine the chosen value
  useEffect(()=>{
    if(stateRef.current.chosenOne && stateRef.current?.chosenOne?.length > 0 && stateRef.current.data)
    {
      const index = stateRef.current?.chosenOne.map((x,i)=>{if(x===true) return i})?.[0]
      setState(p=>({...p,chosenOneValue: stateRef.current.data?.filter((x,i)=>i===index)?.[0]}))
    }
    else if(stateRef.current.data) setState(p=>({...p,chosenOneValue: stateRef.current.data?.[0]}))
  },[stateRef.current.chosenOne])

  const trackDelivery = () => {
    if(stateRef.current.chosenOneValue)
    {
      const deliveryDriverArr = stateRef.current.chosenOneValue.TransferRequests.filter(x=>x.TransferType === "Delivery" && x?.Completed !== true && x?.deliverDriver);
      const deliveryDriver = deliveryDriverArr?.[deliveryDriverArr?.length - 1]?.deliverDriver;

      // Site ID
      const SiteID = projectMap.get(stateRef.current.chosenOneValue?.ProjectID)?.SalesState?.SiteID?.[0]

      // Check for site value
      CheckID({_id: SiteID, key: "Sites", map: teamMember, hydrate: 'teamMemberState', setMap: setTeamMember},(res)=>{
        const lat = res?.address?.location?.lat;
        const lng = res?.address?.location?.lng;

        setPopup2(p=>({...p,
          open: true,
          handleClose: ()=>{setPopup2({open:false})},
          inputField: <Box sx={{position:'relative',top:0,width:'100%',height:'700px',minWidth:'85vw',minHeight:'80vh',zIndex:0}}>{dynamicComponent('geoMap','component',{loc: {lat: lat, lng:lng},offSet:{left:-35},ref: mapRef,managerView:true,projID:stateRef.current.chosenOneValue?.ProjectID, data:[stateRef.current.chosenOneValue], 
            deliverDriver:deliveryDriver,
            SiteID:SiteID},mainState)}</Box>,
        }))
      })
    }
  }

  function calculateTotalTransfers(data) {
    const transferSums = {};

    data.forEach(request => {
        const productId = request.ProductID;
        const transferAmount = Math.abs(request.TransferAmount);

        if (transferSums[productId]) {
            transferSums[productId] += transferAmount;
        } else {
            transferSums[productId] = transferAmount;
        }
    });

    const resultArray = Object.keys(transferSums).map(key => {
        return { [key]: transferSums[key] };
    });

    return resultArray;
}
  
  // Select new date
  const changeDelv=()=>{
    const amount = calculateTotalTransfers(stateRef.current.finalData?.[stateRef.current.chosenOne.indexOf(true)])
    setPopup2(p=>({...p,
      open: true,
      title: 'Reschedule Delivery',
      zIndex:2,
      handleClose: ()=>{setPopup2({open:false})},
      inputField: <Box>{dynamicComponent('DeliveryFormReschedule','elements',{state:'rescheduleForm', AdminView: true, AdminPanelRefresh, setAdminPanelRefresh, data: [{...stateRef.current.chosenOneData, TransferRequests: stateRef.current.finalData?.[stateRef.current.chosenOne.indexOf(true)]}], TransferAmountRef: amount },mainState)}</Box>
    }))
  }

  return <Box sx={{padding: '1%', backgroundColor: '#191919'}}>
    {
        stateRef?.current?.data !== undefined ? 
        (<>{
            stateRef?.current?.data === 0 ? <Typography>There is no deliveries for this project!</Typography>
            :
        <Box sx={{padding: '1%', display: 'flex', justifyContent: 'center', flexDirection: 'column', overflowX:'scroll'}}>
          <Box 
            style={{width: '100%', height: '100%', flex:1, marginBottom: '1%', padding: '1%', borderRadius: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center'}}>
            <Calendar style={{flex: 1}} tileContent={tileContent} onChange={onChange} value={value} />
          </Box>
            {stateRef.current.scrollView}
            <Divider />
            {dynamicComponent('InputField','elements',{state:'SearchBar',label:'Search',type:'text',style:{marginTop: '2%'}},mainState)}
          <Box sx={{flexDirection: 'row', marginTop: '0.5%'}}>
          {dynamicComponent('DefaultButton','elements',{state:'ViewDelivery',
            label:(stateRef.current.chosenOneValue && stateRef.current.chosenOneValue.TransferRequests.filter(x=>x?.Completed !== true)?.length > 0) ? 'Track Delivery' : 'No Active Delivery',
            type:'button',
            style: {backgroundColor:'#483fa6'},
            disabled:(stateRef.current.chosenOneValue && stateRef.current.chosenOneValue.TransferRequests.filter(x=>x?.Completed !== true)?.length > 0) ? false : true,
            full:true},mainState,[trackDelivery])}
          </Box>
          <Box sx={{flexDirection: 'row', marginTop: '1%', gap: '1%', display: 'flex', justifyContent: 'space-evenly'}}>
            {dynamicComponent('DefaultButton','elements',{state:'ChangeDate',
            label:'Set/Change Delivery Information',
            type:'button',
            style: {width: '100%', backgroundColor: '#bf9532'},
            full:true},mainState,[changeDelv])}
          </Box>
        </Box>
        }</>)
        :
        waveLoad()
    }
  </Box>;
}
  export default DeliveriesControl;