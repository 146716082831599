import React from "react";
import useState from 'react-usestateref';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import {Button, Menu,MenuItem, Fade, Typography, ListItemText, ListItemIcon, Divider} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function DropDown({That,viewData,action}){
  const {label,icon,list,replace, defaultValue, state, buttonStyles, fullWidth, menuStyle, disabled} = viewData
  const [, setState, stateRef] = useState(Object)
  const [anchorEl, setAnchorEl] = useState(null);
  const [, setParentState, ] = That
  const [, , openRef] = useState(false);
  const handleClick = ({currentTarget}) => {
    if(!stateRef.current.open) setState(p=>({...p,...{open:true}}))
    setAnchorEl(currentTarget);
  };

  const handleClose = ({value}) => {
  setState(p=>({...p,...{open:false}}))
  setTimeout(() => {
    if(value){ 
      setParentState(p=>({...p,...{[state]:value}}))
      if(action) action.forEach((x)=>x({[viewData.state]:value,pramPass:viewData.pramPass})) 
      }
  }, 250)
  };


  const replaceText=()=>{
    var newLabel = label
    if( defaultValue ) var newLabel = defaultValue
    if(replace && stateRef.current.label) var newLabel = stateRef.current.label
    return <Typography noWrap gutterBottom variant="caption" sx={{display: '-webkit-box',WebkitLineClamp: 2, WebkitBoxOrient: 'vertical',overflow: 'hidden', textOverflow: 'ellipsis', lineHeight:1.1,margin:'auto'}}>{newLabel}</Typography>
  }

return [label ? <Button
        id="drp-button"
        aria-controls={openRef.current && 'fade-menu'}
        aria-haspopup="true"
        aria-expanded={openRef.current && 'true' }
        variant="contained"
        fullWidth={fullWidth?fullWidth:false}
        onClick={handleClick}
        sx={buttonStyles ? buttonStyles :null}
        startIcon={ icon ? icon : null}
        endIcon={<KeyboardArrowDownIcon sx={{m:'auto',minWidth:'3ch'}} />}
        disabled={disabled}
      >
      {replaceText()}
      </Button> : 
      <IconButton aria-label="options" onClick={handleClick} sx={buttonStyles?buttonStyles:null}>
       {icon ? icon : <MoreVertIcon sx={{m:'0px!important'}} />}
      </IconButton>,
      <Menu id="fade-menu" MenuListProps={{ 'aria-labelledby': 'fade-button',  sx: menuStyle}}
        anchorEl={anchorEl}
        open={stateRef.current.open===true?true:false
        }
        onClose={handleClose}
        TransitionComponent={Fade}
      >
      { list?.constructor === Array ?
        list?.flatMap(x=>{
        if(x?.constructor === Object){ var {primary,secondary,label,icon,divider,dividerText,action,style} = x
        return [primary && <MenuItem key={primary} onClick={()=>{ if(action) action(x); else handleClose({value:primary}) }} sx={{...style,gap:'3%'}}>
        {icon && <ListItemIcon sx={style}>{icon}</ListItemIcon>}
        {primary && <ListItemText
            sx={{display:'flex',flexDirection:'column'}}
            primary={<Typography variant="body2" sx={{lineHeight:1}}>{primary}</Typography>}
            secondary={secondary&&<Typography variant="caption" color="text.secondary" sx={{lineHeight:1}}>{secondary}</Typography>}
          />}
        {label && <Typography variant="body2" color="text.secondary" >{label}</Typography>}
        </MenuItem>,
        divider && <Divider>{dividerText && dividerText }</Divider>]
        }
        return <MenuItem key={x} onClick={()=>{handleClose({value:x});setState(p=>({...p,...{label:x}}))}}>{x}</MenuItem>
        })
        : list?.constructor === Object &&
         Object.entries(list).map(([k,v])=><MenuItem key={k} onClick={()=>{handleClose({value:v});setState(p=>({...p,...{label:k}}))}}>{k}</MenuItem>)
        }
      </Menu>]
}