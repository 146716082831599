import React, { useEffect, useRef } from "react";
import { useAtom } from 'jotai';
import useState from 'react-usestateref';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import {Avatar, Paper,Box,Drawer,Checkbox,InputLabel,MenuItem,FormControl,Select,Chip,Typography,Accordion,AccordionSummary,List,ListItemText,ListItemIcon,ListItem,IconButton,InputBase,Divider,ListSubheader,ListItemButton,Collapse,Button } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import SegmentIcon from '@mui/icons-material/Segment';

import {ExpandMore,ExpandLess,Cancel,KeyboardArrowDown} from '@mui/icons-material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import _without from "lodash/without";

const darkTheme = createTheme({palette:{mode:'dark'}});

  export default function ProjectBuilder(props){
  const {That,viewData,dynamicComponent} = props
  const {label, defaultValue,state ,startIcon, full, color} = viewData
  const [, setParentState, parentStateRef] = That
  const mainState = useState(Object)
  const [, setState, stateRef] = mainState

  const outputState = useState( parentStateRef.current?.[state] ? parentStateRef.current?.[state] : defaultValue?.constructor === Object ? Object.assign({},defaultValue) : [])
  const [, , outputRef] = outputState

  useEffect(() => {
   if(stateRef.current.drawerState===false&&outputRef.current) setParentState(p=>({...p,[state]:Object.assign({},...Object.entries(outputRef.current).flatMap(([k,v])=>({[k]:Object.entries(v).flatMap(([k,v],i)=>({...v,id:k}))}))) }))
    setState(p=>({...p, 
      mainBttn: dynamicComponent('DefaultButton','elements',{state:'ProjectBuilder',label:label,type:'button',startIcon:startIcon,color:color,full:full},mainState,[()=>{ setState(p=>({...p,drawerState: !stateRef.current.drawerState }))} ])
    }))
  },[stateRef.current.drawerState])

return [ <Box sx={{m:'auto'}}>
      { stateRef.current.mainBttn }
      </Box>,
      <Drawed {...props} That={mainState} Output={outputState} />]
  }

function Drawed(props){
  const {That} = props
  const [, setParentState, parentStateRef] = That

const closeDrawer =()=> () => setParentState(p=>({...p,drawerState: false }))

return <ThemeProvider theme={darkTheme}>
        <Drawer
          anchor={'right'}
          PaperProps={{ sx: {maxWidth:'80vw', backgroundColor:'rgba(0,0,0,.35)',zIndex:99999,backdropFilter:'blur(15px)',padding:'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)'}}}
          open={parentStateRef.current.drawerState ? parentStateRef.current.drawerState : false}
          onClose={closeDrawer()}
          >
        <ChipsArray {...props}/>
        <Accod {...props} />
        <SearchBar {...props} />
        </Drawer>
      </ThemeProvider>
}

function Accod(props){
  const {viewData,Output} = props
  const {list,divisionName} = viewData
  const [, setOutput, outputRef] = Output
  const build = outputRef.current ? Object.keys(outputRef.current) : []
  const [expanded, setExpanded] = useState(false)
  const accordion = useRef(null)

const handleChange = (panel) => (event, isExpanded) => setExpanded(isExpanded ? panel : false)

return <Box sx={{width:'100%'}} role="presentation">
          <Box ref={accordion} style={{padding:'2%'}}> {
    list && Object.keys(list).flatMap((x,i)=>
     <Accordion expanded={expanded === `panel${i}` || (expanded===false && divisionName === x)} onChange={handleChange(`panel${i}`)} >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1bh-content"
          id={`panel${i}bh-header`}
        >
        <Box sx={{display:'flex',flex:1,flexDirection:'column'}}>
        <Box sx={{display:'flex',flex:1,m:'auto',width:'100%',gap:'5%',justifyContent:'space-between'}}>
        <Typography variant="body2" sx={{ width: '33%', flex: 1,m:'auto',lineHeight:1 }}> {x.toUpperCase()} </Typography>
        <Checkbox 
                checked={build.includes(x)}
                onClick={()=>{ build.includes(x) 
                  ? setOutput(p=> Object.assign({},...Object.entries(p).filter(([k,v])=>k!==x).map(([k,v])=>({[k]:v}))))
                  : setOutput(p=>({...p, [x]:list[x]})) 
                }}
                 />
         </Box>
         <ListItem>
              <UserFilters {...props} catID={x}/> 
          </ListItem>
          </Box>
        </AccordionSummary>
      </Accordion>
) }</Box> </Box> 
}

function UserFilters(props){
  const {That,viewData,catID,Output,dynamicComponent,handlePopupClose,globalState} = props
  const [, setPopup] = useAtom(globalState.popupState)
  const {list} = viewData
  const [, setOutput, outputRef] = Output
  const [, setFiltered, filterRef] = useState(outputRef.current?.[catID] ? Object.entries(outputRef.current[catID]).filter(([k,v])=>v.label).flatMap(([k,v])=>v): [])

  const handleChange = (event) => {
    const { target: { value } } = event
    setFiltered( typeof value === 'string' ? value.split(',') : value )
    setOutput(p=>({...p, [catID]:value}))
  }

  const handleDelete = (e,val) => {
    e.preventDefault();
    setFiltered(p=> p.filter(x=> x.label!==val.label))
    setOutput(p=>({...p,[catID]:filterRef.current}))
  };

  useEffect(()=>{
   if(outputRef.current?.[catID] === undefined && filterRef.current?.length>0 ) setFiltered([])
   if(outputRef.current?.[catID] !== undefined && filterRef.current?.length===0 ) setFiltered(outputRef.current?.[catID] ? Object.entries(outputRef.current[catID]).filter(([k,v])=>v.label).flatMap(([k,v])=>v): [])
  },[outputRef.current?.[catID]])

  const addInventory=(v)=> setPopup(p=>({...p,
      open:true,
      title:`Add unit ammount to ${v.label}`,
      inputField: dynamicComponent('InputField','elements',{state:'UnitCount',label:'Unit Amount',defaultValue:v.units?v.units:null,type:'number'},That,[(x)=>{ 
        
        setFiltered(p=>{ p.find(x=>x.label === v.label).units=x.UnitCount; return p })
        setOutput(p=>({...p,[catID]:filterRef.current}))
      }]),
      handleClose: ()=>{handlePopupClose()},
      cancelLabel: 'Close',
      nameInputSets: {},
  }))


  return <FormControl sx={{width:"100%"}} className={'formList'}>
          <InputLabel id="demo-mutiple-chip-checkbox-label">
         { outputRef.current?.[catID] ? 'Filtered Options' : 'Selection Options' }
          </InputLabel>
          <Select
            labelId="demo-mutiple-chip-checkbox-label"
            id="demo-mutiple-chip-checkbox"
            multiple
            value={filterRef.current}
            onChange={handleChange}
            IconComponent={KeyboardArrowDown}
            renderValue={(sel) => 
              <Box clickable sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {sel.map((v,i)=>
                <Box sx={{display:'flex',backgroundColor:'rgba(255, 255, 255, 0.16)',gap:1,padding:1,borderRadius:'8px'}}>
                  <TaskAltIcon sx={{m:'auto'}}/>
                <Box>
                  <Typography fontSize={"small"} variant="caption"  sx={{ overflow: 'hidden', textOverflow: 'ellipsis', lineHeight:1,whiteSpace:'normal', display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical' }}>{v.label}</Typography>
                  <Box sx={{display:'flex'}}>
                  <Box onMouseDown={(e)=>{e.preventDefault();addInventory(v)}}><Button size="small" variant="outlined" startIcon={<LibraryAddIcon />} >{v.units ? `${v.units} qty` :'Add'}</Button></Box>
                  <Cancel fontSize={"small"} sx={{m:'auto'}} onMouseDown={(e)=>{handleDelete(e,v);e.preventDefault()}} />
                  </Box>
                </Box>
              </Box>
            )}
            </Box> 
            }
          >
          { list[catID] && Object.entries(list[catID]).filter(([k,v])=>v.label).flatMap(([k,v],i)=>
              <MenuItem key={v.label+i} value={v}>
                <Checkbox checked={filterRef.current.some((x)=> x.label === v.label)} />
                <ListItemText primary={v.label} />
              </MenuItem>
          ) }
          </Select>
        </FormControl>
}

export function ChipsArray({Output,dynamicComponent}) {

  const [, setState, ] = useState(Object)
  const [, setOutput, outputRef] = Output
  const [chipData, setChipData] = useState([]);

  useEffect(()=>{
   if(outputRef.current){ 
    setChipData(Object.keys(outputRef.current))
    setState(p=>({...p, sorter: dynamicComponent('GridSorter','elements',{},Output) }))
   }
  },[outputRef.current])

  const handleDelete = (label) => () => {
    setChipData((chips) => chips.filter((chip) => chip !== label));
    setOutput(p=> Object.assign({},...Object.entries(p).filter(([k,v])=>k!==label).map(([k,v])=>({[k]:v}))) )
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd=({source,destination})=> {
    // dropped outside the list
    if (!destination) return;

    const items = reorder(
      chipData,
      source.index,
      destination.index
    );

    var reodered = Object.assign({},...Object.entries(outputRef.current).sort(([k],[k2])=> items.indexOf(k) - items.indexOf(k2) || 0 ).map(([k,v])=>({[k]:v})))
    setOutput(reodered)
  }

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    margin: `0 ${grid}px 0 0`,
    background: isDragging ? "lightgreen" : "transparent",
    ...draggableStyle
  });

  const getListStyle = (isDraggingOver, itemsLength) => ({
    background: isDraggingOver ? "lightblue" : "transparent",
    display: "flex",
    margin: '9px 0',
    width: itemsLength * 68.44 + 16,
    minWidth: '134px'
  });

return <Paper sx={{overflow: "scroll", minHeight: '50px',width:'100%',position:'sticky', top:0,left:0}}>
        <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => 
            <Box ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver, chipData.length)} {...provided.droppableProps} >
              {chipData.map((x, i) => 
                <Draggable key={x} draggableId={`${x+i}`} index={i}>
                  {(provided, snapshot) => 
                    <Box ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle( snapshot.isDragging, provided.draggableProps.style )} >
                       <Chip key={x+i} label={x} avatar={<Avatar>{i+1}</Avatar>} onDelete={handleDelete(x)} />
                    </Box> }
                </Draggable> )}
              {provided.placeholder}
            </Box>}
          </Droppable>
        </DragDropContext>
        </Paper>
}

export function SearchBar({That,viewData,Output}) {
  const {list} = viewData
  const [, setState, stateRef] = That
  const [, setOutput, outputRef] = Output
  const [open, setOpen] = useState(true);
  const module = useRef(null)
  const handleClick = () => {
    setOpen(!open);
  };

  return <Paper component="form" variant="outlined" sx={{ p: '2px 4px',m:'1% 1% 0', display: 'flex', position:'sticky', bottom: '.5%', flexDirection:'column', alignItems: 'center', width: '97%', zIndex:9999 }} >
      <Box sx={{display: 'flex',w:'100%'}}>
       <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <InputBase
        ref={module}
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search"
        inputProps={{ 'aria-label': 'search' }}
        onChange={({target})=> setState(p=>({...p, search: target.value?.length>0 ? target.value : undefined })) }
      />
     { stateRef.current.search &&
      [<Divider variant="middle" flexItem orientation="vertical" />,
      <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
        <CancelIcon onClick={()=>{ module.current.querySelector('input').value = ''; setState(p=>({...p, search: undefined })) }} />
      </IconButton>] }
      </Box>
      { stateRef.current.search  && [<Divider />,
      <List
      sx={{ width: '100%', bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
         { stateRef.current.search }
        </ListSubheader>
      }
    >
     { list && Object.entries(list)
     .filter(([k,v],i)=> Object.entries(v).some(([k,{label}],i)=> label.toLowerCase().includes(stateRef.current.search.toLowerCase())) )
     .flatMap(([k,v],i)=>{
    return [<ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <SegmentIcon />
        </ListItemIcon>
        <ListItemText primary={k} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>,
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={{pl:'15%'}}>
        { Object.entries(v)
        .filter(([k2,{label}],i)=> label.toLowerCase().includes(stateRef.current.search.toLowerCase()))
        .flatMap(([k2,{label}],i)=>{
        return <ListItemButton role={undefined} 
         onClick={()=>{ 
          var id = i+1
          setOutput(p=>({...p, [k]: !p[k] ? [{label,id}] : p[k]?.some((x)=> x.label === label) ? p[k]?.filter((x)=> x.label !== label) : [].concat(p[k],{label,id}) }))
        }} 
        dense>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={outputRef.current?.[k]?.some((x)=> x.label === label)}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText 
             primary={label}
               />
            </ListItemButton>
        }) }
        </List>
      </Collapse>]
     }) }
    </List>] }
    </Paper>
}