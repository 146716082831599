import React, {useEffect} from "react";
import { useAtom } from 'jotai';
import useState from 'react-usestateref';
import PropTypes from 'prop-types';
import {Typography, Box, Chip, LinearProgress} from '@mui/material/';
import DoneIcon from '@mui/icons-material/Done';
import dayjs from 'dayjs';

function LinearProgressWithLabel(props) {
return <Box key={props.series.label+props.series.keyNumb} sx={{ display: 'flex',flexDirection:'column',flex:1,m:'1% 1% 0' }}>
    <Box sx={{ display: 'flex', alignItems: 'center'}}>
      <Box sx={{flex:1}}>
        <LinearProgress variant={props.variant ? props.variant : "determinate"} value={Math.round(props.value)} valueBuffer={props.valueBuffer} />
        <Box sx={{ display:'flex',alignItems:'center'}}>
        { props.series.icon ? props.series.icon : null }
        { props.series ? <Typography sx={{fontSize:10 }} color="text.secondary">{`${props.series.label ? props.series.label : ''} ${props.series.start+' of '+props.series.end }`}</Typography> : null }
      </Box>
      </Box>
      <Box sx={ props.series ? {m:'auto', transform:'translateY(-30%)'} : null }>
      <Chip label={`${Math.round(props.value)}%`} size="small" sx={{backgroundColor: Math.round(props.value)>=100 ?'#0061AD':'rgb(0, 48, 86)'}} avatar={Math.round(props.value)>=100?<DoneIcon/>:null} />
      </Box>
    </Box>
  </Box>
}

LinearProgressWithLabel.propTypes = { value: PropTypes.number.isRequired };
export default function ProgressPercent({That, globalState, viewData}){
const {list, vert, projID} = viewData
const { ProjectID } = projID

const [projectMap,] = useAtom(globalState.projectMapState)
const [,setState, stateRef] = useState({secure:true})
const [, setParentState, parentStateRef] = That
const ProjectData = projectMap.get(ProjectID)
const [projectStats] = useAtom(globalState.projectsStatsState)
if(projectStats.has('quickStatsToday')) var dataSats = projectStats.get('quickStatsToday')?.find(({projID})=>projID.ProjectID===ProjectID)
var projFollow = ProjectData.ProjectState?.teamLogg ? ProjectData.ProjectState.teamLogg.flatMap(x=>x._id): null

const percentChange=(partialValue,totalValue) => (100 * partialValue) / totalValue || 0

useEffect(()=>{
  if(list&&projFollow) list.forEach(({type})=> {
      if(type=='production'){
        var gather =  dataSats?.Production?.projComplete || 0
        var projCount = dataSats?.UnitCount || 0
        if(dataSats?.Production?.projCompleteToday>0) var valueBuffer = percentChange(dataSats?.Production?.projCompleteToday,projCount)
      } else if(type=='deliveries'){
        var gather = ProjectData?.Deliveries?.filter(x=>dayjs().isAfter(dayjs(x.deliveryDate)))?.map(x=>x.unitAmmount)?.reduce((a,b)=>a+b,0) | 0 
        var projCount = dataSats?.UnitCount || 0
        var valueBuffer = percentChange(ProjectData?.Deliveries?.map(x=>x.unitAmmount)?.reduce((a,b)=>a+b,0) | 0,projCount)  
      }
    
    setState(p=>({...p,...{
      [type]: {currVal: gather,
        totalVal: projCount,
        valueBuffer: valueBuffer,
        perctComp: percentChange(gather,projCount)
      }
    }}))
  })
},[list,dataSats?.Production?.projComplete,ProjectData?.Deliveries ])

useEffect(()=>{
if(list?.filter(({type})=> stateRef.current?.[type]?.perctComp).length===0) setParentState(p=>({...p,...{percentageEmpty:true}}))
else if(list?.filter(({type})=> stateRef.current?.[type]?.perctComp).length>0 && parentStateRef.current.percentageEmpty ) setParentState(p=>({...p,...{percentageEmpty:false}}))
},[stateRef.current])

return list?.filter(({type})=> stateRef.current?.[type]?.perctComp).length>0 && <Box key={'perctContainer'+ProjectID} sx={vert ? { width: '100%',display:'flex',flexDirection:'column'} : { width: '100%',display:'flex',mr:'2%'}}>
  {list.filter(({type})=> stateRef.current?.[type]?.perctComp ).map(({label,icon,type},i)=>
       <LinearProgressWithLabel key={"linearProg"+i} variant={stateRef.current?.[type]?.valueBuffer?"buffer":null} value={stateRef.current?.[type]?.perctComp ? stateRef.current[type].perctComp : 0} valueBuffer={stateRef.current?.[type]?.valueBuffer} series={{label:label,start:stateRef.current?.[type]?.currVal,end:stateRef.current?.[type]?.totalVal,icon:icon,keyNumb:ProjectID}} />
      )}
      </Box>
}
