import React, { useEffect } from "react";
import { useAtom } from 'jotai';
import useState from 'react-usestateref';
import { styled } from '@mui/material/styles';
import { faBarcode, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar,Paper,Box,Grid,Typography } from '@mui/material';

const Item = styled(Paper)(({ theme })=>({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: 0,
    textAlign: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%',
    color: theme.palette.text.secondary,
  }));

  export const statuses = [{
    id: 1, name: 'Shipping',
  }, {
    id: 2, name: 'Partial Received',
  }, {
    id: 3, name: 'Warehouse Received',
  }, {
    id: 5, name: 'Partially Received and Delivered',
  }, {
    id: 6, name: 'Partial to Site',
  }, {
    id: 7, name: 'Delivered to Site',
  }, {
    id: 8, name: 'Returned for RMA',
  }, {
    id: 9, name: 'Stock',
  }];

const CreateRequest=(props)=>{
    const {viewData,globalState,useIsMobile,globalWrn,dbUpdateValidate,dynamicComponent,action,imageCompress} = props
     const {defaultValue,categoryLabel,catagory} = viewData
      const outputState = useState(Object)
      const mainState = useState(Object)
      const [,setState, stateRef] = mainState
      
      const [,setPopup3] = useAtom(globalState.popupState3)
      const [,setPopup2] = useAtom(globalState.popupState2)
      const [,setOutput, outputRef] = outputState

      const useAndMobile = useIsMobile(560)

      function latestState(){
        return defaultValue?.x || outputRef.current?.x
      }

      useEffect(()=>{
        if(catagory && catagory.length>0 ) var drop = catagory
        else if(categoryLabel) var drop = categoryLabel?.flatMap((x)=>({primary:x})) 

      setPopup2(p=>({...p,
        headerButton: dynamicComponent('DropDown','elements',{ state: 'CATEGORY_DISPLAY_NAME', label: 'Select A Category', list: drop, defaultValue:outputRef.current?.CATEGORY_DISPLAY_NAME || defaultValue?.CATEGORY_DISPLAY_NAME, replace:true },outputState,[({CATEGORY_DISPLAY_NAME})=>setOutput(p=>({...p,CATEGORY_DISPLAY_NAME:CATEGORY_DISPLAY_NAME}))]),
      }))
      },[defaultValue?.CATEGORY_DISPLAY_NAME,outputRef.current,catagory,categoryLabel])

      const closePopup2=()=>{
        setPopup2({open:false})
      }

      const submitItems=()=>{
        if(Object.keys(outputRef.current).length>0) dbUpdateValidate({model:'Products',queryID:defaultValue?._id?true:false,query:defaultValue?._id || outputRef.current,update:outputRef.current},({success,info,err})=>{ 
              if(err) {
                globalWrn('error',`We had an issue ${defaultValue?'Updating':'Creating'} ${outputRef.current?.DISPLAY_NAME||defaultValue?.DISPLAY_NAME}.`)
              }
                else if(success){ 
                closePopup2()
                globalWrn('success',`${outputRef.current?.DISPLAY_NAME||defaultValue?.DISPLAY_NAME} was ${defaultValue?'Updated':'Created'}.`)
                uploadImage(info._id)
                if(action) {
                  setTimeout(()=>{
                    action.forEach((x)=>x({reload:true})) 
                  },2000)
                }
              }
          })

        if(defaultValue?._id && stateRef.current.uploader) uploadImage(defaultValue?._id)
       }

       function uploadImage(x){ 
        var path = 'public/uploads/productImages/'
        stateRef.current?.uploader?.forEach((data)=>{
          imageCompress({image:data,writePath:path,fileName: `${x}.${data.name.split(".").pop()}` },({sucess,err})=>{
            if(sucess===true) dbUpdateValidate({model:'Products',queryID:true,query:x,update:{IMAGE_SPOTLIGHT:`https://www.dev-services.ekc.app/profiles/productImages/${x}.${data.name.split(".").pop()}` }},({success,info,err})=>{ 
                  if(err) globalWrn('error',`We had an issue saving the product image for ${outputRef.current?.DISPLAY_NAME}.`)
                  if(sucess) closePopup2()
            })
           else globalWrn('error',`There was an error uploading your product image.`)
          })
        })
       }

       useEffect(()=>{
        if(outputRef.current && ((defaultValue && (Object.keys(outputRef.current).length>0 || stateRef.current?.uploader)) || (['MODEL_NUMBER','PART_NUMBER','DISPLAY_NAME','MSRP','MANUFACTURER'].every(x=> Object.keys(outputRef.current).includes(x)))) ) {
          setPopup2(p=>({...p,
            open:true,
            submitLabel: defaultValue?'Update':'Create',
            disableEnter: true,
            handleSubmit: submitItems,
          }))
        }
       },[outputRef.current, stateRef.current?.uploader])

       const deleteDefaultScan = () =>{
        globalWrn('info','You have marked the default serial number for deletion!')
        closePopup3Func()
      }

       useEffect(()=>{ setState(p=>({...p,
        delScanner: dynamicComponent('DefaultButton','elements',{state:'scanDel',label:`Delete default: ${latestState('SN')}`,type:'button',color:'#c0392b',startIcon:<FontAwesomeIcon icon={faTrash}/>,full:true},outputState,[()=>{deleteDefaultScan()}]),
      })) },[latestState('SN')])
    
    useEffect(()=>{ setState(p=>({...p,
      setScanButt: dynamicComponent('DefaultButton','elements',{state:'scanSet',label:stateRef.current.defaultButtonLabel || 'Set Bar Code',type:'button',color:'#32a7e2',startIcon:<FontAwesomeIcon icon={faBarcode}/>,full:true},mainState,[()=>{
        setState(p=>({...p,...{defaultButtonLabel: "Scan Now!"}})); 
        runBarcodeScan();
      }]),
    })) 
    },[stateRef.current.defaultButtonLabel])

    const closePopup3Func=()=>{
      setPopup3({open: false})
    }

    const defaultScan = ({SerialNumber})=>{
      setState(p=>({...p,defaultButtonLabel: `Bar Code: ${SerialNumber}`}))
      setOutput(p=>({...p,SN: SerialNumber}))
      setTimeout(closePopup3Func,1800)
    }

    useEffect(()=>{
      setState(p=>({...p,
      scanner: dynamicComponent('scanner','component',{defaultNum:10,stateChg:['onClick'],state:'defaultScan', manualScan:true, numPopup: true, items:['SN'], bottomLabel: latestState('SN') ? `Current Bar Code: ${latestState('SN')}` : 'Set Bar Code:'},outputState,[defaultScan])
    })) },[latestState('SN')])

       useEffect(()=>{
               setState(p=>({...p,scannerMenu:  <Box sx={{display:'flex',flexDirection:'column',gap:'5px',p:'1%'}}>{[stateRef.current?.setScanButt,stateRef.current.scanner,defaultValue?.SN?stateRef.current.delScanner:null]}</Box>,
        }))
      },[])

useEffect(()=>{
  if(defaultValue) //check if exists
    var {MANUFACTURER,MODEL_NUMBER,PART_NUMBER,NOTES,LINK,DISPLAY_NAME,SHORT_DESCRIPTION,IMAGE_SPOTLIGHT,CHECK_OUT,SN,MSRP,SN} = defaultValue

    setState(p=>({...p,
      modelNum: dynamicComponent('InputField','elements',{state:'MODEL_NUMBER',label:'Model Number',defaultValue:MODEL_NUMBER},outputState),
      partNum: dynamicComponent('InputField','elements',{state:'PART_NUMBER',label:'Part Number/UPC',defaultValue:PART_NUMBER},outputState),
      name: dynamicComponent('InputField','elements',{state:'DISPLAY_NAME',label:'Item Name',defaultValue:DISPLAY_NAME},outputState),
      price: dynamicComponent('InputField','elements',{state:'MSRP',label:'Price (Most Recent MSRP)', type:'currency',defaultValue:MSRP},mainState,[({MSRP})=> setOutput(p=>({...p,
        MSRP: parseFloat(MSRP)
      }))]),
      atCost: dynamicComponent('InputField','elements',{state:'AT_COST',label:'Current Cost Ammount(at cost)',type:'currency'},mainState,[({AT_COST})=> setOutput(p=>({...p,
        AT_COST: parseFloat(AT_COST)
      }))]),
      manufacturer: dynamicComponent('InputField','elements',{state:'MANUFACTURER',label:'Manufacturer Name',defaultValue:MANUFACTURER},outputState),
      description: dynamicComponent('InputField','elements',{state:'SHORT_DESCRIPTION',label:'Description',defaultValue:SHORT_DESCRIPTION},outputState),
      notes: dynamicComponent('InputField','elements',{state:'NOTES',label:'Notes (Optional)',defaultValue:NOTES},outputState),
      checkOut: dynamicComponent('Switcher', 'elements',{state:'CHECK_OUT',label:"Equipment Check-Out",style:{flex:'unset'},defaultValue:CHECK_OUT},outputState),
      hyperlink: dynamicComponent('InputField','elements',{state:'LINK',label:'Website Link to Marketplace',defaultValue:LINK},outputState),
      currImage: <Avatar sx={{height: '60%', width:'60%', margin:'auto',aspectRatio:1/1}} alt="Remy Sharp" src={IMAGE_SPOTLIGHT?.startsWith("https://www.dev-services.ekc.app") ?IMAGE_SPOTLIGHT:
      IMAGE_SPOTLIGHT?.startsWith('https://cdn-images.av-iq.com/') ? IMAGE_SPOTLIGHT : `https://cdn-images.av-iq.com/${IMAGE_SPOTLIGHT}`}/>,
      image: dynamicComponent('Dropzone','elements',{state:"uploader"},mainState),
      defaultButtonLabel: `Bar Code: ${SN ? SN : ``}`||'Set Bar Code'
    }))
   },[defaultValue])

   function runBarcodeScan(){
    setPopup3(p=>({...p,
      open:true,
      title: defaultValue?.SN ? `Change Product's Default Barcode` :`Set Product's Default Barcode`,
      description: "To set scan, use either a scanner (scan any barcode, and scan now), manual entry (case sensitive), or camera. If unwanted scan exists, use delete default serial number option",
      inputField: stateRef.current.scannerMenu,
      handleClose: closePopup3Func,
      headerButton: null,
      nameInputSets: {},
      }))
   }
  
   useEffect(()=>{
    setState(p=>({...p,// One script is for mobile and the other for browser
        addNew: useAndMobile ? <Grid item xs={12} sx={{ width:'100%' }}>
        <Item sx={{justifyContent: 'center'}}><Box sx={{width: '98%', padding:'0.5%'}}>{stateRef.current.modelNum}</Box></Item>
        <Item sx={{justifyContent: 'center'}}><Box sx={{width: '98%', padding:'0.5%'}}>{stateRef.current.partNum}</Box></Item>
        <Item sx={{justifyContent: 'center'}}><Box sx={{width: '98%', padding:'0.5%'}}>{stateRef.current.name}</Box></Item>
        <Item sx={{justifyContent: 'center'}}><Box sx={{width: '98%', padding:'0.5%'}}>{stateRef.current.hyperlink}</Box></Item>
        <Item sx={{justifyContent: 'center'}}><Box sx={{width: '98%', padding:'0.5%'}}>{stateRef.current.manufacturer}</Box></Item>
        <Item sx={{justifyContent: 'center'}}><Box sx={{width: '98%', padding:'0.5%'}}>{stateRef.current.description}</Box></Item>
        <Item sx={{justifyContent: 'center'}}><Box sx={{width: '98%', padding:'0.5%'}}>{stateRef.current.notes}</Box></Item>
        <Item sx={{justifyContent: 'center'}}><Box sx={{width: '98%', padding:'0.5%'}}>{stateRef.current.price}</Box></Item>
        <Item sx={{justifyContent: 'center'}}><Box sx={{width: '98%', padding:'0.5%'}}>{stateRef.current.atCost}</Box></Item>
        <Item sx={{justifyContent: 'center'}}><Box sx={{width: '98%', padding:'0.5%',display:'flex', justifyContent: 'center'}}>{stateRef.current?.checkOut}</Box></Item>
        <Item sx={{justifyContent: 'center'}}><Box sx={{width:'100%', padding:'0.5%', marginTop: '1%', justifyContent: 'center'}}>{dynamicComponent('DefaultButton','elements',{state:'scanSet',label:defaultValue?.SN ? defaultValue?.SN : stateRef.current.defaultButtonLabel ? stateRef.current.defaultButtonLabel : 'Set Bar Code',type:'button',color:'#32a7e2',startIcon:<FontAwesomeIcon icon={faBarcode}/>,full:true},mainState,[runBarcodeScan])}</Box></Item>
        { <Grid sx={{marginTop: '1%'}} container spacing={1}>
          <Typography sx={{width:'100%',p:'1% 5% 0'}}>Upload Product Image:</Typography>
          {defaultValue?.IMAGE_SPOTLIGHT && <Grid item xs={3} sm={3} sx={{overflow: 'auto', margin: 'auto'}}>
            <Box sx={{display:'flex'}}>{stateRef.current.currImage}</Box>
          </Grid>}
          <Grid item xs={9} sm={9} sx={{height: '100%', overflow: 'auto', margin: 'auto'}}>
            <Box>{stateRef.current.image}</Box>
          </Grid>
       </Grid> }
        </Grid>
        :
        <Grid item xs={12} sx={{ width:'100%' }}>
          <Item><Box sx={{width:'49%', padding:'0.5%'}}>{stateRef.current.modelNum}</Box>
          <Box sx={{width:'49%', padding:'0.5%'}}>{stateRef.current.partNum}</Box></Item>
          <Item><Box sx={{width:'49%', padding:'0.5%'}}>{stateRef.current.name}</Box>
          <Box sx={{width:'49%', padding:'0.5%'}}>{stateRef.current.hyperlink}</Box></Item>
          <Item><Box sx={{width:'49%', padding:'0.5%'}}>{stateRef.current.manufacturer}</Box>
          <Box sx={{width:'49%', padding:'0.5%'}}>{stateRef.current.description}</Box></Item>
          <Item><Box sx={{width:'100%', padding:'0.5%'}}>{stateRef.current.notes}</Box></Item>
          <Item><Box sx={{width:'79%', padding:'0.5%',display:'flex',gap:'1%'}}>{stateRef.current.atCost}{stateRef.current.price}</Box>
          <Box sx={{width:'19%', padding:'0.5%',display:'flex'}}>{stateRef.current?.checkOut}</Box></Item>
          <Item><Box sx={{width:'100%', padding:'0.5%'}}>{dynamicComponent('DefaultButton','elements',{state:'scanSet',label:defaultValue?.SN ? defaultValue?.SN : stateRef.current.defaultButtonLabel ? stateRef.current.defaultButtonLabel : 'Set Bar Code',type:'button',color:'#32a7e2',startIcon:<FontAwesomeIcon icon={faBarcode}/>,full:true},mainState,[runBarcodeScan])}</Box></Item>
          { <Grid container spacing={1}>
            <Typography sx={{width:'100%',p:'1% 5% 0'}}>Upload Product Image:</Typography>
            {defaultValue?.IMAGE_SPOTLIGHT && <Grid item xs={3} sm={3} sx={{overflow: 'auto', margin: 'auto'}}>
              <Box sx={{display:'flex'}}>{stateRef.current.currImage}</Box>
            </Grid>}
            <Grid item xs={9} sm={9} sx={{height: '100%', overflow: 'auto', margin: 'auto'}}>
              <Box>{stateRef.current.image}</Box>
            </Grid>
         </Grid> }
          </Grid>
    }))
   },[outputRef.current.CHECK_OUT,stateRef.current.defaultButtonLabel])


   return <Box>
   <Grid container rowSpacing={.5} columnSpacing={{ xs: .5, sm: .5, md: .5 }} >
    <Box sx={{width:'100%',p:'1%'}}>
        {stateRef.current.addNew}
        </Box>
    </Grid>
    </Box>

}
export default CreateRequest;