import React, { useRef, useEffect, memo, useCallback } from 'react';
import useState from 'react-usestateref';
import { useAtom } from 'jotai';
import { Box, Typography, Divider, ListItem, ListItemAvatar, ListItemText, Card, CardContent, CardMedia, Avatar } from '@mui/material';
import { Map, useMapsLibrary, useMap, AdvancedMarker, useAdvancedMarkerRef, InfoWindow} from '@vis.gl/react-google-maps';
import SpeedIcon from '@mui/icons-material/Speed';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

import AltRouteIcon from '@mui/icons-material/AltRoute';
import TurnSlightLeftIcon from '@mui/icons-material/TurnSlightLeft';
import TurnSlightRightIcon from '@mui/icons-material/TurnSlightRight';
import TurnLeftIcon from '@mui/icons-material/TurnLeft';
import TurnRightIcon from '@mui/icons-material/TurnRight';
import UTurnLeftIcon from '@mui/icons-material/UTurnLeft';
import UTurnRightIcon from '@mui/icons-material/UTurnRight';
import ForkLeftIcon from '@mui/icons-material/ForkLeft';
import ForkRightIcon from '@mui/icons-material/ForkRight';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import StraightIcon from '@mui/icons-material/Straight';
import DirectionsTransitFilledIcon from '@mui/icons-material/DirectionsTransitFilled';
import RoundaboutLeftIcon from '@mui/icons-material/RoundaboutLeft';
import RoundaboutRightIcon from '@mui/icons-material/RoundaboutRight';
import RampLeftIcon from '@mui/icons-material/RampLeft';
import RampRightIcon from '@mui/icons-material/RampRight';
import MergeIcon from '@mui/icons-material/Merge';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';

export const Component_geoMap = memo(({globalState,viewData:{loc,offSet,projID,data,deliverDriver,SiteID,managerView},dynamicComponent,dbUpdateValidate,teamMemberFollow,dynamicMapData,globalWrn,newNotification,CheckID}) => {
  const [, , zoomRef] = useState(deliverDriver?20:16);
  const [, setCenter, centerRef] = useState(null);
  const [infowindowOpen, setInfowindowOpen] = useState(null);
  const [teamMember,setTeamMember] = useAtom(globalState.teamMemberState);
  const [divisionMap] = useAtom(globalState.divisionMapState);
  const [aMarker,setAmarker] = useAtom(globalState.aMarker);
  const [UserMap, ] = useAtom(globalState.userMapState)
  const [projectMap,] = useAtom(globalState.projectMapState)
  const [teamFollow,setTeamFollow] = useAtom(globalState.teamFollowState);
  const [markerRef, marker] = useAdvancedMarkerRef();
  const mainState = useState(Object)
  const [, setState, stateRef] = mainState
  var [, setPopup] = useAtom(globalState.popupState);

  const intervalRef = useRef();

  useEffect(()=>{ 
    if(teamFollow.size>0) Array.from([...teamFollow], ([k, {coords}]) =>{ 
      if(stateRef.current?.lat && stateRef.current?.lng) coords = {latitude: stateRef.current?.lat, longitude: stateRef.current?.lng}
        
      if(coords) var { latitude:lat,longitude:lng } = coords

         if(stateRef.current?.[k]) var {latitude,longitude} = stateRef.current[k]
        
        if((latitude || lat)&&(longitude || lng) || (stateRef?.current?.lat && stateRef?.current?.lng)) var item = <AdvancedMarker position={{lat:stateRef?.current?.lat||lat||latitude,lng:stateRef?.current?.lng||lng||longitude }} ref={markerRef} title={'Delivery Driver'} onClick={() => setInfowindowOpen(k)}>
         { deliverDriver && deliverDriver===k ? <Box>
           <Avatar alt="Delivery" src={`https://services.ekc.app/profiles/avatar/${k}_marker.png`} sx={{ position:'absolute',left:'20%',top:'21%',width: 15, height: 15 }}/>
           < LocalShippingIcon sx={{ fontSize: 40, color: '#2e2e2e' }} />
         </Box>
         : <Avatar alt="Delivery" src={`https://services.ekc.app/profiles/avatar/${k}_marker.png`} sx={{ width: 25, height: 25 }}/>}
         </AdvancedMarker>

         if(lat && lng && latitude !== lat && longitude !== lng ){
          dynamicMapData({[k]: item}, aMarker, setAmarker, "aMarker");
          setState(p=>({...p,...{  [k]: coords }}));
          if(k===deliverDriver) setState(p=>({...p,...{ 
            originRef: (stateRef?.current?.lat && stateRef?.current?.lng) ? {lat:stateRef?.current?.lat, lng:stateRef?.current?.lng} : {lat,lng},
            coords: coords
          }}))
          
         }
         else if(!aMarker.has(k)&&(latitude || lat)&&(longitude || lng)){
          dynamicMapData({[k]: item}, aMarker, setAmarker, "aMarker");
          setState(p=>({...p,...{  [k]: coords }}));
         }
         })
  },[teamFollow, stateRef?.current?.lat])

  function infoWindowRender(){
    var {firstName,lastName,email,division,mobilePhone} = teamMember.get(infowindowOpen)
    if(division) var {teamName} = divisionMap.get(division)

    return <Card sx={{ display: 'flex',maxWidth:'290px' }}>
         <Box sx={{ display: 'flex', flexDirection: 'column' }}>
           <CardContent sx={{ flex: '1 0 auto',padding:'0 3%' }}>
             <Typography  variant="h6" sx={{lineHeight:1.1}}>
               {firstName} {lastName}
             </Typography>
             <Typography variant="subtitle2" color="text.secondary" sx={{lineHeight:1}}>
               {teamName}
             </Typography>
           </CardContent>
           <Divider/>
           <Box sx={{ display: 'flex', flexDirection:'column', alignItems: 'start', pl: 1, pb: 1 }}>
           <Typography variant="caption" color="text.secondary">
               {email}
             </Typography>
             <Typography variant="caption" color="text.secondary">
               {mobilePhone||'missing Phone#'}
             </Typography>
           </Box>
         </Box>
         <CardMedia
           component="img"
           sx={{ width: 50 }}
           image={`https://services.ekc.app/profiles/avatar/${infowindowOpen}_avatar.jpg`}
           alt={`${firstName} ${lastName}`}
         />
       </Card>
  }

  useEffect(()=>{
    if(stateRef.current?.startMap)
    {
      intervalRef.current = setInterval(()=>setState(p=>({...p, refresh: stateRef.current?.refresh!==undefined?!stateRef.current?.refresh:true})), 5000); // Update location every 5 seconds
    }
  },[stateRef.current?.startMap])

    useEffect(() => {
      var watchId
      if(stateRef.current?.startMap && managerView !== true) {
      
      if (!navigator.geolocation) {
        console.error("Geolocation is not supported by this browser.");
        return;
      }
  
      const handleSuccess = (position) => {
        const { latitude, longitude, accuracy } = position.coords;
        setState(p=>({...p, lat: latitude, lng: longitude}))
      };
  
      const handleError = (error) => {
        console.error("Error getting geolocation: ", error, error.message);
      };
  
      watchId = navigator.geolocation.watchPosition(
        handleSuccess,
        handleError,
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 10000,
        }
      );
    }
  
      // Clean up the watch on unmount
      return () => navigator.geolocation.clearWatch(watchId);
    }, [stateRef.current?.startMap, stateRef.current?.refresh]);

  useEffect(()=>{ 
    if(deliverDriver){ 
     if(!stateRef.current.destiantionRef || managerView === true) teamMemberFollow({follow:[deliverDriver]},({coords})=>{
        if(coords){
          var { latitude:lat,longitude:lng } = coords;
          dynamicMapData({[deliverDriver]: coords}, teamFollow, setTeamFollow);
          setState(p=>({...p,...{ 
            originRef: {lat,lng},
            destiantionRef: centerRef.current,
          }}))
        }
    })
    }
  },[deliverDriver])

  // Store default center and zoom
  useEffect(()=>{
    if(centerRef.current) setState(p=>({...p, defaultCenter: centerRef.current}))
    if(zoomRef.current) setState(p=>({...p, defaultZoom: zoomRef.current}))
  },[centerRef.current, zoomRef.current])

  const handleCameraChange = useCallback((ev)=> {
    if(ev.detail?.zoom !== stateRef.current?.defaultZoom) setState(p=>({...p,zoom:ev.detail?.zoom}))
    if(ev.detail?.center !== stateRef.current?.defaultCenter) setState(p=>({...p,center: ev.detail?.center}))
  });

  useEffect(() => {
    setState(p=>({...p,startMap: true}))
  }, []);

  useEffect(()=>{
    if(Array.isArray(loc)){
      if(loc>1) loc.forEach((item, i) => setTimeout(
        () => setCenter(item),
        (i + 1) * 5000
      ));
      else setCenter(loc[0])
    }else setCenter(loc)

  },[loc])
  
  const notifyProjectManager = () => {
    // Managers do not need to notify themselves
    if(managerView === true) return;

    if(data.map(x=>x.TransferRequests?.filter(y=>y?.deliveryNotified !== true))?.[0]?.length > 0) {
    const prjManID = projectMap.get(projID)?.ProjectState?.projManager
    var prjManEmail
    var prjManName

    if(teamMember.has(prjManID)) {
      prjManEmail = teamMember.get(prjManID)?.email
      prjManName = `${teamMember.get(prjManID)?.firstName} ${teamMember.get(prjManID)?.lastName}`
    }
    else CheckID({_id: prjManID, key: "User", map: teamMember, setMap: setTeamMember},(res)=>{
      prjManEmail = res?.email
      prjManName = `${res?.firstName} ${res?.lastName}`
    })

    const body = `${UserMap.get('firstName')} ${UserMap.get('lastName')} is 5 minutes away. Please prepare for their arrival.`
    var name_u = prjManName
    newNotification({Title:`A delivery to ${projectMap.get(projID)?.ProjectTitle} is 5 minutes out`,Body:body,Data:null,avatar:prjManID,Icon:null,Choice:{AppNotification:true,Text:false,Email:true},Email:prjManEmail,EmailForm:"Warning",name:name_u,sender:UserMap.get('avatar'),receiver:prjManID})
  
    data.forEach(x=>x.TransferRequests.forEach(req=>{
      var arrayFilters = [{"el._id": req._id}]

    dbUpdateValidate({model:'Inventory',queryID:true,query:req.InventoryID,update:{'$set':{deliveryNotified: true}},arrayFilters:arrayFilters},({success,info,err})=>{ 
          if(success){    
            globalWrn('success', 'Successfully Rescheduled Delivery')   
            setPopup({open: false}) 
          }else {console.log("DELIVERY NOT SAVED!!!", err); globalWrn('error', 'There was a problem rescheduling your delivery')}
        })
      }))
    }
  }

 return <Map 
          mapId={'bf51a910020fa25a'}
          defaultCenter={stateRef.current?.center||stateRef.current.defaultCenter||loc} 
          defaultZoom={stateRef.current.zoom||stateRef.current?.defaultZoom||10}
          offSet={offSet}
          gestureHandling={'greedy'}
          disableDefaultUI={true}
          onCameraChanged={handleCameraChange}
          options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}  >
            
            { SiteID && stateRef.current.destiantionRef && <AdvancedMarker position={stateRef.current.destiantionRef} ref={markerRef} title={'Delivery Driver'} onClick={() => setInfowindowOpen(SiteID)}>
              <Avatar alt="Delivery" src={`https://services.ekc.app/profiles/avatar/${SiteID}_marker.png`} sx={{ width: 30, height: 30 }}/>
              </AdvancedMarker> }

            {stateRef.current.destiantionRef && <Directions origin={stateRef.current.originRef} notifyProjectManager={notifyProjectManager} destination={stateRef.current.destiantionRef} dynamicComponent={dynamicComponent} parentState={mainState} />}
          { aMarker.size>0 && Array.from([...aMarker], ([,v]) =>v) }
          {infowindowOpen && 
          <InfoWindow anchor={marker} maxWidth={300} onCloseClick={() => setInfowindowOpen(null)}>
            {infoWindowRender()}
          </InfoWindow>}
        </Map>
});

function Directions({origin,destination,dynamicComponent,notifyProjectManager,parentState}) {
  const map = useMap()
  const [, , stateRef] = parentState
  const routesLibrary = useMapsLibrary("routes")
  const [directionsService, setDirectionsService] = useState()
  const [directionsRenderer, setDirectionsRenderer] = useState()
  const [routes, setRoutes] = useState([])
  const [routeIndex, setRouteIndex] = useState(0);
  const selected = routes[routeIndex];
  const leg = selected?.legs[0];
  const count = useRef(0);
  const waypoint = leg?.steps?.[count.current]
  const directionsLabel = useRef(null);

  useEffect(()=>{
    if((leg?.duration?.value / 60) <= 5) notifyProjectManager()
  },[leg])

  useEffect(()=>{
    if(waypoint?.instructions && directionsLabel.current) directionsLabel.current.innerHTML = waypoint?.instructions
  },[waypoint?.instructions,directionsLabel.current])

  // Initialize directions service and renderer
  useEffect(() => {
    if (!routesLibrary || !map) return
    setDirectionsService(new routesLibrary.DirectionsService())
    setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map, suppressMarkers: true }))
  }, [routesLibrary, map])

  // Use directions service
  useEffect(() => {
    if (!directionsService || !directionsRenderer) return
    directionsService
      .route({
        origin: origin,
        destination: destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
        provideRouteAlternatives: true
      })
      .then(response => {
        if (response.status === "OK") {
          directionsRenderer.setDirections(response)
          setRoutes(response.routes)
          count.current++;
          console.count();
        }
      })

   // return () => directionsRenderer.setMap(null)
  }, [origin,destination,directionsService, directionsRenderer])



  // Update direction route
  useEffect(() => {
    if (!directionsRenderer) return
    directionsRenderer.setRouteIndex(routeIndex)
  }, [routeIndex, directionsRenderer])


  if (!leg) return null

return <Box sx={{position:'absolute', top:'1%',left:'1%',maxWidth:'97.5%',display: 'flex',alignItems: 'center',width: 'fit-content',border: (theme) => `1px solid ${theme.palette.divider}`,borderRadius: 1,backdropFilter:'blur(15px)',textShadow:'1px 1px rgba(0,0,0,.35)',bgcolor: 'rgba(18,18,18,.5)', '& svg': { m: 1.5,},'& hr': { mx: 0.5, }}} >
 <Box sx={{display:'flex',flexDirection:'column',p:'0 3%',width:'100%',flex:1 }}>
 <ListItemAvatar sx={{margin:'auto',minWidth:'unset'}}>
{ icons[waypoint?.maneuver?.toUpperCase()?.replaceAll('-', '_')] }
</ListItemAvatar>
<ListItemText
      sx={{display:'flex',flexDirection:'column',gap:'4px'}}
      primary={<Typography variant='button' sx={{lineHeight:1,textAlign:'center'}}>{waypoint?.maneuver?.toUpperCase()?.replaceAll('-', ' ')}</Typography>}
      secondary={<Box sx={{display:'flex',justifyContent:'space-evenly'}}><AccessTimeFilledIcon fontSize="small" sx={{m:'0px!important'}}/><Typography sx={{lineHeight:1,m:'1.25% auto auto 3%'}}> {waypoint?.duration?.text}</Typography> <TimeToLeaveIcon fontSize="small" sx={{m:'0px!important'}}/><Typography sx={{lineHeight:1,m:'1.25% auto auto 3%'}}> {waypoint?.distance?.text}</Typography></Box>}
      />
 </Box>

<Divider sx={{margin:'3% 8px 8px 0!important'}} orientation="vertical" variant="middle" flexItem />
<ListItem alignItems="flex-start" sx={{p:0}}>

<ListItemText
 sx={{display:'flex',flexDirection:'column',gap:'4px'}}
 primary={<Box sx={{display:'flex', justifyContent:'flex-end'}}>
  <Typography sx={{m:'auto 0', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical',overflow:'auto',lineHeight:1.1}} ref={directionsLabel}></Typography>
  { dynamicComponent('DropDown','elements',{ state: 'TicketStatus', style:{padding:0}, list:
routes.flatMap((route, index) => (
{primary:route.summary,
secondary: <Box sx={{display:'flex',justifyContent:'space-evenly'}}>
<AccessTimeFilledIcon fontSize="small" sx={{m:'0px!important'}}/>
<Typography sx={{lineHeight:1,m:'1.25% auto auto 3%'}}>{route.legs[0].duration?.text}</Typography>
<TimeToLeaveIcon fontSize="small" sx={{m:'0px!important'}}/>
<Typography sx={{lineHeight:1,m:'1.25% auto auto 3%'}}>{route.legs[0].distance?.text}</Typography>
</Box>,
divider:true,
icon:<AltRouteIcon/>,
action:() => setRouteIndex(index)})), replace:true },parentState) }
 </Box>}
 secondary={<Box><Box>
   <Typography variant='caption'>Arrival in:</Typography>
  <Box sx={{display:'flex',justifyContent:'space-evenly',flexWrap:"wrap"}}>
  <Box sx={{display:'flex',minWidth:'70px'}}> <AccessTimeFilledIcon fontSize="small" sx={{m:'0px!important'}}/>
  <Typography sx={{lineHeight:1,m:'1.25% auto auto 3%'}} noWrap>{leg.duration?.text}</Typography> </Box>
  <Box sx={{display:'flex',minWidth:'70px'}}> <TimeToLeaveIcon fontSize="small" sx={{m:'0px!important'}}/>
 <Typography sx={{lineHeight:1,m:'1.25% auto auto 3%'}} noWrap>{leg.distance?.text}</Typography> </Box>
 {stateRef.current.coords?.speed !==undefined && <Box sx={{display:'flex',minWidth:'70px'}}>
  <SpeedIcon fontSize="small" sx={{m:'0px!important'}}/>
  <Typography sx={{lineHeight:1,m:'1.25% auto auto 3%'}} noWrap>{stateRef.current.coords?.speed?.toFixed(0)}mph</Typography>
 </Box>}
</Box>
<Typography variant='caption'>Latitude: {stateRef.current?.lat||0} Longitude: {stateRef.current?.lng||0}</Typography>
</Box></Box>} 
 />
</ListItem>
</Box>
}

var icons = {
  MANEUVER_UNSPECIFIED: <SentimentVeryDissatisfiedIcon fontSize="large" sx={{m:'0px!important'}}/>,
  TURN_SLIGHT_LEFT: <TurnSlightLeftIcon fontSize="large" sx={{m:'0px!important'}}/>,
  TURN_SHARP_LEFT: <TurnLeftIcon fontSize="large" sx={{m:'0px!important'}}/>,
  UTURN_LEFT: <UTurnLeftIcon fontSize="large" sx={{m:'0px!important'}}/>,
  TURN_LEFT: <TurnLeftIcon fontSize="large" sx={{m:'0px!important'}}/>,
  TURN_SLIGHT_RIGHT: <TurnSlightRightIcon fontSize="large" sx={{m:'0px!important'}}/>,
  TURN_SHARP_RIGHT: <TurnRightIcon fontSize="large" sx={{m:'0px!important'}}/>,
  UTURN_RIGHT: <UTurnRightIcon fontSize="large" sx={{m:'0px!important'}}/>,
  TURN_RIGHT: <TurnRightIcon fontSize="large" sx={{m:'0px!important'}}/>,
  STRAIGHT: <StraightIcon fontSize="large" sx={{m:'0px!important'}}/>,
  RAMP_LEFT: <RampLeftIcon fontSize="large" sx={{m:'0px!important'}}/>,
  RAMP_RIGHT: <RampRightIcon fontSize="large" sx={{m:'0px!important'}}/>,
  MERGE: <MergeIcon fontSize="large" sx={{m:'0px!important'}}/>,
  FORK_LEFT: <ForkLeftIcon fontSize="large" sx={{m:'0px!important'}}/>,
  FORK_RIGHT:<ForkRightIcon fontSize="large" sx={{m:'0px!important'}}/>,
  FERRY: <DirectionsBoatIcon fontSize="large" sx={{m:'0px!important'}}/>,
  FERRY_TRAIN: <DirectionsTransitFilledIcon fontSize="large" sx={{m:'0px!important'}}/>,
  ROUNDABOUT_LEFT: <RoundaboutLeftIcon fontSize="large" sx={{m:'0px!important'}}/>,
  ROUNDABOUT_RIGHT: <RoundaboutRightIcon fontSize="large" sx={{m:'0px!important'}}/>
  }