import React, { useEffect } from "react";
import { useAtom } from 'jotai';
import useState from 'react-usestateref';
import dayjs from 'dayjs';
import { Paper, Box, Typography, Chip } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { experimentalStyled as styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';

const Item = styled(Paper)(({ theme })=>({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '.5%',
  textAlign: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  height: '100%',
  overflow:'auto',
  flex:1,
  color: theme.palette.text.secondary,
}));

var isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
dayjs.extend(isSameOrAfter);

const PurchaseReq=({globalState,dynamicComponent,useIsMobile,That,globalWrn,formatter,handlePopupClose,dbUpdateValidate,newNotification,ExcelCreate,toggleUserSubscription,projectBuilder,queryDB})=>{
    const mainState = useState(Object);
    const outputState = useState(Object);
    const [, setParentState] = That;
    const [, setState, stateRef] = mainState;
    const [teamMember] = useAtom(globalState.teamMemberState);
    const [UserMap] = useAtom(globalState.userMapState);
    const [inventoryMap] = useAtom(globalState.inventoryMapState);
    const [productMap] = useAtom(globalState.productMapState);
    const [, setOutput, outputRef] = outputState;
    const [popupData, setPopup] = useAtom(globalState.popupState);
    const [, setPopup2] = useAtom(globalState.popupState2);
    const [transferOpen, setTransferOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [projectMap] = useAtom(globalState.projectMapState);
    const [,setPopup3] = useAtom(globalState.popupState3);
    const [divisionMap] = useAtom(globalState.divisionMapState);
    const [vendorMap] = useAtom(globalState.vendorMapState);
    const [,setTransferRequest,transferRequest] = useState(Object);
    const [appState,setAppState] = useAtom(globalState.appState);
    const [, setGlobalAppState] = useAtom(globalState.appState);
    const useAndMobile = useIsMobile(560);
    var simp = (t, path) => path.split(".").reduce((r, k) => r?.[k], t);
    var latestState = (x) => outputRef.current[x] || simp(projectMap.get(outputRef.current.ProjectID?.join()),x);

  const selectCurrInventory=(items)=>{

    var ProjectID = stateRef.current.ProjectID
    var transfers = items.flatMap(({InventoryID,QTYOnHand})=>{
    if(InventoryID && inventoryMap.has(InventoryID)){
      var {DivisionID,ProductItems:[y]} = inventoryMap.get(InventoryID)
    } 
    if(y && productMap.has(y?.id)) var {DISPLAY_NAME} = productMap.get(y?.id)
    if(DivisionID && divisionMap.has(DivisionID)) var {divisionLabel,teamName} = divisionMap.get(DivisionID)

    return <Box sx={{ p: 1, border: '1px dashed grey', borderRadius:2 }}>
          <Typography sx={{lineHeight:1,fontSize:'25px',padding:'1%'}}>{divisionLabel||teamName}</Typography>
         { dynamicComponent('transferInventory','elements',{ state: 'inventoryTransf', displayName: DISPLAY_NAME, divName: teamName, totalAmount: QTYOnHand, InventoryID: InventoryID, ProjectID:ProjectID },mainState,[({InventoryID,transfer})=>{ if(transfer&&InventoryID) setTransferRequest(p=>({...p,[InventoryID]:transfer}))} ])}
         </Box>
    })
    var list = Object.assign(...Array.from([...divisionMap],([k,{teamName}])=>({[teamName]:k})).sort((a, b)=> (Object.keys(a)[0] < Object.keys(b)[0]) ? -1 : (Object.keys(a)[0] > Object.keys(b)[0]) ? 1 : 0))

    setPopup2(p=>({...p,
      open: true,
      title:`Transfer Request`,
      description: <Box><Typography>Submit Transfer Request; No Site Delivery</Typography> </Box>,
      headerButton: dynamicComponent('DropDown','elements',{ state: 'TransDivisionID', label: 'Shipping To:', list: list, replace:true },mainState),
      inputField: <Box>{transfers}</Box>,
      width: '45vw',
      helperObject: <Chip  sx={{
        height: 'auto',
        '& .MuiChip-label': {
          display: 'block',
          whiteSpace: 'normal',
          lineHeight: 1
        },
      }} size='small' color='warning' icon={<WarningIcon/>} label={`Submitting this Item Triggers a Transfer Request, Irrespective of the BOM Order`} />,
      handleClose: ()=>setPopup2({open:false}),
    }))
  }

useEffect(()=>{
  if(transferRequest.current && stateRef.current.TransDivisionID){
     setPopup2(p=>({...p,
      handleSubmit:submitTransferReq
     }))
  }
},[transferRequest.current,stateRef.current.TransDivisionID])

  const submitTransferReq=()=>{
    
if(projectMap.has(stateRef.current.ProjectID?.[0])) var { ProjectTitle } = projectMap.get(stateRef.current.ProjectID?.[0])
if(!ProjectIDarr) var ProjectIDarr = stateRef.current.ProjectID
if(!ProjectID) var ProjectID = stateRef.current.ProjectID?.[0]
// If there is a project manager
if(teamMember.get(projectMap.get(ProjectID)?.ProjectState?.projManager)) var myEmail = teamMember.get(projectMap.get(ProjectID)?.ProjectState?.projManager)?.email
// If there is no project manager
else var myEmail = UserMap.get('email')
// If there is a project manager
if(teamMember.get(projectMap.get(ProjectID)?.ProjectState?.projManager)) var nameFUll = `${teamMember.get(projectMap.get(ProjectID)?.ProjectState?.projManager)?.firstName} ${teamMember.get(projectMap.get(ProjectID)?.ProjectState?.projManager)?.lastName}`
// If there is no project manager
else var nameFUll = `${UserMap.get('firstName')} ${UserMap.get('lastName')}`
setState(p=>({...p,submitReq: false}))

Object.entries(transferRequest.current).forEach(([k,v])=>{
  var ammount = v
  var InventoryID = k
  var toDivisionID = stateRef.current.TransDivisionID
  var {logManager:toLogManager,teamName:divName} = divisionMap.get(toDivisionID)
  var {DivisionID:fromDivisionID,PreferredVendor,url} = inventoryMap.get(k)
  var {logManager:fromLogManager} = divisionMap.get(fromDivisionID)
  var namefromFull = `${teamMember.get(fromLogManager)?.firstName} ${teamMember.get(fromLogManager)?.lastName}`

  if(toLogManager && fromLogManager && inventoryMap.has(InventoryID) && teamMember.has(fromLogManager)) {
    var {mobilePhone:toLogManagerPhoneNum,email:toLogManagerEmail} = teamMember.get(toLogManager)
    var {mobilePhone:fromLogManagerPhone,email:fromLogManagerEmail} = teamMember.get(fromLogManager)
    var {TransferRequests,checkOutRequests,category,QTYOnHand,QTYReceived,QTYOrdered,ProductItems} = inventoryMap.get(InventoryID)
    var {DISPLAY_NAME:displayName} = productMap.get(ProductItems?.[0]?.id)

    if(QTYOnHand >= ammount) {
      var body = `${nameFUll} has submitted a transfer request for ${ammount} unit(s) of ${displayName}\n\nType: Stock Transfer\n\nStock is to be transfered to: ${divName}\n\nPlease Add Tracking Number When Ready`
     
      var newQuery = {Notes:`Requested - ${dayjs().format('MMM Do YY - h:mm a')} by ${nameFUll}`,
      ProjectID:ProjectIDarr, Status:'Order Processing', ProductItems, PurchaseOrder: "Pending",
      DivisionID: toDivisionID, QTYOrdered: ammount,QTYOnHand:0, QTYReceived:0, PreferredVendor:PreferredVendor, URL:url,
      DeliverToSite:false, site: "Missing Site(s)", projectName: ProjectTitle, 
      divisionName:divName, checkOutRequests, category,
      TransferRequests: TransferRequests?.flatMap(x=>{ delete x._id; return x}), TrackingNumber: "",timestamp:new Date()}

    if(QTYOnHand === ammount) { 
    
      var clone = inventoryMap.get(InventoryID)
      delete clone._id
      delete clone.ProductItems[0]._id

      dbUpdateValidate({model:'Inventory',queryID:true,query:InventoryID,update:{...clone,QTYOrdered:ammount,QTYReceived:0,QTYOnHand:0,OrderNumber:"Pending",PurchaseOrder:"Stock Transferred", DivisionID: toDivisionID, Status: "Order Processing", timestamp: new Date()}},({success,info,err})=>{
        if(success) {
          globalWrn('success',`Transfer Request was created.`)
          setState(p=>({...p,submitReq: true}))
          setPopup2({open:false})
        }
        else globalWrn('error',`We had an issue creating the transfer request.`)
      })
    } else if(QTYOnHand > ammount) {
      delete newQuery.ProductItems[0]._id
      dbUpdateValidate({model:'Inventory',query:newQuery,update:newQuery},({success,info,err})=>{
        if(success) {
          var item = inventoryMap.get(InventoryID)
          delete item._id
          delete item.inventoryReceived
     
          dbUpdateValidate({model:'Inventory',queryID:true,query:InventoryID,update:{...item,QTYOnHand:QTYOnHand-ammount, QTYOrdered: QTYOnHand-ammount, QTYReceived:QTYOnHand-ammount}},({success,info,err})=>{
            if(err) globalWrn('error',`We had an issue updating the Quantity.`);
          })
          globalWrn('success',`Transfer Request was created.`)
          setState(p=>({...p,submitReq: true}))
          setPopup2({open:false})
        }
        else globalWrn('error',`We had an issue creating request.`);
      })
    }
    if(toLogManager !== fromLogManager) newNotification({Title:`A new transfer request has been filed:`,Body:body,Data:null,Avatar:null,Icon:null,Choice:{AppNotification:true,Text:false,Email:true},avatar:fromLogManager,mobile:fromLogManagerPhone,Email:fromLogManagerEmail,myEmail:myEmail,EmailForm:"TransferRequest",InventoryID:InventoryID,name_u:namefromFull,sender:projectMap.get(ProjectID)?.ProjectState?.projManager||UserMap.get('avatar'),receiver:fromLogManager})
      newNotification({Title:`A new transfer request has been filed:`,Body:body,Data:null,Avatar:null,Icon:null,Choice:{AppNotification:true,Text:false,Email:true},avatar:toLogManager,mobile:toLogManagerPhoneNum,InventoryID:InventoryID,Email:toLogManagerEmail,myEmail:myEmail,EmailForm:"TransferRequest",name_u:nameFUll,sender:projectMap.get(ProjectID)?.ProjectState?.projManager||UserMap.get('avatar'),receiver:toLogManager},({sucess,info,err})=>{
        if(sucess===true) globalWrn('sucess',`Sucessfully submitted transfer request`);
          else if (err) globalWrn('error', `Failed to submit request, try again`);
          setPopup2({open:false})
      })
}
} else {
  if(!toLogManager) globalWrn('error',`We could not proccess your request due to the reciveing Logistics Manager is missing`);
  if(!fromLogManager) globalWrn('error',`We could not proccess your request due to the reciveing Logistics Manager is missing`);
  if(!inventoryMap.has(InventoryID)) globalWrn('error',`We could not proccess your request because the Inventory Item missing`);

  setPopup(p=>({...p,
    enableSubmitButton: true
  }))
  setPopup2(p=>({...p,
    enableSubmitButton: true
  }))
} 
})

}

useEffect(()=>{
  setState(p=>({...p,
    newSt: dynamicComponent('AddSites','elements',{state:'newSite', popupNum: 3},mainState),
  }))
},[])

var checkerFilter = (a,r) =>{ 
  try{ return r.filter(v => !a.includes(v) )}catch(e){return []}
 }


function updaterUsers(pID,modelType){
  var finArr = [];
  var OrrArr = [];
['SalesState.clientID','SalesState.partnerID','ProjectState.projManager','SalesState.estimator','ProjectState.teamLead','ProjectState.Members','CreatedBy'].forEach(x=>{
  var projDat = simp(projectMap.get(stateRef.current.ProjectID?.[0]),x)
  var projNex = outputRef[x]

  if(projDat?.constructor === String) projDat = [projDat]
  if(projNex?.constructor === String) projNex = [projNex]
  if(projDat?.[0]?.constructor === Object) projDat = projDat?.map(x=> x.id)?.filter(x=>x!==undefined)
  if(projNex?.[0]?.constructor === Object) projNex = projNex?.map(x=> x.id)?.filter(x=>x!==undefined)
  if(projDat === undefined) projDat = []
  if(projNex === undefined) projNex = []
  
  if(projDat != []) projDat.forEach(y=>finArr.push(y))
  if(projNex != []) OrrArr.forEach(y=>OrrArr.push(y))

  if(projNex){ 
  var addUser = checkerFilter(projDat,projNex)
  if(addUser.length>0) toggleUserSubscription({users:addUser,type:'addUser',ProjectID: pID, modelType: modelType, projData:{ Status: latestState('Status'), SubStatus: latestState(`${latestState(`Status`)+'State'}Status`) }})
}
  if(projDat){
  }
})
var TestfinArr = [];
finArr.forEach((c)=>{
  if(!TestfinArr.includes(c)){
    TestfinArr.push(c)
  }
});
if(TestfinArr.length>0){
}
}

const submitProjSite=()=>{
  if(!ProjectID) var ProjectID = outputRef.current.ProjectID?.[0]
  var projIDtemp = { DivisionID: projectMap.get(ProjectID?.[0])?.DivisionID,modelType: divisionMap.has(projectMap.get(ProjectID?.[0])?.DivisionID) ? divisionMap.get(projectMap.get(ProjectID?.[0])?.DivisionID).teamName : divisionMap.get(UserMap.get('division')).teamName }
  if(ProjectID) projIDtemp.ProjectID = ProjectID?.[0]
if(outputRef.current?.['SalesState.SiteID'].length > 0)
  if(ProjectID){
    projectBuilder({projID:projIDtemp,submitObj:{['SalesState.SiteID']: outputRef?.current?.['SalesState.SiteID']}},(x)=>{
          var {success,proj,error} = x  
          if(success===true){ 
                updaterUsers(proj._id ? proj._id : ProjectID, divisionMap.get(proj.DivisionID ? proj.DivisionID : projectMap.get(ProjectID?.[0])?.DivisionID )?.teamName)
                setPopup2({open:false})
                setGlobalAppState(p=>{ 
                p.delete('modulePop')
                return new Map(p)
                })
                // Clear old data off of state
                setOutput(p=>({...p,['SalesState.SiteID']:undefined}))
              }else {
                globalWrn('error',`There was an error updating project ${projectMap.get(ProjectID?.[0])?.ProjectTitle}.`)
              }
              setState(p=>({...p,loading : false }))
          })
        }
  else
    globalWrn(`error`, `You must select a site to submit!`)

}

const addSites=()=>{
  // setState right here
  setPopup3(p=>({...p,
    open:true,
    title:`Add District/Company Site`,
    description: `Do not add entire company, government bureau/agency, or school district.`,
    inputField: stateRef.current.newSt,
    handleClose: ()=>{setPopup3({open:false})},
    nameInputSets: {},
}))  
}

useEffect(()=>{
  setState(p=>({...p,
    siteSelect: dynamicComponent('AutoComplete','elements',{state:'SalesState.SiteID',label:'Company(s) / Site(s)', defaultValue: latestState('SalesState.SiteID'),model:'Sites',idConvert:'$nin',query:{'_id':Array.from(teamMember).filter(([,{userType}])=>userType==="Sites").flatMap(([k])=>k)}, addEntry:addSites},outputState)
  }))

  return () => { // Cleanup function to clear data
    setTransferRequest({});
  };
},[])

useEffect(()=>{

  if(outputRef.current.lineItems) {
  const isTrue = outputRef.current.lineItems?.flatMap(x=>x?.siteDeliver === true)

  if(isTrue?.includes(true) && !projectMap.get(stateRef.current.ProjectID?.[0])?.SalesState?.SiteID?.[0]) {
    var userDiv = outputRef.current.DivisionID || UserMap.get('division');
    var modelType = divisionMap.get(userDiv)?.teamName;
    queryDB({model:'Projects',modelType,idConvert:true,query:{"_id":stateRef.current.ProjectID?.[0]},filter:{'SalesState.SiteID':1}},([x],y)=>{
   if(!x?.SalesState?.SiteID) setPopup2(p=>({...p,
      open:true,
      title:`Add Project Site`,
      description: `The Selected Project ${projectMap.get(stateRef.current.ProjectID?.[0])?.ProjectTitle} is Missing its site. Please select one.`,
      inputField: <Box sx={{padding: '1%'}}>{stateRef.current.siteSelect}</Box>,
      handleClose: ()=>{setPopup2({open: false}); setOutput(p=>({...p,siteDeliver:false}))},
      handleSubmit: submitProjSite
    }))
  })
}
}

},[outputRef.current.lineItems])


    const submitItems=()=>{
      var ProjectID = stateRef.current.ProjectID
      if(projectMap.has(stateRef.current.ProjectID?.[0])) var {PoNumber,ProjectTitle,DivisionID} = projectMap.get(stateRef.current.ProjectID?.[0])
      else return  globalWrn('error',`The Project that you Selected appears to be Missing.`)
      var pass = false
      var body = ``
      var total = 0
      var cost = 0
      var names = `\n`
      var totalstockAmmount = 0
      var divsionAmmount = 0
      Promise.all(
      outputRef.current.lineItems?.flatMap((x,i)=>
        new Promise((res, rej) => {
             var newQuery = {Notes:`${x?.notes?x?.notes:`No B.O.M. Notes`} - Requested - ${dayjs().format('MMM Do YY - h:mm a')} by ${UserMap.get('firstName')} ${UserMap.get('lastName')}`,ProjectID:ProjectID,Status:'Requested',ProductItems:x.ProductItems,DivisionID:projectMap.get(ProjectID?.[0])?.DivisionID,QTYOrdered:parseInt(x.quantity,10),PreferredVendor:x?.PreferredVendor,URL:x?.url,DeliverToSite:x?.siteDeliver,Requestor:UserMap.get('avatar')}

             dbUpdateValidate({model:'Inventory',query:newQuery,update:newQuery},({success,info,err})=>{
              
                if(success) {
                  res( info?._id || info?.lastErrorObject?.upserted )
                  globalWrn('success',`Request was created.`)
                  handlePopupClose()
                  if(appState.has('stockResp')){
                    var items = appState.get('stockResp')[i]?.items
                    if(items && items.length>0){
                      pass = true
                      var ammount = parseInt(x.quantity,10)
                      divsionAmmount = divsionAmmount + items?.length                      
                      var stockAmmount = inventoryMap.get(items[0]?.InventoryID)?.QTYOnHand
                      totalstockAmmount = totalstockAmmount + stockAmmount
                      if(x.ProductItems[0]?.Product?.MSRP){
                        total = total + x.ProductItems[0]?.Product?.MSRP*stockAmmount
                        cost = cost + x.ProductItems[0]?.Product?.MSRP*parseInt(ammount,10)
                      }
                      names = names + ammount + ` ` + x.ProductItems[0]?.Product?.DISPLAY_NAME + `\n`
                      body = body+``
                    }
                  } 
                  setParentState(p=>({...p,
                    purchaseRequestField: undefined
                  }))
                }
                else {
                  globalWrn('error',`We had an issue creating request.`)
                  rej()
                }
              })
              })
            )
          ).then((lineID)=>{
            if(pass === true)
            {
              var nameFUll = `${UserMap.get('firstName')} ${UserMap.get('lastName')}`
              var contact = `\nDivision: ${divisionMap.get(UserMap.get('division'))?.teamName}\nWork Phone: ${UserMap.get('workPhone') ? UserMap.get('workPhone') : 'No Work Phone'}\nMobile: ${UserMap.get('mobilePhone') ? UserMap.get('mobilePhone') : 'No Mobile Phone'}\nEmail: ${UserMap.get('email') ? UserMap.get('email') : 'No Email'}`
              body = `${nameFUll} has submitted a BOM request for: ${names}\nHowever, there was ${totalstockAmmount} unit(s) available in ${divsionAmmount} division(s).\n\n${nameFUll}'s Contact Information:\n${contact}\n\n${cost > 0 && (`The BOM Request total was: $`+cost)}\n\n${total !== 0 &&(`If a transfer request was done, it could have saved `+(cost - total <= 0 ? '100% of the cost (excluding shipping)' : formatter.format(total)))}`

              newNotification({Title:`A new BOM request has been filed, but there was stock available:`,Body:body,Data:null,Avatar:null,Icon:null,Choice:{AppNotification:true,Text:false,Email:true},avatar:UserMap.get('avatar'),mobile:'5597188550',Email:'apennycuff@ekccorp.com',myEmail:'apennycuff@ekccorp.com',EmailForm:"Warning",name_u:nameFUll,sender:UserMap.get('avatar'),receiver:UserMap.get('avatar')},({sucess,info,err})=>{
                if(sucess===true) globalWrn('sucess',`Sucessfully submitted transfer request`);
                  else if (err) globalWrn('error', `Failed to submit request, try again`);
                  setPopup2({open:false})
              pass = false
              })
            }
            var ExcelForm = outputRef.current.lineItems.flatMap(x=>{
            var product = productMap.get(x?.ProductItems?.[0]?.id)
              delete x?.ProductItems
              return {...x,...product}
           }) 
           
 
            Promise.all([{
               columns: [...new Set(ExcelForm?.flatMap(x=> Object.entries(x).filter(([k,v])=>!['_id','__v','SN'].includes(k)).flatMap(([k])=>({ header: k === "siteDeliver" ? "Site Delivery" : "watcher" ? "Part #" : k, key: k, width:25 }))))],
               rows: ExcelForm?.map(x=> Object.entries(x).filter(([k,v])=>!['_id','__v','SN'].includes(k)).flatMap(([k,v])=>(k==="siteDeliver"||k==="Site Delivery")?`${teamMember.get(projectMap.get(stateRef.current.ProjectID?.[0])?.SalesState?.SiteID?.[0])?.name}: ${teamMember.get(projectMap.get(stateRef.current.ProjectID?.[0])?.SalesState?.SiteID?.[0])?.address?.label}` : k==="PreferredVendor" ? vendorMap.get(v)?.Name : v))
             }]).then(([x])=>{
              var jsonObject = x.columns.map(JSON.stringify);
              var uniqueSet = new Set(jsonObject);
              x.columns = Array.from(uniqueSet).map(JSON.parse);

              const modifiedPONum = PoNumber.replace(/ /g, '_')

               var name = `inventoryOrder_JOB${modifiedPONum}_${dayjs().format('ddd_MMM_D_YYYY-h:mma')}.xlsx`
               var location = `inventoryOrders/${ProjectID}/`
               var fullPath = `https://www.dev-services.ekc.app/profiles/` + location + name;
               var link = `https://dev.ekc.app/?&type=modifyBOMReports&userID=${UserMap.get('avatar')}&BOMReport=${lineID.toString()}`;
               var info = `Division Name: ${divisionMap.get(DivisionID)?.teamName}\n\nProject Name: ${ProjectTitle}\n\nDate Submitted: ${dayjs().format('ddd_MMM_D_YYYY')}\n\nSubmitted By: ${UserMap.get('firstName')} ${UserMap.get('lastName')}\n\n${UserMap.get('firstName')} ${UserMap.get('lastName')}'s Phone Number: ${UserMap.get('mobilePhone')}\n\n${UserMap.get('firstName')} ${UserMap.get('lastName')}'s Email: ${UserMap.get('email')}`
               
               newNotification({Title:`BOM Order From: ${UserMap.get('firstName')} ${UserMap.get('lastName')}`,Body:`A BOM Order has been submitted with the following information:`,secondaryBody:info,link:link,downloadLink:fullPath,senderAvatar:UserMap.get('avatar'),Avatar:null,Icon:null,Choice:{AppNotification:true,Text:false,Email:true},Email:'purchasing@ekccorp.com',EmailForm:"BOM",name_u:`Purchasing Team`,sender:UserMap.get('avatar'),receiver:UserMap.get('avatar')})
               ExcelCreate({...x,location:location,name:name})
               setState({});
           })

          })           
          }

  useEffect(()=>{
    setState(p=>({...p,
      transferQTY: <Box>{transferOpen === true && dynamicComponent('LabelValue','elements',{state:'QTYOrdered',Min:0,label:'QTY To Transfer',type:'number', defaultValue:latestState("QTYToTransfer"),labelEditable:false},outputState,[({QTYToTransfer})=>setOutput(p=>({...p,QTYToTransfer:QTYToTransfer.value}))])}</Box>,
    }))
    setRefresh(false)
  },[transferOpen, refresh === true])

  // check to make sure amount selector does not go less than 0
  useEffect(()=>{
    if(outputRef.current.QTYToTransfer < 0) {
      setOutput(p=>({...p,QTYToTransfer:0}))
      setRefresh(true)
      globalWrn('error',`You cannot have less than 0 transfered`)
    }
  },[outputRef.current.QTYToTransfer])

  useEffect(()=>{ 
    var vendor = {id:'AutoComplete',loc:'elements',viewData:{state:'PreferredVendor',label:'Preferred Vendor',type:'text',defaultValue:stateRef.current.ProjectID?.[0],model:'Vendors',query:{},filter:{},addEntry:createVendor}}
    var plnRec = [{id:'DynamicChip',loc:'elements',flex:.01,viewData:{state:'dynamicChip', ProjectID:stateRef.current.ProjectID?.[0]},action:[selectCurrInventory]},{id:'ProductSelect',loc:'elements',flex:2.5,viewData:{state:'ProductItems',defaultValue:outputRef.current.ProductItems,label:'Product'}},{id:'InputField',loc:'elements',flex:.5,viewData:{state:'quantity',label:'QTY',type:'number'}},vendor,{id:'InputField',loc:'elements',viewData:{state:'url',label:'URL',type:'text'}},{id:'Switcher', loc:'elements',flex:.07,viewData:{state:'siteDeliver',labelPlacement: "bottom", label:"Site Delivery",style:{gap:'6px'},defaultValue:outputRef.current.siteDeliver}},{id:'InputField',loc:'elements',minWidth:'100%',viewData:{state:'notes',label:'Notes',type:'text',multiline:true}}]

    if(outputRef.current.ProjectID?.[0]?.length>0){
      setPopup(p=>({...p,
      inputField: [ <Box sx={{flex:1,padding:'2% 1%!important', gap:'clamp(6px, 1%, 8px)',display:'flex',flexDirection: useAndMobile ? 'column' : 'row' }}>  
                          <Item sx={{margin:'auto','& > *':{flex:1}}}>
                          { dynamicComponent('UserButton','elements',{state:'projManager',label:useAndMobile?'Select Project Manager':'If you are not the Project Manager, please select someone else by clicking on the name.',defaultValue: latestState('ProjectState.projManager') || UserMap.get('avatar'), divisionName:divisionMap.get(UserMap.get('division'))?.teamName},outputState) }
                          </Item>
                          <Item sx={{flexDirection:'column',margin:'auto','& > *':{flex:1}}}>
                          { dynamicComponent('DatePicker','elements',{state:'SalesState.projectDue',label:'Estimated Project Date', defaultValue: latestState('SalesState.projectDue') || 'Set Ideal Due Date', min: dayjs().startOf('day')},outputState) }
                          { latestState('SalesState.projectDue') && dayjs(latestState('SalesState.projectDue')).isSameOrAfter(dayjs().add(6, 'month')) && <Chip color="warning" size="small" label={'This Project is greater than 6 months out!'} icon={<WarningIcon />} /> }
                          
                          </Item>
                        </Box>,
      <Paper elevation={3} sx={{padding:'.15%'}}>
      { dynamicComponent('Listable','elements',{state:'lineItems',label:'Add Line Item',items:plnRec,defaultValue: null,layout:'row' },outputState) }
      </Paper>]
    }));
    ChipState();
  } else setPopup(p=>({...p,
      inputField: <Box sx={{padding:'2%',display:'flex',justifyContent:'center'}}><Chip color="warning" size="small" icon={<InfoIcon />} label="You must first select a Project!" /></Box>
    }))
  },[outputRef.current.lineItems, outputRef.current.ProjectID,outputRef.current.projManager,stateRef.current.submitReq,outputRef.current['SalesState.projectDue']]);

  const createVendor=()=>{
    var userDiv = outputRef.current.DivisionID || UserMap.get('division')
    var modelType = divisionMap.get(userDiv)?.teamName
    setState(p=>({...p,autocompleteReload:undefined}))
    setPopup2(p=>({...p,
      open:true,
      title:`Create New Vendor`,
      description: `An account number and Vendor name is required. All others are optional.`,
      zIndex: 2,
      inputField: <Box sx={{width:'60vw'}}>{
        dynamicComponent('AddVendor','elements',{state:'vendor',label:'Add an Account Number'},mainState)
      }</Box>,
      handleClose: ()=>setPopup2({open:false}),
      nameInputSets: {},
  }))  
  }

  const createProj=()=>{
    var userDiv = outputRef.current.DivisionID || UserMap.get('division')
    var modelType = divisionMap.get(userDiv)?.teamName
    setState(p=>({...p,autocompleteReload:undefined}))
    setPopup2(p=>({...p,
      open:true,
      title:`Create Project`,
      description: `Please ensure your order # is unique.`,
      zIndex: 2,
      inputField: <Box sx={{width:'60vw',height:'80vh'}}>{dynamicComponent('editProj','component',{projID:{DivisionID:userDiv,modelType:modelType},ref:module},That,[(x)=>{
        setState(p=>({...p,autocompleteReload:true}));setOutput(p=>({...p,ProjectID:x}));
        setPopup2({open:false})
       }])}</Box>,
      handleClose: ()=>setPopup2({open:false}),
      nameInputSets: {},
  }))  
  }

  useEffect(()=>{

    setPopup(p=>({...p,
        open:true,
        zIndex: 1,
        title:`B.O.M. Order Request`,
        width:'75vw',
        description:`You will need to complete all fields to submit`,
        headerButton: <Box sx={{ml:'3%',flex:.6}}>
          { dynamicComponent('AutoComplete','elements',{state:'ProjectID',syncState:mainState,single:true,label:'Job Number',model:'Projects',idConvert:'$nin',query:{'_id':Array.from(projectMap).flatMap(([k])=>k),'Status':{'$in':['Sales','Project']}},filter:{},modelType: Array.from([...divisionMap],([k,{teamName}])=>teamName),addEntry:createProj},mainState, [({ProjectID})=>setOutput(p=>({...p,ProjectID:[ProjectID]}))]) }
          </Box>,
        handleClose: ()=>{
          setParentState(p=>({...p,
            purchaseRequestField: undefined
          }))
          setPopup({open:false})
        }
    }))
  },[])

function ChipState(){
  if(inventoryMap) {
  var out = outputRef.current.lineItems?.filter(({ProductItems})=>ProductItems)?.flatMap(({ProductItems},i)=> 
    ProductItems?.flatMap(({id})=>({index:i,ProductID:id})))?.flatMap(({index,ProductID})=>{ 
      var newItems = Array.from(inventoryMap,([k,{ProductItems:[y],Status,QTYOnHand}])=>{
        if(!['undefined',undefined].includes(y?.id)) var id = y.id
        else id = y?.Product?._id
        if(Status=="Stock"&&id===ProductID) return {InventoryID:k,QTYOnHand}
        return []
      })
      return { index,items: newItems.length>0 ? [].concat(...newItems) : newItems}
  }
  )
  setAppState(p=>{
    p.set('stockResp',out)
    return new Map(p)
    })
}  
}

useEffect(()=>{
  if(outputRef.current.lineItems?.length > 0) ChipState()
},[outputRef, stateRef.current.itemTransfer,inventoryMap])

  useEffect(()=>{
    if(outputRef.current.quantity > 0 && stateRef.current.itemTransfer) setTransferOpen(true)
  },[outputRef.current.quantity, stateRef.current.itemTransfer])

  useEffect(()=>{
    if(outputRef.current && ['lineItems'].every(x=> Object.keys(outputRef.current).includes(x)) && stateRef.current.ProjectID?.length>0 && outputRef.current.lineItems?.length>0 && outputRef.current.lineItems.every(x=> ['ProductItems','quantity','PreferredVendor'].every(z=> Object.keys(x).includes(z) && (z === 'quantity' ? x[z] > 0 : true)))) setPopup(p=>({...p,
      handleSubmit: submitItems,
    }))
    else if(popupData.handleSubmit) setPopup(p=>({...p,
      handleSubmit: undefined,
    }))
  },[outputRef.current])

return null
}

export default PurchaseReq;