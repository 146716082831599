import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import { Map } from '@vis.gl/react-google-maps';
import  GooglePlacesAutocomplete, { getLatLng, geocodeByPlaceId } from "@dylmye/mui-google-places-autocomplete";

 const GeoSuggest=({That,viewData,action})=>{
  const [, setThat,] = That;  
  const [, setState,] = useState(Object)
  const [value, setValue] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

    useEffect(()=>{
      if(selectedOption){
        var {description, place_id} = selectedOption;
        geocodeByPlaceId(place_id)
        .then(async(results) =>({location: await getLatLng(results[0]),details:results[0]?.address_components}))
        .then((x) =>{ var {location,details} = x
          setState(p=>({...p,...{value:description}}))
          if(action) action.forEach((x)=> x({map: MapMap(location),[viewData.state]:{label:description,location:location,details:details,placeId:place_id}}) )
          else setThat(p=>({...p,...{map: MapMap(location),[viewData.state]:{label:description,location:location,details:details,placeId:place_id}}}))
        }).catch(error => console.error(error));
      }
    },[selectedOption])

    const MapMap = (x) => 
    <Map 
     mapId={'bf51a910020fa25a'}
     center={{
      lat: x.lat,
      lng: x.lng
    }} 
     zoom={14}
     gestureHandling={'greedy'}
     disableDefaultUI={true}
     options={{
       zoomControl: false,
       streetViewControl: false,
       mapTypeControl: false,
       fullscreenControl: false,
     }}>
   </Map> 

  return <GooglePlacesAutocomplete
  inputValue={value}
  setInputValue={(newValue) => setValue(newValue)}
  value={selectedOption}
  setValue={(newOption) =>{ setSelectedOption(newOption)}}
  label={viewData?.label || 'Enter Location'}
  debounce={300}
  minLengthAutocomplete={0}
  onLoadFailed={(error) => console.error(error)}
  inputProps={{
    fullWidth: true,
  }}
/>
 }

export default GeoSuggest