import React, { Suspense, useEffect, useRef } from "react";
import { useAtom } from 'jotai';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck, faBarcode, faPlus, faTrash,faCommentsDollar,faProjectDiagram, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import useState from 'react-usestateref';
import dayjs from 'dayjs';
import TaskManager from '../FileManager/TaskManager';

import { experimentalStyled as styled, alpha } from '@mui/material/styles';
import { Box, Typography, Card, CardHeader, CardContent, Grid, Paper,Divider, Chip, Fab, Select,Grow, Table,TableBody,TableCell,TableContainer,TableHead,TableRow, InputBase, LinearProgress } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import {Cancel,KeyboardArrowDown} from '@mui/icons-material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ListAltIcon from '@mui/icons-material/ListAlt';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import SearchIcon from '@mui/icons-material/Search';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

const Item = styled(Paper)(({ theme })=>({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '3%',
  paddingTop: '5%',
  textAlign: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  height: '100%',
  overflow:'auto',
  color: theme.palette.text.secondary,
}));

const BoxGroup = styled(Box)(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: '.25% 1%',
  borderRadius: '8px',
}));

export const Component_editProj = (props) =>{
const {globalWrn,viewData,globalState,dbUpdateValidate,dynamicComponent,handlePopupClose,useIsMobile,waveLoad,action,dynamicMapData,toggleUserSubscription,newNotification,queryDB,projectBuilder,projectDelete,trackPackage} = props
const fieldState = useState(Object)
const filePrepState = useState(Object)
const [, setFilePrep, filePrepRef] = filePrepState
const outputState = useState(Object)
const [, setOutput, outputRef] = outputState
const mainState = useState(Object)
const [, setState, stateRef] = mainState
const drawerState = useState({drawerState:false})
const [, setDrawer,] = drawerState

const [inventoryMap] = useAtom(globalState.inventoryMapState)
const [projectLoggMap, setProjectLoggMap] = useAtom(globalState.projectLoggMapState)
const [, setSalesFieldState, salesFieldStateRef] = useState(Object)
const [, setProjectFieldState, projectFieldStateRef] = useState(Object)
const [UserMap] = useAtom(globalState.userMapState);
const [teamMember] = useAtom(globalState.teamMemberState)
const [productMap] = useAtom(globalState.productMapState)
const [projectStats] = useAtom(globalState.projectsStatsState)
const [divisionMap] = useAtom(globalState.divisionMapState)
const [projectMap,setProjectMap] = useAtom(globalState.projectMapState)
const [projectServices] = useAtom(globalState.projectServicesState);
const [popupData, setPopup] = useAtom(globalState.popupState)
const [, setPopup2] = useAtom(globalState.popupState2)
const [projectKeysMap] = useAtom(globalState.projectKeysState);
const [, setFormName, formNameRef] = useState([])
const mapRef = useRef(null)
const [appState, setGlobalAppState] = useAtom(globalState.appState)
const alignments = useState("All");
var gridRef = useRef(null)

const {projID,DivisionLock} = viewData
const { ProjectID, DivisionID, modelType } = projID
const ProjectData = projectMap.has(ProjectID) ? projectMap.get(ProjectID) : undefined

var paused = ProjectData?.ProjectState?.Paused?.some(x=> !x.endDate && x.startDate) || false

const editStyle ={
  popupPreviewList:{
    display:'flex',
    flexDirection:globalState.useIsMobile(560) ?'column':'row'
  }
}

useEffect(()=>{
  if(outputRef.current.defaultScan){
    setState(p=>({...p,defaultButtonLabel: "New Scan: "+ outputRef.current.defaultScan })); setScan()
  }
},[outputRef.current.defaultScan])

const viewEditlevel = (k,type)=>{
  var userDiv = UserMap.get('division');
  var cat = divisionMap.get(userDiv)?.category;
  var def = type+'Level';
  var x = projectKeysMap.get(k);
  return  x?.[userDiv+'_'+def] ? userDiv+'_'+def : x?.[cat+'_'+def] ? cat+'_'+def : def
}

const simp = (t, path) => path.split(".").reduce((r, k) => r?.[k], t)
function latestState(x){
  return outputRef.current[x] || simp(ProjectData,x)
}

const DivisionData = divisionMap.get(latestState('DivisionID') || DivisionID)

const noficationSubmit=()=>{
  var ommit = ['DivisionID','projIDtemp']

  var checkPt = ['SalesState.clientID','SalesState.partnerID','ProjectState.projManager','SalesState.estimator','ProjectState.teamLead','CreatedBy']
  var origItems = checkPt.flatMap((x)=>simp(ProjectData,x)).filter(x=>x!==undefined)
  var memItems = simp(ProjectData,'ProjectState?.Members')?.map(x=> x.id)?.filter(x=>x!==undefined) || []
  var newItems = checkPt.flatMap((x)=>outputRef.current[x]).filter(x=>x!==undefined)

  var recivers = [...new Set([...origItems,...newItems,...memItems])]

  var Body = Object.entries(outputRef.current)
            .filter(([k,v])=>!ommit.includes(k))
            .map(([k,v])=>{
              if(projectKeysMap.has(k)) var {Label} = projectKeysMap.get(k);

        if(k.includes(".")) var ProjType = k?.split(/[.]+/)?.pop()?.match(/[A-Z][a-z]+|[0-9]+/g)?.join(" ") || k?.split(/[.]+/)?.pop()
        else ProjType = k?.match(/[A-Z][a-z]+|[0-9]+/g)?.join(" ") || k
        
        if(dayjs(v).isValid() && v instanceof Date) v = dayjs(v).format('MMM Do YY - h:mm a') 
        if(teamMember.has(v)) v = `${teamMember.get(v).firstName} ${teamMember.get(v).lastName}`

        var info = `\n\t\u2022 ${Label || ProjType} has been updated ${v instanceof Object || v instanceof Array ? ".": "to: "+v}`
        return {key:k,info:info}
    })

    recivers.forEach((y)=>{
    var newBody = Object.entries(Body).filter(([k,{key,info,info1}])=> projectKeysMap.has(key) && projectKeysMap.get(key)[viewEditlevel(key,'edit')]?.includes(teamMember.get(y)?.level)) // projKeys[key]?.[viewEditlevel(projKeys[key],'view')] && projKeys[key][viewEditlevel(projKeys[key],'view')].includes(teamMember.get(y)?.level))
    .map(([k,{info}])=>info).join('')
    var header = `${UserMap.get('firstName')} ${UserMap.get('lastName')} updated Project "${ latestState('ProjectTitle') }" with the following changes:\n\n ${newBody}`
    if(newBody){
      var phoneNum = teamMember.get(y)?.mobilePhone?.replace(/[^0-9]/g,'')?.slice(-10)
      var email = teamMember.get(y)?.email
      var name_u = (teamMember.get(y)?.firstName + ' ' + teamMember.get(y)?.lastName)
      newNotification({Title:`Project "${ latestState('ProjectTitle')}" has been updated.`,Body:header,Data:null,Avatar:null,Icon:null,Choice:{AppNotification:true,Text:false,Email:true},mobile:phoneNum,Email:email,EmailForm:"Basic",name:name_u,sender:UserMap.get('avatar'),receiver:y})
    } 
    })
  }

  // Warning that submit is taking long
  useEffect(()=>{
    setTimeout(()=>{
      if(stateRef.current.warn === "warn") {
        globalWrn('warning', 'We are running a bit slow, try resubmitting the form or wait a little longer')
        setState(p=>({...p,warn: null})) 
      }
      },8000)
  },[stateRef.current.warn])

  useEffect(()=>{
    console.log(projectMap.get(ProjectID))
  },[])

const submit=()=>{

  if(checkIfSupport()) // format notes for ticketing system
  {
    var Notes = ['ticketNotes.TodoNotes', 'ticketNotes.WOChanges', 'ticketNotes.FollowUpNotes', 'ticketNotes.CloseWONotes', 'ticketNotes.NeedToCompleteNotes', 'ticketNotes.RescheduleNotes']
    Notes.forEach(x=>{
      // Split the string into an array of property names
      var properties = x.split('.');

      // Use reduce to access the nested property dynamically
      var resultPrj = properties.reduce((obj, prop) => obj ? obj[prop] : undefined, ProjectData);
      if(outputRef.current?.[x])
      {
        var Notes;
        var tempObj;
        var isOriginal;
        if(resultPrj && resultPrj.length > 0)
        {
          
          if(outputRef.current?.[x]?.includes(resultPrj.slice(-1)[0]?.Notes))
          {
            isOriginal = false
          }
          else
          {
            isOriginal = true
          }
          //var Notes
          if(resultPrj.length > 0)
            Notes = resultPrj
          tempObj = [{Notes:outputRef.current?.[x],isOriginal:isOriginal, timestamp: new Date(), UserID: UserMap.get('avatar')}]
          Notes = tempObj.concat(Notes)
        }
        else
        {
          Notes = []
          isOriginal = true
          tempObj = [{Notes: outputRef.current?.[x], isOriginal: isOriginal, timestamp: new Date(), UserID: UserMap.get('avatar')}]
          Notes = tempObj.concat(Notes)
        }
        setOutput(p=>({...p,
          [x]: Notes
        }))
      }
    })
    if(!ProjectID) // CreatedDate timestamp for ticket
      setOutput(p=>({...p,
        Created: new Date()
      }))
    setOutput(p=>({...p,
      timestamp: new Date()
    }))
  }

  if( UserMap.get('pageStamp').active==='Sales' && !latestState('SalesState.Status')) setOutput(p=>({...p,'SalesState.Status':'Estimation'}))
  if(checkIfSupport())
  {
    if(!latestState('PoNumber'))
      setOutput(p=>({...p,
        PoNumber: outputRef.current.TicketNumber
      }))
    
    if(!latestState('TicketStatus'))
    setOutput(p=>({...p,
      TicketStatus: "Todo"
    }))
  }

  if(DivisionData?.teamName !== "Fresno Support" || divisionMap.get(outputRef.current.DivisionID)?.teamName !== "Fresno Support") {
    if(!ProjectID&&(!latestState('ProjectTitle') || !latestState('PoNumber') || Array.from([...projectMap],([k,v])=> (v.ProjectTitle===latestState('ProjectTitle')||v.PoNumber===latestState('PoNumber'))&&k!==ProjectID).some(x=>x))){
    setState(p=>({...p,loading:false,[`error-ProjectTitle`]:true}))
    if(Array.from([...projectMap],([k,v])=> v.PoNumber===latestState('PoNumber')&&DivisionData?.teamName!=="Fresno Support"&&k!==ProjectID).some(x=>x)) globalWrn('error',`A Job / Project Number : ${latestState('PoNumber')} aleardy exists.`)
    if(Array.from([...projectMap],([k,v])=> v.ProjectTitle===latestState('ProjectTitle')&&k!==ProjectID).some(x=>x)) globalWrn('error',`A Project name: ${latestState('ProjectTitle')} aleardy exists.`)
    if(!latestState('PoNumber')) globalWrn('error',`You are required to have a Job / Project Number.`)
    if(!latestState('ProjectTitle')) globalWrn('error',`You are required to have a Project name.`)
    return
  }
  }

if((!ProjectID && UserMap.get('pageStamp').active==='Projects')||action) setOutput(p=>({...p,'Status':'Project','SalesState.Status':'Awarded','ProjectState.Status':'Upcoming'}))

if( !latestState('CreatedBy') ) setOutput(p=>({...p,'CreatedBy':UserMap.get('avatar')}))
if(latestState('SalesState.Status') === "Estimation" && latestState('SalesState.ProjectAmmount') && latestState('ProjectState.projManager')) setOutput(p=>({...p,'SalesState.Status':'Awardable'}))
if(outputRef.current.projectLoc?.thumb && outputRef.current.projectLoc?.thumb?.props?.children?.props?.src) setOutput(p=>({...p,['ProjectLoc.thumb']: outputRef.current.projectLoc?.thumb?.props?.children?.props?.src }))
if(outputRef.current['ProjectState.DefaultScan']===false){ 
  delete outputRef.current['ProjectState.DefaultScan']
  setOutput(p=>({...p,$pull: {'ProjectState.DefaultScan': ProjectData.ProjectState.DefaultScan }})) 
}

if(DivisionLock) setOutput(p=>({...p,'DivisionID':DivisionLock})) 

  var projIDtemp = { DivisionID: latestState('DivisionID') ? latestState('DivisionID') : DivisionData?._id ? DivisionData?._id : UserMap.get('division'),modelType: divisionMap.has(latestState('DivisionID')) ? divisionMap.get(latestState('DivisionID')).teamName : divisionMap.get(UserMap.get('division')).teamName }
  if(ProjectID) projIDtemp.ProjectID = ProjectID

if(!latestState('DivisionID')) setOutput(p=>({...p,'DivisionID':projIDtemp.DivisionID})) 

if(outputRef.current['SalesState.ProjectAmmount']) setOutput(p=>({...p,'SalesState.ProjectAmmount': Number(outputRef.current['SalesState.ProjectAmmount']?.replace(/[^0-9.-]+/g,"")) }))

var checkerFilter = (a,r) =>{ 
  try{ return r.filter(v => !a.includes(v) )}catch(e){return []}
 }

function updaterUsers(pID,modelType){
  var finArr = [];
  var OrrArr = [];
['SalesState.clientID','SalesState.partnerID','ProjectState.projManager','SalesState.estimator','ProjectState.teamLead','ProjectState.Members','CreatedBy'].forEach(x=>{
  var projDat = simp(ProjectData,x)
  var projNex = outputRef.current[x]

  if(projDat?.constructor === String) projDat = [projDat]
  if(projNex?.constructor === String) projNex = [projNex]
  if(projDat?.[0]?.constructor === Object) projDat = projDat?.map(x=> x.id)?.filter(x=>x!==undefined)
  if(projNex?.[0]?.constructor === Object) projNex = projNex?.map(x=> x.id)?.filter(x=>x!==undefined)
  if(projDat === undefined) projDat = []
  if(projNex === undefined) projNex = []
  
  if(projDat.length !== 0) projDat.forEach(y=>finArr.push(y))
  if(projNex.length !== 0) OrrArr.forEach(y=>OrrArr.push(y))

  if(projNex){ 
  var addUser = checkerFilter(projDat,projNex)
  if(addUser.length>0) toggleUserSubscription({users:addUser,type:'addUser',ProjectID: pID, modelType: modelType, projData:{ Status: latestState('Status'), SubStatus: latestState(`${latestState(`Status`)+'State'}Status`) }})
}
})
var TestfinArr = [];
finArr.forEach((c)=>{
  if(!TestfinArr.includes(c)){
    TestfinArr.push(c)
  }
});
}

// Safety Check
if(outputRef.current?.['SalesState.ProjectAmmount'] === "") delete outputRef.current?.['SalesState.ProjectAmmount']

if(checkIfSupport())
{
  if(outputRef.current.TicketStatus && outputRef.current.TicketStatus !== ProjectData?.TicketStatus)
  {
    setOutput(p=>({...p,
      statusTimestamp: new Date()
    }))
  }
  Promise.all(
    Array.from( [...divisionMap],([,{teamName}])=> new Promise((res) => queryDB({model:'Projects',query:{TicketNumber:outputRef.current.TicketNumber},filter:{_id:1},modelType:teamName},(items,err)=> res(items))) )
  ).then((x)=>{ 
    var instance = true;
    if([].concat(...x).length !== 0) // check if ticket number exists
    {
      if(ProjectID) // if existing project we dont care
        instance = true;
      else if(!ProjectID) // if non existing project ticket number is duplicate
        instance = false;
    }

    if(instance === true)
    projectBuilder({projID:projIDtemp,submitObj:ProjectID?{$set:outputRef.current}:outputRef.current}).then((x)=>{   
      // Clear loading icon
      setState(p=>({...p,
        SaveIcon: <SaveIcon/>,
        warn: null,
      }))

        var {success,proj,} = x    
        if(success===true){ 
              updaterUsers(proj._id ? proj._id : ProjectID, divisionMap.get(proj.DivisionID ? proj.DivisionID : DivisionID )?.teamName)
              noficationSubmit()
              setState(p=>({...p,
                createNewTicket: false,
              }))
              fileUploader()
              if(action) action.forEach((x)=>x(proj._id)) 
              if(!ProjectID) stateRef.current.createNewFolder(`/EKC/Projects`, proj._id)
              .then(({data})=>{
                stateRef.current.createNewFolder(`/EKC/Projects/${ proj._id}`,'EOD')
                stateRef.current.createNewFolder(`/EKC/Projects/${ proj._id}`,'Team Notes')
                stateRef.current.createNewFolder(`/EKC/Projects/${ proj._id}`,'Client Docs')
              projectBuilder({projID: proj._id,submitObj:ProjectID ? {$set:{[`Repository.${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}folderID`]: data.folderInfo.id }} : {[`Repository.${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}folderID`]: data.folderInfo.id}},({success, update})=>{
                if(success) globalWrn('success',`You have successfully created a repository for ${outputRef.current['name']}`)
              }) })
              setGlobalAppState(p=>{ 
              p.delete('modulePop')
              return new Map(p)
              })
            }else {
              globalWrn('error',`There was an error updating project ${ProjectData?.ProjectTitle}.`)
            }
            setState(p=>({...p,loading : false }))
        })
        else{
          GenerateTicketNumber()
          globalWrn('error',`Ticket number already exists or was just created. Changed # to ${outputRef.current.TicketNumber}.`)
          setState(p=>({...p,
            regenerated: true
        }))
        }
  }).then(()=>{ // ensure that ticket was created
    if(stateRef.current.regenerated && stateRef.current.regenerated === true)
    {
      projectBuilder({projID:projIDtemp,submitObj:ProjectID ? {$set:{...outputRef.current}} : outputRef.current}).then((x)=>{     
        var {success,proj,} = x    
        if(success===true){ 
              updaterUsers(proj._id ? proj._id : ProjectID, divisionMap.get(proj.DivisionID ? proj.DivisionID : DivisionID )?.teamName)
              noficationSubmit()
              setState(p=>({...p,
                createNewTicket: false,
              }))
              fileUploader()
              if(action) action.forEach((x)=>x(proj._id)) 
              if(!ProjectID) stateRef.current.createNewFolder(`/EKC/Projects`, proj._id)
              .then(({data})=>{
                stateRef.current.createNewFolder(`/EKC/Projects/${ proj._id}`,'EOD')
                stateRef.current.createNewFolder(`/EKC/Projects/${ proj._id}`,'Team Notes')
                stateRef.current.createNewFolder(`/EKC/Projects/${ proj._id}`,'Client Docs')
              projectBuilder({projID: proj._id,submitObj:ProjectID ? {$set:{[`Repository.${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}folderID`]: data.folderInfo.id }} : {[`Repository.${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}folderID`]: data.folderInfo.id }},({success, update})=>{
                if(success) globalWrn('success',`You have successfully created a repository for ${outputRef.current['name']}`)
              }) })
              setGlobalAppState(p=>{ 
              p.delete('modulePop')
              return new Map(p)
              })
            }else {
              globalWrn('error',`There was an error updating project ${ProjectData?.ProjectTitle}.`)
            }
            setState(p=>({...p,loading : false }))
        })
    }
  })
}
else
projectBuilder({projID:projIDtemp,submitObj:ProjectID ? {$set:{...outputRef.current}} : outputRef.current},(x)=>{
// Clear loading icon
setState(p=>({...p,
  SaveIcon: <SaveIcon/>,
  warn: null,
}))
var {success,proj} = x    
if(success===true){ 
      updaterUsers(proj?._id ? proj?._id : ProjectID, divisionMap.get(proj.DivisionID ? proj.DivisionID : DivisionID )?.teamName)
      noficationSubmit()
      setState(p=>({...p,
        createNewTicket: false,
      }))
      fileUploader()
      if(action) action.forEach((x)=>x(proj._id)) 
      if(!ProjectID) stateRef.current.createNewFolder(`/EKC/Projects`, proj._id)
      .then(({data})=>{
        stateRef.current.createNewFolder(`/EKC/Projects/${ proj._id}`,'EOD')
        stateRef.current.createNewFolder(`/EKC/Projects/${ proj._id}`,'Team Notes')
        stateRef.current.createNewFolder(`/EKC/Projects/${ proj._id}`,'Client Docs')
      projectBuilder({projID: proj._id,submitObj:ProjectID ? {$set:{[`Repository.${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}folderID`]: data.folderInfo.id }} : {[`Repository.${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}folderID`]: data.folderInfo.id }},({success, update})=>{
        if(success) globalWrn('success',`You have successfully created a repository for ${outputRef.current['name']}`)
      }) })
      setGlobalAppState(p=>{ 
      p.delete('modulePop')
      return new Map(p)
      })
    }else {
      globalWrn('error',`There was an error updating project ${ProjectData?.ProjectTitle}.`)
    }
    setState(p=>({...p,loading : false }))
  })
}

const fileUploader=()=>{

}

const create=()=>{
   if(stateRef.current.SaveIcon) setState(p=>({...p,SaveIcon: waveLoad(), warn: "warn"}))
   if(stateRef.current.loading === true) return
   setState(p=>({...p,loading : true }))
  submit()
}

const deliveryOptions=()=>{
  setPopup(p=>({...p,
    open:true,
    zIndex: 2,
    title:`Set Deliveries`,
    //description: 'Your Deliveries will be amazing.',
    inputField: dynamicComponent('Deliveries','elements',{state:"Deliveries", projID},outputState),
    handleClose: handlePopupClose,
    nameInputSets: {},
}))
}

const defaultScan = (data)=>{
setOutput(p=>({...p,'ProjectState.DefaultScan': data.SerialNumber}))
setTimeout(()=>handlePopupClose(),1800)
}

const deleteDefaultScan = () =>{
  projectBuilder({projID:{ProjectID:ProjectID,modelType:modelType,division:DivisionID},submitObj:ProjectID ? {$set:{"ProjectState.DefaultScan":""}} : {"ProjectState.DefaultScan":""}})
  dbUpdateValidate({model:'Projects',modelType:modelType,division:DivisionID,queryID:true,query:ProjectID,update:{ $unset: {"ProjectState.DefaultScan":""} }})
  globalWrn('info','You have marked the default serial number for deletion!')
  handlePopupClose()
}

const setScan = ()=>{
  setPopup(p=>({...p,
    open:true,
    title: ProjectData?.ProjectState?.DefaultScan ? `Change Project's Default Scan` :`Set Project's Default Scan`,
    description: "To set scan, use either a scanner (scan any serial number, and scan now), manual entry (case sensitive), or camera. If unwanted scan exists, use delete default serial number option",
    inputField: <Box sx={{display:'flex',flexDirection:'column',gap:'5px',p:'1%'}}>{[stateRef.current?.setScanButt,stateRef.current.scanner,ProjectData?.ProjectState?.DefaultScan?stateRef.current.delScanner:null]}</Box>,
    handleClose: handlePopupClose,
    nameInputSets: {},
}))
}

const chromebookBuild=()=>{
  setSalesFieldState(p=>({...p,
  'ProjectTitle': stateRef.current.ProjTitleField,
  'PoNumber': stateRef.current.POnumb,
  'SalesState.estimator': stateRef.current.EstimatorField,
  'ProjectState.projManager': stateRef.current.PMField,
  'ProjectDesc': stateRef.current.ProjDescField,    
  'SalesState.projectDue': <Box sx={{m:'auto',flex:1,flexDirection:'column',textAlign:'left',border:'1px solid rgba(255,255,255,0.3)',borderRadius:'4px',padding:'.2% 3%'}}>{stateRef.current.ProjDueField}</Box>,
  'SalesState.partnerID': stateRef.current.PartnerField,
  'SalesState.clientID': stateRef.current.ClientField,
  'SalesState.UnitCount': <Box sx={{display:'flex',width:'100%'}}>{stateRef.current.addInv}</Box>,
  'SalesState.ProjectAmmount': stateRef.current.InvoiceField,
  'SalesState.OptionsFilter': stateRef.current.projectBuilderField,
  'SalesState.Costs': stateRef.current.CostsFields,
  'ProjectLoc': stateRef.current.GeoSuggestField
}))

var tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate()+1)
setProjectFieldState({
  'ProjectState.Members': <Box sx={{m:'auto',flex:1,flexDirection:'column'}}><Box sx={{display:'flex',justifyContent:'space-between',flex:1,p:'3px 2% 2%'}}><Typography  variant="h7">Team Members</Typography>{stateRef.current.TeamMembers}</Box>{stateRef.current.teamAdd}</Box>,
  'ProjectState.teamLead': stateRef.current.LeadField,
  'ProjectState.Calendar.startDate': stateRef.current.startDateField,
  'ProjectState.Calendar.endDate': stateRef.current.endDateField,
  'ProjectState.DefaultScan': stateRef.current.scanEnableField,
  'SalesState.trackPackages': stateRef.current.tracker,
  'Deliveries': stateRef.current.deliveriesBttn,
  'projectFollow': stateRef.current.followField,
})
}

const constructionBuild=()=>{
  setSalesFieldState({
    'ProjectTitle': stateRef.current.ProjTitleField,
    'PoNumber': stateRef.current.POnumb,
    'ProjectDesc': stateRef.current.ProjDescField,
    'SalesState.ProjectAmmount': stateRef.current.InvoiceField,
    'SalesState.estimator': stateRef.current.EstimatorField,
    'ProjectState.projManager': stateRef.current.PMField,
    'SalesState.dateRFP': <Box sx={{flexDirection:'column',textAlign:'left',width:'100%',border:'1px solid rgba(255,255,255,0.3)',borderRadius:'4px',padding:'.2% 3%'}}>{ stateRef.current.dateRFPField }</Box>,
    'SalesState.projectDue': <Box sx={{m:'auto',flex:1,flexDirection:'column',textAlign:'left',border:'1px solid rgba(255,255,255,0.3)',borderRadius:'4px',padding:'.2% 3%'}}>{stateRef.current.ProjDueField}</Box>,
    'SalesState.partnerID': stateRef.current.PartnerField,
    'SalesState.UnitCount': <Box sx={{display:'flex',width:'100%'}}>{stateRef.current.addInv}</Box>,
    'SalesState.OptionsFilter': stateRef.current.projectBuilderField,
    'SalesState.walkThrough': stateRef.current.walkThrough,
    'SalesState.PoFiles': stateRef.current.poPDF,
    'SalesState.trackPackages': stateRef.current.tracker,
    'ProjectLoc': <Box sx={{p:1,zIndex:999}}>{stateRef.current.GeoSuggestField}</Box>,
  })
 
  setProjectFieldState({
    'ProjectState.teamLead': stateRef.current.LeadField,
    'SalesState.clientID': stateRef.current.ClientField,
    'projectFollow': stateRef.current.followField,
    'ProjectState.Calendar.startDate': <Box sx={{flexDirection:'column',textAlign:'left',width:'100%',p:1}}>{stateRef.current.startDateField}</Box>,
    'ProjectState.Calendar.endDate': <Box sx={{flexDirection:'column',textAlign:'left',width:'100%',p:1}}>{stateRef.current.endDateField}</Box>,
    'ProjectState.Members': <Box sx={{m:'auto',flex:1,flexDirection:'column',p:1}}><Box sx={{display:'flex',justifyContent:'space-between',flex:1,mb:.8}}><Typography  variant="h7">Team Members</Typography>{stateRef.current.TeamMembers}</Box>{stateRef.current.teamAdd}</Box>
    })
}

const supportBuild=()=>{
Object({
  Todo: todo,
  "Create Work Order": createWorkOrder,
  "Schedule": schedule,
  "Close Work Order": closeWorkOrder,
  "Follow Up": followUp,
  "Re-Schedule": reSchedule,
  "Quotes": quotes,
  "Need To Complete": needToComplete
})[latestState('TicketStatus')]?.() || needToComplete()

 function todo(){ 
  setProjectFieldState({})
  setSalesFieldState({
      'ProjectTitle': stateRef.current.ProjTitleField,
      'TicketNumber': stateRef.current.ticketNumb,
      'TicketStatus': stateRef.current.statusDrop,
      'ProjectDesc': stateRef.current.ProjDescField,
      'ticketNotes.TodoNotes': stateRef.current.NotesField,
      'ProjectState.projManager': stateRef.current.PMField,
      'ProjectLoc': <Box sx={{p:1,zIndex:999, padding:'1%', display: 'flex'}}><Box sx={{padding: '2%'}}>{stateRef.current.switchLocation}</Box>{stateRef.current.SiteSwitch && stateRef.current.SiteSwitch === true ? stateRef.current.siteStringInput : stateRef.current.GeoSuggestField}</Box>,
      'SalesState.PoFiles': stateRef.current.poPDF,
      'SalesState.clientID': stateRef.current.ClientField,
      'projectFollow': stateRef.current.followField,
    })
  }

  function createWorkOrder(){ setSalesFieldState({
      'ProjectTitle': stateRef.current.ProjTitleField,
      'TicketNumber': stateRef.current.ticketNumb,
      'TicketStatus': stateRef.current.statusDrop,
      'ProjectDesc': stateRef.current.ProjDescField,
      'ticketNotes.TodoNotes': stateRef.current.NotesField,
      'ticketNotes.CreateWONotes': stateRef.current.NotesField,
      'ProjectState.projManager': stateRef.current.PMField,
      'ProjectLoc': <Box sx={{p:1,zIndex:999, padding:'1%', display: 'flex'}}><Box sx={{padding: '2%'}}>{stateRef.current.switchLocation}</Box>{stateRef.current.SiteSwitch && stateRef.current.SiteSwitch === true ? stateRef.current.siteStringInput : stateRef.current.GeoSuggestField}</Box>,
      'SalesState.PoFiles': stateRef.current.poPDF,
      'WorkOrder': stateRef.current.WorkOrder,
      'ticketNotes.WOChanges': stateRef.current.WOChanges,
      'SalesState.UnitCount': <Box sx={{display:'flex',width:'100%'}}>{stateRef.current.addInv}</Box>,
      'SalesState.clientID': stateRef.current.ClientField,
      'SalesState.trackPackages': stateRef.current.tracker,
      'projectFollow': stateRef.current.followField,
    })
  }
  
 function schedule(){ setSalesFieldState({
      'ProjectTitle': stateRef.current.ProjTitleField,
      'TicketNumber': stateRef.current.ticketNumb,
      'TicketStatus': stateRef.current.statusDrop,
      'ProjectDesc': stateRef.current.ProjDescField,
      'ticketNotes.TodoNotes': stateRef.current.NotesField,
      'ticketNotes.CreateWONotes': stateRef.current.NotesField,
      'ProjectState.projManager': stateRef.current.PMField,
      'ProjectLoc': <Box sx={{p:1,zIndex:999, padding:'1%', display: 'flex'}}><Box sx={{padding: '2%'}}>{stateRef.current.switchLocation}</Box>{stateRef.current.SiteSwitch && stateRef.current.SiteSwitch === true ? stateRef.current.siteStringInput : stateRef.current.GeoSuggestField}</Box>,
      'SalesState.PoFiles': stateRef.current.poPDF,
      'WorkOrder': stateRef.current.WorkOrder,
      'ticketNotes.WOChanges': stateRef.current.WOChanges,
      'SalesState.UnitCount': <Box sx={{display:'flex',width:'100%'}}>{stateRef.current.addInv}</Box>,
      'SalesState.clientID': stateRef.current.ClientField,
      'SalesState.trackPackages': stateRef.current.tracker,
      'projectFollow': stateRef.current.followField,
      'ProjectState.teamLead': stateRef.current.LeadField,
      'ProjectState.Members': <Box sx={{m:'auto',flex:1,flexDirection:'column',p:1}}><Box sx={{display:'flex',justifyContent:'space-between',flex:1,mb:.8}}><Typography  variant="h7">Techs</Typography>{stateRef.current.TeamMembers}</Box>{stateRef.current.teamAdd}</Box>,
      'ProjectState.Calendar.startDate': <Box sx={{flexDirection:'column',textAlign:'left',width:'100%',p:1}}>{stateRef.current.startDateField}</Box>,
      'ProjectState.Calendar.endDate': <Box sx={{flexDirection:'column',textAlign:'left',width:'100%',p:1}}>{stateRef.current.endDateField}</Box>,
    }) }
  
 function closeWorkOrder(){ setSalesFieldState({
      'ProjectTitle': stateRef.current.ProjTitleField,
      'TicketNumber': stateRef.current.ticketNumb,
      'TicketStatus': stateRef.current.statusDrop,
      'ProjectDesc': stateRef.current.ProjDescField,
      'ticketNotes.TodoNotes': stateRef.current.NotesField,
      'ticketNotes.CreateWONotes': stateRef.current.NotesField,
      'ProjectState.projManager': stateRef.current.PMField,
      'ProjectLoc': <Box sx={{p:1,zIndex:999, padding:'1%', display: 'flex'}}><Box sx={{padding: '2%'}}>{stateRef.current.switchLocation}</Box>{stateRef.current.SiteSwitch && stateRef.current.SiteSwitch === true ? stateRef.current.siteStringInput : stateRef.current.GeoSuggestField}</Box>,
      'SalesState.PoFiles': stateRef.current.poPDF,
      'WorkOrder': stateRef.current.WorkOrder,
      'ticketNotes.WOChanges': stateRef.current.WOChanges,
      'SalesState.UnitCount': <Box sx={{display:'flex',width:'100%'}}>{stateRef.current.addInv}</Box>,
      'ticketNotes.CloseWONotes': stateRef.current.closeWO,
      'SalesState.clientID': stateRef.current.ClientField,
      'SalesState.trackPackages': stateRef.current.tracker,
      'projectFollow': stateRef.current.followField,
      'ProjectState.teamLead': stateRef.current.LeadField,
      'ProjectState.Members': <Box sx={{m:'auto',flex:1,flexDirection:'column',p:1}}><Box sx={{display:'flex',justifyContent:'space-between',flex:1,mb:.8}}><Typography  variant="h7">Techs</Typography>{stateRef.current.TeamMembers}</Box>{stateRef.current.teamAdd}</Box>,
      'ProjectState.Calendar.startDate': <Box sx={{flexDirection:'column',textAlign:'left',width:'100%',p:1}}>{stateRef.current.startDateField}</Box>,
      'ProjectState.Calendar.endDate': <Box sx={{flexDirection:'column',textAlign:'left',width:'100%',p:1}}>{stateRef.current.endDateField}</Box>,
    }) }
  
 function followUp(){ setSalesFieldState({
      'ProjectTitle': stateRef.current.ProjTitleField,
      'TicketNumber': stateRef.current.ticketNumb,
      'TicketStatus': stateRef.current.statusDrop,
      'ProjectDesc': stateRef.current.ProjDescField,
      'ticketNotes.TodoNotes': stateRef.current.NotesField,
      'ticketNotes.CreateWONotes': stateRef.current.NotesField,
      'ProjectState.projManager': stateRef.current.PMField,
      'ProjectLoc': <Box sx={{p:1,zIndex:999, padding:'1%', display: 'flex'}}><Box sx={{padding: '2%'}}>{stateRef.current.switchLocation}</Box>{stateRef.current.SiteSwitch && stateRef.current.SiteSwitch === true ? stateRef.current.siteStringInput : stateRef.current.GeoSuggestField}</Box>,
      'SalesState.PoFiles': stateRef.current.poPDF,
      'WorkOrder': stateRef.current.WorkOrder,
      'ticketNotes.WOChanges': stateRef.current.WOChanges,
      'SalesState.UnitCount': <Box sx={{display:'flex',width:'100%'}}>{stateRef.current.addInv}</Box>,
      'ticketNotes.CloseWONotes': stateRef.current.closeWO,
      'ticketNotes.FollowUpNotes': stateRef.current.followUp,
      'SalesState.clientID': stateRef.current.ClientField,
      'SalesState.trackPackages': stateRef.current.tracker,
      'projectFollow': stateRef.current.followField,
      'ProjectState.teamLead': stateRef.current.LeadField,
      'ProjectState.Members': <Box sx={{m:'auto',flex:1,flexDirection:'column',p:1}}><Box sx={{display:'flex',justifyContent:'space-between',flex:1,mb:.8}}><Typography  variant="h7">Techs</Typography>{stateRef.current.TeamMembers}</Box>{stateRef.current.teamAdd}</Box>,
      'ProjectState.Calendar.startDate': <Box sx={{flexDirection:'column',textAlign:'left',width:'100%',p:1}}>{stateRef.current.startDateField}</Box>,
      'ProjectState.Calendar.endDate': <Box sx={{flexDirection:'column',textAlign:'left',width:'100%',p:1}}>{stateRef.current.endDateField}</Box>,
    })
  }

 function reSchedule(){ setSalesFieldState({
      'ProjectTitle': stateRef.current.ProjTitleField,
      'TicketNumber': stateRef.current.ticketNumb,
      'TicketStatus': stateRef.current.statusDrop,
      'ProjectDesc': stateRef.current.ProjDescField,
      'ticketNotes.TodoNotes': stateRef.current.NotesField,
      'ticketNotes.CreateWONotes': stateRef.current.NotesField,
      'ProjectState.projManager': stateRef.current.PMField,
      'ProjectLoc': <Box sx={{p:1,zIndex:999, padding:'1%', display: 'flex'}}><Box sx={{padding: '2%'}}>{stateRef.current.switchLocation}</Box>{stateRef.current.SiteSwitch && stateRef.current.SiteSwitch === true ? stateRef.current.siteStringInput : stateRef.current.GeoSuggestField}</Box>,
      'SalesState.PoFiles': stateRef.current.poPDF,
      'WorkOrder': stateRef.current.WorkOrder,
      'ticketNotes.WOChanges': stateRef.current.WOChanges,
      'SalesState.UnitCount': <Box sx={{display:'flex',width:'100%'}}>{stateRef.current.addInv}</Box>,
      'ticketNotes.CloseWONotes': stateRef.current.closeWO,
      'ticketNotes.FollowUpNotes': stateRef.current.followUp,
      'ticketNotes.RescheduleNotes': stateRef.current.RescheduleNotes,
      'SalesState.clientID': stateRef.current.ClientField,
      'SalesState.trackPackages': stateRef.current.tracker,
      'projectFollow': stateRef.current.followField,
      'ProjectState.teamLead': stateRef.current.LeadField,
      'ProjectState.Members': <Box sx={{m:'auto',flex:1,flexDirection:'column',p:1}}><Box sx={{display:'flex',justifyContent:'space-between',flex:1,mb:.8}}><Typography  variant="h7">Techs</Typography>{stateRef.current.TeamMembers}</Box>{stateRef.current.teamAdd}</Box>,
      'ProjectState.Calendar.startDate': <Box sx={{flexDirection:'column',textAlign:'left',width:'100%',p:1}}>{stateRef.current.startDateField}</Box>,
      'ProjectState.Calendar.endDate': <Box sx={{flexDirection:'column',textAlign:'left',width:'100%',p:1}}>{stateRef.current.endDateField}</Box>,
    }) }
  
 function quotes(){ setSalesFieldState({
      'ProjectTitle': stateRef.current.ProjTitleField,
      'TicketNumber': stateRef.current.ticketNumb,
      'TicketStatus': stateRef.current.statusDrop,
      'ProjectDesc': stateRef.current.ProjDescField,
      'ticketNotes.TodoNotes': stateRef.current.NotesField,
      'ticketNotes.CreateWONotes': stateRef.current.NotesField,
      'ProjectState.projManager': stateRef.current.PMField,
      'ProjectLoc': <Box sx={{p:1,zIndex:999, padding:'1%', display: 'flex'}}><Box sx={{padding: '2%'}}>{stateRef.current.switchLocation}</Box>{stateRef.current.SiteSwitch && stateRef.current.SiteSwitch === true ? stateRef.current.siteStringInput : stateRef.current.GeoSuggestField}</Box>,
      'SalesState.PoFiles': stateRef.current.poPDF,
      'WorkOrder': stateRef.current.WorkOrder,
      'ticketNotes.WOChanges': stateRef.current.WOChanges,
      'SalesState.UnitCount': <Box sx={{display:'flex',width:'100%'}}>{stateRef.current.addInv}</Box>,
      'ticketNotes.CloseWONotes': stateRef.current.closeWO,
      'ticketNotes.FollowUpNotes': stateRef.current.followUp,
      'ticketNotes.RescheduleNotes': stateRef.current.RescheduleNotes,
      'SalesState.clientID': stateRef.current.ClientField,
      'SalesState.trackPackages': stateRef.current.tracker,
      'projectFollow': stateRef.current.followField,
      'ProjectState.teamLead': stateRef.current.LeadField,
      'ProjectState.Members': <Box sx={{m:'auto',flex:1,flexDirection:'column',p:1}}><Box sx={{display:'flex',justifyContent:'space-between',flex:1,mb:.8}}><Typography  variant="h7">Techs</Typography>{stateRef.current.TeamMembers}</Box>{stateRef.current.teamAdd}</Box>,
      'ProjectState.Calendar.startDate': <Box sx={{flexDirection:'column',textAlign:'left',width:'100%',p:1}}>{stateRef.current.startDateField}</Box>,
      'ProjectState.Calendar.endDate': <Box sx={{flexDirection:'column',textAlign:'left',width:'100%',p:1}}>{stateRef.current.endDateField}</Box>,
    }) }
  
 function needToComplete(){ setSalesFieldState({
      'ProjectTitle': stateRef.current.ProjTitleField,
      'TicketNumber': stateRef.current.ticketNumb,
      'TicketStatus': stateRef.current.statusDrop,
      'ProjectDesc': stateRef.current.ProjDescField,
      'ticketNotes.TodoNotes': stateRef.current.NotesField,
      'ticketNotes.CreateWONotes': stateRef.current.NotesField,
      'ProjectState.projManager': stateRef.current.PMField,
      'ProjectLoc': <Box sx={{p:1,zIndex:999, padding:'1%', display: 'flex'}}><Box sx={{padding: '2%'}}>{stateRef.current.switchLocation}</Box>{stateRef.current.SiteSwitch && stateRef.current.SiteSwitch === true ? stateRef.current.siteStringInput : stateRef.current.GeoSuggestField}</Box>,
      'SalesState.PoFiles': stateRef.current.poPDF,
      'WorkOrder': stateRef.current.WorkOrder,
      'ticketNotes.WOChanges': stateRef.current.WOChanges,
      'SalesState.UnitCount': <Box sx={{display:'flex',width:'100%'}}>{stateRef.current.addInv}</Box>,
      'ticketNotes.CloseWONotes': stateRef.current.closeWO,
      'ticketNotes.FollowUpNotes': stateRef.current.followUp,
      'ticketNotes.RescheduleNotes': stateRef.current.RescheduleNotes,
      'ticketNotes.NeedToCompleteNotes': stateRef.current.needToComplete,
      'SalesState.clientID': stateRef.current.ClientField,
      'SalesState.trackPackages': stateRef.current.tracker,
      'projectFollow': stateRef.current.followField,
      'ProjectState.teamLead': stateRef.current.LeadField,
      'ProjectState.Members': <Box sx={{m:'auto',flex:1,flexDirection:'column',p:1}}><Box sx={{display:'flex',justifyContent:'space-between',flex:1,mb:.8}}><Typography  variant="h7">Techs</Typography>{stateRef.current.TeamMembers}</Box>{stateRef.current.teamAdd}</Box>,
      'ProjectState.Calendar.startDate': <Box sx={{flexDirection:'column',textAlign:'left',width:'100%',p:1}}>{stateRef.current.startDateField}</Box>,
      'ProjectState.Calendar.endDate': <Box sx={{flexDirection:'column',textAlign:'left',width:'100%',p:1}}>{stateRef.current.endDateField}</Box>,
    }) }
}

const corperateBuild=()=> setSalesFieldState({
  'ProjectTitle': stateRef.current.ProjTitleField,
  'PoNumber': stateRef.current.POnumb,
  'SalesState.estimator': stateRef.current.EstimatorField,
  'ProjectState.projManager': stateRef.current.PMField,
  'ProjectDesc': stateRef.current.ProjDescField,
  'SalesState.projectDue': <Box sx={{m:'auto',flex:1,flexDirection:'column',textAlign:'left',border:'1px solid rgba(255,255,255,0.3)',borderRadius:'4px',padding:'.2% 3%'}}>{stateRef.current.ProjDueField}</Box>,
  'SalesState.partnerID': stateRef.current.PartnerField,
  'SalesState.clientID': stateRef.current.ClientField,
  'SalesState.tradeName': stateRef.current.TradeNameField,
  'SalesState.jobWalk': stateRef.current.JobWalkField,
  'ProjectLoc': stateRef.current.GeoSuggestField
})

const markups=(x)=> setState(p=>({...p,markups:true}));

const viewEOD=()=> setPopup(p=>({...p,
    open:true,
    zIndex: 2,
    title:`Project's End of Day Reports`,
    description: 'Your EODs will be amazing.',
    inputField: dynamicComponent('EODReport','elements',{state:"EODReport", projID},outputState),
    handleClose: handlePopupClose,
    nameInputSets: {},
}))

const delProj=(x)=>{
  setPopup(p=>({...p,
  open: true,
  title:'Are you sure?',
  description: `You are about to permanently delete ${ProjectData?.ProjectTitle}!`,
  handleSubmit: ()=>{projectDelete(projID,(x)=>{
    setProjectMap({t:'delete',k:projID})
    if(x.success===true){ 
      setGlobalAppState(p=>{
          p.delete('modulePop')
          return new Map(p)
          })
      setPopup(p=>({...p,open:false})) 
    }
    else globalWrn('error',`There was an error deleting project ${ProjectData?.ProjectTitle}.`)

  });
  handlePopupClose()
},    
  handleClose: handlePopupClose,
  submitLabel: 'DELETE',
  nameInputSets: {},
  }))
}

const phaseList =(itemSets)=>{
  var plnRec = [{id:'InputField',loc:'elements',viewData:{state:'label',label:'Phase Name'}},{id:'ProjectBuilder',loc:'elements',viewData:{state:'Options',label:'Scope of Work',color:'#ff9d15',full:true, startIcon:<FactCheckIcon/>,projID:projID, projTitle:ProjectData?.ProjectTitle,...itemSets}}]

  setPopup(p=>({...p,
                open: true,
                zIndex:1,
                title:`Set the Project's Phase and Scope of Work.`,
                description: 'Your Phases will be amazing.',
                inputField: dynamicComponent('Listable','elements',{state:'SalesState.PhaseFilter',label:'Add Project Phase',items:plnRec,defaultValue: latestState('SalesState.PhaseFilter') },outputState),
                cancelLabel: 'Close',
                handleClose: handlePopupClose,
                nameInputSets: {},
            }))
}

const addPoPDF=()=>{
  if(!ProjectID) return globalWrn('error',`You will need to create the project ${latestState('ProjectTitle') ? latestState('ProjectTitle') : ''} first.`)
  setPopup2(p=>({...p,
    open:true,
    title:`${ProjectData.ProjectTitle} Project Files`,
    inputField: dynamicComponent('repository','component',{folderID: ProjectData?.Repository?.[`${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}folderID`],folderName:'/EKC/'+UserMap.get('pageStamp').active,projID},mainState), //folder_1936829
    handleClose: ()=>setPopup2({open:false}),
    cancelLabel: 'Close',
    nameInputSets: {},
}))
}

const trackingStats=()=>{
  setPopup(p=>({...p,
    open:true,
    title:`Tracking Stats`,
    description: `Track your parcels. Quickly and easily`,
    inputField: <Box sx={{display:'flex',gap:'1%'}}>{stateRef.current.trackPackages}{stateRef.current.deliveriesStats}</Box>,
    handleClose: handlePopupClose,
    cancelLabel: 'Close',
    nameInputSets: {},
}))
}

const addPartner=()=>{
  setPopup(p=>({...p,
    open:true,
    title:`Add Partner or Client`,
    description: `Do not add Staff, they will need to create an account.`,
    inputField: stateRef.current.partnerFormBuilder,
    handleClose: ()=>setPopup({open: false}),
    nameInputSets: {},
}))  
}

const pauseProj=(x)=>{ // true is paused false is upaused
  if(x) ProjectData?.ProjectState.Paused.filter(y=>{if(!y.endDate) y.endDate = dayjs().subtract(1, 'minute')})
  else if(!x && ProjectData?.ProjectState.Paused) ProjectData?.ProjectState.Paused.push({startDate:dayjs()})
  else ProjectData.ProjectState.Paused = [{startDate:dayjs()}]
  setOutput(p=>({...p,'ProjectState.Paused': ProjectData?.ProjectState.Paused }))
}

const newSite=()=>{
  setState(p=>({...p,
    newSt: dynamicComponent('AddSites','elements',{state:'newSite'},mainState),
  }))
}

const addSites=()=>{
  newSite()
  setPopup(p=>({...p,
    open:true,
    zIndex: 999,
    title:`Add District/Company Site`,
    description: `Do not add entire company, government bureau/agency, or school district.`,
    inputField: stateRef.current.newSt,
    handleClose: handlePopupClose,
    nameInputSets: {},
}))  
}

const projectList = (items) => {
  // Safety
  if(!items) return
  if(ProjectData?.SalesState?.OptionsFilter && ProjectData?.SalesState?.OptionsFilter.constructor === Object) var listFilter = ProjectData.SalesState.OptionsFilter
 if(items) var data = Object.keys(Object.assign({},...Object.entries(items)
.filter(([k, v],i) => listFilter ? Object.entries(listFilter).some(([k3,v3])=> k3===k) : false )
.map(([k, v],i) =>
listFilter ? Object.entries(listFilter).some(([k3,v3])=>k3===k) ? 
{[k]: Object.entries(v)
.filter(([k2, v2],i2) => Object.entries(listFilter).some(([k3,v3])=> k===k3 ? Object.entries(v3).some(([k4,v4])=> Number(k4)===i2): false) )
.map(([k2, v2],i2) =>({label: v2.label}) )
} 
: {[k]:false}
: {[k]:false}
)) )
try {
return [...data,...Object.keys(items[1])]
}
catch(err) {
return data
}
}

const Searchable =(x)=>{
  if(x?.constructor === Number) return x.toString().startsWith(appState.get('search'))
  if(x?.constructor === Array) return x?.some(z=>Searchable(z))
  if(x?.constructor === Object) return Object.entries(x).some(([k,v])=> Searchable(k) || Searchable(v) )
  if(x?.constructor === String) return x.toLowerCase()?.includes(appState.get('search').toLowerCase()) 
}

const pullReq = () => {

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));


  function onSaving({InventoryID,ColumnID,value,_valuesCache,updateCell,remoteUpdate}){
    if(inventoryMap.has(InventoryID)) var {QTYOnHand} = inventoryMap.get(InventoryID);
    if(QTYOnHand<value) return setPopup2(p=>({...p,
      open:true,
      title: `Pull Request Warning.`,
      zIndex:1,
      description: `You cannot pull more or negative items`,
      handleClose: ()=> setPopup2({open:false}),
    }));
    stateRef.current.pullAmmount.set(InventoryID,{Pull:value});
    setState(p=>({...p, pullAmmount: new Map(stateRef.current.pullAmmount), [_valuesCache?.PurchaseOrder]: QTYOnHand - value }));
    remoteUpdate(value);
    _valuesCache[ColumnID] = value; 
    updateCell(value);
  }

     var newData;
     var items = [].concat(...Array.from([...inventoryMap],([k,v])=> v?.ProjectID?.includes(ProjectID) ? k : []))
     setState(p=>({...p,pullAmmount: new Map() }))
     setPopup(p=>({...p,
          open:true,
          title: `Current Inventory & Pull Req.`,
          zIndex:1,
          description: `Let get going...`,
          headerButton: [dynamicComponent('Switcher', 'elements',{state:'stockSwitch',label:"Pull From Stock",style:{flex:'unset'}},mainState,[({stockSwitch})=>{
            if(stockSwitch===true) newData = [].concat(...Array.from([...inventoryMap],([k,{Status,QTYOnHand}])=> Status === 'Stock'&&QTYOnHand>0 ? k : []))
            else newData = [].concat(...Array.from([...inventoryMap],([k,v])=> v?.ProjectID?.includes(ProjectID) ? k : []))
          setPopup(p=>({...p, inputField: dynamicComponent('InventoryGrid','elements',{state:'DataGridBuilder',focusedLines:newData,alignments:alignments,onSaving:onSaving,gridRef:gridRef,disableGrouping:true,pullReq:true,pagination:stockSwitch},mainState)
          })) 
          }]),
          <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ 'aria-label': 'search' }}
            onChange={({target:{value}})=> setGlobalAppState(p=>{
              p.set('search',value);
              return new Map(p);
               })}
          />
        </Search>
        ],
          inputField: dynamicComponent('InventoryGrid','elements',{state:'DataGridBuilder',focusedLines:items,alignments:alignments,onSaving:onSaving,gridRef:gridRef,disableGrouping:true,pullReq:true},mainState),
          handleClose: ()=> setPopup({open:false}),
      })) 
}

const responsePullReqEmail=({response})=>{
  //console.log("Response Received:", response)
}

useEffect(()=>{
  var divisionName = DivisionData?.teamName ? DivisionData?.teamName : UserMap.has('division') && divisionMap.has(UserMap.get('division')) ? divisionMap.get(UserMap.get('division')).teamName : "EKC Corporate"
  if(stateRef.current.pullAmmount?.size>0&&!popupData.handleSubmit) setPopup(p=>({...p,
  submitLabel:'Pull Inventory',
  handleSubmit: ()=>{ 
    setPopup2(p=>({...p,
      open:true,
      zIndex:2,
      title: `Confirm your Pull Req.`,
      description: `Ensure that everything is correct.`,
      helperObject: dynamicComponent('ToggleBttnGroups','elements',{state:'userOpt',style:{backgroundColor:'unset',border:'unset'}, Items:[{type:'single',label:'TRANSFER OPTIONS',subState:'TransferType',items:[{label:'Direct'},{label:'Delivery'}]}]},mainState,[({userOpt:{TransferType}})=>{
        if(TransferType) Object({
          Direct: ()=> setPopup2(p=>({...p, headerButton: <BoxGroup sx={{display:'flex',m:'.5%', gap:'2%'}}> { dynamicComponent('UserButton','elements',{state:'requestor',label:'Ownership',style:{margin:'auto'},defaultValue:outputRef.current.requestor},outputState) } </BoxGroup> })),
          Delivery: ()=> setPopup2(p=>({...p, headerButton: <BoxGroup sx={{display:'flex',m:'.5%', gap:'2%'}}>{dynamicComponent('UserButton','elements',{state:'deliverDriver',label:'Delivery Driver',style:{margin:'auto'},defaultValue:outputRef.current.deliverDriver},outputState) } <Divider orientation="vertical" variant="middle" flexItem /><Box sx={{margin:'auto'}}>{dynamicComponent('DatePicker','elements',{state:'deliveryDate',label:'Delivery Date', defaultValue:outputRef.current.deliveryDate },outputState) }</Box> </BoxGroup> })),
         // popupData
        })[TransferType]()
      }]),
      headerButton: dynamicComponent('UserButton','elements',{state:'userPickup',label:'Who will pickup item(s)',defaultValue:UserMap.get('avatar'), divisionName:divisionName},mainState),
      inputField: Checkout(),
      handleSubmit: ()=>{
        var Inventory = []
        const date = new Date().toDateString()
        var logManager = DivisionData?.logManager
        var email = 'javakian@ekccorp.com'
        var receiver = UserMap.get('avatar')
        var QTY = []
        var name_u = `${teamMember.get(logManager)?.firstName} ${teamMember.get(logManager)?.lastName}`
        var body = `A BOM Order has been submitted with the following information:${stateRef.current.userPickup ? ('\n\nDesignated Driver: ' + teamMember.get(stateRef.current.userPickup)?.firstName + ' ' + teamMember.get(stateRef.current.userPickup)?.lastName + ' will be picking up the request\n\n') : '\n\n'}Desired Items:\n`
        
        for (let [key, value] of stateRef.current.pullAmmount) {
          body = body + `${inventoryMap.get(key)?.ProductItems[0]?.Product?.DISPLAY_NAME}: ${value?.Pull}\n`
          QTY.push(value?.Pull)
          var temp = {...inventoryMap.get(key)}
          delete temp.ProductItems
          const obj = {...temp, ...inventoryMap.get(key)?.ProductItems?.[0]?.Product}
          Inventory.push(obj)
        }
        
        newNotification({Title:`${UserMap.get('firstName')} ${UserMap.get('lastName')} has submitted a Pull Request on ${date}`,Body:body,Data:null,Icon:null,location:`${DivisionData.teamName}`,Choice:{AppNotification:true,Text:false,Email:true},Email:email,EmailForm:"PullOrder",avatar:teamMember.get(logManager)?.avatar,name:name_u,sender:UserMap.get('avatar'),Inventory:Inventory,QTY:QTY,receiver:receiver, response: responsePullReqEmail})
        setPopup2({open:false});
        setPopup({open:false});
      },
      handleClose: ()=>{
        setPopup2({open:false});
        setPopup(p=>({...p,
          enableSubmitButton: true
        }))
        },
    }))
  }
}))
},[stateRef.current.pullAmmount])

const Checkout = ()=>{
  const rows = Array.from([...stateRef.current.pullAmmount],([k,{Pull}])=>{
    var {ProductItems,PurchaseOrder,QTYOnHand,QTYReceived,Status} = inventoryMap.get(k)
    var ProductID = ProductItems.find(x=>x.id).id

    var DISPLAY_NAME;
    if(productMap.has(ProductID)) DISPLAY_NAME = productMap.get(ProductID)
    else DISPLAY_NAME = ProductItems?.[0]?.Product

    return {data:{Item:DISPLAY_NAME||'Mising',PullOffset:stateRef.current?.[PurchaseOrder], Pull, OnSite:QTYReceived-QTYOnHand||0},ProductID:ProductID,InventoryID:k,Status:Status==='Stock'}
  })
  var header = ['Product','Ammount Requested', 'New Ammount OnHand','Current Ammount OnSite']

  return <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
    <TableHead>
      <TableRow>
        { header.flatMap((x,i)=> <TableCell key={x} align={i!==0 ? 'right':'left'} sx={i===1&&{backgroundColor:'#f4f186',color:'#2e2e2e',fontWeight:900}}><Typography  variant="caption">{x}</Typography></TableCell> )}
      </TableRow>
    </TableHead>
    <TableBody>
      {rows.map(({ProductID,Status,data}) => (
        <TableRow key={ProductID} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
          { Object.entries(data).flatMap(([k,v],i)=> <TableCell key={k} align={i!==0?'right':'left'} sx={i===1?{backgroundColor:'#f4f186',color:'#2e2e2e',fontWeight:900}:Status&&{backgroundColor:'#f9caa7',color:'#2e2e2e'}}>{i===0&&Status&&<Chip label="Stock" variant="outlined" sx={{color:'#2e2e2e',marginRight:'2%'}} />}{k==="Item" ? v?.DISPLAY_NAME : v}</TableCell>) }
        </TableRow>
      ))}
    </TableBody>
  </Table>
  </TableContainer>
}

useEffect(()=>{ 
  if(ProjectID && !projectMap.has(ProjectID)) setGlobalAppState(p=>{
        p.delete('modulePop')
        return new Map(p)
        })
},[projectMap.has(ProjectID)])

useEffect(()=>{
var pauseText = paused?'Unpause Project':'Pause Project'
if(DivisionLock) setOutput(p=>({...p,'DivisionID':DivisionLock})) 

setState(p=>({...p,
    addInv: dynamicComponent('DefaultButton','elements',{state:'PullReq',label:'Inventory/Pull Req.',type:'button',startIcon:<AddShoppingCartIcon/>,full:true},mainState,[pullReq]),
    tracker: dynamicComponent('DefaultButton','elements',{state:'tracking',type:'button',label:'Tracking Shipments',variant:'standard',startIcon:<FontAwesomeIcon icon={faPlus}/>},outputState,[trackingStats]),
    poPDF: dynamicComponent('DefaultButton','elements',{state:'poPDF',type:'button',label:'Add Files',variant:'standard',startIcon:<FontAwesomeIcon icon={faPlus}/>},outputState,[addPoPDF]),
    partnerFormBuilder: dynamicComponent('PartnerField','elements',{state:'newPart'},fieldState),
    headDrp: dynamicComponent('DropDown','elements',{state:'options', list:['Database',ProjectData?.ProjectState?.Status === 'Complete' ? 'Return to Production' : 'Mark As Complete',pauseText,'View EOD','Markups', ProjectID && UserMap.get('level')<=3 ? 'Delete': undefined], replace:true },mainState,[(x,y)=>
      Object({ Database:()=>projGatherData(x,y),
               'Mark As Complete': ()=>projectBuilder({projID:projID,submitObj:ProjectID ? {$set:{'SalesState.Status':'Complete','ProjectState.Status':'Complete',Status:'Completed',TicketStatus: ProjectData?.TicketNumber && 'Completed'}} : {'SalesState.Status':'Complete','ProjectState.Status':'Complete',Status:'Completed'}},(x)=>{globalWrn('info',`Project: ${ProjectData.ProjectTitle} has been marked "Completed".`)} ),
               [pauseText] : ()=>pauseProj(paused),
               'Return to Production': ()=> projectBuilder({projID:projID,submitObj:ProjectID ? {$set:{'SalesState.Status':'Awarded','ProjectState.Status':'Active',Status:'Project',TicketStatus: ProjectData?.TicketNumber && 'Todo'}} : {'SalesState.Status':'Awarded','ProjectState.Status':'Active',Status:'Project'}},(x)=>{globalWrn('info',`Project: ${ProjectData.ProjectTitle} has been marked "Active".`)} ),
               'Delete': ()=>delProj(),
               'Markups': ()=> markups(),
               'View EOD': ()=> viewEOD(),
               })[x.options]()
              ])
  }))

  return () => { // Cleanup function to clear data
    setState({});
    setOutput({});
    setFilePrep({});
    setProjectFieldState({});
  };
},[])

useEffect(()=>{
setState(p=>({...p,
  walkThrough: <Select
    labelId="demo-mutiple-chip-checkbox-label"
    id="demo-mutiple-chip-checkbox"
    multiple
    value={formNameRef.current}
    open={false}
    onClick={()=>setDrawer(p=>({...p,drawerState: true}))}
    IconComponent={KeyboardArrowDown}
    renderValue={(sel) => 
      [<Typography  variant="caption">Walkthrough Forms:</Typography>,
      <Box clickable sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {sel.map((v,i)=>
          <Chip
            key={v.name}
            avatar={<TaskAltIcon/>}
            label={v.name}
            clickable
            deleteIcon={ <Cancel /> }
          />
        )}
      </Box>]
    }
  >
  </Select>
}))
},[formNameRef.current]) 

useEffect(()=>{
  var divisionName = DivisionData?.teamName ? DivisionData?.teamName : UserMap.has('division') && divisionMap.has(UserMap.get('division')) ? divisionMap.get(UserMap.get('division')).teamName : "EKC Corporate"
setState(p=>({...p,
  teamAdd: dynamicComponent('UserSelect','elements',{state:'ProjectState.Members',label:'Associates',projID,divisionName:divisionName,defaultValue:latestState('ProjectState.Members'),projTitle:ProjectData?.ProjectTitle, projList:projectList(projectServices.get(`${DivisionData?.category}Services`)) },outputState),
}));

},[ latestState('DivisionID'), latestState('TicketStatus') ])

useEffect(()=>{
  var items = [
    {id:'DatePicker',loc:'elements',viewData:{state:'date',label:'Arrival Date'}},
    {id:'InputField',loc:'elements',viewData:{state:'label',label:'Product Name',type:'text'}},
    {id:'InputField',loc:'elements',viewData:{state:'addInventory',label:'Add Amount',type:'number'}},
    {id:'InputField',loc:'elements',viewData:{state:'bol',label:'Bill of Lading',type:'text'}}
  ]

  setState(p=>({...p,
   listInventory : dynamicComponent('Listable','elements',{state:'addInventory',label:'+ Add',items:items,defaultValue:outputRef.current?.['SalesState.Inventory']?outputRef.current?.['SalesState.Inventory']:ProjectData?.SalesState?.Inventory,required:false},mainState,[(x)=>{
      if(x.addInventory){
        var invAmmUp = x.addInventory.flatMap(x=>parseInt(x.addInventory,10)).reduce((a,b)=>a+b,0)
        var unitAmm = latestState('SalesState.UnitCount')
        var convert = x.addInventory.map(y=>({...y,addInventory: parseInt(y.addInventory,10)}))
        if(invAmmUp<=unitAmm && invAmmUp > 0) setOutput(p=>({...p,'SalesState.Inventory': convert }))
        else if(invAmmUp && !Number.isNaN(invAmmUp) && invAmmUp !== 0) globalWrn('error',"You cannot have Inventory higher that the project's total amount.")
        setPopup(p=>({...p,description: `You can only add ${invAmmUp ? unitAmm<invAmmUp ? "(Maxed)" : unitAmm-invAmmUp : unitAmm} units of total ${unitAmm} units`,
      }))
      }
    }])
  }))
},[outputRef.current?.['SalesState.Inventory'],ProjectData?.SalesState?.Inventory,ProjectData?.SalesState?.UnitCount])

useEffect(()=>{
  var items = [
    {id:'DatePicker',loc:'elements',viewData:{state:'date',label:'Date'}},
    {id:'Dropzone',loc:'elements',viewData:{state:'files', publisher:true, filePrepState:filePrepState}}, //uploadFile
    {id:'InputField',loc:'elements',viewData:{state:'value',label:'Value Ammount',type:'currency'}}
  ]

  setState(p=>({...p,
   listPoFiles : dynamicComponent('Listable','elements',{state:'PoFiles',label:'+ Add PDF',items:items,required:false,defaultValue:outputRef.current?.['SalesState.PoFiles']?outputRef.current?.['SalesState.PoFiles']:ProjectData?.SalesState?.PoFiles},mainState,[(x)=>{
      if(x.PoFiles && x.PoFiles.length>0 && x.PoFiles.every(x=>x.files)){
        x.PoFiles?.forEach(x=>{
          x.files.forEach(y=>{
           if(y.size&&!filePrepRef.current[y.name]){
             setFilePrep(p=>({...p,[y.name]:y }))
             setTimeout(() => {
              setPopup(p=>({...p,
                inputField: <Box sx={editStyle.popupPreviewList}><Box sx={{flex:1}}>{stateRef.current.POFilePreview}</Box><Box sx={{flex:1}}>{stateRef.current.listPoFiles}</Box></Box>,
              })) 
             }, 5)
          }
          })
          x.files = x.files.map(({name,type})=>({name,type}))
          if(x.value) x.value = parseInt(x.value,10)
        })
        setOutput(p=>({...p,'SalesState.PoFiles':  x.PoFiles }))
      }
    }])
  }))
},[outputRef.current?.['SalesState.PoFiles'],ProjectData?.SalesState?.PoFiles])

useEffect(()=>{ 
  var items = [
    {id:'InputField',loc:'elements',viewData:{state:'label',label:'Label',type:'text'}},
    {id:'InputField',loc:'elements',viewData:{state:'PONumber',label:'PO Number',type:'text'}},
    {id:'InputField',loc:'elements',viewData:{state:'tracking',label:'Tracking #',type:'text'}}
  ]
  if(ProjectData?.SalesState?.trackPackages) {
    ProjectData?.SalesState?.trackPackages.forEach(({tracking})=>{
      trackPackage({tracking},(x)=>{ 
        setState(p=>({...p,
          deliveriesData: p.deliveriesData ? [...p.deliveriesData,x] : [x]
        })) 
      })
    })
  }
  setState(p=>({...p,
  trackPackages: dynamicComponent('Listable','elements',{state:'trackPackages',label:'+ Add Tracking',items:items,required:false,defaultValue:outputRef.current?.['SalesState.trackPackages']?outputRef.current?.['SalesState.trackPackages']:ProjectData?.SalesState?.trackPackages},mainState,[(x)=>{
      if(x.trackPackages && x.trackPackages.length>0) setOutput(p=>({...p,'SalesState.trackPackages':  x.trackPackages }))
    }])
  })) },[outputRef.current?.['SalesState.trackPackages'],ProjectData?.SalesState?.trackPackages])

useEffect(()=>{
if(stateRef.current?.deliveriesData && stateRef.current?.deliveriesData?.length>0) setState(p=>({...p, 
  deliveriesStats: <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
    <Grid item xs={12} md={12} sx={{m:'auto',mt:'1%',display:'flex',flexDirection:'column',gap:'3px',padding:'2%'}} key={'TrackShippingItem'}>
    {stateRef.current.deliveriesData.map((x,i)=>
      <Item key={'itemsTrack'+i} sx={{justifyContent:'center'}}><Suspense fallback={waveLoad()}>
        { dynamicComponent('TrackShipping','elements',{state:'teackStatusDetails',label:'label',tracking:x},mainState) }
        </Suspense></Item>
     )}
     </Grid>    
  </Grid>
}))
},[stateRef.current?.deliveriesData])

function generateRandomNumber(generatedNumbers) {
  // Generate a random 4-digit number
  let randomNum = Math.floor(Math.random() * 9000) + 1000;

  // Check if the number is already generated
  while (generatedNumbers?.includes(randomNum)) {
    randomNum = Math.floor(Math.random() * 9000) + 1000;
  }

  // Add the generated number to the array

  // Return the generated number
  return randomNum;
}

const getItemArr=(TicketStatus)=>{
  const todo = ['ProjectTitle', 'ProjectState.projManager', 'TicketNumber']
  const CreateWO = ['WorkOrder','ProjectTitle']
  const Schedule = ['ProjectState.teamLead', 'ProjectState.Calendar.startDate', 'ProjectState.Calendar.endDate', 'ProjectTitle']
  const NeedToComplete = Schedule
  const Reschedule = ['ProjectState.teamLead', 'ProjectState.Calendar.startDate', 'ProjectState.Calendar.endDate', 'ProjectTitle']
  const Quotes = ['ProjectTitle']

  var id = TicketStatus;
  return Object({
    Todo: todo,
    'Create Work Order': CreateWO,
    Schedule: Schedule,
    'Close Work Order': Schedule,
    'Follow Up': Schedule,
    'Need To Complete': NeedToComplete,
    'Re-Schedule': Reschedule,
    'Quotes': Quotes
  })[id]
  }

function getDescendantProp(obj, desc) {
  var arr = desc.split(".");
  var result = obj;
  
  for (var i = 0; i < arr.length; i++) {
      if (!result || !Object.prototype.hasOwnProperty.call(result, arr[i])) {
        return undefined; // If the property doesn't exist or is undefined, return undefined
      }
      result = result[arr[i]];
  }
  
  return result;
}

const CheckItems=(statusT)=>{
  var status;
  if(!statusT || statusT === undefined || statusT === null) status = "Todo"
  else status = statusT
  var flag = true
  if(getItemArr(status)) getItemArr(status)?.forEach(x=>{
   if (!outputRef.current?.[x] && !getDescendantProp(ProjectData,x)) {
    setState(p=>({...p,
      activateButton: false
    }))
    flag = false
   }
  })
  if(flag === true)
  setState(p=>({...p,
    activateButton: true
  }))
  return flag //for testing purposes
}

function GenerateTicketNumber(generatedNumbers){

   if(!generatedNumbers) generatedNumbers = [];
   const year = dayjs().format('YY') 

   var randomNumber1 = generateRandomNumber(generatedNumbers); // create random 4 digit number
   generatedNumbers.push(randomNumber1); // keep track of randomly generated numbers (so no duplicates created twice)
   
   if(Array.from( [...projectMap],([,{TicketNumber}])=>TicketNumber).includes(`t${year}-s1-${randomNumber1}`))
    return GenerateTicketNumber(generatedNumbers)
  
   Promise.all(
    Array.from( [...divisionMap],([,{teamName}])=> new Promise((res) => queryDB({model:'Projects',query:{TicketNumber:`t${year}-s1-${randomNumber1}`},filter:{_id:1},modelType:teamName},(items,err)=> res(items))) )
  ).then((x)=>{ 
    if([].concat(...x).length === 0) setOutput(p=>({...p,
      TicketNumber: `t${year}-s1-${randomNumber1}`, // generate ticket number
    }))
    else GenerateTicketNumber(generatedNumbers)
  })
}


function getDefaultLabel(value) {
  if(value)
  {
    return value?.slice(0)?.[0]?.Notes
  }
}

const checkIfSupport=()=>{
  return DivisionData?.teamName === "Fresno Support" || divisionMap.get(outputRef.current.DivisionID)?.teamName === "Fresno Support"
}

useEffect(()=>{
  if(!outputRef.current.TicketNumber && !ProjectData?.TicketNumber && checkIfSupport) // if creating a support ticket
  {
    GenerateTicketNumber()
  }
},[projectMap, outputRef.current.DivisionID])

useEffect(()=>{ setState(p=>({...p,
    POFilePreview: dynamicComponent('FilePreview','elements',{state:'scanEnable',label:'Additional POs',projID:projID, images: latestState('SalesState.PoFiles')?.flatMap(x=>x.files)},filePrepState)
})) },[latestState('SalesState.PoFiles')]) 

useEffect(()=>{ setState(p=>({...p,
    ProjTitleField: dynamicComponent('InputField','elements',{state:'ProjectTitle',label:'Project Title',defaultValue:latestState('ProjectTitle') ,type:'text',error:'Project Title Needed',style:{margin:0}},outputState), 
  })) },[latestState('ProjectTitle'),latestState('TicketStatus')]) 

useEffect(()=>{
  const isSupport = checkIfSupport()
  if(isSupport === true)
    CheckItems(outputRef.current.TicketStatus||ProjectData?.TicketStatus)
},[outputRef.current])

useEffect(()=>{ 
  if(!stateRef.current?.SaveIcon) setState(p=>({...p,
    SaveIcon: <SaveIcon/>,
  }))
  const isSupport = checkIfSupport()
  if(stateRef.current.activateButton && stateRef.current.activateButton === true){
    setState(p=>({...p,
      updateBttn: dynamicComponent('DefaultButton', 'elements',{stateChg:['onClick'],state:'saveButton',label:ProjectData?.SalesState ? "UPDATE" : "CREATE",type:'save',startIcon:stateRef?.current?.SaveIcon,full:true},mainState,[create])
    }))  }
  else if(stateRef.current.activateButton === false && isSupport === true){
    setState(p=>({...p,
      updateBttn: undefined
    })) }
  else if(isSupport === false)
  setState(p=>({...p,
    updateBttn: dynamicComponent('DefaultButton', 'elements',{stateChg:['onClick'],state:'saveButton',label:ProjectData?.SalesState ? "UPDATE" : "CREATE",type:'save',startIcon:<SaveIcon/>,full:true},mainState,[create])
  })) 
},[ProjectData?.SalesState,stateRef.current.activateButton, stateRef.current.SaveIcon])

useEffect(()=>{ 
  setState(p=>({...p, 
    MapField: latestState('SalesState.SiteID')
    ? dynamicComponent('geoMap','component',{loc: latestState('SalesState.SiteID')?.map(id=>teamMember.get(id)?.address?.location) ,managerView:true,offSet:{left:-35},ref: mapRef,projID},mainState) 
    :  DivisionData?.DivLoc?.location && dynamicComponent('geoMap','component',{loc: DivisionData?.DivLoc?.location,managerView:true, offSet:{left:-35},ref: mapRef,projID},mainState)
  }))},[latestState('SalesState.SiteID')])

useEffect(()=>{ setState(p=>({...p, 
    ProjDescField: dynamicComponent('InputField','elements',{state:'ProjectDesc',label: checkIfSupport ? 'Job Details/Scope of Work' : 'Job Details', defaultValue: latestState('ProjectDesc'), type:'text' ,style:{margin:0},multiline:true},outputState),
  })) },[latestState('ProjectDesc'), latestState('TicketStatus')])

useEffect(()=>{ setState(p=>({...p, 
    InvoiceField: dynamicComponent('InputField','elements',{state:'SalesState.ProjectAmmount',label:'Invoice Total',defaultValue: latestState('SalesState.ProjectAmmount'),type:'currency'},outputState)
  })) },[latestState('SalesState.ProjectAmmount')])

  useEffect(()=>{ setState(p=>({...p, 
    delScanner: dynamicComponent('DefaultButton','elements',{state:'scanDel',label:`Delete default: ${latestState('ProjectState.DefaultScan')}`,type:'button',color:'#c0392b',startIcon:<FontAwesomeIcon icon={faTrash}/>,full:true},outputState,[()=>{deleteDefaultScan()}]),
  })) },[latestState('ProjectState.DefaultScan')])

useEffect(()=>{ setState(p=>({...p, 
  setScanButt: dynamicComponent('DefaultButton','elements',{state:'scanSet',label:stateRef.current.defaultButtonLabel || 'Set Default Scan',type:'button',color:'#32a7e2',startIcon:<FontAwesomeIcon icon={faBarcode}/>,full:true},mainState,[()=>{setState(p=>({...p,defaultButtonLabel: "Scan Now!"})); setScan()}]),
})) 
},[stateRef.current.defaultButtonLabel])

  useEffect(()=>{ setState(p=>({...p, 
    UnitAmmtField: dynamicComponent('InputField','elements',{state:'SalesState.UnitCount',label:'Unit Amount',defaultValue:latestState('SalesState.UnitCount'),type:'number',style:{margin:0}},outputState)
  })) },[latestState('SalesState.UnitCount')])

  useEffect(()=>{ setState(p=>({...p, 
    scanner: dynamicComponent('scanner','component',{defaultNum:10,stateChg:['onClick'],state:'defaultScan', manualScan:true, items:['SerialNumber'], bottomLabel: latestState('ProjectState.DefaultScan') ? `Current Scan: ${latestState('ProjectState.DefaultScan')}` : 'Set Default Scan:',projID:projID},outputState,[defaultScan])
  })) },[latestState('ProjectState.DefaultScan')])

  useEffect(()=>{ 
    setState(p=>({...p, 
    PartnerField: dynamicComponent('AutoComplete','elements',{state:'SalesState.partnerID',label:'Partner',defaultValue: latestState('SalesState.partnerID') ,model:'Partners',idConvert:'$nin',query:{'_id':Array.from(teamMember).filter(([,{userType}])=>userType==="Partner").flatMap(([k])=>k)},filter:{local:1}, addEntry:addPartner},outputState)
  })) },[latestState('SalesState.partnerID')])

  useEffect(()=>{ 
    setState(p=>({...p, 
    ClientField: dynamicComponent('AutoComplete','elements',{state:'SalesState.clientID',label:'Client',defaultValue: latestState('SalesState.clientID'),model:'Clients',idConvert:'$nin',query:{'_id':Array.from(teamMember).filter(([,{userType}])=>userType==="Client").flatMap(([k])=>k)},filter:{local:1}, addEntry:()=>addPartner()},outputState)
  })) },[latestState('SalesState.clientID'), latestState('TicketStatus')])

  useEffect(()=>{ setState(p=>({...p, 
    followField: dynamicComponent('AutoComplete','elements',{state:'projectFollow',label:'Follow Project',defaultValue: latestState('projectFollow'),model:'User',idConvert:'$nin',query:{'_id':Array.from(teamMember).filter(([,{userType}])=>!userType).flatMap(([k])=>k),authorized:'true'},filter:{local:1}},outputState)
  })) },[latestState('projectFollow')]) 

  useEffect(()=>{ setState(p=>({...p, 
    JobWalkField: dynamicComponent('InputField','elements',{state:'SalesState.jobWalk',label:'Job Walk',defaultValue: latestState('SalesState.jobWalk'),style:{margin:0}},outputState)
  })) },[latestState('SalesState.jobWalk')])

  useEffect(()=>{  setState(p=>({...p, 
    JobNumberField: dynamicComponent('InputField','elements',{state:'SalesState.jobNumber',label:'Job Number',defaultValue: latestState('SalesState.jobNumber'),style:{margin:0}},outputState)
  })) },[latestState('SalesState.jobNumber')])

  useEffect(()=>{ setState(p=>({...p, 
    TradeNameField: dynamicComponent('InputField','elements',{state:'SalesState.tradeName',label:'Trade Name',defaultValue: latestState('SalesState.tradeName'),type:'text',style:{margin:0}},outputState) 
  })) },[latestState('SalesState.tradeName')])

  useEffect(()=>{ setState(p=>({...p, 
    POnumb: dynamicComponent('InputField','elements',{state:'PoNumber',label: DivisionData?.teamName.includes('A/V') ? 'Job #' : 'Job/Order #',defaultValue: latestState('PoNumber'),type:'text',style:{margin:0}},outputState),
  })) },[latestState('PoNumber'), latestState('DivisionID')])

  useEffect(()=>{ 
    setState(p=>({...p, 
    ticketNumb: dynamicComponent('InputField','elements',{state:'TicketNumber',label: latestState('TicketNumber') ? latestState('TicketNumber') : 'Generating Ticket #...',disabled:true,defaultValue: latestState('TicketNumber'),type:'text',style:{margin:0}},outputState),
  }))
  },[latestState('TicketNumber')])

  useEffect(()=>{
    setState(p=>({...p, 
      closeWO:  dynamicComponent('InputField','elements',{state:'ticketNotes.CloseWONotes',label: 'Close Work Order Notes',defaultValue: getDefaultLabel(ProjectData?.ticketNotes?.CloseWONotes), type:'text',style:{margin:0},multiline:true},outputState),
    }))
  },[latestState('TicketStatus')])

  useEffect(()=>{
    setState(p=>({...p, 
      followUp:  dynamicComponent('InputField','elements',{state:'ticketNotes.FollowUpNotes',label: 'Follow Up Notes',defaultValue: getDefaultLabel(ProjectData?.ticketNotes?.FollowUpNotes) ,type:'text',style:{margin:0},multiline:true},outputState),
    }))
  },[latestState('TicketStatus')])

  useEffect(()=>{
    setState(p=>({...p, 
      needToComplete:  dynamicComponent('InputField','elements',{state:'ticketNotes.NeedToCompleteNotes',label: 'Need To Complete Notes',defaultValue: getDefaultLabel(ProjectData?.ticketNotes?.NeedToCompleteNotes) ,type:'text',style:{margin:0},multiline:true},outputState),
    }))
  },[latestState('TicketStatus')])

  useEffect(()=>{
    setState(p=>({...p,
      switchLocation: dynamicComponent('Switcher', 'elements',{state:'SiteSwitch',label:"Write",style:{flex:'unset'}, defaultValue: latestState('SiteString') ? true : false},mainState,[()=>{
          supportBuild()
      }])
    }))
  },[latestState('SiteString')])

  useEffect(()=>{  setState(p=>({...p,
    siteStringInput: dynamicComponent('InputField','elements',{state:'SiteString',label: 'Site Name',defaultValue: latestState('SiteString'),type:'text',style:{margin:0},multiline:true},outputState),
  }))
  },[latestState('SiteString'),latestState('TicketStatus')])

  useEffect(()=>{ 
    setState(p=>({...p, 
    NotesField: dynamicComponent('InputField','elements',{state:'ticketNotes.TodoNotes',label: 'Todo Notes',defaultValue: getDefaultLabel(ProjectData?.ticketNotes?.TodoNotes), type:'text',style:{margin:0},multiline:true},outputState)
  }))
  },[latestState('ticketNotes.TodoNotes'),latestState('TicketStatus')])

  useEffect(()=>{  setState(p=>({...p, 
    WorkOrder: dynamicComponent('InputField','elements',{state:'WorkOrder',label: 'Work Order Number',defaultValue: latestState('WorkOrder'),type:'text',style:{margin:0},multiline:true},outputState),
  }))
  },[latestState('WorkOrder'),latestState('TicketStatus')])

  useEffect(()=>{  setState(p=>({...p, 
    RescheduleNotes: dynamicComponent('InputField','elements',{state:'ticketNotes.RescheduleNotes',label: 'Reschedule Notes',defaultValue: ProjectData?.ticketNotes?.RescheduleNotes?.[0]?.Notes,type:'text',style:{margin:0},multiline:true},outputState),
  }))
  },[ProjectData?.ticketNotes?.RescheduleNotes,latestState('TicketStatus')])

  useEffect(()=>{ setState(p=>({...p, 
    WOChanges: dynamicComponent('InputField','elements',{state:'ticketNotes.WOChanges',label: 'Work Order Changes',defaultValue: getDefaultLabel(ProjectData?.ticketNotes?.WOChanges),type:'text',style:{margin:0},multiline:true},outputState),
  }))
  },[latestState('ticketNotes.WOChanges'),latestState('TicketStatus')])

  useEffect(()=>{ 
    setState(p=>({...p, 
    statusDrop: dynamicComponent('DropDown','elements',{ state: 'TicketStatus', label: 'Ticket Status', defaultValue: latestState('TicketStatus')!==undefined ? latestState('TicketStatus') : 'Todo', list: ['Todo', 'Create Work Order', 'Schedule', 'Close Work Order', 'Follow Up', 'Re-Schedule', 'Quotes', 'Need To Complete'], replace:true },outputState) 
  }))
  },[latestState('TicketStatus')])

  useEffect(()=>{ setState(p=>({...p,
    GeoSuggestField: dynamicComponent('AutoComplete','elements',{state:'SalesState.SiteID',label:'Company(s) / Site(s)', defaultValue: latestState('SalesState.SiteID'),model:'Sites',idConvert:'$nin',query:{'_id':Array.from(teamMember).filter(([,{userType}])=>userType==="Sites").flatMap(([k])=>k)}, addEntry:addSites},outputState,[()=>{
      setOutput(p=>({...p, 
        SiteString: undefined
      }))
    }])
  })) },[latestState('SalesState.SiteID'),latestState('TicketStatus')])

  useEffect(()=>{ 
   var defaultDrop = `${DivisionData?.teamName ? DivisionData?.teamName : UserMap.has('division') && divisionMap.has(UserMap.get('division')) ? divisionMap.get(UserMap.get('division')).teamName : "EKC Corporate"}`
   var list = Object.assign(...Array.from([...divisionMap],([k,v])=>({[v.teamName]:k})).sort((a, b)=> (Object.keys(a)[0] < Object.keys(b)[0]) ? -1 : (Object.keys(a)[0] > Object.keys(b)[0]) ? 1 : 0))
   setState(p=>({...p, 
    DivisionDrpDwn: dynamicComponent('DropDown','elements',{ state: 'DivisionID', label: 'Select A Divison', defaultValue: defaultDrop, list: list, replace:true, disabled: DivisionLock?true:false },outputState) 
  }))
},[divisionMap.get(UserMap.get('division'))?.teamName,DivisionData?.teamName])

  useEffect(()=>{ setState(p=>({...p, 
      dateRFPField: dynamicComponent('DatePicker','elements',{state:'dateRFP',label:'RFP Due Date', defaultValue:latestState('SalesState.dateRFP') },outputState)
   })) },[latestState('SalesState.dateRFP')])

  useEffect(()=>{ setState(p=>({...p, 
     startDateField: dynamicComponent('DatePicker','elements',{state:'ProjectState.Calendar.startDate',label:'Project Start Date & Daily Start Time', defaultValue: latestState('ProjectState.Calendar.startDate') || 'Start Date Needed', min: dayjs().startOf('day'), max: latestState('SalesState.rfpDue.calendar') ? latestState('SalesState.rfpDue.calendar') : null },outputState)
  })) },[outputRef.current?.['ProjectState.Calendar.startDate'], ProjectData?.ProjectState?.Calendar?.startDate, outputRef.current?.['SalesState.rfpDue.calendar'], ProjectData?.SalesState?.rfpDue?.calendar, latestState('TicketStatus') ])

  useEffect(()=>{ 
    setState(p=>({...p, 
     endDateField: dynamicComponent('DatePicker','elements',{state:'ProjectState.Calendar.endDate',label:'Project End Date & Daily End Time', defaultValue: latestState('ProjectState.Calendar.endDate') || 'End Date Needed', min: latestState('ProjectState.Calendar.startDate') ? latestState('ProjectState.Calendar.startDate') : null, max: latestState('SalesState.projectDue.calendar') ? latestState('SalesState.projectDue.calendar') : null },outputState)
  })) },[outputRef.current?.['ProjectState.Calendar.endDate'], ProjectData?.ProjectState?.Calendar?.endDate, outputRef.current?.['ProjectState.Calendar.startDate'], ProjectData?.ProjectState?.Calendar?.startDate, outputRef.current?.['SalesState.rfpDue.calendar'], ProjectData?.SalesState?.rfpDue?.calendar, latestState('TicketStatus') ])

  useEffect(()=>{ setState(p=>({...p, 
      ProjDueField: dynamicComponent('DatePicker','elements',{state:'SalesState.projectDue',label:'Estimated Start Date (TBD)', defaultValue: latestState('SalesState.projectDue') || 'Set Ideal Due Date', min: dayjs().startOf('day')},outputState)
  })) },[latestState('SalesState.projectDue'), outputRef.current?.['ProjectState.Calendar.startDate'], ProjectData?.ProjectState?.Calendar?.startDate, outputRef.current?.['SalesState.rfpDue.calendar'], ProjectData?.SalesState?.rfpDue?.calendar ])

  useEffect(()=>{ setState(p=>({...p, 
    scanEnableField: dynamicComponent('DefaultButton','elements',{state:'scanEnable',label: latestState('ProjectState.DefaultScan') ? `Modify Default: ${latestState('ProjectState.DefaultScan')}` : 'Set Default Scan:',style:{lineHeight:1},startIcon:<FontAwesomeIcon icon={faBarcode}/>,full:true},outputState,[setScan]),
  })) },[latestState('ProjectState.DefaultScan')])

  useEffect(()=>{ setState(p=>({...p, 
   deliveriesBttn: dynamicComponent('DefaultButton','elements',{state:'deliveriesEdit',label:'View/Modify Deliveries',style:{lineHeight:1, backgroundColor: '#319e0d'},startIcon:<LocalShippingIcon/>,full:true},mainState,[openDeliveries]),
  }))
  },[latestState('Deliveries')])

  const openDeliveries = () => {
    setPopup(p=>({...p,
      open:true,
      zIndex: 1,
      inputField: dynamicComponent('DeliveriesControl','elements',{state:'deliveryOpt',label:'Deliveries',ProjectID},mainState),
      handleClose: ()=>{setPopup({open:false})}
    }))

  }

useEffect(()=>{
  var divisionCat = divisionMap.get(latestState('divisionID'))?.category ? divisionMap.get(latestState('divisionID')).category : DivisionData?.category ? DivisionData.category : UserMap.has('division') && divisionMap.has(UserMap.get('division')) ? divisionMap.get(UserMap.get('division')).category : "Corporate" 
  var divisionName = DivisionData?.teamName ? DivisionData?.teamName : UserMap.has('division') && divisionMap.has(UserMap.get('division')) ? divisionMap.get(UserMap.get('division')).teamName : "EKC Corporate"

  var newViewData = {state:'SalesState.OptionsFilter',label:'Scope of Work',color:'#ff9d15', startIcon:<FactCheckIcon/>,projID:projID,projTitle:latestState('ProjectTitle'),defaultValue:latestState('SalesState.OptionsFilter')}
 
  if( projectServices.has(`${divisionCat}Services`)){
    var items = projectServices.get(`${divisionCat}Services`)
    var itemSets = {projList:projectList(items),chapters:Array.from([...divisionMap],([k,v])=>v?.teamName),list:items,divisionName:divisionName,}
    newViewData = Object.assign({},newViewData,itemSets)
  }
  
  var SOW = dynamicComponent('ProjectBuilder','elements',newViewData,outputState)
  var Phased = <Box sx={{m:'auto'}}>{dynamicComponent('DefaultButton','elements',{state:'scanEnable',label:'Phased Scope',type:'button',startIcon:<MultipleStopIcon/>},outputState,[()=>phaseList(itemSets)])}</Box>

  if(projectServices.has(`${divisionCat}Services`)) setState(p=>({...p,
    projectBuilderField: [ latestState('SalesState.PhaseOption')? Phased : SOW,
    dynamicComponent('Switcher', 'elements',{state:'SalesState.PhaseOption',label:"Phased",style:{flex:'unset'},defaultValue:latestState('SalesState.PhaseOption')},outputState)],
  })) 

},[latestState('SalesState.OptionsFilter'),latestState('SalesState.PhaseFilter'),latestState('SalesState.PhaseOption')])


useEffect(()=>{ setState(p=>({...p,
    TeamMembers: latestState('ProjectState.Members') ? dynamicComponent('AvatarBubble','elements',{state:'teams',Catagory:'Team',label:'missing Lead', isProject: true, style:{width:'20px',height:'20px'},userID: [...new Set(latestState('ProjectState.Members').flatMap(x=> x.id))] },outputState)
  : <Typography  variant="body2" >No Members</Typography>
  })) 
},[outputRef.current?.["ProjectState.Members"]])

  const projGatherData = () =>{

    function popupData(){
      setPopup(p=>({...p,
        open:true,
        title:`${ProjectData.ProjectTitle} DataBase`,
        fullWidth: true,
        inputField: dynamicComponent('DatabaseTable','elements',{projID},mainState),
        handleClose: ()=>setPopup({open:false}),
        nameInputSets: {},
    }))
    }
    
      if(ProjectID && !Array.from([...projectLoggMap],([,v])=> v.some(()=>v.ProjectID===ProjectID)).some(x=>x)){
        console.log('Herer')
        //Warning here, modelType used before its defined
        if(!modelType){
          var {DivisionID} = projectMap.get(ProjectID)
          var{teamName:modelType} = divisionMap.get(DivisionID)
        }

        queryDB({model:'Projects',modelType,idConvert:true,query:{"_id":ProjectID},filter:{'ProjectState.teamLogg':1}},([{ProjectState}],y)=>{
          console.log('Response', ProjectState )
         if(ProjectState?.teamLogg) Promise.all(
         ProjectState?.teamLogg?.flatMap(({_id,logg})=>{
           logg.ProjectID = ProjectID
           return dynamicMapData({[_id]:logg},projectLoggMap,setProjectLoggMap,(x)=>{ setProjectLoggMap(p=>new Map(p))}) 
         })
         ).then(()=>popupData())
         else globalWrn('error',`This Project Database is empty.`)
       })
     }else popupData()
  
  }

useEffect(()=>{ 
var divisionName = stateRef.current.divisionName ? stateRef.current.divisionName : DivisionData?.teamName ? DivisionData?.teamName : UserMap.has('division') && divisionMap.has(UserMap.get('division')) ? divisionMap.get(UserMap.get('division')).teamName : "EKC Corporate"
var divisionCat = stateRef.current.division ? divisionMap.get(stateRef.current.division).category : DivisionData?.category ? DivisionData.category : UserMap.has('division') && divisionMap.has(UserMap.get('division')) ? divisionMap.get(UserMap.get('division')).category : "Corporate"  

setState(p=>({...p,
    divisionCat : divisionCat,
    divisionName : divisionName,
    EstimatorField : <Box sx={{m:'auto',flex:1}}>{dynamicComponent('UserButton','elements',{state:'SalesState.estimator',label:'Project Estimator',defaultValue:outputRef.current['SalesState.estimator']?outputRef.current['SalesState.estimator']:ProjectData?.SalesState?.estimator, divisionName:divisionName},outputState)}</Box>,
    divisionID: stateRef.current.division ? stateRef.current.division : DivisionID ? DivisionID : DivisionData?.avatar, 
    modelType: divisionMap.has(stateRef.current.division) ? divisionMap.get(stateRef.current.division).teamName : DivisionData?.teamName,
  }))

},[stateRef.current.division])
 
useEffect(()=>{ var divisionName = stateRef.current.divisionName ? stateRef.current.divisionName : DivisionData?.teamName ? DivisionData?.teamName : UserMap.has('division') && divisionMap.has(UserMap.get('division')) ? divisionMap.get(UserMap.get('division')).teamName : "EKC Corporate"
var divisionCat = divisionMap.get(latestState('DivisionID'))?.category ? divisionMap.get(latestState('DivisionID'))?.category : divisionMap.get(UserMap.get('division')).category
setState(p=>({...p,
    PMField : dynamicComponent('UserButton','elements',{state:'ProjectState.projManager',label:divisionCat==="Support" && checkIfSupport ? 'Assigned To' : 'Project Manager',defaultValue:outputRef.current['ProjectState.projManager']?outputRef.current['ProjectState.projManager']:ProjectData?.ProjectState?.projManager, divisionName:divisionName},outputState),
  })) },[latestState('ProjectState.projManager'),latestState('TicketStatus')])

useEffect(()=>{ var divisionName = stateRef.current.divisionName ? stateRef.current.divisionName : DivisionData?.teamName ? DivisionData?.teamName : UserMap.has('division') && divisionMap.has(UserMap.get('division')) ? divisionMap.get(UserMap.get('division')).teamName : "EKC Corporate"
setState(p=>({...p,
    LeadField : dynamicComponent('UserButton','elements',{state:'ProjectState.teamLead',label:'Project Lead',defaultValue:outputRef.current['ProjectState.teamLead']?outputRef.current['ProjectState.teamLead']:ProjectData?.ProjectState?.teamLead , divisionName:divisionName},outputState),
  })) },[latestState('ProjectState.teamLead'), latestState('TicketStatus')])

useEffect(()=>{
  Object({
    Corporate: corperateBuild,
    AV: constructionBuild,
    Sales: constructionBuild,
    ChromeBook: chromebookBuild,
    Construction: constructionBuild,
    Support: supportBuild,
  })[ divisionMap.get(latestState('DivisionID'))?.category ? divisionMap.get(latestState('DivisionID'))?.category : divisionMap.get(UserMap.get('division')).category ]()
},[stateRef.current, divisionMap.get(latestState('DivisionID'))?.category ])

return [<TaskManager That={mainState}/>,<PanelBuilder {...props} mainState={mainState} checkIfSupport={checkIfSupport} waveLoad={waveLoad} dynamicComponent={dynamicComponent} outputState={outputState} projID={projID} latestState={latestState} globalState={globalState} drawerState={drawerState} useIsMobile={useIsMobile} salesFieldStateRef={salesFieldStateRef} projectFieldStateRef={projectFieldStateRef} viewEditlevel={viewEditlevel} action={action} pauseProj={pauseProj}/>]
}

const PanelBuilder=({mainState,checkIfSupport,dynamicComponent,outputState,projID,waveLoad,globalState,useIsMobile,projectFieldStateRef,salesFieldStateRef,latestState,viewEditlevel,action,pauseProj})=>{
  const [, setState, stateRef] = mainState
  const [, , outputRef] = outputState
  const { ProjectID } = projID
  const [projectKeysMap] = useAtom(globalState.projectKeysState);
  const [, setGlobalAppState] = useAtom(globalState.appState);
  const [projectMap] = useAtom(globalState.projectMapState);
  const [UserMap] = useAtom(globalState.userMapState);
  const [,setPopup] = useAtom(globalState.popupState);
  const [, setPanelBuild, panelBuildRef] = useState(Object)
  var pauseC = useState(Object)

  const ProjectData = projectMap.has(ProjectID) ? projectMap.get(ProjectID) : undefined
  var paused =  ProjectData?.ProjectState?.Paused?.some(x=> !x.endDate && x.startDate) || false
  const isOdd = (n) => (n & 1) === 1

  const overdueAlert=()=>{
    var plnRec = [{id:'InputField',loc:'elements',viewData:{state:'Alert',label:'Alert (in days out)',type:'number'}}]
    var defaultSub = latestState('overdueAlert.alert') || [{"Alert": "5" },{ "Alert": "7"}, { "Alert": "10" }]
    setPopup(p=>({...p,
      open:true,
      zIndex: 2,
      title:`Set Overdue Alert`,
      description: 'By Default your Alerts are set to...',
      inputField: [
        <Box sx={{p:'2%',display:'flex',justifyContent:'flex-end'}}>{ dynamicComponent('Switcher', 'elements',{state:'overdueAlert.startDate',labelPlacement:'left',label:"Begin alerts at project start date? Otherwise this will default to project due date.",style:{width:'92%'},defaultValue:false},outputState) }</Box>,
        dynamicComponent('Listable','elements',{state:'overdueAlert',label:'Add Alert',items:plnRec,defaultValue: defaultSub },outputState)
        ],
      cancelLabel: 'Close',
      handleClose: ()=>setPopup({open:false}),
  }))
  }


  const optionSelector = ({generalOptions,eodOptions}) => {
    if(stateRef.current.generalOptions && generalOptions) var generalOpt = generalOptions.filter(x => !stateRef.current.generalOptions.includes(x))
    else generalOpt = generalOptions
    setState(p=>({...p,
      generalOptions,
      eodOptions
    }))
    if(generalOpt?.includes('PAUSE')||generalOpt?.includes('UNPAUSE')) pauseProj(paused)
    if(generalOpt?.includes('OVERDUE ALERTS') && !ProjectData?.userOpt?.some(({generalOptions})=>generalOptions?.includes('OVERDUE ALERTS')) ) overdueAlert()
  }

  const PopStyle = {
    OverviewFull : {
      position: 'absolute',
      display: 'flex',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 1000,
      backdropFilter: 'blur(15px)',
    },
    mainCard:{
      position: 'relative',
      overflow:'unset',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor:paused?'#7E7E7C':null,
      maxWidth: !action ? '60%' : 'unset',
      maxHeight: '85%',
      minWidth: '325px',
      margin: ProjectID ? 'auto 1% auto auto' : 'auto',
    },
    cardHeader: {
      position:'sticky',
      flexWrap: 'wrap',
      justifyContent: "flex-end",
      overflow: 'visible',
      zIndex: 101,
      backgroundColor:paused?'#7E7E7C':'rgb(18, 18, 18)',
      textShadow:'1px 1px #121212',
      padding:'8px',
      paddingLeft: 'clamp(40px,8%,10%)',
      '&& > *': {overflow:'hidden'}
    },
    close: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: 'unset',
      height: 'unset',
      aspectRatio: '1/1',
      zIndex: 1000,
      borderRadius: 'unset',
      background: 'var(--primary-color)',
      borderBottomRightRadius: '8px'
    },
    cellSize: useIsMobile(560) ? 12 : 6,
    }

    useEffect(()=>{
      setState(p=>({...p,
        projOptions: dynamicComponent('ToggleBttnGroups','elements',{state:'userOpt',defaultValue:latestState('userOpt'),Items:[{type:'multi',label:'GENERAL OPTIONS',subState:'generalOptions',items:[{label:paused?'UNPAUSE':'PAUSE'},{label:'OVERDUE ALERTS'},{label:'AUTO COMPLETED'}]},{type:'multi',label:'EOD OPTIONS',subState:'eodOptions',items:[{label:'COLLABERATIVE EOD'},{label:'OVERALL PERCENTAGE'},{label:'CLOCK IN/OUT'}]}]},outputState,[({userOpt})=>optionSelector(userOpt)]), 
      }))
    },[latestState('userOpt')])

    useEffect(()=>{ 
    var userLevel = UserMap.get('level');
      setPanelBuild(p=>({...p,
        proBuildOut : [ !checkIfSupport() && <Divider sx={{position:'sticky',top:'-26px',pt:'10px',pb:'5px',zIndex:999}}>
                            <Chip icon={<FontAwesomeIcon icon={faCommentsDollar}/>} label="Sales" sx={{backdropFilter:'blur(15px'}} />
                         </Divider>,
                          <Grid container rowSpacing={.5} columnSpacing={{ xs: .5, sm: .5, md: .5 }} sx={{p:'1% 2%'}}>
                          { Object.entries(salesFieldStateRef.current)
                          .filter(([k,v],i,a)=> ((latestState('ProjectTitle') && (latestState('PoNumber') || latestState('TicketNumber'))) || ['ProjectTitle','PoNumber','TicketNumber'].includes(k)) )
                          .filter(([k,v],i,a)=> projectKeysMap.has(k) && projectKeysMap.get(k)?.['viewLevel'].includes(userLevel) || false)
                          .map(([k,v],i,a)=>
                            <Grow in={true} >
                            <Grid item xs={12} 
                              md={ (a.length-1===i&&!isOdd(i) ) ? 12 : 6 } 
                              sx={ projectKeysMap.has(k) && projectKeysMap.get(k)[viewEditlevel(k,'edit')]?.includes(userLevel) ? {m:'auto'} : {pointerEvents:'none',filter:'opacity(0.5)',m:'auto'} }
                              key={'salesFieldStateRef'+i}
                              >
                              <Item sx={{'& > *':{flex:1}}}><Suspense fallback={waveLoad()}>{v}</Suspense></Item>
                            </Grid>
                            </Grow> )}
                          </Grid>,
                          <Divider/>,
                        UserMap.get('pageStamp')?.active === 'Projects' && [ 
                         Object.keys(projectFieldStateRef.current).length>0 && 
                         !checkIfSupport() && <Divider sx={{position:'sticky',zIndex:100,top:'-26px',backgroundColor:'#1e1e1e',pt:'10px',pb:'5px'}}>
                          <Chip icon={<FontAwesomeIcon icon={faProjectDiagram}/>} label="Project" />
                        </Divider>,
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} sx={{p:'1% 2%'}}>
                        { Object.entries(projectFieldStateRef.current)
                        .filter(([k,v],i,a)=> (latestState('ProjectTitle') && (latestState('PoNumber') || latestState('TicketNumber'))) || ['ProjectTitle','PoNumber','TicketNumber'].includes(k) )
                        .filter(([k,v],i,a)=> projectKeysMap.has(k) && projectKeysMap.get(k)[viewEditlevel(k,'view')].includes(userLevel) )
                        .map(([k,v],i,a)=>
                            <Grid item xs={12} md={(a.length-1===i&&!isOdd(i) )? 12 : 6 } 
                            sx={ projectKeysMap.has(k) && projectKeysMap.get(k)[viewEditlevel(k,'edit')]?.includes(userLevel) ? {m:'auto'} : {pointerEvents:'none',filter:'opacity(0.5)',m:'auto'} }
                            key={'projectFieldStateRef'+i}>
                              <Item><Suspense fallback={"loading..."}>{v}</Suspense></Item>
                            </Grid> ) }    
                          </Grid> ]
                          ]
      }))
      },[projectFieldStateRef.current,salesFieldStateRef.current,stateRef.current])

return [ <Box sx={PopStyle.OverviewFull}>
    { !action && <Box sx={{opacity:stateRef.current.markups!==true?.65:1,position:'absolute',width:'100%',height:'100%'}}>
      { stateRef.current.MapField }
    </Box> }
      { stateRef.current.markups!==true? <Card sx={PopStyle.mainCard}>
    {!action && <Fab onClick={()=>{ setGlobalAppState(p=>{ p.delete('modulePop'); setState(p=>({...p,createNewTicket: false})); return new Map(p); }) }}
      style={PopStyle.close}>
      <FontAwesomeIcon icon={faTimesCircle} className={'searchClose'} style={{color:'#fff'}}/>
    </Fab>}
    <CardHeader
      color="primary"
      sx={PopStyle.cardHeader}
      avatar={paused && dynamicComponent('DefaultButton','elements',{state:'deliveryOpt',color:'#c0392b',label:'PAUSED',startIcon:<PauseCircleOutlineIcon/>},mainState)}
      action={ <Box sx={{m:1}}>{stateRef.current.DivisionDrpDwn} {latestState('ProjectTitle') && stateRef.current.headDrp}</Box> }
      title={
      <Box sx={{overflow:'hidden',maxHeight:'56px'}}><Typography  variant="h5" sx={{textOverflow: 'ellipsis',overflow:'hidden',whiteSpace:'nowrap',color:pauseC}}>
      { latestState('ProjectTitle') ? latestState('ProjectTitle') : UserMap.get('pageStamp').active==='Sales'? 'New Opportunity': 'New Project' }
      </Typography></Box> } 
      subheader={ latestState('PoNumber') ? 
        <Typography variant="subtitle2" color="text.secondary" sx={{lineHeight:1}}>JOB #: {latestState('PoNumber')}</Typography> 
        : false }
    /> 
  <CardContent sx={{overflow:'auto',p:0}}>
  <Suspense fallback={LinearBuffer()}>
    { panelBuildRef.current.proBuildOut }
    </Suspense>
  <Box sx={{padding:'1% 3% 0',overflow:'auto'}}>
    { latestState('ProjectTitle') && latestState('PoNumber') && stateRef.current.projOptions }
    </Box>
  </CardContent>
    {(
    (Object.keys(outputRef.current).length > 0) && 
    (
        (
            (!checkIfSupport() && latestState('PoNumber')) || 
            (checkIfSupport() && latestState('ProjectTitle'))
        )
    )
) && <Box sx={{m: '1%'}}>{stateRef.current.updateBttn}</Box>}
    </Card>: [<Fab sx={{position:'absolute',right:0,backgroundColor:'#cd908b'}} onClick={()=> setState(p=>({...p,markups:false})) } >
     < ListAltIcon />
    </Fab>, 
    stateRef.current.bluePrintsField
    ]}
  </Box>,
   stateRef.current.FormDrawer ]
}

function LinearBuffer() {
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(10);
  const progressRef = useRef(() => {});

  useEffect(() =>{
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  }); 

  useEffect(() =>{
    const timer = setInterval(() => {
      progressRef.current();
    }, 500); 

    return () => { clearInterval(timer) };
  },[]); 

return <Box sx={{ width: '100%',padding: '1%', backgroundColor:'rgb(18,18,18)', borderRadius: '8px' }}>
      <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} />
      <Typography variant="subtitle1" color="text.secondary" >
        LOADING CURRENT PROJECTS
      </Typography>
    </Box>
}
