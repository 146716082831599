import React, {useEffect} from "react";
import useState from 'react-usestateref';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export default function InputField({That, viewData, action, formatPhoneNumber}){
const {label, state, defaultValue, type, pramPass, variant, required, error, placeholder,multiline, endIcon, style, disabled } = viewData
const [, setState, stateRef] = useState(Object)
const [, setOutput, outputRef] = useState(defaultValue? defaultValue: '')
const [, setParentState, parentStateRef] = That
const [values, setValues] = React.useState(Object);

  const handleChange = (prop) => (event) =>  setValues({ ...values, [prop]: event.target.value });

  const handleClickShowPassword = () => setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  
  const handleMouseDownPassword = (event) =>  event.preventDefault();

  const telNumb=(x)=>{ 
    const {valid, format} = formatPhoneNumber(outputRef.current)
    if(valid===true){ 
      setOutput(format)
      if(stateRef.current.error===true) setState(p=>({...p,...{error:false,helperText:undefined}}))
    }
    if(valid===false) setState(p=>({...p,...{error:true,helperText:'Not a valid phone number.'}}))
    }
 const validateEmail=()=>{
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(outputRef.current).toLowerCase());
  }
    
 useEffect(() =>{
  if(!stateRef.current.ready) return setState(p=>({...p,...{ready:true}}))
  if(type==='email') setOutput(String(outputRef.current).toLowerCase())
  setParentState(p=>({...p,...{[state]:outputRef.current}}))
  if(action) action.forEach((x)=>x({[state]:outputRef.current,pramPass:pramPass})) 
  if(type==='tel') telNumb()
  if(type==='email'&&!validateEmail()&&outputRef.current!=="") setState(p=>({...p,...{error:true,helperText:'Not a valid email.'}}))
  else if((type==='email'&& validateEmail() && stateRef.current.error===true)||outputRef.current==="") setState(p=>({...p,...{error:false,helperText:undefined}}))
 },[outputRef.current])

return <TextField 
  required={required?true:false}
  color={required&&"warning"}
  fullWidth
  disabled={disabled&&disabled===true?true:false}
  error={stateRef.current?.error ? stateRef.current.error : parentStateRef.current[`error-${state}`] ? true : false}
  helperText={stateRef.current?.helperText?stateRef.current.helperText:parentStateRef.current[`error-${state}`] ? error : null}
  margin={"dense"}
  type={type==='password' ? values.showPassword ? 'text' : 'password': type}
  value={outputRef.current}
  onChange={(x)=>setOutput(x.target.value)&&handleChange('password')}
  id={state}
  label={label}
  style={{minWidth:'75px',...style}}
  multiline={multiline?true:false}
  variant={variant ? variant : "outlined"}
  placeholder={placeholder}
  InputProps={{
  endAdornment : type==='password' ? <InputAdornment position="end">
                 <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment> : endIcon ? <InputAdornment position="end">
                 <IconButton
                  aria-label="toggle touchID"
                  onClick={endIcon.action}
                  onMouseDown={endIcon.action}
                  edge="end"
                >
                {endIcon.icon}
                </IconButton>
              </InputAdornment>: null,
  startAdornment : type==='currency' ? <InputAdornment position="start">$</InputAdornment> : null,  
  autoComplete: 'new-password',         
  }}
  />
}
