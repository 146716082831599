import React, {useEffect} from "react";
import useState from 'react-usestateref';
import { useAtom } from 'jotai';
import { TextField,Button,Paper, ListItem, ListItemText, Typography, IconButton, Box, Avatar} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { LazyLoadImage } from 'react-lazy-load-image-component'; 
import CircularProgress from '@mui/material/CircularProgress';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import SyncIcon from '@mui/icons-material/Sync';

export default function AutoComplete(props){
const {That, globalState, viewData, action, dynamicMapData, globalWrn, accountStrip,queryDB} = props
const {label, state, pramPass,query,model,modelType,filter,addEntry,defaultValue,items,single,idConvert,syncState,disabled} = viewData
const [teamMember,setTeamMember] = useAtom(globalState.teamMemberState);
const [projectMap, setProjectMap] = useAtom(globalState.projectMapState);
const [productMap, ] = useAtom(globalState.productMapState);
const [inventoryMap, ] = useAtom(globalState.inventoryMapState);
const [vendorMap, setVendorMap] = useAtom(globalState.vendorMapState);
const [divisionMap] = useAtom(globalState.divisionMapState);
if(syncState) var [, setSyncState] = syncState
const [, setParentState] = That
const [, setValue,{current:valueRef}] = useState([]);

  const [open, setOpen] = useState(false);
  const [options, setOptions, {current: optionsRef}] = useState([]);
  const loading = open && options.length === 0;

const handleChange=(e,v)=>{
if(single) v = v.slice(-1);
setValue([...v]);
var val = v.flatMap(x=>x.id)
setParentState(p=>({...p,...{[state]:val}}))
if(action){
  databaseQuery()
  action.forEach((x)=>x({[state]:val,pramPass:pramPass})) 
}
}

const filterOptions=(options, { inputValue }) => options.filter((x)=> Object.values(x).filter(y=>y?.constructor===String).some(z=>z.toLowerCase().includes(inputValue.toLowerCase())))

const Tabbar = ({ children, ...other }) => (
  <Paper {...other} sx={{minWidth:'200px'}}>
     <Box sx={{display:'flex'}}><Button sx={{ width:'90%',m:'5%' }} key={"1"} variant="contained" color="primary" onMouseDown={()=>addEntry(model)} startIcon={<AddCircleOutlinedIcon />}>Create</Button>
     <IconButton key={"2"} onMouseDown={()=>{
     databaseQuery(Refresh); 
      }} sx={{padding:'5%'}}><SyncIcon/></IconButton></Box>
    {children}
  </Paper>
);

const Refresh=()=>{
  globalWrn('success', `Successfully Refreshed`)
}

useEffect(() => { 
  if(defaultValue) setValue([...optionsRef?.filter(x=> x.divionID ? defaultValue.includes(x.divionID) : defaultValue.includes(x.id))])
},[optionsRef,defaultValue])

useEffect(()=>{
 if(syncState) setSyncState(p=>({...p,...{
    databaseQuery:databaseQuery
  }}))
},[])

  function update(items){ 
    setOptions(items?.flatMap(x=>{
      if(x._id) return {...x,id:x._id}
     
      var user = filter ? x[Object.keys(filter)[0]] : x
      return {...user,id:x._id}
     }));
  }

  function resolve(items){
    var keepable = ['firstName','lastName','email','avatar','workPhone','mobilePhone','description','division','DirectSupervisor','SupervisorOf','userType','level','socketID','company','title'];

    Object({
      Partners: ()=> Promise.all(
        items?.flatMap(x=> dynamicMapData({ [x._id]: accountStrip(x ,{_id:x._id,keep:keepable,model}) }, teamMember, setTeamMember) )
        ).then(()=> update(Array.from(teamMember).filter(([,{userType}])=>userType==="Partner").flatMap(([k,v])=>({Primary:`${v.firstName} ${v.lastName}`,Secondary:v.company,_id:k}))) ),
      Clients: ()=> Promise.all(
        items?.flatMap(x=> dynamicMapData({ [x._id]: accountStrip(x ,{_id:x._id,keep:keepable,model}) }, teamMember, setTeamMember) )
        ).then(()=> update(Array.from(teamMember).filter(([,{userType}])=>userType==="Client").flatMap(([k,v])=>({Primary:`${v.firstName} ${v.lastName}`,Secondary:v.company,_id:k})))),
      User: ()=> Promise.all( 
        items?.flatMap(x=> dynamicMapData({ [x._id]: accountStrip(x ,{_id:x._id,keep:keepable,model}) }, teamMember, setTeamMember) )
        ).then(()=> update(Array.from(teamMember).filter(([,{userType}])=>!userType).flatMap(([k,v])=>({Primary:`${v?.firstName} ${v?.lastName}`,Secondary: divisionMap.get(v?.division)?.teamName,_id:k}) )) ),
      Vendors: ()=> Promise.all( items?.flatMap(x=> dynamicMapData({[x._id]: x}, vendorMap, setVendorMap) )
        ).then(()=> update(Array.from(vendorMap).flatMap(([k,v])=>({Primary:v['Name'],Secondary:v.AccountNumber,_id:k}))) ),
      Sites: ()=>  Promise.all(
        items?.flatMap(x=> dynamicMapData({ [x._id]: {
        ...x,
        SupervisorOf: x.ClientID,
        avatar: x._id,
        companyID: x._id,
        company: x.type,
        department: x.address?.label,
        division: "Sites",
        email: x.email,
        firstName: x.name,
        lastName: x.description,
        title: x.POnumber,
        userType: "Sites",
        workPhone: x.phone
    } }, teamMember, setTeamMember ) )
    ).then(()=> update(Array.from(teamMember)?.filter(([,{userType}])=>userType==="Sites")?.flatMap(([k,v])=>({Primary:v.firstName,Secondary:v.department,_id:k}))) ),
    Projects: ()=> Promise.all( items?.flatMap(x=>{ 
        delete x.Logg;
        x?.ProjectState?.teamLogg?.forEach(y=> delete y.logg);
        dynamicMapData({ [x._id]: x }, projectMap, setProjectMap);
        return
      })).then(()=> update(Array.from(projectMap).flatMap(([k,v])=>({Primary:v.PoNumber,Secondary:v.ProjectTitle,_id:k}))) )
       })[model]?.();
  }

  var Maps =  Object({
    Vendors: teamMember,
    Partners: teamMember,
    Clients: teamMember,
    User: teamMember,
    Sites: teamMember,
    Projects: projectMap,
    Inventory: inventoryMap,
    Product: productMap,
    DivCheck: divisionMap
    });

  function databaseQuery(callBk){

    if(Maps[model].size>0) Object({
      Projects: ()=> update(Array.from(Maps[model]).flatMap(([k,v])=>({Primary:v.PoNumber,Secondary:v.ProjectTitle,_id:k}))),
      Partners: ()=> update(Array.from(teamMember).filter(([,{userType}])=>userType==="Partner").flatMap(([k,v])=>({Primary:`${v.firstName} ${v.lastName}`,Secondary:v.company,_id:k}))),
      Clients: ()=> update(Array.from(teamMember).filter(([,{userType}])=>userType==="Client").flatMap(([k,v])=>({Primary:`${v.firstName} ${v.lastName}`,Secondary:v.company,_id:k}))),
      User: ()=> update(Array.from(teamMember).filter(([,{userType}])=>['User',undefined].includes(userType)).flatMap(([k,v])=>({Primary:`${v?.firstName} ${v?.lastName}`,Secondary: divisionMap.get(v?.division)?.teamName,_id:k}) )),
      Vendors: ()=> update(Array.from(vendorMap).flatMap(([k,v])=>({Primary:v['Name'],Secondary:v.AccountNumber,_id:k}))),
      Sites: ()=> update(Array.from(teamMember)?.filter(([,{userType}])=>userType==="Sites")?.flatMap(([k,v])=>({Primary:v.firstName,Secondary:v.department,_id:k}))),
       })[model]?.()
    
    if(modelType && modelType.constructor===Array) Promise.all(
      modelType.flatMap(x=> new Promise((res)=> queryDB({model,query,filter,modelType:x,idConvert},(items)=>res(items)) ) )
      ).then((x)=>{  
        var items = [].concat(...x);
        if(items.length>0) resolve(items)
        else update(Array.from(Maps[model]).flatMap(([k,v])=>({Primary:v.PoNumber,Secondary:v.ProjectTitle,_id:k})))
        callBk?.()
        })
    else queryDB({model,query,filter,modelType,idConvert},(items,err)=>{
      if(items.length>0) resolve(items,err)
      else Object({
       Partners: ()=> update(Array.from(teamMember).filter(([,{userType}])=>userType==="Partner").flatMap(([k,v])=>({Primary:`${v.firstName} ${v.lastName}`,Secondary:v.company,_id:k}))),
       Clients: ()=> update(Array.from(teamMember).filter(([,{userType}])=>userType==="Client").flatMap(([k,v])=>({Primary:`${v.firstName} ${v.lastName}`,Secondary:v.company,_id:k}))),
       User: ()=> update(Array.from(teamMember).filter(([,{userType}])=>['User',undefined].includes(userType)).flatMap(([k,v])=>({Primary:`${v?.firstName} ${v?.lastName}`,Secondary: divisionMap.get(v?.division)?.teamName,_id:k}) )),
       Vendors: ()=> update(Array.from(vendorMap).flatMap(([k,v])=>({Primary:v['Name'],Secondary:v.AccountNumber,_id:k}))),
       Sites: ()=> update(Array.from(teamMember)?.filter(([,{userType}])=>userType==="Sites")?.flatMap(([k,v])=>({Primary:v.firstName,Secondary:v.department,_id:k}))),
        })[model]?.()

      callBk?.()
})
  }

  useEffect(() => {
  if(query!==undefined && model!==undefined) databaseQuery()
  else if(items) setOptions(items)
  }, [modelType]);

return <Box sx={{overflow:'auto',padding:'3% 0 0',width:'100%'}}><Autocomplete
      id={state}
      sx={{m:'auto',width:'100%'}}
      open={open}
      multiple
      onChange={handleChange}
      disabled={disabled}
      onOpen={()=>setOpen(true)}
      onClose={()=>setOpen(false)}
      getOptionLabel={({Primary,Secondary,_id}) => <ListItem alignItems="flex-start" sx={{gap:'4%',padding:0}}>
        {_id && <Avatar alt={_id} sx={{ width: 24, height: 24, margin:'auto' }}>
        <LazyLoadImage
              alt={'...loading'}
              loading='lazy'
              effect="blur"
              threshold={10000}
              height={'100%'}
              width={'100%'}
              style={{objectFit:'contain'}}
              key={_id}
              onError={(e)=>e.target.src = `https://${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}services.ekc.app/scripts/images/blank-profile.png`}
              src={`https://${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}services.ekc.app/profiles/avatar/${_id}_avatar.jpg`}
            /> 
        </Avatar>
        }
      <ListItemText sx={{ flexShrink: 0 }} primary={<Typography sx={{display: '-webkit-box',WebkitLineClamp: 2, WebkitBoxOrient: 'vertical',overflow: 'hidden', textOverflow: 'ellipsis', lineHeight:1.1,fontSize:'14px'}}>{Primary}</Typography>} secondary={<Typography sx={{display: '-webkit-box',WebkitLineClamp: 2, WebkitBoxOrient: 'vertical',overflow: 'hidden', textOverflow: 'ellipsis', lineHeight:1.1,fontSize:'11px'}}>{Secondary||'Missing'}</Typography>}/>
      </ListItem> }
      filterOptions={filterOptions}
      options={optionsRef}
      value={valueRef}
      loading={loading}
      PaperComponent={addEntry ? Tabbar : false}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          type="search"
          autoComplete={'off'}
          onFocus={()=>{setOpen(true)}}
          InputProps={{
            ...params.InputProps,
            autoComplete: "off",
            endAdornment: ( [loading && <CircularProgress color="inherit" size={20} />,params.InputProps.endAdornment] ),
          }}
        />
      )}
    />
    </Box>
}