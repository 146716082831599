import React, {useEffect} from "react";
import { useAtom } from 'jotai';

export default function Notify({globalWrn,globalState,notificationUpdate}){
    const [UserMap, ] = useAtom(globalState.userMapState)

    //end of subscription code

    useEffect(( )=>{
      var arr = UserMap.get('Notifications')
      var amount = 0
      if(arr){
        for(var i = 0; i < arr?.length; i++){
          if(arr.at(i).read === 2){
            amount = amount + 1
          }
        }
        if(amount > 3){
          handleButtonClick(0, null, arr) 
        }
        else{ 
        for(var i = 0; i < arr?.length; i++){
            if(arr.at(i).read === 2){
              handleButtonClick(arr.at(i), ()=> notificationUpdate({userID:UserMap.get('avatar'),type:'changeRead',notifID:arr.at(i)._id}))
            }
        }
      }
    };
  },[UserMap.get('Notifications')])                                                                                                                                                                                                  
  
    const handleButtonClick =(spot, callBk, wholeArr)=> {

      if (!spot && !wholeArr)
        return
      else if(wholeArr){
        for(var i = 0; i < wholeArr?.length; i++){
          notificationUpdate({userID:UserMap.get('avatar'),type:'changeRead',notifID:wholeArr.at(i)._id})
        }
        if(window.cordova && (window.cordova.platformId === "ios" || window.cordova.platformId === "android")){
          document.addEventListener("pause", onPause(Body, Icon, Title, Data), false);
        }
        else{
          globalWrn('info',"You have many new notifications")
        }
        return
      }

      else{

      var Title = spot.Title
      var Body = spot.Body
      var Data = spot.data
      var Icon = spot.Icon

      if(spot.Title === undefined) return //makes sure it does not display empty notification
  
      if(window.cordova && window.cordova.platformId === "browser"){ //running browser on cordova
        if (Title != "New Call"){
          globalWrn('info',Body)
        }
      }

      else if(window.cordova && (window.cordova.platformId === "ios" || window.cordova.platformId === "android")){ //ios or android
          document.addEventListener("pause", onPause(Body, Icon, Title, Data), false);
        }
  
      else{ //regular browser (yarn react-scripts start)
        if(Title != "New Call" && Body != undefined){
          let myStr = Title
          let firstWord = myStr.split(" ")[0]
          if(firstWord === 'Project'){
            globalWrn('info',Title)
          }
          else{
            globalWrn('info',Body)
          }
        }
      }
      callBk()
    }
    }

    function onPause(Body, Title){
      window.cordova?.plugins.notification?.local.schedule({
        title: Title,
        text: Body,
        foreground: true,
      })
    }

return null
};