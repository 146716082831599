import React, {useEffect} from "react";
import { useAtom } from 'jotai';
import useState from 'react-usestateref';
import { Box, Typography, List, ListItem, ListSubheader, ListItemIcon, ListItemText, Divider } from '@mui/material';
import { PasswordCheck } from '../views/view_SignUp'
import dayjs from 'dayjs';

import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(localizedFormat)

const PartnerField =({dynamicComponent,viewData,useIsMobile,globalWrn,globalState,dbUpdateValidate, extUserAccount, inviteReq, queryDB})=>{
    const { defaultValue } = viewData
    const mainState = useState(Object)
    const outputState = useState(Object)
    const fieldState = useState(Object)
    const [, , fieldRef] = fieldState
    const [, setState, stateRef] = useState(Object)
    const passState = useState(Object)
    const [, setPass, passRef] = passState;
    const [UserMap] = useAtom(globalState.userMapState)
    const [, setPopup] = useAtom(globalState.popupState)
    const [teamMember, setTeamMember] = useAtom(globalState.teamMemberState)
    const [divisionMap] = useAtom(globalState.divisionMapState)
    const [, setOutput, outputRef] = outputState

    const useAndMobile = useIsMobile(560)

    const submitUser = ()=>{console.log("Fire"); extUserAccount({data: outputRef.current},(x)=>setPopup({open:false})) }

    useEffect(() => {
      if(passRef.current.password && passRef.current.passwordConf && passRef.current.password === passRef.current.passwordConf && passRef.current.validPW === true ) updatePass()
      if(((passRef.current.password || passRef.current.passwordConf) && passRef.current.password !== passRef.current.passwordConf && passRef.current.validPW !== true ) && !passRef.current.helper) setPass(p=>({...p, helper: 'Both passwords must match before we can update your password.' }))
    },[passRef.current])

    const sendPass=()=>{
      dbUpdateValidate({model:'User',queryID:true,query:defaultValue.avatar,update:{['local.password']:passRef.current.password}},({success,info,err})=>{
        if(passRef.current.helper) setPass(p=>({...p, helper: undefined,password: undefined,passwordConf:undefined,validPW: undefined }))
        if(success===true) globalWrn('success',"Your Password was saved.")
        else globalWrn('error',"There was an error updating your Password:"+err)
      })
      setPopup({open:false})
    }
    
    const updatePass=()=>{  
        setPopup(p=>({...p,
          open: true,
          button03: {
          action: sendPass,
          label: 'Update Password'
        }
      }))
      }

    const membersOptions=(query)=>{ 
      queryDB({model:'User',query:query,filter:{'local.firstName':1,'local.lastName':1}},(items)=>{
          if(!items ||items.length <= 0) membersOptions({'local.level':1})
          else if(items.length <= 0 && query == ({'local.level':1}) ){
            var items = [{local:{firstName: "No Members",lastName: "Found"},_id: false}]; 
            delete outputRef.current.manager
          }else{
        var list = items.map((x)=>({[x.local.firstName+' '+x.local.lastName]: x._id}) )
        var supName =  defaultValue.DirectSupervisor && teamMember.has(defaultValue.DirectSupervisor) ? `${teamMember.get(defaultValue.DirectSupervisor)?.firstName} ${teamMember.get(defaultValue.DirectSupervisor)?.lastName}` : 'Select A Supervisor'
        setState(p=>({...p,
          managementOptions: dynamicComponent('DropDown','elements',{
            state: 'local.DirectSupervisor',
            label: 'Select A Supervisor',
            defaultValue: supName,
            list: Object.assign(...list), 
            replace:true 
            },outputState) 
        }))
    }})
}
    const MemberLevel = ()=>{
      queryDB({model:'AdminOrg',query:{'fieldSet':`positions`},filter:{'_id':0,'listing':1}},(items,err)=>
      setState(p=>({...p,
          userLevel: dynamicComponent('DropDown','elements',{
          state: 'level',
          label: `User's Position level.`,
          defaultValue: items[0].listing[defaultValue?.level],
          list: Object.fromEntries(Object.entries(items[0].listing).map(([k,v])=>[v,k])), 
          replace:true 
          },mainState,[({level})=>{
            setOutput(p=>({...p,
              'local.level': parseInt(level,10)
            }))
          }]) 
      }))
     )
}
     const DivisionDrpDwn = ()=> {
      var defaulut = divisionMap.has(defaultValue.division) ? divisionMap.get(defaultValue.division).teamName : "EKC Corporate"
      var arrayList = Array.from([...divisionMap],([k,{teamName}])=>({[teamName]:k}))
       if(arrayList.length>0) var list = Object.assign(...arrayList)

      setState(p=>({...p, 
       DivisionDrpDwn: dynamicComponent('DropDown','elements',{ state: 'local.division', label: 'Select A Divison', defaultValue: defaulut, list: list, replace:true },outputState) 
      }))
    }

    const supofDiv = ()=>{
      setState(p=>({...p, 
        supofDivField: <Box sx={{paddingBottom: '1%'}}>{dynamicComponent('AutoComplete','elements',{state:'local.SupervisorOf',label:'Supervisor Of',defaultValue: defaultValue.SupervisorOf,items: Array.from([...divisionMap],([k,v])=>({Primary:v.teamName,Secondary:v.description,divionID:k}))},outputState)}</Box>
      }))
    }

    useEffect(()=>{ 
      setState(p=>({...p, 
        userTypeField: dynamicComponent('RadioGroup','elements',{state: 'local.userType', list:['Partner','Client'],defaultValue: defaultValue?.userType },outputState),
        firstNameField: dynamicComponent('InputField','elements',{state:'local.firstName',label:'First Name',type:'text',defaultValue: defaultValue?.firstName},outputState),
        lastNameField: dynamicComponent('InputField','elements',{state:'local.lastName',label:'Last Name',type:'text', defaultValue: defaultValue?.lastName},outputState),
        titleField: dynamicComponent('InputField','elements',{state:'local.title',label:'Title',type:'text', defaultValue: defaultValue?.title},outputState),
        phoneField: dynamicComponent('InputField','elements',{state:'local.mobilePhone',label:'Mobile #',type:'text', defaultValue: defaultValue?.mobilePhone},outputState),
        workPhoneField: dynamicComponent('InputField','elements',{state:'local.workPhone',label:'Phone #',type:'text', defaultValue: defaultValue?.workPhone},outputState),
        emailField: dynamicComponent('InputField','elements',{state:'local.email',label:'Email',type:'text', defaultValue: defaultValue?.email},outputState),
        companyField: dynamicComponent('InputField','elements',{state:'local.company',label:'Company',type:'text', defaultValue: defaultValue?.company},outputState),
        addressField: dynamicComponent('GeoSuggest','elements',{state:'local.geoUser',label:'Office Address',limit:4,},outputState),
        descriptionField: dynamicComponent('InputField','elements',{state:'local.description',label:'Notes',type:'text', defaultValue: defaultValue?.description},outputState),
      }))
      if(defaultValue && (!defaultValue.userType || !["Client", "Partner", "Site"].includes(defaultValue.userType))){
         MemberLevel()
         DivisionDrpDwn()
         supofDiv()
         membersOptions({$or:[{'local.level':{$lte:defaultValue?.level},'local.division':defaultValue?.division},{'local.SupervisorOf':defaultValue?.avatar}]})
         setPopup(p=>({...p,
          title: `Modify ${defaultValue.firstName} ${defaultValue.lastName} account.`,
          description: `You can modifiy the user's content below.`,
          helper: {
            label: 'More Info',
            action: ()=>{
              queryDB({model:'User',keep:['log'],idConvert: true,query:{"_id":defaultValue.avatar},filter:{'local':1}},([{log}])=>{ 
                setPopup(p=>({...p,
                  helper: {primary:'Last Login: '+dayjs(log?.[log.length - 1]?.logged?.timestamp).format("lll"),secondary:`UUID: `+defaultValue.avatar }
                }))
                if(log) setState(p=>({...p,
                  userDetails: <List
                  sx={{ width: '100%', bgcolor: 'background.paper' }}
                  subheader={<ListSubheader>{`${defaultValue.firstName} ${defaultValue.lastName} Log:`}</ListSubheader>}
                >
                  { log.map((x,i)=>Object.entries(x).map(([k,v])=>{
                    if(v.constructor === Object)
                  return <ListItem sx={{}} key={'listItem'+i}>
                   <ListItemIcon>
                     { dynamicComponent('AvatarBubble','elements',{state:'projMembers',label:'missing Members',style:{width:'25px',height:'25px'},userID:defaultValue.avatar},mainState) }
                   </ListItemIcon>
                   {v.constructor === String 
                   ? <ListItemText id="log Item" primary={v} secondary={dayjs(v?.timestamp).format("lll")} />
                   : <ListItemText id="log Item" primary={`${teamMember.get(defaultValue?.avatar)?.firstName} ${teamMember.get(defaultValue?.avatar)?.lastName}`} secondary={dayjs(v?.timestamp).format("lll")} /> }
                 </ListItem>
                })
                  )}
                  </List>
                }))
               })
              }
          }
         }))
      }
    },[defaultValue])


    const submitChange=()=>{
      console.log("Fire")
       dbUpdateValidate({model:defaultValue.userType?defaultValue.userType:'User',queryID:true,query:defaultValue.avatar,update:outputRef.current}, ({info,success})=>{
        console.log("info:", info)
        if(success===true) {
          globalWrn('success',`Modified ${defaultValue.firstName} ${defaultValue.lastName} account.`)
          //setTeamMember({t:'set', k:info?._id, v:{...info?.local, _id: info?._id}})
          console.log(teamMember.get(info?._id))
        }
        else globalWrn('error',`There was an error updating ${defaultValue.firstName} ${defaultValue.lastName} account.`)
       })
       setPopup({open:false})
    }


    useEffect(()=>{
      if(defaultValue){
      if(outputRef.current['local.userType'] || outputRef.current['local.firstName']||outputRef.current['local.lastName']||outputRef.current['local.title']||outputRef.current['local.mobilePhone']||
        outputRef.current['local.workPhone']||outputRef.current['local.email']||outputRef.current['local.company']||outputRef.current['local.description'] ||outputRef.current['local.geoUser']||
        outputRef.current['local.SupervisorOf']||outputRef.current['local.division']||outputRef.current['local.level']||outputRef.current['local.DirectSupervisor']) setPopup(p=>({...p,

          open: true,
          handleClose: ()=>{setPopup({open:false})},
          handleSubmit: submitChange,
          submitLabel: 'Update'
      })) 
      }else{
      if(outputRef.current && ['local.userType','local.firstName','local.lastName','local.email','local.company'].every(x=> Object.entries(outputRef.current).some(([k,v])=> x===k && v.length>0) ) ){
        if(Array.from([...teamMember],([k,v])=>v.email).some(x=>x===outputRef.current['local.email'])) globalWrn('error',`This ${outputRef.current['local.userType']}'s email already exists.`)
        else setPopup(p=>({...p,
          handleSubmit:submitUser, 
          submitLabel:`Create ${outputRef.current['local.userType']} Contact`,
          button03: {label:`Add & Invite ${outputRef.current['local.userType']}`,action:()=>{
            extUserAccount(outputRef.current,({data})=>{
              inviteReq({userID:data._id,requestor:{avatar:UserMap.get('avatar'),email:UserMap.get('email'),fullName:`${UserMap.get('firstName')} ${UserMap.get('lastName')}`}},({success,y})=>{ if(success) globalWrn('success',`An invite email was sent to ${fieldRef.current.firstName} ${fieldRef.current.lastName}.`) })
                setPopup({open:false})
            })
          }}
        }))
        }
      }
      },[outputRef.current]) 

    function userBuildOut(){
      return <Box sx={{ display: 'flex', flexWrap: 'wrap',flexDirection:'column',p:'1%'}}>

      <Box style={{display:useAndMobile?'contents':'flex',flex:1,gap:'1%' }}>
      {stateRef.current.firstNameField}
      {stateRef.current.lastNameField}
      {stateRef.current.titleField}
      </Box>
      <Box style={{display:useAndMobile?'contents':'flex',flex:1,gap:'1%' }}>
      {stateRef.current.phoneField}
      {stateRef.current.workPhoneField}
      {stateRef.current.emailField}
      </Box>
      <Box style={{display:useAndMobile?'contents':'flex',flex:1}}>
      {stateRef.current.descriptionField}
      </Box>
      <Divider>Account Settings</Divider>

      <Box style={{display:useAndMobile?'contents':'flex',flex:1,gap:'1%' }}>
        <Box sx={{ display: 'flex', flexDirection:'column',p:'1%', width: '98%'}}>
        <Typography noWrap gutterBottom variant="caption">Position/Level</Typography>
        {stateRef.current.userLevel}
        </Box>
        <Box sx={{ display: 'flex', flexDirection:'column',p:'1%',flex:1}}>
        <Typography noWrap gutterBottom variant="caption">Supervisor of Divisions</Typography>
        {stateRef.current.supofDivField}
        </Box>
      </Box>

      <Box style={{display:useAndMobile?'contents':'flex',flex:1,gap:'1%' }}>
        <Box sx={{ display: 'flex', flexDirection:'column',p:'1%'}}>
        <Typography noWrap gutterBottom variant="caption">Division</Typography>
        {stateRef.current.DivisionDrpDwn}
        </Box>
        <Box sx={{ display: 'flex', flexDirection:'column',p:'1%',flex:1}}>
        <Typography noWrap gutterBottom variant="caption">Supervisor</Typography>
        {stateRef.current.managementOptions}
        </Box>
      </Box>

      <Box style={{display:'flex',flex:1,gap:'1%',p:'0 1%'}}>
      <PasswordCheck dynamicComponent={dynamicComponent} outputState={passState} That={mainState}/>
      </Box>
      <Box style={{display:'flex',marginTop:'1%',flex:1,gap:'1%',p:'0 1%'}}>
       {stateRef.current.userDetails}
      </Box>
      </Box>
      }

function standardBuild(){
return <Box sx={{ display: 'flex', flexWrap: 'wrap',flexDirection:'column',p:'1%'}}>
<Box style={{display:'flex',flex:1,justifyContent:'center'}}>
{ stateRef.current.userTypeField }
</Box>
<Box style={{display:'flex',flex:1,gap:'1%' }}>
{stateRef.current.firstNameField}
{stateRef.current.lastNameField}
{stateRef.current.titleField}
</Box>
<Box style={{display:'flex',flex:1,gap:'1%' }}>
{stateRef.current.phoneField}
{stateRef.current.workPhoneField}
{stateRef.current.emailField}
</Box>
<Box style={{display:'flex',flex:1,gap:'1%' }}>
{stateRef.current.companyField}
<li className={"locatoinField"} style={{margin:'auto',minWidth:220}}>
{stateRef.current.addressField}
</li>
</Box>
<Box style={{display:'flex',flex:1}}>
{stateRef.current.descriptionField}
</Box>
</Box>
}


return defaultValue && (!defaultValue.userType || !["Client", "Partner", "Site"].includes(defaultValue.userType)) ? userBuildOut() : standardBuild()
}

export default PartnerField