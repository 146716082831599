import React from "react";
import { useAtom } from 'jotai';
import useState from 'react-usestateref';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export default function PopMenu({viewData,action,globalState}) {
  const {label,list,userID,bttnIcon,disable} = viewData
  const [, setState] = useState(Object)
  const [anchorEl, setAnchorEl] = useState(null);

  const [teamMember] = useAtom(globalState.teamMemberState)
  const [, setOpen,{current:OpenRef}] = useState(false);

  const handleClick = ({currentTarget}) => {
    if(disable) return
    setAnchorEl(currentTarget);
    setOpen(true)
  };

  const handleClose = (x) => {
    setOpen(false)
        if(x.value){ 
          setState(p=>({...p,label: x.value }))
          action.forEach(y=> y(x.value))
  }
  };

return [<IconButton
        aria-label={"more"}
        id={"long-button"}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{padding:'unset',cursor:'context-menu',margin:'auto' }}
        key={userID+label+'q'}
      >
      { bttnIcon ? bttnIcon : <MoreVertIcon /> }
      </IconButton>,
      OpenRef && <Menu
        id={"long-menu"}
        key={"icon-"+userID}
        MenuListProps={{'aria-labelledby': 'long-button' }}
        anchorEl={anchorEl}
        open={OpenRef}
        onClose={handleClose}
      >
        {userID && teamMember.has(userID) && [<MenuItem key={userID+'name'}>
        {teamMember.get(userID)?.firstName} {teamMember.get(userID)?.lastName}
        </MenuItem>,<Divider/>] }
        {list && list?.map(({label,icon},i) => (
          <MenuItem key={label+userID+i} onClick={()=>handleClose({value:label})}>
          {icon && <ListItemIcon> <FontAwesomeIcon icon={icon}/> </ListItemIcon> }
          { label }
          </MenuItem>
        )) }
        </Menu> ]
}

