/* eslint-disable no-undef */

import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import store from './Redux/store';
import { Provider as JotaiProvider } from 'jotai';
import { SharedWorkerPolyfill as SharedWorker } from "@okikio/sharedworker";

import { SnackbarProvider } from 'notistack';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import { APIProvider } from '@vis.gl/react-google-maps';
import './app.css';
import { Sessions, jotaiStore } from "./globalState";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const darkTheme = createTheme({palette:{mode:'dark'}});
const root = ReactDOM.createRoot(document.getElementById('root'));

const sharedworker = new SharedWorker(new URL("./shared-worker.js", import.meta.url),{type: "module",'name' : 'SocketService'});
Sessions().then((sessionReq) => sharedworker.postMessage({type:'connect',...sessionReq}) );

const startApp = () => root.render( 
  <Provider store={store}>
  <APIProvider apiKey={process.env.REACT_APP_GOOGLEAPI} libraries={['marker',"places"]}>
  <JotaiProvider store={jotaiStore}>
  <ThemeProvider theme={darkTheme}>
  <SnackbarProvider maxSnack={4} preventDuplicate>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <App sharedworker={sharedworker}/>
    </LocalizationProvider>
  </SnackbarProvider>
  </ThemeProvider>
  </JotaiProvider>
  </APIProvider>
  </Provider>
);

if(!window.cordova) startApp()
else document.addEventListener("deviceready",startApp(), false)

//reportWebVitals();