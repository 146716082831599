/* eslint-disable no-unused-expressions */
import React, {Suspense,useEffect,useRef, useCallback } from "react";
import {ErrorBoundary} from 'react-error-boundary';
import useState from 'react-usestateref';
import {io} from "socket.io-client";
import dayjs from 'dayjs';
import confetti from 'canvas-confetti';
import LinearProgress from '@mui/joy/LinearProgress';
import {CssVarsProvider} from '@mui/joy/styles';
import {useSnackbar} from 'notistack';
import {Paper,LinearProgress as MuiProgress,CircularProgress,Box,Grow,Typography,Chip,ListItemText} from '@mui/material';
import autoAnimate from '@formkit/auto-animate'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MediationIcon from '@mui/icons-material/Mediation';
import WarningIcon from '@mui/icons-material/Warning';
import {LazyWithRetry} from "./Utils/lazyWithRetry";

import { useAtom } from 'jotai';

import mainconfig from './Data/Config';
import * as globalState from "./globalState";

import { SharedWorkerPolyfill as SharedWorker } from "@okikio/sharedworker";

import { Sessions } from "./globalState";

/* to be removed */
import LabelValue from './Elements/LabelValue';
import UserButton from './Elements/UserButton';
import PopupDialog from './Elements/PopupDialog';
import Notifications from './Elements/Notifications';
import SearchBar from './Elements/SearchBar';
import AvatarBubble from './Elements/AvatarBubble';
import DropDown from './Elements/DropDown';
import DefaultButton from './Elements/DefaultButton';
import InputField from './Elements/InputField';
import AutoComplete from './Elements/AutoComplete';
import PopMenu from './Elements/PopMenu';
import TransferList from './Elements/TransferList';
import DivisonFilter from './Elements/DivisonFilter';
import ListGrid from './Elements/ListGrid';
import UserSelect from './Elements/UserSelect';
import InventoryGrid from './Elements/InventoryGrid';
import ToggleBttnGroups from './Elements/ToggleBttnGroups';
import PartnerField from './Elements/PartnerField';
import FilePreview from './Elements/FilePreview';
// import DrawDiagram from './Elements/DrawDiagram';
import Switcher from './Elements/Switcher';
import DatePicker from './Elements/DatePicker';
// import FormBuilder from './Elements/FormBuilder';
import ProjectBuilder from './Elements/ProjectBuilder';
import GridSorter from './Elements/GridSorter';
import AddSites from './Elements/AddSites';
import GeoSuggest from './Elements/GeoSuggest';
import DatabaseTable from './Elements/DatabaseTable';
import PurchaseReq from './Elements/PurchaseReq';
import DynamicChip from './Elements/DynamicChip';
import ProductSelect from './Elements/ProductSelect';
import CreateProduct from './Elements/CreateProduct';
import Dropzone from './Elements/Dropzone';
import EODReport from './Elements/EODReport';
import RadioGroup from './Elements/RadioGroup';
import AddVendor from './Elements/AddVendor';
import TicketBoard from './Elements/TicketBoard';
import ItemsListed from './Elements/ItemsListed';
import Listable from './Elements/Listable';
// import PartnerPanel from './Elements/PartnerPanel';
import ProductCard from './Elements/ProductCard';
import ProgressPercent from './Elements/ProgressPercent';
import AddInventory from './Elements/AddInventory';
import FinishPop from './Elements/FinishPop';
import DeliveriesFormPopup from './Elements/DeliveriesFormPopup';
import DeliveryFormSubmit from './Elements/DeliveryFormSubmit';
import DeliveryFormReschedule from './Elements/DeliveryFormReschedule';
import Timeline_Field from './Elements/Timeline_Field';
import transferInventory from './Elements/transferInventory';
import RunInventoryAudit from './Elements/RunInventoryAudit';
import EODform from './Elements/EODform';
import ScanList from './Elements/ScanList';
import ProjStepper from './Elements/ProjStepper';
import DeliveriesControl from './Elements/DeliveriesControl';

import {Component_verticleMenu} from './components/component_verticleMenu';
import {Component_statsSelect} from './components/component_statsSelect';
import {Component_geoUser} from './components/component_geoUser';
import {Component_editProj} from './components/component_editProj';
// import Component_geoSuggest from './components/component_geoSuggest';
import {Component_panelBuild} from './components/component_panelBuild';
import {Component_geoMap} from './components/component_geoMap';
import {Component_scanner} from './components/component_scanner';
import {Component_editDivsn} from './components/component_editDivsn';
import {Component_avatar} from './components/component_avatar';
import {Component_repository} from './components/component_repository';
import {Component_fillProj} from './components/component_fillProj';

import TaskManager from './FileManager/TaskManager';

/* -- end of to be removed -- */
const marsterComps = ({
    LabelValue: LabelValue,
    UserButton: UserButton, 
    PopupDialog: PopupDialog,
    TaskManager: TaskManager,
    Notifications: Notifications,
    SearchBar: SearchBar,
    InputField: InputField,
    AvatarBubble: AvatarBubble,
    DropDown: DropDown,
    DefaultButton: DefaultButton,
    AutoComplete: AutoComplete,
    PopMenu: PopMenu,
    TransferList: TransferList,
    DivisonFilter: DivisonFilter,
    ListGrid: ListGrid,
    UserSelect: UserSelect,
    InventoryGrid: InventoryGrid,
    Listable: Listable,
    FilePreview: FilePreview,
    // DrawDiagram: DrawDiagram,
    Switcher: Switcher,
    DatePicker: DatePicker,
    // FormBuilder: FormBuilder,
    ProjectBuilder: ProjectBuilder,
    GridSorter: GridSorter,
    AddSites: AddSites,
    GeoSuggest: GeoSuggest,
    DatabaseTable: DatabaseTable,
    PurchaseReq: PurchaseReq,
    DynamicChip: DynamicChip,
    ProductSelect: ProductSelect,
    CreateProduct: CreateProduct,
    Dropzone: Dropzone,
    EODReport: EODReport,
    RadioGroup: RadioGroup,
    AddVendor: AddVendor,
    TicketBoard: TicketBoard,
    ItemsListed: ItemsListed,
    PartnerField: PartnerField,
    ToggleBttnGroups: ToggleBttnGroups,
    // PartnerPanel: PartnerPanel,
    ProductCard: ProductCard,
    ProgressPercent: ProgressPercent,
    AddInventory: AddInventory,
    FinishPop: FinishPop,
    DeliveriesFormPopup: DeliveriesFormPopup,
    DeliveryFormSubmit: DeliveryFormSubmit,
    DeliveryFormReschedule: DeliveryFormReschedule,
    Timeline_Field: Timeline_Field,
    transferInventory: transferInventory,
    RunInventoryAudit: RunInventoryAudit,
    EODform: EODform,
    ScanList: ScanList,
    ProjStepper: ProjStepper,
    DeliveriesControl: DeliveriesControl,

   // geoSuggest: Component_geoSuggest,
    verticleMenu: Component_verticleMenu,
    geoUser: Component_geoUser,
    editProj: Component_editProj,
    statsSelect: Component_statsSelect,
    panelBuild: Component_panelBuild,
    scanner: Component_scanner,
    editDivsn: Component_editDivsn,
    avatar: Component_avatar,
    repository: Component_repository,
    geoMap: Component_geoMap,
    fillProj: Component_fillProj,
});

// import relativeTime from "dayjs/plugin/relativeTime.js";
var relativeTime = require('dayjs/plugin/relativeTime');
var isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
var isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
var isToday = require('dayjs/plugin/isToday');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
dayjs.extend(isToday);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(relativeTime);

var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const AppStyle = {
    position: 'relative',
    backgroundColor: 'rgba(0,0,0,0.4)',
    display: 'flex',
    margin: 'auto',
    borderRadius: '4px',
    zIndex: 0,
    height: 'auto',
    width: 'auto',
    maxHeight: 'calc(98% - env(safe-area-inset-top) - env(safe-area-inset-bottom))',
    maxWidth: ' calc(98% - env(safe-area-inset-right) - env(safe-area-inset-left))',
    boxShadow: '-1px -1px hsl(0deg 0% 100% / 35%), 0 20px 50px rgb(0 0 0 / 50%), 1px 1px hsl(0deg 0% 100% / 25%)',
    transition: 'all 1.3s ease',
    overflow: 'hidden',
    padding: 'unset',
    WebkitBackdropFilter: 'blur(0.5rem)',
    backdropFilter: 'blur(0.5rem)',
    transitionDelay: '250ms',
    transitionProperty: 'width',
}

/*function formatBytes(a,b=2){if(!+a)return"0 Bytes";const c=0>b?0:b,d=Math.floor(Math.log(a)/Math.log(1024));return`${parseFloat((a/Math.pow(1024,d)).toFixed(c))} ${["Bytes","KiB","MiB","GiB","TiB","PiB","EiB","ZiB","YiB"][d]}`}
function mapSize(oMap){
    function replacer(key, value) {
      if(value instanceof Map) {
        return {
          dataType: 'Map',
          value: [...value],
        };
      } else return value;
    }
    return formatBytes(JSON.stringify(oMap, replacer).length);
}*/


export default({sharedworker}) => {
    const mainState = useState(Object)
    const [, setState, {current: stateRef}] = mainState
    const [, setComponent, {current:ComponentRef}] = useState(null);
    const [projGroups, setprojGroups] = useState(new Set());
    const [popupData, setPopup] = useAtom(globalState.popupState);
    const [popupData2] = useAtom(globalState.popupState2);
    const [popupData3] = useAtom(globalState.popupState3);
    const [popupData4] = useAtom(globalState.popupState4);
    const [teamFollow,setTeamFollow] = useAtom(globalState.teamFollowState);
    const [productMap, setProductMap] = useAtom(globalState.productMapState);
    const [divisionMap, setDivisionMap] = useAtom(globalState.divisionMapState);
    const [projectMap, setProjectMap] = useAtom(globalState.projectMapState);
    const [listColumnsMap, setListColumnsMap] = useAtom(globalState.listColumnsState);
    const [projectKeysMap, setProjectKeysMap] = useAtom(globalState.projectKeysState);
    const [projectServices, setProjectServicesMap] = useAtom(globalState.projectServicesState);
    const [vendorMap, setVendorMap] = useAtom(globalState.vendorMapState);
    const [projectLoggMap, setProjectLoggMap] = useAtom(globalState.projectLoggMapState);
    const [, setDivisionFilterMap] = useAtom(globalState.divisionFilterMapState);
    const [inventoryMap, setInventoryMap] = useAtom(globalState.inventoryMapState);
    const [, setProjectFilterMap] = useAtom(globalState.projectFilterMapState);
    const [teamMember, setTeamMember] = useAtom(globalState.teamMemberState);
    const [headerPopup, setHeaderPopup] = useAtom(globalState.headerPopupState);
    const [perfLables, setPerfLables] = useAtom(globalState.perfLablesMap);
    const [, setActiveSession] = useAtom(globalState.activeSessionState);
    //const [appSocket, setAppSocket] = useAtom(globalState.appSocketState);
    //const [appSocketQuery, setAppSocketQuery] = useAtom(globalState.appSocketQueryState);
    const [UserMap, setUserMap] = useAtom(globalState.userMapState);
    const [appState, setAppState] = useAtom(globalState.appState);
    const [searchState] = useAtom(globalState.searchState);
    const [menuItems] = useAtom(globalState.menuItemState);
    const popArray = useState([]);

    const {enqueueSnackbar: Snackbar, closeSnackbar} = useSnackbar();
    const App = useRef(null);
    const {Searcher} = globalState;
    //var socketIO = appSocket.get('socketIO');
    //var socketQueryIO = appSocket.get('socketQueryIO');
    //var socketQueryIO = appSocketQuery.get('socketQueryIO');

    // useEffect(()=> console.log('UserMap UPdated:' ,UserMap ),[UserMap])
    // useEffect(()=> console.log('projectMap UPdated:' ,projectMap ),[projectMap])
    // useEffect(()=> console.log('divisionMap UPdated:' ,divisionMap),[divisionMap])
    // useEffect(()=> console.log('teamMember UPdated:' ,teamMember),[teamMember])
    // useEffect(()=> console.log('appState UPdated:' ,appState),[appState])
    // useEffect(()=> console.log('searchState UPdated:' ,searchState),[searchState])
    // useEffect(()=> console.log('projectServices UPdated:' ,projectServices),[projectServices])
    // useEffect(()=> console.log('appSocketValidationIO UPdated:' ,appSocketValidationIO),[appSocketValidationIO])
    // useEffect(()=> console.log('appSocketQuery UPdated:' ,appSocketQuery),[appSocketQuery])
    // useEffect(()=>console.log("VendorMap:", vendorMap),[vendorMap])
    // useEffect(()=>console.log("inventoryMap:", inventoryMap),[inventoryMap])

    const appbutter = (x)=> autoAnimate(x, (el, action, oldCoords, newCoords) => {
        let keyframes
        if (action === 'add') keyframes = [
            { opacity: 0, transform: 'scale(0) rotateY(20deg)' },
            { transform: 'scale(1.1)' },
            { transform: 'scale(0.9)' },
            { opacity: 1, transform: 'scale(1.05)' },
            { transform: 'scale(0.95)' },
            { transform: 'scale(1.02)' },
            { transform: 'scale(0.98) rotateY(0)' },
            { opacity: 1, transform: 'scale(1)' }
          ]

        if (action === 'remove') keyframes = [
            { transform: 'scale(1)', opacity: 1 },
            { transform: 'scale(1.15)', opacity: 1, offset: 0.33 },
            { transform: 'scale(0.75)', opacity: 0.1, offset: 0.5 },
            { transform: 'scale(0.5)', opacity: 0 }
          ]

       /* if (action === 'remain') {
          const deltaX = oldCoords.left - newCoords.left
          const deltaY = oldCoords.top - newCoords.top
          const [widthFrom, widthTo, heightFrom, heightTo] = getTransitionSizes(el, oldCoords, newCoords)
          const start = { transform: `translate(${deltaX}px, ${deltaY}px)` }
          const mid = { transform: `translate(${deltaX * -0.15}px, ${deltaY * -0.15}px)`, offset: 0.75 }
          const end = { transform: `translate(0, 0)` }
          if (widthFrom !== widthTo) {
            start.width = `${widthFrom}px`
            mid.width = `${widthFrom >= widthTo ? widthTo / 1.05 : widthTo * 1.05}px`
            end.width = `${widthTo}px`
          }
          if (heightFrom !== heightTo) {
            start.height = `${heightFrom}px`
            mid.height = `${heightFrom >= heightTo ? heightTo / 1.05 : heightTo * 1.05}px`
            end.height = `${heightTo}px`
          }
          keyframes = [start, mid, end]
        } */
        return new KeyframeEffect(el, keyframes, { duration: 800, easing: 'cubic-bezier(0, 0, 0.58, 1)' })
      }); 
    
    var setItems = (x) => {
       Object({
            teamMember: setAppState(p => { p.set('teamMemberSet',true); return new Map(p)}),
            projectLoggMap: setAppState(p => { p.set('projectLoggSet',true); return new Map(p)}),
            projectMap: setAppState(p => { p.set('projectSet',true); return new Map(p)}),
            divisionMap: setAppState(p => { p.set('divisionsSet',true); return new Map(p)}),
        })[x]
    };

   /* useEffect(()=>{
        const spectrumworkerQueryDB = new SharedWorker(new URL("./Workers/shared-worker-Spectrum.js", import.meta.url),{'name' : 'SocketService'});
        spectrumworkerQueryDB.postMessage({type:'spectrumAPI',data:{location:"vendors/EKC",params:"",Type:"vendors",Filters:{}}})
        spectrumworkerQueryDB.onmessage = (x)=> {

            spectrumworkerQueryDB.terminate()
            }
    },[])*/

    const accountStrip=(account,{_id,model,keep,remove})=>{
        var userData = Object.assign(...Object.entries(account.local||account).filter(([k,v])=> v !== undefined).map(([k,v])=>({[k]:v})))
        userData.avatar = _id;
        if(keep) Object.entries(userData).map(([key,value]) => !keep.includes(key) ? delete userData[key] : '') 
        if(remove) remove.forEach(x=>delete userData[x])
        if(!userData._id) userData._id = _id
        if(!userData.userType) userData.userType = model
        return userData
      };

      //const formatBytes=(a,b=2)=>{if(!+a)return"0 Bytes";const c=0>b?0:b,d=Math.floor(Math.log(a)/Math.log(1024));return`${parseFloat((a/Math.pow(1024,d)).toFixed(c))} ${["Bytes","KiB","MiB","GiB","TiB","PiB","EiB","ZiB","YiB"][d]}`}

      //if('storage' in navigator && 'estimate' in navigator.storage) navigator.storage.estimate().then((estimate)=> console.log(`Using ${formatBytes(estimate.usage)} out of ${formatBytes(estimate.quota)} bytes.`)); 

    const queryDB = ({model,modelType,idConvert,query,filter,hydrate},callBK) => {
        const sharedworkerQueryDB = new SharedWorker(new URL("./Workers/shared-worker-queryDB.js", import.meta.url),{'name' : 'SharedWorkerQueryDB'});
        //return
        if(!model) return console.log('missing Model');
       // console.log("model:", model, query, callBK)
       //console.log("What is first id:", id)
       //const callBK_ID = useId();
       const callBK_ID = dayjs().toISOString() + Math.random();
       //return console.log("What is:", callBK_ID);
        //if(["Inventory", "Products"].includes(model)) return;

       sharedworkerQueryDB.postMessage({type:'queryDB',data:{model,modelType,idConvert,query,filter,hydrate:hydrate||false,callBK_ID:callBK_ID}})
       sharedworkerQueryDB.onmessage = ({data})=> {
           // console.log("Query DB Response:", x?.data, query, model)

            if(data?.callBK_ID === callBK_ID) callBK?.(data?.data);

            sharedworkerQueryDB.terminate()

            //console.log("Data1 is:", query, data, callBK)
            //if(callBK) callBK(data);
          //  console.log("querydb resp:", x, query)
        }

        /* queryDB({model,modelType,idConvert,query,filter},(items)=>{
            if (hydrate === true) { }
            if(callBK) callBK(items)
        }); */
    }

    const amIupdated=(data,callBK)=>{
        sharedworker.postMessage({type:'newNotification',data})

        sharedworker.onmessage = ({data})=>{
            if(callBK) callBK({...data})
        };
    }
    
    // Get the value from the global map or the database
    const checkIDPromise = ({_id, key, map, hydrate, setMap, teamName}) => {
        return new Promise((resolve, reject) => {
            // Map has the value
            if (map.has(_id)) {
                if((map.get(_id)?.timestamp || map.get(_id)?.updatedAt)) amIupdated({model:key,idConvert:false,modelType:teamName,query:{"documentID":_id},currTimestamp:(map.get(_id)?.timestamp || map.get(_id)?.updatedAt)}, (resp)=>{
                    if(resp !== true) resolve(resp);
                    else resolve(map.get(_id));
                })
                else {
                    queryDB({ query: { "_id": _id }, idConvert: true, model: key, modelType:teamName, hydrate}, (item) => {
                        if (item?.length > 0) {
                           resolve(item);
                        }
                        else {console.log("Could not find ID in Database!"); reject(new Error('_id information not found'))};
                    });
                }
            }
            // Map does not have value, get from database
            else if(_id) queryDB({ query: { "_id": _id }, idConvert: true, model: key, modelType:teamName, hydrate}, (item) => {
                     if (item?.length > 0) {
                        resolve(item);
                     }
                     else {console.log("Could not find ID in Database! _id, model, and query:", _id, key, {"_id": _id}); reject(new Error('_id information not found'))};
                 });
            else reject(new Error("_id is not defined!"))
        });
    }
      // CheckID function
      const CheckID = ({_id,key,map,setMap,hydrate,teamName}, callBK) => {
        if(key === "Projects" && !teamName) return console.log("Error: need teamName for Projects Query")
        checkIDPromise({_id,key,map,setMap,hydrate,teamName}).then((resp)=> callBK&&callBK(resp?.[0]) ).catch((err)=>console.log("Error while checking _id:", err));
      }

      // Image Compress Function
      const imageCompress = (data,callBK) => {
        sharedworker.postMessage({type:'imageCompress',data})

        sharedworker.onmessage = ({data})=>{
            if(callBK) callBK({...data})
        };
      }

      // Excel Create
      const ExcelCreate = (data, callBK) => {
        sharedworker.postMessage({type:'ExcelCreate',data})

        sharedworker.onmessage = ({data})=>{
            if(callBK) callBK({...data})
        };
      }

      // Delete Vendor
      const delVendor = (data, callBK) => {
        sharedworker.postMessage({type:'delVendor',data})

        sharedworker.onmessage = ({data})=>{
            if(callBK) callBK({...data})
        };
      }

      // Project Follow
      const projFollow = (data) => {
        sharedworker.postMessage({type:'projFollow',data})
      }

      // Updated Account
      const updatedAccnt = (data, callBK) => {
        sharedworker.postMessage({type:'updatedAccnt',data})

        sharedworker.onmessage = ({data})=>{
            if(callBK) callBK({...data})
        };
      }

      // Inventory Focus
      const inventoryFocus = (data, callBK) => {
        sharedworker.postMessage({type:'inventoryFocus',data})
      }

      // User Subscription
      const toggleUserSubscription = (data) => {
        sharedworker.postMessage({type:'toggleUserSubscription',data})
      }

      // Inventory Delete
      const inventoryDelete = (data, callBK) => {
        sharedworker.postMessage({type:'inventoryDelete',data})

        sharedworker.onmessage = ({data})=>{
            if(callBK) callBK({...data})
        };
      }

      // Inventory Audit
      const auditInventory = (data, callBK) => {
        sharedworker.postMessage({type:'auditInventory',data})

        sharedworker.onmessage = ({data})=>{
            if(callBK) callBK({...data})
        };
      }

      // 360
      const devices360 = (data) => {
        sharedworker.postMessage({type:'devices360',data})
      }

      // User Delete
      const userDelete = (data, callBK) => {
        sharedworker.postMessage({type:'userDelete',data})

        sharedworker.onmessage = ({data})=>{
            if(callBK) callBK({...data})
        };
      }

      // extUserAccount
      const extUserAccount = (data, callBK) => {
        sharedworker.postMessage({type:'extUserAccount',data})

        sharedworker.onmessage = ({data})=>{
            console.log("Data is:", data)
            if(callBK) callBK({...data})
        };
      }

      // Invite Req
      const inviteReq = (data, callBK) => {
        sharedworker.postMessage({type:'inviteReq',data})

        sharedworker.onmessage = ({data})=>{
            if(callBK) callBK({...data})
        };
      }

      // Project EOD
      const projectEOD = (data, callBK) => {
        sharedworker.postMessage({type:'projectEOD',data})

        sharedworker.onmessage = ({data})=>{
            if(callBK) callBK({...data})
        };
      }

      // Update fulfillment
      const updateFulfillment = (data, callBK) => {
        sharedworker.postMessage({type:'updateFulfillment',data})

        sharedworker.onmessage = ({data})=>{
            if(callBK) callBK({...data})
        };
      }

      // Team Member Follow
      const teamMemberFollow = (data, callBK) => {
        sharedworker.postMessage({type:'teamMemberFollow',data})

        sharedworker.onmessage = ({data})=>{
            if(callBK) callBK({...data})
        };
      }

      // Project Delete
      const projectDelete = (data, callBK) => {
        sharedworker.postMessage({type:'projectDelete',data})
    
        sharedworker.onmessage = ({data})=>{
            if(callBK) callBK({...data})
        };
      }

      // Notification Update
      const notificationUpdate = (data) => {
        sharedworker.postMessage({type:'notificationUpdate',data})
      }

       // Product Delete
       const productDelete = (data, callBK) => {
        sharedworker.postMessage({type:'productDelete',data})
    
        sharedworker.onmessage = ({data})=>{
            if(callBK) callBK({...data})
        };
       }

      // Cell Compress
      const CellFocusFunc = (data, callBK) => {
        sharedworker.postMessage({type:'CellFocusFunc', data:{}});

        sharedworker.onmessage = ({data})=>{
            if(callBK) callBK({...data})
        };
      }

      //Emailer function
      const newNotification = ({receiver,sender,location,read,timestamp,Title,Body,secondaryBody,Inventory,Product,QTY,folderName,Email,name,Choice,EmailForm,avatar,senderAvatar,name_u,myEmail,link,downloadLink,response,cc},callBK) => {
        sharedworker.postMessage({type:'newNotification', data: {receiver,sender,location,read,timestamp,Title,Body,secondaryBody,Inventory,Product,QTY,folderName,Email,name,Choice,EmailForm,avatar,senderAvatar,name_u,myEmail,link,downloadLink,response,cc}})

        sharedworker.onmessage = ({data})=>{
            if(callBK) callBK({...data})
        };
      }

      // Project Builder
      const projectBuilder = ({ projID, submitObj}, callBK) => {
        sharedworker.postMessage({type:'projectBuilder', data: { projID: projID, submitObj: submitObj}})

        sharedworker.onmessage = ({data})=>{
            if(callBK) callBK({...data})
        };
      }

      // Company Builder
      const companyBuilder = (data, callBK) => {
        sharedworker.postMessage({type:'companyBuilder', data})

        sharedworker.onmessage = ({data})=>{
            if(callBK) callBK({...data})
        };
      }

      // Active Rooms
      const activeRooms = (data, callBK) => {
        sharedworker.postMessage({type:'activeRooms', data})

        sharedworker.onmessage = ({data})=>{
            if(callBK) callBK({...data})
        };
      }

      // Message
      const messageChat = (data, callBK) => {
        sharedworker.postMessage({type:'messageChat', data})

        sharedworker.onmessage = ({data})=>{
            if(callBK) callBK({...data})
        };
      }

      // Create 360 Client
      const createClient360 = (data, callBK) => {
        sharedworker.postMessage({type:'createClient360', data})

        sharedworker.onmessage = ({data})=>{
            if(callBK) callBK({...data})
        };
      }

      // Email Code
      const emailCode = (data, callBK) => {
        sharedworker.postMessage({type:'emailCode', data})

        sharedworker.onmessage = ({data})=>{
            if(callBK) callBK({...data})
        };
      }

      // Verify Email Code
      const emailCodeVerify = (data, callBK) => {
        sharedworker.postMessage({type:'emailCodeVerify', data})

        sharedworker.onmessage = ({data})=>{
            if(callBK) callBK({...data})
        };
      }

      // Track Package
      const trackPackage = (data, callBK) => {
        sharedworker.postMessage({type:'trackPackage', data})

        sharedworker.onmessage = ({data})=>{
            if(callBK) callBK({...data})
        };
      }


      const doesIDExist= useCallback(({model,_id,modelType,projectLogg,idConvert},callBK)=>{ console.log("DO NOT USE THIS ANYMORE! Model and modelType:", model, modelType); return })

    useEffect(() =>{
        if(UserMap.has('notification')){
             globalWrn('info',UserMap.get('notification'))
        }
     },[UserMap.get('notification')]);


    useEffect(()=>{
        globalState.userStore.getItem('scanQue').then(x=>{ 
            if(x && x.length>0) window.addEventListener('beforeunload', alertUser, {once: true})
            else window.removeEventListener('beforeunload', alertUser, true)
        })
    },[appState.get('quedItems')])    


    const alertUser = e => {
        e.preventDefault();
        e.stopPropagation();
        e.returnValue = 'Test'
      }

    useEffect(() => {
        setState(p => ({...p,
                mainPopup: popupData.open &&  dynamicComponent('PopupDialog', 'elements', {...popupData}, mainState)
            }))
    },[popupData])

    useEffect(() => {
        setState(p => ({...p,
                secondPopup: popupData2.open && dynamicComponent('PopupDialog', 'elements', {...popupData2}, mainState)
            }))
    }, [popupData2])

    useEffect(() => {
        setState(p => ({...p,
                thirdPopup: popupData3.open && dynamicComponent('PopupDialog', 'elements', {...popupData3}, mainState)
            }))
    }, [popupData3])

    useEffect(() => {
        setState(p => ({...p,
                fourthPopup: popupData4.open && dynamicComponent('PopupDialog', 'elements', {...popupData4}, mainState)
            }))
    }, [popupData4])

    useEffect(() => {  
        if (!stateRef.geoUser && !stateRef.signage)
            setState(p => ({...p,
                geoUser: dynamicComponent('geoUser', 'component', {},)
            }))
         else if (stateRef.geoUser && stateRef.signage) 
            setState(p => ({...p,
                    geoUser: []
                }))
    }, [ stateRef ]);

    const dynamicMapData = useCallback((data, dataPoint, dataPointSet, key, callBK) => {
        if (data) Promise.all(Object.entries(data).flatMap(async([k, v]) => dataPointSet({t:'set',k,v}) )).then((p) => {
                    if(callBK) callBK?.({valid: true, res: p})
                    
                })            
            }, []);

    // Local Storage Function
     const storeLocally = async(key, data) => {
        try{
            var newObj = {}
            if(globalState.storage.getAllKeys().length > 0 && globalState.storage.getString(key) && Object.keys(JSON.parse(globalState.storage.getString(key))).length > 0) newObj = {...JSON.parse(globalState.storage.getString(key)), ...data}
            else newObj = {...data}
            // This line does storage
           // if(!globalState.storage.getString(key)) globalState.storage.set(key, JSON.stringify(newObj));
        } catch (error) {
            console.log("Encountered an error when storing locally:", error)
        }
    }

        const noficationSubmit=(ProjectData,outputRef)=>{
            const viewEditlevel = (x,type)=>{
                var userDiv = UserMap.get('division')
                var cat = divisionMap.get(userDiv)?.category
                var def = type+'Level'
                return  x?.[userDiv+'_'+def] ? userDiv+'_'+def : x?.[cat+'_'+def] ? cat+'_'+def : def
              }

            var ommit = ['DivisionID','projIDtemp']
            var checkPt = ['SalesState.clientID','SalesState.partnerID','ProjectState.projManager','SalesState.estimator','ProjectState.teamLead','CreatedBy']
            var origItems = checkPt.flatMap((x)=>simp(ProjectData,x)).filter(x=>x!==undefined)
            var memItems = simp(ProjectData,'ProjectState?.Members')?.map(x=> x.id)?.filter(x=>x!==undefined) || []
            var newItems = checkPt.flatMap((x)=>outputRef.current?.[x]).filter(x=>x!==undefined)
          
            var recivers = [...new Set([...origItems,...newItems,...memItems])]
            queryDB({model:'AdminOrg',query:{'fieldSet':'ProjectKeys'},filter:{'_id':0,'fieldSet':0}},([projKeys],err)=>{
            if(!outputRef.current) return
            var Body = Object.entries(outputRef.current)
                      .filter(([k,v])=>!ommit.includes(k))
                      .map(([k,v])=>{
                  if(k.includes(".")) var ProjType = k?.split(/[.]+/)?.pop()?.match(/[A-Z][a-z]+|[0-9]+/g)?.join(" ") || k?.split(/[.]+/)?.pop()
                  else ProjType = k?.match(/[A-Z][a-z]+|[0-9]+/g)?.join(" ") || k
                  
                  if(dayjs(v).isValid() && v instanceof Date) var v = dayjs(v).format('MMM Do YY - h:mm a') 
                  if(teamMember.has(v)) v = `${teamMember.get(v).firstName} ${teamMember.get(v).lastName}`
          
                  var info = `\n\t\u2022 ${projKeys[k]?.Label ? projKeys[k].Label : ProjType} has been updated ${v instanceof Object || v instanceof Array ? ".": "to: "+v}`
                  return {key:k,info:info}
              })
          
              recivers.forEach((y)=>{
              var newBody = Object.entries(Body).filter(([k,{key,info,info1}])=> projKeys[key]?.[viewEditlevel(projKeys[key],'view')] && projKeys[key][viewEditlevel(projKeys[key],'view')].includes(teamMember.get(y)?.level)).map(([k,{info}])=>info).join('')
              var header = `${UserMap.get('firstName')} ${UserMap.get('lastName')} updated Project "${ ProjectData.ProjectTitle }" with the following changes:\n\n ${newBody}`
              if(newBody){
                var phoneNum = teamMember.get(y)?.mobilePhone?.replace(/[^0-9]/g,'')?.slice(-10)
                var email = teamMember.get(y)?.email
                var name_u = (teamMember.get(y)?.firstName + ' ' + teamMember.get(y)?.lastName)
                newNotification({Title:`Project "${ ProjectData?.ProjectTitle}" has been updated.`,Body:header,Data:null,Avatar:null,Icon:null,Choice:{AppNotification:true,Text:false,Email:true},mobile:phoneNum,Email:email,EmailForm:"Basic",name:name_u,sender:UserMap.get('avatar'),receiver:y})
              } 
              })
            })
            }


    useEffect(() => {
        if (UserMap.get('pageHold') && stateRef.currentPageID) setAppState(p => { p.set('pageHold', true); return new Map(p)}) 
        if ((UserMap.get('pageStamp') ?. pageID && appState.get('pageHold') !== true )|| ['Notify'].includes(UserMap.get('pageStamp')?.pageID) ) updateCurrentViewState({state: 'currentPageID', id: UserMap.get('pageStamp').pageID, type: 'page'})
        if (UserMap.get('pageHold') && !stateRef.currentPageID ) setAppState(p => { p.set('pageHold', true); return new Map(p)}) 
    }, [UserMap.get('pageStamp') ?. pageID,UserMap.get('pageHold')]);

    const mobileBrowser = () => {
            let hasTouchScreen = false;
            if ("maxTouchPoints" in navigator) 
                hasTouchScreen = navigator.maxTouchPoints > 0;
             else if ("msMaxTouchPoints" in navigator) 
                hasTouchScreen = navigator.msMaxTouchPoints > 0;
             else {
                const mQ = matchMedia ?. ("(pointer:coarse)");
                if (mQ ?. media === "(pointer:coarse)") 
                    hasTouchScreen = !! mQ.matches;
                 else if ("orientation" in window) 
                    hasTouchScreen = true;
                 else {
                    const UA = navigator.userAgent;
                    hasTouchScreen = /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
                }
            }
            return hasTouchScreen
        };

        const divisionFilterChange = (data) => Object.entries(Object.assign({}, ...data)).map(([k, v]) => setDivisionFilterMap({t:'set',k,v}));
           const userCount = (id) =>{
            try{
            return Array.from(projectMap.entries(), ([k, v]) => [{[k]:v}].filter(() => v !== false).flatMap((items, i) => ({
                todayLng: Object.entries(v).filter(
                    ([k1, {ProjectState}]) => ProjectState ?. teamLogg && ProjectState ?. teamLogg.some(x => x.date == dayjs().format('DD/MM/YYYY')) ? projectLoggMap.get(ProjectState ?. teamLogg ?. find(x => x.date == dayjs().format('DD/MM/YYYY'))._id) ?. some(x => x.user == id) : false
                ).flatMap(
                        ([k1, {ProjectState}]) => projectLoggMap.get(ProjectState ?. teamLogg ?. find(x => x.date == dayjs().format('DD/MM/YYYY'))._id).filter(x => x.user == id).length
                    ),
                    grdTotal: Object.entries(v).filter(
                        ([k1, {ProjectState}]) => ProjectState ?. teamLogg
                    ).flatMap(
                        ([k1, {ProjectState}]) => ProjectState ?. teamLogg ?. flatMap(x => projectLoggMap.has(x ?. _id) && projectLoggMap.get(x ?. _id).some(x => x.user == id) ? projectLoggMap.get(x ?. _id) ?. filter(x => x.user == id).length : 0)
                    )
                })))  
            } catch (error) {
                return 0
            }
        };

        function isIterable(input) {
            if (input === null || input === undefined)  return false
            return typeof input[Symbol.iterator] === 'function'
        }


    const filterProjSet = useCallback((k, v, val) => {
        let submitObj = {}

        if (v ?. ProjectState ?. Calendar ?. startDate !== undefined && v ?. ProjectState ?. Calendar ?. endDate !== undefined && !['Active', 'Scheduled', 'Completed', 'Complete'].includes(v ?. ProjectState ?. Status)) 
            submitObj['ProjectState.Status'] = 'Scheduled'

        

        if (v ?. ProjectState ?. Calendar ?. startDate !== undefined && v ?. ProjectState ?. Calendar ?. endDate !== undefined && dayjs().isSameOrBefore(v ?. ProjectState ?. Calendar ?. endDate, 'date') && dayjs().isSameOrAfter(v ?. ProjectState ?. Calendar ?. startDate, 'date') && !['Active', 'Complete'].includes(v ?. ProjectState ?. Status)) 
            submitObj['ProjectState.Status'] = 'Active'

            

        if (Object.keys(submitObj).length !== 0) {
            if (divisionMap.has(v.DivisionID)) {
                var modelType = divisionMap.get(v.DivisionID) ?. teamName
                var projID = {
                    DivisionID: v.DivisionID,
                    ProjectID: v._id,
                    modelType: modelType
                }

                projectBuilder({ projID: projID, submitObj: submitObj}, ({success, info}) => {
                    if (success) console.log('Updated', info)
                     else console.log('Error:', info, '||', divisionMap)
                })
            }
        }
    },[divisionMap]);

    const filterProj = useCallback((latestMap, val, type, limited) => { 
        var filtered = [].concat(...Array.from(latestMap.entries(), ([k, v]) => [{
                [k]: v
            }].flatMap(() => {

            if (divisionMap.has(v?.DivisionID)) filterProjSet(k, v, val)

            if (limited && v.ProjectState ?. Paused ?. some(x => !x.endDate && x.startDate)) return {[k]: false}
            
            if ('EOS' === UserMap.get('pageStamp') ?. active && v[`ProjectState`] ?. Status === val) return {[k]: true}
            
            if (val === 'All') {
                if (['Projects'].includes(UserMap.get('pageStamp')?.active) && ['Upcoming', 'Scheduled', 'Active'].includes(v[`ProjectState`]?.Status) ) return {[k]: true}
                if (['Sales'].includes(UserMap.get('pageStamp')?.active) && ['Awardable', 'Estimation','Lost','Awarded'].includes(v[`SalesState`]?.Status) ) return {[k]: true}
            }
            if (v.Status === val || v ?. [`${type}State`] ?. Status === val) return {[k]: true}
            
            return {[k]: false}
        })))

        if (filtered.length > 0) 
            return Object.assign({}, ... filtered)
        
        return filtered
    }, [UserMap]);

const updateCurrentViewState = ({id, type, state}) => {
        if (stateRef[state] !== id) {
            setState(p => ({...p,
                    currentPageID: id||'SignIn',
                    pageCounter: p.pageCounter+1||0
                }))
            setComponent(dynamicComponent(id||'SignIn', type, null, mainState))
        }
    }

    useEffect(() => {
        if (stateRef.quedItems > 0) 
            globalState.userStore.getItem('validateQue').then(x => {
                console.log('update que', x)
                if (x) 
                    Promise.all(x.flatMap(y => dbUpdateValidate(y, y.callBK))).then(() => {
                        globalState.userStore.removeItem('validateQue')
                        setState(p => ({...p,
                                quedItems: 0
                        }))
                        globalWrn('success', `Process Completed`)
                        console.log("Finished")
                    })
                
            })
        
    }, [ stateRef.quedItems ]);

    useEffect(()=>{ 
        if(UserMap.has('level')&&![9,10].includes(UserMap.get('level'))) sharedworker.postMessage({type:'userMenu'})
        },[UserMap.get('level')]); 

    const dbUpdateValidate = ({query, update, model, modelType, queryID, deleteDoc, validate, idConvert, arrayFilters, DivisionID, idCreate }, callBk) => {
            globalState.Sessions().then(({sessionID}) => {

                if ([false, null].includes(sessionID)) { 
                    if (!appState.get('pageHold')){   globalWrn('info', "Your session has Expired, Please Sign in again.")
                }
                };

                const sharedworkerDBValid = new SharedWorker(new URL("./Workers/shared-worker-DBValid.js", import.meta.url),{'name' : 'SharedWorkerDBValid'});

                sharedworkerDBValid.postMessage({type:'dbUpdateValidate',data:{ model, modelType, sessionID, division: DivisionID, query, queryID, idConvert, idCreate, validate, deleteDoc, arrayFilters, update}});
                sharedworkerDBValid.onmessage = ({data})=> {
                    callBk?.(data);
                    sharedworkerDBValid.terminate();
                }
            })
        }

        const simp = (t, path) => path.split(".").reduce((r, k) => r ?. [k], t)

        const latestState = (x, outputRef, ProjectData)=> outputRef.current[x] || simp(ProjectData, x)

        const checker = (a, r) => (r?.every(v => (a?.includes(v))))

            const percentage = (a, b) => ((a - b) / b) * 100

                const globalWrn = (x, y, z) => {
                        var options = { variant: x, anchorOrigin: { vertical: 'bottom', horizontal: 'right'}}
                        if (z) options = { ... options, ...z }
                        const action = snackbarId => <Chip label="DISMISS" onClick={() => closeSnackbar(snackbarId) } onDelete={() => closeSnackbar(snackbarId)}/>
                        Snackbar(y, {action,... options});
                    }

                    const repositoryFetch = () => {
                            if (UserMap.size > 0 && !appState.has('repositoryID') ) {
                                queryDB({ model: 'AdminOrg', query: {'fieldSet': "repositoryID" }, filter: {'_id': 0,'fieldSet': 0 }}, (items, err) => 
                                    Promise.all(Object.entries(items[0]).map(([k, v]) => setAppState(p => {
                                        p.set('repositoryID', true)
                                        p.set(k, v)
                                        return new Map(p)
                                    }))).then(() => setAppState(p => new Map(p)))
                                )
                            }
                        }

                        const percentChange = (partialValue, totalValue) => (100 * partialValue) / totalValue | 0
                        
                        const congrats = (x) => {
                                const {top, left, width, height} = x.getBoundingClientRect()
                                var { innerWidth : width2, innerHeight : height2 } = window
                                confetti({  particleCount: 150, spread: 60, origin: { x: percentChange( (left + (width / 2)), width2 ) * .01, y: percentChange( (top + (height / 2)), height2 ) * .01 } })
                            }

                            const membersList = (stateChg, query, ref, That, action) => {
                                    const [ThatState, setThat] = That;
                                    queryDB({ model: 'User', query: query, filter: { 'local.firstName': 1, 'local.lastName': 1 }}, async (items, filter) => {
                                        if (!items || items.length <= 0)  membersList(stateChg, { 'local.level': 1 }, ref, That, action)
                                         else if (items.length <= 0 && query == ({'local.level': 1})) {
                                            var items = [{ local: { firstName: "No Members", lastName: "Found" }, _id: false }];
                                            delete ThatState.manager
                                        } else {
                                            var list = items.map((x) => ({[x.local.firstName + ' ' + x.local.lastName]: x ?. _id})),
                                                mamagmentData = {
                                                    stateChg: stateChg,
                                                    state: 'manager',
                                                    preItem: 'Manager',
                                                    listItems: Object.assign({}, ... list),
                                                    replace: true,
                                                    mainRef: ref
                                                };
                                            setThat(p => ({ ...p,...{ 
                                                managementOptions: dynamicComponent('dropDown', 'component', mamagmentData, That, action) 
                                            }}))
                                        }
                                    })
                                }

                                const memberTempQuery = (callBk) => {
                                        var currtMember = [...teamMember.keys()]
                                        queryDB({ model: 'User', query: { "_id": {  $nin: currtMember } } }, (x) => {
                                            if (x) callBk(x)
                                             else callBk(false)
                                        })
                                    }

                                    const memberQuery = (id, callBk) => {
                                            if (teamMember.has(id))  callBk(teamMember.get(id))
                                             else queryDB({
                                                    model: 'User',
                                                    query: { "_id": id }
                                                }, ([{ local}]) => {
                                                    if (local) { 
                                                        dynamicMapData({ [id]: local }, teamMember, setTeamMember, "teamMember")
                                                        callBk(local)
                                                    } else callBk(false)
                                                })
                                        }

                                        const handlePopupClose = () => setPopup({open: false})

                                        const dbRequest = useCallback((model, query, filter) => new Promise(resolve => queryDB({ model: model, query: query, filter: filter }, (items, filter) =>
                                             items == null ? resolve({false: '... '}) : resolve(Object.assign({}, ...items.map((x) => Object.assign(...Object.keys(filter).map((filters) => Object.entries(x[filters]).map(([key, value]) => ({[value]: key})))))[0])))),[])

                                        const dynamicComponent = (id, type, viewData, That, action) => { 
                                                if (id === undefined)  return
                                                if (That) var [, setThat] = That

                                                if(marsterComps[id]) var Component = marsterComps[id]
                                                else{ 
                                                function errored(err){           console.error('Errored: ',err)
                                                    setHeaderPopup(p => new Set([ <Box className={'geoInform'} sx={{display:'flex',flexDirection:'column',height:'100%',padding:'1%'}}> 
                                                    <Typography variant={"body1"} sx={{textAlign:'center'}}>This Section is currently in Maintenance Mode!</Typography> <Typography variant={"subtitle1"} sx={{textAlign:'center',flex:1}} >Please check back later!</Typography>
                                                    <Box sx={{padding:'5%'}}> { dynamicComponent('DefaultButton', 'elements',{state:'reDirButton',label:'GoTo Discover',type:'save',startIcon:<MediationIcon/>,full:true},mainState,[()=>{
                                                            stateRef.updateCurrentModuleState({state:'moduleID',id: 'menu_1', type:'module'})
                                                    }]) } </Box>
                                                    <Typography variant={"caption"}  sx={{textAlign:'center'}} >Error: {type} {id}</Typography> </Box> ])) 
                                                }

                                                var Component = LazyWithRetry(() => Object({
                                                                page: ()=> import('./views/view_'+id).then(module=>({default:module['View_' + id]})).catch(errored),
                                                                module: ()=> import('./modules/module_'+id).then(module=>({default:module['Module_'+id]})).catch(errored),
                                                                elements: ()=> import ('./Elements/'+id).catch(errored),
                                                                component: ()=> import ('./components/component_'+id).then(module=>({default:module['Component_'+id]})).catch(errored),
                                                            })?.[type]?.() );
                                                };

                                                if(!Component) return null

                                                return <Suspense fallback={ waveLoad(id, type) }>
                                                    <Component 
                                                        That={That}
                                                        action={action}
                                                        viewData={viewData}
                                                        {...generalItems}
                                                        updateUser={({state, value}) => { if(state !== 'pramPass') setUserMap(p => {
                                                                        p.set('pageStamp',{...UserMap.get('pageStamp'),[state]: value});
                                                                        return new Map(p)
                                                                    }) }}
                                                        updateApp={x => setState(p => ({...p, user: {...p.user,...{[x.state]: x.value}} }))
                                                        }
                                                        updateLocal={({state, value}) => setThat(p => ({...p,[state]: value}))}
                                                    />
                                                </Suspense>
                                            };

                                            const gatherGroups = (data) => data?.type();

                                            const formatPhoneNumber = (phoneNumberString) => {
                                                var stripped = ('' + phoneNumberString).replace(/^(\+\d*)/, '').replace(/[^0-9,ex,x,ext]+/g, '');
                                                var phone = new RegExp(/^((\+(\d{1,3}))|1)? ?\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})( ?(ext\.? ?|ex\.? ?|,\.? ?|x\.? ?|x )(\d{1,4}))?$/);
                                                phoneNumberString = phoneNumberString.trim();
                                                var results = phone.exec(phoneNumberString);
                                                if (results !== null && results.length > 8) {
                                                    var intlCode = (results[3] ? `+${
                                                        results[3]
                                                    }` : '+1');
                                                    var formatted = intlCode + " (" + results[4] + ") " + results[5] + "-" + results[6] + (typeof results[9] !== "undefined" ? " ex " + results[9] : "");
                                                    return {
                                                        valid: true,
                                                        format: formatted,
                                                        strip: intlCode + results[4] + results[5] + results[6] + (typeof results[9] !== "undefined" ? " ex " + results[9] : "")
                                                    }
                                                }
                                                return {valid: false, strip: stripped}
                                            };

                                            const generalItems = {
                                                LabelValue,
                                                mainconfig,
                                                TaskManager,
                                                memberQuery,
                                                memberTempQuery,
                                                globalState,
                                                projGroups,
                                                dynamicMapData,
                                                noficationSubmit,
                                                globalWrn,
                                                closeSnackbar,
                                                membersList,
                                                dbRequest,
                                                latestState,
                                                simp,
                                                userCount,
                                                mobileBrowser,
                                                dynamicComponent,
                                                dbUpdateValidate,
                                                gatherGroups,
                                                formatPhoneNumber,
                                                projectBuilder,
                                                companyBuilder,
                                                formatter,
                                                filterProj,
                                                divisionFilterChange,
                                                isIterable,
                                                percentage,
                                                congrats,
                                                checker,
                                                waveLoad,
                                                AppRef: App.current,
                                                doesIDExist: doesIDExist,
                                                auditInventory,
                                                queryDB:queryDB,
                                                CheckID:CheckID,
                                                imageCompress,
                                                CellFocusFunc,
                                                updateFulfillment,
                                                ExcelCreate,
                                                inventoryFocus,
                                                inventoryDelete,
                                                productDelete,
                                                extUserAccount,
                                                projectDelete,
                                                projectEOD,
                                                trackPackage,
                                                inviteReq,
                                                messageChat,
                                                activeRooms,
                                                createClient360,
                                                devices360,
                                                delVendor,
                                                userDelete,
                                                projFollow,
                                                emailCode,
                                                emailCodeVerify,
                                                updatedAccnt,
                                                teamMemberFollow,
                                                notificationUpdate,
                                                toggleUserSubscription,
                                                newNotification: newNotification,
                                                repositoryFetch: repositoryFetch,
                                                useIsMobile: globalState.useIsMobile,
                                                appState: mainState,
                                                handlePopupClose,
                                                accountStrip,
                                                sharedworker,
                                                repositoryFetch,
                                            }

                                            const fallbackRender = ({ error, resetErrorBoundary })=> [stateRef.canvas,<Box><CircularProgress sx={{position:'absolute',top:'50%',left:'50%'}}></CircularProgress></Box>]

                                            const onReset = (details) =>{ setAppState(p => { p.delete('modulePop'); return new Map(p)})
                                                setState(p=>({...p, errorLog: undefined, errorTitle: undefined }))
                                                globalWrn('info', `rebuilding module... sending logs`)
                                            };

                                            useEffect(()=>{
                                                setState(p=>({...p, splashPageMenu: dynamicComponent('Splash', 'module', {}, mainState)}))
                                            },[])

                                            return <ErrorBoundary  fallbackRender={fallbackRender} onReset={onReset}>
                                                { [ // <Searcher sharedworker={sharedworker}/>,
                                                // dynamicComponent('Splash', 'module', {}, mainState),
                                                stateRef.current?.splashPageMenu,
                                                //stateRef.connIcons,
                                                <Grow in={!stateRef.errored} appear={false} timeout={5500}>
                                                    <Paper elevation={8} ref={App} style={AppStyle}>
                                                        { ComponentRef }
                                                    </Paper>
                                                </Grow>,
                                                stateRef.errored,
                                                stateRef.geoUser,
                                                stateRef.fourthPopup,
                                                stateRef.thirdPopup,
                                                stateRef.secondPopup,
                                                stateRef.mainPopup,
                                                //['658b331f16579770c91f09c9','60d3986b19714034d574daa5','6255b3801ba3aa988a8cd213'].includes(UserMap.get('avatar')) && stateRef.chats,
                                                ]}
                                                </ErrorBoundary>
                                    }
                                    

                                    const waveLoad = (id, type) => type === 'socketIO' 
                                        ? <CssVarsProvider><LinearProgress sx={{maxHeight: '2px'}}/></CssVarsProvider>
                                        : id && id ?. toLowerCase() ?. includes('avatar') ? <CircularProgress/>: <MuiProgress/>;