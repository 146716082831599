import React, { useEffect } from "react";
import { Box, Avatar, Card, CardContent, CardActions, Typography, Grid, AppBar, Tab, Tabs, Paper, ToggleButton } from '@mui/material';
import { useAtom } from 'jotai';
import useState from 'react-usestateref';
import { useTheme } from '@mui/material/styles';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import TransformIcon from '@mui/icons-material/Transform';
import SwipeableViews from 'react-swipeable-views';
import { styled } from '@mui/material/styles';
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from '@mui/material/ToggleButtonGroup';

const categories = {
  Audio: {
    label: "Audio",
  },
  Cameras: {
    label: "Cameras",
  },
  Cases_Bags: {
    label: "Cases & Bags",
  },
  Computers_Software: {
    label: "Computers & Software",
  },
  Conferencing: {
    label: "Conferencing",
  },
  Content_Management: {
    label: "Content Management",
  },
  Control: {
    label: "Control",
  },
  Displays: {
    label: "Displays",
  },
  Furniture: {
    label: "Furniture",
  },
  Lighting_Studio: {
    label: "Lighting & Studio",
  },
  Mounts_Rigging: {
    label: "Mounts & Rigging",
  },
  Networking_Cabling: {
    label: "Networking & Cabling",
  },
  Power: {
    label: "Power",
  },
  Presentation: {
    label: "Presentation",
  },
  Production: {
    label: "Production",
  },
  Security_Saftey: {
    label: "Security & Safety",
  },
  Signal_Managment: {
    label: "Signal Management",
  }
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: -1,
      borderLeft: '1px solid transparent',
    },
}));

 const ProductCard=({That,globalState,useIsMobile,dynamicComponent,CheckID,mobileBrowser,viewData:{ProductID,InventoryID,productExists,fallBackProd,fallBackInv}}) => {
  const [productMap] = useAtom(globalState.productMapState)
  const [inventoryMap] = useAtom(globalState.inventoryMapState)
  const [UserMap] = useAtom(globalState.userMapState)
  const [, setPopup] = useAtom(globalState.popupState)
  const [, setPopup2] = useAtom(globalState.popupState2)
  const mainState = useState(Object)
  const [, setState, stateRef] = mainState
  const [, setParentState] = That

  const [vendorMap,setVendorMap] = useAtom(globalState.vendorMapState)

  const useAndMobile = useIsMobile(560)

  if(ProductID && (productMap.has(ProductID) || fallBackProd)) var {IMAGE_SPOTLIGHT, DISPLAY_NAME,LINK,NOTES,PART_NUMBER,CHECK_OUT,MODEL_NUMBER,SHORT_DESCRIPTION,MANUFACTURER,MSRP,SN} = (productMap.get(ProductID)||fallBackProd);
  if(InventoryID && (inventoryMap.has(InventoryID) || fallBackInv)) var {URL, PreferredVendor, DeliverToSite} = (inventoryMap.get(InventoryID)||fallBackInv);
  useEffect(()=>{
    if(PreferredVendor && Array.isArray(PreferredVendor) ) PreferredVendor?.flatMap((p)=>
      CheckID({_id: p, key: "Vendors",map: vendorMap, setMap: setVendorMap}, (ven) => {
        var vendorLabel = ven?.Name || `Vendor Missing Name`
        setState(p=>({...p,...{vendors: p?.vendors ? [...p.vendors,vendorLabel] : [vendorLabel] }}))
        })
      )
  },[PreferredVendor,vendorMap]);

  const editProduct=(ProductID)=>{
    const labels = Object.values(categories).map(({label}) => label);
    setPopup2(p=>({...p,...{
      title: `Edit Product`,
      zIndex: 3,
      open:true,
      inputField:<Box>{dynamicComponent('CreateProduct','elements',{state:'inventoryScripts', defaultValue:productMap.get(ProductID)||fallBackProd, categoryLabel:labels })}</Box>, //send default value (inventory item)
      button03: undefined,
      helper: undefined,
      handleClose: ()=> setPopup2({open:false}),
    }}))
  };
  
  const editInventoryItem=(InventoryID)=>{
    setPopup(p=>({...p,...{
      title: `Edit Inventory Item`,
      zIndex: 3,
      open:true,
      inputField:<Box>{dynamicComponent('AddInventory','elements',{state:'inventoryScripts', defaultValue:inventoryMap.get(InventoryID)||fallBackInv })}</Box>, //send default value (inventory item)
      button03: undefined,
      helper: undefined,
      handleClose: ()=> setPopup({open:false}),
    }}))
  }

 const textNotation = (label,value)=> <Paper elevation={0} sx={{ display: 'flex', border: (theme) => `1px solid ${theme.palette.divider}`,flexWrap: 'wrap', }} >
  <StyledToggleButtonGroup size="small" value={"left"} exclusive aria-label="Manufacturer" >
    <ToggleButton value="left" aria-label="left aligned" color={'primary'}>
      <Typography variant="button">{label}:</Typography>
      </ToggleButton>
      <ToggleButton value="center" aria-label="centered">
    <Typography gutterBottom variant="body2" style={{margin:'auto',maxWidth:'350px',lineHeight:1}}> {value || `Need to set ${label}`} </Typography>
    </ToggleButton>
    </StyledToggleButtonGroup>
    </Paper>

useEffect(()=>{
 var card = <Card sx={{ maxWidth: "100%" }}>
              
              <Avatar variant="rounded" sx={{ maxHeight: '100%', width:'100%', height:useAndMobile?'30vh':'50vh'}} >
        <LazyLoadImage
        alt={'...loading'}
        loading='lazy'
        effect="blur"
        threshold={10000}
        height={'100%'}
        width={'100%'}
        style={{objectFit:'cover',filter:'blur(20px)',scale:1.1}}
        key={'MainLogo'}
        onError={(e)=>e.target.src = 'https://www.services.ekc.app/scripts/images/AV.jpeg'}
        src={IMAGE_SPOTLIGHT||'https://www.services.ekc.app/scripts/images/AV.jpeg'}
        />
        <Box sx={{position:'absolute', height: '100%'}}>
        <LazyLoadImage
        alt={'...loading'}
        loading='lazy'
        effect="blur"
        threshold={10000}
        height={'100%'}
        width={'100%'}
        style={{objectFit:'contain'}}
        key={'MainLogo'}
        onError={(e)=>e.target.src = 'https://www.services.ekc.app/scripts/images/AV.jpeg'}
        src={IMAGE_SPOTLIGHT||'https://www.services.ekc.app/scripts/images/AV.jpeg'}
        />
        </Box>
    </Avatar>

              <CardContent sx={{display:'flex',flexDirection:'column',gap:'5px',pb:'.75%',pt:'.75%'}}>
              <Paper elevation={0} sx={{ display: 'flex', border: (theme) => `1px solid ${theme.palette.divider}`,flexWrap: 'wrap' }} >
                <ToggleButton value="left" aria-label="left aligned" color={'primary'} sx={{width:'100%'}}>
                <Typography gutterBottom variant="body1" align="center" sx={{margin:'unset',lineHeight:1,overflow:'hidden',textOverflow: 'ellipsis',display:'-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical'}}>{DISPLAY_NAME}</Typography>
                </ToggleButton>
              </Paper>
                {inventoryMap.get(InventoryID)?.Notes && <Typography align="center" variant="subtitle2" color="text.secondary" sx={{lineHeight:1}}>
                {inventoryMap.get(InventoryID)?.Notes}
                </Typography>}
          
              </CardContent>
                <CardActions>
                  <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
                    <Tabbed dynamicComponent={dynamicComponent} useIsMobile={useIsMobile} tabs={[
                      {label:'Specs',type:'tab',field:[<Typography variant="body2" sx={{fontWeight: 400, color:'#ffffff',lineHeight:1}}><Grid>
                        <Grid item xs={2} sx={{display:'flex',gap:'1%',rowGap:'3px',padding:'.5%',flexWrap: 'wrap'}}>
                        { textNotation('Product',DISPLAY_NAME || `Need to set Display Name` ) }
                        { textNotation('Description', SHORT_DESCRIPTION ) }
                        { textNotation('Manufacturer', MANUFACTURER ) }
                        { textNotation('MSRP', MSRP ? '$'+MSRP : `Need to set MSRP` ) }
                        { textNotation('Model Number', MODEL_NUMBER ) }
                        { textNotation('Part Number/UPC', PART_NUMBER ) }
                        { textNotation('Barcode', SN ) }
                        { textNotation('Notes', NOTES ) }
                        { textNotation('Link', LINK ) }
                        { textNotation('Checked Out', CHECK_OUT === true ? `Item can be checked out` : `Item cannot be checked out` ) }
                        </Grid>
                        </Grid></Typography>]},
                        {label:'BOM Information',type:'tab',field:[<Grid>
                             <Grid item xs={2} sx={{display:'flex',gap:'1%',rowGap:'3px',padding:'.5%',flexWrap: 'wrap'}}>
                           { textNotation('Preffered Vendor', stateRef.current.vendors?.join(", ") ) }
                           { textNotation('URL to Item', URL ) }
                           { textNotation('Site Delivery', DeliverToSite === true ? `Yes` : `No` ) }
                        </Grid>
                        </Grid>], disabled:PreferredVendor?false:true},
                      {label:'Features',type:'tab',field:[<Typography align="center" variant="body2" sx={{fontWeight: 400, color:'#ffffff',lineHeight:1}}>Features Coming Soon!</Typography>]},
                      {label:'Requirements',type:'tab',field:[<Typography align="center" variant="body2" sx={{fontWeight: 400, color:'#ffffff',lineHeight:1}}>Requirements Coming Soon!</Typography>]},
                      ]}/>
                  </Box>
                </CardActions>
            </Card>

  var button3, helper
  if(UserMap.get('level') <= 3) {
    button3 = {
      label: 'Edit Inventory Item',
        action: ()=>{
          editInventoryItem(InventoryID);
          setParentState(p=>({...p,...{
            productCard : undefined
          }}));},
    }
    helper = {
      label: productExists ? 'Edit Product' : 'This Product Came from an old Catalog, please create a new product item.',
      action: ()=>{
        productExists && editProduct(ProductID);
        setParentState(p=>({...p,...{
          productCard : undefined
        }}))
      },
    }
  }
  else { button3 = {}; helper = {} }

   setPopup(p=>({...p,...{
    open:true,
    zIndex: 2,
    inputField: card,
    handleClose: ()=>{ 
      setPopup({open:false})
      setParentState(p=>({...p,...{
        productCard : undefined
      }}))
    }, 
    button03: button3,
    helper: helper,
    }}))

  },[ProductID,mobileBrowser()])
  return null
}  

const Tabbed = ({tabs,defPos,dynamicComponent,useIsMobile}) =>{
  const {direction} = useTheme();
  const [, setValue,valueRef] = useState(Number);
  const mainState = useState(Object)

  const useAndMobile = useIsMobile(560)

  useEffect(()=>{
    if(defPos) setValue(defPos)
  },[defPos,valueRef.current])

  const handleChange = (event, newValue) => setValue(newValue)
  const handleChangeIndex = (index) => setValue(index);

  return [<AppBar position="static">
   <Tabs
  value={valueRef.current}
  onChange={handleChange}
  indicatorColor="secondary"
  textColor="inherit"
  variant="fullWidth"
  aria-label="full width tabs example"
  >
  {tabs && tabs.flatMap(({label,type,action,disabled},i)=>{ 
    if(type==='tab' && disabled !== true) return <Tab key={'label'+label+i} style={{fontSize: useAndMobile ? '10px' : '16px'}} label={label} {...a11yProps(i)} />
    if(type==='button') return [<Box sx={{p:'.5%'}}>{dynamicComponent('DefaultButton','elements',{state:'tabAction',label:label,type:'button',startIcon:<TransformIcon/>},mainState,[action])}</Box>]
 } )}
</Tabs>
</AppBar>,
<SwipeableViews
axis={direction === 'rtl' ? 'x-reverse' : 'x'}
index={valueRef.current}
onChangeIndex={handleChangeIndex}
>
{ tabs && tabs.flatMap(({label,field},i)=>
  <TabPanelMUI key={'Field'+label+i}  value={valueRef.current} index={i} dir={direction}>{field || <Typography variant="body2" sx={{fontWeight: 400, color:'#ffffff',lineHeight:1}}>Missing</Typography> }</TabPanelMUI>
  ) }
</SwipeableViews>]
}

function a11yProps(i) {
  return {
    id: `full-width-tab-${i}`,
    'aria-controls': `full-width-tabpanel-${i}`,
  };
}

function TabPanelMUI({ children, value, index, ...other }) {

  return <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: '.35%' }}>
          {children}
        </Box>}
    </Box>
}

export default ProductCard
