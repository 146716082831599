import React, { useEffect, useRef } from "react";
import { useAtom } from 'jotai';
import useState from 'react-usestateref';
import * as globalState from "../globalState";
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import FileViewer from "react-file-viewer";
import { 
        setSelectedFiles, 
        inverseSelectedFiles,
        selectAllFiles,
        unsetSelectedFiles,
        copyToBufferFiles,
        cutToBufferFiles,
        pasteFiles,
        setSelectedFolder,
        getFilesList,
        getFoldersList,
        setHistoryIndex,
        renameFiles,
        createNewFile,
        createNewFolder,
        emptydir,
        deleteItems,
        dublicateItem,
        archive,
        unzip,
        saveimage,
        listViewChange,
        clearBufferFiles
        } from '../Redux/actions';
import { Paper, Grid, Box, Collapse, Button } from '@mui/material';
//import { makeStyles } from '@material-ui/core/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useClasses from "./styleConvert"
import FolderBar from './FolderBar';
import TopBar from './TopBar';
import ContainerBar from './ContainerBar';
import QrGenerator from '../Elements/QrGenerator';
import config from './Elements/config.json';
import mainconfig from '../Data/Config';
import {convertDate, formatBytes} from '../Utils/Utils';
import ImageEditor from './Elements/ImageEditor';
//import PerfectScrollbar from 'react-perfect-scrollbar';
//import './Assets/PerfectScroll.css';
import { faCopy, faCut, faPaste, faTrash, faFolderMinus, faSpellCheck, faPlusSquare, faFolderPlus, faForward, faBackward, faArrowCircleLeft, faCheckDouble, faSyncAlt, faListAlt, faObjectGroup, faFileMedical, faClone, faFileSignature, faBorderStyle, faFileArchive, faBoxOpen, faFileUpload, faFileDownload, faSave, faSearch, faBinoculars, faInfoCircle, faUserShield, faTh,faThList, faExpandArrowsAlt, faCompressArrowsAlt, faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import { faListAlt as farListAlt } from '@fortawesome/free-regular-svg-icons';
import { faAdversal } from '@fortawesome/free-brands-svg-icons'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

const darkTheme = createTheme({ 
    palette: { mode: 'dark' },
   });

//const useStyles = makeStyles(theme => ( console.warn('theme.spacing(2)',theme.spacing(2),'theme.palette.text.secondary',theme.palette.text.secondary) & {
const styles = theme => ({
    root: {
      flexGrow: 1
    },
    folderSide: {
        flexGrow: 1,
        background: '#2e2e2e',//'#f9fafc',
        borderRight: '1px solid rgba(255,255,255,.35)'
    },
    paper: {
      padding: 16,//theme.spacing(2),
      textAlign: 'center',
      color: 'rgba(0, 0, 0, 0.54)',//theme.palette.text.secondary,
    },
    fmMinimized:{

    },
    fmExpanded:{
        position: "fixed",
        top:'0',
        left:'0',
        height:'100%',
        width:'100%',
        zIndex:'999',
        padding:'20px',
        background: 'rgba(255, 255, 255, 0.7)'
    },
    containerWrapper: {
        position:'relative'
    },
    infoMessages: {
        position:'absolute',
        width:'100%',
        bottom: '0',
        left: '0',
        padding: '10px 20px',
        fontSize: '13px',
        background: '#1e1e1e',
        textAlign: 'center',
        borderTop: '1px solid rgba(255,255,255,.45)'
      },
})
//);

function cordovaDownload(filename, data, mimeType) {
    var blob = new Blob([data], {
      type: mimeType
    });

    console.log('cordovaDownload pre--')

    if (window.cordova && window.cordova.platformId !== "browser") {

        console.log('line 107' )
        
        window.platform.ready().then(() => {
      /*  var storageLocation = "";
        switch (window.device.platform) {
          case "Android":
            storageLocation = window.cordova.file.externalDataDirectory;
            break;
          case "iOS":
            storageLocation = window.cordova.file.documentsDirectory;
            break;
        }
         var folderPath = storageLocation;
         */

        // if(window.device.platform==="Android") var folderPath = window.cordova.file.externalDataDirectory;
        //else if(window.device.platform==="iOS") var folderPath = window.cordova.file.documentsDirectory;

        window.resolveLocalFileSystemURL(
            window.device.platform==="Android" ? window.cordova.file.externalDataDirectory : window.device.platform==="iOS" ? window.cordova.file.documentsDirectory : '',
          function (dir) {
            dir.getFile(
              filename,
              {
                create: true
              },
              function (file) {
                file.createWriter(
                  function (fileWriter) {
                    fileWriter.write(blob);

                    console.log('cordovaDownload-MID' )

                    fileWriter.onwriteend = function () {
                      var url = file.toURL();

                      console.log('Newaring Write ned')

                      window.cordova.plugins.fileOpener2.open(url, mimeType, {
                        error: function error(err) {
                          console.error(err);
                          alert("Unable to download");
                        },
                        success: function success() {
                          console.log("success with opening the file");
                        }
                      });
                    };
  
                    fileWriter.onerror = function (err) {
                      alert("Unable to download");
                      console.error(err);
                    };
                  },
                  function (err) {
                    // failed
                    alert("Unable to download");
                    console.error(err);
                  }
                );
              },
              function (err) {
                alert("Unable to download");
                console.error(err);
              }
            );
          },
          function (err) {
            alert("Unable to download");
            console.error(err);
          }
        );
        })
    } else {
        console.log('Trying Save As')
      saveAs(blob, filename);
    }
  }





function FileManager(props){

    //const classes = useStyles();
    const classes = useClasses(styles)
    var {selectCallback, height} = props;
    height = (height !== undefined || height > 300 ? `${height}px` : '500px');
    const bigHeight = `${window.innerHeight - 100}px`;
    const [messagesList, setMessages] = useState([]);
    const [isloading, setLoading] = useState(false);
    const [uploadBox, setuploadBox] = useState(false);
    const [expand, setExpand] = useState(false);
    const selecMessages = props.selectedFiles.length > 0 || props.bufferedItems.files.length > 0;
    //const useIsMobile = props.globalState.useIsMobile
    //const useIsMobileVert = props.globalState.useIsMobileVert
    const mobileSize = globalState.useIsMobile(560)
    const [popup01, popupData1] = useAtom(globalState.popupState)
    const [popup02, popupData2] = useAtom(globalState.popupState2)
    const [popup03,popupData3] = useAtom(globalState.popupState3)
    const [mainPopupData, setMainPopup] = useAtom(globalState.popupState)
    const [projectMap, setProjectMap] = useAtom(globalState.projectMapState)
    const [UserMap, setUserMap] = useAtom(props.globalState.userMapState)
    const viewerRef = useRef(null)
    const [editImage, setEditImage] = useState({
        open:false,
        closeCallBack: false,
        submitCallback: false,
        name:'',
        path:'',
        extension:''
    })

if(!popup03.open) var setPopup = popupData3
else if(!popup02.open) setPopup = popupData2
else if(!popup01.open) setPopup = popupData1

 // [popupData, setPopup] = useState({ open: false, });

    const handlingHistory = (historyInfo, index) => {
        props.setHistoryIndex(index);
        props.unsetSelectedFiles();
        switch (historyInfo.action) {
            case 'folderChange':
                    operations.handleSetMainFolder(historyInfo.path, true);
                break;
        
            default:
                break;
        }
    }

    const handleClose = () => {
        setPopup({
          open: false
        });
    };

    const handleClickPopupOpen = (data) => {
        setPopup({
          ...data,
          open: true,
        });
    };

    const operations = {

        handleAddSelected :(path) => {
            props.setSelectedFiles(path);
        },

        handleUnsetSelected :() => {
            props.unsetSelectedFiles();
        },

        handleInverseSelected :() => {
            props.inverseSelectedFiles();
        },

        handleSelectAll :() => {
            props.selectAllFiles();
        },
        
        handleGotoParent: () => {
            props.unsetSelectedFiles();
            operations.handleSetMainFolder(props.foldersList.path);
        },

        handleGoBackWard: () => {
            let historyIndex = props.history.currentIndex > 0 ? props.history.currentIndex - 1 : 0;
            let historyInfo = props.history.steps[historyIndex];
            handlingHistory(historyInfo,historyIndex);
        },

        handleGoForWard: () => {
            if(props.history.currentIndex + 1 < props.history.steps.length) {
                let historyIndex =  props.history.currentIndex + 1;
                let historyInfo = props.history.steps[historyIndex];
                handlingHistory(historyInfo,historyIndex);
            }
        },

        handleCopy : () => {
            props.copyToBufferFiles();
            setMessages([{
                title: `File Successfully Copied`,
                type:'info',
                //message: 'You can paste it in any folder',
                timer: 3000,
            }]);
        },
    
        handleCut : () => {
            props.cutToBufferFiles();
            setMessages([{
                title: `File Successfully Cut`,
                type:'info',
                //message: 'You can paste it in any folder',
                timer: 3000,
            }]);
        },
    
        handlePaste : ()=> {
            let files = props.bufferedItems.files.map((item)=> item.path);

            props.pasteFiles(files, props.bufferedItems.type, props.selectedFolder).then(result =>{
                operations.handleReload();
                setMessages([{
                    title: `File Successfully Pasted`,
                    type:'success',
                   // message: 'You can paste it in any folder',
                    timer: 3000,
                }]);
            }).catch((error)=>{
                setMessages([{
                    title: `Error happened while paste items`,
                    type:'error',
                    message: error.message
                }]);
            });
        },

        handleSetMainFolder: (value, history = false) => {
          console.log('handleSetMainFolder', value, history)
            props.unsetSelectedFiles();
            props.setSelectedFolder(value, history);
            props.getFilesList(value).then(result=>{
                setMessages([{
                    title: `Folder Successfully Loaded`,
                    type:'success',
                   // message: 'You can paste it in any folder',
                    timer: 3000,
                }]);
            })
        },

        handleDelete : ()=> {
            let files = props.selectedFiles.map((item)=> item.path);

            const handleDeleteSubmit = ()=>{
                setPopup({
                    open: false
                });
                props.deleteItems(files).then(result=>{
                    props.unsetSelectedFiles();
                    operations.handleReload();
                    setMessages([{
                        title: `Delete files and folders request`,
                        type:'success',
                        message: 'All files and folders successfully deleted'
                    }]);
                }).catch((error)=>{
                    setMessages([{
                        title: `Error happened while removing`,
                        type:'error',
                        message: error.message
                    }]);
                });
            }
                        
            handleClickPopupOpen({
                title:`Deleting selected files and folders: ${props.selectedFiles.length} items `,
                description:`All selected files and folder will remove without recover`,
                handleClose: handleClose,
                handleSubmit: handleDeleteSubmit,
                nameInputSets:{}
            });
        },
    
        handleEmptyFolder : ()=> {
            var path = props.selectedFolder;
            
            const handleEmptySubmit = ()=>{
                setPopup({
                    open: false
                });

                props.emptydir(path).then(result=>{
                    props.unsetSelectedFiles();
                    operations.handleReload();
                    setMessages([{
                        title: `Empty folder request`,
                        type:'success',
                        message: 'All files and folders successfully removed'
                    }]);
                }).catch((error)=>{
                    setMessages([{
                        title: `Error happened while empty folder`,
                        type:'error',
                        message: error.message
                    }]);
                });
            }
                        
            handleClickPopupOpen({
                title:`Deleting all files and folders in ${path}`,
                description:`All files and folder will remove without recover`,
                handleClose: handleClose,
                handleSubmit: handleEmptySubmit,
                nameInputSets:{}
            });
        },

        handleNewFile : ()=> {
            var fileName = 'new_file.txt';
            const handleNewFileChange = value => {
                fileName = value;
            }
            const handleNewFileSubmit = ()=>{
                setPopup({
                    open: false
                });
                props.createNewFile(props.selectedFolder, fileName).then(result=>{
                    operations.handleReload();
                }).catch((error)=>{
                    setMessages([{
                        title: `Error happened while creating file`,
                        type:'error',
                        message: error.message
                    }]);
                });
            }
                        
            handleClickPopupOpen({
                title:`Creating new file`,
                description:'Only allowed file extensions can be created. Otherwise will be ignored by server.',
                handleClose: handleClose,
                handleSubmit: handleNewFileSubmit,
                nameInputSets: {
                    label:'File Name',
                    value:fileName,
                    callBack:handleNewFileChange,
                }
            });
        },
    
        handleNewFolder : ()=> {
            var folderName = 'newfolder';
            const handleNewFolderChange = value => {
                folderName = value;
            }
            const handleNewFolderSubmit = ()=>{
                setPopup({
                    open: false
                });
                props.createNewFolder(props.selectedFolder, folderName).then(result=>{
                    operations.handleReload();
                }).catch((error)=>{
                    setMessages([{
                        title: `Error happened while creating folder`,
                        type:'error',
                        message: error.message
                    }]);
                });
            }
                        
            handleClickPopupOpen({
                title:`Creating new folder`,
                description:'Dont use spaces, localised symbols or emojies. This can affect problems',
                handleClose: handleClose,
                handleSubmit: handleNewFolderSubmit,
                nameInputSets: {
                    label:'Folder Name',
                    value:folderName,
                    callBack:handleNewFolderChange,
                }
            });
        },
    
        handleRename : ()=> {
            var item = props.selectedFiles[0];
            var renameTxt = item.name;
            const handleRenameChange = value => {
                renameTxt = value;
            }
            const handleRenameSubmit = ()=>{
                setPopup({
                    open: false
                });
                props.renameFiles(item.path, renameTxt).then(result=>{
                    props.unsetSelectedFiles();
                    operations.handleReload();
                }).catch((error)=>{
                    setMessages([{
                        title: `Error happened while rename`,
                        type:'error',
                        message: error.message
                    }]);
                });
            }
                        
            handleClickPopupOpen({
                title:`Renaming of ${item.name}`,
                handleClose: handleClose,
                handleSubmit: handleRenameSubmit,
                nameInputSets: {
                    label:'Folder Name',
                    value:renameTxt,
                    callBack:handleRenameChange,
                }
            });
        },

        handleReload: ()=>{
            setLoading(true);
            setMessages([{
                title: `Wait While Reloading`,
                type:'info',
                message: '',
                progress:true,
                disableClose: true
            }]);
            props.getFilesList(props.selectedFolder);
            props.getFoldersList().then(result=>{
                setLoading(false);
                setMessages([]);
                console.log()
            });
        },

        handleDuplicate : ()=> {
            var item = props.selectedFiles[0];
            props.dublicateItem(item.path).then(result=>{
                    props.unsetSelectedFiles();
                    operations.handleReload();
                }).catch((error)=>{
                    setMessages([{
                        title: `Error happened while duplicate`,
                        type:'error',
                        message: error.message
                    }]);
                });
        },

        handleCreateZip : ()=> {
            var name = 'archive_name';
            let files = props.selectedFiles.map((item)=> item.path);
            let destination = props.selectedFolder;
            const handleArchiveChange = value => {
                name = value;
            }
            const handleArchiveSubmit = ()=>{
                setPopup({
                    open: false
                });
                props.archive(files, destination, name).then(result=>{
                    operations.handleReload();
                    props.unsetSelectedFiles();
                }).catch((error)=>{
                    setMessages([{
                        title: `Error happened while creating archive`,
                        type:'error',
                        message: error.message
                    }]);
                });
            }
                        
            handleClickPopupOpen({
                title:`Creating new archive`,
                description:'Create a new archive with all selected files. If there is already file with this name it will replace',
                handleClose: handleClose,
                handleSubmit: handleArchiveSubmit,
                nameInputSets: {
                    label:'Archive Name',
                    value:name,
                    callBack:handleArchiveChange,
                }
            });
        },

        handleExtractZip : ()=> {
            let file = props.selectedFiles[0].path;
            let destination = props.selectedFolder;
            const handleArchiveSubmit = ()=>{
                setPopup({
                    open: false
                });
                props.unzip(file, destination).then(result=>{
                    operations.handleReload();
                    props.unsetSelectedFiles();
                }).catch((error)=>{
                    setMessages([{
                        title: `Error happened while extraction archive`,
                        type:'error',
                        message: error.message
                    }]);
                });
            }
                        
            handleClickPopupOpen({
                title:`Extract all files from archive to ${destination}`,
                description:'All files will extracted. If they are existed in folder alreadt they will replaced.',
                handleClose: handleClose,
                handleSubmit: handleArchiveSubmit,
                nameInputSets: {}
            });
        },

        handlePreview : ()=> {
            let file = props.selectedFiles[0];
            props.unsetSelectedFiles();
            var viewer = <Box ref={viewerRef} sx={{width:'85vw', height: '95vh'}} key={"safteyFrame"}><FileViewer fileType={file.extension.replace(/[.]+/g, '')} filePath={mainconfig.serverPath+file.path} onError={(e)=>console.log(e, "error in file-viewer") } /></Box>
              //  console.log('VIEWER',file.extension.replace(/[.]+/g, '') )
              setPopup(p=>({...p,...{
                open:true,
                width:'85vw',
               // height:'85vh',
                title:`File: ${file.name}`,
                //description: viewer,
                inputField: viewer,
                handleClose: handleClose,
        }}))
        },

        handleGetInfo : ()=> {
            let file = props.selectedFiles[0];
            let isImage = checkSelectedFileType('image');
            props.unsetSelectedFiles();
            let description = `
                <ul class="list">
                    <li><b>Name</b> : ${file.name}</li>
                    <li><b>Path</b> : ${file.path}</li>
                    ${file.type === 'file'? 
                    `<li><b>Size</b> : ${formatBytes(file.size)}</li>
                    <li><b>Extension</b> : ${file.extension}</li>`
                    :''}
                    <li><b>Created</b> : ${convertDate(file.created)}</li>
                    <li><b>Modified</b> : ${convertDate(file.modified)}</li>
                    <li><b>Permissions</b> : Others - ${file.premissions.others}, Group - ${file.premissions.group}, Owner - ${file.premissions.owner}</li>
                </ul>
                ${isImage ? `<img src="${mainconfig.serverPath}${file.path}" />`: ''}
            `;
            handleClickPopupOpen({
                title:`File: ${file.name}`,
                description,
                handleClose: handleClose,
                nameInputSets: {}
            });
        },

         handleAdminView : ()=> {
            let file = props.selectedFiles[0];
            let isImage = checkSelectedFileType('image');
            props.unsetSelectedFiles();
            let description = `
                <ul class="list">
                    <li><b>Name</b> : ${file.name}</li>
                    <li><b>Path</b> : ${file.path}</li>
                    ${file.type === 'file'? 
                    `<li><b>Size</b> : ${formatBytes(file.size)}</li>
                    <li><b>Extension</b> : ${file.extension}</li>`
                    :''}
                    <li><b>Created</b> : ${convertDate(file.created)}</li>
                    <li><b>Modified</b> : ${convertDate(file.modified)}</li>
                    <li><b>Permissions</b> : Others - ${file.premissions.others}, Group - ${file.premissions.group}, Owner - ${file.premissions.owner}</li>
                </ul>
                ${isImage ? `<img src="${mainconfig.serverPath}${file.path}" />`: ''}
            `;
            setPopup(p=>({...p,...{
                open: true,
                title:`Who Can View: ${file.name}`,
                description: 'You can limit or allow staff (by position), Clients, and Partners.',
                handleClose: handleClose,
                nameInputSets: {}
            }}));
        },
    
        handleReturnCallBack : (item)=> {
            console.log('handleReturnCallBack Method Called', item);
            if(selectCallback) {
                selectCallback(item);
            }
            return true;
        },

        handleUpload : ()=> {
            setuploadBox(!uploadBox);
            setLoading(!isloading);
        },

        handleEditImage : ()=> {
            const item = props.selectedFiles[0];
            const fullpath = `${mainconfig.serverPath}${item.path}`;
            const closeCallBack = ()=>{
                setEditImage({
                    open:false,
                    closeCallBack: false,
                    submitCallback: false,
                    name:'',
                    path:'',
                });
            }
            const submitCallback = (imageData, asNew)=>{
                setEditImage({
                    open:false,
                    closeCallBack: false,
                    submitCallback: false,
                    name:'',
                    path:'',
                    extension:''
                });
                props.saveimage(imageData, item.path, asNew).then((result)=>{
                    props.unsetSelectedFiles();
                    props.getFilesList(props.selectedFolder).then(()=>{
                        setMessages([{
                            title:`Image successfully saved`,
                            type:'info',
                            message:'Changes may not be visible because of cache. Please update the page',
                        }]);
                    }).catch((error)=>{
                        console.log(error);
                    });
                }).catch((error)=>{
                    setMessages([{
                        title:`Error happened while saving image`,
                        type:'error',
                        message: error.message
                    }]);
                });
       
            }
            setEditImage({
                open:true,
                closeCallBack,
                submitCallback,
                name:item.name,
                path:fullpath,
                extension:item.extension
            });
        },

        handleEditText : ()=> {
            console.log('IMage Edit');
        },

        handleDownload : ({extension,path,name})=> {
        if(path){
                fetch(`${mainconfig.serverPath}${path}`)
                .then( (response)=> response.blob() )
                .then( (blob)=> 
                  setTimeout(() => {
                    if(window.cordova) cordovaDownload(name, blob, extension)
                    else {
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download",`${name}.${extension}`);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                    }
                  }, 1000)
                );
            }
            else if(!path){ 
            let file = props.selectedFiles[0];
            setTimeout(() => {
                window.open(`${mainconfig.serverPath}${file.path}`);
              }, 100)
            }
            else{

            }
        },

        handleFullExpand: ()=>{
            setExpand(!expand);
        },

        handleViewChange: (type)=>{
            props.listViewChange(type);
        },

        handleDragEnd : (result)=> {
          return
            setLoading(!isloading);
            try {
                let files = [];
                let destination;
                props.filesList.forEach(file => {
                  if(file.id === result.draggableId){
                    files = [file.path];
                  }
                  if(file.id === result.destination.droppableId){
                    destination = file.path;
                  }
                });
        
                if(destination !== undefined && files.length !== 0){
                    props.pasteFiles(files, 'cut', destination).then(result =>{
                        operations.handleReload();
                        setMessages([{
                            title: `File Successfully Moved`,
                            type:'success',
                            message: 'File that you dragged successfully moved',
                            timer: 3000,
                        }]);
                    }).catch((error)=>{
                      
                    });
                }
               
              } catch (error) { }
              setLoading(!isloading);
              console.log('Drag ended',result);
        },

    }

    useEffect(()=>{
        if(UserMap.get('projectsView')==='jsListView') operations.handleViewChange('list')
        if(UserMap.get('projectsView')==='jsGridView') operations.handleViewChange('grid')
      //  UserMap.has('projectsView') ? UserMap.get('projectsView') : 'jsGridView'
    },[UserMap.get('projectsView')])

    const checkSelectedFileType = (type)=>{
        try {
            switch (type) {
                case 'text':
                        return config.textFiles.includes(props.selectedFiles[0].extension);
                case 'archive':
                    return config.archiveFiles.includes(props.selectedFiles[0].extension);

                case 'image':
                    return config.imageFiles.includes(props.selectedFiles[0].extension);

                case 'file':
                    return props.selectedFiles[0].type === 'file';
            
                default:
                    return false;
            }
        } catch (error) {
            return false;
        }
    }

    const allButtons = {
        copy:{
            title: 'Copy',
            icon: faCopy,
            onClick: operations.handleCopy,
            disable: !(props.selectedFiles.length > 0)
        },
        cut:{
            title: 'Cut',
            icon: faCut,
            onClick: operations.handleCut,
            disable: !(props.selectedFiles.length > 0)
        },
        paste:{
            title: 'Paste',
            icon: faPaste,
            onClick: operations.handlePaste,
            disable: !(props.bufferedItems.files.length > 0)
        },
        delete:{
            title: 'Delete',
            icon: faTrash,
            onClick: operations.handleDelete,
            disable: !(props.selectedFiles.length > 0)
        },
        emptyFolder:{
            title: 'Empty Folder',
            icon: faFolderMinus,
            onClick: operations.handleEmptyFolder,
        },
        rename:{
            title: 'Rename',
            icon: faAdversal,
            onClick: operations.handleRename,
            disable: !(props.selectedFiles.length === 1)

        },
        newFile:{
            title: 'New File',
            icon: faPlusSquare,
            onClick: operations.handleNewFile,
        },
        newFolder:{
            title: 'New Folder',
            icon: faFolderPlus,
            onClick: operations.handleNewFolder,
        },
        goForwad:{
            title: 'Forwad',
            icon: faForward,
            onClick: operations.handleGoForWard,
            disable: !(props.history.currentIndex + 1 < props.history.steps.length)
        },
        goParent:{
            title: 'Go to parent folder',
            icon: faBackward,
            onClick: operations.handleGotoParent,
            disable: props.selectedFolder === props.foldersList.path
        },
        goBack:{
            title: 'Back',
            icon: faUndoAlt,
            onClick: operations.handleGoBackWard,
            disable: !(props.history.currentIndex > 0)
        },
        selectAll:{
            title: 'Select all',
            icon: faListAlt,
            onClick: operations.handleSelectAll,
            disable: !(props.selectedFiles.length !== props.filesList.length)
        },
        selectNone:{
            title: 'Select none',
            icon: farListAlt,
            onClick: operations.handleUnsetSelected,
            disable: (props.selectedFiles.length === 0)
        },
        inverseSelected:{
            title: 'Invert selection',
            icon: faObjectGroup,
            onClick: operations.handleInverseSelected,
            disable: !(props.selectedFiles.length !== props.filesList.length && props.selectedFiles.length > 0)
        },
        selectFile: {
            title: 'Select file',
            icon: faFileMedical,
            onClick: operations.handleReturnCallBack,
            disable: typeof selectCallback === 'undefined'
        },
        reload: {
            title:'Reload',
            icon: faSyncAlt,
            onClick: operations.handleReload,
        },
        dubplicate:{
            title: 'Duplicate',
            icon: faClone,
            onClick: operations.handleDuplicate,
            disable: !(props.selectedFiles.length === 1)
        },

        editFile:{
            title: 'Edit File',
            icon: faFileSignature,
            onClick: operations.handleEditText,
            disable: !(props.selectedFiles.length === 1 && checkSelectedFileType('text'))
        },

        editImage:{
            title: 'Resize & Rotate',
            icon: faBorderStyle,
            onClick: operations.handleEditImage,
            disable: !(props.selectedFiles.length === 1 && checkSelectedFileType('image'))
        },
        createZip:{
            title: 'Create archive',
            icon: faFileArchive,
            onClick: operations.handleCreateZip,
            disable: !(props.selectedFiles.length > 0)
        },
        extractZip:{
            title: 'Extract files from archive',
            icon: faBoxOpen,
            onClick: operations.handleExtractZip,
            disable: !(props.selectedFiles.length === 1 && checkSelectedFileType('archive'))
        },
        uploadFile:{
            title: 'Upload Files',
            icon: faFileUpload,
            onClick: operations.handleUpload,
        },
        searchFile:{
            title: 'Search File',
            icon: faSearch,
            onClick: operations.handleSearchFile,
        },
        saveFile:{
            title: 'Save Changes',
            icon: faSave,
            onClick: operations.handleSaveFileChanges,
        },
        preview:{
            title: 'View',
            icon: faBinoculars,
            onClick: operations.handlePreview,
            disable: !(props.selectedFiles.length === 1) //&& checkSelectedFileType('image')
        },
        getInfo:{
            title: 'Get Info',
            icon: faInfoCircle,
            onClick: operations.handleGetInfo,
            disable: !(props.selectedFiles.length === 1)
        },
        adminView:{
            title: 'Who can view this',
            icon: faUserShield,
            onClick: operations.handleAdminView,
            disable: !(props.selectedFiles.length === 1)
        },
        download:{
            title: 'Download File',
            icon: faFileDownload,
            onClick: operations.handleDownload,
            disable: !(props.selectedFiles.length === 1 && checkSelectedFileType('file'))
        },
        gridView: {
            title: 'Grid view',
            icon: faTh,
            onClick: ()=>operations.handleViewChange('grid'),
            disable: props.itemsView === 'grid'
        },
        listView: {
            title: 'List View',
            icon: faThList,
            onClick: ()=>operations.handleViewChange('list'),
            disable: props.itemsView === 'list'
        },
        fullScreen: {
            title: 'Full Screen',
            icon: expand ? faCompressArrowsAlt : faExpandArrowsAlt,
            onClick: operations.handleFullExpand
        },
        qrCode: {
            title: 'Generate QR Code',
            icon: QrCodeScannerIcon,
            onClick: ()=>{ 
            var {name,extension,path} = props.selectedFiles[0];  
            setPopup(p=>({...p,...{
                open:true,
               // height:'85vh',path
                title:`Create QR Code`,
                //description: viewer,
                inputField: <QrGenerator name={name} path={path} server={mainconfig.serverPath} extension={extension.replace(/[.]+/g, '')} />,
                handleClose: handleClose,
        }}))
        } 
        }
    }

    /*[allButtons.gridView, allButtons.listView]*/
const aviableButtons = () => { 
      var btns = { topbar:[
            [allButtons.download, allButtons.goBack, allButtons.goParent, allButtons.goForwad],
            [allButtons.inverseSelected, allButtons.selectNone, allButtons.selectAll],
            [allButtons.preview, allButtons.getInfo, allButtons.selectFile],
        ],
        file: [
            [allButtons.download,allButtons.createZip, allButtons.extractZip, allButtons.adminView ],
            [allButtons.preview, allButtons.getInfo, allButtons.selectFile],
        ],
        container: [
            [allButtons.goBack, allButtons.goForwad, allButtons.goParent],
            [allButtons.newFile, allButtons.newFolder, allButtons.emptyFolder,  allButtons.uploadFile, allButtons.reload],
            [allButtons.inverseSelected, allButtons.selectNone, allButtons.selectAll],
        ]}
        if(UserMap.get('level')<=3){ 
          btns.topbar.push([allButtons.newFile, allButtons.newFolder, allButtons.uploadFile, allButtons.reload],[allButtons.copy, allButtons.cut, allButtons.paste, allButtons.delete, allButtons.emptyFolder, allButtons.dubplicate],[allButtons.rename, allButtons.editImage],[allButtons.createZip, allButtons.extractZip])
          btns.file.push([allButtons.copy, allButtons.cut, allButtons.paste, allButtons.delete, allButtons.dubplicate],[allButtons.rename, allButtons.editImage,allButtons.qrCode])
        }
return btns
    }

    function updateFolderDir(value,reName){
        props.getFilesList('');
        props.getFoldersList().then(res=>props.setSelectedFolder(value))
        props.unsetSelectedFiles();
        props.setSelectedFolder(value, false);
        props.getFilesList(value).then(result=>{
                   setMessages([{
                       title: `${reName ? reName : value.split('/').at(-1)} Successfully Loaded`,
                       type:'success',
                      // message: 'You can paste it in any folder',
                       timer: 3000,
                   }]);
               })
    }
    useEffect(() => {
    // console.log('Props:::',props)
    if(props?.viewData?.projID?.ProjectID){ 
        props.getFoldersList(`/EKC/Projects/`+props.viewData.projID.ProjectID,false)
        updateFolderDir(`/EKC/Projects/`+props.viewData.projID.ProjectID, projectMap.get(props.viewData.projID.ProjectID).ProjectTitle )
    }else updateFolderDir(props.viewData.folderName)
    }, []); 

const mobile720 = props.globalState.useWidthHeight(720)
const mobile420 = props.globalState.useWidthHeight(420)

return <ThemeProvider theme={darkTheme}>
    <ImageEditor/>
    <Box style={{display:'flex',flex:1,width:'100%',overflow:'hidden'}} className={expand ? classes.fmExpanded : classes.fmMinimized} >
        <Paper style={{backgroundColor:'transparent',display:'flex',flex:1,flexDirection:'column',width:'100%'}}>
            {/* popupData.open &&  <PopupDialog {...popupData} /> */}
            {editImage.open &&  <ImageEditor {...editImage} /> }
            <TopBar buttons={aviableButtons()} />
            <Grid container style={{flex:1,flexWrap:'unset',flexDirection: mobile720.mw && !mobile420.mh ?'column':'row',overflow:'hidden'}}>
                <Grid item xs={3} sm={2} className={classes.folderSide} style={{maxWidth:'unset',flexBasis:'unset',minWidth:'24vw',margin:'1% .5%',borderRadius:'8px',boxShadow:'3px 3px 5px rgb(0 0 0 / 35%)'}}>
                    <Box >
                        <Box style={{ maxHeight: (expand ? bigHeight : height )}}>
                            <FolderBar foldersList={props.foldersList} onFolderClick={operations.handleSetMainFolder} selectedFolder={props.selectedFolder} />
                        </Box>
                    </Box>
                </Grid>
                <Grid className={classes.containerWrapper} item xs={9} sm={10} style={{maxWidth:'unset',
                    margin: '1% 0',
                    backgroundColor: '#2e2e2e',
                    borderRadius: '8px',
                    overflow:'hidden',
                    boxShadow: 'rgb(0 0 0 / 35%) 3px 3px 5px',
                    width:'100%'}} >
                    <Box sx={{width:'100%',height:'100%'}}>
                        <Box style={{overflow:'auto',width:'100%',height:'100%'}/* maxHeight: (expand ? bigHeight : height )*/}>
                            <ContainerBar buttons={aviableButtons()} messages={messagesList} setMessages={setMessages} globalWrn={props.globalWrn} isloading={isloading} uploadBox={uploadBox} operations={operations} />
                        </Box>
                    </Box>
                    <Collapse in={selecMessages}>
                        <Box className={classes.infoMessages}>
                            {props.selectedFiles.length > 0 && <Box className="text"><b>{props.selectedFiles.length}</b> items are selected</Box> }
                            {props.bufferedItems.files.length > 0 && 
                                <Box className={"text"}>
                                  <b>{props.bufferedItems.files.length}</b> {props.bufferedItems.type === 'cut' ? 'cuted' : 'copied'} items in buffer
                                  (<Button onClick={(e)=>{e.preventDefault(); props.clearBufferFiles()}}>Clear</Button>)
                                </Box>}
                        </Box>
                    </Collapse>
                </Grid>
            </Grid>
        </Paper>
    </Box>
    </ThemeProvider>
}

const mapStateToProps = store => ({
    store,
    selectedFiles: store.filemanager.selectedFiles,
    selectedFolder: store.filemanager.selectedFolder,
    bufferedItems: store.filemanager.bufferedItems,
    foldersList: store.filemanager.foldersList,
    filesList: store.filemanager.filesList,
    itemsView: store.filemanager.itemsView,
    history: store.filemanager.history,
    translations : store.dashboard.translations,
    lang : store.dashboard.lang,
});

const mapDispatchToProps = dispatch => ({
    setSelectedFiles: (path) => dispatch(setSelectedFiles(path)),
    unsetSelectedFiles: () => dispatch(unsetSelectedFiles()),
    inverseSelectedFiles: () => dispatch(inverseSelectedFiles()),
    selectAllFiles: () => dispatch(selectAllFiles()),
    copyToBufferFiles: () => dispatch(copyToBufferFiles()),
    cutToBufferFiles: () => dispatch(cutToBufferFiles()),
    pasteFiles: (files, type,destination) => dispatch(pasteFiles(files, type,destination)),
    setSelectedFolder: (path, history) => dispatch(setSelectedFolder(path, history)),
    getFoldersList: () => dispatch(getFoldersList()),
    getFilesList: (path) => dispatch(getFilesList(path)),
    setHistoryIndex: (path) => dispatch(setHistoryIndex(path)),
    renameFiles: (path, newName) => dispatch(renameFiles(path, newName)),
    createNewFile: (destination, fileName) => dispatch(createNewFile(destination, fileName)),
    createNewFolder: (destination, folderName) => dispatch(createNewFolder(destination, folderName)),
    emptydir: (path) => dispatch(emptydir(path)),
    deleteItems: (items) => dispatch(deleteItems(items)),
    dublicateItem: (path) => dispatch(dublicateItem(path)),
    archive: (files, destination, name) => dispatch(archive(files, destination, name)),
    saveimage: (file, path, isnew) => dispatch(saveimage(file, path, isnew)),
    unzip: (file, destination) => dispatch(unzip(file, destination)),
    listViewChange: (type) => dispatch(listViewChange(type)),
    clearBufferFiles: () => dispatch(clearBufferFiles()),    
});

export default connect(mapStateToProps, mapDispatchToProps)(FileManager);