import React, { useEffect, useRef, Suspense } from "react";
import { useAtom } from 'jotai';
import useState from 'react-usestateref';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { styled } from '@mui/material/styles';
import { Avatar,Paper,Divider,Box,Drawer,Checkbox,InputLabel,MenuItem,FormControl,Select,Chip,Typography,Accordion,AccordionSummary,AccordionDetails,List,ListItemText,ListItemIcon,ListItem,IconButton,InputBase,ListSubheader,ListItemButton,Collapse } from '@mui/material';
import {ExpandMore,ExpandLess,Cancel,KeyboardArrowDown} from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import SegmentIcon from '@mui/icons-material/Segment';

import _without from "lodash/without";

export default function UserSelect(props){
  const {That,viewData,action,globalState} = props
  const {label,state,manualOpen,defaultValue,disabled,list,chapters} = viewData;
  const [divisionMap] = useAtom(globalState.divisionMapState);
  const [teamMember,] = useAtom(globalState.teamMemberState);
  const [,setParentState] = That
  const mainState = useState(Object)
  const [, setState, stateRef] = mainState
  const outputState = useState([])
  const [, setOutput, outputRef] = outputState

  useEffect(()=>{
    if(!chapters) viewData.chapters = Array.from([...divisionMap],([k,v])=>v?.teamName)
    if(!list) viewData.list = Object.assign({},...globalState.groupByKey([...Array.from([...teamMember],([k,{division}])=>({DivisionID:division,_id:k}))],'DivisionID').flatMap(({DivisionID,data})=>({[divisionMap.get(DivisionID)?.teamName]:data.flatMap(({_id})=>_id)})))
  },[])

  useEffect(()=>{
   if(defaultValue?.length>0){ 
    setOutput(Object.assign({},...defaultValue.flatMap(({id,userFilter})=>({[id]:userFilter}))))
    setState(p=>({...p,
      defaultValue: Object.assign({},...defaultValue)
    }))
  }
  },[defaultValue])
  
  useEffect(()=>{
    if(Object.keys(outputRef.current)?.length > 0 || defaultValue){
    if(stateRef.current.drawerState===false && (Object.keys(outputRef.current)?.length>0 || (stateRef.current?.defaultValue && Object.keys(stateRef.current?.defaultValue)?.length>0 )) ){
      var val = Object.entries(outputRef.current).flatMap(([k,v])=>({id:k,userFilter:v}))
      if(stateRef.current.defaultValue && Object.keys(stateRef.current.defaultValue).length > 0) var compare = stateRef.current?.defaultValue ? Object.entries(stateRef.current?.defaultValue).flatMap(([k,v])=>({id:k,userFilter:v})) : null
      if(compare !== null && JSON.stringify(val) !== JSON.stringify(compare)){
          setParentState(p=>({...p,[state]: val }))
          if(action) action.forEach((x)=>x({[state]:val})) 
        }
      }
    }
  },[stateRef.current.drawerState])

  useEffect(()=>{
    if(manualOpen) setState(p=>({...p,drawerState: true,menuBttn:null}))
    else setState(p=>({...p,
      menuBttn: props.dynamicComponent('DefaultButton','elements',{state:'teamMembers',label:label,type:'button',disabled:disabled,startIcon:<FontAwesomeIcon icon={faUsers}/>,color:'#c0392b',full:true},mainState,[()=>setState(p=>({...p,drawerState: !stateRef.current.drawerState })) ])
     }))
  },[manualOpen])
  
return [ stateRef.current.menuBttn, <Drawed {...props} That={mainState} Output={outputState} /> ]
  }

function Drawed(props){
  const {That,projList,globalState,waveLoad,queryDB,viewData:{projID}} = props;
  const [, setParentState, {current: parentStateRef}] = That;
  if(projID) var { ProjectID, DivisionID } = projID;
  const [, setState, {current:stateRef}] = useState(Object);
  const [divisionMap] = useAtom(globalState.divisionMapState);
  const [projectMap] = useAtom(globalState.projectMapState);
  const DivisionData = divisionMap.get(DivisionID);
  const ProjectData = projectMap.get(ProjectID);

  useEffect(()=>{
    
    if(DivisionData&&ProjectData&&!projList&&!stateRef.projList) {
      setState(p=>({...p,
        projList: <Box>{waveLoad()}</Box>
      }))
      console.log('Trigger queryDB')
      queryDB({model:'AdminOrg',query:{'fieldSet':{'$in':[`${DivisionData?.category}Services`,`${DivisionData?.category}Production`]}},filter:{'_id':0,'fieldSet':0,'Number':0}},([items,items2],err)=>{ 
      if(items2) items = Object.assign(items,items2) 
      setState(p=>({...p,
        projList: projectLists(items,ProjectData)
      }))
    })
  }
  },[DivisionData,ProjectData,projList])

return <Drawer
          anchor={'right'}
          PaperProps={{ sx: { backgroundColor:'rgba(0,0,0,.35)',backdropFilter:'blur(15px)',padding:'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)'}}}
          open={parentStateRef.drawerState || false}
          onClose={()=> setParentState(p=>({...p,drawerState: false })) }
          >
          <ChipsArray {...props} />
          <Accod {...props} projList={projList||stateRef.projList} />
          <SearchBar {...props} />
        </Drawer>
}


function Accod(props){
  const {viewData,Output,waveLoad,globalState} = props;
  const {chapters,list,divisionName,divisionSel} = viewData;
  const [, setOutput, outputRef] = Output;
  const teams = outputRef.current ? Object.keys(outputRef.current) : [];
  const [expanded, setExpanded] = React.useState(false);
  const accordion = useRef(null);

  const handleChange = (panel)=>(event, isExpanded)=>setExpanded(isExpanded ? panel : false);

 return <Box sx={{width:'100%'}} role="presentation">
      <Box ref={accordion} style={{padding:'2%'}}> {
      chapters && chapters.flatMap((x,i)=>
    <Accordion loading={true} expanded={expanded === `panel${i}` || (expanded===false && divisionName === x)} onChange={handleChange(`panel${i}`)} >
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1bh-content" id={`panel${i}bh-header`} >
        <Typography sx={{ width: '33%', flexShrink: 0 }}> {x.toUpperCase()} </Typography>
        {divisionSel && <Checkbox 
            checked={teams.includes(...list[x])}
            onClick={()=>{
             var newVal = Object.assign({},...list[x].map(x=>({[x]:[]})))
             if(!teams.includes(...list[x]))setOutput(p=>({...p,...newVal }))
             else setOutput(p=> Object.assign({},...Object.entries(p).filter(([k,v])=>!list[x].includes(k)).map(([k,v])=>({[k]:v}))))
            }}
          /> }
        </AccordionSummary>
        <AccordionDetails sx={{padding:0}}>
        <Suspense fallback={waveLoad()}>
        { list[x]?.flatMap(y=> <Listed {...props} userID={y} />) }
         </Suspense>
        </AccordionDetails>
      </Accordion>
) }</Box></Box> 
}

function Listed(props){
  const {That,viewData:{projID},globalState,userID,projList,Output,dynamicComponent,waveLoad,queryDB} = props;
  const [, setOutput, outputRef] = Output;
  const [, setState, stateRef] = useState(Object);
  const [teamMember] = useAtom(globalState.teamMemberState);
  const [projectMap] = useAtom(globalState.projectMapState);
  // const [divisionMap] = useAtom(globalState.divisionMapState);
  if(projID) var { ProjectID } = projID;
  const ProjectData = projectMap.get(ProjectID);
  const teams = outputRef.current ? Object.keys(outputRef.current) : [];
  if(teamMember.has(userID)) var fullName = `${teamMember.get(userID).firstName} ${teamMember.get(userID).lastName}`;

useEffect(()=>{
if(userID) setState(p=>({...p,
  userAvatar:<ListItemIcon>{ dynamicComponent('AvatarBubble','elements',{state:'projLead',Catagory:'Team',label:'missing Lead',userID:userID},That) }</ListItemIcon>
}))
 },[userID])

useEffect(()=>{ 
 if(ProjectData?.ProjectState?.Members?.some(({id,userFilter})=>id===userID&&userFilter)||Object.keys(outputRef.current).includes(userID)) {
  setState(p=>({...p,
      userFilter: <ListItem sx={{maxWidth:250,flex:1}}>
      <UserFilters {...props} userID={userID} projList={projList}/>
     </ListItem>
     }))
    }
},[ProjectData?.ProjectState?.Members?.some(({id,userFilter})=>id===userID&&userFilter),outputRef.current,stateRef.current.projList])

return <List sx={{padding:0}} key={'listed'+userID}>
<ListItem key={'itemName'+userID} sx={{flexWrap: 'wrap',maxWidth: '65vw'}} >
{ stateRef.current.userAvatar }
    <Box sx={{display:'flex',justifyContent:'space-evenly',flex:1}}>
     <Checkbox 
           checked={teams.includes(userID)}
           onClick={()=>{
             teams.includes(userID)
             ? setOutput(p=> Object.assign({},...Object.entries(p).filter(([k,v])=>k!==userID).map(([k,v])=>({[k]:v}))))
             : setOutput(p=>({...p, [userID]:[] })) 
           }}
          />
          <ListItemText primary={<Typography>{fullName}</Typography>} sx={{m:'auto'}} />
      </Box>
      { stateRef.current.userFilter }
</ListItem>
<Divider variant="inset" />
</List>
}

const projectLists = (items, ProjectData) => { // User Select Scope of Work Assignments
  if(ProjectData?.SalesState?.OptionsFilter && ProjectData?.SalesState?.OptionsFilter.constructor === Object) var listFilter = ProjectData.SalesState.OptionsFilter
  var data = Object.keys(Object.assign({},...Object.entries(items)
  .filter(([k, v],i) => listFilter ? Object.entries(listFilter).some(([k3,v3])=> k3==k) : false )
  .map(([k, v],i) =>
  listFilter ? Object.entries(listFilter).some(([k3,v3])=>k3==k) ? 
  {[k]: Object.entries(v)
  .filter(([k2, v2],i2) => Object.entries(listFilter).some(([k3,v3])=> k==k3 ? Object.entries(v3).some(([k4,v4])=> Number(k4)==i2): false) )
  .map(([k2, v2],i2) =>({label: v2.label}) )
  } 
  : {[k]:false}
  : {[k]:false}
  )) )
  try {
  return [...data,...Object.keys(items[1])]
  }
  catch(err) {
  return data
  }
  }

function UserFilters(props){
  const {userID,Output,projList,viewData,globalState, waveLoad } = props;
  const [projectMap] = useAtom(globalState.projectMapState);
  const {projID} = viewData
  if(projID) var { ProjectID } = projID;
  const ProjectData = projectMap.get(ProjectID)
  const [,setParentState,parentRef] = Output;
  const [,setPersonName,personNameRef] = useState([]);


  useEffect(()=>{
    setPersonName(ProjectData?.ProjectState?.Members?.find(({id})=>id===userID)?.userFilter||[])
  },[userID])

  const mainState = useState({open:false})
  const [,setState,stateRef] = mainState

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    margin: `0 8px 0 0`,
    background: isDragging ? "lightgreen" : "transparent",
    ...draggableStyle
  });

  const getListStyle = (isDraggingOver, itemsLength) => ({
    display: "flex",
    flexDirection: 'column',
    gap: 2,
    margin: '9px 0',
    width: '100%',//itemsLength * 68.44 + 16,
    height: '100%'
  });

  const handleChange = ({ target: { value } }) => {
    setPersonName( typeof value === 'string' ? value.split(',') : value )
    setParentState(p=>({...p,[userID]:value}))
  }

  const handleDelete = (e,value)=>{
    e.preventDefault();
    setParentState(p=>({...p,[userID]:p[userID].filter(x=>x!==value)}))
    setPersonName((p) => p.filter(x=>x!==value));
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd=({source,destination})=> {
    if (!destination) return;

    const items = reorder( parentRef.current[userID], source.index, destination.index );
    setParentState(p=>({...p,[userID]:items}))
    setPersonName(items);
  }

  return projList && <FormControl sx={{width:"100%"}} className={'formList'}>
  <InputLabel id="mutiple-chip-checkbox-label">
  
 { projList.length>0 ? 'Assignments' : 'Project Options Needed' }
  </InputLabel>
  <Select
    labelId="mutiple-chip-checkbox-label"
    id="expanable"
    multiple
    value={personNameRef.current}
    onChange={handleChange}
    onClose={()=>setState(p=>({...p,open:false}))}
    onClick={({target})=>{if(target.id==='expanable') setState(p=>({...p,open:true}))  }}
    open={stateRef.current.open}
    IconComponent={KeyboardArrowDown}
    renderValue={(selected) => 
      <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={userID+"droppable"} >
        {(provided, snapshot) => 
         <Box ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver, selected.length)} {...provided.droppableProps} >
                {selected.map((value,i)=>
                 <Draggable key={value} draggableId={`${value+i}`} index={i}>
                 {(provided, snapshot) => 
                  <Box ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle( snapshot.isDragging, provided.draggableProps.style )} >
                    <Chip
                      key={value}
                      label={value}
                      clickable
                      avatar={<DragIndicatorIcon />}
                      deleteIcon={ <Cancel onMouseDown={(e) =>{handleDelete(e, value)}} /> }
                      onDelete={(e)=>{e.preventDefault()}}
                    />
                  </Box>}
                </Draggable> )}
          </Box>}
        </Droppable>
      </DragDropContext>
    }>
  { projList&&projList?.length > 0 ? projList?.map((name) =>
      <MenuItem key={name} value={name}>
        <Checkbox checked={personNameRef.current?.includes(name)} />
        <ListItemText primary={name} />
      </MenuItem>
    ) : <Box sx={{display: 'flex', justifyContent: 'center', padding: '2%', flexDirection: 'column'}}>Loading Assignments<Box sx={{padding: '1%'}}>{waveLoad()}</Box></Box>}
  </Select>
</FormControl>
}

const ListItemBar = styled('li')(({ theme }) =>({
  margin: theme.spacing(0.5),
}));

export function ChipsArray({That,Output,viewData,action,globalState}) {
  const [, setOutput, outputRef] = Output
  const [teamMember] = useAtom(globalState.teamMemberState)
  const [chipData, setChipData] = useState([]);

  useEffect(()=>{
  if(outputRef.current) setChipData(Object.keys(outputRef.current).flatMap((x,i)=>({ key: i, label:x})))
  },[outputRef.current])

  const handleDelete = (label) => () => {
    setChipData((chips) => chips.filter((chip) => chip.label !== label));
    setOutput(p=> Object.assign({},...Object.entries(p).filter(([k,v])=>k!==label).map(([k,v])=>({[k]:v}))) )
  };

  return <Paper
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 99,
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        maxWidth: '60vw',
        p: 0.5,
        m: 0,
      }}
      component="ul"
    >
      {chipData.map(({key,label}) =>{
        let icon;

        return (
          <ListItemBar key={key}>
            <Chip
              icon={icon}
              label={`${teamMember.get(label)?.firstName} ${teamMember.get(label)?.lastName}`}
              avatar={<Avatar alt="" src={`https://services.ekc.app/profiles/avatar/${label}_avatar.jpg`}/>}
              onDelete={handleDelete(label)}
            />
          </ListItemBar>
        );
      })}
    </Paper>
}


export function SearchBar(props) {
  var {That,viewData,globalState,Output,waveLoad,dynamicMapData,queryDB} = props
  const {list,projID} = viewData
  if(projID) var { ProjectID, DivisionID, modelType } = projID
  const [teamMember,setTeamMember] = useAtom(globalState.teamMemberState)
  const [, setState, stateRef] = That
  const [, setOutput, outputRef] = Output
  const [projectMap] = useAtom(globalState.projectMapState);
  const [divisionMap] = useAtom(globalState.divisionMapState);
  const ProjectData = projectMap.get(ProjectID)
  const [open, setOpen] = useState(true);
  const module = useRef(null)


  const getAllMembers=async()=>{
    setState(p=>({...p,refreshActive: true}))
    console.log('Trigger queryDB 02')
    queryDB({ model: 'User', query: {}}, (items, err) => {
      const newArr = Object.assign({}, ...items.flatMap(({_id,local})=>({[_id]:local})))
      dynamicMapData(newArr, teamMember, setTeamMember, "teams")
      setState(p=>({...p,refreshActive: false}))
    })
  }
  
  const DivisionData = divisionMap.get(DivisionID);
  const handleClick = () => setOpen(!open) ;

  useEffect(()=>{
    if(DivisionData && ProjectData){
      console.log('Trigger queryDB 03')
       queryDB({model:'AdminOrg',query:{'fieldSet':{'$in':[`${DivisionData?.category}Services`,`${DivisionData?.category}Production`]}},filter:{'_id':0,'fieldSet':0,'Number':0}},([items,items2],err)=>{ 
    if(items2) items = Object.assign(items,items2) 
      setState(p=>({...p,
        projList: projectLists(items,ProjectData)
      }))
    })
  }
  },[DivisionData,ProjectData]);

  return <Paper component="form" variant="outlined" sx={{ p: '2px 4px',m:'1% 1% 0', display: 'flex', position:'sticky', bottom: '.5%', flexDirection:'column', alignItems: 'center', width: '97%', zIndex:9999 }} >
      <Box sx={{display: 'flex',w:'100%'}}>
       <Box><IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <InputBase
        ref={module}
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search"
        inputProps={{ 'aria-label': 'search' }}
        onChange={({target})=> setState(p=>({...p, search: target.value?.length>0 ? target.value : undefined })) }
      />
     {stateRef?.current?.refreshActive === true ? waveLoad() : <IconButton onClick={getAllMembers}><RefreshIcon/></IconButton>}</Box>
     { stateRef.current.search &&
      [<Divider variant="middle" flexItem orientation="vertical" />,
      <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
        <CancelIcon onClick={()=>{ module.current.querySelector('input').value = ''; setState(p=>({...p, search: undefined })) }} />
      </IconButton>] }
      </Box>
      { stateRef.current.search && [<Divider />,
      <List
      sx={{ width: '100%', bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader id="nested-list-subheader">
         { stateRef.current.search }
        </ListSubheader>
      }
    >
     { list && Object.entries(list)
     .filter(([k,v],i)=> v.some((userID)=> teamMember.has(userID) && Object.values(teamMember.get(userID)).filter(v=>v?.constructor === String).map(v=>v.toLowerCase()).some(x=>x.startsWith(stateRef.current.search.toLowerCase()))))
     .flatMap(([k,v],i)=>{

    return [<ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <SegmentIcon />
        </ListItemIcon>
        <ListItemText primary={k} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>,
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List disablePadding sx={{pl:'15%'}}>
        {
        v.filter((userID)=> teamMember.has(userID) && Object.values(teamMember.get(userID)).filter(v=>v&&v?.constructor === String).map(v=>v.toLowerCase()).some(x=> x.startsWith(stateRef.current.search.toLowerCase())) )
        .flatMap((userID,i)=>{
        var {firstName, lastName} = teamMember.get(userID)
        var label = firstName+' '+lastName
        return <ListItemButton role={undefined} dense>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={Object.keys(outputRef.current).includes(userID)}
                  tabIndex={-1}
                  disableRipple
                onClick={()=>{
                  if(Object.keys(outputRef.current).includes(userID)) setOutput(p=> Object.assign({},...Object.entries(p).filter(([k,v])=>k!==userID).map(([k,v])=>({[k]:v})))) // setParentState(p=>Object.entries(p).filter(([k,v])=>k!==y.avatar ))
                  else setOutput(p=>({...p, [userID]:[]})) 
                }} 
                />
              </ListItemIcon>
              <ListItemText 
             primary={label}
             secondary={ 'Search Relivance: '+ teamMember.has(userID) && Object.values(teamMember.get(userID)).filter(v=>v&&v?.constructor === String).map(v=>v.toLowerCase()).find(x=> x.startsWith(stateRef.current.search.toLowerCase())) }
               />
               <ListItem sx={{maxWidth:250,flex:1}}>
                <UserFilters {...props} userID={userID} projList={stateRef.current.projList}/>
              </ListItem> 
            </ListItemButton>
        }) }
        </List>
      </Collapse>]
     }) }
    </List>] }
    </Paper>
}