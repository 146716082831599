  import { useEffect } from "react";
  import { atom, useAtom, createStore as JotaiStore  } from 'jotai';
  import { atomWithStorage, createJSONStorage } from 'jotai/utils';

  import { MMKV } from 'react-native-mmkv'

  import useState from 'react-usestateref';
  import localForage from 'localforage';
  export var userStore = localForage.createInstance({ name: "GeneralEKC" });

  export const storage = new MMKV();
  export const jotaiStore = JotaiStore();

  export function atomWithBroadcast(key, iV) {

    const baseAtom = atom(iV);
    const listeners = new Set();
    const channel  = new BroadcastChannel(key);
    channel.onmessage = e => listeners.forEach(l => l(e));
    
    const broadcastAtom = atom(
      get => get(baseAtom),
      (get, set, {value,isEvent}) => {
        if(value instanceof Function){
          var update = value(get(baseAtom))
          console.log('Im a func',key,update, value)
         // set(baseAtom, update);
          // channel.postMessage(get(baseAtom));
        }else {
          var {k,v,t} = value;
          try {
            get(baseAtom)?.[t]?.(k,v);
            var update = new Map(get(baseAtom));
          } catch (error) {
            console.log('Update Err:',error,'||',key,'||',get(baseAtom),k,v,t);
            var update = get(baseAtom);
          }
        
          set(baseAtom, update);
          if(!isEvent) channel.postMessage(value);
        }
       
       // console.log('broadcastAtom:', value)
        
        // channel.postMessage(get(baseAtom));
      }
    );

    broadcastAtom.onMount = setAtom => {
      const listener = ({data}) => setAtom({ isEvent: true, value: data });
      listeners.add(listener);
      return () => {channel.close(); listeners.delete(listener);}
    };

    return atom(
      get => get(broadcastAtom),
      (get, set, update) => set(broadcastAtom, { isEvent: false, value: update })
    );
  }

export const userMapState = atomWithBroadcast("userMap",new Map()); 
export const sessionIDState = atomWithBroadcast("sessionID",new Map());

export const appSocketValidateState = atom(new Map()); 
export const appSocketQueryState = atom(new Map()); 
export const appSocketEOSState = atom(new Map()); 

export const searchState = atomWithBroadcast("searchState",new Map()); 

export const appState = atom(new Map()); 

export const eosState = atom(new Map()); 

export const listColumnsState = atomWithBroadcast("listColumns",new Map()); 

export const projectKeysState = atomWithBroadcast("projectKeys",new Map()); 

export const projectServicesState = atomWithBroadcast("projectServices",new Map()); 

export const CellFocus = atomWithBroadcast("cellFocus",new Map());  

export const aMarker = atomWithBroadcast("aMarker",new Map()); 

export const perfLablesMap = atomWithBroadcast("perfLables",new Map()); 

export const popupState = atom({open:false}); 

export const popupState2 = atom({open:false}); 

export const popupState3 = atom({open:false}); 

export const popupState4 = atom({open:false}); 

export const menuItemState = atomWithBroadcast("menuItems",new Map()); 

export const headerPopupState = atom(new Set()); 

export const chatterState = atomWithBroadcast("chatter",new Map());

export const scheduleState = atomWithBroadcast("schedule",new Map()); 

export const divisionMapState = atomWithBroadcast("divisionMap",new Map()); 
 
export const divisionFilterMapState = atomWithBroadcast("divisionMapFilter",new Map()); 

export const projectFilterMapState = atomWithBroadcast("projectMapFilter",new Map()); 

export const projectMapState = atomWithBroadcast("projectMap",new Map());

export const columnStatsState = atomWithBroadcast("columnStats",new Map());

export const projectsStatsState = atomWithBroadcast("projectStats",new Map());

export const nonNegotiableState = atomWithBroadcast("nonNegotiable", {reInit: false})

export const teamLoggState = atom(new Set());

export const teamMemberState = atomWithBroadcast("teamMemeber",new Map()); 

export const teamMemberMap = atomWithBroadcast("teamMemeberMap",new Map()); 

export const teamFollowState = atomWithBroadcast("teamFollow",new Map());

export const projFollowState = atom(new Set()); 

export const projectLoggMapState = atomWithBroadcast("projectLoggMap",new Map()); 

export const productMapState = atomWithBroadcast("productMap",new Map());

export const vendorMapState = atomWithBroadcast("vendorMap",new Map()); 

export const inventoryMapState = atomWithBroadcast("inventoryMap",new Map());

export const leaderBoardState = atomWithBroadcast("leaderBoard",new Map()); 

export const messagesState = atomWithBroadcast("messages",new Map()); 

export const googleEventState = atom(new Set()); 

export const activeSessionState = atomWithBroadcast("activeSession",new Map()); 

export const notificationState = atomWithBroadcast("notifications",new Map());

// OTHER ITEMS ///

export const useKeyPress = targetKey => {
  const [, setKeyPressed, keyPressedRef] = useState(false);

  const downHandler = ({ key }) => {
    if (key === targetKey) setKeyPressed(true);
  };

  const upHandler = ({ key }) => {
    if (key === targetKey) setKeyPressed(false);
  };

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);

  return keyPressedRef.current;
};

export const setSessionStorage=(data)=>{
  const {localStorage} = window
  if(data === 'clear'){
    localStorage.clear();
     userStore.removeItem('session');
  }else localStorage.setItem("session",data.secret)
};

export const Sessions=()=> new Promise((resolve) => {
const {location,history,localStorage} = window
var query = {}
var urlPass = Object.fromEntries(new URLSearchParams(location.search))
if(Object.keys(urlPass).length>0) query.query = urlPass

var x = localStorage.getItem('sessionID');
resolve( {...query,sessionID:x||false,sessionType:x?'session':false})
history.pushState({}, document.title, "/")
});

export const useIsMobile =(x)=> {
  const getIsMobile = () => window.innerWidth <= x;
  const [isMobile, setIsMobile] = useState(getIsMobile())
    useEffect(() => {
        const onResize = () => setIsMobile(getIsMobile())
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize)
    }, [])
   return isMobile
};

export function useWidthHeight(x){
  const getWidth = () => window.innerWidth 
  || document.documentElement.clientWidth 
  || document.body.clientWidth;

  const getHeight = () => window.innerHeight 
  || document.documentElement.clientHeight 
  || document.body.clientHeight;

 const IsMobileWidth = () => widthRef.current <= x;
 const IsMobileHeight = () => heightRef.current <= x;

  let [width, setWidth, widthRef] = useState(getWidth());
  let [height, setHeight, heightRef] = useState(getHeight());
  let [mWidth, setMWidth, mWidthRef] = useState(IsMobileWidth());
  let [mHeight, setMHeight, mHeightRef] = useState(IsMobileHeight());

  useEffect(() => {
    const resizeListener = () => { 
      setWidth(getWidth());
      setHeight(getHeight());
      setMWidth(IsMobileWidth());
      setMHeight(IsMobileHeight());
       };
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, [])
return {width:widthRef.current,height:heightRef.current,mw:mWidthRef.current,mh:mHeightRef.current};
};

export const useIsMobileVert =(x)=> {
  const getIsMobile = () => window.innerHeight <= x;
  const [isMobile, setIsMobile] = useState(getIsMobile())
    useEffect(() => {
        const onResize = () => setIsMobile(getIsMobile())
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize)
    }, [])
   return isMobile
};

export const closePopup =({container:x,target:e},callBK)=>{
    if(e!==undefined && x!==null && x!==undefined && !x.contains(e)){
      window.removeEventListener('mousedown', closePopup)
      callBK(true,e)
    }else if(x!==null && e===undefined) window.addEventListener('mousedown', (e)=>closePopup({container:x,target:e.target},callBK))
}

export function useScroll() {
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);

  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [clientWidth, setClientWidth] = useState(0);

  const onScrollHandler = (event) => {
    setScrollTop(event.target.scrollTop);
    setScrollHeight(event.target.scrollHeight);
    setClientHeight(event.target.clientHeight);

    setScrollLeft(event.target.scrollLeft);
    setScrollWidth(event.target.scrollWidth);
    setClientWidth(event.target.clientWidth);
  };

  function getBoxShadow() {
    const isTop = scrollTop === 0;
    const isBetween = scrollTop > 0 && clientHeight < scrollHeight - scrollTop;
    const isBottom = clientHeight <= (scrollHeight - scrollTop)+10;
    var percent = parseInt(((((clientHeight / scrollHeight - scrollTop)*-1)/(scrollHeight-clientHeight))*100).toFixed(0),10)
    var fadeFallPre = percent < 10 ? percent : 10
    var fadeFallPost = percent > 90 ? percent : 90

    if(scrollHeight===0&&clientHeight===0&&scrollTop===0) return {percent:false,style:{boxShadow:"unset",WebkitMaskImage:"unset"}}
    let boxShadow = {percent:100,style:{boxShadow:"inset 0 50px 25px -25px rgb(0 0 0 / 25%)"}}
    if (isTop) boxShadow = {percent:0,style:{boxShadow:"inset 0 -50px 25px -25px rgb(0 0 0 / 25%)",'WebkitMaskImage':'linear-gradient(180deg,#000 95%,transparent 100%)'}}
    else if (isBetween) boxShadow = {percent:percent,style:{boxShadow:"inset 0 -50px 25px -25px rgb(0 0 0 / 25%),inset 0 50px 25px -25px rgb(0 0 0 / 25%)",WebkitMaskImage:`linear-gradient(180deg,#000 ${fadeFallPost}%,transparent 100%)`}} // transparent 0%,#000 ${fadeFallPre}%,
    else if (isBottom) boxShadow = {percent:100,style:{boxShadow:"inset 0 50px 25px -25px rgb(0 0 0 / 25%)"}}
    return boxShadow;
  }

  function getBoxShadowHoz() {
    const isLeft = scrollLeft === 0;
    const isHorzBetween = scrollLeft > 0 && clientWidth < scrollWidth - scrollLeft;
    const isRight = clientWidth <= (scrollWidth - scrollLeft)+10;
    var percent = parseInt(((((clientWidth / scrollWidth - scrollLeft)*-1)/(scrollWidth-clientWidth))*100).toFixed(0),10)
    var fadeFallPre = percent < 25 ? percent : 25
    var fadeFallPost = percent > 75 ? percent : 75
    if (scrollLeft===0&&clientWidth===0&&scrollWidth===0) return {percent:false,style:{boxShadow:"unset",WebkitMaskImage:"unset"}}
    let boxShadow = {percent:100,style:{boxShadow:"inset -50px 0 50px -50px rgb(0 0 0 / 25%)"}}
    if (isLeft) boxShadow = {percent:0,style:{boxShadow:"inset -50px 0 50px -50px rgb(0 0 0 / 25%)",'WebkitMaskImage':'linear-gradient(70deg,#000 70%,transparent 100%)'}}
    else if (isHorzBetween) boxShadow = {percent:percent,style:{pl:'0!important',boxShadow:"inset -50px 0 50px -50px rgb(0 0 0 / 25%),inset 50px 0 50px -50px rgb(0 0 0 / 25%)",WebkitMaskImage:`linear-gradient(90deg,transparent 0%,#000 ${fadeFallPre}%,#000 ${fadeFallPost}%,transparent 100%)`}}
    else if (isRight) boxShadow = {percent:100,style:{pl:'0!important',boxShadow:"inset 50px 0 50px -50px rgb(0 0 0 / 25%)",WebkitMaskImage:'linear-gradient(90deg,transparent 0%,#000 20%)'}} //inset -50px 0 50px -50px rgb(0 0 0 / 25%)
    return boxShadow;
  }

  return { scrollStat: getBoxShadow(), scrollStatHorz: getBoxShadowHoz(), onScrollHandler };
}


export function memorySizeOf(obj) {
  var bytes = 0;

  function sizeOf(obj) {
      if(obj !== null && obj !== undefined) {
          switch(typeof obj) {
          case 'number':
              bytes += 8;
              break;
          case 'string':
              bytes += obj.length * 2;
              break;
          case 'boolean':
              bytes += 4;
              break;
          case 'object':
              var objClass = Object.prototype.toString.call(obj).slice(8, -1);
              if(objClass === 'Object' || objClass === 'Array') {
                  for(var key in obj) {
                      if(!obj.hasOwnProperty(key)) continue;
                      sizeOf(obj[key]);
                  }
              } else bytes += obj.toString().length * 2;
              break;
          }
      }
      return bytes;
  };

  return {fb: formatByteSize(sizeOf(obj)),val:sizeOf(obj)};
};

export function formatByteSize(bytes) {
  if(bytes < 1024) return bytes + " bytes";
  else if(bytes < 1048576) return(bytes / 1024).toFixed(2) + " KiB";
  else if(bytes < 1073741824) return(bytes / 1048576).toFixed(2) + " MiB";
  else return(bytes / 1073741824).toFixed(2) + " GiB";
};

export function objectDeepKeys(obj){
  return Object.keys(obj)
    .filter(key => obj[key] instanceof Object)
    .map(key => objectDeepKeys(obj[key]).map(k => `${key}.${k}`))
    .reduce((x, y) => x.concat(y), Object.keys(obj))
};


export const groupByKey = (data, key) => Object.values(
  data.reduce((res, item) => {
   const value = item?.[key]
   const existing = res[value] || {[key]: value, _id:item?._id, data:[]}
   return {...res,[value] : {...existing,data: [...existing.data, item] }
   } 
  }, {})
);

export const textColor = (color)=> {
  if (color.length == 7) {
    const rgb = [ parseInt(color.substring(1, 3), 16), parseInt(color.substring(3, 5), 16), parseInt(color.substring(5), 16)];
    const luminance = (0.2126 * rgb[0]) / 255 + (0.7152 * rgb[1]) / 255 + (0.0722 * rgb[2]) / 255;
    return luminance > 0.5;
  }
  return false;
};

export const Searcher = ({sharedworker})=>{
  const [projectMap] = useAtom(projectMapState);
  const [inventoryMap] = useAtom(inventoryMapState);
  const [teamMember] = useAtom(teamMemberState);
  const [divisionMap] = useAtom(divisionMapState);
  const [productMap] = useAtom(productMapState);
  const [app] = useAtom(appState); // setApp
  const [searchState] = useAtom(searchState);
  
  return null
};