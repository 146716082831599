    import React, {   useEffect, useRef } from "react";
    import { useAtom } from 'jotai';
    import useState from 'react-usestateref';
    import BarcodeReader from 'react-barcode-reader'
    import '../public/scripts/css/eos.css';
    import { Box, Button } from '@mui/material';
    import Quagga from '@ericblade/quagga2';

export const Component_scanner = ({That,globalState,action,handlePopupClose,viewData:{manualScan, numPopup},waveLoad,dynamicComponent}) =>{
const mainState = useState(Object)
const outputState = useState(Object)
const [, setOutput, outputRef] = outputState
const [, setThat, thatRef] = That
const [popupData, setPopup] = useAtom(globalState.popupState)
const [popupData4, setPopup4] = useAtom(globalState.popupState4)
const scannerRef = useRef(null)
const [, setScanning, scanningRef] = useState(false);

const closePopup4=()=>{
  setPopup4(p=>({...p,...{
    open:false,
  }}))
}

const manualAdd=()=>{
  if(numPopup === true)
  {
    setPopup4(p=>({...p,...{
      open:true,
                  title:`Manually Add Barcode`,
                  description: 'Scan with scanner or add manually.',
                  inputField: [
                    dynamicComponent('InputField','elements',{state:'manualAdd',label:`Add Default Scan`,type:'text'},mainState,[({manualAdd})=>{
                    if(manualAdd.length>0) setPopup4(p=>({...p,...{
                        handleSubmit:()=>{ 
                          action?.forEach((x)=>x({SerialNumber:manualAdd}))
                          closePopup4()
                         }, 
                        submitLabel:`Add Default "${manualAdd}"`,
                      }})) 
                      else if(manualAdd.length===0 && popupData4.handleSubmit ) setPopup4(p=>({...p,...{handleSubmit: undefined }}))
                    }])
                  ],
                  handleClose: ()=>closePopup4(),
                  nameInputSets: {},
    }}))
  }
  else
  {
  setPopup(p=>({...p,...{
                  open:true,
                  title:`Manually Add Barcode`,
                  description: 'Scan with scanner or add manually.',
                  inputField: [
                    dynamicComponent('InputField','elements',{state:'manualAdd',label:`Add Default Scan`,type:'text'},mainState,[({manualAdd})=>{
                    if(manualAdd.length>0) setPopup(p=>({...p,...{
                        handleSubmit:()=>{ 
                          action.map((x)=>x({SerialNumber:manualAdd}))
                          handlePopupClose()
                         }, 
                        submitLabel:`Add Default "${manualAdd}"`,
                      }})) 
                      else if(manualAdd.length===0 && popupData.handleSubmit ) setPopup(p=>({...p,...{handleSubmit: undefined }}))
                    }])
                  ],
                  handleClose: ()=>handlePopupClose(),
                  nameInputSets: {},
  }}))
  }
}

const mobileScanRes = ({codeResult}) =>{ 
  if(codeResult.code) handleScanMulti({[thatRef.current.userFilter ? thatRef.current.userFilter[thatRef.current.cardPlace] :['SerialNumber']]:codeResult.code})
}
const handleScanMulti=(data)=>{
 setThat(p=>({...p,...{cardPlace: thatRef.current.cardPlace+1 }}))
 setOutput(p=>({...p,...data}))
}

const equals = (a, b) =>
  a.length === b.length &&
  a.every(e=>b.includes(e))

useEffect(()=>{
if(popupData.handleScanMulti){  
  handleScanMulti(popupData.handleScanMulti)
  handlePopupClose()
}
},[popupData.handleScanMulti])

 useEffect(() => {
  var comparable = thatRef.current.userFilter ? thatRef.current.userFilter : ['SerialNumber']
  if(equals(comparable,Object.keys(outputRef.current))) action?.forEach((x)=>x({...outputRef.current},(x)=> setOutput({}) ))
 },[outputRef.current])

return <Box sx={{gap:'5px', display:'flex',flexDirection:'column',padding:'0 4% 4%'}}>
  <BarcodeReader 
  tabIndex={0}
  onError={(e)=>{ 
    console.log('Scanner Sucks and errored out',e) 
    e.length > 1 && handleScanMulti({[thatRef.current.userFilter ? thatRef.current.userFilter[thatRef.current.cardPlace] :['SerialNumber']]:e})
  }} 
  onScan={(e)=> handleScanMulti({[thatRef.current.userFilter ? thatRef.current.userFilter[thatRef.current.cardPlace] :['SerialNumber']]:e})} /*timeBeforeScanTest={50}*/ />
{manualScan ? <Button variant="contained" fullWidth onClick={()=>manualAdd()}>Add Manually</Button> : null}
<Button variant="contained" fullWidth onClick={() => setScanning(p=>!p) }>{scanningRef.current?'Stop Camera Scan':'Camera Scan'}</Button>
{ scanningRef.current ? <Box ref={scannerRef}>
        <canvas className="drawingBuffer" style={{ position: 'absolute' }} width={scannerRef.current?.getBoundingClientRect()?.width} height={scannerRef.current?.getBoundingClientRect()?.height} />   
        {scanningRef.current ? <Scanner scannerRef={scannerRef} onDetected={mobileScanRes} waveLoad={waveLoad} /> : null}
    </Box>
: null}
</Box>
}

const defaultDecoders = ['ean_reader'];

export const Scanner = ({
  onDetected,
  scannerRef,
  waveLoad,
  cameraId,
  facingMode,
  constraints = {
    width: { min: 288, max: scannerRef.current?.getBoundingClientRect()?.width },
    height: { min: 100, max: 300 },
  },
  numOfWorkers = navigator.hardwareConcurrency || 0,
  decoders = defaultDecoders,
  locate = true,
}) => {
  const [/*old*/, setReady, readyRef] = useState({ready:false,setable:true});

  const resultCanvas = (result) => {
    const drawingCtx = Quagga.canvas.ctx.overlay;
    const drawingCanvas = Quagga.canvas.dom.overlay;
    drawingCtx.font = "24px Arial";
     drawingCtx.fillStyle = 'green';
    if (result.boxes) {
        drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute('width')), parseInt(drawingCanvas.getAttribute('height')));
        result.boxes.filter((box) => box !== result.box).forEach((box)=> Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: 'purple', lineWidth: 2 }) )
    }
    if (result.box) Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: 'blue', lineWidth: 2 })
    if (result.codeResult && result.codeResult.code) {
        drawingCtx.font = "24px Arial";
        drawingCtx.fillText(result.codeResult.code, 10, 20);
        setTimeout(() => {
          drawingCtx.clearRect(0, 0, 0, 0)
          drawingCtx.fillText('', 10, 20);
        }, 3000);
    }

}

useEffect(() => {
  if (navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === 'function') {
  Quagga.init({
      inputStream: {
        type: 'LiveStream',
        constraints: {
          ...constraints,
          ...(cameraId && { deviceId: cameraId }),
          ...(!cameraId && { facingMode }),
        },
        target: scannerRef.current,
      },
      frequency: 'full',
      locator: {
        patchSize: 'x-large',
        halfSample: false,
      },
      numOfWorkers,
      decoder: { readers: decoders },
      locate,
    },
    (err) => {
      if (err) return console.log(err);
      Quagga.start();
      setReady(p=>({...p,...{  
        ready:true
      }}))
    }
  );
  Quagga.onDetected((result)=>{
    if(result){
      resultCanvas(result)
      if(readyRef.current?.setable) {
        setReady(p=>({...p,...{ setable: false }}))
        onDetected(result)
        setTimeout(() => setReady(p=>({...p,...{ setable: true }})), 5000);
      }
      
    }});
  Quagga.onProcessed((result)=>{
    if(result){
      resultCanvas(result)
    }});
  }

  return () =>{ Quagga.stop() }
}, []);

  return readyRef.current?.ready ? null : waveLoad()
}
