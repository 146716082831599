    import React, { useEffect, useCallback } from "react";
    import { useAtom, useAtomValue } from 'jotai';
    import useState from 'react-usestateref';
    import {ToggleButton,ToggleButtonGroup,Typography,Grow, Divider} from "@mui/material";

export const Component_statsSelect = ({globalState,dbUpdateValidate,sharedworker,dynamicMapData,waveLoad,queryDB}) =>{
const divisionMap = useAtomValue(globalState.divisionMapState);
const [, setProjState, {current: projStateRef}] = useState(Object);
const [, setAppState] = useAtom(globalState.appState);
const divisionFilterMap = useAtomValue(globalState.divisionFilterMapState);
const projectFilterMap = useAtomValue(globalState.projectFilterMapState);
const listColumnsMap = useAtomValue(globalState.listColumnsState);
const columnStatsMap = useAtomValue(globalState.columnStatsState);
const projectStats = useAtomValue(globalState.projectsStatsState);
const [projectMap, setProjectMap] = useAtom(globalState.projectMapState);
const [UserMap,setUserMap] = useAtom(globalState.userMapState);
const [, setAlignment, {current: alignmentRef}] = useState(UserMap.get('pageStamp')[UserMap.get('pageStamp').active]);
const { scrollStatHorz, onScrollHandler } = globalState.useScroll();

const CompletedProjects = useCallback((val) =>{
  var someCompletedProj = Array.from(projectMap.values()).some(({Status}) =>['Completed'].includes(Status))

  if(val==='Completed'&&!someCompletedProj){
    var divisions1 = [].concat(...Array.from(divisionFilterMap,([k,v])=>{
      if(["ChromeBook","Corporate","Sales","Construction","AV"].includes(k)) return []
      if(v===true) return divisionMap.get(k)?.teamName
      return []
    }))
    var divisions2 = [].concat(...Array.from(divisionFilterMap,([k,v])=>{
      if(["ChromeBook","Corporate","Sales","Construction","AV"].includes(k)) return []
      if(v===false) return divisionMap.get(k)?.teamName
      return []
    }))
    divisions1.forEach(x=>
     queryDB({model:'Projects',modelType:x,query:{Status:'Completed'}},(items)=>
     items.forEach(data=> dynamicMapData({[data._id]: data}, projectMap, setProjectMap) )
    ))
    divisions2.forEach(x=>
      queryDB({model:'Projects',modelType:x,query:{Status:'Completed'}},(items)=>
      items.forEach(data=> dynamicMapData({[data._id]: data}, projectMap, setProjectMap) )
     ))
  }
  else if(val!=='Completed'&&someCompletedProj) Array.from(projectMap.entries()).some(([k,{Status}]) =>{
     if(['Completed'].includes(Status)) setProjectMap({t:'delete',k})
    })
},[projectMap,divisionFilterMap,divisionMap,dynamicMapData,setProjectMap])

const handleChange = (e,val) =>{
  if(val === null) return
  CompletedProjects(val)
  setAlignment(val);
  // setAppState(p => { p.set('projectsFilterType', filtered); return new Map(p)}) 
  setUserMap({t:'set',k:'pageStamp',v:{...UserMap.get('pageStamp'),[UserMap.get('pageStamp').active]:val}})
  // Object.entries(filtered).map(([k,v])=>setProjectFilterMap({t:'set',k,v}))
  dbUpdateValidate({model:'User',query:true,update:{[`local.pageStamp.${UserMap.get('pageStamp').active}`]:val}})
  sharedworker.postMessage({type:'filterProjects2',pageStamp:UserMap.get('pageStamp')})
};

 /* 
 const userSpecific = useCallback(({DivisionID,ProjectID,ProjectState,SalesState}) =>{ 
  var userLevel = UserMap.get('level');
  var userAvatar = UserMap.get('avatar');

  if(userLevel<3) return true
  if(userLevel===5 && UserMap.get('division')===DivisionID) return true
  if(userLevel=== ProjectState?.projManager || userLevel=== ProjectState?.teamLead) return true
  if(userLevel===7 && ProjectState?.Paused?.some(x=> !x.endDate && x.startDate)) return false
  if(SalesState?.partnerID?.includes(userAvatar) || SalesState?.clientID?.includes(userAvatar)) return true
  try{ 
    Object.entries(projectStats.get(DivisionID)?.teamsAssign[ProjectID])?.some(([k,v])=>k===userAvatar) 
  }catch(e){ return false }
  return false
},[UserMap,projectStats]);

 const catDefine = useCallback(()=>{
    var active = UserMap.get('pageStamp')?.active
    var type = active !== 'Projects' ? active : active.slice(0, -1)
    var filterType = active === 'Sales' ? 'Sales' : 'Project'
    setAlignment(UserMap.get('pageStamp')[active])

    if(listColumnsMap.has(active)) listColumnsMap.get(active).flatMap((val,i2)=> setProjState(p=>({...p,
    [`total${val}`] : Array.from(projectFilterMap.entries()) 
    .filter(([k,v])=>userSpecific({...projectMap.get(k),ProjectID:k}))
    .filter(([k,v])=> val === 'All' && ['Sales'].includes(type) ? true : v === true).length,
    [`limited${val}`] : Array.from(projectFilterMap.entries()) // filterProj(projectMap,val,filterType)
    .filter(([k,v])=>userSpecific({...projectMap.get(k),ProjectID:k}))
    .filter(([k,v])=> divisionFilterMap.get(projectMap.get(k)?.DivisionID) )
    .filter(([k,v])=> val === 'All' && ['Sales'].includes(type) ? true : v === true).length
    })))
},[UserMap,projectMap,listColumnsMap,projectFilterMap])

useEffect(catDefine,[divisionFilterMap,UserMap.get('pageStamp')?.active,UserMap.get('pageStamp')?.[UserMap.get('pageStamp')?.active],projectMap.size, listColumnsMap]); */

return <ToggleButtonGroup color={"secondary"} value={alignmentRef} size="small" exclusive onChange={handleChange} onScroll={onScrollHandler} sx={{width:'99.25%',mt:'clamp(3px, 0.35%, 7px)',p:'.25%',backdropFilter:'blur(15px)',backgroundColor:'rgba(18,18,28,.2)',border:'1px solid rgba(18,18,18,0.05)',overflow:'auto',...scrollStatHorz.style}} >
  { listColumnsMap.get(UserMap.get('pageStamp')?.active)?.flatMap((x,i,a)=>{
    if(x==='DIVIDER') return <Divider orientation="vertical" variant="middle" flexItem sx={{m:'auto 1%'}}/>
    var total = columnStatsMap.has(`limited${x}`) && columnStatsMap.get(`limited${x}`) !== 0 && columnStatsMap.get(`limited${x}`) !== columnStatsMap.get(`total${x}`) ? 
    <Typography variant="body1" sx={{fontWeight: 900,color:'#ff9d15',lineHeight:1}}>({columnStatsMap.get(`limited${x}`)}/{columnStatsMap.get(`total${x}`)}) {x}</Typography> : 
    <Typography variant="body2" sx={{fontWeight: 900, color:'#ffffff',lineHeight:1}}>({columnStatsMap.get(`total${x}`)}) {x}</Typography>
    
    return <Grow in={true} key={`categoriesGrow-${i}`} timeout={{ enter: (i+1)*500 }} unmountOnExit>
      <ToggleButton value={x} sx={{flex:1,padding:'6px', textShadow: '.5px .5px rgb(0 0 0 / 65%)', borderLeft: a[i-1]==='DIVIDER' ? '1px solid rgba(255, 255, 255, 0.12)!important' : '', boxShadow: alignmentRef === x ? 'inset -0.5px -0.5px 0 rgb(50 167 226 / 65%), inset 0.5px 0.5px rgb(50 167 226 / 65%)' : 'unset', backgroundColor: alignmentRef === x ? 'rgba(50, 167, 226,.25)' : 'unset'}} >
      { alignmentRef === x ? total : <Typography variant="body2" sx={{fontWeight: 400, color:'#ffffff',lineHeight:1}}>{x}</Typography> } 
    </ToggleButton>
    </Grow>
}
) || waveLoad()}
</ToggleButtonGroup>
}