    import React, { useEffect, useRef, lazy } from "react";
    import { useAtom } from 'jotai';
    import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
    import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
    import useState from 'react-usestateref';

    import dayjs from 'dayjs';
    import { io } from "socket.io-client";

    import {Box, Fab} from '@mui/material';

var isToday = require('dayjs/plugin/isToday')
dayjs.extend(isToday)

export const Component_fillProj = props =>{
 const{closeSnackbar,queryDB,globalWrn,dynamicComponent,globalState,viewData,userCount,projFollow,dynamicMapData} = props
 const mainState = useState({cardPlace:0})
 const [, setState, stateRef] = mainState
 const [UserMap] = useAtom(globalState.userMapState)
 const [projectLoggMap,setProjectLoggMap] = useAtom(globalState.projectLoggMapState)
 const [, setGlobalAppState] = useAtom(globalState.appState)
 const [divisionMap] = useAtom(globalState.divisionMapState)
 const [projectMap] = useAtom(globalState.projectMapState)
 const [appState, setAppState] = useAtom(globalState.appState)
 const [, setPopup] = useAtom(globalState.popupState)
 const comp = useRef(null)

 const [eosSocket,setEosSocket] = useAtom(globalState.eosState)
 var EOSsocketIO = eosSocket.get('EOSsocketIO');

 function playSound(x) {
  var sound;
  if(x==='errorSound') sound = new Audio('https://dev-services.ekc.app/scripts/audio/error.mp3')
  if(x==='counterSound') sound = new Audio('https://dev-services.ekc.app/scripts/audio/EOSscan.mp3')
  if(x==='successSound') sound = new Audio('https://dev-services.ekc.app/scripts/audio/EOSSucess.mp3')

  sound.play();

  sound.onended = function(){
      this.src = "";
      this.srcObject = null;
      this.remove();
  };
}


 const projID = viewData.projID
 const { ProjectID, DivisionID, modelType } = projID
 const ProjectData = projectMap.get(ProjectID)
 const DivisionData = divisionMap.get(DivisionID)

 useEffect(()=>{
 if(ProjectID && !Array.from([...projectLoggMap],([,v])=> v.some(()=>v.ProjectID===ProjectID)).some(x=>x)){
   queryDB({model:'Projects',modelType,idConvert:true,query:{"_id":ProjectID},filter:{'ProjectState.teamLogg':1}},([x],y)=>{
  if(x?.ProjectState){ 
    var {ProjectState} = x
    Promise.all(
      ProjectState?.teamLogg?.flatMap(({_id,logg})=>{
      logg.ProjectID = ProjectID
      return dynamicMapData({[_id]:logg},projectLoggMap,setProjectLoggMap,(x)=>{ /*setProjectLoggMap(p=>new Map(p))*/}) 
    })
    ).then(()=>projSteps())
  }
  })
}
 },[ProjectID])


 const PopStyle = {
  OverviewFull : {
    position: 'absolute',
    display: 'flex',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1000,
    backdropFilter: 'blur(15px)',
    backgroundColor: stateRef.current.warnColor||'inherit'
  },
  projectAssmbl : {
    minWidth: '50%',
    display: 'flex',
    columnGap: '8%',
    rowGap: '20px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: 'auto',
    maxHeight: '87vh'
  },
  speedRing:{
    position: 'relative',
    margin:'auto',
    flex:1,
    backgroundColor: 'rgba(18,18,18,.85)',
    borderRadius: '8px',
  }
  }  

  useEffect(() => { 
    globalState.Sessions().then(y=> setEosSocket(p=>{p.set('EOSsocketIO',io(`https://www.${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}eosservice.ekc.app`,{secure:true,transports: ['websocket'],query:y})); return new Map(p)})) 
   },[!eosSocket.has('EOSsocketIO')])

   useEffect(()=>{
    if(eosSocket.get('EOSsocketIO')?.connected&&appState.get('quedItems')>0) globalState.userStore.getItem('scanQue').then(x=>{ 
     if(x) Promise.all(
     x.flatMap(y=> socketSubmit(y,()=>{}))
     ).then(()=>
      globalState.userStore.removeItem('scanQue').then(async()=>{
        var updated = await globalState.userStore.getItem('scanQue')
        setAppState(p => { p.set('quedItems',0); return new Map(p)})
        globalWrn('success',`your Scan Que has been submited.`)
      })
    )
    })
  },[appState.get('quedItems'),eosSocket.get('EOSsocketIO')?.connected])

const ScanActioln=(data,callBK)=>{
if(ProjectData?.ProjectState?.DefaultScan !== undefined && data?.SerialNumber?.length > ProjectData?.ProjectState?.DefaultScan?.length+4){
  callBK(true)
  stateRef.current.scanListControls({SerialNumber:"Slow down there Speedy!!",label:`Your scans are doubling up ${data.SerialNumber}`})
  setState(p=>({...p,...{ warnColor: '#ec5252' }}))
  playSound('errorSound')
  setTimeout(()=>{ 
    setState(p=>({...p,...{warnColor:undefined,cardPlace:0}}))
  },2000)
}else{ 
if(stateRef.current.confirmOption) return setPopup(p=>({...p,...{
  open:true,
  zIndex: 2,
  title:`Confirm Submision`,
  description: 'Ensure the scan listed are correct. Edit if nessisary',
  inputField: <Box sx={{p:'1%',gap:'10px',display:'flex',flexDirection:'column'}}>{Object.entries(data).flatMap(([k,v])=> dynamicComponent('InputField','elements',{state:k,label:k,defaultValue:v,style:{margin:0}},mainState))}</Box>,
  cancelLabel: 'Clear & Retry',
  handleClose: ()=>{
    setState(p=>({...p,...{cardPlace:0}}))
    callBK()
    setPopup({open:false})
  },
  handleSubmit: ()=>{socketSubmit(data,callBK);setPopup({open:false})},
}}))

if(EOSsocketIO?.connected) socketSubmit(data,callBK)
else globalState.userStore.getItem('scanQue').then(x=>{ 
  if(x){ 
    globalState.userStore.setItem('scanQue',[...x,data])
    setAppState(p => { p.set('quedItems', [...x,data].length); return new Map(p)})
  }else{ 
    globalState.userStore.setItem('scanQue',[data])
    setAppState(p => { p.set('quedItems', 1); return new Map(p)})
  }
  setState(p=>({...p,...{cardPlace:0}}))
  callBK()
})
}
}

useEffect(() =>{ 
if(stateRef.current.projStepps) stateRef.current.projStepps(stateRef.current.cardPlace)
if(stateRef.current.projStepps && ![0,stateRef.current?.userFilter?.length+1].includes(stateRef.current.cardPlace)){
  if(stateRef.current.userFilter.length === stateRef.current.cardPlace && stateRef.current.cardPlace !== 0) globalWrn('warning',`↩︎ Restart back up to the First Step`)
  else if(stateRef.current.cardPlace !== 0) globalWrn('warning',`⇣ Please proceed to Step: ${stateRef.current.cardPlace+1}`)
  playSound('counterSound')
  setState(p=>({...p,...{ warnColor: '#ffd58b' }}))
  setTimeout(()=> setState(p=>({...p,...{warnColor:undefined}})) ,800)
}
},[stateRef.current.cardPlace])

useEffect(() =>{ 
var Component = lazy(() => import('../views/view_Signage').then(module => ({ default: module['SpeedRing'] })))
var todayLogs = ProjectData?.ProjectState?.teamLogg !== undefined && ProjectData?.ProjectState?.teamLogg.some(({date})=>dayjs(date).isToday()) ? ProjectData?.ProjectState.teamLogg.flatMap(()=> ProjectData.ProjectState.teamLogg.filter(({date})=> dayjs(date).isToday()).flatMap(({_id})=>_id)) : []
setState(p=>({...p,...{
  ProjectTitle : ProjectData.ProjectTitle,
  scannerField : dynamicComponent('scanner','component',{defaultNum:10,stateChg:['onClick'],state:'scanner',projID},mainState,[ScanActioln]),
  SpeedRing : <Component {...props} loggID={todayLogs} user={UserMap.get('avatar')} projOverview={true}/>,
  userListings : dynamicComponent('ScanList','elements',{projID:projID,scanListControls:(x)=>{ setState(p=>({...p,...{ scanListControls:x}}))} },mainState),
}}))
 return () =>{ projFollow({unfollow:[ProjectData.ProjectID]})}
},[stateRef.current.userFilter,appState.get('quedItems'),ProjectData])


function socketSubmit(data,callBK){
  globalWrn('info',`Submitting Scan... Please Wait`)
  EOSsocketIO.emit('ScanQuery',{
    model:modelType,
    query:{'_id':ProjectID},
    userFilter:{options:stateRef.current.userFilter,timestamp:new Date()},      
    update:{user:[UserMap.get('avatar')],data:data} 
    },(x)=>{
      callBK(x)
      setState(p=>({...p,...{cardPlace:0}}))
    if(x.valid===true){ 
      playSound('successSound')
      globalWrn('success',`Scan Accepted!!`)
      setState(p=>({...p,...{ warnColor: '#90ee90' }}))
      setTimeout(()=> setState(p=>({...p,...{warnColor:undefined}})) ,2000)
    }
    if(x.valid===false){ 
      globalWrn('error',`There was an issue!!`)
      playSound('errorSound')
      stateRef.current.scanListControls({...data,label:x.type.replace(/[A-Z]/g, ' $&').trim()})
      setState(p=>({...p,...{ warnColor: '#ec5252' }}))
      setTimeout(()=> setState(p=>({...p,...{warnColor:undefined}})) ,2000)
    }
    })
}

useEffect(() => { 
 setState(p=>({...p,...{
  userFilter: ProjectData?.ProjectState?.Members?.some(({id,userFilter})=>id===UserMap.get('avatar')&&userFilter) ? ['SerialNumber', ...ProjectData?.ProjectState?.Members?.find(({id})=>id===UserMap.get('avatar'))?.userFilter] : ['SerialNumber'],
  }}))
projSteps()
},[UserMap.get('avatar'),ProjectData])

useEffect(()=>{
setState(p=>({...p,...{UserCount: userCount(UserMap.get('avatar'))?.[0]?.todayLng?.[0] | 0 }}))
},[projectMap,projectLoggMap])

useEffect(() =>{ 
  if(!EOSsocketIO) return

  EOSsocketIO.on('session',(data)=>{
    //data.label = 'EOSsession'
   // setSessionStorage(data)
  })
  EOSsocketIO.on('connect',()=>{ 
   if(stateRef.current.socketDisconnect){
    closeSnackbar(stateRef.current.socketDisconnect)
    setState(p=>({...p,...{socketDisconnect:undefined}}))
    globalWrn('success','Your Network Connection to EOS Serivces has Reestablished.')
  }else globalWrn('success','You have now connected to EOS Serivces.')
  })
  EOSsocketIO.on('disconnect', err =>{ 
   globalWrn('error',`EOS Serivces has disconnected due to your network!`,{persist: true,action:(x)=>setState(p=>({...p,...{socketDisconnect:x}})) })
  })
},[EOSsocketIO?.connected]);

const gatherList = async() => await new Promise((resolve) => queryDB({model:'AdminOrg',query:{'fieldSet':{'$in':[ `${DivisionData.category}Services`,`${DivisionData.category}Production`]}},filter:{'_id':0,'fieldSet':0,'Number':0}},(items,err)=>{ 
  if(stateRef.current?.userFilter || ProjectData.SalesState?.OptionsFilter) var listFilter = stateRef.current.userFilter && stateRef.current.userFilter.length>0 ? stateRef.current.userFilter : ['SerialNumber']

  return resolve( items.flatMap(x=>Object.entries(x)
  .filter(([k, v],i) => listFilter ? listFilter.includes(k) : false )
  .map(([k, v],i) =>({[k]: Object.entries(v)
  .map(([k2, v2],i2) =>(ProjectData.SalesState?.OptionsFilter?.[k]?.[0] ? {...ProjectData.SalesState.OptionsFilter[k][0],...v2}: v2) ) }))))
}) )

const projSteps = async() => {
var preList = await gatherList()
if(stateRef.current.userFilter) preList =
Object.entries(preList).sort(([,v],[,v2]) => {
  var a = stateRef.current.userFilter.indexOf(Object.keys(v)[0])
  var b = stateRef.current.userFilter.indexOf(Object.keys(v2)[0]) 
  return a < b ? -1 : a > b ? 1 : 0;
}).flatMap(([k,v])=>v)
setState(p=>({...p,...{ preList: preList }}))
}

useEffect(()=>{
  if(stateRef.current.preList) setState(p=>({...p,...{ 
    projSteps: dynamicComponent('ProjStepper','elements',{projID,projStepps:(x)=>setState(p=>({...p,...{projStepps:x}}))},mainState)
  }}))
  },[stateRef.current.preList])

return [ stateRef.current.Map,
      <Box className={"OverviewFull"} style={PopStyle.OverviewFull} ref={comp}>
      <Box className={"scannerClm"} style={PopStyle.projectAssmbl}>
     
      <Box className={'createProj fillProj'} style={PopStyle.speedRing}>
      <Fab onClick={()=> setGlobalAppState(p=>{ p.delete('modulePop'); return new Map(p) }) }
          style={{
            position: 'absolute',
            top: '-.35%',
            left: '-.35%',
            width: 'unset',
            height: 'unset',
            aspectRatio: '1/1',
            zIndex: 99,
            borderRadius: 'unset',
            background: 'var(--primary-color)',
            borderBottomRightRadius: '8px'
          }}
        >
        <FontAwesomeIcon icon={faTimesCircle} className={'searchClose'} style={{color:'#fff'}}/>
        </Fab>
      {<Box className={'unitScan'} style={{flexWrap:'unset',padding: '3%',}}>
        { stateRef.current.SpeedRing }
        </Box>}
      { stateRef.current.scannerField }
      </Box>
        <Box sx={{flex:1,display:'flex', overflow: 'auto', justifyContent:'center'}}>
          { stateRef.current.projSteps }
        </Box>
      </Box>
        { !globalState.useIsMobile(666) ? stateRef.current.userListings : null }
      </Box>]
}


