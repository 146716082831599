 /* eslint-disable no-unused-expressions */
 import React, { useEffect } from "react";
 import { useAtom } from 'jotai';
 import useState from 'react-usestateref';
 import AvTimerIcon from '@mui/icons-material/AvTimer';
 import VerifiedIcon from '@mui/icons-material/Verified';
 import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
 import SnoozeIcon from '@mui/icons-material/Snooze';
 import dayjs from "dayjs";
 import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
 import * as localizedFormat from 'dayjs/plugin/localizedFormat';
 import * as isToday from 'dayjs/plugin/isToday';

 import LogoutIcon from '@mui/icons-material/Logout';

 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
 import { faProjectDiagram, faUserClock } from '@fortawesome/free-solid-svg-icons';
 import LocalShippingIcon from '@mui/icons-material/LocalShipping';

 import {Box,Typography,Toolbar,Paper,AppBar,IconButton,ListItem,Chip,ListItemText,Avatar,Divider} from "@mui/material";
 import { styled } from '@mui/material/styles';
 dayjs.extend(localizedFormat);
 dayjs.extend(isToday);
 dayjs.extend(isSameOrBefore)

 const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: 60,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
}));


const FinishPopup=({dynamicMapData,dbUpdateValidate,dynamicComponent,globalState,globalWrn,CheckID,queryDB,projectBuilder})=> {
  const mainState = useState({ 
      noListLabel: 'No Projects',
      buttonTitle: 'FETCH: End Of Day Projects',
      waitingLabel: '...Gathering your EOD Forms'
    })
  const [, setState, stateRef] = mainState
  const [, setEODInfo, EODInfoRef] = useState(Array)
  const [, setDeliveries, DeliveriesRef] = useState(Array)
  const [, setRemote, RemoteRef] = useState(Array)
  const [, setCompletedProj, completedProjRef] = useState(Array)
 // const stagedRef = new Set()
  const [appState,setAppState] = useAtom(globalState.appState)
  const [UserMap, setUserMap] = useAtom(globalState.userMapState)
  const [projectMap,setProjectMap] = useAtom(globalState.projectMapState)
  const [inventoryMap,setInventoryMap] = useAtom(globalState.inventoryMapState)
  const [divisionMap,] = useAtom(globalState.divisionMapState)
  const [negotiate,] = useAtom(globalState.nonNegotiableState); // Flag that forces re-render
  const tableState = useState([])
  const [, setTableState, tableStateRef] = tableState
 // const [, setValue, valueRef] = valueTable;
  const fieldState = useState(Object);
  const [, setField, fieldRef] = fieldState;
  const [reSync, setReSync] = useState(false);

  var [, setPopup] = useAtom(globalState.popupState);

  useEffect(()=>{
   queryDB({ model: 'Inventory', query: {'TransferRequests.deliverDriver': UserMap.get('avatar'),
      'TransferRequests.deliveryDate': {  $lt: dayjs().endOf('day').toISOString()}}, filter:{} }, (x) => {
        x?.forEach(y=>{
        dynamicMapData({[y._id]: y}, inventoryMap, setInventoryMap);
        var {TransferRequests,DivisionID,ProjectID,ProductItems:[{id:ProductID}]} = y;
               var currRequests = TransferRequests.filter(x=>x?.TransferType && (["Delivery"].includes(x?.TransferType) && x?.Completed !== true && x?.deliveryDate)).flatMap(x=>{return {...x, ProductID, InventoryID: y._id}})
               var closedRequests = TransferRequests.filter(x=>x?.TransferType && (["Delivery"].includes(x?.TransferType) && x?.Completed === true && x?.deliveryDate)).flatMap(x=>{return {...x, ProductID, InventoryID: y._id}})
               if(currRequests?.length > 0) {
               let finalObj = {
                ProjectID: ProjectID?.[0],
                DivisionID,
                ProductID: [ProductID],
                InventoryID: [y?._id],
                TransferRequests: currRequests
               }
               setDeliveries(p=> {
                if(p?.filter(x=>x.ProjectID === y.ProjectID?.[0])?.length === 0)
                  return [...p, finalObj]
                else if (p?.filter(x=>x.ProjectID === y.ProjectID?.[0])?.length > 0) return p.flatMap(tmp=>{ 
                  if(tmp.ProjectID === y.ProjectID?.[0]) 
                    return {
                        ProjectID:tmp.ProjectID,
                        DivisionID:tmp.DivisionID,
                        ProductID:tmp.ProductID.concat(ProductID),
                        InventoryID:tmp.InventoryID.concat(y._id),
                        TransferRequests:tmp.TransferRequests.concat(currRequests)
                      };
                  else return tmp;
                    })
                else if (!p) return [finalObj]
               })
              }
               })
      }
      )
  },[negotiate]);

  const removeItem = (x) => {
    setDeliveries(p=>p.map(tmp=>tmp.ProjectID === x?.ProjectID ? {...x, TransferRequests: tmp.TransferRequests.flatMap(x=>{return {...x, Completed: true}})} : tmp))
  }

  useEffect(()=>{
    queryDB({ model: 'Inventory', query: {'TransferRequests.requestor': UserMap.get('avatar'),
      'TransferRequests.deliveryDate': {  $lt: dayjs().endOf('day').toISOString()}}, filter:{} }, (x) => {
        x?.forEach(y=>{
        dynamicMapData({[y._id]: y}, inventoryMap, setInventoryMap);
        var {TransferRequests,DivisionID,ProjectID,ProductItems:[{id:ProductID}]} = y;
               var currRequests = TransferRequests.filter(x=>x?.TransferType && (["Remote"].includes(x?.TransferType) && x?.Completed !== true && x?.deliveryDate)).flatMap(x=>{return {...x, ProductID, InventoryID: y._id}})

               if(currRequests?.length > 0) {
               let finalObj = {
                ProjectID: ProjectID?.[0],
                DivisionID,
                ProductID: [ProductID],
                InventoryID: [y?._id],
                TransferRequests: currRequests
               }
               setRemote(p=> {
                if(p?.filter(x=>x.ProjectID === y.ProjectID?.[0])?.length === 0)
                  return [...p, finalObj]
                else if (p?.filter(x=>x.ProjectID === y.ProjectID?.[0])?.length > 0) return p.flatMap(tmp=>{ 
                  if(tmp.ProjectID === y.ProjectID?.[0]) 
                    return {
                        ProjectID:tmp.ProjectID,
                        DivisionID:tmp.DivisionID,
                        ProductID:tmp.ProductID.concat(ProductID),
                        InventoryID:tmp.InventoryID.concat(y._id),
                        TransferRequests:tmp.TransferRequests.concat(currRequests)
                      };
                  else return tmp;
                    })
                else if (!p) return [finalObj]
               })}
               })
      }
      )
  },[negotiate])

  useEffect(()=>{  
    var userID = UserMap.get('avatar')

    const missingEODs = [].concat(...Array.from([...projectMap],([k,v])=> [v]
      .filter(({ProjectState,DivisionID})=> 
      ProjectState?.EODLogg?.some(({Warned,WarnedTo})=>{ 
          if(Warned === true){
            var category = divisionMap.get(DivisionID)?.category
            if(WarnedTo === userID) return true
            if( category === 'ChromeBook' && ProjectState?.projManager === userID ) return true
            if( ['Construction',' AV'].includes(category) && ProjectState?.teamLead === userID ) return true
            return false
          }
          return false
        })
      ) ))
      setEODInfo(missingEODs)

      const completedProj = [].concat(...Array.from([...projectMap],([k,v])=> [v].filter(x=>x?.snooze?.alerts ? dayjs(x?.snooze.alerts)?.isSameOrBefore(dayjs()) : x).filter(x=>x?.ProjectState?.Status === "Active").filter(({ProjectState})=>{
        if(ProjectState?.Calendar?.endDate && dayjs().isSameOrAfter(ProjectState?.Calendar?.endDate) && ProjectState?.projManager === userID) return true
      })))
      setCompletedProj(completedProj)


  if(missingEODs.length > 0 || completedProj.length > 0 || DeliveriesRef.current?.length > 0) setAppState(p => { p.set('requiredPopup', true); return new Map(p)}) 
  else if((missingEODs.length === 0 && completedProj.length === 0 && DeliveriesRef.current?.length === 0)){
      setAppState(p => { p.set('requiredPopup', false); return new Map(p)}) 
      setState(p=>({...p,...{ FinishPopup: undefined }}))
    }
},[projectMap,reSync,UserMap.get('avatar')]);


useEffect(()=>{
  if(EODInfoRef.current) EODInfoRef.current.flatMap(({ProjectTitle,DivisionID,ProjectState,_id:ProjectID})=> ProjectState?.EODLogg?.flatMap(({date, _id})=>({
      projLabel: `${ProjectTitle} (Missing EOD on ${dayjs(date).format('l')})`,
      DivisionID: DivisionID,
      modelType: divisionMap.get(DivisionID)?.teamName,
      ProjectID: ProjectID,
      reSubmission: date,
      icon: <FontAwesomeIcon icon={faUserClock}/>,
      eodID: _id
      }))).forEach(items=>{
       if(!tableStateRef.current.some((x)=>items.projLabel===x.projLabel)){
         setTableState(p=>[].concat(p,items));
         missingEOD(items);
       } 
      })

},[EODInfoRef.current]);

useEffect(()=>{
  if(completedProjRef.current) completedProjRef.current.flatMap(({ProjectTitle,DivisionID,ProjectState,_id:ProjectID, PoNumber})=>({
    projLabel: `${ProjectTitle} (Job # ${PoNumber})`,
    DivisionID: DivisionID,
    modelType: divisionMap.get(DivisionID)?.teamName,
    ProjectID: ProjectID,
    icon: <FontAwesomeIcon icon={faProjectDiagram}/>,
    completion: ProjectState?.Calendar?.endDate || dayjs()
    })).forEach(items=>{
     if(!tableStateRef.current.some((x)=>items.projLabel===x.projLabel)){
       setTableState(p=>[].concat(p,items)); 
       projectComplete(items);
     }
    })
},[completedProjRef.current])

const buildDeliveryTab = ({remote, ProjectTitle, delivery, DivisionID, ProjectID, InventoryID}) => {
  if(remote && remote.length>0) return {
    projLabel: `${ProjectTitle} (Remote Transfer on ${dayjs(delivery.deliveryDate).format('l')})`,
    DivisionID,
    modelType: divisionMap.get(DivisionID)?.teamName,
    ProjectID,
    icon: <LocalShippingIcon />,
    InventoryID,
    Deliveries: remote,
  };

  return {
    projLabel: `${ProjectTitle} (Missing Delivery Report on ${dayjs(delivery.deliveryDate).format('l')})`,
    DivisionID,
    modelType: divisionMap.get(DivisionID)?.teamName,
    ProjectID,
    icon: <LocalShippingIcon />,
    InventoryID,
    Deliveries: delivery,
    }
  
}

useEffect(() => {
  const processDeliveries = async () => {
    if (DeliveriesRef.current) {
      const promises = DeliveriesRef.current.flatMap((tempV) => {
        var { InventoryID, DivisionID, ProjectID, ProductID, TransferRequests } = tempV;
        const delivery = TransferRequests?.filter(({ deliveryDate, deliverDriver, Report, Reschedule, TransferAmount, Completed }) => (deliverDriver && deliverDriver === UserMap.get('avatar') && Completed !== true && !Report && dayjs().isSameOrAfter(deliveryDate))
        )?.flatMap(x => ({ ...x, ProductID }));

        if (!projectMap.has(ProjectID)) {
          return new Promise((resolve) => {
            CheckID({ 
              key: "Projects", 
              teamName: divisionMap.get(DivisionID)?.teamName, 
              map: projectMap, 
              setMap: setProjectMap, 
              _id: ProjectID 
            }, (resp) => {
              const { ProjectTitle } = resp;
              resolve(buildDeliveryTab({ ProjectTitle, delivery, DivisionID, ProjectID, InventoryID }));
            });
          });
        } else {
          const { ProjectTitle } = projectMap.get(ProjectID);
          return Promise.resolve(buildDeliveryTab({ ProjectTitle, delivery, DivisionID, ProjectID, InventoryID }));
        }

        
      });

      Promise.all(promises).then(results => {
        results.forEach(items => {
          if (!tableStateRef.current.some(x => items.projLabel === x.projLabel)) {
            setTableState(p => [].concat(p, items));
            DeliveriesBuildout(items);
          }
        });
      });
    }
    if(RemoteRef.current)
      {
        const promises = RemoteRef.current.flatMap(({ InventoryID, DivisionID, ProjectID, ProductID, TransferRequests }) => {
          const delivery = TransferRequests?.filter(({ deliveryDate, requestor, Report }) => (requestor === UserMap.get('avatar') && !Report && dayjs().isSameOrAfter(deliveryDate))
          )?.flatMap(x => ({ ...x, ProductID }));
  
          const remote = TransferRequests?.filter(({ TransferType, deliveryDate, Report }) => 
            TransferType && TransferType === "Remote" && !Report && dayjs().isSameOrAfter(deliveryDate)
          )?.flatMap(x => ({ ...x, ProductID }));
  
          if (!projectMap.has(ProjectID)) {
            return new Promise((resolve) => {
              CheckID({ 
                key: "Projects", 
                teamName: divisionMap.get(DivisionID)?.teamName, 
                map: projectMap, 
                setMap: setProjectMap, 
                _id: ProjectID 
              }, (resp) => {
                const { ProjectTitle } = resp;
                resolve(buildDeliveryTab({ remote, ProjectTitle, delivery, DivisionID, ProjectID, InventoryID }));
              });
            });
          } else {
            const { ProjectTitle } = projectMap.get(ProjectID);
            return Promise.resolve(buildDeliveryTab({ remote, ProjectTitle, delivery, DivisionID, ProjectID, InventoryID }));
          }
        });
  
        Promise.all(promises).then(results => {
          results.forEach(items => {
            if (!tableStateRef.current.some(x => items.projLabel === x.projLabel)) {
              setTableState(p => [].concat(p, items));
              DeliveriesBuildout(items);
            }
          });
        });
      }
  };

  processDeliveries();
}, [DeliveriesRef.current, RemoteRef.current]);


const gatherList = async(userFilter,projFilter,category) => await new Promise((resolve) => queryDB({model:'AdminOrg',query:{'fieldSet':[`${category}Services`,`${category}Production`]},filter:{'_id':0,'fieldSet':0,'Number':0}},(items,err)=>{ 
  var projectFilter = projFilter ? projFilter : []
  return resolve( Object.assign({},...items.reverse().flatMap(x=>Object.entries(x)
  .filter(([k, v],i) => projectFilter ? Object.keys(projectFilter).includes(k) : false )
  .map(([k, v],i) =>({[k]: Object.entries(v)
  .filter(([k2, v2],i2) => projectFilter ? projectFilter[k].flatMap(x=>Object.values(x)).includes(v2.label) : false)
  .map(([k2, v2],i2) =>({...projectFilter[k][0],...v2})) 
  }) 
  ))) )
}) );


const handleRemove = (item) => {
  setTableState((prev)=>[...prev.filter(({projLabel})=>projLabel !== item)]);
  setField(p=>{delete p[item];return p})
  if(tableStateRef.current.length===0){
    setAppState(p => { p.set('requiredPopup', false); return new Map(p)}) 
    setPopup({open:false})
  }
};

const projectComplete=({DivisionID,ProjectID,modelType,projLabel,completion})=>{
  if (projectMap.has( ProjectID )) var {ProjectState,SalesState} = projectMap.get( ProjectID );
  var projID = { ProjectID, DivisionID, modelType } 
  var defaultEnd = stateRef.current['ProjectState.Calendar.endDate'] || ProjectState?.Calendar?.endDate || 'End Date Needed';
var item = <AppBar position="static">
<Toolbar variant="dense" sx={{pl:'0px!important',flexWrap:'wrap',gap:'12px',p:'1%!important'}} >
<ListItem alignItems="flex-start" sx={{gap:'5px',p:0,width:'unset'}}>
<AvTimerIcon sx={{fontSize:'50px',margin:'auto'}} />
<ListItemText sx={{display:'flex',flexDirection:'column',gap:'4px'}}
primary={<Typography variant="body1" color="inherit" sx={{display: '-webkit-box',WebkitLineClamp: 2, WebkitBoxOrient: 'vertical',overflow: 'hidden', textOverflow: 'ellipsis', lineHeight:1.1}}>{projLabel}</Typography>}
secondary={
[ <Typography sx={{ display: '-webkit-box',WebkitLineClamp: 2, WebkitBoxOrient: 'vertical',overflow: 'hidden', textOverflow: 'ellipsis', lineHeight:1 }} component="span" variant="body2" color="text.primary">Schedule to be completed:</Typography>,
` ${dayjs(completion).format('LLLL')} `]
}
/>
</ListItem>
<Box sx={{flex:1,display:'flex',gap:'8px',justifyContent:'space-evenly',flexWrap:'wrap'}}>
  {dynamicComponent('DefaultButton','elements',{state:'marked',label:'Mark as Complete',type:'button',startIcon:<VerifiedIcon/>, color:'#A0E77D',style:{color:'#2e2e2e'}},mainState,[()=>
  projectBuilder({projID:projID,submitObj:{'SalesState.Status':'Complete','ProjectState.Status':'Complete',Status:'Completed'}},(x)=>{
    globalWrn('info',`Project: ${projLabel} has been marked "Completed".`); 
    handleRemove(projLabel);
    setCompletedProj(completedProjRef.current.filter(x=>{if(x?._id === projID) return x})); 
    setReSync(!reSync);
  })
  ])
  }
  <Item elevation={8}>
  { dynamicComponent('DatePicker','elements',{state:'ProjectState.Calendar.endDate',label:'Extend Out End Date', defaultValue: defaultEnd, min: ProjectState?.Calendar?.startDate, max: SalesState?.projectDue?.calendar },mainState,[(x)=>
  projectBuilder({projID:projID,submitObj:x},(x)=>{
    globalWrn('info',`Project: ${projLabel} has been Extend Out to: ${dayjs(x['ProjectState.Calendar.endDate'])}.`).format('LLLL');
    setCompletedProj(completedProjRef.current.filter(x=>{if(x?._id === projID) return x})); 
    handleRemove(projLabel);
    } )]) }
  </Item>

  { dynamicComponent('DropDown','elements',{
    state: 'snoozeDrp',
    icon: <SnoozeIcon sx={{ minWidth: '14px' }} />,
    buttonStyles: {backgroundColor:'rgb(18,18,18)'},
    label: 'Snooze',
    buttonStyles: {color:'#fff',backgroundColor:'transparent'},
    list: [
      {primary:'1 minute',secondary:dayjs().add(1, 'minute').format('LT'), icon: <SnoozeIcon/> },
      {primary:'2 hours',secondary:dayjs().add(2, 'hour').format('LT'), icon: <SnoozeIcon/> },
      {primary:'4 hours',secondary:dayjs().add(4, 'hour').format('LT'), icon: <SnoozeIcon/> },
      {primary:'6 hours',secondary:dayjs().add(6, 'hour').format('LT'), icon: <SnoozeIcon/> },
    ],
    replace:true, 
    },mainState,[({snoozeDrp})=>{
    var newSnooze = Object({
      '1 minute': ()=> dayjs().add(1,'minute'),
      '2 hours': ()=> dayjs().add(2,'hour'),
      '4 hours': ()=> dayjs().add(4,'hour'),
      '6 hours': ()=> dayjs().add(6,'hour')
    })[snoozeDrp]()
    projectBuilder({projID:projID,submitObj:{
      'snooze.alerts': newSnooze.toDate()
  }},()=>{
    handleRemove(projLabel);
    globalWrn('info',`Project: ${projLabel} has been Snoozed till: ${snoozeDrp}.`)?.format('LT')} );
    }
    ]) }
</Box>
</Toolbar>
</AppBar>

setField(p=>({...p,[projLabel]: item})) 
};

const DeliveriesBuildout = ({ProjectID,projLabel}) => {
    const regex = /\(Remote/;
    var item = projLabel && regex.test(projLabel) ? 
    RemoteRef.current.filter(x=>x.ProjectID === ProjectID).flatMap(y=>dynamicComponent('RemoteFormPopup', 'elements',{state:'RemoteFormPopup',data: [y]},mainState))
 : DeliveriesRef.current.filter(x=>x.ProjectID === ProjectID).flatMap(y=>dynamicComponent('DeliveriesFormPopup', 'elements',{state:'DeliveriesFormPopupState',data: [y], removeItem: removeItem},mainState));
setField(p=>({...p,[projLabel]: item}))
};

const missingEOD=({DivisionID,ProjectID,modelType,projLabel,reSubmission,Deliveries,eodID})=>{
  if (projectMap.has( ProjectID )) var {SalesState} = projectMap.get( ProjectID )
  if (divisionMap.has( DivisionID ) ) var {category} = divisionMap.get( DivisionID )
  var userFilter = SalesState?.PhaseOption ? SalesState?.PhaseFilter : SalesState?.OptionsFilter
      if(SalesState?.PhaseOption && SalesState?.PhaseFilter ) Promise.all(
        SalesState?.PhaseFilter.map(({label,Options})=>({[projLabel]:{label,Options: gatherList(userFilter,Options,category)}}))
      ).then(([x])=> setField(p=>({...p,...{ 
        [projLabel]: x?.dynamicComponent('EODform', 'elements',{state:'saveForm',projID:{ProjectID,DivisionID,modelType},ProjData:x,projLabel,reSubmission,Deliveries,eodID,handleRemove:handleRemove},mainState)
       }})) )
      else  {
        Promise.all([gatherList(userFilter,SalesState?.OptionsFilter,category)]).then(([x])=>
        setField(p=>({...p,...{ [projLabel]: dynamicComponent('EODform', 'elements',{state:'saveForm',projID:{ProjectID,DivisionID,modelType,eodID:eodID},ProjData:x,projLabel,reSubmission,eodID:eodID,handleRemove:handleRemove},mainState) }}))
       )
      }
};

useEffect(()=>{
  if( fieldRef.current ) setState(p=>({...p,...{
   FinishPopup: appState.get('requiredPopup') && <Box sx={{ display: 'flex', flexDirection:'column', width:'100%', gap:'1%' }}>
   <AppBar position="static">
     <Toolbar sx={{gap:'2%', paddingLeft:'1%'}}>
       <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
         <ReportProblemOutlinedIcon />
       </IconButton>
       <Typography variant="h6" sx={{ flexGrow: 1, }} noWrap> {stateRef.current.title} </Typography>
       <Box sx={{display:'flex',overflow:'auto'}}>
       { DeliveriesRef.current?.length > 0 && <Chip avatar={<Avatar>{DeliveriesRef.current?.length}</Avatar>} label="Delivery(s)" /> }
       { completedProjRef.current.length > 0 && <Chip avatar={<Avatar>{completedProjRef.current.length}</Avatar>} label="Project(s) Ending" /> }
       { EODInfoRef.current.length > 0 && <Chip avatar={<Avatar>{EODInfoRef.current.length}</Avatar>} label="Missing EOD(s)" /> }
       </Box>
       <Divider orientation="vertical" variant="middle" flexItem />
       {dynamicComponent('DefaultButton','elements',{state:'scanEnable',label:'Logout',type:'button',startIcon:<LogoutIcon/>},mainState,[()=>{
         setPopup(p=>({...p,
           open: true,
           title:'Are you sure?',
           description: 'You are about to logout of the EKC App, are you sure you meant to do this?',
           handleSubmit: ()=>{ 
             setAppState(p => { p.set('pageHold', false); return new Map(p)}) 
             dbUpdateValidate({model:'User',query:true,update:{[`local.pageStamp.pageID`]:'SignIn'}},(x)=>{if(x===true) globalWrn('info',"You have now been logged out."); })
             setUserMap({t:'set',k:'pageStamp',v:{pageID:'SignIn'}})
             setPopup({open:false})
           },
           handleClose: ()=> setPopup({open:false}),
           submitLabel: 'LOGOUT',
         }))
       }])}
     </Toolbar>
   </AppBar>

     { dynamicComponent('ItemsListed','elements',{tableState,fieldState,bttnAction:()=>setReSync(true), disableDelete: true},mainState) }
   </Box>
 }}))

},[ fieldRef.current, DeliveriesRef.current ])

return [stateRef.current.FinishPopup,DeliveriesRef.current?.length > 0 && DeliveriesRef.current.flatMap(x=>x?.ProductID?.flatMap(y=>stateRef.current?.[y+'productCard']))]
}

 export default FinishPopup;