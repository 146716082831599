import React, { useEffect, useMemo, useRef, useCallback } from 'react';
import { useAtom } from 'jotai';
import useState from 'react-usestateref';
import { MaterialReactTable, useMaterialReactTable, MRT_ToggleFiltersButton, MRT_ToggleDensePaddingButton, MRT_ShowHideColumnsButton } from 'material-react-table';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import SwipeableViews from 'react-swipeable-views';
import { saveAs } from 'file-saver-es';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import SignatureCanvas from 'react-signature-canvas'
import WarehouseIcon from '@mui/icons-material/Warehouse';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import BackHandIcon from '@mui/icons-material/BackHand';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import InventoryIcon from '@mui/icons-material/Inventory';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import PendingActionsIcon from '@mui/icons-material/PendingActions';

import useLongPress from "../Elements/useLongPress";

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

//Material UI Imports
import { Box, Button, Card, MenuItem, CardActions, ListSubheader, ListItemButton, Typography, ToggleButton, Chip, Avatar, ListItem, ListItemText, Grow, ListItemAvatar, IconButton, Divider, ToggleButtonGroup, List, AppBar, Tabs, Tab, Menu, ButtonGroup, Table,TableBody,TableCell,TableContainer,TableHead,TableRow, Paper, Grid, Skeleton, CardContent } from '@mui/material';
import { Edit, Print, Transform, LowPriority, FileDownload, AssignmentReturned, PictureAsPdf, Inventory2Outlined, Inventory, AccountTree, AirlineStops, AirportShuttle, Warehouse, RequestQuote, HourglassTop, PauseCircle, ProductionQuantityLimits, AssignmentTurnedIn, LocalShipping, RemoveDone, KeyboardArrowDown, Save, DoneAll, ReportGmailerrorred } from '@mui/icons-material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useTheme, styled,alpha } from '@mui/material/styles';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import dayjs from 'dayjs';

var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

const BoxGroup = styled(Box)(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: '.25% 1%',
  borderRadius: '8px',
}));

const BootstrapTooltip = styled(({ className, useStyles, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: { color: theme.palette.common.black },
  [`& .${tooltipClasses.tooltip}`]: { backgroundColor: theme.palette.common.black },
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const isNumeric=(x)=> /^-?\d+$/.test(x);

const coloring = Object({
  'Shipping':  {color: 'white', backgroundColor: '#5F6A6A'},
  'Partial Received': {color: '#4a4e4d', backgroundColor: '#f6cd61'},
  'Warehouse Received': {color: '#4a4e4d', backgroundColor: '#3da4ab'},
  'Partially Received and Delivered': {color: '#4a4e4d', backgroundColor: '#a8e6cf'},
  'Partial to Site': {color: '#4a4e4d', backgroundColor: '#adcbe3'},
  'Delivered to Site': {color: 'white', backgroundColor: '#64a1f4'},
  'Returned for RMA': {color: 'white',  backgroundColor: '#fe8a71'},
  'Stock': {color: '#4a4e4d', backgroundColor: '#f9caa7'},
  'Requested': {color: '#4a4e4d', backgroundColor: '#02d1af'},
  'Order Placed': {color: '#4a4e4d', backgroundColor: '#539ffc'},
  'Order Paused': {color: '#4a4e4d', backgroundColor: '#d68fff'},
  'Order Unavailable': {color:' #000000', backgroundColor: '#ff5252'},
  'Order Processing': {color: '#4a4e4d', backgroundColor: '#bff52c'},
  'Order Checked On': {color: '#4a4e4d', backgroundColor: '#ffdd00'},
});

const InventoryGrid=(props)=>{
  var {dynamicMapData,globalState,TaskManager,dynamicComponent,dbUpdateValidate,That,waveLoad,globalWrn,defaultArray,formatter,viewData,That,useIsMobile,projectBuilder,queryDB,newNotification,imageCompress,inventoryFocus,inventoryDelete,ExcelCreate,CellFocusFunc} = props;
  if(viewData) var {alignments,gridRef,data:invnetoryData,pullReq,Purchasing,onSaving,focusedLines,disableStatusFilter} = viewData;
  // const [,,{current:parentRef}] = That
  const mainState = useState(Object);
  const [, setState,{current:stateRef}] = mainState;
  const [, setUploadTempFiles, uploadTempFilesRef] = useState(Object);
  const [, setPopup2] = useAtom(globalState.popupState2);
  const [divisionMap] = useAtom(globalState.divisionMapState);
  const [, setAppState] = useAtom(globalState.appState);
  const [searchState] = useAtom(globalState.searchState);
  const [inventoryMap, setInventoryMap] = useAtom(globalState.inventoryMapState);
  const [projectMap] = useAtom(globalState.projectMapState);
  const [productMap,setProductMap] = useAtom(globalState.productMapState);
  const [, setPopup3] = useAtom(globalState.popupState3);
  const [, setPopup4] = useAtom(globalState.popupState4);
  const [teamMember] = useAtom(globalState.teamMemberState);
  const [, setPopup] = useAtom(globalState.popupState);
  const [divisionFilterMap] = useAtom(globalState.divisionFilterMapState);
  const [vendorMap] = useAtom(globalState.vendorMapState);
  const [CellFocus, setCellFocus] = useAtom(globalState.CellFocus);
  const [UserMap] = useAtom(globalState.userMapState);
  const [,setTransferRequest,transferRequest] = useState([]);
  const [, ,alignmentRef] = alignments;
  const alignment2 = useState('All');
  const {direction} = useTheme();
  const [, setValue,{current:valueRef}] = useState(0);
  const tempValState = useState(Object);
  const [, setTempVal,{current:tempValRef}] = tempValState;
  const useAndMobile = useIsMobile(560);
  const outputState = useState(Object)
  const [, setOutput, outputRef] = outputState;

  const blurRef = useRef(null);
  const [, ,{current:anchorRef}] = useState(null);
  const open = Boolean(anchorRef);

  // Variable for inventory submission
  const [parameters, setParameters] = useState({})

  const rowVirtualizerInstanceRef = useRef(null);
  const mainData = useState([]); 
  const [, setData, dataRef] = mainData;
  const [isLoading, setIsLoading] = useState(true);
  const [sorting, setSorting] = useState([]);

  var gridRef2 = useRef(null)

  var sig = useRef(null)
  var sig2 = useRef(null)

  const updateScreen = async() => {
    setData(dataRef.current.flatMap((x)=>inventoryMap.has(x._id)&&inventoryMap.get(x?._id)))
  }

  useEffect(()=>{
    if(inventoryMap.size > 0) rebuild()
    else if (stateRef.initWarn !== true) {
      globalWrn('info', `Fetching Projects and Inventory...`)
      setState(p=>({...p,initWarn:true}))
    }
  },[stateRef.divisionSet,stateRef.statusFilter,searchState.get("searchResult"),inventoryMap,divisionFilterMap])

  useEffect(()=>{
    updateScreen()
  },[inventoryMap])

  useEffect(()=>{ 
    if(invnetoryData){
     setData(invnetoryData);
     setIsLoading(false);
  }
  },[invnetoryData]);

  const closeTransferList=()=>{
    setOutput(p=>({...p,
      requestor: undefined,
    }))
    closeAllPopups()
  }

  useEffect(() => {
    //scroll to the top of the table when the sorting changes
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
    } catch (error) {
      console.error(error);
    }
  }, [sorting]);

  const cancelPopup=()=>{
    setPopup({open:false})
   };

   const cancelUndo=()=>{
    setPopup2({open:false})
    setPopup({open:false})
  };

  const automatedNotesResp=()=> `No associated notes.`;

  const submitProjSite=(ProjectID,{row,ColumnID,value,InventoryID,index,_valuesCache,updateCell})=>{
    var projIDtemp = { DivisionID: projectMap.get(ProjectID)?.DivisionID,modelType: divisionMap.has(projectMap.get(ProjectID)?.DivisionID) ? divisionMap.get(projectMap.get(ProjectID)?.DivisionID).teamName : divisionMap.get(UserMap.get('division')).teamName }
    var {QTYReceived,QTYOnHand,Status,ProductItems} = inventoryMap.get(InventoryID);
    if(ProjectID) projIDtemp.ProjectID = ProjectID
  if(outputRef.current?.['SalesState.SiteID'].length > 0)
    if(ProjectID){
      projectBuilder({projID:projIDtemp,submitObj:{['SalesState.SiteID']: outputRef.current?.['SalesState.SiteID']}},(x)=>{
            var {success} = x  
            if(success===true){ 
                  setPopup2({open:false})
                  setAppState(p=>{ 
                  p.delete('modulePop')
                  return new Map(p)
                  })
                  setTransferRequest(p=>{p.push({
                    ProjectID,
                    InventoryID,
                    TransferAmount: value-QTYOnHand,
                    ColumnID,
                    value,
                    row,
                    Status,
                    QTYReceived,
                    ProductItems,
                    tempTime: dayjs(),
                    _valuesCache,
                    updateCell,
                    index
                  });return p});
                  // Clear old data off of state
                  setOutput(p=>({...p,['SalesState.SiteID']:undefined}))
                }else {
                  globalWrn('error',`There was an error updating project ${projectMap.get(ProjectID)?.ProjectTitle}.`)
                }
                setState(p=>({...p,loading : false }))
            })
          }
  };

  const addSites=()=>{
    setPopup3(p=>({...p,
      open:true,
      title:`Add District/Company Site`,
      description: `Do not add entire company, government bureau/agency, or school district.`,
      inputField: stateRef.newSt,
      handleClose: ()=>{setPopup3({open:false})},
      nameInputSets: {},
  }))  
  };

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
    filename: `EKC_APP_Inventory_${dayjs().format('llll')}`
  });

    const handleExportData = () => {
      const csv = generateCsv(csvConfig)(dataRef.current);
      download(csvConfig)(csv);
    };

    const handleExportRows = (rows,type) => {

      var idArr = []
      var tempArr = deepCopy(dataRef.current)
      tempArr.forEach((x,i)=>{tempArr[i].Manufacturer = productMap.has(x?.ProductItems?.[0]?.id) ? productMap.get(x?.ProductItems?.[0]?.id)?.MANUFACTURER : "N/A"})
      tempArr.forEach((x,i)=>{tempArr[i].TotalValue = roundToDecimal((productMap.get(x?.ProductItems?.[0]?.id)?.MSRP) * (x?.QTYOnHand?x?.QTYOnHand:0), 2) })
      tempArr.forEach((x,i)=>{tempArr[i].vendorID = vendorMap.has(x?.vendorID) ? vendorMap.get(x?.vendorID)?.Name : "N/A"})
      tempArr = [...replaceKeysAndValuesInArrayOfObjects(tempArr, "ProductItems", "ProductItems", idArr)]
      tempArr = [...replaceKeysAndValuesInArrayOfObjects(tempArr, "vendorID", "PreferredVendor")]

      const tableHead = columns.map((c)=>c.accessorKey);

      tempArr = filterKeysInArrayOfObjects(tempArr, tableHead)
      tempArr = generateKeys(tempArr, tableHead)
      tempArr = tempArr.map(x=>orderObjectByKey(x));

      var tableData = tempArr.map(x=>Object.values(x))
      var tableHeaders = Object.keys(tempArr?.[0]) || []

      if(type==='CSV'){
        const csv = generateCsv(csvConfig)(tableData);
        download(csvConfig)(csv);
      }
      else { 
      const doc = new jsPDF('l', 'pt');
      var columnNames = [];
      tableHeaders.map(x=>columns.map(y=>{if(Object.values(y).includes(x)) columnNames.push(y?.header)}));
      autoTable(doc, {
        head: [tableHeaders],
        body: tableData,
        styles: {
          fontWeight: 'normal',
          overflow: 'linebreak',
          columnWidth: 'auto'
        },
        columnStyles: {
          country: {columnWidth: 'wrap'}
        },
      });
  
      doc.save(`EKC_APP_Inventory_${dayjs().format('llll')}.pdf`);
    };
    setPopup2({open:false})
  }

  // Start of date capturing functions for switching items from requested
useEffect(()=>{ 
  setState(p=>({...p,
    vendorField: dynamicComponent('AutoComplete','elements',{state:'vendorID',label:'Selected Vendor',type:'text',model:'Vendors',defaultValue:inventoryMap.get(),query:{},filter:{},addEntry:createVendor},outputState), 
    trackingNum: <Box sx={{width:'100%'}}>{dynamicComponent('InputField','elements',{state:'TrackingNumber',label:'Add Tracking Number'},outputState)}</Box>,
    stockPriority: dynamicComponent('DefaultButton','elements',{state:'setGrid',label:'Skip & show Stock Items',type:'button',startIcon:<WarehouseIcon/>},mainState,[()=>{ setState(p=>({...p, stockPriority: true })) }]),
    siteSelect: dynamicComponent('AutoComplete','elements',{state:'SalesState.SiteID',label:'Company(s) / Site(s)' ,model:'Sites',idConvert:'$nin',query:{'_id':Array.from(teamMember).filter(([,{userType}])=>userType==="Sites").flatMap(([k])=>k)}, addEntry:addSites},outputState),
    exportBttn: dynamicComponent('DropDown','elements',{ state: 'Export', defaultValue: 'Export',icon: <FileDownload/>, label: 'Export PDF/CSV', list: ['Export All', 'Export Selected'], replace:true },mainState,[({Export})=>{
     
      const exporting=(type)=>{
        Object({ 
          'Export All': ()=>handleExportRows(table.getPrePaginationRowModel().rows,type),
          'Export Selected': ()=> handleExportRows(table.getSelectedRowModel().rows,type)
          })[Export]()
       }
     
      setPopup2(p=>({...p,
        open: true,
        title:`Select PDF or CSV`,
        description: `you will need to select your export format`,
        inputField: <ButtonGroup fullWidth={true} variant="contained" aria-label="Basic button group" sx={{p:'4%',width:'93%'}}>
        <Button onClick={()=>exporting('CSV')}>CSV</Button>
        <Button onClick={()=>exporting('PDF')}>PDF</Button>
      </ButtonGroup>,
      handleClose: ()=> setPopup2({open:false}),
      }))

      }]),
    //dynamicComponent('DropDown','elements',{state:'exporter',label:'Export',icon: <FileDownload/>, defaultValue: parentRef.statusFilter, list:[{primary:'Excel',icon:<AssignmentReturned/>},{primary:'PDF',icon:<PictureAsPdf/>}]},mainState,[({exporter})=>onExporting(exporter)]),
    newSt: dynamicComponent('AddSites','elements',{state:'newSite', popupNum: 3},mainState),
    inputCheckedOnNotes: <Box sx={{padding: '1%'}}>{dynamicComponent('InputField','elements',{state:'checkedOnNotes',label:'Notes (Optional)' ,type:'text',style:{margin:0}},outputState)}</Box>,
  }))

  return () => { // Cleanup function to clear data
    setState({});
    setOutput({});
  };
 },[])

 useEffect(()=>{
  setState(p=>({...p,
  orderPlaced: <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} sx={{p:'1% 2%'}}>
  <Grid item xs={12} md={ useAndMobile ? 12 : 6 }>
    <Box>{dynamicComponent('InputField','elements',{state:'poNum',label:'Add Purchase Order Number'},outputState)}</Box>
    <Box>{dynamicComponent('InputField','elements',{state:'OrderNumPlace',label:'Add Order Number'},outputState)}</Box>
  </Grid>
  <Grid item xs={12} md={ useAndMobile ? 12 : 6 }>
  <Box>{dynamicComponent('InputField','elements',{state:'TrackingNumber',label:'Add Tracking Number'},outputState)}</Box>
  {stateRef.atCost}
  </Grid>
  <Grid item xs={12} md={ useAndMobile ? 12 : 6 }>
  { dynamicComponent('DatePicker','elements',{state:'expectedDeliveryDate',label:'Expected Delivery Date', min: dayjs() },outputState) }
  </Grid>
</Grid>,
}))
 },[stateRef.atCost])

  function rebuild(){ 
    setIsLoading(true);
     Promise.all([defaultArray()]).then(([res])=>{
      setData([].concat(...res));
      setIsLoading(false);
    })
  }

  useEffect(()=>{
    if(dataRef.current) setState(p=>({...p,
      totalVal: formatter.format(
        dataRef.current?.filter(({ProductItems,QTYOnHand})=>QTYOnHand>0&&ProductItems?.some(({id})=>productMap.has(id)))
        .filter(({_id})=> searchState.has("searchResult") ? searchState.get("searchResult").flatMap(({data})=>data).flatMap(x=>x._id).includes(_id) : true)
        .flatMap(({ProductItems,QTYOnHand,QTYReceived})=>{
          var {id:ProductID} = ProductItems.find(({id})=>id);
          if(productMap.has(ProductID)) return productMap.get(ProductID)?.MSRP*(stateRef.statusFilter==='On-Site'?(QTYReceived-QTYOnHand):QTYOnHand)
          return ProductItems?.find(({Product})=>Product.MSRP).Product.MSRP*(stateRef.statusFilter==='On-Site'?(QTYReceived-QTYOnHand):QTYOnHand)
        })
        .reduce((a,b)=>a+b,0)
      ) 
    }))
  },[dataRef.current,searchState.get("searchResult"),stateRef.statusFilter, stateRef.divisionSet])

  if(!defaultArray) var defaultArray=async()=>{ 
    var results = Array.from(inventoryMap.values(),(x)=>{
    var {ProductItems,ProjectID,DivisionID} = x;
    if(ProductItems?.length<=0) return [];

    if(projectMap.has(ProjectID?.[0])){
        if(!['Sales','Project'].includes(projectMap.get(ProjectID[0])?.Status)) return [];
        if(projectMap.has(ProjectID[0])) var {ProjectTitle,SalesState} = projectMap.get(ProjectID[0]);
        if(teamMember.has(SalesState?.SiteID?.[0])) var {name} = teamMember.get(SalesState?.SiteID?.[0]);
         x.projectName = ProjectTitle;
         x.site = name || 'Missing Site(s)';
      };

    if(divisionMap.has(DivisionID)){ 
       if(divisionMap.has(DivisionID)) var {category,teamName} = divisionMap.get(DivisionID);
        x.category = category;
        x.divisionName = teamName;
      };

    return x
  }).filter(({DivisionID})=>{
    if(!stateRef.divisionSet && divisionFilterMap.has(DivisionID) && !divisionFilterMap.get(DivisionID)) return false;
    if(stateRef.divisionSet && DivisionID !== stateRef.divisionSet) return false;
    return true;
  }).filter(({Status})=>{
    if(['Purchasing'].includes(stateRef.statusFilter)&&['Requested','Order Paused'].includes(Status)) return true;
    if(['On-Site'].includes(stateRef.statusFilter)&&['Partial to Site','Partially Received and Delivered','Delivered to Site'].includes(Status)) return true;
    if(['On-Hand'].includes(stateRef.statusFilter)&&['Partial to Site','Partially Received and Delivered','Warehouse Received'].includes(Status)) return true;
    if(stateRef.statusFilter&&stateRef.statusFilter!=='All'&&(stateRef.statusFilter!==Status&&stateRef.statusFilter!=="Order Placed/Processing")) return false;
    else if(stateRef.statusFilter&&stateRef.statusFilter==="Order Placed/Processing" && (Status !== "Order Placed" && Status !== "Order Processing")) return false;
    else return true;
  }).filter(({_id})=>{
    if(searchState.has("searchResult")){
      var searchRes = searchState.get("searchResult").flatMap(({data})=>data).flatMap(x=>x._id)
      return searchRes.includes(_id)
    } else return true
  }).filter(({_id})=>focusedLines ? focusedLines.includes(_id) : true)
  return [].concat(...results)
}

  const checkForSiteLocation=(ProjectID,{row,ColumnID,value,InventoryID,index,_valuesCache,updateCell})=>{
    if(!projectMap.get(ProjectID)?.SalesState?.SiteID?.[0]){ 
      setPopup2(p=>({...p,
        open:true,
        title:`Add Project Site`,
        description: `The Selected Project ${projectMap.get(ProjectID)?.ProjectTitle} is Missing its site. Please select one.`,
        inputField: <Box sx={{padding: '1%'}}>{stateRef.siteSelect}</Box>,
        handleClose: ()=>{
          closeAllPopups(); 
          table.setEditingCell(null);
          globalWrn(`error`, `You must select a site to submit!`); return false},
        handleSubmit: ()=>{
          submitProjSite(ProjectID,{row,ColumnID,value,InventoryID,index,_valuesCache,updateCell}); 
          if(ColumnID==="QTYOnHand") setState(p=>({...p,currentQTYOnHand:value}))
          updateCell(null);
          },
      }))
    }
    else return true;
  };

  const submitEmailChange=(Requestor, update,{QTYReceived,QTYOnHand,PurchaseOrder,TrackingNumber,Status},inventoryID_temp)=>{
    if(Requestor && Status !== "Stock" && Status !== "Order Unavailable" && Status !== "Order Paused")
    {
      var localizedFormat = require('dayjs/plugin/localizedFormat')
      dayjs.extend(localizedFormat)

      var body

      if(Status && Status === "RMA") body = `Your item (PO #: ${PurchaseOrder?PurchaseOrder:"(RMA)"}) has been moved to RMA on: ${dayjs().format('LLLL')}.\n\nItem:\n${update?.InventoryID ? productMap.get(inventoryMap.get(update?.InventoryID)?.ProductItems?.[0]?._id)?.DISPLAY_NAME||inventoryMap.get(update?.InventoryID)?.ProductItems?.[0]?.Product?.DISPLAY_NAME : "Could Not Find Product"}`
      else if (Status === "Order Checked On") body = `Product:\n${update?.InventoryID ? productMap.get(inventoryMap.get(update?.InventoryID)?.ProductItems?.[0]?._id)?.DISPLAY_NAME||inventoryMap.get(update?.InventoryID)?.ProductItems?.[0]?.Product?.DISPLAY_NAME : "Could Not Find Product"}\n\nThe status is:\n${update?.Status||Status}\n\nIncluded notes were: ${update?.CheckedOnNotes || outputRef.current.checkedOnNotes || "No Notes"}\n\nItem was set as "Order Checked On" on:\n${dayjs().format('LLLL')}`
      else if(Status === "Shipping") body = `Product:\n${update?.InventoryID ? productMap.get(inventoryMap.get(update?.InventoryID)?.ProductItems?.[0]?._id)?.DISPLAY_NAME||inventoryMap.get(update?.InventoryID)?.ProductItems?.[0]?.Product?.DISPLAY_NAME : "Could Not Find Product"}\n\nThe status is:\n${update?.Status||Status}\n\nYour tracking number is:\n${TrackingNumber?TrackingNumber:outputRef.current.TrackingNumber?outputRef.current.TrackingNumber:update?.TrackingNumber}\n\nItem was set as shipping on:\n${dayjs().format('LLLL')}\n\nDelivery Location:\n${update?.InventoryID ? divisionMap.get(inventoryMap.get(update?.InventoryID)?.DivisionID)?.teamName : "No Warehouse Assigned"}`
      else body = `Product:\n${update?.InventoryID ? productMap.get(inventoryMap.get(update?.InventoryID)?.ProductItems?.[0]?._id)?.DISPLAY_NAME||inventoryMap.get(update?.InventoryID)?.ProductItems?.[0]?.Product?.DISPLAY_NAME : "Could Not Find Product"}\n\nYour Quantity Received is:\n${update?.QTYReceived?update?.QTYReceived:QTYReceived?QTYReceived:0}\n\nYour Quantity on Hand is:\n${update?.QTYOnHand?update?.QTYOnHand:QTYOnHand?QTYOnHand:0}\n\nThe status is:\n${update?.Status?update?.Status:Status==="Order Placed"?"Order Processing":Status}\n\nLocation:\n${update?.InventoryID ? divisionMap.get(inventoryMap.get(update?.InventoryID)?.DivisionID)?.teamName : "No Warehouse Assigned"}\n\nJob Name & Number:\nName: ${update?.InventoryID ? projectMap.get(inventoryMap.get(update?.InventoryID)?.ProjectID?.[0])?.ProjectTitle : "No Associated Project"}\nJob #: ${update?.InventoryID ? projectMap.get(inventoryMap.get(update?.InventoryID)?.ProjectID?.[0])?.PoNumber : "No Associated Project"}\n\nItem was modified on:\n${dayjs().format('LLLL')}`

       var myEmail = UserMap.get('email')
       var nameFUll = `${UserMap.get('firstName')} ${UserMap.get('lastName')}`
       newNotification({Title:`${UserMap.get('firstName')} ${UserMap.get('lastName')} has modified inventory item PO #: ${PurchaseOrder?PurchaseOrder:inventoryMap.get(update?.InventoryID)?.PurchaseOrder?inventoryMap.get(update?.InventoryID)?.PurchaseOrder:outputRef.current.poNum?outputRef.current.poNum:"(Cannot find PO #)"}`,Body:body,Data:null,Avatar:null,Icon:null,Choice:{AppNotification:true,Text:false,Email:true},avatar:Requestor,mobile:teamMember.get(Requestor)?.mobilePhone,Email:teamMember.get(Requestor)?.email,myEmail:myEmail,EmailForm:"Fancy",name_u:nameFUll,sender:UserMap.get('avatar'),receiver:Requestor},({sucess,info,err})=>{
           if (err) globalWrn('error', `Failed to email notify project manager`);
           setPopup2({open:false})
       })
     }
  };

  // File Upload functions
  const tempfileUploader =(date,ProjectID,InventoryID)=>{
    stateRef.createNewFolder(`/EKC/Projects/${ProjectID}`,'BOL')
  
    var path = `./EKC/Projects/${ProjectID}/BOL`
    if(uploadTempFilesRef.current?.[ProjectID])
      Promise.all(Object.entries(uploadTempFilesRef.current?.[ProjectID]).flatMap(([k,{data,name,index}])=>{
        imageCompress({image:data, writePath:path, fileName:name.replace(".",`( ${k+'_'+index} ).`)}, ({sucess,err})=>{
          if(sucess===true){
            globalWrn('success',`Your File was Uploaded.`)
            uploadChecker(ProjectID, date, InventoryID)
          }
          else globalWrn('error',`There was an error uploading your file(s).`)
        })
    })).then(()=>{
      setUploadTempFiles({})
      setPopup({open: false})
    })
  };

  function downloadBOL(path, fileName) {
    const handleDownload = (url, fileName) => {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.download = fileName || "downloaded-file";
          document.body.appendChild(link);
  
          link.click();
  
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error fetching the file:", error);
        });
    };

    handleDownload(path, fileName);
  }

  function FreightLog(ProjectID, InventoryID){
    if(!ProjectID) var ProjectID = inventoryMap.get(InventoryID)?.ProjectID?.[0]
    stateRef.getFilesList(`/EKC/Projects/${ProjectID}/BOL`).then(({data})=>{ 
    })
    return stateRef.getFilesList(`/EKC/Projects/${ProjectID}/BOL`).then(({data})=>{ 
      if(data?.children && inventoryMap.get(InventoryID)?.projectStagingZone) var value = <Box> {data?.children?.flatMap(({name,path,created})=>
        <Card>
        <CardContent sx={{display: 'flex', alignItems: 'center'}}>
          <Typography>
            {`Project Staging Zone: ${inventoryMap.get(InventoryID)?.projectStagingZone}`}
            </Typography>
            <CardActions>
              {dynamicComponent('DefaultButton','elements',{state:'donwloadBOL',label:'Download BOL',type:'button',startIcon:<CloudDownloadIcon/>},mainState,[()=>downloadBOL(path,name)])}
            </CardActions>
        </CardContent>
      </Card>
      )}
        </Box>
      else var value = <Box>
        <Card>
          <CardContent>
            <Typography>
              {`No BOL found for ${projectMap.get(ProjectID)?.ProjectTitle}`}
            </Typography>
          </CardContent>
        </Card>
      </Box>

      setBOLState(value, InventoryID)
    })
  }

  function setBOLState(value, InventoryID){
    setState(p=>({...p,
    ['BOLState_'+[InventoryID]]: value
    }))
  }

  function uploadChecker(ProjectID, date, InventoryID){
    if(!ProjectID) var ProjectID = inventoryMap.get(InventoryID)?.ProjectID?.[0]
    stateRef.getFilesList(`/EKC/Projects/${ProjectID}/BOL`).then(({data})=>{ 
      if(data?.children) setState(p=>({...p,uploaded : data?.children,
      }))
      })
  };
  // -------------------------------

  const createVendor=()=>{
    setState(p=>({...p,autocompleteReload:undefined}))
    setPopup2(p=>({...p,
      open:true,
      title:`Create New Vendor`,
      description: `An account number and Vendor name is required. All others are optional.`,
      inputField: <Box sx={{width:'60vw'}}>{
        dynamicComponent('AddVendor','elements',{state:'vendor',label:'Add an Account Number'},mainState)
      }</Box>,
      handleClose: ()=>setPopup2({open:false}),
      nameInputSets: {},
  }))  
  };

  const resetVendorChoiceField=(_id)=> {
   setOutput(p=>({...p,
    vendorID: inventoryMap.get(_id)?.PreferredVendor?.[0]
   })); 
    setState(p=>({...p,
      vendorField: dynamicComponent('AutoComplete','elements',{state:'vendorID',label:'Selected Vendor',type:'text',single: true,defaultValue:inventoryMap.get(_id)?.PreferredVendor?.[0],model:'Vendors',query:{},filter:{},addEntry:createVendor},outputState),
    }));
  };

  useEffect(()=>{
      CellFocusFunc(({UserID,Column,Row})=>{
        
        for (const [k,v] of CellFocus) {
          if(v.UserID===UserID) setCellFocus({t:'delete', k})
        }

        setCellFocus({t:'set',k:Column+Row,v:{UserID,Column,Row}})
      })
    
      return ()=> inventoryFocus({UserID:UserMap.get('avatar'),Column:null,Row:null})
  },[]);

  const submitNewItem=(update,callBK)=>{
    dbUpdateValidate({model:'Inventory',queryID:false,query:update,update},({success,info,err})=>{
    if(callBK) callBK({success,info,err})
    if(success){
      setPopup2({open: false})
      rebuild()
    globalWrn('success',`New inventory item for ${projectMap.get(update?.ProjectID?.[0])?.ProjectTitle||"No Project Name"} was created.`)
    }
  })
}

  const apprSubmit=({update,InventoryID,paramters},callBK)=>{// check if popup is needed
    if(!update) return; // Saftey
    // Get Data if it does not exist
    if(update && !update?.InventoryID) update.InventoryID = parameters?.InventoryID
    if(!paramters) paramters = parameters
    if(!InventoryID) InventoryID = update.InventoryID

    // Add status to update
    if(!update.Status) update.Status = inventoryMap.get(update?.InventoryID)?.Status

    // Saftey
    if(inventoryMap.has(InventoryID) && update?.QTYReceived > inventoryMap.get(InventoryID)?.QTYOrdered && update?.Status !== "Stock") {
      update.QTYReceived = inventoryMap.get(InventoryID)?.QTYOrdered
    }

  if(update.Status === "Order Checked On"){ // popup needed
    setPopup(p=>({...p,
      open: true,
      title: `Please fill out the fields (optional)`,
      inputField: stateRef.inputCheckedOnNotes,
      handleSubmit: ()=>{
        const notes = (inventoryMap.get((update?.InventoryID||InventoryID))?.CheckedOnNotes ? inventoryMap.get((update?.InventoryID||InventoryID))?.CheckedOnNotes : "") + `\nNew "Order Checked On" Notes: ` + outputRef.current.checkedOnNotes ? outputRef.current.checkedOnNotes : ""
        const saveObj = {
            quantityType: paramters.ColumnID,
            timestamp: new Date(),
            Notes: notes,
            MSRP: productMap.get(inventoryMap.get((update?.InventoryID||InventoryID))?.ProductItems?.[0]?.id)?.MSRP,
            userID: UserMap.get('avatar')
          }
        submitItems({...update,CheckedOnNotes:outputRef.current.checkedOnNotes},saveObj,paramters,callBK)
      },
      handleClose: ()=>{
        setPopup({open:false})
      }
    }))
  }
  else{ // popup not needed, proceed to submit
    var notes = automatedNotesResp()

  if(notes === false) return // Back out

  const saveObj = {
    type: update?.Status ? update?.Status : inventoryMap.get((update?.InventoryID||InventoryID))?.Status,
          quantityType: paramters.ColumnID !== 'Status' ? paramters.ColumnID : 'Status',
          quantity: paramters.ColumnID !== 'Status' && Object.keys(update?.[paramters?.ColumnID]).length > 0 ? update?.[paramters?.ColumnID] : 0,
          timestamp: new Date(),
          Notes: notes,
          MSRP: productMap.get(inventoryMap.get((update?.InventoryID||InventoryID))?.ProductItems?.[0]?.id)?.MSRP,
          userID: UserMap.get('avatar')
    }

  submitItems(update,saveObj,paramters,callBK)
  }
};
var submitItems=(update,dates,paramters,callBK)=>{ 

if(inventoryMap.get(update.InventoryID)) var {Status, QTYOrdered, QTYReceived, QTYOnHand, ProjectID, PurchaseOrder} = inventoryMap.get(update.InventoryID);

if(update.Status !== "Stock") update.Status = getStatus({Status:update?.Status||Status, QTYOrdered, QTYReceived:update?.QTYReceived!==undefined?update.QTYReceived:QTYReceived, QTYOnHand:update?.QTYOnHand!==undefined?update.QTYOnHand:QTYOnHand});
dates.type = update.Status || Status;
if(update.$push) update.$push = {...update.$push, dates: dates}
else update.$push = {dates:dates};

// Saftey
if(Object.keys(update).includes("QTYOnHand") && typeof update?.QTYOnHand === 'object') update.QTYOnHand = 0 
dbUpdateValidate({model:'Inventory',queryID:true,query:update.InventoryID,update,idCreate:paramters?.idCreate},({success,info,err})=>{
if(callBK) callBK({success,info,err})
if(success){
globalWrn('success',`Inventory item for ${projectMap.get(ProjectID?.[0])?.ProjectTitle} was updated.`)
if(paramters){
  var {row} = paramters;

  if(!row) row = parameters?.row

  Promise.all( Object.entries(update).flatMap(([k,v])=>{
    if(row) {
    if(k === 'Status'){
      row.original.Status = v;
      return row._valuesCache[k] = v;
    } 
    if(k !== '$push' && row?._valuesCache) return row._valuesCache[k] = v;
    }
  })).then(()=> {
    table.setEditingCell(null);
})

  if(stateRef.rightList) var uniqueVal = removeInstances(stateRef?.rightList, transferRequest.current)
              //store it in transferRequest

  setTransferRequest(uniqueVal)

  setState(p=>({...p,
    clearTransf: true,
  }))
}
closeAllPopups()
setOutput({}) // clear output state
const inventoryID_temp = update.InventoryID
submitEmailChange(projectMap.get(ProjectID?.[0])?.ProjectState?.projManager,update,{QTYReceived,QTYOnHand,PurchaseOrder,Status:update?.Status||Status},inventoryID_temp)
}else globalWrn('error',`We had an issue updating inventory item for ${projectMap.get(ProjectID?.[0])?.projectName}.`)
})

return
};

// QTY Received
const changedQTYReceived=({row,ColumnID,value,InventoryID,index,_valuesCache,updateCell})=>{
   if(inventoryMap.has(InventoryID)) var {QTYReceived,QTYOrdered,QTYOnHand,Status,ProjectID,_id,PurchaseOrder,projectStagingZone,OrderNumber} = inventoryMap.get(InventoryID);
   var update = {[ColumnID]:value}

   if(Status === "Stock") 
   {
    apprSubmit({update:{...update, QTYReceived: value, QTYOnHand: value, QTYOrdered: value, Status: "Stock"},paramters:{row,ColumnID,value,InventoryID,index,_valuesCache,updateCell}})
    return;
   }
    if(['Order Paused','Order Unavailable','Order Placed','Requested'].includes(Status)) return setPopup(p=>({...p,
      open:true,
      zIndex: 1,
      title:`This Line Item's Current Status Can Not Allow Received Inventory.`,
      description:`You originally had ${QTYReceived||0} and now you tring to modify it to ${value}. You will need to Fully Edit this Inventory Item.`,
      handleClose: closeAllPopups,
    })) 
    const QYTOnHandVal = QTYReceived!==undefined && QTYOnHand!==undefined ? ((value-QTYReceived)+QTYOnHand) : value
     if(value>QTYOrdered && Status !== "Stock") setPopup2(p=>({...p,
       open:true,
       zIndex: 2,
       title:`DO YOU WANT TO CREATE A NEW INVENTORY ITEM`,
       description:`${value-QTYOrdered} Item(s) will be created in an new inventory line item.`,
       handleClose: closeAllPopups,

       handleSubmit: ()=>{
        const inventoryValues = {...inventoryMap.get(InventoryID)}
        delete inventoryValues._id
        delete inventoryValues.InventoryID
        var newVal = {...update,...inventoryValues,Status: "Warehouse Received", inventoryReceived: [dayjs()],OrderNumber,PurchaseOrder:PurchaseOrder?PurchaseOrder:`No PO`+` (Over Ordered - ${dayjs().format('MMM Do YY - h:mm a')})`, ProjectID:ProjectID,QTYReceived:Math.abs(value-QTYOrdered), QTYOrdered:Math.abs(value-QTYOrdered), QTYOnHand:Math.abs(value-QTYOrdered)}
        submitNewItem(newVal)
       }
     })) 

     if(QTYReceived && value<QTYReceived) return setPopup(p=>({...p,
       open:true,
       zIndex: 1,
       title:`You cannot apply less than what you received prior.`,
       description:`You origanally had ${QTYReceived?QTYReceived:0} and now you tring to modify it to ${value}. You will need to Fully Edit this Inventory Item.`,
       handleClose: cancelUndo,
     })) 

     // Uploading BOL functions
      var items = [ {id:'InputField',loc:'elements',viewData:{state:'label',label:'Photo/Video Of',type:'text',minWidth:'100%'}}, {id:'Dropzone',loc:'elements',viewData:{state:'addFile',label:'Photo/Video'}} ]
      var lists = dynamicComponent('Listable','elements',{state:'addFiles',label:'+ Add',items:items,defaultValue:null,required:false},mainState,[(x)=>{
        if(x.addFiles) x.addFiles.forEach(({label,addFile})=>{ 
          if(label && addFile?.length > 0){
          addFile.forEach((x,i)=> setUploadTempFiles(p=>({...p,[ProjectID]:{...p[ProjectID],[label]:{type:'AddFile', index:i, name:x.name, fileType:x.type, data:x}}})))
          }
          })
      }])
      // ------------------------

    
     if(value===QTYOrdered && ["Shipping","Partial Received"]?.includes(Status)) update = {...update,"Status":"Warehouse Received"};
     if(value>0&&value!==QTYOrdered&&["Shipping"]?.includes(Status)) update = {...update,"Status":"Partial Received"};
     if(projectStagingZone) {
      const inventoryReceived = inventoryMap.get(InventoryID)?.inventoryReceived
      const QTYOrdered = inventoryMap.get(InventoryID)?.QTYOrdered

      setPopup(p=>({...p,
       handleSubmit: ()=> {
        apprSubmit({update:{...update,QTYOnHand:update?.QTYOnHand > QTYOrdered ? QTYOrdered : update?.QTYOnHand,QTYReceived: update?.QTYReceived > QTYOrdered ? QTYOrdered : update?.QTYReceived,inventoryReceived: inventoryReceived ? inventoryReceived.concat([dayjs()]) : [dayjs()],projectStagingZone,QTYOnHand:QYTOnHandVal,InventoryID},paramters:{row,ColumnID,index,_valuesCache,updateCell,value}})
       
      }
      }))
    }
     var otherItems = [inventoryMap.get(_id)]?.filter(({_id,ProjectID,projectStagingZone})=> _id !==InventoryID && ProjectID?.includes(ProjectID?.[0]) && projectStagingZone)?.flatMap(({projectStagingZone})=>({primary:projectStagingZone}))
     var newDrop = dynamicComponent('DropDown','elements',{state:'stockShelf',label:'Select Zone', list:otherItems,replace:true},mainState)
     return setPopup(p=>({...p,
       open:true,
       zIndex: 1,
       title:`Please set a Project Staging Zone`,
       description:`If any inventory line item has job # and a Staging Zone this will defult to that Zone# unless changed.`,
       inputField: [
       <Box sx={{p:'1%',display:'flex',gap:'2%'}}>
         {dynamicComponent('InputField','elements',{state:'projectStagingZone',label:'Project Staging Zone',defaultValue:projectStagingZone ,type:'text',error:'Project Staging Zone Needed',style:{margin:0}},outputState,[()=>projectStage({projectStagingZone:outputRef.current.projectStagingZone,QTYReceived:value, InventoryID,ColumnID,index,value,_valuesCache,updateCell,row})])}
         {newDrop&&newDrop.length>0&&newDrop}
         </Box>,
         <Typography sx={{width:'100%',p:'1% 5% 0'}}>Upload BOL: (Optional)</Typography>,
         <Box sx={{p:'1%'}}>{lists}</Box>],
       handleClose: closeAllPopups,
     })) 
}

// Start of Transfer Request Functions -------------------------

const handleRMAItem = ({ColumnID,InventoryID,RMAItem,QTYOnHand,TransferAmount,value}) => {
  const curItem = RMAItem;
  var update = {[ColumnID]:value};

  if(!QTYOnHand) QTYOnHand = inventoryMap.get(InventoryID)?.QTYOnHand

  const Dates = inventoryMap.get(InventoryID)?.Dates
  var dates = Dates || []

  const notes = `RMA Item Created on ${dayjs().format("LLL")}`
  dates.push({
      type: "Returned for RMA",
      quantityType: ColumnID !== 'Status' ? ColumnID : 'Status',
      quantity: TransferAmount,
      timestamp: new Date(),
      Notes: notes,
      MSRP: productMap.get(inventoryMap.get((InventoryID||update?.InventoryID))?.ProductItems?.[0]?.id)?.MSRP,
      userID: UserMap.get('avatar')
  })

  const dateNow = new Date();

  const receivedNow = [dateNow?dateNow:""];

  if(!curItem?.QTYOnHand || Object.keys(curItem?.QTYOnHand).length === 0) curItem.QTYOnHand = 0
  if(curItem?._id) delete curItem._id

  delete curItem.__v
  delete curItem.ProductItems[0]._id
  delete curItem.InventoryID

  if ((value < QTYOnHand) && (QTYOnHand-TransferAmount < 0)) return globalWrn('error', 'You cannot RMA more items than the Quantity on Hand!')
  else
    dbUpdateValidate({
      model:'Inventory',
      query:{...curItem,Notes:stateRef.RMANotes||"No Notes",inventoryReceived:receivedNow.concat(curItem?.inventoryReceived),dates:dates,RMANumber:stateRef.RMATrack||"No Number",Status:"Returned for RMA",QTYOrdered:0,QTYReceived:Math.abs(TransferAmount),QTYOnHand:Math.abs(TransferAmount),PurchaseOrder:curItem?.PurchaseOrder?curItem?.PurchaseOrder+'(RMA)':'RMA',timestamp:dateNow},
      update:{Notes:stateRef.RMANotes||"No Notes",inventoryReceived:receivedNow.concat(curItem?.inventoryReceived||[]),dates:dates,RMANumber:stateRef.RMATrack||"No Number",Status:"Returned for RMA",QTYOrdered:0,QTYReceived:Math.abs(TransferAmount),QTYOnHand:Math.abs(TransferAmount),PurchaseOrder:curItem?.PurchaseOrder?curItem?.PurchaseOrder+'(RMA)':'RMA',timestamp:dateNow}},
      ({success})=>{
      setState(p=>({...p,loading : false }))
      if(success){
        closeAllPopups();
        globalWrn('success',`${curItem?.PurchaseOrder || "RMA"} was created.`)
        submitEmailChange(projectMap.get(inventoryMap.get(InventoryID)?.ProjectID?.[0])?.ProjectState?.projManager,{InventoryID:InventoryID},{QTYReceived:curItem?.QTYReceived,QTYOnHand,PurchaseOrder:curItem?.PurchaseOrder,Status:"RMA"})
    
        rebuild();
      }
      else {
        globalWrn('error',`We had an issue creating ${curItem?.PurchaseOrder ? curItem?.PurchaseOrder : "RMA"}.`)
        closeAllPopups()
      }
    })
  return true;
};

const handleStockItem = ({InventoryID,StockItem,TransferAmount,row}) => {
  var ProductID = inventoryMap.get(InventoryID)?.ProductItems?.[0]?.Product?._id
  var stockItemID = [].concat(...Array.from([...inventoryMap],([k,{Status,ProductItems}])=>{ 
    if(Status==='Stock' && ProductItems?.[0]?.id === ProductID) return k
    return []
  }))
  var item = stockItemID.filter((x)=>x?.ProductItems?.[0]?.id === inventoryMap.get(stockItemID)?.ProductItems?.[0]?.id) // ArrayStoreRef.current?._array?.filter(({_id})=> stockItemID.includes(_id))
  
  
  var curItem = {...inventoryMap.get(InventoryID)}

  if(item?.length > 0) {
  // Clear Popup
  setPopup4({open:false})
  // Build Popup
  setPopup4(p=>({...p,
  open: true,
  zIndex: 1,
  handleClose: ()=>{setPopup4({open:false}); setState(p=>({...p,activePopup:false}))},
  title: `Modify desired line item`,
  description: `Please increase the desired stock item by ${TransferAmount} on Quantity on Hand and close.`,
  inputField: dynamicComponent('InventoryGrid','elements',{state:'DataGridBuilder',data: StockItem.flatMap(x=>inventoryMap.get(x)) ,alignments:alignments,onSaving:onSaving,gridRef:gridRef2,defaultArray:defaultArray,disableGrouping:true},mainState)
 }))
}
else {
  const divisionDefaultProj = divisionMap.get(projectMap.get(curItem?.ProjectID?.[0])?.DivisionID).projectID
  setPopup4(p=>({...p,
    open:true,
    zIndex: 1,
    button03: undefined,
    helper: undefined,
    handleClose: setPopup4({open: false}),
    title: `Create New Inventory Line Item`,
    description: `Please create a new stock inventory item with QTY ${Math.abs(TransferAmount)}. We could not find an existing stock inventory item for this product, so you must create a new one.`,
    inputField: dynamicComponent('AddInventory','elements',{state:'inventoryScripts',defaultValue:{...curItem,QTYOrdered:Math.abs(TransferAmount),QTYReceived:Math.abs(TransferAmount),QTYOnHand:Math.abs(TransferAmount), ProjectID: divisionDefaultProj?.[0]}, isStock:true, PopupVal: 4, statusStock:true, table, row},outputState),
  }))
}
};

// Submit
useEffect(()=>{
  if(outputRef?.current?.requestor && outputRef.current?.signedName && stateRef.sign && stateRef.rightList){
    setPopup(p=>({...p,
      handleSubmit: ()=>{ 
        var timestamp = new Date()
        timestamp = dayjs(timestamp).format()
        Promise.all([
          stateRef.rightList.flatMap((x)=>{
            var {ColumnID,InventoryID,QTYOnHand,TransferAmount,Status,value,index,_valuesCache,updateCell,row} = x;
            var update = {[ColumnID]:value};

            if(x?.RMAItem) var RMAItem = x.RMAItem;
            if(x?.StockItem) var StockItem = x.StockItem;
          // If Item is an RMA Item
          if(RMAItem) var rmaResp = handleRMAItem({timestamp,ColumnID,InventoryID,RMAItem,QTYOnHand,TransferAmount,Status,value,index,_valuesCache,updateCell});
          if(rmaResp === false) return;
          // If Item is a Stock Item
          if(StockItem) handleStockItem({timestamp,ColumnID,InventoryID,StockItem,QTYOnHand,TransferAmount,Status,value,index,_valuesCache,updateCell,row})

          if(outputRef.current.requestor) {
            if(outputRef.current.deliveryDate) {
              if(outputRef.current.deliverDriver) var TransferType = "Delivery"
              else var TransferType = "Remote"
            }
            else var TransferType = "Direct"
          }

         var transferPush = {$push:{TransferRequests: {Status,InventoryID,ProductID:inventoryMap.get(InventoryID)?.ProductItems?.[0]?.id,TransferAmount,...outputRef.current,TransferType,QTYOnHand, authorizor:UserMap.get('avatar'),timestamp:timestamp}}};

         // Ensure that original item's QTYOnHand does not increase if you 
         // RMA increase an item (add QTYOnHand)
         if(RMAItem && inventoryMap.get(InventoryID)?.QTYOnHand < value) update.QTYOnHand = QTYOnHand||inventoryMap.get(InventoryID)?.QTYOnHand
         update = {...update, ...transferPush};

         // If you are transferring into stock, set the right amount
         if(StockItem && value - inventoryMap.get(x.InventoryID)?.QTYOnHand > 0) update.QTYOnHand = inventoryMap.get(x.InventoryID)?.QTYOnHand
         else if(StockItem) update.QTYOnHand = value

         apprSubmit({update:{...update,InventoryID},paramters:{row,ColumnID,index,_valuesCache,updateCell,value,idCreate:"$push.TransferRequests"}},({success,info,err})=>{ 
          // dbUpdateValidate({model:'Inventory',queryID:true,query:x.InventoryID,update:{'QTYOnHand':x.value, $push:{TransferRequests: {...x,...outputRef.current,authorizor:UserMap.get('avatar'),timestamp:timestamp}}}},({success,info,err})=>{ 
            if(err) return globalWrn('error',`We had an issue saving the transfer request for ${outputRef.current?.DISPLAY_NAME}.`)
            if(success){
              saveSignature(InventoryID,info._id,timestamp)
              //remove instances
              
              updateCell(value)

              closeTransferList()
              return setPopup({open: false})
            }}) 
          })
        ])
      }
  }))
  }
},[outputRef.current?.requestor, outputRef.current?.signedName, stateRef.sign ])

// Save Signature
const saveSignature = (_id, productID,timestamp)=>{
  stateRef.createNewFolder(`./public/uploads/inventory/${_id}/${productID}`,'transfer')
  var saved = sig.current?.toDataURL("image/png",1)
  if(saved) imageCompress({ image: dataURItoBlob(saved), writePath: `./public/uploads/inventory/${_id}/${productID}/transfer/`, fileName: outputRef?.current?.requestor+productID+timestamp+'.png' },({sucess,err})=>{
    if(sucess===true) globalWrn('success',`Your Signature Was Submitted.`)
    else globalWrn('error',`There was an error updating your signature.`)
  })
  }

  function removeInstances(arr1, arr2) {
    return arr2.filter(item1 => !arr1.some(item2 => item1?.tempTime === item2?.tempTime))
  };

  const getStatus=({Status,QTYOrdered,QTYReceived,QTYOnHand})=>{
    if(Status === "Returned for RMA") return Status
    else if(Status === "Shipping" && (!QTYReceived || QTYReceived === 0)) return "Shipping"
    else if (Status === "Stock") return Status
    else if (Status === "Order Checked On") return Status
    else if(QTYReceived === QTYOrdered && QTYOnHand === 0 && Status!=="Delivered to Site") return "Delivered to Site"
    else if(QTYOrdered > QTYReceived && QTYOnHand < QTYReceived && QTYReceived > 0 && Status!=="Partially Received and Delivered") return "Partially Received and Delivered"
    else if(QTYOrdered > QTYReceived && QTYOnHand === QTYReceived && QTYReceived > 0 && Status!=="Partial Received") return "Partial Received"
    else if(QTYOrdered === QTYReceived && QTYReceived === QTYOnHand && !['Stock','RMA','Warehouse Received'].includes(Status)) return "Warehouse Received"
    else if((QTYOrdered === QTYReceived) && (QTYOnHand > 0) && (QTYOnHand < QTYReceived) && Status!=="Partial to Site" ) return "Partial to Site"
    else if(QTYReceived === 0 && QTYOnHand === 0 && Status!=="Shipping") return "Shipping"
    else return Status
  }

  const dataURItoBlob = (dataURI)=>{
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString,
        mimeType,
        typedArray,
        i;

    // extract data string and convert to base64 if necessary
    byteString = (dataURI.split(',')[0].indexOf('base64') >= 0) ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);

    // separate out the mime component
    mimeType = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    typedArray = new Uint8Array(byteString.length);

    for (i = 0; i < byteString.length; i++) {
        typedArray[i] = byteString.charCodeAt(i);
    }

    return new Blob([typedArray], {type: mimeType});
}

// Popup 
useEffect(()=>{
  if(transferRequest.current?.length>0&&!stateRef.transferButton) setState(p=>({...p,transferButton: dynamicComponent('DefaultButton','elements',{state:'transfer',label:'Transfer Item(s)',type:'button',startIcon:<Transform/>,style:{backgroundColor:'#90caf9',color:'#000',padding: '1% 3%', borderRadius: '4px', lineHeight: 1}},mainState,[()=>{
    setPopup(p=>({...p,
      open:true,
      zIndex: 1,
      title:`Inventory Sign Off`,
      width:'75vw',
      description:`Select User to transfer item(s) to`,
      helperObject: dynamicComponent('ToggleBttnGroups','elements',{state:'userOpt',style:{backgroundColor:'unset',border:'unset'}, Items:[{type:'single',label:'TRANSFER OPTIONS',subState:'TransferType',items:[{label:'Direct'},{label:'Remote'},{label:'Delivery'}]}]},mainState,[({userOpt:{TransferType}})=>{
        if(TransferType) Object({
          Direct: ()=> setPopup(p=>({...p, headerButton: <BoxGroup sx={{display:'flex',m:'.5%', gap:'2%'}}> { dynamicComponent('UserButton','elements',{state:'requestor',label:'Ownership',style:{margin:'auto'},defaultValue:outputRef.current.requestor},outputState) } </BoxGroup> })),
          Remote: ()=> setPopup(p=>({...p, headerButton: <BoxGroup sx={{display:'flex',m:'.5%', gap:'2%'}}> { dynamicComponent('UserButton','elements',{state:'requestor',label:'Ownership',style:{margin:'auto'},defaultValue:outputRef.current.requestor},outputState) } <Divider orientation="vertical" variant="middle" flexItem /> <Box sx={{margin:'auto'}}>{dynamicComponent('DatePicker','elements',{state:'deliveryDate',label:'Pickup Date', defaultValue:outputRef.current.deliveryDate },outputState) }</Box> </BoxGroup> })),
          Delivery: ()=> setPopup(p=>({...p, headerButton: <BoxGroup sx={{display:'flex',m:'.5%', gap:'2%'}}> {dynamicComponent('UserButton','elements',{state:'requestor',label:'Ownership',style:{margin:'auto'},defaultValue:outputRef.current.requestor},outputState) } <Divider orientation="vertical" variant="middle" flexItem /> {dynamicComponent('UserButton','elements',{state:'deliverDriver',label:'Delivery Driver',style:{margin:'auto'},defaultValue:outputRef.current.deliverDriver},outputState) } <Divider orientation="vertical" variant="middle" flexItem /> <Box sx={{margin:'auto'}}>{dynamicComponent('DatePicker','elements',{state:'deliveryDate',label:'Delivery Date', defaultValue:outputRef.current.deliveryDate },outputState) }</Box> </BoxGroup> })),
        })[TransferType]()

    }]),
      headerButton: [transferRequest?.current?.filter(x=>x?.stagingZoneReturn && x?.stagingZoneReturn === true).length > 0 && <BoxGroup sx={{display:'flex',m:'.5%', gap:'2%'}}> 
      { dynamicComponent('UserButton','elements',{state:'prevOwner',label:'Previous Owner',defaultValue:outputRef.current.prevOwner},outputState) } </BoxGroup>,
      <BoxGroup sx={{display:'flex',m:'.5%', gap:'2%'}}> 
      { dynamicComponent('UserButton','elements',{state:'requestor',label:'Ownership',defaultValue:outputRef.current.requestor},outputState) }
       </BoxGroup>],
      handleClose: ()=>closeTransferList(),
    }))
}])
}))
  else if(transferRequest.current?.length === 0) setState(p=>({...p,transferButton:undefined}))
},[transferRequest.current?.length])

// Signature Canvas
useEffect(()=>{
  if(!stateRef?.signCanvas && sig2.current?.offsetWidth) setState(p=>({...p,
   signCanvas: <SignatureCanvas penColor='white' ref={sig}
               canvasProps={{width: `${sig2.current?.offsetWidth}px`, height: 200, className: 'sigCanvas'}} onEnd={()=>{
               setState(p=>({...p,sign:true}))
               setTimeout(() => {
               setPopup(p=>({...p,
                 helper: {
                   label: `Clear Signature`,
                   action: ()=>{
                     sig.current.clear()
                     setState(p=>({...p,sign:undefined}))
                     setPopup(p=>({...p,
                       helper: undefined,
                       handleSubmit: undefined
                     }))
                   }
                 }
                 }))
               }, "100");
               }} />
   }))
 },[sig2.current?.offsetWidth])

 // Input Field for signature canvas
useEffect(()=>{
  if(stateRef.rightList?.length>0&&!stateRef.sigQued){
    setState(p=>({...p, sigQued: true }))
    setPopup(p=>({...p,
      inputField: [...p.inputField,
        <Box sx={{p:'1%'}}>
         <Box>{stateRef?.textBox}</Box>
         <Box><Typography>I acknowledge that I am responsible for the item I am receiving as well as its condition and wareabouts</Typography></Box>
         <Box sx={{border:'1px solid rgba(255,255,255,.35)', borderRadius:4}}><Divider variant="middle" >Please Sign Here</Divider>{stateRef?.signCanvas}</Box>
         </Box>]
    }))
  }else if(stateRef.rightList?.length===0&&stateRef.sigQued){
    setState(p=>({...p, sigQued: false }))
    setPopup(p=>({...p,
      inputField: [<Box sx={{padding:'1%'}} ref={sig2} >{stateRef?.transferList}</Box>]
    }))
  } 
},[stateRef.rightList])

// Input Field
useEffect(()=>{
  setState(p=>({...p,
    transferList: dynamicComponent('TransferList','elements',{state:'transferList', list:transferRequest},mainState),
    textBox:<Box>{dynamicComponent('InputField','elements',{state:'signedName',label: `Have ${teamMember.get(outputRef?.current?.requestor)?.firstName} write his/her full name:` ,type:'text'},outputState)}</Box>,
    typog: <Box><Typography>I ({teamMember.get(outputRef?.current?.requestor)?.firstName} {teamMember.get(outputRef?.current?.requestor)?.lastName}) acknowledge that I am responsible for the item I am receiving as well as its condition and wareabouts </Typography></Box>,
  }))
  },[outputRef.current.requestor])

  // Controller for multi-type transfer
useEffect(()=>{
  function addList(){
    setPopup(p=>({...p,
      inputField: [<Box sx={{padding:'1%'}} ref={sig2} >{stateRef.transferList}</Box>]
  }))
  }
  function clear(){
    setPopup(p=>({...p,
      inputField: undefined,
    }))
  }
  if(outputRef.current.requestor && ((transferRequest?.current?.filter(x=>x?.stagingZoneReturn && x?.stagingZoneReturn === true).length > 0 && outputRef.current.prevOwner) || (transferRequest?.current?.filter(x=>!x?.stagingZoneReturn).length > 0)) && !stateRef.userOpt) addList()
  else if(stateRef.userOpt && stateRef.userOpt?.TransferType !== null) Object({
      Direct:()=>{
        if(outputRef.current.requestor && ((transferRequest?.current?.filter(x=>x?.stagingZoneReturn && x?.stagingZoneReturn === true).length > 0 && outputRef.current.prevOwner) || (transferRequest?.current?.filter(x=>!x?.stagingZoneReturn).length > 0))) addList()
      }, 
      Remote:()=>{
        if(outputRef.current.requestor && outputRef.current.deliveryDate) addList()
        else clear()
      },
      Delivery:()=>{
        if(outputRef.current.requestor && outputRef.current.deliveryDate && outputRef.current.deliverDriver) addList()
        else clear()
      },
    })[stateRef.userOpt?.TransferType]()
  },[outputRef.current?.requestor,stateRef.userOpt?.TransferType, outputRef.current.deliveryDate, outputRef.current.deliverDriver, outputRef.current.prevOwner])

  // End Transfer Request Buttons -------------------------------

// QTY On Hand
const changedQTYOnHand=({row,ColumnID,value,InventoryID,index,_valuesCache,updateCell})=>{
var {QTYReceived,QTYOnHand,QTYOrdered,Status,ProjectID,_id,inventoryReceived,ProductItems} = inventoryMap.get(InventoryID);
var update = {[ColumnID]:value}

var days = undefined;
if(inventoryReceived?.[0]){
  const date1 = dayjs()
  const date2 = dayjs(inventoryReceived?.[0])
  var hours = date1.diff(date2, 'hours')
  days = Math.floor(hours / 24)
}
if(!QTYOnHand) QTYOnHand = 0;

var qckHelper = {
  open:true,
  zIndex: 1,
  description: <Box sx={{display: 'flex', alignItems: 'center'}}><Typography sx={{display: 'flex', fontSize: '15px', alignItems:'center'}}>{`${Math.abs(value-QTYOnHand)} Item(s) will be moved.`}&nbsp;
  <Typography sx={{fontSize: '15px', display: 'flex', alignItems: 'center'}}>Rec. On: {inventoryReceived?.[0] ? dayjs(inventoryReceived?.[0]).format('ll') : "No Date Found"}. Item is: &nbsp;{days <= 30 ? 
  <Chip size='small' color='success' icon={<LowPriority/>} label={days!==undefined?`${days} day(s) old`:'Missing Date'} /> : 
  <Chip size='small' color='error' icon={<LowPriority/>} label={days!==undefined?`${days} day(s) old`:'Missing Date'}/>}
  </Typography></Typography></Box>,
  helper:{
  label: 'RETURN RMA/DOA',
  action: ()=>rmaSet({ColumnID,value,InventoryID,index,_valuesCache,updateCell})
},
button03: {
 label: 'Stock',
 action: ()=>Stock({ColumnID,value,InventoryID,index,_valuesCache,updateCell}),
},
handleClose: ()=>{
  setState(p=>({...p,[InventoryID+'QTYOnHand']: undefined }))
  closeAllPopups()
},
handleSubmit: ()=>{
   var ready = checkForSiteLocation(inventoryMap.get(InventoryID)?.ProjectID?.[0],{row,ColumnID,value,InventoryID,index,_valuesCache,updateCell})
   setPopup({open:false})
   if(ready === true) {
   if(ColumnID==="QTYOnHand") setState(p=>({...p,currentQTYOnHand:value}))
   setTransferRequest(p=>{
  var obj = {
    ProjectID,
    InventoryID,
    TransferAmount: value-QTYOnHand,
    ColumnID,
    value,
    row,
    Status,
    QTYReceived,
    ProductItems,
    tempTime: dayjs(),
    _valuesCache,
    updateCell,
    index
  }
  if(p) p.push(obj)
  else var p = [obj]
  
  return p});

   updateCell(null);
  }
 }
};

  // Safety Check
  if((!QTYReceived||QTYReceived===0||value>QTYReceived) && Status !== "Stock") return setPopup(p=>({...p,
    open:true,
    zIndex: 1,
    title:`You cannot have more than you received.`,
    description:`You have ${QTYReceived?QTYReceived:0} received and now you tring to modify ${value} to On-Hand. You will need to Fully Edit this Inventory Item.`,
    handleClose: ()=>{
     setState(p=>({...p,[InventoryID+'QTYOnHand']: undefined }))
     closeAllPopups()},
  }))
  // Safety Check
  if(value<0) return setPopup(p=>({...p,
    open:true,
    zIndex: 1,
    title:`You cannot have a negative amount.`,
    handleClose: closeAllPopups
  }))

  // Reduced QTY On Hand
  if(value!==QTYOnHand && value < QTYOnHand) return setPopup(p=>({...p,
    title:`Is this a Transfer to Stock, Site, or a Return?`,
    submitLabel: 'Transfer to Site',
    ...qckHelper,
  }));

  // Increased QTY On Hand
  if(value!==QTYOnHand && (value > QTYOnHand || !QTYOnHand) && Status !== "Stock") return setPopup(p=>({...p,
    title:`Is this item Stock, RMA, or Staging Zone Return?`,
    submitLabel: 'Staging Zone Return',
    ...qckHelper,
  }));
  else if(((QTYOnHand && value > QTYOnHand) || (!QTYOnHand && value > 0)) && Status === "Stock") update = {...update,QTYOrdered:value,QTYReceived:value,QTYOnHand:value};
  
  if(value===QTYOrdered && ["Shipping","Partial Received"]?.includes(Status)) update = {...update,Status:"Warehouse Received"};
  if(value>0&&value!==QTYOrdered&&["Shipping"]?.includes(Status)) update = {...update,Status:"Partial Received"};

  apprSubmit({update:{...update,InventoryID},paramters:{row,ColumnID,index,_valuesCache,updateCell,value}});
}


// Updated Status Stock
const updatedStockStatus=({ColumnID,value,InventoryID,index,row,_valuesCache,updateCell})=>{
  var {ProductItems,stockShelf} = inventoryMap.get(InventoryID);
  var update = {[ColumnID]:value}
  update = {...update,Status:"Stock"}
  if(stockShelf?.aisles) setPopup(p=>({...p, 
    handleSubmit: ()=>{ 
    apprSubmit({update:{...update,InventoryID},paramters:{row,ColumnID,index,_valuesCache,updateCell,value}})
  }}))
  else { 
   var ProductID = ProductItems.find(({id})=>id).id
   if(ProductID && productMap.has(ProductID)) var otherItems = ''
   else otherItems = [] 

    if(otherItems.length >0){
      if(otherItems.length === 1){ 
        var aisles = otherItems[0].primary
        var shelf = otherItems[0].secondary
        setPopup(p=>({...p, 
          handleSubmit: ()=>{ 
          update = {...update,stockShelf}
          apprSubmit({update:{...update,InventoryID},paramters:{row,ColumnID,index,_valuesCache,updateCell,value}})
      }}))
      }
      else var newDrop = dynamicComponent('DropDown','elements',{state:'stockShelf',label:'Select Zone', list:otherItems,replace:true},mainState)
    }
  }

  return setPopup(p=>({...p,
    open:true,
    zIndex: 1,
    title:`Please set the Stocking Area.`,
    description:`If any inventory line item has Product #, the stock area will will default to that unless changed.`,
    inputField: <Box sx={{p:'1%',display:'flex',gap:'2%'}}>
      {dynamicComponent('InputField','elements',{state:'stockShelf.aisles',label:'Stock Aisles',defaultValue:aisles ,type:'text',error:'Stock Aisles Needed',style:{margin:0}},outputState,[()=>StockStage({InventoryID},{ColumnID,value,InventoryID,index,row,_valuesCache,updateCell})])}
      {dynamicComponent('InputField','elements',{state:'stockShelf.shelf',label:'Stock Shelf',defaultValue:shelf ,type:'text',error:'Stock Shelf Needed',style:{margin:0}},outputState,[()=>StockStage({InventoryID},{ColumnID,value,InventoryID,index,row,_valuesCache,updateCell})])}
      {newDrop&&newDrop}
      </Box>,
    handleClose: closeAllPopups,
  })) 
};

// Updated Status Shipping
const updatedShippingStatus=({ColumnID,value,InventoryID,index,_valuesCache,row,updateCell})=>{
  var update = {[ColumnID]:value}

  const inventoryReceived = inventoryMap.get(InventoryID)?.inventoryReceived

  const submitTrackNum=(key)=>{
    if(outputRef.current?.TrackingNumber) {
      update = {...update,TrackingNumber:outputRef.current.TrackingNumber,Status:"Shipping", inventoryReceived: inventoryReceived ? inventoryReceived.concat([dayjs()]) : [dayjs()]};
      apprSubmit({update:{...update,InventoryID},paramters:{row,ColumnID,index,_valuesCache,updateCell,value}});
    }
    else{
      globalWrn('error',`You must put in a tracking number to submit!`)
      setPopup(p=>({...p,
        description: `You must put in a tracking number to submit`,
        enableSubmitButton: true, //stops circular progress animation
      }))
    }
   }
    setPopup(p=>({...p,
      open:true,
      title:`Add Tracking Number`,
      handleClose: cancelPopup,
      inputField: stateRef.trackingNum,
      handleSubmit: ()=>submitTrackNum(InventoryID),
    }))
};

// Status Changed Order Placed
const updatedOrderPlacedStatus=({ColumnID,value,InventoryID,index,updateCell,row,_valuesCache})=>{
  if(outputRef.current.poNum) setOutput(p=>({...p,
    poNum: undefined,
    OrderNumPlace: undefined,
    TrackingNumber: undefined
  }))

  // curItem variables
  var {QTYReceived,QTYOnHand,QTYOrdered,_id,PurchaseOrder,ProjectID} = inventoryMap.get(InventoryID);
  const submitInfo=(key,row)=>{
    if(outputRef.current?.poNum && outputRef.current?.TrackingNumber && outputRef?.current?.TrackingNumber !== "" && outputRef.current.vendorID && (outputRef.current.vendorID.length === 1 || !Array.isArray(outputRef.current.vendorID))) {
      // curItem.ProjectID[0]
      const inventoryReceived = inventoryMap.get(InventoryID)?.inventoryReceived
      const updateVal = {expectedDeliveryDate: outputRef?.current?.expectedDeliveryDate, TrackingNumber:outputRef.current?.TrackingNumber, PurchaseOrder:outputRef.current?.poNum, vendorID: outputRef.current.vendorID?.[0], OrderNumber:outputRef.current?.OrderNumPlace, Status: "Shipping", inventoryReceived: inventoryReceived ? inventoryReceived.concat([dayjs()]) : [dayjs()], QTYOnHand, QTYOrdered, QTYReceived}
      dbUpdateValidate({model:'Inventory',queryID:true,query:_id,update:updateVal},({success,info,err})=>{ 
        if(err) globalWrn('error',`We had an issue saving the Order and Tracking Number`)
        else if(success){ 
          globalWrn('success', `Saved Order and Tracking Number`)
          closeAllPopups()

          Promise.all( Object.entries(updateVal).flatMap(([k,v])=>{
            if(row) {
            if(k === 'Status') return row.original.Status = v;
            if(k !== '$push' && row?._valuesCache) return row._valuesCache[k] = v;
            }
          })).then(()=> table.setEditingRow(null))
          submitEmailChange(projectMap.get(ProjectID?.[0])?.ProjectState?.projManager,{InventoryID:key},{QTYReceived,QTYOnHand,PurchaseOrder,Status:"Shipping"})
        }})
    }
    else if(outputRef.current?.poNum && outputRef.current.vendorID && (outputRef.current.vendorID.length === 1 || !Array.isArray(outputRef.current.vendorID))) {
      const updateVal = {expectedDeliveryDate: outputRef?.current?.expectedDeliveryDate, OrderNumber:outputRef.current?.OrderNumPlace, vendorID: outputRef.current.vendorID?.[0], Status: "Order Processing", PurchaseOrder:outputRef.current?.poNum}

      dbUpdateValidate({model:'Inventory',queryID:true,query:_id,update:{OrderNumber:outputRef.current?.OrderNumPlace, vendorID: outputRef.current.vendorID?.[0], Status: "Order Processing", PurchaseOrder:outputRef.current?.poNum}},({success,info,err})=>{ 
        if(err) globalWrn('error',`We had an issue saving the Order Number`)
        else if(success){ 
          globalWrn('success', `Saved Purchase Order Number`)
          closeAllPopups()
          
          Promise.all( Object.entries(updateVal).flatMap(([k,v])=>{
            if(row) {
            if(k === 'Status') return row.original.Status = v;
            if(k !== '$push' && row?._valuesCache) return row._valuesCache[k] = v;
            }
          })).then(()=> table.setEditingRow(null))
          submitEmailChange(projectMap.get(ProjectID?.[0])?.ProjectState?.projManager,{InventoryID:key},{QTYReceived,QTYOnHand,PurchaseOrder,Status:"Order Processing"})
        }})
    }
    else {
      globalWrn('error',`You must put in a Purchase Order number and select a SINGLE vendor to submit!`)
      setPopup(p=>({...p,
        description: `You must put in a Purchase Order number and select a SINGLE vendor to submit`,
        enableSubmitButton: true, //stops circular progress animation
      }))
    }
   }
   resetVendorChoiceField(_id); // set vendor field with default value

   if(_id && inventoryMap.has(_id)){
    var {ProductItems:[{id:ProductID}]} = inventoryMap.get(_id);
    if(ProductID && productMap.has(ProductID) ) var {atCost} = productMap.get(ProductID)
   } 

   var orderPlaced = <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} sx={{p:'1% 2%'}}>
   <Grid item xs={12} md={ useAndMobile ? 12 : 6 }>
     <Box>{dynamicComponent('InputField','elements',{state:'poNum',label:'Add Purchase Order Number'},outputState)}</Box>
     <Box>{dynamicComponent('InputField','elements',{state:'OrderNumPlace',label:'Add Order Number'},outputState)}</Box>
   </Grid>
   <Grid item xs={12} md={ useAndMobile ? 12 : 6 }>
   <Box>{dynamicComponent('InputField','elements',{state:'TrackingNumber',label:'Add Tracking Number'},outputState)}</Box>
   <Box>{dynamicComponent('InputField','elements',{state:'AT_COST',label:'Current Cost Ammount(at cost)',defaultValue: atCost,type:'currency'},outputState)}</Box>
   </Grid>
   <Grid item xs={12} md={ 12 }>
   { dynamicComponent('DatePicker','elements',{state:'expectedDeliveryDate',label:'Expected Delivery Date', min: dayjs() },outputState) }
   </Grid>
 </Grid>;

    setPopup(p=>({...p,
      open:true,
      title:`Add An Order Number`,
      description: `Add a Tracking Number if Available`,
      width: '60%',
      handleClose: closeAllPopups,
      headerButton: stateRef.vendorField,
      inputField: orderPlaced,
      handleSubmit: ()=>submitInfo(InventoryID,row),
    }))
}
// Updated Status Order Paused
const updatedOrderPausedStatus=({ColumnID,value,InventoryID,index,_valuesCache,updateCell,row})=>{
  var {ProjectID,PurchasingNotes,ProductItems,PreferredVendor} = inventoryMap.get(InventoryID);

  if(PreferredVendor?.[0] && vendorMap.has(PreferredVendor?.[0])) var {Name} = vendorMap.get(PreferredVendor?.[0]);

  var update = {[ColumnID]:value};
  update.Status = "Order Paused";
  setPopup(p=>({...p,
    open: true,
    title:`Notes/Reason for Pause`,
    description: `Add any details for the Project Manager to be aware of`,
    handleClose: closeAllPopups,
    inputField: <Box>{dynamicComponent('InputField','elements',{state:'PurchasingNotes',label:'Add Notes Here'},outputState)}</Box>,
    handleSubmit: ()=>{
      PurchasingNotes&&setOutput(p=>({...p,
        PurchasingNotes: PurchasingNotes + " - " + outputRef.current.PurchasingNotes
      }))
      update = {...update,PurchasingNotes:outputRef.current.PurchasingNotes}
      apprSubmit({update:{...update,InventoryID},paramters:{row,ColumnID,index,_valuesCache,updateCell,value}});
      const Requestor = projectMap.get(ProjectID?.[0])?.ProjectState?.projManager
      if(Requestor){
        const Title = `At Least One Of Your Orders Was Paused`
        const Body = `Your Request for ${ProductItems[0]?.Product?.DISPLAY_NAME} was paused.\n\nNotes:\n${update.PurchasingNotes||'No Notes'}`
        const mobile = teamMember.get(Requestor)?.mobilePhone!==undefined && teamMember.get(Requestor)?.mobilePhone.replace(/[^0-9]/g,'').slice(-10)
        newNotification({Title:Title,Body:Body,EmailForm:"Fancy",Icon:null,mobile:mobile,Choice:{AppNotification:false,Text:false,Email:true},firstName:teamMember.get(Requestor)?.firstName,lastName:teamMember.get(Requestor)?.lastName,avatar:Requestor,Email:teamMember.get(Requestor)?.email, receiver:Requestor, sender:UserMap.get('avatar')})  
      }
      setOutput(p=>({...p,PurchasingNotes:null }))
    },
    button03: {
      label: `Pause All Req. for ${Name || 'this vendor'}`,
      action: ()=>{

        function memberAssemble(x){
          if(!x) return
          if(x&&teamMember.has(x)) var {firstName,lastName,workPhone,email} = teamMember.get(x)
          return <ListItem sx={{p:'unset'}}>
                   <ListItemAvatar sx={{minWidth:35}}>
                    { dynamicComponent('AvatarBubble','elements',{state:'teams',label:'missing Lead', isInventory: true, style:{width:'30px',height:'30px'},userID:x },mainState) }
                     </ListItemAvatar>
                     <ListItemText sx={{overflow:'hidden',textOverflow:'ellipsis',display:'flex',flexDirection:'column'}} primary={<Typography variant="subtitle">{[firstName,' ',lastName]}</Typography>} secondary={<Typography variant="caption">{workPhone||email}</Typography>} />
                  </ListItem>
        }
  
        var rows = dataRef.current.filter((x)=> x?.Status==='Requested' && x?.PreferredVendor?.[0] === PreferredVendor?.[0]).flatMap(({ProductItems,QTYOrdered,ProjectID,timestamp})=>{
          var ProductID = ProductItems.find(x=>x.id).id
          if(projectMap.has(ProjectID?.[0])) var {ProjectTitle,ProjectState,PoNumber} = projectMap.get(ProjectID[0])
          if(productMap.has(ProductID)) var {DISPLAY_NAME} = productMap.get(ProductID)
          else var {DISPLAY_NAME} = ProductItems?.[0]?.Product
          if(ProjectState?.projManager) var user = memberAssemble(ProjectState?.projManager)

          return {data:{Item:DISPLAY_NAME||'Mising',PullOffset:QTYOrdered,reqDate: dayjs(timestamp)?.format('lll')||'--',PoNumber,ProjectTitle,projManager:user||'Missing'}} 
        })

        var header = ['Product','Ammount Requested','Date Req.', 'Job Number','Project Name', 'Project Manager']
        setPopup2(p=>({...p,
          open: true,
          title:`Confirm Paused`,
          description: `By submitting below you will pause the below Requests, Project Manager(s) will be informed`,
          inputField: <TableContainer component={Paper}>
         <Table sx={{ minWidth: 650 }} aria-label="simple table">
           <TableHead>
             <TableRow>
               { header.flatMap((x,i)=> <TableCell key={x} align={i!==0 ? 'right':'left'} sx={i==1&&{backgroundColor:'#f4f186',color:'#2e2e2e',fontWeight:900}}><Typography variant="caption">{x}</Typography></TableCell> )}
             </TableRow>
           </TableHead>
           <TableBody>
             {rows.map(({ProductID,Status,data}) => (
               <TableRow key={ProductID} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                 { Object.entries(data).flatMap(([k,v],i)=> <TableCell key={k} align={i!==0?'right':'left'} sx={i==1?{backgroundColor:'#f4f186',color:'#2e2e2e',fontWeight:900}:Status&&{backgroundColor:'#f9caa7',color:'#2e2e2e'}}>{i===0&&Status&&<Chip label="Stock" variant="outlined" sx={{color:'#2e2e2e',marginRight:'2%'}} />}{v}</TableCell>) }
               </TableRow>
             ))}
           </TableBody>
         </Table>
       </TableContainer>,
        handleClose: ()=> setPopup2({open:false}),
        handleSubmit: ()=>{
          PurchasingNotes&&setOutput(p=>({...p,
            PurchasingNotes: PurchasingNotes + " - " + outputRef.current.PurchasingNotes
          })) }
        }))
      },
     },
  }))
}
// Status Order Unavailable
const updatedOrderUnavailableStatus=({ColumnID,value,InventoryID,index,_valuesCache,updateCell,row})=>{
  var {ProjectID,PurchasingNotes,ProductItems} = inventoryMap.get(InventoryID);
  setPopup(p=>({...p,
    open:true,
    title:`Notes/Reason for Unavailability`,
    description: `Add any details for the Project Manager to be aware of`,
    handleClose: closeAllPopups,
    inputField: [<Box>{dynamicComponent('InputField','elements',{state:'PurchasingNotes',label:'Add Notes Here'},outputState)}</Box>],
    handleSubmit: ()=>{
      PurchasingNotes && setOutput(p=>({...p,
        PurchasingNotes: PurchasingNotes + " - " + outputRef.current.PurchasingNotes
      }))
      apprSubmit({update:{Status: "Order Unavailable", PurchasingNotes: outputRef?.current?.PurchasingNotes, InventoryID},paramters:{ColumnID,value,InventoryID,index,_valuesCache,updateCell,row}})
      const Requestor = projectMap.get(ProjectID?.[0])?.ProjectState?.projManager
      if(Requestor) {
        const Title = `At Least One Of Your Orders Was Unavailable`
        const Body = `Your Request for ${ProductItems[0]?.Product?.DISPLAY_NAME} is Unavailable.\n\nNotes:\n${outputRef?.current?.PurchasingNotes||'No Notes'}`
        const mobile = teamMember.get(Requestor)?.mobilePhone!==undefined && teamMember.get(Requestor)?.mobilePhone.replace(/[^0-9]/g,'').slice(-10)
        newNotification({Title:Title,Body:Body,EmailForm:"Fancy",Icon:null,mobile:mobile,Choice:{AppNotification:false,Text:false,Email:true},firstName:teamMember.get(Requestor)?.firstName,lastName:teamMember.get(Requestor)?.lastName,avatar:Requestor,Email:teamMember.get(Requestor)?.email, receiver:Requestor, sender:UserMap.get('avatar')})
      }
      setOutput(p=>({...p,PurchasingNotes:null}))
    }
  }))
}
// Status Warehouse Receieved
const updatedWarehouseReceivedStatus=({ColumnID,value,row,InventoryID,index,_valuesCache,updateCell})=>{
  var {ProjectID,_id,projectStagingZone,stockShelf} = inventoryMap.get(InventoryID);
  
  // Uploading BOL functions
  var items = [ {id:'InputField',loc:'elements',viewData:{state:'label',label:'Photo/Video Of',type:'text'}}, {id:'Dropzone',loc:'elements',viewData:{state:'addFile',label:'Photo/Video'}} ]
  var lists = dynamicComponent('Listable','elements',{state:'addFiles',label:'+ Add',items:items,defaultValue:null,required:false},mainState,[(x)=>{
    if(x.addFiles) x.addFiles.forEach(({label,addFile})=>{ if(label && addFile && outputRef.current.projectStagingZone){
      addFile.forEach((x,i)=> setUploadTempFiles(p=>({...p,[ProjectID]:{...p[ProjectID],[label+'_'+i]:{type:'AddFile', index:i, name:x.name, fileType:x.type, data:x}}})))
    }
     })
    
  }])
 // ------------------------

  const ProductID = inventoryMap.get(_id)?.ProductItems?.[0]?.id
  try {
    if(ProductID && productMap.has(ProductID) && inventoryMap.has(_id)) var otherItems = [].concat(inventoryMap.get(_id)?.filter((x)=> x?._id !== InventoryID && x?.ProductItems.find((x)=>x?.id)?.id === ProductID && x?.stockShelf)?.flatMap((z)=>({primary:z?.stockShelf.aisles,secondary:z?.stockShelf.shelf,action:()=>{setOutput(p=>({...p,'stockShelf.shelf':z?.stockShelf.shelf,'stockShelf.aisles':z?.stockShelf.aisles})); StockStage({InventoryID:_id},{ColumnID,value,InventoryID,index,row,_valuesCache,updateCell})} })) )
  } catch {
    console.log("Could not generate otherItems")
  }
    if(!otherItems) otherItems = [] 

    if(otherItems.length > 0) otherItems = otherItems?.[0];

    var newDrop = dynamicComponent('DropDown','elements',{state:'stockShelf',label:'Select Zone', list:otherItems,replace:true},mainState)

   return setPopup(p=>({...p,
       open:true,
       zIndex: 1,
       title:`Please set a Project Staging Zone`,
       description:`If any inventory line item has job # and a Staging Zone this will defult to that Zone# unless changed.`,
       inputField: [
       <Box sx={{p:'1%',display:'flex',gap:'2%'}}>
         {dynamicComponent('InputField','elements',{state:'projectStagingZone',label:'Project Staging Zone',defaultValue:projectStagingZone ,type:'text',error:'Project Staging Zone Needed',style:{margin:0}},outputState,[()=>projectStage({projectStagingZone:outputRef.current.projectStagingZone, InventoryID,ColumnID,value,InventoryID,index,_valuesCache,updateCell, row})])}
         {newDrop&&newDrop}
         </Box>,
         <Typography sx={{width:'100%',p:'1% 5% 0'}}>Upload BOL: (Optional)</Typography>,
         <Box sx={{p:'1%'}}>{lists}</Box>],
       handleClose: closeAllPopups,
     }));
}

// RMA Function
const rmaSet = ({ColumnID,value,InventoryID,index,_valuesCache,updateCell}) => {
  var curItem = {...inventoryMap.get(InventoryID)}
  var {QTYReceived,QTYOnHand,QTYOrdered,Status,ProjectID,_id,ProductItems,PurchaseOrder,inventoryReceived,TransferRequests,_id,__v} = inventoryMap.get(InventoryID);

  // Day calculation
  const date1 = dayjs()
  const date2 = dayjs(inventoryReceived?.[0])
  var hours = date1.diff(date2, 'hours')
  const days = Math.floor(hours / 24)

   setPopup({
     open:true,
     zIndex: 1,
     title:`RETURN RMA/DOA.`,
     width:'55vw',
     description: <Box sx={{display: 'flex', alignItems: 'center'}}><Typography sx={{display: 'flex', fontSize: '15px', alignItems:'center'}}>{Math.abs(value-QTYOnHand)}&nbsp;
     <Typography sx={{fontSize: '15px', display: 'flex', alignItems: 'center'}}>Item(s) will be Returned. Rec. On: {inventoryReceived?.[0] ? dayjs(inventoryReceived?.[0]).format('ll') : "No Date Found"}. Item is: &nbsp;{days <= 30 ? 
     <Chip size='small' color='success' icon={<LowPriority/>} label={`${days} day(s) old`} /> : 
     <Chip size='small' color='error' icon={<LowPriority/>} label={`${days} day(s) old`}/>}
     </Typography></Typography></Box>,
     inputField: <Box sx={{padding: '1%'}}>{[<React.Fragment key={"1thousand"}>{dynamicComponent('Switcher', 'elements',{state:'RMAtoDOA',label:"R.T.V. or Replacement",style:{flex:'unset'}},mainState)}</React.Fragment>,<React.Fragment key={"2thousand"}>{dynamicComponent('InputField','elements',{state:'RMATrack',label:'Tracking Number for RMA',type:'text',style:{margin:0}},mainState)}</React.Fragment>,<React.Fragment key={"3thousand"}>{dynamicComponent('InputField','elements',{state:'RMANotes',label:'Reason for RMA/DOA',type:'text',error:'Reason Needed',style:{margin:0}},mainState)}</React.Fragment>]}</Box>,
     handleClose: closeAllPopups,
     handleSubmit: ()=>{
       if(TransferRequests) TransferRequests.flatMap((x)=>{ delete x._id })
       if(isNumeric(curItem?.QTYOnHand)) curItem.QTYOnHand = parseInt(curItem.QTYOnHand,10)
       if(curItem?.QTYOnHand)
       curItem.QTYOnHand = parseInt(curItem?.QTYOnHand, 10)

       if(curItem.QTYOnHand) curItem.QTYOnHand = Math.abs(value)
       curItem.QTYReceived = Math.abs(value)

       setTransferRequest(p=>{p?.push({
        ProjectID,
        InventoryID: _id,
        QTYOnHand,
        TransferAmount: value-QTYOnHand,
        value,
        ColumnID,
        Status,
        QTYReceived,
        skipTransfer:true,
        ProductItems,
        tempTime: dayjs(),
        _valuesCache,
        index,
        updateCell,
        RMAItem: curItem,
       });return p});

       closeAllPopups();
         return updateCell(null); // End function
      }
   })
 }

 // Stock Function
 const Stock = ({ColumnID,row,value,InventoryID,index,_valuesCache,updateCell}) =>{
  var curItem = {...inventoryMap.get(InventoryID)}
  var {QTYReceived,QTYOnHand,QTYOrdered,Status,ProjectID,_id,ProductItems,PurchaseOrder,inventoryReceived,TransferRequests,Dates,_id,__v} = curItem;
  var {ProductItems} = inventoryMap.get(InventoryID);

  if(!QTYOnHand) QTYOnHand = 0; // If QTYOnHand Doesn't Exist

  var ProductID = ProductItems?.[0]?.id 
  if(divisionMap.size>0) var list = Object.assign(...Array.from([...divisionMap],([k,v])=>({[v.teamName]:k})).sort((a, b)=> (Object.keys(a)[0] < Object.keys(b)[0]) ? -1 : (Object.keys(a)[0] > Object.keys(b)[0]) ? 1 : 0))

 var stockItemID = [].concat(...Array.from([...inventoryMap],([k,{Status,ProductItems}])=>{ 
    if(Status==='Stock' && ProductItems?.[0]?.id === ProductID) return k
    return []
  }))

   var item = stockItemID.filter((x)=>x?.ProductItems?.[0]?.id === inventoryMap.get(stockItemID)?.ProductItems?.[0]?.id)
   setTransferRequest(p=>{p?.push({
      ProjectID,
      InventoryID: _id,
      TransferAmount: Math.abs(value-QTYOnHand),
      value,
      ColumnID,
      Status,
      QTYReceived,
      skipTransfer:true,
      ProductItems,
      tempTime: dayjs(),
      _valuesCache,
      index,
      updateCell,
      StockItem: item
    });return p})
    closeAllPopups()
  }

  function projectStage({projectStagingZone, QTYReceived, item, data, key, ColumnID,value,InventoryID,index,_valuesCache,updateCell}){
    if(inventoryMap.has(InventoryID)) var {QTYReceived:oldQTYReceived, QTYOnHand, ProjectID} = inventoryMap.get(InventoryID)
    if(!data) data = {}
    if(!oldQTYReceived) oldQTYReceived = 0;
    var QYTOnHandVal;
    if(QTYReceived) // Increment values if needed
    {
      QYTOnHandVal = oldQTYReceived && QTYOnHand ? ((value-oldQTYReceived)+QTYOnHand) : value
    }
    // If we are updating QTY Received
    if(value && QTYReceived) var submitData = { 
      Status: ColumnID === "Status" ? value : inventoryMap.get(InventoryID)?.Status,
      projectStagingZone,
      QTYReceived: value,
      QTYOnHand: QYTOnHandVal,
    }
    // If we are updating status
    else if (value) var submitData = {
      Status: ColumnID === "Status" ? value : inventoryMap.get(InventoryID)?.Status,
      projectStagingZone,
    }
    if(submitData) var data = submitData
    else {
      var data = {}
      console.error("submitData was not created! At InventoryGrid.jsx, function projectStage()")
    }
   setState(p=>({...p,projectStageBackup: {...data}}))
   setPopup(p=>({...p,
     handleSubmit: ()=> {
      if(uploadTempFilesRef.current) tempfileUploader(dayjs(), ProjectID?.[0], InventoryID); 
      apprSubmit({update:data?{...data,InventoryID:InventoryID}:{...stateRef.projectStageBackup, InventoryID: InventoryID},paramters:{ColumnID,value,InventoryID,index,_valuesCache,updateCell}})
    }
   }))
   }

   function StockStage({InventoryID},{ColumnID,value,index,row,_valuesCache,updateCell}){
    if(outputRef.current && ( outputRef.current.stockShelf || ['stockShelf.aisles','stockShelf.shelf'].every(x=> Object.keys(outputRef.current).includes(x))))
      setPopup(p=>({...p,
        handleSubmit: ()=>{ 
          if(outputRef.current.stockShelf) apprSubmit({update:{Status: "Stock", stockShelf:outputRef.current.stockShelf,InventoryID:InventoryID},paramters:{ColumnID,value,InventoryID,index,row,_valuesCache,updateCell}})
          else apprSubmit({update:{Status: "Stock", stockShelf:{aisles:outputRef.current['stockShelf.aisles'],shelf:outputRef.current['stockShelf.shelf']},InventoryID:InventoryID},paramters:{ColumnID,value,InventoryID,index,row,_valuesCache,updateCell}})
        }
      }))
    };

    const updatedPartialReceivedStatus=({ColumnID,value,InventoryID,index,_valuesCache,updateCell})=>{
      apprSubmit({update:{Status:"Partial Received",InventoryID:InventoryID},paramters:{ColumnID,index,_valuesCache,updateCell,value}},({err})=>{
        if(err) {
          console.log("An error occured:", err)
          globalWrn('error', 'There was a problem updating your status')
        }
      })
    };

    const updatedPartiallyReceivedAndDeliveredStatus = ({ColumnID,value,InventoryID,index,_valuesCache,updateCell}) => {
      apprSubmit({update:{Status:"Partially Received and Delivered",InventoryID:InventoryID},paramters:{ColumnID,index,_valuesCache,updateCell,value}},({err})=>{
        if(err) {
          console.log("An error occured:", err)
          globalWrn('error', 'There was a problem updating your status')
        }
      })
    };

    const updatedPartialToSite = ({ColumnID,value,InventoryID,index,_valuesCache,updateCell}) => {
      apprSubmit({update:{Status:"Partial to Site",InventoryID:InventoryID},paramters:{ColumnID,index,_valuesCache,updateCell,value}},({err})=>{
        if(err) {
          console.log("An error occured:", err)
          globalWrn('error', 'There was a problem updating your status')
        }
      })
    };

    const updatedRequested = ({ColumnID,value,InventoryID,index,_valuesCache,updateCell}) => {
      apprSubmit({update:{Status:"Requested",InventoryID:InventoryID},paramters:{ColumnID,index,_valuesCache,updateCell,value}},({err})=>{
        if(err) {
          console.log("An error occured:", err)
          globalWrn('error', 'There was a problem updating your status')
        }
      })
    };

    const updatedDeliveredToSite = ({ColumnID,value,InventoryID,index,_valuesCache,updateCell}) => {
      apprSubmit({update:{Status:"Delivered to Site",InventoryID:InventoryID},paramters:{ColumnID,index,_valuesCache,updateCell,value}},({err})=>{
        if(err) 
        {
          console.log("An error occured:", err)
          globalWrn('error', 'There was a problem updating your status')
        }
      })
    };

    const updatedOrderCheckedOnStatus = ({ColumnID,value,InventoryID,index,_valuesCache,updateCell}) => {
      apprSubmit({update:{Status:"Order Checked On",InventoryID:InventoryID},paramters:{ColumnID,index,_valuesCache,updateCell,value}},({err})=>{
            if(err) {
              console.log("An error occured:", err)
              globalWrn('error', 'There was a problem updating your status')
            }
          })
    };

const submitPaths = {
  "QTYReceived": changedQTYReceived,
  "QTYOnHand": changedQTYOnHand,
  "Stock": updatedStockStatus,
  "Shipping": updatedShippingStatus,
  "Order Placed": updatedOrderPlacedStatus,
  "Order Paused": updatedOrderPausedStatus,
  "Order Unavailable": updatedOrderUnavailableStatus,
  "Order Checked On": updatedOrderCheckedOnStatus,
  "Warehouse Received": updatedWarehouseReceivedStatus,
  "Partial Received": updatedPartialReceivedStatus,
  "Partially Received and Delivered": updatedPartiallyReceivedAndDeliveredStatus,
  "Partial to Site": updatedPartialToSite,
  "Requested": updatedRequested,
  "Delivered to Site": updatedDeliveredToSite,
};

  if(!onSaving) var onSaving=({row,InventoryID,ColumnID,index,value,_valuesCache,updateCell})=>{

   var {QTYReceived,QTYOnHand,QTYOrdered} = inventoryMap.get(InventoryID);
   if(isNumeric(QTYOrdered)) QTYOrdered = parseInt(QTYOrdered,10);
   if(isNumeric(QTYReceived)) QTYReceived = parseInt(QTYReceived,10);
   if(isNumeric(QTYOnHand)) QTYOnHand = parseInt(QTYOnHand,10);
   
   if(QTYOrdered < 0) QTYOrdered = 0
   if(QTYReceived < 0) QTYReceived = 0
   if(QTYOnHand < 0) QTYOnHand = 0

   setParameters({row,InventoryID,ColumnID,index,value,_valuesCache,updateCell})


   if(ColumnID === 'Status'){
    if(ColumnID) submitPaths[value]?.({row,ColumnID,value,InventoryID,index,_valuesCache,updateCell})
    else return
   }
   else submitPaths[ColumnID]?.({row,ColumnID,value,InventoryID,index,_valuesCache,updateCell})
  } 


const displayView2 = ({row:{original:{_id:InventoryID},id:key,index},column:{id:ColumnID,getIsPinned}})=>{ 
  if(ColumnID==='QTYOnHand' && transferRequest.current && transferRequest.current?.some(x=> x.InventoryID === InventoryID)){
    var {TransferAmount, value} = transferRequest.current.find(x=> x.InventoryID === InventoryID)
    var secondaryOverRide = `Transf: ${TransferAmount} ➜ (${value} will remain)`
  }

  if(inventoryMap.has(InventoryID)) var {ProjectID, Status, [ColumnID]:itemValue } = inventoryMap.get(InventoryID)
  if(secondaryID==='PoNumber'){   
    if(ProjectID?.[0]&&projectMap.has(ProjectID[0])) var{PoNumber} = projectMap.get(ProjectID[0])
    if(PoNumber) var secondaryID = 'Job# '+PoNumber
  } 

  var Field = <ListItemText
  primary={<Typography variant="subtitle1" sx={{...DisplayViewStyle.Typography,color:getIsPinned()?'#fff':coloring[Status]?.color}}> { itemValue || '--' } </Typography>} 
  secondary={ secondaryOverRide 
    ? <Chip label={secondaryOverRide} size="small" color="warning" sx={{fontSize:'11px',height:'16px'}} /> 
    : secondaryID && <Typography variant="caption" sx={{textShadow:'.5px .5px 1px rgba(0,0,0,.35)',color:getIsPinned()?'#fff':coloring[Status]?.color}}> {secondaryID || 'Missing'} </Typography> } 
  />;

  if( ColumnID && index && CellFocus.has(ColumnID+index)){
    var { UserID } = CellFocus.get(ColumnID+index)
    if(UserID!==UserMap.get('avatar')){
    if(teamMember.has(UserID)) var {firstName,lastName} = teamMember.get(UserID);


    return [ <Chip label={`${firstName} ${lastName} `} size="small" color="secondary"
        icon={ dynamicComponent('AvatarBubble','elements',{state:'teams',label:'missing user', isInventory: true, style:{width:'25px',height:'25px'},userID: UserID },mainState) }
        onDelete={()=>{}}
        deleteIcon={<Edit />}
        sx={{position:'absolute',marginLeft:'18px'}}
         />,
         Field ]
 }
} else return Field
};

const editorRender2 = ({row,column:{id:ColumnID,columnDef:{header}}})=> { 
  var {_valuesCache,original:{_id:InventoryID},id,index} = row

  if(inventoryMap.has(InventoryID)){
    var invnetoryData = inventoryMap.get(InventoryID);
  } 
if(invnetoryData)
  return ['Returned for RMA','Requested','Order Placed','Order Paused','Order Unavailable','Order Processing','Order Checked On'].includes(invnetoryData?.['Status'])
  ? <Chip color="warning" size="small" icon={<ReportGmailerrorred />} label={`Cannot be modified.`} onDelete={()=>table.setEditingCell(null)} />
  : <Box ref={blurRef} sx={{display:'flex', flexDirection:'column'}}>
  { dynamicComponent('LabelValue','elements',{ state:InventoryID+ColumnID, label:header, type:'number', labelEditable:false, Min:0, Max:null, defaultValue: invnetoryData?.[ColumnID]},tempValState)}
  { tempValRef[InventoryID+ColumnID]!==undefined && invnetoryData[ColumnID]!== tempValRef[InventoryID+ColumnID] && <Button sx={{lineHeight:1,padding:'1%'}} variant="contained" color="success" onClick={()=>{ 
    var value = tempValRef[InventoryID+ColumnID];
      onSaving({row,InventoryID,value,ColumnID,index,_valuesCache,updateCell:table.setEditingCell,remoteUpdate:(x)=> setData(p=>{ p[index][id] = value; return p}) })
      setTempVal(p=>({...p,[InventoryID+ColumnID]: undefined}))
    
  }} startIcon={<Save />}>Save</Button> }
  </Box>
  };

  const productBuildOut2 = ({row, row:{original:{_id:InventoryID},id:key,index,_valuesCache},column:{id:ColumnID,getIsPinned}})=>{
    if(inventoryMap.has(InventoryID) && inventoryMap.get(InventoryID)?.ProductItems){
       var { ProductItems:[{id:ProductID}] } = inventoryMap.get(InventoryID);
      if(productMap.has(ProductID)) var {IMAGE_SPOTLIGHT,DISPLAY_NAME,MODEL_NUMBER} = productMap.get(ProductID)
    }

    return <Box sx={{display:'flex',flexDirection:'column',width:'100%'}} onClick={async()=>{
        if(productMap.has(ProductID)){
          var prodExists = productMap.has(ProductID)
        } else if(ProductID && !productMap.has(ProductID)){ 
          queryDB({model:'Products',query:{"_id": ProductID}},([productData])=>{
            if(!productData) var prodExists = false
            else{  
              var prodExists = true
              dynamicMapData({[productData._id]: productData}, productMap, setProductMap);
          }
          setState(p=>({...p,
            productCard : dynamicComponent('ProductCard', 'elements', {ProductID, InventoryID:InventoryID, productExists:prodExists}, mainState)
          }))
           
          })
        } else var prodExists = false
          setState(p=>({...p,
            productCard : dynamicComponent('ProductCard', 'elements', {ProductID, InventoryID:InventoryID, productExists:prodExists}, mainState)
          }))

      }}><ListItem sx={{p:'unset',gap:'3%'}}>
      <ListItemAvatar sx={{minWidth:25}}>
      <Avatar variant="rounded" sx={{ width:'40px', height:'40px'}} >
      <LazyLoadImage alt={'IMAGE'} effect="blur" loading='lazy' threshold={10000} height={'100%'} width={'100%'} style={{objectFit:'contain'}} key={'MainLogo'}
        onError={(e)=>{ e.target.src = 'https://www.services.ekc.app/scripts/images/AV.jpeg'}}
        src={IMAGE_SPOTLIGHT||'https://www.services.ekc.app/scripts/images/AV.jpeg'}
      /> 
      </Avatar>
     </ListItemAvatar> 
     <ListItemText
      sx={{display:'flex',flexDirection:'column'}}
      primary={<Typography variant="subtitle" sx={{display: '-webkit-box',lineHeight:1, WebkitLineClamp: 2, WebkitBoxOrient: 'vertical',overflow:'hidden',textOverflow: 'ellipsis',color: getIsPinned()?'#fff':coloring[inventoryMap.get(InventoryID)?.Status]?.color }}>{DISPLAY_NAME||'Missing'}</Typography>} secondary={<Typography variant="caption" sx={{lineHeight:1,color: getIsPinned()?'#fff':coloring[inventoryMap.get(InventoryID)?.Status]?.color }}>{'Model #: '+MODEL_NUMBER||'Missing'}</Typography>} />
    </ListItem></Box> || waveLoad()
  };

  const locStat2 = ({row:{original:{_id:InventoryID},id:key,index},column:{id:ColumnID,getIsPinned}})=>{
      if(inventoryMap.has(InventoryID)){
      var {Status,dates,TrackingNumber,timestamp,stockShelf,projectStagingZone,RMANumber,ProjectID,Notes,consumable,_id} = inventoryMap.get(InventoryID);

      if(ProjectID?.[0]&&projectMap.has(ProjectID[0])) var{ProjectTitle,SalesState} = projectMap.get(ProjectID[0])
      if(SalesState?.SiteID?.[0]&&teamMember.has(SalesState.SiteID[0])) var {address,firstName} = teamMember.get(SalesState.SiteID[0])
     
      var items = Object({
        'Shipping': {
          icon: 'T#',
          primary: TrackingNumber || 'Missing',
          secondary: ['N/A',undefined].includes(TrackingNumber)?'not availible': 'Out for delivery'
        },
        'Partial Received': {
          icon: 'T#',
          primary: TrackingNumber || 'Missing',
          secondary: `Out for delivery | ${projectStagingZone ? `Zone: ${projectStagingZone}`: 'Missing Zone'}`
        },
        'Warehouse Received': {
          icon: 'WR',
          primary: projectStagingZone ? `Zone: ${projectStagingZone}`: 'Missing Zone',
          secondary: 'Project Staging Zone'
        },
        'Partially Received and Delivered': {
          icon: 'R&D',
          primary: projectStagingZone ? `Zone: ${projectStagingZone}`: 'Missing Zone',
          secondary: address?.label
        },
        'Partial to Site': {
          icon: 'PTS',
          primary: firstName || 'Missing Delivery Site',
          secondary: address?.label||'Missing Address'
        },
        'Delivered to Site': {
          icon: 'DTS',
          primary: firstName || 'Missing Delivery Site',
          secondary: address?.label||'Missing Address'
        },
        'Returned for RMA': {
          icon: 'RMA',
          primary: `RMA Tracking # ${RMANumber || 'Missing'}`,
          secondary: 'Tracking Information'
        },
        'Stock': {
          icon: 'SK',
          primary: stockShelf?.aisles ? `Aisle: ${stockShelf?.aisles}` : 'Missing Aisle',
          secondary: stockShelf?.shelf ? `Shelf: ${stockShelf?.shelf}` : 'Missing Shelf',
          consumableIcon: consumable
        },
        'Requested': (()=>{
          const regexName = /Requested - .* by (.*)$/;
          const matchName = Notes?.match(regexName);
          if (matchName && matchName.length >= 2) var nameVal = matchName[1];
          
          const regexTime = /Requested - ([^-]+) - ([^-]+) by/;
          const matchTime = Notes?.match(regexTime);
            
          if (matchTime && matchTime.length >= 3) {
                var datePart = matchTime[1].trim();
                var timePart = matchTime[2].trim();
          }
          return {
            icon: 'R',
            primary: nameVal ? `Requested By: ${nameVal}` : `Requested`,
            secondary: datePart && timePart ? `${datePart} ${timePart}` : ``
          } })(),
        'Order Placed': {
          icon: 'Plc',
          primary: `Order Placed`
        },
        'Order Paused': {
          icon: 'Pau',
          primary: `Order Paused`
        },
        'Order Unavailable': {
          icon: 'Un',
          primary: `Order is Unavailable`,
          secondary: `Select different product or contact purchasing manager`,
        },
        "Order Checked On": {
          icon: "CO",
          primary: `Order Checked On:`,
          secondary: dates?dates?.filter(x=>x.type === "Order Checked On")?.[0]?.timestamp ? dayjs(dates.filter(x=>x.type === "Order Checked On")?.[0]?.timestamp).format('lll') : `No Date` : `No Date`,
          third: dates && {Notes: dates?.filter(x=>x.type === "Order Checked On")?.[0]?.Notes, Icon: dynamicComponent('AvatarBubble','elements',{state:'user_1',Catagory:'Team',label:'User', isInventory: true, style:{width:'22px',height:'22px'},userID: dates?.filter(x=>x.type === "Order Checked On")?.[0]?.userID },mainState)}
          },
        'Order Processing': {
            icon: 'Pr',
            primary: `Order Processing`,
            secondary: `Order last modified: ${dayjs(timestamp).format("lll")}`
          }
        })?.[Status]

      if(items) var {icon,primary,secondary,consumableIcon,third} = items 

      return <ListItem sx={{padding:0,gap:'3%'}}>
      <ListItemAvatar sx={{minWidth:'unset'}}>
        <Avatar sx={{ width: 24, height: 24 }}>
        <Typography variant="subtitle2">{icon}</Typography>
        </Avatar>
      </ListItemAvatar>
      <Box sx={{display:'flex',flexDirection:'column'}}>
      <ListItemText 
      sx={{display:'flex',flexDirection:'column',justifyContent:'center'}}
      primary={<Typography variant="caption" sx={{lineHeight:1.1,color:getIsPinned()?'#fff':coloring[Status]?.color}}>{primary}</Typography>} 
      secondary={<Typography variant="caption" sx={{display:'flex',whiteSpace:'pre-wrap',lineHeight:1,color:getIsPinned()?'#fff':coloring[Status]?.color}}>{secondary}</Typography>} />
      {third && <Chip color="warning" size="small" icon={third.Icon} label={third.Notes} /> }
      {consumableIcon && consumableIcon === true && stateRef?.[_id+'fireIcon']}
      </Box>
     </ListItem>

      } return 'Pending'
  };

  const preferredVendor2 = ({row:{original:{_id:InventoryID},id:key,index},column:{id:ColumnID,getIsPinned}})=>{
    if(inventoryMap.has(InventoryID)) var {ProductItems,PreferredVendor} = inventoryMap.get(InventoryID);
        var label = PreferredVendor ? 'Preferred Vendor' : 'Missing'

        return <ListItemText
          sx={{display:'flex',flexDirection:'column',justifyContent:'center'}}
          primary={<Typography variant="subtitle2" sx={{color:getIsPinned()?'#fff':coloring[inventoryMap.get(InventoryID)?.Status]?.color,lineHeight:1}}>{vendorMap.get(PreferredVendor?.[0])?.Name||'N/A'}</Typography>}
          secondary={ <Typography sx={{ display: 'inline',color:getIsPinned()?'#fff':coloring[inventoryMap.get(InventoryID)?.Status]?.color,lineHeight:1}} variant="caption" >{label}</Typography> }
          /> 
  };

  const manufacturerField = ({row:{original:{_id:InventoryID},id:key,index},column:{id:ColumnID,getIsPinned}})=>{
    if(inventoryMap.has(InventoryID)) var {ProductItems,PreferredVendor} = inventoryMap.get(InventoryID);
        if(!PreferredVendor && ProductItems?.[0]?.id && productMap.has(ProductItems[0].id)) var {MANUFACTURER} = productMap.get(ProductItems[0].id)
        var label = MANUFACTURER ? 'Manufacturer' : 'Missing'

        return <ListItemText
          sx={{display:'flex',flexDirection:'column',justifyContent:'center'}}
          primary={<Typography variant="subtitle2" sx={{color:getIsPinned()?'#fff':coloring[inventoryMap.get(InventoryID)?.Status]?.color,lineHeight:1}}>{MANUFACTURER||'N/A'}</Typography>}
          secondary={ <Typography sx={{ display: 'inline',color:getIsPinned()?'#fff':coloring[inventoryMap.get(InventoryID)?.Status]?.color,lineHeight:1}} variant="caption" >{label}</Typography> }
          /> 
  };

  const totalValue2 = ({row:{original:{_id:InventoryID},id:key,index},column:{id:ColumnID,getIsPinned}})=>{
      if(inventoryMap.has(InventoryID)) var {ProductItems,QTYOnHand,QTYOrdered} = inventoryMap.get(InventoryID);

      return <Typography variant="subtitle2" sx={{color:getIsPinned()?'#fff':coloring[inventoryMap.get(InventoryID)?.Status]?.color}}>{ProductItems?.flatMap(({id,Product})=>{
          if(productMap.has(id) && ((QTYOnHand && QTYOnHand !== 0) || (QTYOrdered && QTYOrdered !== 0))) return `$${(productMap.get(id)?.MSRP*(QTYOrdered&&QTYOrdered!==0?QTYOrdered:QTYOnHand)).toFixed(2)}`
          else if((QTYOnHand && QTYOnHand === 0) && (QTYOrdered && QTYOrdered === 0) && productMap.has(id)) return `No QTY`
          else return `N/A` })}</Typography>
  };

  const statusDrp2 = (({row,column:{id}})=>{
    const handleClick = ({currentTarget}) => {
     setTempVal(p=>({...p,[row?.original?._id+id]: currentTarget}))
    }
    const handleClose = (primary) => {
      setTempVal(p=>({...p,[row?.original?._id+id]: undefined}))
      onSaving({row,InventoryID:row?.original?._id,ColumnID:id,index:row?.index,_valuesCache:row?._valuesCache,value:primary,updateCell:null})
    }


      if([...statuses,...statuses2,...statuses3].find(({primary})=>primary===row?.original?.Status) !== undefined) var {icon,primary} = [...statuses,...statuses2,...statuses3].find(({primary})=>primary===row?.original?.Status);
  
      var list = ['Requested',"Order Paused","Order Unavailable",undefined].includes(row?.original?.Status) ? 
      statuses2.flatMap(({primary,icon})=>({primary,icon})) : 
      ['Order Processing', 'Order Checked On'].includes(row?.original?.Status) 
      ? statuses3.flatMap(({primary,icon})=>({primary,icon})) 
      : statuses.flatMap(({primary,icon})=>({primary,icon}))

     return <Box sx={{width:'100%'}}>
            <Button id="demo-customized-button"
              aria-controls={tempValRef[row?.original?._id+id] && 'demo-customized-menu'}
              aria-haspopup="true"
              aria-expanded={tempValRef[row?.original?._id+id] && 'true'}
              variant="contained"
              disableElevation
              onClick={handleClick}
              disabled={[pullReq].includes(true)}
              endIcon={![pullReq].includes(true) &&<KeyboardArrowDown />}
              startIcon={<Box sx={{padding:0,display:'flex'}}>{icon}</Box>}
              sx={coloring[primary] ? {...coloring[primary],width:'100%', padding: '3% 6%', justifyContent:'space-between'} : {width:'100%', padding: '3% 6%', justifyContent:'space-between'}}
            >
          <Typography variant="caption" sx={{whiteSpace:'break-spaces', display: '-webkit-box',WebkitLineClamp: 2, WebkitBoxOrient: 'vertical',overflow: 'hidden', textOverflow: 'ellipsis', lineHeight:1.1}}>
          {row?.original?.Status || 'Status'}
          </Typography>
         
        </Button>
        <StyledMenu id="demo-customized-menu" MenuListProps={{ 'aria-labelledby': 'demo-customized-button',}}
          anchorEl={tempValRef[row?.original?._id+id]||null}
          open={tempValRef[row?.original?._id+id]}
          onClose={handleClose}
        >
          {list.flatMap(({icon,primary})=>
           <MenuItem key={primary} onClick={()=>handleClose(primary)}>
           {icon}
           <Typography variant="subtitle2" sx={{whiteSpace:'break-spaces', display: '-webkit-box',WebkitLineClamp: 2, WebkitBoxOrient: 'vertical',overflow: 'hidden', textOverflow: 'ellipsis', lineHeight:1.1}}>
            {primary}
           </Typography>
         </MenuItem>
          )}
        </StyledMenu>
      </Box>
  });

  const masterDetial2 = ({row, row:{original:{_id:InventoryID},id:key,index}})=>{
    const renderDelete=(data)=> {
      setPopup({open:false}) // clear popup data
        setPopup(p=>({...p,
          open:true,
          zIndex: 9999,
          title:`Are you sure you want to permanently delete this item?`,
          description:`This cannot be undone`,
          handleClose: closeAllPopups,
          handleSubmit: ()=>deleteInventoryItem(data),
      }))
    }; 

    const deleteInventoryItem=(_id)=>{
      inventoryDelete({InventoryID:_id},({success,info,err})=>{
        closeAllPopups()
        setInventoryMap({t:'delete',k:_id})
        globalWrn('success',`Delete Successful.`)
        setData(p=> {
          p.filter((x,i)=> i!==index )
          return p;
        });
        rebuild()
        })
      }; 

  if(inventoryMap.has(InventoryID)) var {ProjectID} = inventoryMap.get(InventoryID)

    if(alignmentRef.current==='Projects'){
      var items = [].concat(...Array.from([...inventoryMap],([k,v])=> v?.ProjectID?.includes(ProjectID[0]) ? {...v,_id:k} : [] ))
      return <Box sx={{maxHeight:'55vh',overflow:'auto'}}>{ dynamicComponent('InventoryGrid','elements',{state:'DataGridBuilder',data:items,alignments:alignment2,disableGrouping:true},mainState) }</Box>
    }

    const handleChange = (e, newValue) => setValue(newValue);
    const handleChangeIndex = (index) => setValue(index);  

   // Set the states
   if(InventoryID && row?.getIsExpanded() && !stateRef?.['BOL_'+[InventoryID]]) {
    buildBOL({row, InventoryID, ProjectID})
  }
  if(InventoryID && row?.getIsExpanded() && !stateRef?.['Inventorylog_'+[InventoryID]]) {
    generateInventoryLog({InventoryID})
  }

  // Clear the states
  if(InventoryID && stateRef?.['BOL_'+[InventoryID]] === InventoryID && row?.getIsExpanded() === false) {
    setState(p=>({...p,
    ['BOL_'+[InventoryID]]: undefined
  }))
}
  if(InventoryID && stateRef?.['Inventorylog_'+[InventoryID]] === InventoryID && row?.getIsExpanded() === false) setState(p=>({...p,
    ['Inventorylog_'+[InventoryID]]: undefined
  }))

    var tabs= [
      {label:'Summary',type:'tab',field: [addressTab2({InventoryID,ProjectID:ProjectID?.[0]})]},
      {label:'Shipping Status',type:'tab',field:[<Typography variant="body2" sx={{fontWeight: 400, color:'#ffffff',lineHeight:1}}>Features Coming Soon!</Typography>]},
      {label:'Freight Log', type:'tab',field:[stateRef['BOLState_'+[InventoryID]]||waveLoad()]},
      {label:'Inventory Log',type:'tab',field:[stateRef['Inventorylog_'+[InventoryID]]||waveLoad()]},
      {label:'Sign-Off History',type:'tab',field:[signOffHist2({InventoryID, row})]},
       UserMap.get('level') < 2 && {label:'Delete Line Item',type:'button',action:()=>renderDelete(InventoryID),field:[<Typography variant="body2" sx={{fontWeight: 400, color:'#ffffff',lineHeight:1}}>Delete Line Item.</Typography>]},
      ];

    return <Box sx={{width: rowVirtualizerInstanceRef.current?.scrollRect?.width-30,position:'sticky',top:'1%',left:'1%'}}>
      <AppBar position="static">
   <Tabs indicatorColor="secondary" textColor="inherit" variant="fullWidth" aria-label="full width  tabs" value={valueRef} onChange={handleChange} >
  {tabs.flatMap(({label,type,action,disabled},i)=>{ 
    if(type==='tab' && disabled !== true) return <Tab key={'label'+label+i} label={label} {...a11yProps(i)} />
    if(type==='button') return [<Box sx={{p:'.5%'}}>{dynamicComponent('DefaultButton','elements',{state:'tabAction',label:label,type:'button',startIcon:<Transform/>},mainState,[action])}</Box>]
  })}
</Tabs>
</AppBar>
<SwipeableViews axis={direction === 'rtl' ? 'x-reverse' : 'x'} index={valueRef} onChangeIndex={handleChangeIndex} >
{ tabs.flatMap(({label,field},i)=>
  <TabPanelMUI key={'Field'+label+i}  value={valueRef} index={i} dir={direction}>{field || <Typography variant="body2" sx={{fontWeight: 400, color:'#ffffff',lineHeight:1}}>Missing</Typography> }</TabPanelMUI>
  )}
</SwipeableViews>
      </Box>
  };

  const generateInventoryLog = ({InventoryID}) => {
    setState(p=>({...p,
      ['Inventorylog_'+[InventoryID]]: getHistory2({InventoryID})
    }))
    }

  const signOffHist2= ({InventoryID})=>{
    if(inventoryMap.has(InventoryID)) var {_id,TransferRequests} = inventoryMap.get(InventoryID)

      var labels = TransferRequests?.flatMap(({requestor})=> requestor ? `Aquired by: ${teamMember.get(requestor)?.firstName} ${teamMember.get(requestor)?.lastName}` : "Requestor not recorded!")
      var sigs = TransferRequests?.flatMap(({timestamp,requestor})=>`https://www.dev-services.ekc.app/profiles/inventory/${_id}/${_id}/transfer/${requestor}${_id}${dayjs(timestamp).format()}.png`)
      var colors = ["#ff0000", "#0dff00", "#0011ff"]
      var secondaryTitles = TransferRequests?.flatMap(({timestamp})=> timestamp ? dayjs(timestamp).format(`MMMM DD, 'YY hh:mma`) : "Date/Time not recorded")
      var secondaryDesc = TransferRequests?.flatMap(({authorizor})=> authorizor ? `Authorized by: ${teamMember.get(authorizor)?.firstName} ${teamMember.get(authorizor)?.lastName}` : `Authorizor not recorded!`)
      return <Box sx={{height:'75vh', overflow:'auto', backgroundColor: "rgba(0,0,0,0.35)"}}>
              { dynamicComponent('Timeline_Field','elements',{state:'timeline',labels:labels, alternate:"alternate", missing:'There is nothing to currently show.', colors:colors, secondaryTitle:secondaryTitles, avatarContent:sigs, description:secondaryDesc },mainState) }
            </Box> 
  };
  
  const getHistory2= ({InventoryID})=>{
    if(inventoryMap.has(InventoryID)) var {dates} = inventoryMap.get(InventoryID)
    return <Card>
        <Timeline position="alternate">
            {dates ? dates.flatMap(x=>
                  <TimelineItem>
                            <TimelineOppositeContent color="text.secondary">
                            {dayjs(x.timestamp).format('lll')}
                            </TimelineOppositeContent>
                        <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Box>
                                        <Typography>{`${x?.quantityType}: ${x?.quantity||x?.type}`}</Typography>
                                    </Box>
                                </TimelineContent>
                        </TimelineItem> )
            : <Typography>No History Available</Typography> }
    </Timeline>
    </Card>
  };

  const buildBOL=async({InventoryID})=>{
    setState(p=>({...p,
        ['BOL_'+[InventoryID]]: InventoryID
       }))
    FreightLog(null,InventoryID)
  }

  const addressTab2 = ({ProjectID}) => {
    if(projectMap.has(ProjectID)) var{ ProjectTitle, ProjectDesc, PoNumber, ProjectState, SalesState } = projectMap.get(ProjectID)
    if(SalesState?.SiteID?.[0]&&teamMember.has(SalesState.SiteID[0])) var {address} = teamMember.get(SalesState.SiteID[0])
    if(SalesState?.clientID) var clients = SalesState.clientID.filter(x=>teamMember.has(x)).flatMap(x=>memberAssemble(x))
    if(SalesState?.partnerID) var partners = SalesState.partnerID.filter(x=>teamMember.has(x)).flatMap(x=>memberAssemble(x))  
  
    function memberAssemble(x){
      if(!x) return
      if(x&&teamMember.has(x)) var {firstName,lastName,workPhone,email} = teamMember.get(x)
      return <ListItem sx={{p:'unset'}}>
               <ListItemAvatar sx={{minWidth:35}}>
                { dynamicComponent('AvatarBubble','elements',{state:'teams',label:'missing Lead', isInventory: true, style:{width:'30px',height:'30px'},userID:x },mainState) }
                 </ListItemAvatar>
                 <ListItemText sx={{overflow:'hidden',textOverflow:'ellipsis',display:'flex',flexDirection:'column'}} primary={<Typography variant="subtitle">{[firstName,' ',lastName]}</Typography>} secondary={<Typography variant="caption">{workPhone||email}</Typography>} />
              </ListItem>
    };
  
  return <Box sx={{display:'flex',gap:'1%',overflow:'auto',backgroundColor:'rgba(0,0,0,.55)',color:'#fff',borderRadius:'4px'}}>
        <List sx={{flex:1}}>
        <Typography variant="subtitle1" ml={1}>{ProjectTitle||'Project'}</Typography>
          <Divider />
          <ListItem sx={{gap:'2%'}}>
            <ListItemAvatar>
            <Typography variant="subtitle1" >Job #:</Typography>
            </ListItemAvatar>
            <ListItemText sx={{overflow:'hidden',textOverflow:'ellipsis'}} primary={<Typography variant="subtitle2">{PoNumber}</Typography>} />
          </ListItem>
          <Divider variant="inset"  />
          <ListItem sx={{gap:'2%'}}>
            <ListItemAvatar>
            <Typography variant="subtitle1" >Manager:</Typography>
            </ListItemAvatar>
            <ListItemText sx={{overflow:'hidden',textOverflow:'ellipsis'}} primary={<Typography variant="subtitle2">{memberAssemble(ProjectState?.projManager)||'Missing'}</Typography>} />
          </ListItem>
          <Divider variant="inset"  />
          <ListItem sx={{gap:'2%'}}>
            <ListItemAvatar>
            <Typography variant="subtitle1" >Lead:</Typography>
            </ListItemAvatar>
            <ListItemText sx={{overflow:'hidden',textOverflow:'ellipsis'}} primary={<Typography variant="subtitle2">{memberAssemble(ProjectState?.teamLead)||'Missing'}</Typography>} />
          </ListItem>
        </List>
  
        <List sx={{flex:1}}>
        <Typography variant="subtitle1" >CLIENTS:</Typography>
        <Divider />
          <ListItem sx={{gap:'2%'}}>
            <ListItemAvatar>
            <Typography variant="subtitle1" >Site:</Typography>
            </ListItemAvatar>
             <ListItemText sx={{overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'break-spaces'}} primary={<Typography variant="subtitle2">{address?.label||'Not Set in Project'}</Typography>} />
          </ListItem>
          <Divider variant="inset"  />
          <ListItem sx={{gap:'2%'}}>
            <ListItemAvatar>
            <Typography variant="subtitle1" >Contacts:</Typography>
            </ListItemAvatar>
            {clients||'Not Set'}
          </ListItem>
          <Divider variant="inset"  />
          <ListItem sx={{gap:'2%'}}>
            <ListItemAvatar>
            <Typography variant="subtitle1" >SOW:</Typography>
            </ListItemAvatar>
            <ListItemText sx={{overflow:'hidden',textOverflow:'ellipsis'}} primary={<Typography variant="subtitle2">{'Scope of Work'}</Typography>} />
          </ListItem>
          </List>
  
        <List sx={{flex:1}}>
        <Typography variant="subtitle1" >PARTNERS:</Typography>
        <Divider />
          <ListItem sx={{gap:'2%'}}>
            <ListItemAvatar>
            <Typography variant="subtitle1" >Contacts:</Typography>
            </ListItemAvatar>
            {partners||'Not Set'}
          </ListItem>
          <Divider variant="inset"  />
          <ListItem sx={{gap:'2%'}}>
            <ListItemAvatar>
            <Typography variant="subtitle1" >Notes:</Typography>
            </ListItemAvatar>
            <ListItemText sx={{overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'break-spaces'}} primary={<Typography variant="subtitle2">{ProjectDesc||'Missing'}</Typography>} />
          </ListItem>
          </List>
        </Box>
    }; 
  
    function a11yProps(i) {
      return {
        id: `full-width-tab-${i}`,
        'aria-controls': `full-width-tabpanel-${i}`,
      };
    }

    function TabPanelMUI({ children, value, index, ...other }) {
      return <Box
          role="tabpanel"
          hidden={value !== index}
          id={`full-width-tabpanel-${index}`}
          aria-labelledby={`full-width-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box sx={{ p: '.35%' }}>
              {children}
            </Box>
          )}
        </Box>
    };

    const bttmBarView2 = ()=>{
      return <Box sx={{ display: 'flex', gap: '16px', width:'100%', flexWrap: 'wrap', justifyContent:'space-between' }} >
          <Box sx={{display:'flex', gap:'4%'}}>
          { stateRef.exportBttn }
          <Box>
          <Typography variant="caption" color="inherit" sx={{m:'auto 0',lineHeight:1, whiteSpace:'nowrap'}}>
          { stateRef.statusFilter||'All' }
        </Typography>
          <Typography variant="subtitle1" color="inherit" sx={{m:'auto 0',lineHeight:1}}>
         { stateRef.totalVal || loadingChip }
        </Typography>
          </Box>
          </Box>
          <ButtonGroup aria-label="Table options" variant="outlined">
          <MRT_ToggleFiltersButton table={table} />
          <IconButton onClick={()=> setState(p=>({...p,selection: !p.selection })) } >
             { stateRef.selection ? <RemoveDone/> : <DoneAll />}
            </IconButton>
           <MRT_ToggleDensePaddingButton table={table} />
           <IconButton onClick={()=> window.print() } >
              <Print />
            </IconButton>
           { <MRT_ShowHideColumnsButton table={table} /> }
            { stateRef.transferButton }
        </ButtonGroup>
          </Box>
    }
  
const columns = useMemo(() => [
      {
        id: 'PurchaseOrder',
        accessorKey: 'PurchaseOrder',
        header: "Purchase Order #",
        Cell: displayView2,
        enableEditing: false
        },
     {
      id: 'OrderNumber',
      accessorKey: 'OrderNumber',
      header: "Order #",
      Cell: displayView2,
      enableEditing: false
    },
    {
      id: 'projectName',
      accessorKey: 'projectName',
      header: "Job Number",
      Cell: displayView2,
      enableEditing: false
    },
    {
      id: 'QTYOrdered',
      accessorKey: 'QTYOrdered',
      header: "QTY Ord",
      Cell: displayView2,
      enableEditing: false
    },
    {
      id: 'QTYReceived',
      accessorKey: 'QTYReceived',
      header: "QTY Rec",
      Cell: displayView2,
      Edit: editorRender2,
      enableEditing: ![pullReq,Purchasing].includes(true),
    },
    {
      id: 'QTYOnHand',
      accessorKey: 'QTYOnHand',
      header: "QTY OH",
     Cell: displayView2,
     Edit: editorRender2,
     enableEditing: true,
    },
    {
      id: 'ProductItems',
      accessorKey: 'ProductItems',
      visible: true,
      header: "Products",
      size: 275,
      Cell: productBuildOut2,
      enableEditing: false
    },
    {
      id: 'TrackingNumber',
      accessorKey: 'TrackingNumber',
      visible: true,
      header: "Loc. Stats",
      size: 175,
      dataType: 'string',
      Cell: locStat2,
      enableEditing: false
    },
    {
      id: 'PreferredVendor',
      accessorKey: 'PreferredVendor',
      visible: true,
      header: "Vendor",
      Cell: preferredVendor2,
      allowEditing: false
    },
    {
      id: 'Manufacturer',
      accessorKey: 'Manufacturer',
      visible: true,
      header: "Manufacturer",
      Cell: manufacturerField,
      allowEditing: false
    },
    {
      id: 'TotalValue',
      accessorKey: 'TotalValue',
      visible: true,
      header: "Total $",
      Cell: totalValue2,
      enableEditing: false
    },
    {
      id: 'Status',
      accessorKey: 'Status',
      visible: true,
      header: "Status",
      Cell: statusDrp2,
      enableEditing: false
    }

    ],[tempValRef,open,dataRef.current]);

    const columnsVal = useMemo(()=>
      columns?.flatMap(x=>{
        if (x?.name === "date") return {
          accessorKey: x?.name,
          header: x?.title,
          enableEditing: false,
          accessorFn: (x) => new Date(x?.date),
          filterVariant: 'date-range',
          Cell: ({ cell }) => `${cell?.row?.original?.date}`, // convert back to string for display
        }
        else if (x?.name === "time") return {
          accessorKey: x?.name,
          header: x?.title,
          enableEditing: false,
        }
        else if (x?.name === "numberSet") return {
          accessorKey: x?.name,
          header: x?.title,
          enableEditing: false,
        }
        else if (x?.name === "_id") return {
          id: x?.name,
          header: 'Employee',
          enableEditing: false,
          accessorFn: (x) => x?.user,
          filterVariant: "autocomplete",
         /* filterSelectOptions: (multiProject ? multiProject : projFollow)?.filter(x=>projectLoggMap.has(x))?.flatMap((x,i)=>
          projectLoggMap.get(x)?.flatMap((y,i2)=>
           y.user && y.user
              ?.filter(x=>x!==undefined)
              ?.flatMap((x)=>{
                return `${teamMember.get(x)?.firstName} ${teamMember.get(x)?.lastName}`
              })
            ))?.reduce((unique, item) => unique.includes(item) ? unique : [...unique, item], []),*/
          accessorKey: x?.name,
          Cell: ({ row }) => (
            <Box sx={{ display: 'flex', alignItems: 'center',gap:'5%' }} >
            <Box>{dynamicComponent('AvatarBubble','elements',{state:'teams',label:'Employee', isProject: true, style:{width:'30px',height:'30px',margin:'unset'},userID: row.original.userID },mainState)}</Box>
            <Typography variant='caption' >
              {row.original.user}
            </Typography>
          </Box>
          ),
        }
        else return {
          accessorKey: x?.name,
          header: x?.title,
        }
  }).filter(x=>x?.accessorKey !== 'user'), [columns],);

    const closeAllPopups=()=>{
      setPopup({open:false})
      setPopup2({open:false})
    }

    function replaceKeysAndValuesInArrayOfObjects(array, oldKey, newKey, idArr) {
      // Iterate through each object in the array
      array.forEach(obj => {
          // Check if the object has the oldKey
          if (obj.hasOwnProperty(oldKey)) {
              // Save the value associated with the oldKey
              let value = obj[oldKey];
              // If newValue is defined, replace the value, otherwise keep it the same
              if (oldKey === "ProductItems")
              {
                if(idArr) idArr.push(obj[oldKey]?.[0]?.id);
                value = value?.[0]?.Product?.DISPLAY_NAME?.substring(0, 16);
                console.log('value',value)
              }
              // Delete the oldKey
              delete obj[oldKey];
              // Add the newKey with the saved value
              obj[newKey] = value;
          }
      });
      return array;
  }

  function roundToDecimal(number, decimalPlaces) {
    const factor = Math.pow(10, decimalPlaces);
    return Math.round(number * factor) / factor;
  }

  function filterKeysInArrayOfObjects(array, keysToKeep) {
    array.forEach(obj => {
        Object.keys(obj).forEach(key => {
            if (!keysToKeep.includes(key)) {
                delete obj[key];
            }
        });
    });
    return array;
  }

  function orderObjectByKey(obj) {
    const sortedKeys = Object.keys(obj).sort();
    const orderedObject = {};
    sortedKeys.forEach(key => {
      orderedObject[key] = obj[key];
    });
    return orderedObject;
  }

  function generateKeys(array, keysToKeep) {
    array.forEach(obj => {
      keysToKeep.forEach(key => {
          if (!obj?.[key]) {
            obj[key] = "No Value";
          }
      });
    });
    return array;
  }

  // Ensure that dataRef.current is not modified when using it as a variable
  function deepCopy(obj) {
    if (typeof obj !== 'object' || obj === null) {
      return obj; // Base case: if obj is not an object, return it
    }
  
    let copy;
    if (Array.isArray(obj)) {
      copy = [];
      obj.forEach((item, index) => {
        copy[index] = deepCopy(item); // Recursively copy array elements
      });
    } else {
      copy = {};
      Object.keys(obj).forEach(key => {
        copy[key] = deepCopy(obj[key]); // Recursively copy object properties
      });
    }
  
    return copy;
  }
  
  
    const onExporting = useCallback((type) => {
      const doc = new jsPDF();
      
      // Perform a deep copy, so dataRef.current is not modified when performing operations
      var tempArr = deepCopy(dataRef.current)
      var idArr = []

      tempArr.forEach((x,i)=>{tempArr[i].Manufacturer = productMap.has(x?.ProductItems?.[0]?.id) ? productMap.get(x?.ProductItems?.[0]?.id)?.MANUFACTURER : "No Value"})
      tempArr.forEach((x,i)=>{tempArr[i].TotalValue = roundToDecimal((productMap.get(x?.ProductItems?.[0]?.id)?.MSRP) * (x?.QTYOnHand?x?.QTYOnHand:0), 2) })
      tempArr.forEach((x,i)=>{tempArr[i].vendorID = vendorMap.has(x?.vendorID) ? vendorMap.get(x?.vendorID)?.Name : "No Value"})
      tempArr = [...replaceKeysAndValuesInArrayOfObjects(tempArr, "ProductItems", "ProductItems", idArr)]
      tempArr = [...replaceKeysAndValuesInArrayOfObjects(tempArr, "vendorID", "PreferredVendor")]

      var tableHeaders
      const tableHead = columns.map((c)=>c.accessorKey);

      tempArr = filterKeysInArrayOfObjects(tempArr, tableHead)
      tempArr = generateKeys(tempArr, tableHead)
      tempArr = tempArr.map(x=>orderObjectByKey(x));

      var tableData = tempArr.map(x=>Object.values(x))
      tableHeaders = Object.keys(tempArr?.[0]) || []

      ['IMAGE_SPOTLIGHT'].concat(columns)

      var columnNames = []
      tableHeaders.map(x=>columns.map(y=>{if(Object.values(y).includes(x)) columnNames.push(y?.header)}))
        if(type==='PDF') {
        autoTable(doc, {
          styles: {
            cellWidth: 'auto',
            minCellWidth: 17
          },
          head: [columnNames],
          body: tableData,
        });
        doc.save(`Inventory_Sheet_${dayjs().format('lll')}.pdf`);
      }
      if(type==='Excel') 
      {

        const getColumnWidth = {
          "Order #": 15,
          "Vendor": 15,
          "Manufacturer": 20,
          "Products": 40,
          "Purchase Order #": 25,
          "QTY OH": 10,
          "QTY Ord": 10,
          "QTY Rec": 10,
          "Status": 25,
          "Total $": 12,
          "Loc. Stats": 25,
          "Job Number": 25,
        };

        const downloadFile=(buffer)=>{
          saveAs(new Blob([buffer], { type: "application/octet-stream" }), `Inventory_Sheet_${dayjs().format('lll')}.xlsx`);
        }
        var x = {};
        x.columns = columnNames;
        x.rows = tableData;

        ExcelCreate({data:x,columnWidths:getColumnWidth,location:"test",name:"Test"},(buffer)=>{
          downloadFile(buffer);
        })
      };
    }, []);

  const table = useMaterialReactTable({
    columns,
    data: dataRef.current,
    enableFullScreenToggle: false,
    enableColumnFilterModes: true,
    enableGlobalFilter: false,
    enableSorting: true,
    enableMultiSort: true,
    defaultDisplayColumn: { enableResizing: true },
    enableBottomToolbar: true,
    enableTopToolbar: false,
    enableColumnResizing: true,
    enableColumnVirtualization: true,
    enableGlobalFilterModes: true,
    enablePagination: [pullReq,Purchasing].includes(true),
    enableColumnPinning: true,
    enableRowNumbers: false,
    enableRowVirtualization: true,
    muiTableContainerProps: { sx: { maxHeight: (gridRef.current?.clientHeight||500)-56 } },
    rowVirtualizerOptions: { overscan: 15 }, //optionally customize the row virtualizer
    positionToolbarAlertBanner: 'bottom',
    displayColumnDefOptions: { 
      'mrt-row-expand': { size: 0 },
      'mrt-row-select': { size: 0 },
    },
    onSortingChange: setSorting,
    state: { isLoading, sorting },
    rowVirtualizerInstanceRef, //optional
    enableColumnFilterModes: true,
    enableEditing: true,
    editDisplayMode: 'cell',
    enableColumnOrdering: true,
    enableFacetedValues: true,
    enableRowActions: false,
    enableRowSelection: (row) => stateRef.selection ? false : true,
    enableSelectAll: stateRef.selection ? false : true,
    enableStickyHeader: true,
    enableRowPinning: true,
    columnFilterDisplayMode: 'popover',
    enableGrouping: true,
    groupedColumnMode: 'reorder',
    rowPinningDisplayMode: 'select-sticky',
    getRowId: (row) => row._id,
    initialState: {
      density: 'compact',
      showColumnFilters: true,
      showGlobalFilter: true,
      rowPinning: {
        top: [],
        bottom: [],
      },
      columnPinning: {
        left: ['mrt-row-expand', !stateRef.selection && 'mrt-row-select'],
        right: ['Status'],
      },
    },
    renderEmptyRowsFallback: () => <Box sx={{display:'flex',width:rowVirtualizerInstanceRef.current?.scrollRect?.width}}><Typography sx={{margin:'audo',textAlign:'center'}}>OMG THERE ARE NO ROWS 😳</Typography></Box>,
    muiTableBodyRowProps: ({ row, table }) => {
      const { density } = table.getState();
      return { sx: {...coloring[row.original?.Status], height: row.getIsPinned() ? `${ density === 'compact' ? 37 : density === 'comfortable' ? 53 : 69 }px` : undefined } };
    },
    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
      sx: {
        transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
        transition: 'transform 0.2s',
      },
    }),
    renderDetailPanel: masterDetial2,
    renderBottomToolbarCustomActions: bttmBarView2
  
  });

const DisplayViewStyle = {
  Typography: {
    whiteSpace: 'break-spaces',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden', 
    textOverflow: 'ellipsis', 
    lineHeight:1.1,
    textShadow:'.5px .5px 1px rgba(0,0,0,.35)',
  }
}

const loadingChip = <Box sx={{width:'100%'}}><Skeleton sx={{ backgroundColor: 'rgba(0,0,0,.15)' }} animation="wave" /></Box>

function PositionedMenu({globalState,dynamicComponent,mainState,alignments,gridRef}) {
  const [, setState, stateRef] = mainState;
  const [, setAlignment,alignmentRef] = alignments;
  const [, setAnchorEl,anchorRef] = useState(null);
  const [divisionMap] = useAtom(globalState.divisionMapState);
  const open = Boolean(anchorRef.current);
  const { scrollStatHorz, onScrollHandler } = globalState.useScroll();

  const handleClose = () => setAnchorEl(null);
  const onLongPress = (e)=> setAnchorEl(e);
  
  const longPressEvent = useLongPress({onLongPress, defaultOptions:{
    shouldPreventDefault: true,
    delay: 250,
  }}) 

  const handleChange = (e,val) =>{ 
    if(val) setAlignment(val) 
  }

  const handleDivisionChange=(x)=>{
    setState(p=>({...p,divisionSet:x}));
    gridRef.current?.instance?.getScrollable()?.scrollTo(0);
    handleClose()
  }

  return [!disableStatusFilter && <ToggleButtonGroup
            color={"secondary"}
            value={alignmentRef.current}
            size="small"
            exclusive
            onChange={handleChange}
            onScroll={onScrollHandler}
            sx={{width:'99.25%',p:'.25%',backdropFilter:'blur(15px)',backgroundColor:'rgba(18,18,28,.2)',border:'1px solid rgba(18,18,18,0.05)',overflow:'auto',...scrollStatHorz.style}}
          >
          { ['Sites','Projects','Division','Vendors','Inventory'].map((x,i,a)=>{
            if(x==='DIVIDER') return <Divider orientation="vertical" key={x} variant="middle" flexItem sx={{m:'auto 1%'}}/>

            return <Grow in={true} key={`categoriesGrow-${i}`} timeout={{ enter: (i+1)*500 }} unmountOnExit>
              <ToggleButton {...longPressEvent} id={x} value={x} sx={{flex:1,padding:'6px', textShadow: '.5px .5px rgb(0 0 0 / 65%)', borderLeft: a[i-1]==='DIVIDER' ? '1px solid rgba(255, 255, 255, 0.12)!important' : '', boxShadow: alignmentRef.current === x ? 'inset -0.5px -0.5px 0 rgb(50 167 226 / 65%), inset 0.5px 0.5px rgb(50 167 226 / 65%)' : 'unset', backgroundColor: alignmentRef.current === x ? 'rgba(50, 167, 226,.25)' : 'unset'}} >
              <ListItemText 
              primary={<Typography variant="body2" sx={{fontWeight: 400, color:'#ffffff',lineHeight:1}}>{x}</Typography>} 
              secondary={(stateRef.current.divisionSet && x==='Division') && <Typography variant="caption" sx={{whiteSpace:'nowrap', fontSize: 10}}>( {divisionMap.get(stateRef.current.divisionSet)?.teamName} )</Typography>}
               />
              </ToggleButton>
            </Grow>
        }
        )}
        
         <Box sx={{m:'auto',p:'0 0 0 1.5%'}}>{dynamicComponent('DropDown','elements',{state:'statusFilter',label:'Select Status',defaultValue: stateRef.current.statusFilter,menuStyle:{display: 'flex', flexDirection: 'column', gap: '3px', padding: '1%'}, icon: statuses.find(({name})=> name===stateRef.current.statusFilter)?.icon, list:[{primary:'All',icon: <AllInclusiveIcon/>,divider:true,dividerText:'status'},...statusesMain.flatMap(x=>({...x,primary:x.name,style:coloring[x.name]})),{divider:true,dividerText:'other'},{primary:'On-Hand',icon: <BackHandIcon/>},{primary:'On-Site',icon: <EngineeringIcon/>},{primary:'Purchasing',icon: <ShoppingBasketIcon/>}], replace:true },mainState)}</Box>
         </ToggleButtonGroup>,
      <Menu
        id="Projects"
        aria-labelledby="Projects Filter"
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 8,
          sx: {
            overflow: 'auto',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} >
          { stateRef.current.divisionSet &&  <ListSubheader onClick={()=>{setState(p=>({...p,divisionSet:undefined}));gridRef.current?.instance?.getScrollable().scrollTo(0);handleClose()}} sx={{textAlign:'right',zIndex:2,background:'unset'}}>RESET</ListSubheader>}
          { [...new Set(Array.from([...divisionMap],([,{category}])=>category))].map(x=>[
          <ListSubheader key={x}>{x}</ListSubheader>,
          Array.from([...divisionMap],([k,{category,teamName}])=>category===x &&
       
          <ListItem key={k} disablePadding>
        <ListItemButton onClick={()=>handleDivisionChange(k)} selected={stateRef.current.divisionSet===k?true:false}>
          <ListItemAvatar>
            <Avatar alt={`Avatar n°${k}`} src={`https://dev-services.ekc.app/profiles/avatar/${k}_avatar.jpg`}/>
          </ListItemAvatar>
          <ListItemText id={k} primary={teamName} />
        </ListItemButton>
      </ListItem>
          )
          ]) }
      </List> 
      </Menu>]
}

useEffect(()=>{
  setState(p=>({...p,
    topFilterMenu: <PositionedMenu globalState={globalState} gridRef={gridRef} dynamicComponent={dynamicComponent} alignments={alignments} mainState={mainState}/>
  }))
},[alignments, gridRef])


return [<TaskManager That={mainState}/>,
    <Box sx={{display:'flex'}}>{stateRef?.topFilterMenu}</Box>,,
    <Box sx={{flex:1,overflow:'auto',display:'flex',flex:1}} ref={gridRef}><MaterialReactTable table={table} />{stateRef.productCard}</Box>
  ];
};

export default InventoryGrid;


export const statuses = [{
  id: 1, primary: 'Partial Received',icon: <Inventory2Outlined/>,
}, {
  id: 2, primary: 'Warehouse Received',icon: <Inventory/>,
}, {
  id: 3, primary: 'Partially Received and Delivered',icon: <AccountTree/>,
}, {
  id: 4, primary: 'Partial to Site',icon: <AirlineStops/>,
}, {
  id: 5, primary: 'Delivered to Site',icon: <AirportShuttle/>,
}, {
  id: 6, primary: 'Returned for RMA',icon: <LowPriority/>,
}, {
  id: 7, primary: 'Stock',icon: <Warehouse/>,
}, {
  id: 8, primary: "Requested", icon: <RequestQuote/>
}, {
  id: 9, primary: "Order Processing", icon: <HourglassTop/>
}
];

export const statuses2 = [{
id: 1, primary: 'Order Paused',icon: <PauseCircle/>,
}, {
id: 2, primary: 'Order Unavailable',icon: <ProductionQuantityLimits/>,
}, {
id: 3, primary: "Order Placed", icon: <AssignmentTurnedIn/>
}
];

export const statuses3 = [{
id: 1, primary: 'Shipping', icon: <LocalShipping/>,
}, {
id: 2, primary: "Order Checked On", icon: <RemoveDone/>
}]

export const statusesMain = [{
  id: 1, name: 'Shipping', icon: <LocalShippingIcon/>,
}, {
  id: 2, name: 'Partial Received',icon: <Inventory2OutlinedIcon/>,
}, {
  id: 3, name: 'Warehouse Received',icon: <InventoryIcon/>,
}, {
  id: 5, name: 'Partially Received and Delivered',icon: <AccountTreeIcon/>,
}, {
  id: 6, name: 'Partial to Site',icon: <AirlineStopsIcon/>,
}, {
  id: 7, name: 'Delivered to Site',icon: <AirportShuttleIcon/>,
}, {
  id: 8, name: 'Returned for RMA',icon: <LowPriorityIcon/>,
}, {
  id: 9, name: 'Stock',icon: <WarehouseIcon/>,
}, {
  id: 10, name: "Requested", icon: <RequestQuoteIcon/>
}, {
  id: 7, name: 'Order Placed/Processing',icon: <HourglassTopIcon/>,
}, {
  id: 8, name: 'Order Paused',icon: <PauseCircleIcon/>,
}, {
  id: 9, name: 'Order Unavailable',icon: <ProductionQuantityLimitsIcon/>,
}, {
  id: 9, name: 'Order Checked On',icon: <PendingActionsIcon/>,
}
];