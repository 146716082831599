import React, { memo, useEffect, useRef } from "react";
import { useAtom } from 'jotai';
import useState from 'react-usestateref';
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {Box,Paper,Typography,Card,CardContent,CardMedia,Chip,Collapse,Divider,IconButton,ListItem,ListItemAvatar,ListItemText,ToggleButton} from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import dayjs from 'dayjs';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import ToggleButtonGroup, { toggleButtonGroupClasses } from '@mui/material/ToggleButtonGroup';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';

var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

const BootstrapTooltip = styled(({ className, useStyles, ...props }) => (
  <Tooltip {...props} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const getBackgroundColor = (isDraggingOver, isDraggingFrom) => {
  if (isDraggingOver) return '#ffb347';
  if (isDraggingFrom) return '#6488EA';
  return 'transparent';
};

const Wrapper = styled(Box)(({ theme,isDraggingOver,isDraggingFrom }) => ({
  border:`3px solid ${getBackgroundColor(isDraggingOver, isDraggingFrom)}`,
  display: 'flex',
  flexDirection: 'column',
  opacity: `${({ isDropDisabled }) => (isDropDisabled ? 0.5 : 'inherit')}`,
  padding: '8px',
  borderRadius: '8px',
  paddingBottom: 0,
  transition: 'background-color 0.2s ease, opacity 0.1s ease',
  userSelect: 'none',
  height:'100%'
}));

const CloneBadge = styled('div')(({ theme }) => ({
  background: '#79f2c0',
  bottom: `${8 / 2}px`,
  border: '2px solid #57d9a3',
  borderRadius: '50%',
  boxSizing: 'border-box',
  fontSize: '10px',
  position: 'absolute',
  right: `-${40 / 3}px`,
  top: `-${40 / 3}px`,
  transform: 'rotate(40deg)',
  height: '40px',
  width: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

function getStyle(provided, style) {
  if (!style) return provided.draggableProps.style;
}

         const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
            [`& .${toggleButtonGroupClasses.grouped}`]: {
              margin: theme.spacing(0.5),
              border: 0,
              borderRadius: theme.shape.borderRadius,
              [`&.${toggleButtonGroupClasses.disabled}`]: {
                border: 0,
              },
            },
            [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
              {
                marginLeft: -1,
                borderLeft: '1px solid transparent',
              },
          }));

          const textNotation = (label,value)=> <Paper elevation={0} sx={{ display: 'flex', border: (theme) => `1px solid ${theme.palette.divider}`,flexWrap: 'wrap', }} >
          <StyledToggleButtonGroup size="small" value={"left"} exclusive aria-label="Manufacturer" >
            <ToggleButton value="left" aria-label="left aligned" color={'primary'} sx={{p:0}}>
            <Typography variant="soft" color="primary" size="small"
        sx={{
          px: 0.2,
          cursor:'default',
          writingMode: 'vertical-lr',
          textAlign: 'center',
          fontSize: '14px',
          fontWeight: '900',
          letterSpacing: '1px',
          textTransform: 'uppercase',
          borderLeft: '1px solid',
          borderColor: 'divider',
          lineHeight: 1
        }}
      >{label}</Typography>
              </ToggleButton>
              <ToggleButton value="center" aria-label="centered">
            <Typography gutterBottom variant="subtitle2" style={{margin:'auto',maxWidth:'350px',lineHeight:1,fontSize:'13px',textTransform:'none',lineHeight:1.1,textAlign:'left'}}> {value || `Need to set ${label}`} </Typography>
            </ToggleButton>
            </StyledToggleButtonGroup>
            </Paper>

const TicketBoard = (props) => {
  const {globalState,dynamicComponent,globalWrn,dbUpdateValidate,noficationSubmit,isCombineEnabled,useClone,containerHeight,withScrollableColumns,projectBuilder,queryDB} = props
  const [projectMap] = useAtom(globalState.projectMapState);
  const [, setPopup] = useAtom(globalState.popupState);
  const [divisionMap] = useAtom(globalState.divisionMapState);
  const [UserMap] = useAtom(globalState.userMapState);
  const mainState = useState(Object);
  const [, setState, {current:stateRef}] = mainState;
  const outputState = useState(Object);
  const [, setOutput, outputRef] = outputState;
  const [, setOrdered] = useState([]);
  const moduleRef = useRef(null);

  useEffect(()=>{
    if(projectMap.size>1) reSyncItems()
  },[projectMap, projectMap.size,UserMap.get('ticketFilters'),stateRef.defaultColumns])

  const sorter = ([a],[b]) =>{ 
    if(stateRef.manualOrder) var columnFields = stateRef.manualOrder
    else{ if(UserMap.has('ticketFilters')) var {columnFields} = UserMap.get('ticketFilters') }
    return columnFields?.indexOf(a) - columnFields?.indexOf(b)
  }

  useEffect(()=>{
    if(!stateRef.defaultColumns) queryDB({model:'AdminOrg',query:{'fieldSet':'ListColumns'},filter:{'_id':0,'Tickets':1}},([{Tickets}],err)=>{
      var missing = Tickets.filter(x=>!stateRef?.sceneData?.[x]).flatMap(x=>({[x]:[]}))
      setState(p=>({...p,
        sceneData: Object.assign({},stateRef.sceneData,...missing),
        defaultColumns: Object.assign({},...Tickets.flatMap(x=>({[x]:[]})))
        }))
    })
  },[])

  useEffect(()=>{
   if(stateRef.sceneData&&Object.values(stateRef.sceneData).every(x=>x.length===0)) setState(p=>({...p, loading: true }))
   else if(stateRef.loading&&stateRef.sceneData) setState(p=>({...p, loading: false }))
  },[stateRef.sceneData])

  useEffect(()=>{
    if(stateRef.loading) setTimeout(() => {
      if(stateRef.loading){
        reSyncItems()
      }
    }, 5000);
  },[stateRef.loading])

  const reSyncItems = () =>{
   var ticketItems = Object.assign({},...globalState.groupByKey([...Array.from([...projectMap],([k,v])=>v)],'TicketStatus')
    .filter(({TicketStatus})=> TicketStatus!==undefined )
    .flatMap(({data,TicketStatus},i)=>({[TicketStatus]:data.flatMap(x=>x._id)}) ))
    setState(p=>({...p, 
      sceneData: p.defaultColumns ? Object.assign({},...Object.entries(p.defaultColumns).sort(sorter).map(([k,v])=>({[k]: [].concat(v,ticketItems[k]||[])}))) : ticketItems,
      loading: Object.keys(ticketItems).length===0 || Object.values(ticketItems).every(x=>x.length===0)
    }))
  }

  const submit = (id,status,event) => {

    if(projectMap.has(id)) var { ticketNotes, DivisionID } = projectMap.get(id)
    Object.keys(outputRef.current).filter(x=> x.startsWith('ticketNotes.'))?.forEach(x=>{ 
      var item = x.split('.').slice(-1)[0]
      var itemVal = outputRef.current['ticketNotes.'+item]
      // Clear out the old data
      delete outputRef.current?.['ticketNotes.'+item]
      setOutput({"$set":outputRef.current})
      setOutput(p=>({...p, 
        "$push" : {['ticketNotes.'+item]:{
          Notes: itemVal,
          isOriginal: ticketNotes?.[item]?.some(x=>x?.Notes!==itemVal) || true,
          timestamp: new Date(),
          UserID: UserMap.get('avatar')
        }},
      }))
     })
     projectBuilder({projID:{modelType:divisionMap.get(DivisionID)?.teamName,ProjectID:id},submitObj:outputRef.current},({success,proj,error})=>{     
      if(success===true){ 
           setOutput({})
           setPopup({open: false})
           noficationSubmit(projectMap.get(id),outputRef.current)
           reSyncItems()
         }else globalWrn('error',`There was an error updating the ticket`)
         setState(p=>({...p,loading : false }))
     })
  }

  const CreateUpdatesPopup=(id, status,from)=>{
    if(projectMap.has(id)) var { ticketNotes, WorkOrder, DivisionID, ProjectState } = projectMap.get(id)

      const Todo = () => dynamicComponent('InputField','elements',{state:'ticketNotes.TodoNotes',label: 'Todo Notes',defaultValue: ticketNotes?.TodoNotes?.[ticketNotes?.TodoNotes?.length - 1]?.Notes,type:'text',style:{margin:0},multiline:true},outputState)

      const CreateWorkOrder = () => [ dynamicComponent('InputField','elements',{state:'WorkOrder',label: 'Work Order Number',defaultValue: WorkOrder,type:'text',style:{margin:0},required:true},outputState),
            dynamicComponent('InputField','elements',{state:'ticketNotes.WOChanges',label: 'Work Order Changes',defaultValue: ticketNotes?.WOChanges?.[ticketNotes?.WOChanges?.length - 1]?.Notes,type:'text',style:{margin:0},required:true},outputState) ]
     
      const Schedule = () => [ dynamicComponent('UserButton','elements',{state:'ProjectState.teamLead',label:'Project Lead',defaultValue: ProjectState?.teamLead , divisionName: divisionMap.get(DivisionID) },outputState),
            dynamicComponent('DatePicker','elements',{state:'ProjectState.Calendar.startDate',label:'Project Start Date & Daily Start Time', defaultValue: ProjectState?.Calendar?.startDate, min: dayjs().startOf('day'), max: null },outputState),
            dynamicComponent('DatePicker','elements',{state:'ProjectState.Calendar.endDate',label:'Project End Date & Daily End Time', defaultValue: ProjectState?.Calendar?.endDate, min: outputRef.current?.['ProjectState.Calendar.startDate'] || dayjs().startOf('day'), max: null },outputState) ]

      const CloseWorkOrder = () => dynamicComponent('InputField','elements',{state:'ticketNotes.CloseWONotes',label: 'Close Work Order Notes',defaultValue: ticketNotes?.CloseWONotes?.[ticketNotes?.CloseWONotes?.length - 1]?.Notes,type:'text',style:{margin:0},multiline:true},outputState)

      const FollowUp = () => dynamicComponent('InputField','elements',{state:'ticketNotes.FollowUpNotes',label: 'Follow Up Notes',defaultValue: ticketNotes?.FollowUpNotes?.[ticketNotes?.FollowUpNotes?.length - 1]?.Notes,type:'text',style:{margin:0},multiline:true},outputState)
      
      const ReSchedule = () => [...Schedule(), dynamicComponent('InputField','elements',{state:'ticketNotes.RescheduleNotes',label: 'Reschedule Notes',defaultValue: ticketNotes?.RescheduleNotes?.[ticketNotes?.RescheduleNotes?.length - 1]?.Notes,type:'text',style:{margin:0},multiline:true},outputState) ]

      const Quotes = () => dynamicComponent('InputField','elements',{state:'ticketNotes.QuotesNotes',label: 'Quotes Notes',defaultValue: ticketNotes?.QuotesNotes?.[ticketNotes?.QuotesNotes?.length - 1]?.Notes,type:'text',style:{margin:0},multiline:true},outputState)

      const Completed = () => dynamicComponent('InputField','elements',{state:'ticketNotes.CompletedNotes',label: 'Completed Notes',defaultValue: ticketNotes?.CompletedNotes?.[ticketNotes?.CompletedNotes?.length - 1]?.Notes,type:'text',style:{margin:0},multiline:true},outputState)

      const NeedToComplete = () => dynamicComponent('InputField','elements',{state:'ticketNotes.NeedToCompleteNotes',label: 'Need to Complete Notes',defaultValue: ticketNotes?.NeedToCompleteNotes?.[ticketNotes?.NeedToCompleteNotes?.length - 1]?.Notes,type:'text',style:{margin:0},multiline:true},outputState)
          
      var items = Object({
        "Need To Complete": NeedToComplete,
        Quotes: Quotes,
        "Re-Schedule": ReSchedule,
        Completed: Completed,
        "Follow Up": FollowUp,
        "Close Work Order": CloseWorkOrder,
        Schedule: Schedule,
        "Create Work Order": CreateWorkOrder,
        Todo: Todo
      })[status]()

      setPopup(p=>({...p,
        open: true,
        zIndex: 1,
        title: `Moving ${from} ➝ ${status} field`,
        description: `Please fill out all required fields`,
        inputField: <Box sx={{padding: '3% 1% 1%'}}>{items}</Box>,
        handleSubmit: ()=> submit(id,status),
        handleClose: ()=>{ 
          setPopup({open:false});
          reSyncItems()
      }
      }))
  }

  const onDragEnd = ({combine,source,type,destination,droppableId,draggableId}) => { 
    setState(p=>({...p,dragging:false}))
    if (combine) {
      if (type === "COLUMN") {
        const shallow = Object.keys(stateRef.sceneData)
        shallow.splice(source.index, 1);
        return setOrdered(shallow);
      }

      const withQuoteRemoved = [...stateRef.sceneData?.[source.droppableId]];
      withQuoteRemoved.splice(source.index, 1);
      return setState(p=>({...p, 
        sceneData: { ...p.sceneData, [source.droppableId]: withQuoteRemoved }
      }))
    }


    if ( !destination || (source.droppableId === destination.droppableId && source.index === destination.index) ) return;

    // reordering column
    if (type === "COLUMN"){
        var reorder1 = reorder(Object.keys(stateRef.sceneData), source.index, destination.index)
        setState(p=>({...p,
          sceneData: Object.assign({},...reorder1.flatMap(x=>({[x]: stateRef.sceneData[x] }))),
          manualOrder: reorder1,
        }))
        return dbUpdateValidate({model:'User',query:true,update:{[`local.ticketFilters.columnFields`]:reorder1}},(x)=>{ })
    }
    var data = reorderQuoteMap({ quoteMap: stateRef.sceneData, source, destination});
    setState(p=>({...p, 
      sceneData: data.quoteMap
    }))

    if(destination?.droppableId!==source.droppableId){
      setOutput(p=>({...p, 
        TicketStatus: destination?.droppableId,
        statusTimestamp: new Date()
      }))
       CreateUpdatesPopup(draggableId, destination?.droppableId,source.droppableId)
    }
  };

  return <Box ref={moduleRef} sx={{overflow:'auto'}}><DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={()=> setState(p=>({...p,dragging:true})) }>
        <Droppable droppableId="board" type="COLUMN" direction="horizontal"
          ignoreContainerClipping={Boolean(containerHeight)}
          isCombineEnabled={isCombineEnabled}
        >
          {(provided) => <Box ref={provided.innerRef} {...provided.droppableProps} sx={{display:'inline-flex',gap:'.5%',height:'100%',overflow:'auto',width:'100%'}} >
          { Object.keys(stateRef.sceneData||[])?.filter(x=> !stateRef.archived ? x!=='Completed' : true)?.map((key,i) => 
            <Column
            key={key}
            index={i}
            title={key}
            parentState={mainState}
            {...props}
            moduleRef={moduleRef}
            isScrollable={withScrollableColumns}
            isCombineEnabled={isCombineEnabled}
            useClone={useClone}
            />
            ) }
            { !stateRef.archived && <Box sx={{display:'flex',height:'92vh'}}><Typography
                onClick={()=> setState(p=>({...p, archived: true }))}
                  variant="soft"
                  size="small"
                  sx={{
                    px: 0.2,
                    cursor:'default',
                    writingMode: 'vertical-rl',
                    textAlign: 'center',
                    fontSize: '14px',
                    fontWeight: '900',
                    letterSpacing: '1px',
                    textTransform: 'uppercase',
                    borderLeft: '1px solid',
                    borderColor: 'divider',
                    backgroundColor: '#ff4c30'
                  }}
                >ARCHIVED</Typography></Box> }
          </Box> }
        </Droppable>
      </DragDropContext>
      <Box sx={{position:'absolute',left:0,bottom:0}}>
      { dynamicComponent('DefaultButton','elements',{state:'resetGrid',label:'ReSync',type:'button',startIcon:<RestartAltIcon/>},mainState,[reSyncItems]) }
      </Box>
      </Box>
};

TicketBoard.defaultProps = {
  isCombineEnabled: false
};

TicketBoard.propTypes = {
  isCombineEnabled: PropTypes.bool
};

export default TicketBoard;



/* Reorder */

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const reorderQuoteMap = ({ quoteMap, source, destination }) => {
  const current = [...quoteMap[source.droppableId]];
  const next = [...quoteMap[destination.droppableId]];
  const target = current[source.index];

  // moving to same list
  if (source.droppableId === destination.droppableId) return { quoteMap: { ...quoteMap, [source.droppableId]: reorder(current, source.index, destination.index) } };

  current.splice(source.index, 1);
  next.splice(destination.index, 0, target);
  return { quoteMap: {...quoteMap,[source.droppableId]: current,[destination.droppableId]: next} };
};

const Column = (props) => {
  const {index,isScrollable,isCombineEnabled,useClone,parentState,title,moduleRef,waveLoad} = props
  const [,setState,{current:stateRef}] = parentState;

  return <Draggable draggableId={title} index={index}>
      {(provided, snapshot) =>{ 
     var styled = {backgroundColor: '#21364E', height:'100%',minWidth:'395px',minHeight:moduleRef?.current?.clientHeight*2||350+'px'}
     return <Paper ref={provided.innerRef} elevation={16} square={false} variant="outlined" sx={styled} {...provided.draggableProps} >
      <Box isDragging={snapshot.isDragging} {...provided.dragHandleProps} sx={{backgroundColor: 'rgba(18,18,18,.35)', display:'flex',justifyContent:'space-between',position:'sticky',top:0,zIndex:1,backdropFilter:'blur(15px)'}}>
        <DragIndicatorIcon />
        <Typography sx={{flex:1,textAlign:'center',pointerEvents:'none'}}>{title}</Typography>
        { title === 'Completed' &&  <DisabledByDefaultIcon onClick={()=> setState(p=>({...p, archived: false }))} />}
      </Box>
      {stateRef.loading && waveLoad()}
      <QuoteList
            listId={title}
            listType="QUOTE"
            style={{ backgroundColor: snapshot.isDragging && '#ffb347' }}
            {...props}
            internalScroll={isScrollable}
            isCombineEnabled={Boolean(isCombineEnabled)}
            useClone={Boolean(useClone)}
          />
      </Paper>} }
    </Draggable>
};

function QuoteList(props) {
const {ignoreContainerClipping, internalScroll, scrollContainerStyle, isDropDisabled, isCombineEnabled, listId = 'LIST', listType, style, quotes, title, useClone, parentState,globalState} = props
  return <Droppable
      droppableId={listId}
      type={listType}
      ignoreContainerClipping={ignoreContainerClipping}
      isDropDisabled={isDropDisabled}
      isCombineEnabled={isCombineEnabled}
      renderClone={ useClone && ((provided, snapshot, descriptor) =>
              <QuoteItem
                quote={quotes[descriptor.source.index]}
                provided={provided}
                isDragging={snapshot.isDragging}
                isClone
              /> )
      }
    >
   { (dropProvided, dropSnapshot) => <Wrapper style={{...style}} isDraggingOver={dropSnapshot.isDraggingOver} isDropDisabled={isDropDisabled} isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)} {...dropProvided.droppableProps} >
      <Box style={internalScroll && scrollContainerStyle} sx={{display:'flex',flexDirection:'column',gap:'10px',minWidth:'375px',minHeight:'200px',height:'100%'}}><InnerList {...props} dropProvided={dropProvided} /></Box> 
    </Wrapper> }
    </Droppable>
}

const InnerList = (props) => <Box sx={{display:'flex',flexDirection:'column',gap:'10px',minWidth:'375px',minHeight:'200px',height:'100%'}}>
  <Box sx={{minHeight: '250px',paddingBottom:' 8px',display:'flex',flexDirection:'column',gap:'7px',height:'100%'}} ref={props.dropProvided.innerRef}>
      <InnerQuoteList {...props} />
      {props.dropProvided.placeholder}
  </Box>
  </Box>

const InnerQuoteList = memo(function InnerQuoteList(props) {
  const {parentState,title,globalState,dynamicComponent,globalWrn} = props
  const [,,{current:stateRef}] = parentState;
  return stateRef.sceneData?.[title]?.map((id, index) =>
    <Draggable key={id} draggableId={id} index={index}>
      { (dragProvided, dragSnapshot) => <QuoteItem
          key={id}
          ProjectID={id}
          globalState={globalState}
          parentState={parentState}
          globalWrn={globalWrn}
          dynamicComponent={dynamicComponent}
          isDragging={dragSnapshot.isDragging}
          isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
          provided={dragProvided}
        /> }
    </Draggable> )
});

const QuoteItem = memo(function QuoteItem({ isDragging, isGroupedOver, provided, style, isClone, index, globalState, ProjectID, parentState, dynamicComponent, globalWrn}) {
  const [projectMap] = useAtom(globalState.projectMapState);
  const [, setGlobalAppState] = useAtom(globalState.appState);
  const [, setPopup2] = useAtom(globalState.popupState2);
  const [divisionMap] = useAtom(globalState.divisionMapState);
  const [UserMap] = useAtom(globalState.userMapState);
  const [,setState,{current:stateRef}] = parentState;

  var cardFocus = stateRef.focusedCard===ProjectID
  if(projectMap.has(ProjectID)) var {DivisionID,ProjectState,ProjectTitle,ProjectDesc,Created,TicketNumber,TicketStatus,ticketNotes,Repository} = projectMap.get(ProjectID)

  const viewFiles=()=>{
    if(!ProjectID) return globalWrn('error',`You will need to create the project ${ProjectTitle || ''} first.`)
    setPopup2(p=>({...p,
      open:true,
      title:`${ProjectTitle} Project Files`,
      inputField: dynamicComponent('repository','component',{folderID: Repository?.[`${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}folderID`],folderName:'/EKC/'+UserMap.get('pageStamp').active,projID:{ ProjectID, DivisionID } },parentState),
      handleClose: ()=>setPopup2({open:false}),
      cancelLabel: 'Close',
  }))
  }

  return <Box
      isDragging={isDragging}
      isGroupedOver={isGroupedOver}
      isClone={isClone}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getStyle(provided, style)}
      data-is-dragging={isDragging}
      data-testid={ProjectID}
      data-index={index}
      aria-label={ProjectID}
    >
<Card sx={{ display: 'flex',width:'100%'}}>
  <CardMedia
      component="img"
      sx={{ width: 100, maxHeight: cardFocus?'unset':87, height:'93%', maxWidth:70, borderRadius:'4px',m:'auto 2%',pointerEvents:'none' }}
      image={
        ProjectState?.projManager ? `https://${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}services.ekc.app/profiles/avatar/${ProjectState?.projManager}_avatar.jpg` :
      "https://www.irecsolarcareermap.org/assets/images-jobs/SolarAssemblerInstaller.jpg"}
      alt="Live from space album cover"
    />
    <Box sx={{ display: 'flex', flexDirection: 'column',flex:1,gap:'3px' }}>
      <CardContent sx={{ minWidth:'150px',flex: '1 0 auto',display:'flex',flexDirection:'column',justifyContent:'space-evenly',padding:0,gap:'2%'}} onClick={()=>{ setState(p=>({...p,focusedCard:ProjectID})) }}>
        <Box sx={{display:'flex',justifyContent:'space-between'}}>
      <ListItem alignItems="flex-start" sx={{padding:'unset',gap:'3%'}} >
        <ListItemAvatar sx={{margin:'auto',minWidth:'unset'}}>
          <Box sx={{ position: 'relative',width:'40px',height:'40px',margin:'auto' }}>
    <CircularProgress
      variant="determinate"
      sx={{
        color: (theme) =>
          theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
      }}
      size={40}
      thickness={4}
      value={100}
    />
    <CircularProgress
      variant="determinate"
      disableShrink
      sx={{
        color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
        [`& .${circularProgressClasses.circle}`]: {
          strokeLinecap: 'round',
        },
      }}
      size={40}
      thickness={4}
      value={0}
    />
    <Box sx={{ top: 0, left: 0, bottom: 0, right: 0, position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Typography variant="caption" sx={{fontWeight:900,color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8')}} >
        {`0%`}
      </Typography>
    </Box>
  </Box>
</ListItemAvatar> 
<ListItemText sx={{minWidth:'150px'}} primary={<Typography variant="body2" sx={{m:'auto',m:'auto',overflow: 'hidden',display:'-webkit-box',WebkitLineClamp:2,WebkitBoxOrient:'vertical',lineHeight:1}}>{ProjectTitle}</Typography>} secondary={  <Typography variant="caption" sx={{m:'auto',overflow: 'hidden',display:'-webkit-box',WebkitLineClamp:2,WebkitBoxOrient:'vertical',lineHeight:1}} >{ProjectDesc}</Typography> } /> 
</ListItem> 
    </Box>
   <Box sx={{display:'flex', gap:'2%',justifyContent:'flex-start'}}>
   { ["Create Work Order","Todo","Follow Up"].includes(TicketStatus) && 
        [ <Chip size="small" variant="outlined" color="warning" 
          avatar={<CalendarMonthIcon sx={{width:'16px!important', color:'#328ce6!important'}} />}
          label={ <Typography variant="caption" noWrap>C: {dayjs(Created)?.format('l')}</Typography> }
          sx={{fontSize:'12px','& .MuiChip-label': { display: 'block',whiteSpace: 'normal', lineHeight: 1.1, color:'#328ce6!important', outlineColor:'#328ce6!important' }}}
        />, <Divider/>]}
        {ProjectState?.Calendar?.startDate && ["Schedule"].includes(TicketStatus) &&
        [<Chip size="small" color="warning" variant="outlined"
          avatar={<CalendarMonthIcon sx={{width:'16px!important', color:'#10c93b!important'}} />}
          label={ <Typography variant="caption" noWrap>S: {dayjs(ProjectState?.Calendar?.startDate)?.format('l')}</Typography> }
          sx={{fontSize:'12px','& .MuiChip-label': { display: 'block',whiteSpace: 'normal',lineHeight: 1.1,color:'#10c93b!important',outlineColor:'#10c93b!important'}}}
        />, <Divider/>]}
        {ProjectState?.Calendar?.endDate && ["Schedule","Close Work Order"].includes(TicketStatus) &&
        <Chip
          size="small"
          avatar={<CalendarMonthIcon sx={{width:'16px!important', color:'#d60404!important'}} />}
          color="warning" 
          label={ <Typography variant="caption" noWrap>E: {dayjs(ProjectState?.Calendar?.endDate)?.format('l')}</Typography> }
          variant="outlined"
          sx={{fontSize:'12px','& .MuiChip-label': {
            display: 'block',
            whiteSpace: 'normal',
            lineHeight: 1.1,
            color:'#d60404!important',
            outlineColor:'#d60404!important'
          }}}
        /> }
      </Box>
      </CardContent>
      <Collapse in={cardFocus}>
      <Typography variant="body2" sx={{lineHeight:1.1,p:'3%'}}>{textNotation('Notes',getColumnNotesField({TicketStatus,ticketNotes})?.[0]?.Notes)}</Typography>
      </Collapse>
    </Box>
    <Box sx={{display:"flex",flexDirection:'column',justifyContent:'flex-start',padding:'1%',gap:'5%'}}>
    <BootstrapTooltip placement="bottom" disableFocusListener disableTouchListener title={'Edit Ticket'}><IconButton sx={{padding:0}} onClick={()=>setGlobalAppState(p=>{ 
    p.set('modulePop', dynamicComponent('editProj','component',{projID:{ProjectID,DivisionID,modelType:divisionMap.get(DivisionID)?.teamName}},parentState)); 
    return new Map(p)
  })}><DesignServicesIcon/></IconButton></BootstrapTooltip>
 <BootstrapTooltip placement="bottom" disableFocusListener disableTouchListener title={'Ticket Files'}><IconButton sx={{padding:0}} onClick={viewFiles}>
  <FileOpenIcon/>
  </IconButton>
  </BootstrapTooltip>
    <BootstrapTooltip placement="bottom" disableFocusListener disableTouchListener title={'View Notes'}><IconButton sx={{padding:0}} onClick={()=> console.log('Clicked')}><SpeakerNotesIcon/></IconButton></BootstrapTooltip>
          {["Close Work Order", "Follow Up", "Need To Complete"].includes(TicketStatus) && stateRef?.[ProjectID+'archive']}
      </Box>
    <BootstrapTooltip placement="right" disableFocusListener disableTouchListener title={TicketNumber.toUpperCase()}>
    <Typography variant="soft" color="primary" size="small"
        sx={{
          px: 0.2,
          cursor:'default',
          writingMode: 'vertical-rl',
          textAlign: 'center',
          fontSize: '12px',
          fontWeight: '900',
          letterSpacing: '1px',
          textTransform: 'uppercase',
          borderLeft: '1px solid',
          borderColor: 'divider',
          padding: '1% .25%'
        }}
      >{TicketNumber}
      </Typography>
      </BootstrapTooltip>
  </Card>
  { isClone && <CloneBadge>Clone</CloneBadge> }
    </Box>
})

const getColumnNotesField=({TicketStatus,ticketNotes})=>{
  var id = TicketStatus;
  return Object({
    Todo: ticketNotes?.TodoNotes,
    'Create Work Order': ticketNotes?.WOChanges,
    Schedule: ticketNotes?.WOChanges,
    'Close Work Order': ticketNotes?.CloseWONotes,
    'Follow Up': ticketNotes?.FollowUpNotes,
    'Need To Complete': ticketNotes?.NeedToCompleteNotes,
    'Re-Schedule': ticketNotes?.RescheduleNotes,
    'Quotes': ticketNotes?.RescheduleNotes
  })[id]
  }
  