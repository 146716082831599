    import React, { useEffect, useRef } from "react";
    import { useAtom } from 'jotai';
    import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
    import { faFileContract } from '@fortawesome/free-solid-svg-icons';
    import useState from 'react-usestateref';
    import CloseIcon from '@mui/icons-material/Close';
    import Draggable from 'react-draggable';
    import SaveIcon from '@mui/icons-material/Save';
    //import { styled } from '@mui/material/styles';
    import { Box, Grid, Card, CardMedia, CardContent, CardActions, Typography, Divider, Fab } from '@mui/material';

    const PopStyle = {
      close: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 'unset',
        height: 'unset',
        aspectRatio: '1/1',
        zIndex: 1000,
        borderRadius: 'unset',
        background: 'var(--primary-color)',
        borderBottomRightRadius: '8px'
      }
      }
  
    export const Component_editDivsn = ({globalState,That,viewData,dynamicComponent,globalWrn,handlePopupClose,useIsMobile,dbUpdateValidate}) =>{
      const {DivisionID,divisionName} = viewData
      const mainState = useState(Object)
      const [, setState, stateRef] = mainState
      const outputState = useState(Object)
      const [, setOutput, outputRef] = outputState
      const [, setPopup] = useAtom(globalState.popupState)
      const [, setPopup2] = useAtom(globalState.popupState2)
      const [UserMap] = useAtom(globalState.userMapState)
      const [, setGlobalAppState] = useAtom(globalState.appState)
      const [divisionMap] = useAtom(globalState.divisionMapState)
      const {DivLoc,description,projectID,teamName,divisionLabel} = divisionMap.get(DivisionID)
      const useAndMobile = useIsMobile(560)
      const mapRef = useRef(null)

      const simp = (t, path) => path.split(".").reduce((r, k) => r?.[k], t)
      const latestState =(x)=> outputRef.current[x] || simp(divisionMap.get(DivisionID),x)

      const pnl =()=> {
        var plnRec = [{id:'LabelValue',loc:'elements',viewData:{state:'inventory',label:'Costs',type:'number',labelEditable:true}}]
        setPopup(p=>({...p,
        open: true,
        title:`Set Your Division Costs.`,
        description: 'Your costs will be amazing.',
        inputField: dynamicComponent('Listable','elements',{state:'division.Costs',label:'Add Cost Item',items:plnRec,defaultValue: outputRef.current['division.Costs'] ? outputRef.current['division.Costs'] : divisionMap.get(DivisionID)?.Costs ? divisionMap.get(DivisionID)?.Costs : null },outputState),
        handleClose: handlePopupClose,
        nameInputSets: {},
      }))}

    useEffect(()=>{ 
        setState(p=>({...p,
          picture: dynamicComponent('avatar','component',{stateChg:['dbUpdate'],state:'avatar',className:'ProfileCircle',avatar: DivisionID, maxWidthOrHeight: 300},mainState),
        }))
    },[])

    useEffect(()=>{
      if(Object.keys(outputRef.current).length>0 && !stateRef.current.updateBttn) setState(p=>({...p,
        updateBttn: <Box sx={{m:'1%'}}>{dynamicComponent('DefaultButton', 'elements',{stateChg:['onClick'],state:'saveButton',label:"UPDATE",type:'save',startIcon:<SaveIcon/>,full:true},mainState,[save])}</Box>
      }))
    },[outputRef.current])

    const save=()=>{
      dbUpdateValidate({model:'Divisions',modelType:divisionMap.get(DivisionID)?.teamName,queryID:true,query:DivisionID,update:outputRef.current},({success,info,err})=>{
        if(success){
          globalWrn('success',`Division item for ${divisionLabel || teamName} was updated.`)
          setGlobalAppState(p=>{ p.delete('modulePop'); return new Map(p) })
       } else globalWrn('error',`We had an issue updating Division ${divisionLabel || teamName}.`)
       })
    }

    const createProj=()=>{
      var userDiv = outputRef.current.DivisionID || UserMap.get('division')
      var modelType = divisionMap.get(userDiv)?.teamName
      setState(p=>({...p,projectReload:undefined}))
      setPopup2(p=>({...p,
        open:true,
        title:`Create Project`,
        zIndex: 2,
        inputField: <Box sx={{width:'60vw',height:'80vh'}}>{dynamicComponent('editProj','component',{projID:{DivisionID:userDiv,modelType:modelType},DivisionLock:userDiv,ref:module},That,[(x)=>{setState(p=>({...p,projectReload:true}));setOutput(p=>({...p,ProjectID:x}));setPopup2(p=>({...p,
          open:false})) }])}</Box>,
        handleClose: ()=>setPopup2({open:false}),
        nameInputSets: {},
    }))  
    }

    useEffect(()=>{
      setState(p=>({...p, 
        geoMap: dynamicComponent('geoMap','component',{loc: DivLoc?.location, ref: mapRef,projID:{ DivisionID, modelType:teamName }},mainState),
        followField: dynamicComponent('AutoComplete','elements',{state:'division.projectFollow',label:'Follow All Projects',defaultValue: latestState('projectFollow'),model:'User',query:{},filter:{local:1}},outputState),
        divisionName: dynamicComponent('InputField','elements',{state:'division.divisionLabel',label:'Division Name/Label', defaultValue: divisionMap.get(DivisionID)?.divisionLabel || divisionMap.get(DivisionID)?.teamName, type:'text', style:{margin:0},multiline:true},outputState),
        divisionDescription: dynamicComponent('InputField','elements',{state:'division.description',label:'Division Notes', defaultValue: divisionMap.get(DivisionID)?.description, type:'text', style:{margin:0},multiline:true},outputState),
        phoneNumber: dynamicComponent('InputField','elements',{state:'division.workPhone',label:'Office Phone#', defaultValue: divisionMap.get(DivisionID)?.workPhone, type:'phone', style:{margin:0},multiline:true},outputState),
        costsBttn: dynamicComponent('DefaultButton','elements',{state:'costs',color:'#f58426',label:'Division Costs',startIcon:<FontAwesomeIcon icon={faFileContract}/>},mainState,[pnl]),
        membersList: latestState('Members') ? dynamicComponent('AvatarBubble','elements',{state:'teams',label:'missing Lead', style:{width:'20px',height:'20px'},userID: [...new Set(latestState('Members').flatMap(x=> x.id))] },mainState) : <Typography variant="caption" >No Members Listed</Typography>,
        projManager: dynamicComponent('UserButton','elements',{state:'division.divsionManager',label:'Division Director',defaultValue: latestState('divsionManager'), divisionName:divisionName},outputState),
        logManager: dynamicComponent('UserButton','elements',{state:'division.logManager',defaultValue: divisionMap.get(DivisionID)?.logManager, label:'Logistics Manager', divisionName:divisionName},outputState),
        warehouseManager: dynamicComponent('UserButton','elements',{state:'division.warehouseManager',label:'Warehouse Manager', divisionName:divisionName},outputState),
        geoSug: dynamicComponent('GeoSuggest','elements',{state:'DivLoc',label: latestState('DivLoc.label') || "Enter Delivery Location",limit:4},mainState,[({DivLoc})=>{ setOutput(p=>({...p, 'division.DivLoc':{...DivLoc} })) }]),
        defProj: dynamicComponent('AutoComplete','elements',{state:'division.projectID',label:'Default Division Stock',defaultValue: projectID?.[0],model:'Projects',query:{'Status':{$in:['Sales','Project']}},filter:{},modelType: teamName,addEntry:createProj,single:true},outputState),
      }))
    },[divisionMap.get(DivisionID)])

    useEffect(()=>{
      setState(p=>({...p, 
        geoMap: dynamicComponent('geoMap','component',{loc: DivLoc?.location, ref: mapRef,projID:{ ProjectID:null, DivisionID, modelType:teamName }},mainState),
      }))
    },[])

      return [ <Box sx={{opacity:.65,position:'absolute',width:'100%',height:'100%'}} key={'someVal'}>
               { stateRef.current.geoMap }
              </Box>,
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'} >
      <Card sx={{ maxWidth: 'clamp(40%,445px,86vw)', m:'auto 3% auto auto',display:'flex',flexDirection:'column' }}>
      <Fab onClick={()=>{ setGlobalAppState(p=>{ p.delete('modulePop'); return new Map(p) }) }} style={PopStyle.close}>
      <CloseIcon/>
    </Fab>
      <CardMedia sx={{maxHeight:'150px',display:'flex'}}>
        { stateRef.current.picture}
      </CardMedia>
      <CardContent id="draggable-dialog-title" sx={{p:'1.25% 2%'}} >
        <Typography gutterBottom variant="h5" sx={{m:'auto',lineHeight:1}}>
          {teamName}
        </Typography>
        <Typography variant="body2" color="text.secondary">
         {description}
        </Typography>
     </CardContent>
     <CardActions sx={{overflow:'auto',flexDirection:'column'}}>
     

      <Box sx={{width:'100%'}}>
      <Divider variant="middle"><Typography gutterBottom variant="caption" component="div">DIVISION SETTINGS</Typography></Divider>
            <Grid container rowSpacing={.5} columnSpacing={{ xs: .5, sm: .5, md: .5 }} >
            <Grid container item xs={12} alignItems="center" justifyContent="center" md={ useAndMobile ? 12 : 6 }>
            { stateRef.current.divisionName }
            </Grid>
            <Grid container item xs={12} alignItems="center" justifyContent="center" md={ useAndMobile ? 12 : 6 }>
            { stateRef.current.divisionDescription }
            </Grid>
            <Grid item xs={12} md={ useAndMobile ? 12 : 6 }>
            { stateRef.current.phoneNumber }
            </Grid>
            <Grid item xs={12} md={ useAndMobile ? 12 : 6 }>
            <Box sx={{display:'flex',height:'90%',m:'1%',justifyContent:'center'}}>{ stateRef.current.costsBttn }</Box>
            </Grid>
            <Grid item xs={12} md={ useAndMobile ? 12 : 6 }>
            { stateRef.current.projManager }
            </Grid>
            <Grid item xs={12} md={ useAndMobile ? 12 : 6 }>
            { stateRef.current.logManager }
            </Grid>
            <Grid item xs={12} md={ useAndMobile ? 12 : 6 }>
            { stateRef.current.warehouseManager }
            </Grid>
            <Grid sx={{padding:'1%'}} item xs={12} md={ useAndMobile ? 12 : 6 }>
              {stateRef.current.defProj}
            </Grid>
            <Grid sx={{padding:'1%'}} item xs={12} md={ useAndMobile ? 12 : 12 }>
            { stateRef.current.geoSug }
            </Grid>
            <Grid item xs={12} md={ useAndMobile ? 12 : 6 }>
            <Box sx={{m:'auto',flex:1,flexDirection:'column'}}>
              <Box sx={{display:'flex',flexDirection:'column',justifyContent:'space-between',flex:1,p:'3px 2% 2%'}}>
                <Typography variant="body2">Project Managers</Typography>
                { stateRef.current.membersList }
              </Box>{stateRef.current.teamAdd}</Box>
            </Grid>
            <Grid sx={{padding:'1%'}} item xs={12} md={ useAndMobile ? 12 : 6 }>
            <Box sx={{m:'auto',flex:1,flexDirection:'column'}}>
              <Box sx={{display:'flex',flexDirection:'column',justifyContent:'space-between',flex:1,p:'3px 2% 2%'}}>
                <Typography variant="body2">Team Members</Typography>
                { stateRef.current.membersList }
              </Box>{stateRef.current.teamAdd}</Box>
            </Grid>
            </Grid>
            </Box>
        <Box sx={{width:'100%'}}>
          <Divider variant="middle"><Typography gutterBottom variant="caption" component="div">YOUR SETTINGS</Typography></Divider>
            <Grid container rowSpacing={.5} columnSpacing={{ xs: .5, sm: .5, md: .5 }} >
            <Grid container item xs={12} alignItems="center" justifyContent="center" md={ useAndMobile ? 12 : 12 }>
            { stateRef.current.followField }
              </Grid>
            <Grid container item xs={12} alignItems="center" justifyContent="center" md={ useAndMobile ? 12 : 12 }>
            </Grid>
          </Grid>
        </Box>
        </CardActions>
    { stateRef.current.updateBttn }
    </Card>
    </Draggable>] 
    }
