import React, { useEffect } from "react";
import { useAtom } from 'jotai';
import useState from 'react-usestateref';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import {Box,Chip,Typography} from '@mui/material';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import WarningIcon from '@mui/icons-material/Warning';

export default function DynamicChip({viewData:{index},action,globalState}) {
  const mainState = useState(Object)
  const [, setState, stateRef] = mainState
  const [appState] = useAtom(globalState.appState)

  const BootstrapTooltip = styled(({ className, useStyles, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className}} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  useEffect(()=>{
    setInterval(e => setState(p=>({...p,...{
      blinker: !p.blinker
    }})), 2000);
  },[])

  useEffect(()=>{
   if(appState.get('stockResp')&&index!==undefined){
    var stockPre = appState.get('stockResp').find(x=> x.index === index)
    var stockResp = stockPre?.items

    if(stockResp && stockResp?.some(x=> x.QTYOnHand > 0)){
      var stockAmmount = stockResp?.filter(({QTYOnHand})=>![undefined,0].includes(QTYOnHand))?.flatMap(({QTYOnHand})=>QTYOnHand)?.reduce((a,b)=>a+b,0)
      var label = <Box sx={{padding:1}}>
        <Typography sx={{lineHeight:1,fontSize:'13px'}}>Found {stockAmmount} unit(s)</Typography>        
        <Typography sx={{lineHeight:1,fontSize:'13px'}}>Click Here to Transfer</Typography>
        </Box>

      setState(p=>({...p,...{
       onDelete: ()=>{
         if(action) action.forEach((x)=> x(stockResp,stockAmmount))},
        label: label
      }}))
    }

    if (!stockResp?.some(x=> x.QTYOnHand > 0) && stateRef.current.label)
    {
      setState(p=>({...p,...{
        onDelete: undefined,
        label: undefined
      }}))
    }
   
   
   }
  },[appState.get('stockResp'),index])


return stateRef.current.label && <Box onClick={stateRef.current.onDelete}><BootstrapTooltip placement="top" disableFocusListener disableTouchListener title="Transfer Items"><Chip variant={stateRef.current.blinker?"filled":"outlined"} color="warning" deleteIcon={<MultipleStopIcon />} label={stateRef.current.label} onDelete={stateRef.current.onDelete} icon={<WarningIcon />} /></BootstrapTooltip></Box>
}

