import React, { useEffect, useRef } from "react";
import { useAtom } from 'jotai';
import useState from 'react-usestateref';
import dayjs from "dayjs";
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import * as isToday from 'dayjs/plugin/isToday';

import SignatureCanvas from 'react-signature-canvas'

import {Box,Typography,Grid} from "@mui/material";
dayjs.extend(localizedFormat);
dayjs.extend(isToday);
dayjs.extend(isSameOrBefore)


const DeliveryForm=({dynamicComponent,dbUpdateValidate,TaskManager,globalState,viewData,globalWrn,CheckID,newNotification,imageCompress,projectBuilder})=> {
   const mainState = useState(Object)
   const outputState = useState(Object)
   const {data,removeItem} = viewData
   const [, setState, stateRef] = mainState
   const [, , outputRef] = outputState
   const [UserMap] = useAtom(globalState.userMapState)
   const [,setAppState] = useAtom(globalState.appState)
   const [projectMap] = useAtom(globalState.projectMapState)
   const [, setInventoryMap] = useAtom(globalState.inventoryMapState)
   const [divisionMap, ] = useAtom(globalState.divisionMapState)
   const [teamMember, setTeamMember] = useAtom(globalState.teamMemberState)
   const [productMap,] = useAtom(globalState.productMapState)
   const [negotiate, setNegotiate] = useAtom(globalState.nonNegotiableState); // Flag that forces re-render
   const [, setUploadTempFiles, uploadTempFilesRef] = useState(Object);
   var sig = useRef({})
   var sig2 = useRef(null)

   const timestamp = dayjs()
   
   var [, setPopup] = useAtom(globalState.popupState)

   useEffect(()=>{
    const siteID = projectMap.get(data?.[0]?.ProjectID)?.SalesState?.SiteID?.[0]
    var siteName
    if(teamMember.has(siteID)) siteName = teamMember.get(siteID)?.address?.label
    else CheckID({_id: siteID, key: "Sites", map: teamMember, hydrate: 'teamMemberState', setMap: setTeamMember},(res)=>{
      siteName = res?.address?.label
    })

    setState(p=>({...p,
      siteName: siteName
    }))
   },[])

   useEffect(()=>{
    if(stateRef.current.tester?.current && Object.keys(uploadTempFilesRef.current)?.length > 0 && outputRef.current?.signedNameField)
      {
        setPopup(p=>({...p,...{
          handleSubmit: submitItems
        }}))
      }
    else setPopup(p=>({...p,...{
          handleSubmit: undefined
        }}))
   },[stateRef.current.tester,uploadTempFilesRef.current, outputRef?.current?.notesField, outputRef.current?.signedNameField])

   useEffect(()=>{
    setState(p=>({...p,...{
        timestamp: timestamp,
        notes: <Box ref={sig2}>{dynamicComponent('InputField','elements',{state:'notesField',label:'Notes',type:'text',style:{margin:0}},outputState)}</Box>,
        nameField: dynamicComponent('InputField','elements',{state:'signedNameField',label: `Have the receiver write their full name:` ,type:'text'},outputState),
        sigTyp: <Box><Typography>I acknowledge that the item was delivered to {stateRef.current?.siteName} on {dayjs().format('lll')}</Typography></Box>,
        sigCanvas: <Box sx={{borderStyle: 'solid', borderColor: 'white', borderWidth: '2px'}}>{sig2.current?.offsetWidth && <SignatureCanvas penColor='white' ref={sig}
        canvasProps={{height: 200, width: `${sig2.current?.offsetWidth}px` , className: 'sigCanvas'}} onEnd={()=>{
        setState(p=>({...p,...{sign:true, tester: sig}}))
        setTimeout(() => {
        setPopup(p=>({...p,...{
          helper: {
            label: `Clear Signature`,
            action: ()=>{
              sig.current.clear()
              setState(p=>({...p,...{sign:undefined}}))
              setPopup(p=>({...p,...{
                helper: undefined,
                handleSubmit: undefined
              }}))
            }
          }
          }}))
        }, "100");
        }} />}</Box>,
    }}))

   },[data, sig2.current?.offsetWidth, outputRef.current, stateRef.current?.siteName, sig.current])

   useEffect(()=>{
    data?.forEach(x=>{
      if(x?.InventoryID.filter(y=>y === x?.InventoryID).length > 0) x?.TransferRequests.forEach(vals=>{if(vals?.InventoryID === x?.InventoryID) setState(p=>({...p, ["QTYDelivered"+[x._id]]: Math.abs(vals?.TransferAmount)}))})
        })
  },[data])

   useEffect(()=>{
    var items = [ {id:'InputField',loc:'elements',viewData:{state:'label',label:'Photo/Video Of',type:'text'}}, {id:'Dropzone',loc:'elements',viewData:{state:'addFile',label:'Photo/Video'}} ]
      setState(p=>({...p,...{
        uploadFile: dynamicComponent('Listable','elements',{state:'addFiles',label:'+ Add',items:items,defaultValue:null,required:false},mainState,[(x)=>{
          if(x.addFiles) x.addFiles.forEach(({label,addFile})=>{ if(label && addFile){
            addFile.forEach((x,i)=> data?.[0]?.InventoryID?.forEach(id=>{setUploadTempFiles(p=>({...p,[data?.[0]?.ProjectID]:{...p[data?.[0]?.ProjectID],[label+'_'+i]:{type:'AddFile', index:i, name:x.name, fileType:x.type, data:x}}})); console.log(uploadTempFilesRef)}))
          }
           })
          
        }])
        
      }}))
   },[outputRef.current.signedNameField,sig, stateRef.current.sign, outputRef.current.notesField])

   const dataURItoBlob = (dataURI)=>{
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString,
        mimeType,
        typedArray,
        i;

    // extract data string and convert to base64 if necessary
    byteString = (dataURI.split(',')[0].indexOf('base64') >= 0) ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);

    // separate out the mime component
    mimeType = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    typedArray = new Uint8Array(byteString.length);

    for (i = 0; i < byteString.length; i++) {
        typedArray[i] = byteString.charCodeAt(i);
    }

    return new Blob([typedArray], {type: mimeType});
}

   // Save Signature
  const saveSignature = (_id, productID,timestamp)=>{
    stateRef.current?.createNewFolder(`./public/uploads/inventory/${_id}/${productID}`,'delivery')
    var saved = sig?.current?.toDataURL("image/png",1)
    if(saved) imageCompress({ image: dataURItoBlob(saved), writePath: `./public/uploads/inventory/${_id}/${productID}/delivery/`, fileName: UserMap.get('avatar')+productID+timestamp+'.png' },({sucess,err})=>{
      if(sucess===true){ console.log("signature was saved:", sucess); globalWrn('success',`Your Signature Was Submitted.`)}
      else {console.log("Error saving signature:", err); globalWrn('error',`There was an error updating your signature.`)}
    })
    }

      // File Upload functions
      const tempfileUploader =(date,ProjectID)=>{
        stateRef.current.createNewFolder(`/EKC/Projects/${ProjectID}`,'Deliveries')
        stateRef.current.createNewFolder(`/EKC/Projects/${ProjectID}/Deliveries`, date )
      
        var path = `./EKC/Projects/${ProjectID}/Deliveries/${date}`
        if(uploadTempFilesRef.current?.[ProjectID]) {
          Promise.all(Object.entries(uploadTempFilesRef.current?.[ProjectID]).flatMap(([k,{data,name,index}])=>{
            imageCompress({image:data,writePath:path,fileName:name.replace(".",`( ${k+'_'+index} ).`)},({sucess,err})=>{
              console.log('Tempupload:: ', {image:data,writePath:path,fileName:name.replace(".",`( ${k+'_'+index} ).`)},{sucess,err} )
              if(sucess===true){
                globalWrn('success',`Your File was Uploaded.`)
              }
              else globalWrn('error',`There was an error uploading your file(s).`)
            })
        })).then(()=>{
          setUploadTempFiles({})
          setPopup({open: false})
        })
      }
      }


   const submitItems=()=>{
    //for each item to be delivered, submit to the corresponding projects and inventory items
    data.forEach(x => {
      var numEmailer = 0
      x.InventoryID.forEach((id,i)=>{
      const submitObj = {
        timestamp: stateRef.current.timestamp,
        notes: outputRef.current.notesField,
        name: outputRef.current.signedNameField,
        InventoryID: id
      }

      var projIDtemp = { DivisionID: x?.DivisionID,modelType: divisionMap.has(x?.DivisionID) ? divisionMap.get(x?.DivisionID).teamName : divisionMap.get(UserMap.get('division')).teamName }
      if(x?.ProjectID) projIDtemp.ProjectID = x?.ProjectID

      //project submit
      projectBuilder({projID:projIDtemp,submitObj:{$set:{}, $push:{Deliveries:submitObj}}},(resp)=>{ 
        var {success} = resp    
        if(success===true){ 
            //inventory submit for each delivery
            var itemsArr = [];

            return new Promise((resolve, reject) => { x.TransferRequests.forEach(req => {
              // Determine if active delivery
              if(!req?.deliveryDate && dayjs()?.isBefore(req?.deliveryDate)) {/*Do Nothing*/}
              else{
             var arrayFilters = [{"el.timestamp": req.timestamp}]
             // Array for email to project manager
             itemsArr.push({name:productMap.get(req?.ProductID)?.DISPLAY_NAME, value: Math.abs(req?.TransferAmount)})

             var output = {'$set': { 'TransferRequests.$[el].Completed':true, 'TransferRequests.$[el].Notes': (req?.Notes ? (req?.Notes + " + ") : "") + outputRef?.current?.notesField, 'TransferRequests.$[el].ReceiverName': outputRef?.current?.signedNameField }}
             dbUpdateValidate({model:'Inventory',queryID:true,query:id,update:output,arrayFilters:arrayFilters},(response)=>{ 
              if(response?.success){ 
                setInventoryMap({t:'set',k:x?.info?._id,v:x?.info})
                setTimeout(()=>setNegotiate(negotiate ? {reInit: !negotiate.reInit} : {reInit: true}),1000)
                removeItem(x)
                }else {console.log("DELIVERY NOT SAVED!!!", x?.err); reject(x?.err);}
              })
            }
            })
            x.InventoryID?.forEach((y,i)=>{saveSignature(y,y,dayjs()); tempfileUploader(dayjs(), x?.ProjectID)});

            resolve(itemsArr);
          }).then((itemsArr)=>{

              setAppState(p=>{ 
              p.delete('modulePop')
              return new Map(p)
              })

              // Delivery Email Functions -----------------------------------------------------------
              if(numEmailer === 0) {
                numEmailer = 1
                const prjManID = projectMap.get(data?.[0]?.ProjectID)?.ProjectState?.projManager
                var prjManEmail
                var prjManName
        
                if(teamMember.has(prjManID)) {
                  prjManEmail = teamMember.get(prjManID)?.email
                  prjManName = `${teamMember.get(prjManID)?.firstName} ${teamMember.get(prjManID)?.lastName}`
                }
                else CheckID({_id: prjManID, key: "User", map: teamMember, hydrate: 'teamMemberState', setMap: setTeamMember},(res)=>{
                  prjManEmail = res?.email
                  prjManName = `${res?.firstName} ${res?.lastName}`
                })
        
                var bodyField = itemsArr.flatMap(y=>{return `<li>${y.name}: <b>${y.value}</b></li>`}).join('')
                const body = `${UserMap.get('firstName')} ${UserMap.get('lastName')} finished a delivery. Go to your project to review it now.\n\nNotes Provided: ${outputRef?.current?.notesField || 'No notes added.'}\n\nItem(s) Delivered:\n<ul>${bodyField}</ul>`
                var name_u = prjManName
                newNotification({Title:`A delivery to ${projectMap.get(data?.[0]?.ProjectID)?.ProjectTitle} was delivered`,Body:body,Data:null,avatar:prjManID,Icon:null,Choice:{AppNotification:true,Text:false,Email:true},Email:prjManEmail,EmailForm:"Warning",name:name_u,sender:UserMap.get('avatar'),receiver:prjManID})
              }
              // End Of Delivery Email Functions ----------------------------------------------------
            })

            }else {
              globalWrn('error',`There was an error updating project ${projectMap.get(x?.ProjectID)?.ProjectTitle}.`)
            }
          })
      })
    })
      }

   return [<TaskManager That={mainState}/>,
   <Box sx={{padding: '2%'}}><Grid>
    <Box sx={{padding: '1%'}}>
    {stateRef.current.nameField}
    </Box>
    <Box sx={{padding: '1%'}}>
    {stateRef.current.notes}
    </Box>
    <Box sx={{padding: '1%'}}>
    {stateRef.current.sigTyp}
    </Box>
    <Box sx={{padding: '1%'}}>
    {stateRef.current.sigCanvas}
    </Box>
    <Box sx={{padding: '1%'}}>
    {stateRef.current.uploadFile}
    </Box>
    </Grid></Box>]
}
export default DeliveryForm;
