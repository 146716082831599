   /* eslint-disable no-unused-expressions */
    import React, { useEffect, useRef } from "react";
    import { useAtom } from 'jotai';
    import useState from 'react-usestateref';
    import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
    import { faTrophy,faDumpsterFire,faArchive,faDatabase,faUserClock} from '@fortawesome/free-solid-svg-icons'
    import dayjs from 'dayjs';
    import * as localizedFormat from 'dayjs/plugin/localizedFormat';

    import HelpCenterIcon from '@mui/icons-material/HelpCenter';
    import LocalShippingIcon from '@mui/icons-material/LocalShipping';
    import { styled } from '@mui/material/styles';
    import { keyframes } from '@mui/system';
    import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';
    import LowPriorityIcon from '@mui/icons-material/LowPriority';
    import EditIcon from '@mui/icons-material/Edit';
    import VisibilityIcon from '@mui/icons-material/Visibility';
    import SpeedIcon from '@mui/icons-material/Speed';
    import FactCheckIcon from '@mui/icons-material/FactCheck';
    import BackupTableIcon from '@mui/icons-material/BackupTable';
    import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
    import FolderCopyIcon from '@mui/icons-material/FolderCopy';
    import TaskAltIcon from '@mui/icons-material/TaskAlt';
    import { Box,Divider,AppBar,Toolbar,Container,Avatar,CardContent,CardMedia,CardHeader,Card,IconButton,Paper,Fab,Typography,Grow,Grid,ListItem,ListItemAvatar,ListItemText } from '@mui/material';

var advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

const pulse = keyframes`
from {
  transform: scale(.8);
  opacity: 1;
}
to {
  transform: scale(1.4);
  opacity: 0;
}
`;


const StyledFab = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  top: -48,
  left: 0,
  right: 0,
  boxShadow: 'unset',
  border: '10px solid #1e1e1e',
  width: '80px',
  height: '80px',
  margin: '0 auto',
});

const Item = styled(Paper)(({ theme })=>({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: 0,
  textAlign: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  height: '100%',
  color: theme.palette.text.secondary,
}));

export function Component_panelBuild(props) {
 const { That, globalState, viewData: {projID, index}, dynamicComponent,dynamicMapData,congrats,handlePopupClose,globalWrn,waveLoad,projectBuilder,updateFulfillment,queryDB } = props;
 const { ProjectID, DivisionID } = projID
 const mainstate = useState(Object)
 const [, setState, {current:stateRef}] = mainstate
 const [projectLoggMap, setProjectLoggMap] = useAtom(globalState.projectLoggMapState)
 const [appState, setAppState] = useAtom(globalState.appState)
 const [divisionMap] = useAtom(globalState.divisionMapState)
 const [projectMap] = useAtom(globalState.projectMapState)
 const [teamMember] = useAtom(globalState.teamMemberState)
 const [UserMap] = useAtom(globalState.userMapState)
 const [, setPopup] = useAtom(globalState.popupState)
 const [, setPopup2] = useAtom(globalState.popupState2)
 const { scrollStatHorz, onScrollHandler } = globalState.useScroll()
 
 const ProjectData = projectMap.get(ProjectID)
 const DivisionData = divisionMap.get(DivisionID)
 var paused = ProjectData?.ProjectState?.Paused?.some(x=> !x.endDate && x.startDate) || false
 const module = useRef(null)
 const wonRef = useRef(null)
 const wonRef2 = useRef(null)

 useEffect(()=>{
  return () => { // Cleanup function to clear data
    setState({});
  };
 },[])

const updateTeam = (data)=> {
projectBuilder({projID:projID,submitObj:data})
}

const simp = (t, path) => path.split(".").reduce((r, k) => r?.[k], t)
function latestState(x){
  return simp(ProjectData,x)
}

const stylized = {
  listContainer: {
    pl:'0px!important',
    pr:'0!important',
    maxWidth:'unset!important',
    borderRadius:'4px',
    ...scrollStatHorz.style,
  }
}

  useEffect(()=>{
    if(UserMap.get('pageStamp').active==='EOS' && UserMap.get('level')<=6 && teamMember.size>0 ) queryDB({model:'AdminOrg',query:{'fieldSet':[`${DivisionData?.category}Services`,`${DivisionData?.category}Production`]},filter:{'_id':0,'fieldSet':0,'Number':0}},([items,addOn],err)=>{
      var chapters = Array.from([...divisionMap],([k,v])=>v?.teamName)
      var list = Object.assign(...chapters.flatMap(x=>({[x]:[].concat(...Array.from([...teamMember],([k,v])=>[{[k]:v}].filter(()=>divisionMap.get(v.division)?.teamName === x).map(()=>v.avatar)))})))
            
              setState(p=>({...p,
              teamEditor: <Box>{ dynamicComponent('UserSelect','elements',{state:'ProjectState.Members',label:'+Team',projID:projID,divisionName:DivisionData?.teamName,defaultValue:latestState('ProjectState.Members'), projTitle:ProjectData?.ProjectTitle, chapters:chapters, list:list },That,[updateTeam]) }</Box>
             }))
            
      }) 
    else if(UserMap.get('pageStamp').active==='EOS' && UserMap.get('level')<=6 && !stateRef.teamEditor ) setState(p=>({...p,
      teamEditor: <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',gap:'15%'}}>
        <Typography variant="caption" sx={{lineHeight:1,textShadow:'2px 2px rgba(0,0,0,.45)'}} noWrap>Gathering Teams</Typography>
        {waveLoad()}
        </Box>
    }))
  },[UserMap.get('pageStamp').active==='EOS', UserMap.get('level')<=6, teamMember.size>0])

  useEffect(()=>{
    if(stateRef.registered===ProjectID) return
    var userLvl = UserMap.get('level')
    if(userLvl<8) setState(p=>({...p,
      editorBttn: <Box sx={{pointerEvents:'none'}}><Fab sx={{maxHeight:'56px',minWidth:'56px',bgcolor:'#ff7551',zIndex:'unset',pointerEvents:'auto'}} aria-label="Edit" onClick={()=> 
        setAppState(p => { p.set('modulePop', dynamicComponent(UserMap.get('pageStamp').active==='EOS'?'fillProj':'editProj','component',{projID:projID},That)); return new Map(p)}) 
      }> 
      { UserMap.get('pageStamp').active==='EOS' ? <SpeedIcon/> : <EditIcon/> }
    </Fab></Box>
     }))

    setState(p=>({...p,
      registered: ProjectID,
      editorFab : 
      <StyledFab aria-label="Edit" sx={paused?{border:'10px solid #858583'}:null} color={[9,10].includes(userLvl) ? 'info' : UserMap.get('pageStamp').active==='EOS' ? 'warning' : 'secondary' }onClick={()=>
        setAppState(p => { p.set('modulePop', [9,10].includes(userLvl) ? dynamicComponent('PartnerPanel','elements',{state:'AnaliticData',projID,projGatherData:projGatherData},That) : dynamicComponent(UserMap.get('pageStamp').active==='EOS'?'fillProj':'editProj','component',{projID:projID},That) ); return new Map(p)}) 
        }> 
      {[9,10].includes(userLvl) ? <VisibilityIcon/> : UserMap.get('pageStamp').active==='EOS' ? <SpeedIcon/> : <EditIcon/> }
    </StyledFab>
    }))

    if(userLvl<=5) setState(p=>({...p,
      dataBttn: dynamicComponent('DefaultButton','elements',{state:'Database',label:'Database',type:'button',projID:projID,startIcon:<FontAwesomeIcon icon={faDatabase}/>,color:'#6c5ecf',full:true},That,[projGatherData])
    }))

    if(ProjectData?.ProjectState?.projManager) var projMan = teamMember.get(ProjectData.ProjectState.projManager)?.firstName+' '+teamMember.get(ProjectData.ProjectState.projManager)?.lastName
    if(ProjectData?.ProjectState?.teamLead) var teamLead = teamMember.get(ProjectData.ProjectState.teamLead)?.firstName+' '+teamMember.get(ProjectData.ProjectState.teamLead)?.lastName

    var list = [
      projMan && {primary:projMan,secondary:'Project Manager', icon: dynamicComponent('AvatarBubble','elements',{state:'projLead',label:'missing Lead',style:{width:'25px',height:'25px'},userID:ProjectData.ProjectState.projManager},That) },
      teamLead && {primary:teamLead, secondary:'Project Lead',icon: dynamicComponent('AvatarBubble','elements',{state:'projLead',label:'missing Lead',style:{width:'25px',height:'25px'},userID:ProjectData.ProjectState?.teamLead},That) },
      {divider:true,dividerText:'MENU'},
      {primary:'View EOD',icon:<FontAwesomeIcon icon={faUserClock}/>},{primary:'Database',icon:<BackupTableIcon/>},{primary:'Project Files',icon:<FolderCopyIcon/>},{primary:'Project Details',icon:<HelpCenterIcon/>}] 

    if([4,5].includes(userLvl)) list.push({primary:'Edit Project',icon:<EditIcon/>})
    if([0,1,2,3].includes(userLvl)) list.push(...[{divider:true},{primary:ProjectData?.ProjectState?.Status === 'Complete' ? 'Return to Production' : 'Mark As Complete',icon: ProjectData?.ProjectState?.Status === 'Complete' ? <LowPriorityIcon/> : <TaskAltIcon/>}])
    if(userLvl<=7) setState(p=>({...p,
      sideBarMenu: [<Divider orientation="vertical" variant="middle" flexItem key={"_1"+UserMap.get('avatar')}/>,
          dynamicComponent('DropDown','elements',{state:'options', list:list, replace:true },That,[(x,y)=>{
            var obj ={
             'Edit Project':()=> projEditor(x,y),
              Database:()=>projGatherData(x,y),
              Analytics:()=>analitics(x,projID),
              'View EOD': ()=>viewEOD(),
              'Project Files': viewFiles,
              'Return to Production': ()=> projectBuilder({projID:projID,submitObj:{'SalesState.Status':'Awarded','ProjectState.Status':'Active',Status:'Project',TicketStatus: ProjectData?.TicketNumber && 'Todo'}},(x)=>{globalWrn('info',`Project: ${ProjectData.ProjectTitle} has been marked "Active".`)} ),
              'Mark As Complete': ()=>{ projectBuilder({projID:projID,submitObj:{'SalesState.Status':'Complete','ProjectState.Status':'Complete',Status:'Completed',TicketStatus: ProjectData?.TicketNumber && 'Completed'}},(x)=>{setState(p=>({...p,panelBuild:null,analitics:null}))} ) },
              'Project Details': openProjStats
            }
            obj[x.options]()
          }])]
        }))

  },[UserMap.get('level'),projectMap.get(ProjectID)])

  useEffect(()=>{
    if(ProjectData?.ProjectState?.Members) setState(p=>({...p,
      projMembers: dynamicComponent('AvatarBubble','elements',{state:'projMembers',label:'missing Members',maxAvatar:4,style:{width:'30px',height:'30px'},userID:[...new Set(ProjectData.ProjectState?.Members.flatMap(x=> x.id))]},That) 
    }))
  },[ProjectData?.ProjectState?.Members])

  const closePopupItem=()=>{
    setPopup({open:false})
  }

  const viewEOD=()=>{
    setPopup(p=>({...p,
      open:true,
      zIndex: 2,
      title:`Project's End of Day Reports`,
      description: 'Your EODs will be amazing.',
      inputField: dynamicComponent('EODReport','elements',{state:"EODReport", projID},That),
      handleClose: closePopupItem,
      nameInputSets: {},
  }))
  }

  const viewFiles=()=>{
    if(!ProjectID) return globalWrn('error',`You will need to create the project ${ProjectData?.ProjectTitle || ''} first.`)
    setPopup2(p=>({...p,
      open:true,
      title:`${ProjectData.ProjectTitle} Project Files`,
      inputField: dynamicComponent('repository','component',{folderID: ProjectData?.Repository?.[`${process.env.REACT_APP_ENVIROMENT==="dev"?"dev-":""}folderID`],folderName:'/EKC/'+UserMap.get('pageStamp').active,projID},That), //folder_1936829
      handleClose: ()=>setPopup2({open:false}),
      cancelLabel: 'Close',
      nameInputSets: {},
  }))
  }

const projGatherData = () =>{
  function popupData(){
    setPopup(p=>({...p,
      open:true,
      title:`${ProjectData.ProjectTitle} DataBase`,
      fullWidth: true,
      inputField: dynamicComponent('DatabaseTable','elements',{projID},mainstate),
      handleClose: ()=>setPopup({open:false}),
      nameInputSets: {},
  }))
};

if(ProjectID && !Array.from([...projectLoggMap],([,v])=> v.some(()=>v.ProjectID===ProjectID)).some(x=>x)){
  //Warning here, modelType used before its defined
  if(!modelType){
    var {DivisionID} = projectMap.get(ProjectID)
    var{teamName:modelType} = divisionMap.get(DivisionID)
  }

  queryDB({model:'Projects',modelType,idConvert:true,query:{"_id":ProjectID},filter:{'ProjectState.teamLogg':1}},([{ProjectState}],y)=>{
    console.log('Response', ProjectState )
  if(ProjectState?.teamLogg) Promise.all(
   ProjectState?.teamLogg?.flatMap(({_id,logg})=>{
     logg.ProjectID = ProjectID
     return dynamicMapData({[_id]:logg},projectLoggMap,setProjectLoggMap,(x)=>{ setProjectLoggMap(p=>new Map(p))}) 
   })
   ).then( popupData )
   else globalWrn('error',`This Project Database is empty.`)
 })
}else popupData()

}

const handleStatus=(x,data)=>{
var submitObj = {'SalesState.Status': x}
if(x === "Awarded"){ 
  submitObj['Status'] = 'Project'
  submitObj['ProjectState.Status'] = 'Upcoming'
    }
  updateTeam(submitObj)
  }

const analitics=(x,y)=>{
  setAppState(p => { p.set('modulePop', dynamicComponent('PartnerPanel','elements',{state:'AnaliticData',projID,projGatherData:projGatherData},That) ); return new Map(p)}) 
}

function inRange(x, min, max) {
  return ((x-min)*(x-max) <= 0);
}

const range = [
  { min: -500, max: -51,type: 'Early',color:'#78bbc1', gradient: 'linear-gradient(90deg, rgba(120,187,193,0) 15%, rgba(120,187,193,.6) 100%)' },
  { min: -50, max: -2, type: 'InGood',color:'#78c1a3', gradient: 'linear-gradient(90deg, rgba(120,193,163,0) 15%, rgba(120,193,163,.6) 100%)' },
  { min: -1, max: 0, type: 'Normal',color:'#c1cbb1', gradient: 'linear-gradient(90deg, rgba(193,203,177,0) 15%, rgba(193,203,177,.6) 100%)'},
  { min: 1, max: 2, type: 'Close',color:'#ffdbc2', gradient: 'linear-gradient(90deg, rgba(255,219,194,0) 15%, rgba(255,219,194,.6) 100%)'},
  { min: 3, max: 10, type: 'Alert',color:'#f2b4a3', gradient: 'linear-gradient(90deg, rgba(242,180,163,0) 15%, rgba(242,180,163,.6) 100%)'},
  { min: 11, max: 50, type: 'ReallyBad',color:'#f38989', gradient: 'linear-gradient(90deg, rgba(243,137,137,0) 15%, rgba(243,137,137,.6) 100%)'},
  { min: 51, max: 500, type: 'Horrible',color:'#ee5b5b', gradient: 'linear-gradient(90deg, rgba(238,91,91,15%) 0, rgba(238,91,91,.6) 100%)'}
]
var dueDate = (divisionMap.get(ProjectData?.DivisionID)?.category === "Construction" ? ProjectData?.SalesState?.rfpDue?.calendar && ProjectData.SalesState.rfpDue.calendar : ProjectData?.SalesState?.projectDue && ProjectData.SalesState?.projectDue) || (ProjectData?.ProjectState?.Calendar?.endDate && ProjectData.ProjectState.Calendar.endDate)

const date1 = dayjs()
var newItem = date1.diff(dueDate, 'day')
var rangeSet = range.find(({min,max,type})=> inRange(newItem,min,max))
if (rangeSet) var {color ,gradient} = rangeSet

const IconBuilder=()=>{
  var colorPulse = {
    position: 'absolute',
    top: '-10%',
    left: '-10%',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    animation: `${pulse} 1.2s infinite ease-in-out`,
    animationDelay: `${index*25}ms`,
    border: `5px solid ${color}`,
    content: '""',
  }

  return <Avatar sx={{ width: "52px", height:'52px',ml:'1.35%', bgcolor: color?color:'#bcbcbc',overflow:'unset', fontSize: '10px', textShadow: 'none', textAlign:"center",'&::after': (newItem>0&&ProjectData.Status!=='Completed')?colorPulse:null }} aria-label="recipe">
    <Typography variant="caption" sx={{zIndex:1,lineHeight:.9,fontWeight:500,textTransform: 'capitalize',textShadow:'1px 1px rgba(255,255,255,.45)'}}>
   {dueDate ? dayjs(dueDate).fromNow() : 'Not Set' }
    </Typography>
</Avatar> 
}

const projEditor =()=>{
  setAppState(p => { p.set('modulePop', dynamicComponent(UserMap.get('pageStamp').active==='EOS'?'fillProj':'editProj','component',{projID:projID},That)); return new Map(p)}) 
}

const getMostRecentEOD=()=>{
  var mostRecent
  if(ProjectData?.ProjectState?.EODLogg) ProjectData?.ProjectState?.EODLogg?.filter(x=>dayjs(x.date).isToday()).length > 0 &&

              ProjectData?.ProjectState?.EODLogg.flatMap(x=>{
                if(dayjs(x.date).isToday())
                {
                  mostRecent = x
                  x?.revisions?.length > 0 && x?.revisions?.flatMap(val=>{
                    mostRecent = val?.date > x?.date ? val : x
                  })
                }
              })
                  return mostRecent
}

const openProjStats=()=>{
  var today = getMostRecentEOD()
  dayjs.extend(localizedFormat);
  setState(p=>({...p,
    projectDetails: <Grid>
      <Box sx={{backgroundColor: '#373944',}}>
      <Divider>Details</Divider>
      <Item>
            {
            ProjectData?.ProjectDesc &&  
            <Box sx={{width:'100%', padding:'1%'}}>
              <b>Description:</b> {ProjectData?.ProjectDesc}
            </Box>
            }
            </Item>
            <Divider>Project Information</Divider>
            <Item>
            {
            ProjectData?.PoNumber &&
            <Box sx={{width:'48%', padding:'1%'}}>
              <b>PO Number:</b> {ProjectData?.PoNumber}
            </Box>
            }
            {
          ProjectData?.JobNumber &&
        <Box sx={{width:'48%', padding:'1%'}}>
              <b>Job Number:</b> {ProjectData?.JobNumber}
            </Box>
            }

            {
            ProjectData?.WorkOrder &&
            <Box sx={{width:'48%', padding:'1%'}}>
              <b>Work Order:</b> {ProjectData?.WorkOrder}
            </Box>
            }
            </Item>
            <Divider>EOD Today</Divider>
              { 
              today ? 
               <Item>{ today?.technotes &&
               <Box sx={{padding:'1%'}}><b>Tech Notes:</b> {today?.techNotes}
               </Box>
                }
               <Box sx={{padding: '1%'}}>
                 <b>Notes:</b> {today?.notes}
               </Box>
               </Item>
               :
              <Item><Box sx={{padding:'1%'}}>EOD Not Yet Completed Today!</Box></Item>
              }  
            <Divider>Team Leaders</Divider>
            <Item>
              {
                ProjectData?.ProjectState?.projManager &&
                <Box sx={{width:'48%', padding:'1%'}}>
                  <Box sx={{padding: '1%'}}>
                  {dynamicComponent('AvatarBubble','elements',{state:'LeadUser',label:'Picture',userID:ProjectData?.ProjectState?.projManager,style:{width:80,height:80}},That)}
                  </Box>
                  <b>Project Manager:</b> {teamMember.get(ProjectData?.ProjectState?.projManager)?.firstName} {teamMember.get(ProjectData?.ProjectState?.projManager)?.lastName}
                </Box>
              }
              {
                ProjectData?.ProjectState?.teamLead &&
                <Box sx={{width:'48%', padding:'1%'}}>
                  <Box sx={{padding:'1%'}}>
                  {dynamicComponent('AvatarBubble','elements',{state:'LeadUser',label:'Picture',userID:ProjectData?.ProjectState?.teamLead,style:{width:80,height:80}},That)}
                  </Box>
                  <b>Team Lead:</b> {teamMember.get(ProjectData?.ProjectState?.teamLead)?.lastName} {teamMember.get(ProjectData?.ProjectState?.teamLead)?.lastName}
                  </Box>
              }
            </Item>
            <Divider>Team Members</Divider>
            <Item>
              { ProjectData?.ProjectState?.Members?.flatMap((x,i)=>{
               return <Box key={"boxmember"+ i} sx={{padding:'1%'}}>
                <Box sx={{padding:'1%'}}>
                {dynamicComponent('AvatarBubble','elements',{state:'LeadUser',label:'Picture',userID:x.id,style:{width:80,height:80}},That)}
                </Box>
                {`${teamMember.get(x?.id)?.firstName} ${teamMember.get(x?.id)?.lastName}`}
              </Box>
  })}
            </Item>
            </Box>
          </Grid>
  }))  

  if(ProjectData?.SalesState?.SiteID?.length>0) var site = ProjectData?.SalesState?.SiteID?.flatMap(x=> teamMember.get(x))[0]

    setPopup(p=>({...p,
      open:true,
      title:`${ProjectData?.ProjectTitle}`,
      description: `Project Details`,
      inputField: stateRef.projectDetails,
      handleClose: handlePopupClose,
      cancelLabel: 'Close',
      helper: site && {
        label: <ListItem alignItems="flex-start" sx={{padding:0}}>
        <ListItemAvatar sx={{minWidth:'40px'}}>
          <AssistantDirectionIcon sx={{fontSize:'28px'}} />
        </ListItemAvatar>
        <ListItemText sx={{display:'flex',flexDirection:'column'}}
          primary={<Typography variant="subtitle">{site.firstName}</Typography>}
          secondary={<Typography variant="caption">{site.department}</Typography>}
        />
      </ListItem>,
        action: ()=> window.open(`https://www.google.com/maps/search/?api=1&query=${site.address.location.lat},${site.address.location.lng}`, '_blank', 'noreferrer')
      }
  }))  
}

useEffect(() =>{ 

if(UserMap.get('pageStamp').active==='Sales'){

if(ProjectData?.SalesState?.Status === "Awarded") setState(p=>({...p,projBody: dynamicComponent('DefaultButton','elements',{state:'Awarded',label:'WON',type:'button',startIcon:<FontAwesomeIcon ref={wonRef2} icon={faTrophy}/>,full:true,colorStyle:'success',variant:"outlined"},That,[()=>congrats(wonRef2.current)]) // ,color:'#1ecd97'
}))

else if(ProjectData?.SalesState?.Status === "Lost") setState(p=>({...p,projBody: dynamicComponent('DefaultButton','elements',{state:'Lost',label:'LOST',type:'button',startIcon:<FontAwesomeIcon icon={faDumpsterFire}/>,colorStyle:'error',full:true,variant:"outlined"},That) // ,color:'#ec5252'
}))

else if(ProjectData?.SalesState?.Status === "Archive") setState(p=>({...p,projBody : dynamicComponent('DefaultButton','elements',{state:'Archive',label:'Un-Archive',type:'button',startIcon:<FontAwesomeIcon icon={faArchive}/>,colorStyle:'warning',full:true,variant:"outlined"},That,[()=>handleStatus('Awardable')]) // color:'#ff9d15'
 }))

else if(ProjectData?.SalesState?.Status === "Awardable") setState(p=>({...p,projBody : [
  dynamicComponent('DefaultButton','elements',{state:'Awarded',label:'WON',type:'button',startIcon:<FontAwesomeIcon ref={wonRef} icon={faTrophy}/>,color:'#1ecd97',full:true},That,[()=>{
    congrats(wonRef.current);
    if(ProjectData.Fulfillment?.fulfillmentID){
      var fulfillmentID = ProjectData.Fulfillment?.fulfillmentID
      var {Inventory:FulfillInventory,labelFulfillment} = DivisionData?.FulfillmentInventory?.find(({_id})=>_id===fulfillmentID)
      ProjectData.Fulfillment.Inventory.forEach(({inventory})=>{ 
        var oldItem = FulfillInventory.find(({inventory:inv2})=>inv2.label===inventory.label)?.inventory?.value
        if(oldItem) FulfillInventory.find(({inventory:inv2})=>inv2.label===inventory.label).inventory.value = oldItem-inventory.value
      })
      updateFulfillment({data:{Inventory:FulfillInventory},projID:{DivisionID:DivisionID,fulfillmentID:fulfillmentID}},({sucess,info,err})=>{
        if(sucess) globalWrn('success',`${labelFulfillment} was updated.`)
        else globalWrn('error',`We had an issue creating ${labelFulfillment}.`)
      }) 
    }
    handleStatus('Awarded')
  }]),
  dynamicComponent('DefaultButton','elements',{state:'Lost',label:'LOST',type:'button',startIcon:<FontAwesomeIcon icon={faDumpsterFire}/>,color:'#ec5252',full:true},That,[()=>handleStatus('Lost')]),
  dynamicComponent('DefaultButton','elements',{state:'Archive',label:'FILE',type:'button',startIcon:<FontAwesomeIcon icon={faArchive}/>,color:'#ff9d15',full:true},That,[()=>handleStatus('Archive')]),
] }))
else if(ProjectData?.SalesState?.Status === "Estimation")  setState(p=>({...p,projBody : undefined }))
}

},[ UserMap.get('pageStamp')[UserMap.get('pageStamp').active],ProjectData?.SalesState?.Status ])

const listBuild=()=> <AppBar position="static">
  <Container sx={{...stylized.listContainer,background:gradient}}>
    <Toolbar key={"1"} disableGutters onScroll={onScrollHandler} sx={{justifyContent:'space-between',overflow:'auto',overflowY:'hidden', gap:'14px'}} id={ProjectID} onClick={({target:{id}})=>{ if(ProjectID===id) openProjStats() }} >
      { IconBuilder() }
      { paused && <Box>{dynamicComponent('DefaultButton','elements',{state:'deliveryOpt',color:'#c0392b',label:'PAUSED',startIcon:<PauseCircleOutlineIcon/>,disabled:true},That)}</Box> }
      <Box sx={{pointerEvents:'none'}}>
        <Typography variant="body1" sx={{lineHeight:1,textShadow:'2px 2px rgba(0,0,0,.45)'}} noWrap>
          { ProjectData.ProjectTitle }
        </Typography>
        <Typography variant="caption" noWrap sx={{textShadow:'2px 2px rgba(0,0,0,.45)'}}>
        { ProjectData[`${ProjectData.Status}State`]?.Status ? ProjectData[`${ProjectData.Status}State`]?.Status : ProjectData.Status }
        </Typography>
      </Box>
      <Divider orientation="vertical" variant="middle" flexItem />
      <Box sx={{pointerEvents:'none'}}>
        <Typography variant="body1" sx={{lineHeight:1,textShadow:'2px 2px rgba(0,0,0,.45)'}} noWrap>
          { ProjectData.PoNumber ? DivisionData?.teamName.includes('A/V') ? `Job # ${ ProjectData.PoNumber }`: `Order # ${ ProjectData.PoNumber }` : 'JOB# N/A' }
        </Typography>
        <Typography variant="caption" noWrap sx={{textShadow:'2px 2px rgba(0,0,0,.45)'}}>
          { divisionMap.get(ProjectData?.DivisionID)?.category === "Construction" 
          ? ProjectData?.SalesState?.rfpDue?.calendar 
          ? `Due: ${dayjs(ProjectData.SalesState.rfpDue.calendar).format("MMM Do 'YY")}` 
          : 'Due date N/A.' : ProjectData?.SalesState?.projectDue 
          ? `Due: ${dayjs(ProjectData.SalesState?.projectDue).format("MMM Do 'YY")}` 
          : 'Due date N/A.' 
          }
        </Typography>
      </Box>
      <Divider orientation="vertical" variant="middle" flexItem />
      <Box sx={{pointerEvents:'none'}}>
        <Typography variant="caption" sx={{lineHeight:1,textShadow:'2px 2px rgba(0,0,0,.45)'}} noWrap>
          Starts: { ProjectData.ProjectState?.Calendar?.startDate ? dayjs(ProjectData.ProjectState.Calendar.startDate).format("MMM Do 'YY") : 'Start date N/A' }
        </Typography><br/>
        <Typography variant="caption" noWrap sx={{textShadow:'2px 2px rgba(0,0,0,.45)'}}>
          Ends: { ProjectData.ProjectState?.Calendar?.endDate  ? dayjs(ProjectData.ProjectState.Calendar.endDate).format("MMM Do 'YY") : 'End date N/A' }
        </Typography>
      </Box>
      <Divider orientation="vertical" variant="middle" flexItem />
      <CardContent sx={{padding:'5px!important',justifyContent:'center',columnGap:'6%',display:'flex','& button':{margin:'0 1%'}}}>
      { stateRef.teamEditor }
      { stateRef.editorBttn }
      { stateRef.clientBttn }
    <Box sx={{display:'flex',margin:'auto'}}>
    { stateRef.projBody }
    </Box>
    </CardContent>
      { stateRef.sideBarMenu }
    </Toolbar>
  </Container>
</AppBar>

const gridBuild=()=> <Paper elevation={8} sx={{height:'100%',display:'flex'}}>
    <Card sx={{ position:"relative", maxWidth: 310, m:'auto', boxShadow:'-1px -1px rgb(255 255 255 / 35%), 0 20px 50px rgb(0 0 0 / 50%), 1px 1px rgb(255 255 255 / 25%)',backgroundColor:paused?'#757575':null,backgroundImage:gradient}} >
      <CardHeader
        color="primary"
        sx={{position:'absolute',width:'100%',backgroundColor:'rgba(18, 18, 18,.75)',textShadow:'1px 1px #121212'}}
        avatar={
          [
           IconBuilder(),
          paused && <Box sx={{ml:'5%'}} key={"2_"+UserMap.get('avatar')}>{dynamicComponent('DefaultButton','elements',{state:'deliveryOpt',color:'#c0392b',label:'PAUSED',startIcon:<PauseCircleOutlineIcon/>,disabled:true},That)}</Box> ]
        }
        action={ stateRef.sideBarMenu }
        title={ ProjectData.ProjectTitle }
        subheader={ ProjectData?.SalesState?.rfpDue?.calendar ? `Due: ${dayjs(ProjectData.SalesState.rfpDue.calendar).format("MMM Do 'YY")}` : 'Due date not set.' }
      />
      <CardMedia
        component="img"
        height="194"
        image={`https://dev-services.ekc.app/scripts/images/${DivisionData?.category}.jpeg`}
        alt={DivisionData?.category}
      />
      <Box sx={{ width: '100%', paddingTop:'3%' }}>
     { dynamicComponent('ProgressPercent','elements',{state:'progressLabel',list:[{icon:<FactCheckIcon sx={{fontSize:13,mr:.5}}/>,label:'Production:',type:'production'},{icon:<LocalShippingIcon sx={{fontSize:13,mr:.5}}/>,label:'Deliveries:',type:'deliveries'}],vert:true,projID:projID,color:'#6c5ecf'},That) }
      </Box> 
      <CardContent sx={{paddingBottom:"50px",paddingTop:0}}>
       <Box sx={{display:'flex',flexWrap:'wrap',justifyContent:'space-between'}}>
        <Box sx={{m:'auto',display:'flex',justifyContent:'center',flexDirection:'column'}}>
        { ProjectData.PoNumber && UserMap.get('pageStamp').active!=='EOS' ? 
        <Typography gutterBottom variant="body1"  sx={{margin:'auto',lineHeight:1}}>
        {DivisionData?.teamName.includes('A/V') ? `JOB # ${ ProjectData.PoNumber }` : `ORDER # ${ProjectData.PoNumber}`}
        </Typography> : DivisionData?.teamName.includes('A/V') ? 'JOB# Not Set. ' : 'ORDER# Not Set.'}
        <Typography gutterBottom variant="caption" sx={{margin:'auto',textAlign:'center'}}>
        ( { ProjectData[`${ProjectData.Status}State`]?.Status ? ProjectData[`${ProjectData.Status}State`]?.Status : ProjectData.Status } )
        </Typography> 
        </Box>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Box sx={{m:'auto'}}>
        <Typography gutterBottom variant="body2" sx={{margin:'auto'}}>
         { ProjectData.ProjectState?.Calendar?.startDate ? 
          ('Starts: '+ dayjs(ProjectData.ProjectState.Calendar.startDate).format("MMM Do 'YY") )
          : 'Start Date Not Set.'}
        </Typography>
        <Typography gutterBottom variant="body2" sx={{margin:'auto'}}>
          { ProjectData.ProjectState?.Calendar?.endDate ? 
           ('Ends: '+ dayjs(ProjectData.ProjectState.Calendar.endDate).format("MMM Do 'YY") )
            : 'End Date Not Set.'}
         </Typography>
         </Box>
         </Box>
         <Box sx={{display:'flex',flexWrap:'wrap',justifyContent:'space-between'}}>
         <Typography variant="body2" color="text.secondary" sx={{margin:'auto'}}>
         { ProjectData.ProjectDesc ? ProjectData.ProjectDesc : 'No Description.' }
        </Typography> 
        </Box>
        <Box sx={{'& button':{margin:'1%'}}}>
         { stateRef.teamEditor }
         { stateRef.projBody }
         { stateRef.dataBttn }
        </Box>
      </CardContent>
      <AppBar position={"relative"} color="primary" sx={{ top: 'auto', bottom: 0, p:'1% 2%', flexDirection:'row', height:'60px',backgroundColor:paused?'#7b7979':'#090909' }}>
          { ProjectData.ProjectState?.projManager && 
          <IconButton color="inherit" >
          { dynamicComponent('AvatarBubble','elements',{state:'projLead',label:'missing Lead',style:{width:'35px',height:'35px'},userID:ProjectData.ProjectState.projManager,subUserID:ProjectData.ProjectState?.teamLead},That) }
          </IconButton> }

        { stateRef.editorFab }
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{marginTop:'3.3%','& button':{marginTop:'unset',zIndex:2}}}>
          { stateRef.projMembers }
        </Box>
      </AppBar>
    </Card>
    </Paper>

return projectMap.has(ProjectID) && <Grow in={true} key={ProjectID+"panelBuild"} easing={{ enter: "cubic-bezier(0,1.5,.75,1)", exit: "linear"}} > 
<Box key={'panel'+ProjectID} ref={module} data={ProjectID} style={ UserMap.get('projectsView')==='jsListView' ? {width:'100%'} : {margin:'auto',height:'100%'}}>
{ UserMap.get('projectsView') ==='jsListView' ? listBuild() : gridBuild() }
</Box>
</Grow>
}
