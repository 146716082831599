import React, {useEffect} from "react";
import { useAtom } from 'jotai';
import useState from 'react-usestateref';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import {Box,ListSubheader,Chip,CardHeader,Typography,Avatar,Divider,List,ListItem} from '@mui/material';
import dayjs from 'dayjs';

var isToday = require('dayjs/plugin/isToday')
dayjs.extend(isToday)

export default function ScanList(props){
const {That, globalState, viewData, action, dynamicComponent, isIterable} = props
const {projID, state, pramPass,scanListControls } = viewData
const [projectMap] = useAtom(props.globalState.projectMapState)
const [projectLoggMap] = useAtom(globalState.projectLoggMapState)
const [UserMap] = useAtom(props.globalState.userMapState)
const [appState, ] = useAtom(globalState.appState)
const [, setParentState, parentStateRef] = That
const mainState = useState({
  secure:true,
  errorItem:[],
  OptionSwitch: dynamicComponent('Switcher','elements',{state:'confirmOption',label:"Confirm Before Submission",labelPlacement:'right',style:{flex:'unset',margin:'auto'},defaultValue:parentStateRef.current.confirmOption},That)
}) 
const [, setState, stateRef] = mainState
const [, , outputRef] = useState(String)
const { ProjectID } = projID
const ProjectData = projectMap.get(ProjectID)

useEffect(() =>{
  if(outputRef.current==='')return
  setParentState(p=>({...p,...{[state]:outputRef.current}}))
  if(action) action.forEach((x)=>x({[state]:outputRef.current,pramPass:pramPass})) 
 },[outputRef.current])

useEffect(()=>{
  var otherListableItems = Array.from([...projectLoggMap],([,v])=>v)?.filter(x=>x.ProjectID===ProjectID)?.flatMap(x=> x?.filter(({userFilter})=> isIterable(userFilter) && Object.keys(...userFilter)?.includes(UserMap.get('avatar')) && !dayjs(Object.assign(...userFilter)[UserMap.get('avatar')]?.timestamp).isToday()))?.flatMap(y=>({numberSet:y.numberSet,SerialNumber:y.data.SerialNumber, timestamp: Object.assign(...y.userFilter)[UserMap.get('avatar')].timestamp,options: Object.assign(...y.userFilter)[UserMap.get('avatar')].options }))
  var listableItems = Array.from([...projectLoggMap],([,v])=>v).filter(x=>x.ProjectID===ProjectID).flatMap(x=> x.filter(({userFilter})=> isIterable(userFilter) && Object.keys(...userFilter).includes(UserMap.get('avatar')) && dayjs(Object.assign(...userFilter)[UserMap.get('avatar')]?.timestamp).isToday())).flatMap(y=>({numberSet:y.numberSet,SerialNumber:y.data.SerialNumber, timestamp: Object.assign(...y.userFilter)[UserMap.get('avatar')].timestamp,options: Object.assign(...y.userFilter)[UserMap.get('avatar')].options }))

setState(p=>({...p,...{
  activeList: listableItems.length,
  totalList: otherListableItems.length,
  projStat: <Box sx={{overflow:'auto'}}>
  { listableItems?.sort((a, b) => b?.timestamp?.localeCompare(a?.timestamp))?.map(({numberSet,options,SerialNumber,timestamp},i)=>
  [ <ListItem key={'listedProj'+i} alignItems="flex-start" sx={{p:0,pr:'1%'}}>
   <CardHeader
     sx={{width: '100%',display:'flex', justifyContent:'space-between',p:'2%',pr:'3.5%',gap:'3%'}}
     avatar={
       <Avatar sx={{ bgcolor: '#1ecd97',mr:0 }} aria-label="recipe">
         <CheckCircleIcon sx={{fontSize:38}}/>
       </Avatar>
     }
     action={ <Box sx={{display:'flex',flexDirection:'column'}}><Chip
             label={`# ${numberSet ? numberSet : 'N/A'}`} 
             deleteIcon={<DoneIcon />}
             color="success" 
              /><Typography variant="caption">{dayjs(timestamp).format('MMM Do YY - h:mma')}</Typography></Box> }
     title={<Typography variant="Body2" sx={{fontWeight:500}}>Serial#: {SerialNumber}</Typography>}
     subheader={<Typography variant="caption" sx={{display: '-webkit-box',WebkitLineClamp: 3, WebkitBoxOrient: 'vertical',overflow: 'hidden', textOverflow: 'ellipsis', lineHeight:1.1}} >{options?.flatMap(x=> x?.split(/(?=[A-Z])/).join(" ")).join(", ")}</Typography>}
   />
   </ListItem>,
   <Divider variant="inset"/>]
   )}
    { otherListableItems?.sort((a, b) => b?.timestamp?.localeCompare(a?.timestamp))?.map(({numberSet,options,SerialNumber,timestamp},i)=>
  [ <ListItem key={'listedProj'+i} alignItems="flex-start" sx={{p:0,backgroundColor:'#ff7551',borderRadius:'8px',m:'.5%',width:'99%',color:'#2e2e2e'}}>
   <CardHeader
     sx={{width: '100%',display:'flex', justifyContent:'space-between',p:'2%',pr:'3.5%',gap:'3%'}}
     avatar={
       <Avatar sx={{ bgcolor: '#1ecd97',mr:0 }} aria-label="recipe">
         <CheckCircleIcon sx={{fontSize:38}}/>
       </Avatar>
     }
     action={ <Box sx={{display:'flex',flexDirection:'column'}}><Chip
             label={`# ${numberSet ? numberSet : 'N/A'}`} 
             deleteIcon={<DoneIcon />}
             color="success" 
              /><Typography variant="caption">{dayjs(timestamp).format('MMM Do YY - h:mma')}</Typography></Box> }
     title={<Typography variant="Body2" sx={{fontWeight:500}}>Serial#: {SerialNumber}</Typography>}
     subheader={<Typography variant="caption" sx={{display: '-webkit-box',WebkitLineClamp: 3, WebkitBoxOrient: 'vertical',overflow: 'hidden', textOverflow: 'ellipsis', lineHeight:1.1}}>{options?.flatMap(x=> x?.split(/(?=[A-Z])/).join(" ")).join(", ")}</Typography>}
   />
   </ListItem>,
   <Divider variant="inset"/>]
   )}
   <Divider/>
   </Box>
}}))
},[projectLoggMap])

useEffect(() =>{
scanListControls((x)=>{ 
  x.id = Math.random()
  setTimeout(()=> setState(p=>({...p,...{ errorItem: p.errorItem.filter(y=>y.id!==x.id)}})) ,15000 )
  setState(p=>({...p,...{ errorItem: [...stateRef.current.errorItem,x] }})) 
})
},[])


return <List sx={{ display: 'flex', flexDirection: 'column', maxHeight:'95%', width: '100%', m: 'auto', maxWidth:'45%', bgcolor: 'rgba(18,18,18,.85)', borderRadius:1 }}>
        <Box sx={{width:'95%',pl:'5%',display:'flex',justifyContent:'space-evenly'}}>
        { stateRef.current.OptionSwitch }
        <Typography variant="h6" style={{lineHeight:1,fontWeight:900,flex:1}}>{ProjectData.ProjectTitle ? ProjectData.ProjectTitle :'Unknown Project' }</Typography>
        </Box>
      {appState.get('quedItems')>0 && <ListSubheader sx={{backgroundColor:'#32a7e2',color:'#2e2e2e',fontWeight: 800,lineHeight:'25px'}}>Offline Qued Scans: {appState.get('quedItems')} (DO NOT CLOSE APP)</ListSubheader>}
      <ListSubheader>Warnings & Issues</ListSubheader>
      <Divider/>
      { stateRef.current.errorItem?.map((x,i)=>
      [<ListItem alignItems="flex-start">
      <CardHeader
        sx={{width: '100%',display:'flex', justifyContent:'space-between'}}
        avatar={
          <Avatar sx={{ bgcolor: '#ec5252' }} aria-label="recipe">
            <WarningAmberIcon sx={{fontSize:28}}/>
          </Avatar>
        }
        action={
            <Chip
                label="ERROR"
                onDelete={()=>{}}
                deleteIcon={<ErrorOutlineIcon />}
                variant="outlined"
                color="error"
            />
        }
        color="error"
        title={x.SerialNumber}
        subheader={x.label}
      />
      </ListItem>,
      <Divider variant="inset"/>]
      )}
      <ListSubheader sx={{display:'flex',justifyContent:'space-between',pr:'1%'}}>Scan List <Chip
                sx={{m:'auto 0'}}
                label={stateRef.current?.activeList+' for today | '+ stateRef.current?.totalList+' in total' }
                onDelete={()=>{}}
                deleteIcon={<CheckCircleIcon />}
                variant="outlined"
                color="success"
            /></ListSubheader>
      <Divider/>
        {stateRef.current.projStat}
    </List>
}