import React, { useEffect } from "react";
import { Box, Typography, List, ListItem, Checkbox, Avatar, ListItemText, Grid, Button ,Card, CardHeader, Divider } from '@mui/material';
import useState from 'react-usestateref';
import { useAtom } from 'jotai';

const not = (a, b) => a.filter((value) => b.indexOf(value) === -1);
const intersection = (a, b) => a.filter((value) => b.indexOf(value) !== -1);
const union = (a, b) => [...a, ...not(b, a)];

const TransferList=({That,globalState,CheckID,viewData:{list,TransferAmountRef}})=> {
  const [productMap, setProductMap] = useAtom(globalState.productMapState)
  const mainState = useState(Object)
  const [, setState, stateRef] = mainState
  const [, setParentState, parentStateRef] = That
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState(list.current);
  const [right, setRight] = useState([]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  useEffect(()=>{
   if(right) setParentState(p=>({...p,...{
      rightList: right,
    }}));
  },[right])

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    
    if (currentIndex === -1) newChecked.push(value);
    else newChecked.splice(currentIndex, 1);
    setChecked(newChecked);
    
  };
 
  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) setChecked(not(checked, items));
    else setChecked(union(checked, items));
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const processProducts = async (left) => {
    if (left && left[0] !== undefined) {
      const promises = left.map(({ ProductItems }) => {
        ProductItems.forEach((ProductID,i)=> {
          if (!productMap.has(ProductID)) {
          return new Promise((resolve) => {
            CheckID({
              key: "Products",
              map: productMap,
              setMap: setProductMap,
              hydrate: 'productMapState',
              _id: ProductID.id
            }, (resp) => {
              resolve();
            })});
          
        } else {
          return Promise.resolve();
        }
      });
      });

      await Promise.all(promises);
    }
  };

  // Ensure we have the data
  useEffect(()=>{
    processProducts(left)
  },[left])

  const customList = (title, items) =>{
     return <Card sx={{height:'100%'}}>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items?.length && items?.length !== 0}
            indeterminate={
              numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          height: '100%',
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {
        items.flatMap((curr)=>{
          var {InventoryID,ProductItems} = curr
          var TransferAmount = list.current?.filter(x=>x._id === InventoryID)?.[0]?.TransferAmount
          const labelId = `transfer-list-all-item-${InventoryID}-label`;
        return <Box>
            {ProductItems?.flatMap(({id,Product},i)=> {
            if(productMap.has(id) || Object.keys(Product)?.length > 0) var {IMAGE_SPOTLIGHT,DISPLAY_NAME} = Product||productMap.get(id)
           return <ListItem
              key={InventoryID}
              role="listitem"
              button
              onClick={handleToggle(curr)}
              sx={{gap:'6%'}}
            >
               <Checkbox
                  checked={checked.indexOf(curr) !== -1||checked.filter(x=>x?.InventoryID === curr?.InventoryID)?.length > 0}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
                <Avatar src={IMAGE_SPOTLIGHT }/>
                <ListItemText key={id} id={labelId} primary={<Typography>{DISPLAY_NAME || `No Display Name`}</Typography>} secondary={<Typography sx={{lineHeight: 1}}>Units: {TransferAmountRef && TransferAmountRef?.length > 0 ? TransferAmountRef?.filter(x=>Object.keys(x)?.[0] === id)?.[0]?.[id] : TransferAmount}</Typography>} />
            </ListItem>
         }) }</Box>
        })}
      </List>
    </Card>
    }
  

  useEffect(()=>{
    setState(p=>({...p,
      customListVal: customList('Choices', left)
    }))
  },[left, checked])

  useEffect(()=>{
    setState(p=>({...p,
      customListValRight: customList('Chosen', right)
    }))
  },[right, checked])

  return <Grid container spacing={1} justifyContent="center" alignItems="center" sx={{alignItems:'stretch'}}>
      <Grid item sx={{flex:1}}>{stateRef.current.customListVal}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item sx={{flex:1}}>{stateRef.current.customListValRight}</Grid>
    </Grid>
}

  export default TransferList