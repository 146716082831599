import React, { useEffect, useMemo } from 'react';
import useState from 'react-usestateref'; 
import { useAtom } from 'jotai';

import dayjs from 'dayjs';
  import { MaterialReactTable } from 'material-react-table';
//Material UI Imports
import { Box, Typography,Tooltip, IconButton } from '@mui/material';
import { mkConfig, generateCsv, download } from 'export-to-csv'; //or use your library of choice here
import { jsPDF } from 'jspdf'; //or use your library of choice here
import autoTable from 'jspdf-autotable';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
//Icons Imports
var weekOfYear = require('dayjs/plugin/weekOfYear')
dayjs.extend(weekOfYear)

var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

const TableData = ({doesIDExist,dynamicComponent,waveLoad,dbUpdateValidate,globalState,globalWrn,multiProject,isIterable,viewData:{projID}}) => {
  const [, setState, stateRef] = useState({expanded:true,grouping:"date",focused:false,defaultTB:true})
  const mainState = useState(Object);
  const { ProjectID, DivisionID, modelType } = projID
  const [divisionMap] = useAtom(globalState.divisionMapState)
  const [projectMap,setProjectMap] = useAtom(globalState.projectMapState)
  const [projectLoggMap, setProjectLoggMap] = useAtom(globalState.projectLoggMapState)
  const [teamMember, setTeamMember] = useAtom(globalState.teamMemberState);
  const [UserMap] = useAtom(globalState.userMapState)
  const [, setPopup2] = useAtom(globalState.popupState2);
  const ProjectData = projectMap.has(ProjectID) && projectMap.get(ProjectID)
  const projFollow = ProjectData?.ProjectState?.teamLogg?.flatMap(({_id})=>_id) || []

  const onSaving=(table, changes, type, row)=>{ 
    var data = changes
    var id = row?.original?._id;
    var keySet = Array.from([...projectLoggMap],([k,v])=>{ if(v.some(({_id})=>_id===id)) return k }).filter(x=>x!==undefined)[0]
    var orig =  projectLoggMap.get(keySet)?.find(({_id})=>_id===id).data
    var arrayFilters
    if(keySet) arrayFilters =  [{"el._id": keySet }]

    if(type && type==='remove'){
    var output = {"$pull":{'ProjectState.teamLogg.$[el].logg':{'_id': id}}}
      return dbUpdateValidate({model:'Projects',modelType: modelType || divisionMap.get(DivisionID)?.teamName,idConvert:true,queryID:true,query:ProjectID,update:output,arrayFilters:arrayFilters},({success,info,err})=>{ 
      if(success){
          setPopup2({open: false})
          globalWrn('success',`You have successfully updated ${ProjectData.ProjectTitle}.`);
          doesIDExist({map:projectMap,set:setProjectMap,model:'Projects',_id:ProjectID,modelType:divisionMap.get(DivisionID)?.teamName})
      }
      if(err) globalWrn('error',`there was an error updating ${ProjectData.ProjectTitle}.`)
      })
    }
    else {

   if(id && arrayFilters?.length > 0) arrayFilters = [...arrayFilters,{"el2._id": id }]
   else if (id) arrayFilters = [{ "el2._id": id }]

  delete data._id
  delete data.date
  delete data.time
  delete data.numberSet


   var output = {'ProjectState.teamLogg.$[el].logg.$[el2].data':{...orig,...data},  "$push": {'ProjectState.teamLogg.$[el].logg.$[el2].userFilter': {[UserMap.get('avatar')]:{timestamp: dayjs().toISOString(),options:Object.keys(data)} }}
  }
  
   dbUpdateValidate({model:'Projects',modelType: modelType || divisionMap.get(DivisionID)?.teamName,queryID:true,query:ProjectID,update:output,arrayFilters:arrayFilters},({success,info,err})=>{ 
    if(success) {
      globalWrn('success',`You have successfully updated ${ProjectData.ProjectTitle}.`)
      table.setEditingRow(null); //exit editing mode
    }
      if(err) globalWrn('error',`there was an error updating ${ProjectData.ProjectTitle}.`)
    })
    }
    return
  }

    //DELETE action
  const openDeleteConfirmModal = (table, row) => {
    setPopup2(p=>({...p,
      open: true,
      title: "Delete Entry",
      description: "Are you sure you want to delete this row? This cannot be undone",
      handleSubmit: ()=>onSaving(table,null,"remove",row),
      handleClose: ()=>{setPopup2({open: false})}
    }))
    }  

  const projGatherData = () => {
    if(multiProject) var grouppedProj = multiProject
    else grouppedProj = projFollow

    if(grouppedProj.filter(x=>projectLoggMap.has(x))) var orders = grouppedProj.filter(x=>projectLoggMap.has(x))?.flatMap((x,i)=>
       projectLoggMap.get(x)?.flatMap((y,i2)=>
        y.user && y.user.flatMap((z,i3)=>{
          
        var userID = teamMember.get(z)
        if(isIterable(y.userFilter)) var userFilt = Object.assign(...y.userFilter)[z]
        var userFilter = userFilt?.options?.filter(x=>x!=='SerialNumber')?.flatMap(x=>({[x]:y.data[x]}))
        if(userFilter && userFilter?.length>0) userFilter = Object.assign(...userFilter)
        return { 
          id: i+'-'+i2+'-'+i3,
          _id: y._id,
          userID:z,
          user:`${userID?.firstName} ${userID?.lastName}`,
          numberSet: y.numberSet ? y.numberSet:'Missing',
          time:dayjs(userFilt?.timestamp).format('h:mm a'),
          date:dayjs(userFilt?.timestamp).format('MMMM DD YYYY'),
          week:dayjs(userFilt?.timestamp)?.week(),
          month:dayjs(userFilt?.timestamp).format('MMMM YYYY'),
          mulitUserProject: y.user?.length>1 ? true : false,
          SerialNumber:y.data?.SerialNumber? y.data.SerialNumber : 'Missing',
          ...userFilter}
      })
      )
    )
  
    var deviceFocusOrders = grouppedProj.map((x,i)=>
     projectLoggMap.get(x)?.flatMap((y,i2)=>{
        var numberSet = y.numberSet ? y.numberSet:'Missing'
        var serialNum = y.data?.SerialNumber? y.data.SerialNumber : 'Missing'
        var preUserFilter = y.userFilter?.length>0 ? Object.entries(Object.assign(...y.userFilter)).flatMap(([k,v])=> v?.options?.filter(x=>x!=='SerialNumber')?.flatMap(x=>({[x]:y.data[x]}))).filter(x=>x!==undefined) : []
        var userArray = y.userFilter?.length>0 ? Object.entries(Object.assign(...y.userFilter)).flatMap(([k,v])=>k) :[]
        var userFilt = y.userFilter?.length>0 ? Object.assign(...y.userFilter)[userArray.slice(-1)[0]]:[]
        var userID = teamMember.get(userArray.slice(-1)[0])
        var userFilter = preUserFilter?.length>0 ? Object.assign(...preUserFilter) : preUserFilter
        return { 
          id: i+'-'+i2,
          _id: y._id,
          userID: userArray.slice(-1)[0],
          SerialNumber: y.data?.SerialNumber? y.data.SerialNumber : 'Missing',
          user: userArray?.length>1?'Multiple Users':`${userID?.firstName} ${userID?.lastName}`,
          numberSet: y.numberSet ? y.numberSet:'Missing',
          time:dayjs(userFilt.timestamp).format('h:mm a'),
          date:dayjs(userFilt.timestamp).format('MMMM DD YYYY'),
          week:dayjs(userFilt.timestamp).week(),
          month:dayjs(userFilt.timestamp).format('MMMM YYYY'),
          mulitUserProject: userArray?.length>1 ? true : false,
          ...userFilter}
      })
    )

    var tempNum = 0;
    
    var removedColumns = ['id','week','month','userID','mulitUserProject']
    if(orders) var columns = [...new Set(orders.filter(x=> x !== undefined).flatMap(x=>Object.keys(x)))].filter(x=>!removedColumns.includes(x)).flatMap(x=>{
      if(x==='user') var title = 'Employee'
       else if(x==='numberSet') title = 'Device #'
       else if(x==='SerialNumber') title = 'Serial #'
       else title = x?.charAt(0).toUpperCase() + x?.slice(1).replace(/([A-Z])/g, ' $1').trim()
       tempNum = tempNum + 1;
       return {name:x,title:title, id:tempNum}
       })
    setState(p=>({...p,...{
      rows: orders,
      columns: columns,
      deviceFocusOrders: [].concat(...deviceFocusOrders),
      totalCount: [].concat(...orders)?.length
    }})) 
    }

  useEffect(()=>{
    projGatherData()
  },[projectLoggMap])

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
    filename: `${ProjectData?.ProjectTitle}_${ProjectData?.PoNumber}_${dayjs().format('llll')}`
  });

    const handleExportRows = (rows) => {
      const rowData = rows.map((row) => row.original);
      const csv = generateCsv(csvConfig)(rowData);
      download(csvConfig)(csv);
    };
  
    const handleExportData = () => {
      const csv = generateCsv(csvConfig)(stateRef.current?.rows);
      download(csvConfig)(csv);
    };

    const handleExportRowsPDF = (rows) => {
      const doc = new jsPDF();
      const tableData = rows.map((row) => Object.values(row.original));
      const tableHeaders = columnsVal.map((c) => c.header);
  
      autoTable(doc, {
        head: [tableHeaders],
        body: tableData,
      });
  
      doc.save(`${ProjectData?.ProjectTitle}_Scans.pdf`);
    };

    useEffect(()=>{
      if(ProjectData?.ProjectState?.teamMembers?.length > 0) ProjectData?.ProjectState?.teamMembers.forEach(x=>doesIDExist({map:teamMember,set:setTeamMember,model:'Team',_id:x?._id,modelType:divisionMap.get(DivisionID)?.teamName}))
      if(ProjectData?.ProjectState?.projManager) doesIDExist({map:teamMember,set:setTeamMember,model:'Team',_id:ProjectData?.ProjectState?.projManager,modelType:divisionMap.get(DivisionID)?.teamName})
      if(ProjectData?.ProjectState?.teamLead) doesIDExist({map:teamMember,set:setTeamMember,model:'Team',_id:ProjectData?.ProjectState?.teamLead,modelType:divisionMap.get(DivisionID)?.teamName})

      },[ProjectData?.ProjectState?.teamMembers, ProjectData?.ProjectState?.projManager, ProjectData?.ProjectState?.teamLead])

  const columnsVal = useMemo(()=>
      stateRef?.current?.columns?.flatMap(x=>{
        if (x?.name === "date") return {
          accessorKey: x?.name,
          header: x?.title,
          enableEditing: false,
          accessorFn: (x) => new Date(x?.date),
          filterVariant: 'date-range',
          Cell: ({ cell }) => `${cell?.row?.original?.date}`, // convert back to string for display
        }
        else if (x?.name === "time") return {
          accessorKey: x?.name,
          header: x?.title,
          enableEditing: false,
        }
        else if (x?.name === "numberSet") return {
          accessorKey: x?.name,
          header: x?.title,
          enableEditing: false,
        }
        else if (x?.name === "_id") return {
          id: x?.name,
          header: 'Employee',
          enableEditing: false,
          accessorFn: (x) => x?.user,
          filterVariant: "autocomplete",
          filterSelectOptions: (multiProject ? multiProject : projFollow)?.filter(x=>projectLoggMap.has(x))?.flatMap((x,i)=>
          projectLoggMap.get(x)?.flatMap((y,i2)=>
           y.user && y.user
              ?.filter(x=>x!==undefined)
              ?.flatMap((x)=>{
                return `${teamMember.get(x)?.firstName} ${teamMember.get(x)?.lastName}`
              })
            ))?.reduce((unique, item) => unique.includes(item) ? unique : [...unique, item], []),
          accessorKey: x?.name,
          Cell: ({ row }) => (
            <Box sx={{ display: 'flex', alignItems: 'center',gap:'5%' }} >
            <Box>{dynamicComponent('AvatarBubble','elements',{state:'teams',label:'Employee', isProject: true, style:{width:'30px',height:'30px',margin:'unset'},userID: row.original.userID },mainState)}</Box>
            <Typography variant='caption' >
              {row.original.user}
            </Typography>
          </Box>
          ),
        }
        else return {
          accessorKey: x?.name,
          header: x?.title,
        }
  }).filter(x=>x?.accessorKey !== 'user'), [stateRef.current.columns],);

  return ( <Box sx={{overflow: 'auto', width: '100%'}}>{ columnsVal && stateRef.current?.rows &&
    <MaterialReactTable
      columns={columnsVal}
      data={stateRef.current?.rows}
      enableColumnFilterModes
      enableColumnOrdering
      enableEditing
      enableGrouping
      enablePinning
      onEditingRowSave={ ({ table, values, row }) => {
        onSaving(table, values, null, row);
      }}
      renderRowActions={({ row, table }) => (
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <Tooltip title="Edit">
            <IconButton onClick={() => table.setEditingRow(row)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton color="error" onClick={() => openDeleteConfirmModal(table,row)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}

      initialState={{ showColumnFilters: true,density: 'compact' }}
      positionToolbarAlertBanner="bottom"
      renderTopToolbarCustomActions={({ table }) => (
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            padding: '8px',
            flexWrap: 'wrap',
          }}
        >
          { dynamicComponent('DropDown','elements',{ state: 'Export', defaultValue: 'Select An Export Option', label: 'Export PDF/CSV', list: ['Export All Data (CSV)', 'Export All Rows (CSV)', 'Export Page Rows (CSV)', 'Export Selected Rows (CSV)', 'Export All Rows (PDF)', 'Export Page Rows (PDF)', 'Export Selected Rows (PDF)'], replace:true },mainState,[({Export})=>{
            Object({ 
              'Export All Data (CSV)': handleExportData,
              'Export All Rows (CSV)': ()=>handleExportRows(table.getPrePaginationRowModel().rows),
              'Export Page Rows (CSV)': ()=> handleExportRows(table.getRowModel().rows),
              'Export Selected Rows (CSV)': ()=> handleExportRows(table.getSelectedRowModel().rows),
              'Export All Rows (PDF)': ()=> handleExportRowsPDF(table.getPrePaginationRowModel().rows),
              'Export Page Rows (PDF)': ()=> handleExportRowsPDF(table.getRowModel().rows),
              'Export Selected Rows (PDF)': ()=> handleExportRowsPDF(table.getSelectedRowModel().rows),
              })[Export]()
      }])}
         
      </Box>
      )}
      sx={{width: '100%', overflow: 'auto', overflowX: 'auto', position: 'relative'}}
    />
  }
  </Box> || waveLoad()
  );
};

export default TableData;
